import { IIdentityData } from '@aclass/admin/base/identity';
import { ISystemAlertData } from '@aclass/core/base/system.alert';
import { ISystemNotificationData } from '@aclass/core/base/system.notification';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NavigationExtras } from '@angular/router';

@Story({
    name: 'system'
})
export class SystemStory {

    @Task() static DRY_RUN: string;

    @Task() static SWITCH_APPLICATION: string;

    @Task() static UPDATE_APPLICATION: string;

    @Task() static NAVIGATE: string;

    @Task() static STOP_NAVIGATE: string;

    @Task() static UPDATE_INIT_STATE: string;

    @Task() static CHECK_BROWSER: string;

    @Task() static CALL_BASE_INTERCEPTOR: string;

    @Task() static CALL_SMART_INTERCEPTOR: string;

    @Task() static SWITCH_BASE_INTERCEPTOR: string;

    @Task() static SWITCH_SMART_INTERCEPTOR: string;

    @Task() static REMOVE_ALERT: string;

    @Task() static SHOW_ALERT: string;

    @Task() static REMOVE_NOTIFICATION: string;

    @Task() static SHOW_NOTIFICATION: string;

    @Task() static UPDATE_IDENTITY: string;

    @Task() static UPDATE_VERSION: string;

    @Task() static UPDATE_REDIRECT: string;

    static UPDATE_ROUTE = UPDATE_LOCATION;

    @Task() static UPDATE_NEXT_PAGE_LOCK: string;

    @Task() static UPDATE_CONFIGURATION: string;

    static dryRun(): Action {
        return { type: SystemStory.DRY_RUN };
    }

    static switchApplication(v: string | null): Action {
        return { type: SystemStory.SWITCH_APPLICATION, payload: v };
    }

    static updateApplication(v: string | null): Action {
        return { type: SystemStory.UPDATE_APPLICATION, payload: v };
    }

    static navigate(commands: Array<any>, extras?: NavigationExtras & { sharedLink?: boolean, inNewTab?: boolean }): Action {
        return { type: SystemStory.NAVIGATE, payload: { commands, extras } };
    }

    static stopNavigate(reason: string): Action {
        return { type: SystemStory.STOP_NAVIGATE, payload: reason };
    }

    static updateInitState(v: boolean | null): Action {
        return { type: SystemStory.UPDATE_INIT_STATE, payload: v };
    }

    static checkBrowser(): Action {
        return { type: SystemStory.CHECK_BROWSER };
    }

    static callBaseInterceptor(rs: HttpErrorResponse | HttpResponse<any>): Action {
        return { type: SystemStory.CALL_BASE_INTERCEPTOR, payload: rs };
    }

    static callSmartInterceptor(rs: HttpResponse<any>): Action {
        return { type: SystemStory.CALL_SMART_INTERCEPTOR, payload: rs };
    }

    static switchBaseInterceptor(v: boolean | null): Action {
        return { type: SystemStory.SWITCH_BASE_INTERCEPTOR, payload: v };
    }

    static switchSmartInterceptor(v: boolean | null): Action {
        return { type: SystemStory.SWITCH_SMART_INTERCEPTOR, payload: v };
    }

    static removeAlert(id: string): Action {
        return { type: SystemStory.REMOVE_ALERT, payload: id };
    }

    static showAlert(alert: ISystemAlertData): Action {
        return { type: SystemStory.SHOW_ALERT, payload: alert };
    }

    static removeNotification(id: string): Action {
        return { type: SystemStory.REMOVE_NOTIFICATION, payload: id };
    }

    static showNotification(notification: ISystemNotificationData): Action {
        return { type: SystemStory.SHOW_NOTIFICATION, payload: notification };
    }

    static updateIdentity(identity: IIdentityData): Action {
        return { type: SystemStory.UPDATE_IDENTITY, payload: identity };
    }

    static updateRedirect(payload: string | null): Action {
        return { type: SystemStory.UPDATE_REDIRECT, payload };
    }

    static updateVersion(v: string): Action {
        return { type: SystemStory.UPDATE_VERSION, payload: v };
    }

    static updateNextPageLock(v: boolean | null): Action {
        return { type: SystemStory.UPDATE_NEXT_PAGE_LOCK, payload: v };
    }

    static updateConfiguration(v: object): Action {
        return { type: SystemStory.UPDATE_CONFIGURATION, payload: v };
    }
}
