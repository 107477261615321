import { ModalManager } from '@aclass/admin/managers';
import { AppStory, TemplateStory } from '@aclass/admin/storage/actions';
import { IAdminState } from '@aclass/admin/storage/states';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DRIVERS, Locker } from 'angular-safeguard';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-ao-layout',
    templateUrl: './ao-layout.component.html'
})

export class AoLayoutComponent implements OnInit, OnDestroy {

    /**
     * Current user name
     */
    username: string;

    private _collapsed;

    private subscriptions: Array<Subscription> = [];

    set collapsed(v) {
        if (v === this._collapsed) {
            return;
        }
        this.ngRdx.dispatch(TemplateStory.toggleMenu(v));
    }

    get collapsed() {
        return this._collapsed;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private modalManager: ModalManager,
        private storage: Locker,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.username = this.ngRdx.getState().systemModule.getIn(['identity', 'username']) || '';
        let collapsed: null | boolean = this.ngRdx.getState().templateModule.get('menuCollapsed');
        if (collapsed !== null) {
            this._collapsed = collapsed;
        } else {
            collapsed = this.storage.get(DRIVERS.LOCAL, 'templateModule:menuCollapsed');
            this.collapsed = typeof collapsed === 'boolean' ? collapsed : false;
        }
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['templateModule', 'menuCollapsed'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._collapsed = v)
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() logout = () => AppStory.logout();

    runReport = () => this.modalManager.report();
}
