import { RedirectEditStateResolver } from '@aclass/admin/resolvers';
import {
    OrmStory, RedirectStory, RootStory, SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IRedirectData } from '@aclass/admin/storage/models';
import { IAdminState, IRedirectModuleState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const redirectModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(RedirectStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.redirectModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            RedirectStory.updateFormSearchPage({ }),
            RedirectStory.updateCollapsedSearchPage(null),
            // Unlock page
            RedirectStory.updateSearchPageInitState(true),
            RedirectStory.updateInstancesOnSearchPage(instanceManager.getInstances().map(i => i.buildId()).toArray()),
        ])
    ),
    action.pipe(
        ofType(RedirectStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.redirectModule;
            const brands = module.getIn(['searchPageForm', 'brands']);
            const locales = module.getIn(['searchPageForm', 'locales']);

            return of(RedirectStory.updateInstancesOnSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()));
        }),
    ),
    action.pipe(
        ofType(RedirectStory.RECORDS_SEARCH_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.redirectModule.get('recordsSearchPageId') !== payload),
        switchMap(({ payload }: Action<string>) => [
            // Reset prev records state
            RedirectStory.updateRecordsSearchPageId(payload),
            RedirectStory.updateFormRecordsSearchPage({ }),
            RedirectStory.importRecordsOnRecordsSearchPage([]),
            RedirectStory.updatePaginationOnRecordsSearchPage(null),
            RedirectStory.updateOrderOnRecordsSearchPage(null),
            RedirectStory.updateSearchLockOnRecordsSearchPage(null),
            RedirectStory.updateCollapsedRecordsSearchPage(null),
            // Unlock page
        ])
    ),
    action.pipe(
        ofType(RedirectStory.RECORDS_SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.redirectModule;
            const [locale] = instanceManager.parseId(module.get('recordsSearchPageId'));
            const rq: DataSearchRqData = {
                where: module.get('recordsSearchPageForm'),
                pagination: module.get('recordsSearchPagePagination'),
                order: module.get('recordsSearchPageOrder'),
            };
            const params = instanceManager.buildHeadersByLocale([locale]);

            return concat(of(RedirectStory.updateSearchLockOnRecordsSearchPage(true)), http.post('redirects/search', rq, { ...params}).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IRedirectData>>>) => [
                    OrmStory.populateRedirects(rs.body.results),
                    RedirectStory.importRecordsOnRecordsSearchPage(rs.body.results.map(r => r.id)),
                    RedirectStory.updatePaginationOnRecordsSearchPage(rs.body.pagination),
                    RedirectStory.updateOrderOnRecordsSearchPage(rs.body.order),
                    RedirectStory.updateSearchLockOnRecordsSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(RedirectStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IRedirectModuleState['editPageRecordId']>) => state.value.redirectModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IRedirectModuleState['editPageRecordId']>) => {
            const [locale, id] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeadersByLocale([locale]);

            return http.get(`redirects/${id}`, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<Transformed<IRedirectData>>) => [
                        RedirectStory.updateRecordIdEditPage(payload),
                        RedirectStory.updateFormOnEditPage({ }),
                        OrmStory.populateRedirects([rs.body.data]),
                    ],
                    () => [
                        SystemStory.stopNavigate(RedirectEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['redirects'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(RedirectStory.EDIT_PAGE_REMOVE_RECORD),
        mergeMap(() => {
            const module = state.value.redirectModule;
            const [locale, id] = instanceManager.parseId(module.get('editPageRecordId'));
            const params = instanceManager.buildHeadersByLocale([locale]);

            return http.delete(`redirects/${id}`, params).pipe(
                dispatchActions(() => {
                    return [
                        RedirectStory.importRecordsOnRecordsSearchPage([]),
                        RootStory.resetForm(),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Redirect removed. Please note that redirects cached on cms side')),
                        RedirectStory.updateSearchPageInitState(false),
                        OrmStory.dropRedirects([id]),
                        RedirectStory.updateRemoveModalLockOnEditPage(false),
                        RedirectStory.updateShowRemoveModalOnEditPage(false),
                        RedirectStory.updateRecordsSearchPageId(null),
                        SystemStory.navigate(['redirects', `${locale}`], { replaceUrl: true, sharedLink: true }),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(RedirectStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const [locale, id] = instanceManager.parseId(state.value.redirectModule.get('editPageRecordId'));
            const params = instanceManager.buildHeadersByLocale([locale]);
            const form = state.value.redirectModule.get('editPageForm').toJS();
            const result = {
                source: form.source,
                redirect: form.redirect,
                permanent: form.permanent,
            };

            return concat(of(RedirectStory.updateSaveLockEditPage(true)), http.put(`redirects/${id}`, result, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<IRedirectData>) => [
                        RedirectStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Redirect updated. Please note that redirects cached on cms side')),
                        OrmStory.populateRedirects([rs.body]),
                        RedirectStory.updateRecordsSearchPageId(null),
                        SystemStory.navigate(['redirects', `${locale}`], { replaceUrl: true, sharedLink: true }),
                    ],
                    () => [
                        RedirectStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(RedirectStory.CREATE_PAGE_DRY_RUN),
        filter(({ payload }: Action<IRedirectModuleState['createPageInstanceId']>) => state.value.redirectModule.get('createPageInstanceId') !== payload),
        mergeMap(({ payload }: Action<IRedirectModuleState['createPageInstanceId']>) => [
            RedirectStory.updateCreatePageInstanceId(payload)
        ]),
    ),
    action.pipe(
        ofType(RedirectStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
            const [locale] = instanceManager.parseId(state.value.redirectModule.get('createPageInstanceId'));
            const params = instanceManager.buildHeadersByLocale([locale]);
            const form = state.value.redirectModule.get('createPageForm').toJS();
            const result = {
                source: form.source,
                redirect: form.redirect,
                permanent: form.permanent,
            };

            return concat(of(RedirectStory.updateSaveLockCreatePage(true)), http.post(`redirects`, result, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<IRedirectData>) => [
                        RedirectStory.updateSaveLockCreatePage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Redirect created. Please note that redirects cached on cms side')),
                        OrmStory.populateRedirects([rs.body]),
                        RootStory.resetForm(),
                        RedirectStory.updateRecordsSearchPageId(null),
                        SystemStory.navigate(['redirects', `${locale}`], { replaceUrl: true, sharedLink: true }),
                    ],
                    () => [
                        RedirectStory.updateSaveLockCreatePage(false),
                    ]
                )
            ));
        }),
    ),
);
