import { AdditionalProduct } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IAdditionalProductModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<AdditionalProduct['id']>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * The id of product
     */
    editPageRecordId: AdditionalProduct['id'] | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;
}

export const INITIAL_ADDITIONAL_PRODUCT_STATE = createTypedState<IAdditionalProductModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageForm: { },
    createPageSaveLock: null,
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageForm: { },
});
