import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '../src/environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

// tslint:disable-next-line
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
