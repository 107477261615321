import { ISwitchButtonData } from '@aclass/admin/components';
import { Document } from '@aclass/admin/storage/models/document';
import { DocumentOfflineBookingMapping } from '@aclass/admin/storage/models/document.offline.booking.mapping';
import { OfflineBookingPassenger } from '@aclass/admin/storage/models/offline.booking.passenger';
import { OfflineBookingPayment } from '@aclass/admin/storage/models/offline.booking.payment';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Fk, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { IOfflineBookingData } from '@aclass/core/storage/models/offline.booking';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'OfflineBooking'
})
export class OfflineBooking extends Entity<IOfflineBookingData> {

    static PAYMENT_CARD = 1;

    static PAYMENT_BANK = 2;

    static PAYMENTS = [
        OfflineBooking.PAYMENT_CARD,
        OfflineBooking.PAYMENT_BANK,
    ];

    static PAYMENT_NAMES = {
        [OfflineBooking.PAYMENT_CARD]: 'By Card',
        [OfflineBooking.PAYMENT_BANK]: 'By Bank Transfer',
    };

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly bookedAt: number | null;

    @Attr() readonly revision: number | null;

    @Attr() readonly deposit: number | null;

    @Attr() readonly roomType: string | null;

    @Attr() readonly comment: string | null;

    @Attr() readonly paymentType: number | null;

    @Attr() readonly personalDataAllowed: boolean | null;

    @Attr() readonly healthDataAllowed: boolean | null;

    @Attr() readonly isDepositPayed: boolean | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedOfflineBooking' }) readonly enquireDataId: Opportunity | null;

    @Many({ to: 'Document', relatedName: 'relatedOfflineBookings', through: 'DocumentOfflineBookingMapping', throughFields: ['bookingId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    readonly relatedOfflineBookingPassengers: QuerySet<OfflineBookingPassenger> | null;

    readonly relatedOfflineBookingPayments: QuerySet<OfflineBookingPayment> | null;

    readonly relatedDocumentOfflineBookingMappings: QuerySet<DocumentOfflineBookingMapping>;

    /**
     * Payment type code to name
     */
    get paymentTypeName(): string {
        return this.paymentType in OfflineBooking.PAYMENT_NAMES ? OfflineBooking.PAYMENT_NAMES[this.paymentType] : '';
    }

    /**
     * Prepares statuses for checkbox
     */
    static composePaymentTypeForSwitchButton(): Array<ISwitchButtonData<number>> {
        return OfflineBooking.PAYMENTS.map(r => ({
            title: OfflineBooking.PAYMENT_NAMES[r],
            value: r,
        }));
    }
}
