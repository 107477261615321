import { OfflineBooking } from '@aclass/admin/storage/models/offline.booking';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOfflineBookingPayment {

    readonly id: string | null;

    readonly amount: number | null;

    readonly cardNo: string | null;

    readonly type: string | null;

    readonly payedAt: string | null;

    readonly bookingId: OfflineBooking | string | null;
}

@Model({
    name: 'OfflineBookingPayment'
})
export class OfflineBookingPayment extends Entity<IOfflineBookingPayment> {

    static PAYMENT_NAMES = {
        1: 'Dankort/Visa Dankort',
        3: 'Visa / Visa Electron',
        4: 'Mastercard',
        6: 'JCB',
        7: 'Maestro',
        8: 'Diners Club',
        9: 'American Express',
        11: 'Forbrugsforeningen',
        13: 'Danske Netbetalinger',
        14: 'PayPal',
        17: 'Klarna',
        18: 'SveaWebPay',
        19: 'SEB (SE)',
        20: 'Nordea (SE)',
        21: 'Handelsbanken (SE)',
        22: 'Swedbank (SE)',
        23: 'ViaBill',
        24: 'Beeptify',
        25: 'iDEAL',
        27: 'Paii',
        28: 'Brandts Gavekort',
        29: 'MobilePay Online',
    };

    @Attr() readonly id: string | null;

    @Attr() readonly amount: number | null;

    @Attr() readonly cardNo: string | null;

    @Attr() readonly type: string | null;

    @Attr() readonly payedAt: string | null;

    @Fk({ to: 'OfflineBooking', relatedName: 'relatedOfflineBookingPayments' }) readonly bookingId: OfflineBooking | null;

    /**
     * Payment type code to name
     */
    get paymentTypeName(): string {
        return this.type in OfflineBookingPayment.PAYMENT_NAMES ? OfflineBookingPayment.PAYMENT_NAMES[this.type] : '';
    }
}
