import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IExceptionData {

    readonly id: number;

    readonly brand: string;

    readonly brandLabel: string;

    readonly productName: string;

    readonly productId: number;

    readonly optionName: string;

    readonly optionId: number;

    readonly productType: string;

    readonly createdAt: number;

    readonly updatedAt: number;

    readonly exceptionText: string;
}

@Model({
    name: 'Exception'
})
export class Exception extends Entity<IExceptionData> {

    @Attr() readonly id: number | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly productName: string | null;

    @Attr() readonly productId: number | null;

    @Attr() readonly optionName: string | null;

    @Attr() readonly optionId: number | null;

    @Attr() readonly productType: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly exceptionText: string | null;
}
