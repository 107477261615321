import { DateWatcherFilteredDirective } from '@aclass/admin/directives/date.watcher.filtered.directive';
import { DateWatcherNodeDirective } from '@aclass/admin/directives/date.watcher.node.directive';
import { SystemStory } from '@aclass/admin/storage/actions';
import { IAdminState } from '@aclass/admin/storage/states';
import { Daytable } from '@aclass/core/base/daytable';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { NgRedux } from '@angular-redux/store';
import { AfterViewInit, ContentChildren, Directive, Input, OnDestroy, QueryList } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[admDateWatcher]'
})
export class DateWatcherDirective implements OnDestroy, AfterViewInit {

    @ContentChildren(DateWatcherNodeDirective, { descendants: true }) nodes: QueryList<DateWatcherNodeDirective>;
    @ContentChildren(DateWatcherFilteredDirective, { descendants: true }) filteredNodes: QueryList<DateWatcherFilteredDirective>;

    @Input('admDateWatcher') set control(v: FormControl) {
        this.deattach();
        this._control = v || null;
        if (!v) {
            return;
        }
        this.attach();
    }

    private subscriptions: Array<Subscription> = [];

    private _control: FormControl;

    private _date: string;

    get control(): FormControl {
        return this._control;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        if (!this.control) {
            return;
        }
        this.updateMinDate();
    }

    private attach() {
        this._date = this.control.value;
        this.subscriptions.push(
            this.control.valueChanges.subscribe(v => {
                if (this._date && v && this._date !== v) {
                    this.nodes.forEach(r => r.runUpdateSequence(Daytable.fromDateString(v).diff(Daytable.fromDateString(this._date), 'days')));
                    this.filteredNodes.forEach(r => r.runUpdateSequence(Daytable.fromDateString(v).diff(Daytable.fromDateString(this._date), 'days')));
                    this.ngRdx.dispatch(SystemStory.showNotification(SystemNotification.info('Success', [
                        'Dates successful updated.',
                        'Please take note that the prices for the new date may differ from the original prices.',
                        'Update total costs or recalculate prices for the new date if needed.',
                    ].join(' '))));
                }

                if (v !== null ) {
                    this._date = v;
                    this.updateMinDate();
                }
            })
        );
    }

    private deattach() {
        this.subscriptions.forEach(r => r.unsubscribe());
        this.subscriptions = [];
        this._date = null;
    }

    private updateMinDate() {
        this.nodes.forEach(r => {
            r.min = this._date;
        });
    }
}
