import { Iterable, List, Map } from 'immutable';

/**
 * Helper which can be used as "Type Guard" to check that given value is [[List]]
 */
export function isList<T>(v): v is List<T> {
    return List.isList(v);
}

/**
 * Helper which can be used as "Type Guard" to check that given value is [[Map]]
 */
export function isMap<K, V>(v): v is Map<K, V> {
    return Map.isMap(v);
}

/**
 * Helper which can be used as "Type Guard" to check that given value is [[Iterable]]
 */
export function isIterable<K, V>(v): v is Iterable<K, V> {
    return Iterable.isIterable(v);
}
