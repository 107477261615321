import { ISwitchButtonData } from '@aclass/admin/components';
import { InstanceManager } from '@aclass/admin/managers';
import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { Customer } from '@aclass/admin/storage/models/customer';
import { Delivery } from '@aclass/admin/storage/models/delivery';
import { Document } from '@aclass/admin/storage/models/document';
import {
    DocumentTravelWiseOpportunityMapping
} from '@aclass/admin/storage/models/document.travel.vise.opportunity.mapping';
import { Itinerary } from '@aclass/admin/storage/models/itinerary';
import { OfflineBooking } from '@aclass/admin/storage/models/offline.booking';
import { OpportunityEmailMessageLog } from '@aclass/admin/storage/models/opportunity.email.message.log';
import { OpportunityLog } from '@aclass/admin/storage/models/opportunity.log';
import { PCTourPackage } from '@aclass/admin/storage/models/pc.tour.package';
import { PipelineLevel } from '@aclass/admin/storage/models/pipeline.level';
import { Quote } from '@aclass/admin/storage/models/quote';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { TravelWiseDocument } from '@aclass/admin/storage/models/travel.wise.document';
import { User } from '@aclass/admin/storage/models/user';
import { Vamoos } from '@aclass/admin/storage/models/vamoos';
import { Attr, Fk, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { ILeadScoreExplanation, IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'Opportunity'
})
export class Opportunity extends Entity<IOpportunityData> {

    static CONTACT_TYPE_SALES = 1;

    static CONTACT_TYPE_CUSTOMER = 2;

    static CONTACT_TYPES = [
        Opportunity.CONTACT_TYPE_SALES,
        Opportunity.CONTACT_TYPE_CUSTOMER,
    ];

    static CONTACT_TYPE_NAMES = {
        [Opportunity.CONTACT_TYPE_SALES]: 'By sales',
        [Opportunity.CONTACT_TYPE_CUSTOMER]: 'By customer',
    };

    @Attr() readonly id: number | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly instanceId: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly flightAt: string | null;

    @Attr() readonly directFlight: boolean | null;

    @Attr() readonly followUpAt: string | null;

    @Attr() readonly leadScore: number | null;

    @Attr() readonly leadScoreExplanation: ILeadScoreExplanation | null;

    @Attr() readonly interest: string | null;

    @Attr() readonly adults: number | null;

    @Attr() readonly children: number | null;

    @Attr() readonly sent: boolean | null;

    @Attr() readonly comment: string | null;

    @Attr() readonly airport: string | null;

    @Attr() readonly archived: boolean | null;

    @Attr() readonly contactType: number | null;

    @Attr() readonly pingBack: boolean | null;

    @Attr() readonly levelWonExists: string | null;

    @Attr() readonly isUsedCustomPipeline: boolean | null;

    @Attr() readonly isUsedVamoos: boolean | null;

    @Attr() readonly vamoosUser: string | null;

    @Attr() readonly vamoosAppId: number | null;

    @Attr() readonly vamoosReferenceCode: string | null;

    @Attr() readonly marketingSource: string;

    @Attr() readonly otherDescription: string;

    @Fk({ to: 'Itinerary', relatedName: 'relatedTourOpportunities' }) readonly itineraryId: Itinerary;

    @Fk({ to: 'Itinerary', relatedName: 'relatedCombinationOpportunities' }) readonly combinationItineraryId: Itinerary | null;

    @Fk({ to: 'Customer', relatedName: 'relatedOpportunities' }) readonly customerId: Customer;

    @Fk({ to: 'Vamoos', relatedName: 'relatedOpportunities' }) readonly vamoosId: Vamoos;

    @Fk({ to: 'PCTourPackage', relatedName: 'relatedOpportunities' }) readonly packageId: PCTourPackage | null;

    @Fk({ to: 'User', relatedName: 'relatedOpportunities' }) readonly assignedTo: User | null;

    @Fk({ to: 'PipelineLevel', relatedName: 'relatedOpportunities' }) readonly pipelineId: PipelineLevel | null;

    @Fk({ to: 'TourRevision', relatedName: 'relatedOpportunities' }) readonly tourRevisionId: TourRevision | null;

    @Fk({ to: 'CombinationRevision', relatedName: 'relatedOpportunities' }) readonly combinationRevisionId: CombinationRevision | null;

    @Many({ to: 'Document', relatedName: 'relatedOpportunities', through: 'DocumentOpportunityMapping', throughFields: ['enquireDataId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    @Many({ to: 'TravelWiseDocument', relatedName: 'relatedOpportunities', through: 'DocumentTravelWiseOpportunityMapping', throughFields: ['enquireDataId', 'documentId'] }) readonly relatedTravelWiseDocuments: QuerySet<TravelWiseDocument>;

    readonly relatedQuotes: QuerySet<Quote>;

    readonly relatedOfflineBooking: QuerySet<OfflineBooking>;

    readonly relatedDelivery: QuerySet<Delivery>;

    readonly relatedLogs: QuerySet<OpportunityLog>;

    readonly relatedEmailLogs: QuerySet<OpportunityEmailMessageLog>;

    readonly relatedDocumentTravelWiseOpportunityMappings: QuerySet<DocumentTravelWiseOpportunityMapping>;

    get pax() {
        return Number(this.adults) + Number(this.children);
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeContactTypeForSwitchButton(): Array<ISwitchButtonData<number>> {
        return Opportunity.CONTACT_TYPES.map(r => ({
            title: Opportunity.CONTACT_TYPE_NAMES[r],
            value: r,
        }));
    }

    isAfrica(): boolean {
        return InstanceManager.isAfrica(this.brand);
    }

    isAsia(): boolean {
        return InstanceManager.isAsia(this.brand);
    }

    isLama(): boolean {
        return InstanceManager.isLama(this.brand);
    }

    isAustralia(): boolean {
        return InstanceManager.isAustralia(this.brand);
    }

    isNorthAmerica(): boolean {
        return InstanceManager.isNorthAmerica(this.brand);
    }

    isUk(): boolean {
        return InstanceManager.isUk(this.locale);
    }

    isDe(): boolean {
        return InstanceManager.isDe(this.locale);
    }

    isDk(): boolean {
        return InstanceManager.isDk(this.locale);
    }

    isSe(): boolean {
        return InstanceManager.isSe(this.locale);
    }

    isNo(): boolean {
        return InstanceManager.isNo(this.locale);
    }

    isNl(): boolean {
        return InstanceManager.isNl(this.locale);
    }

    isFi(): boolean {
        return InstanceManager.isFi(this.locale);
    }

    isFr(): boolean {
        return InstanceManager.isFr(this.locale);
    }
}
