import { IIdentityData } from '@aclass/admin/base/identity';
import { AppStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { DRIVERS } from 'angular-safeguard';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

export const appModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, storage }: IEpicDi) => merge(
    action.pipe(
        ofType(AppStory.AUTO_LOGIN),
        mergeMap(() => {
            const toLoginPage = SystemStory.navigate(['login']);
            const application = storage.get(DRIVERS.LOCAL, 'application');
            if (!application || !storage.get(DRIVERS.LOCAL, 'token')) {
                return of(toLoginPage);
            }

            return http.post('users/login-by-token', null).pipe(
                dispatchActions(
                    (rs: Response<IIdentityData>) => [
                        SystemStory.updateIdentity(rs.body),
                        SystemStory.updateInitState(false),
                        SystemStory.navigate([application])
                    ],
                    () => [
                        toLoginPage
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(AppStory.LOGIN),
        mergeMap(() => {
            const module = state.value.appModule;
            const redirect = state.value.systemModule.get('redirect');
            const data: { email: string | null, password: string | null, application: string } = module.get('loginPageForm').toJS();
            const { email, password, application } = data;
            if (!email || !password) {
                return of(AppStory.showError('Please enter email and password'));
            }

            return concat(of(AppStory.updateLoginLock(true)), http.post('users/login', { email, password }).pipe(
                dispatchActions(
                    (rs: Response<IIdentityData>) => {
                        storage.set(DRIVERS.LOCAL, 'token', rs.body.token);

                        return [
                            SystemStory.updateIdentity(rs.body),
                            SystemStory.updateInitState(false),
                            SystemStory.updateRedirect(null),
                            SystemStory.navigate([redirect && redirect.indexOf(`/${ application }`) === 0 ? redirect : application]),
                            // Button unlock in system module, after dependencies loaded
                            // AppStory.updateLoginLock(false),
                        ];
                    },
                    (rs: Response) => [
                        AppStory.showError(rs.errors.map(e => e.message).join('<br>')),
                        AppStory.updateLoginLock(false)
                    ]
                )
            ));
        })
    ),
    action.pipe(
        ofType(AppStory.LOGOUT),
        switchMap(() => {
            storage.clear(DRIVERS.LOCAL);

            return [
                RootStory.reset(),
                SystemStory.navigate(['login'])
            ];
        })
    )
);
