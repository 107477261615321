import { RootStory } from '@aclass/admin/storage/actions';
import { INITIAL_STATE } from '@aclass/admin/storage/state';
import { IAdminState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { fromJS, Map } from 'immutable';

export function rootReducer(): any {
    return (state: IAdminState = INITIAL_STATE, action: Action) => {
        const { type, payload } = action;
        switch (type) {
            case RootStory.FORM_CHANGED:
                const [moduleName, path] = payload.path;
                const module: Map<string, any> = state[moduleName];

                return {
                    ...state, ...{
                        [moduleName]: module.updateIn(path, () => fromJS(payload.value))
                    }
                };
            case RootStory.RESET:
                return INITIAL_STATE;
            default:
                return state;
        }
    };
}
