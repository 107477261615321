import { ExceptionEditStateResolver } from '@aclass/admin/resolvers';
import {
    ExceptionStory,
    OrmStory,
    PackageItineraryStory,
    QuoteStory,
    RootStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IExceptionData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const exceptionModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(ExceptionStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.exceptionModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            ExceptionStory.updateFormSearchPage({ }),
            ExceptionStory.importRecordsOnSearchPage([]),
            ExceptionStory.updatePaginationOnSearchPage(null),
            ExceptionStory.updateOrderOnSearchPage(null),
            ExceptionStory.updateSearchLockOnSearchPage(null),
            ExceptionStory.updateCollapsedSearchPage(null),
            // Unlock page
            ExceptionStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(ExceptionStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.exceptionModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(ExceptionStory.updateSearchLockOnSearchPage(true)), http.post('exceptions/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IExceptionData>>>) => [
                    OrmStory.populateExceptions(rs.body.results),
                    ExceptionStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    ExceptionStory.updatePaginationOnSearchPage(rs.body.pagination),
                    ExceptionStory.updateOrderOnSearchPage(rs.body.order),
                    ExceptionStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(ExceptionStory.PRODUCT_OPTION_SEARCH),
        mergeMap(({ payload }: Required<Action<{ brand: string, productType: string, productId: number, name: string | null }>>) => {
            const productId = payload.productId;
            if (productId === null) {
                return concat(of(PackageItineraryStory.dayUpdateSearchOptionLock('uid', true)), http.post('tw/search-options-with-contracts', []).pipe(
                    dispatchActions((rs: Response<any>) => [
                        ExceptionStory.productUpdateOptions([]),
                        ExceptionStory.productIdUpdateOptions(productId, []),
                        OrmStory.populateTwOptions(rs.body.results.map(({ id, name, contractId }) => ({ id, name, contractId }))),
                    ])
                ));
            }
            const rq: DataSearchRqData = {
                where: {
                    productId: payload.productId,
                    productType: payload.productType,
                    name: payload.name || null,
                    deleted: false,
                    active: true,
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(PackageItineraryStory.dayUpdateSearchOptionLock('uid', true)), http.post('tw/search-options-with-contracts', rq).pipe(
                dispatchActions((rs: Response<any>) => [
                    ExceptionStory.productUpdateOptions(rs.body.results),
                    ExceptionStory.productIdUpdateOptions(productId, rs.body.results.map(r => r.id)),
                    OrmStory.populateTwOptions(rs.body.results.map(({ id, name, contractId }) => ({ id, name, contractId }))),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(ExceptionStory.CREATE_PAGE_DRY_RUN),
        mergeMap(({ payload }: Required<Action<{ brand: string, productType: string, productId: number | null, name: string | null }>>) => {
            const rq: DataSearchRqData = {
                where: {
                    brand: payload.brand,
                    productType: payload.productType,
                    pcTourId: null,
                    pcCombinationId: null,
                    name: payload.name,
                },
                pagination:  new Pagination().all(),
                order: {
                    by: 'name',
                    isReverse: false
                }
            };

            return concat(of(QuoteStory.updateSearchLockHotelPicker(true)), http.post('tw/headless-product-search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<any>>>) => {
                    return [
                        OrmStory.populateTwProducts(rs.body.results),
                        ExceptionStory.importProductsIds(rs.body.results.map(r => r.id)),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(ExceptionStory.CREATE_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<any> | null) => {
                let data: Map<string, any>;
                let rq: any;
                if (payload) {
                    data = state.value.exceptionModule.get('editPageForm');
                    rq = {
                        brand: data.get('brand'),
                        productType: data.get('productType'),
                        productId: data.get('productId'),
                        optionId: data.get('optionId'),
                    };
                } else {
                    data = state.value.exceptionModule.get('createPageForm');
                    let options = [];
                    if (data.get('optionId') !== null) {
                        options = Array.isArray(data.get('optionId').toJS()) ? data.get('optionId').toJS().map(item => item.id) : [data.get('optionId').toJS().id];
                    } else if (data.get('appliesToAll') === true) {
                        options = state.value.exceptionModule.get('productOptions').map(item => item.id).toArray();
                    }
                    rq = {
                        brand: data.get('brands'),
                        productType: data.get('productType'),
                        productId: data.get('productId').id,
                        options,
                        appliesToAll: data.get('appliesToAll'),
                        exceptionText: data.get('exceptionText')
                    };
                }

                return concat(of(ExceptionStory.updateSearchPageInitState(true)), http.post('exceptions/create', rq).pipe(
                    dispatchActions((rs: Response<any>) => {
                        const operation = rs.body.operation;
                        const count = rs.body.count;
                        const plural = count > 1 ? 'Exceptions' : 'Exception';

                        if (rs.body.success) {
                            return [
                                SystemStory.showNotification(SystemNotification.success('Success', `${count} ${plural} ${operation}`)),
                                ExceptionStory.submitOnSearchPage(),
                                RootStory.resetForm(),
                                SystemStory.navigate(['exceptions'], { sharedLink: true})
                            ];
                        } else {
                            return [
                                SystemStory.showNotification(SystemNotification.error('Error', `${count} ${plural} not ${operation}`)),
                            ];
                        }
                    })));
            },
        )),
    action.pipe(
        ofType(ExceptionStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload}: Action<IExceptionData['id']>) => http.get(`exceptions/${payload}`).pipe(
            dispatchActions((rs: Response<any>) => {
                    return [
                        ExceptionStory.updateIdEditPage(rs.body.data.id),
                        ExceptionStory.updateFormOnEditPage(rs.body.data),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(ExceptionEditStateResolver.STOP_EVENT_NAME),
                ]
            )
        ))
    ),
    action.pipe(
        ofType(ExceptionStory.EDIT_PAGE_REMOVE),
        mergeMap(({ payload}: Action<number>) => {
            const id = payload ? payload : state.value.exceptionModule.get('editPageRecordId');

            return http.delete(`exceptions/${id}`).pipe(
                dispatchActions(
                    () => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Exception removed`)),
                        ExceptionStory.updateSearchLockOnSearchPage(true),
                        SystemStory.navigate(['exceptions'], { sharedLink: true })
                    ],
                    () => [
                        ExceptionStory.updateSaveLockEditPage(false),
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(ExceptionStory.EDIT_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<IExceptionData['exceptionText']>) => {
            const id = state.value.exceptionModule.get('editPageRecordId');
            const rq = {
                id: id,
                exceptionText: payload
            };

            return concat(of(ExceptionStory.updateSaveLockEditPage(true)), http.put(`exceptions/${id}`, rq).pipe(
                dispatchActions(
                    (rs: Response<Transformed<IExceptionData>>) => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Exception updated`)),
                        ExceptionStory.updateFormOnEditPage(rs.body.data),
                        ExceptionStory.updateSearchLockOnSearchPage(true),
                    ],
                    () => [
                        ExceptionStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
);
