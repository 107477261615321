import { QuestionnaireEditStateResolver, QuestionnaireShowResultStateResolver } from '@aclass/admin/resolvers';
import {
    OrmStory,
    QuestionnaireStory,
    QuoteStory,
    RootStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    IQuestionnaireData,
    IQuestionnaireResultData,
    IQuestionData, Questionnaire
} from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { tryToSaveFile } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Order } from '@aclass/core/rest/order';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type transformed = Transformed<IQuestionnaireData, {
    questions?: Array<Transformed<IQuestionData>>;
}>;

export const questionnaireModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager}: IEpicDi) => merge(
    action.pipe(
        ofType(QuestionnaireStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.questionnaireModule.get('searchPageInitialized')),
        switchMap(() => [
            QuestionnaireStory.submitOnSearchPage(),
            QuestionnaireStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(QuestionnaireStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload}: Action<boolean>) => {
            let queue = of(QuestionnaireStory.updateSearchLockOnSearchPage(true));
            let rq: DataSearchRqData;
            const module = state.value.questionnaireModule;
            if (payload) {
                queue = concat(queue, of(RootStory.resetForm()));
                rq = {
                    pagination: module.get('searchPagePagination'),
                    order: module.get('searchPageOrder'),
                };
            } else {
                const form = module.get('searchPageForm').toJS();
                rq = {
                    where: {
                        brands: form.brands,
                        locales: form.locales,
                    },
                    pagination: module.get('searchPagePagination'),
                    order: module.get('searchPageOrder'),
                };
            }

            return concat(queue, http.post('questionnaire/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IQuestionnaireData>>>) => [
                    OrmStory.populateQuestionnaires(rs.body.results),
                    QuestionnaireStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    QuestionnaireStory.updatePaginationOnSearchPage(rs.body.pagination),
                    QuestionnaireStory.updateOrderOnSearchPage(rs.body.order),
                    QuestionnaireStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(QuestionnaireStory.VIEW_PAGE_PREVIEW),
        mergeMap(() => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');
            const defaults = [QuoteStory.updateSaveLockEditPage(false)];

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.post(`offers/preview/${id}`, module.get('viewPageForm')).pipe(dispatchActions(
                (rs: Response<any>) => {
                    const { protocol, hostname, port} = window.location;
                    const host = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
                    const page = window.open(`${host}/ao/quote-preview`, '_blank', 'location=no,status=no');
                    if (!page) {
                        return defaults.concat([
                            SystemStory.showNotification(SystemNotification.error('Error', 'Please allow application to open new page(you should see cross button in address bar) and try again.')),
                        ]);
                    }
                    // @ts-ignore
                    page.__QUOTE__ = JSON.stringify(rs.body);

                    return defaults;
                },
                () => defaults
            )));
        }),
    ),
    action.pipe(
        ofType(QuestionnaireStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
                const data: Map<string, any> = state.value.questionnaireModule.get('createPageForm');
                const rq: any = {
                    brand: data.get('brands'),
                    locale: data.get('locales'),
                    name: data.get('name'),
                    description: data.get('description'),
                    linkTitle: data.get('linkTitle'),
                    questions: data.get('questions'),
                    thankText: data.get('thankText')
                };

                return concat(of(QuestionnaireStory.updateSearchPageInitState(true)), http.post('questionnaire', rq).pipe(
                    dispatchActions((rs: Response<IQuestionnaireData>) => [
                            SystemStory.showNotification(SystemNotification.success('Success', `Questionnaire created`)),
                            OrmStory.populateQuestionnaires([rs.body]),
                            RootStory.resetForm(),
                            QuestionnaireStory.updateSearchPageInitState(false),
                            QuestionnaireStory.updateQuestionnaireIdCreatePage(null),
                            SystemStory.navigate(['questionnaires'], { sharedLink: true})
                        ],
                    )),
                );
            },
        )),
    action.pipe(
        ofType(QuestionnaireStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload}: Action<IQuestionnaireData['id']>) => state.value.questionnaireModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload}: Action<IQuestionnaireData['id']>) => http.get(`questionnaire/${payload}`).pipe(
            dispatchActions((rs: Response<transformed>) => {
                    const { data, includes} = rs.body;

                    return [
                        QuestionnaireStory.updateFormOnEditPage({ }),
                        OrmStory.populateQuestionnaires([data]),
                        OrmStory.populateQuestions(includes.questions.map(q => q.data)),
                        QuestionnaireStory.updateIdEditPage(payload),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(QuestionnaireEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['questionnaire'], { sharedLink: true})
                ]
            )
        ))
    ),
    action.pipe(
        ofType(QuestionnaireStory.SEARCH_PAGE_COPY_QUESTIONNAIRE),
        mergeMap(({ payload}: Action<IQuestionnaireData['id']>) => http.get(`questionnaire/${payload}`).pipe(
            dispatchActions((rs: Response<transformed>) => {
                    const { data, includes} = rs.body;
                    const redirect = SystemStory.navigate(['questionnaires', 'create'], { sharedLink: true});

                    return [
                        QuestionnaireStory.updateFormOnCreatePage({ }),
                        OrmStory.populateQuestionnaires([data]),
                        OrmStory.populateQuestions(includes.questions.map(q => q.data)),
                        QuestionnaireStory.updateQuestionnaireIdCreatePage(payload),
                        redirect
                    ];
                },
                () => [
                    SystemStory.stopNavigate(QuestionnaireEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['questionnaire'], { sharedLink: true})
                ]
            )
        ))
    ),
    action.pipe(
        ofType(QuestionnaireStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const id = state.value.questionnaireModule.get('editPageRecordId');
            const data: Map<string, any> = state.value.questionnaireModule.get('editPageForm');
            const rq: any = {
                brand: data.get('brand'),
                locale: data.get('locale'),
                name: data.get('name'),
                description: data.get('description'),
                linkTitle: data.get('linkTitle'),
                questions: data.get('questions'),
                thankText: data.get('thankText')
            };

            const questionnaire = findById<Questionnaire>(orm.session(state.value.orm), Questionnaire, id);
            const questions = questionnaire.relatedQuestions ? questionnaire.relatedQuestions.all().toModelArray() : [];

            return concat(http.put(`questionnaire/${id}`, rq).pipe(
                dispatchActions(
                    (rs: Response<transformed>) => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Questionnaire "${rs.body.data.name}" updated`)),
                        OrmStory.dropQuestions(questions.map(item => item.id)),
                        OrmStory.populateQuestionnaires([rs.body.data]),
                    ],
                )
            ));
        }),
    ),

    action.pipe(
        ofType(QuestionnaireStory.RESULT_PAGE_DRY_RUN),
        mergeMap(({ payload}: Action<string>) => {
            const [brand, locale, id] = instanceManager.parseId(payload);
            const rq: DataSearchRqData = {
                where: {
                    id: parseInt(id),
                    deleted: false
                },
                order: new Order({ by: 'createdAt', isReverse: false})
            };
            const params = instanceManager.buildHeaders([brand, locale]);

            return http.post(`questionnaire/result`, rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IQuestionnaireResultData>>>) => {

                        return [
                            QuestionnaireStory.updateIdResultPage(payload),
                            OrmStory.populateQuestionnaireResults(rs.body.results),
                            QuestionnaireStory.importRecordsOnSearchResultPage(rs.body.results),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(QuestionnaireEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['questionnaire'], { sharedLink: true})
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(QuestionnaireStory.SHOW_RESULT_PAGE_DRY_RUN),
        mergeMap(({ payload}: Action<string>) => {
            const [brand, locale, id] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeaders([brand, locale]);

            return http.get(`questionnaire/result/show/${id}`, params).pipe(
                dispatchActions((rs: Response<IQuestionnaireResultData>) => {

                        return [
                            QuestionnaireStory.updateIdShowResultPage(payload),
                            OrmStory.populateQuestionnaireResults([rs.body]),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(QuestionnaireShowResultStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['questionnaire'], { sharedLink: true})
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(QuestionnaireStory.VIEW_PAGE_DOWNLOAD),
        mergeMap(({ payload}: Action<string>) => {

            return concat(
                of(QuestionnaireStory.updateSearchLockOnViewPage(true)),
                http.get(
                    `questionnaire/download/${payload}`,
                    { observe: 'response', responseType: 'text'}
                ).pipe(
                    mergeMap((rs: HttpResponse<string>) => {
                        tryToSaveFile(rs);

                        return of(QuestionnaireStory.updateSearchLockOnViewPage(false));
                    })
                ));
        }),
    ),
    action.pipe(
        ofType(QuestionnaireStory.VIEW_PAGE_DELETE_TOGGLE),
        mergeMap(({ payload}: Action<string>) => {

            return concat(
                http.get(
                    `questionnaire/delete-toggle/${payload}`
                ).pipe(
                    dispatchActions((rs: Response<transformed>) => {

                            return [SystemStory.showNotification(SystemNotification
                                .success('Success',
                                    `Questionnaire "${rs.body.data.name}" ${rs.body.data.deleted ? 'deleted' : 'restored'}`))];
                        }
                    )
                ));
        }),
    ),
);
