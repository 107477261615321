import { ModalManager } from '@aclass/admin/managers';
import { readToBase64 } from '@aclass/core/base/file.reader';
import { Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrWizard } from '@clr/angular';
import { of, zip, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'adm-report-issue',
    templateUrl: './report-issue.component.html',
    styleUrls: [],
})
export class ReportIssueComponent implements OnInit, OnDestroy {

    @ViewChild('wizard', { static: true }) wizard: ClrWizard;

    form: FormGroup;

    loading: boolean;

    finished: boolean;

    success: boolean;

    ticket: number | null;

    private _attachments: Array<File>;

    private _extra: object;

    private _show: boolean;

    private awaiter: EventEmitter<boolean | undefined> | undefined;

    private subscriptions: Array<Subscription> = [];

    /**
     * Short code to set trigger value
     */
    set show(v: boolean) {
        if (!v && this.awaiter) {
            this.destroy();
        }
        this._show = v;
    }

    /**
     * Short code to get trigger value
     */
    get show(): boolean {
        return this._show;
    }

    constructor(
        private modalManager: ModalManager,
        private http: HttpClient,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.loading = false;
        this.finished = false;
        this.success = false;
        this._show = false;
        this._attachments = [];
        this._extra = { };
        this.form = new FormGroup({
            subject: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
            message: new FormControl(null),
            attachments: new FormControl(null),
            state: new FormControl(false),
        });
        type input = Action<{ listener: EventEmitter<boolean | undefined>, params?: object }>;
        this.subscriptions.push(
            this.modalManager.modal
                .pipe(filter((v: input) => v.type === ModalManager.REPORT_OPEN))
                .subscribe((v: input) => {
                    this._show = true;
                    this.awaiter = v.payload.listener;
                    this._extra = typeof v.payload.params === 'object' ? v.payload.params : { };
                }),
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        if (this.awaiter) {
            this.destroy();
        }
    }

    /**
     * Emits selected value to awaiter
     */
    runResolve() {
        if (this.awaiter) {
            this.destroy();
        }

        this._show = false;
    }

    /**
     * Sends report
     */
    runSend() {
        this.loading = true;
        this.wizard.next();
        const stream = this._attachments.length ? zip(...this._attachments.map(readToBase64)) : of([]);
        stream.pipe(
            mergeMap(attachments => {
                const data = {
                    subject: this.form.controls.subject.value,
                    message: this.form.controls.message.value,
                    attachments,
                    extra: this._extra
                };

                return this.http.post('system/report', data);
            }),
        ).subscribe((v: Response<{ ticket: number}>) => {
            this.success = v.success;
            if (v.success) {
                this.ticket = v.body.ticket;
            }
            this.finished = true;
            this.loading = false;
        });
    }

    /**
     * Handles upload action
     */
    runUpload(e: Event) {
        // @ts-ignore
        this._attachments = Array.from(e.target.files);
    }

    getSuccessMessage = () => {
        return `Your ticked № ${this.ticket} was sent to support team!`;
    }

    private destroy() {
        this.awaiter.emit(this.finished);
        // flush listener
        this.awaiter = undefined;
        this.loading = false;
        this.finished = false;
        this._attachments = [];
        this._extra = { };
        this.form.reset();
        this.wizard.reset();
    }
}
