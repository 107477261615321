import { EmailTemplate } from '@aclass/admin/storage/models/email.template';
import { Questionnaire } from '@aclass/admin/storage/models/questionnaire';
import { SmsTemplate } from '@aclass/admin/storage/models/sms.template';
import { User } from '@aclass/admin/storage/models/user';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDomainDetailData {

    readonly id: string | null;

    readonly brandName: string | null;

    readonly email: string | null;

    readonly fromName: string | null;

    readonly manager: number | null;

    readonly primaryEmailTemplateId: EmailTemplate | number | null;

    readonly secondaryEmailTemplateId: EmailTemplate | number | null;

    readonly vamoosInitialTemplateId: EmailTemplate | number | null;

    readonly vamoosUpdateNotifyTemplateId: EmailTemplate | number | null;

    readonly vamoosAlertTemplateId: EmailTemplate | number | null;

    readonly primarySmsTemplateId: SmsTemplate | number | null;

    readonly vamoosSmsInitialTemplateId: SmsTemplate | number | null;

    readonly vamoosSmsUpdateNotifyTemplateId: SmsTemplate | number | null;

    readonly primaryQuestionnaireTemplateId: Questionnaire | number | null;
}

@Model({
    name: 'DomainDetail'
})
export class DomainDetail extends Entity<IDomainDetailData> {

    @Attr() readonly id: string | null;

    @Attr() readonly brandName: string | null;

    @Attr() readonly email: string | null;

    @Attr() readonly fromName: string | null;

    @Fk({ to: 'User', relatedName: 'relatedDomainDetail' }) readonly manager: User | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'relatedDomainDetail' }) readonly primaryEmailTemplateId: EmailTemplate | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'relatedDomainDetailSecond' }) readonly secondaryEmailTemplateId: EmailTemplate | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'vamoosInitialTemplate' }) readonly vamoosInitialTemplateId: EmailTemplate | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'vamoosUpdateNotifyTemplate' }) readonly vamoosUpdateNotifyTemplateId: EmailTemplate | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'vamoosAlertTemplate' }) readonly vamoosAlertTemplateId: EmailTemplate | null;

    @Fk({ to: 'SmsTemplate', relatedName: 'relatedDomainDetail' }) readonly primarySmsTemplateId: SmsTemplate | null;

    @Fk({ to: 'SmsTemplate', relatedName: 'vamoosSmsInitialTemplateId' }) readonly vamoosSmsInitialTemplateId: SmsTemplate | null;

    @Fk({ to: 'SmsTemplate', relatedName: 'vamoosSmsUpdateNotifyTemplateId' }) readonly vamoosSmsUpdateNotifyTemplateId: SmsTemplate | null;

    @Fk({ to: 'Questionnaire', relatedName: 'relatedDomainDetail' }) readonly primaryQuestionnaireTemplateId: Questionnaire | null;
}
