import { INotificationModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'notification'
})
export class NotificationStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_IMPORT_PIPELINES: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: NotificationStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: INotificationModuleState['searchPageInitialized']): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: INotificationModuleState['searchPageCollapsed']): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(payload: INotificationModuleState['searchPageLock']): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: NotificationStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<number>): Action {
        return { type: NotificationStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static importPipelinesOnSearchPage(payload: Array<any>): Action {
        return { type: NotificationStory.SEARCH_PAGE_IMPORT_PIPELINES, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: NotificationStory.SEARCH_PAGE_SUBMIT, payload };
    }
}
