import { ISaveState } from '@aclass/admin/managers/form.manager';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';
import { BaseRootStory } from '@aclass/core/storage/actions/base.root.story';

@Story({
    name: 'root'
})
export class RootStory extends BaseRootStory {

    @Task() static RESET_FORM: string;

    @Task() static AFTER_SAVE_FORM: string;

    static resetForm(formName: string = ''): Action {
        return { type: RootStory.RESET_FORM, payload: formName };
    }

    static afterSaveForm(v: ISaveState): Action {
        return { type: RootStory.AFTER_SAVE_FORM, payload: v };
    }
}
