import { AppStory } from '@aclass/admin/storage/actions';
import { IAdminState, IAppModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-login-base',
    templateUrl: './login-base.component.html',
})
export class LoginBaseComponent implements OnInit, OnDestroy {

    applications: Array<string>;

    ff: ReactiveFormFactory;

    private _loading: boolean;

    private _msg: string;

    private subscriptions: Array<Subscription> = [];

    get loading(): boolean {
        return this._loading;
    }

    get msg(): string {
        return this._msg;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.applications = ['ao', 'cms'];
        this._loading = false;
        this._msg = '';
        this.ff = new ReactiveFormFactory({
            name: 'Credentials',
            attach: ['appModule', 'loginPageForm'],
            controls: {
                application: new FormControl(this.applications[0]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                password: new FormControl(null, [Validators.required]),
            }
        });
        this.subscriptions.push(
            this.ngRdx.select<IAppModuleState['loginLock']>(['appModule', 'loginLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._loading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IAppModuleState['loginPageMessage']>(['appModule', 'loginPageMessage'])
                .subscribe(v => this._msg = v)
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() login = () => AppStory.login();

    @dispatch() hideError = () => AppStory.hideError();
}
