import { Combination, ICombinationData } from '@aclass/admin/storage/models/combination';
import { IPCCombinationData, PCCombination } from '@aclass/admin/storage/models/pc.combination';
import { Quote } from '@aclass/admin/storage/models/quote';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ICombinationRevisionData {

    readonly id: string | null;

    readonly name: string | null;

    readonly pcCombinationId: IPCCombinationData['id'] | null;

    readonly combinationId: ICombinationData['id'] | null;
}

@Model({
    name: 'CombinationRevision'
})
export class CombinationRevision extends Entity<ICombinationRevisionData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Fk({ to: 'PCCombination', relatedName: 'relatedCombinationRevisions' }) readonly pcCombinationId: PCCombination | null;

    @Fk({ to: 'Combination', relatedName: 'combinationRevisions' }) readonly combinationId: Combination | null;

    readonly relatedQuotes: QuerySet<Quote>;

    readonly relatedTours: QuerySet<TourRevision>;
}

