import { IOrderData, Order } from '@aclass/core/rest/order';
import { IPaginationData, Pagination } from '@aclass/core/rest/pagination';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';

export class ClrDatagridTransformer {

    static toNextPaginationState(clrState: ClrDatagridStateInterface, state: Pagination): IPaginationData | null {
        if (!('page' in clrState)) {
            return null;
        }
        const clrSt = clrState.page;
        const nextState: IPaginationData = {
            pageSize: clrSt.size,
            totalCount: state.totalCount,
            page: clrSt.from ? Math.ceil(clrSt.from / clrSt.size) : 0
        };
        if (nextState.pageSize === state.pageSize && nextState.page === state.page) {
            return null;
        }

        return nextState;
    }

    static toNextOrderState(clrState: ClrDatagridStateInterface, state: Order): IOrderData | null {
        if (!('sort' in clrState)) {
            return null;
        }
        const clrSt: any = clrState.sort;
        const nextState: IOrderData = {
            by: clrSt.by,
            isReverse: clrSt.reverse
        };

        if (nextState.by === state.by && nextState.isReverse === state.isReverse) {
            return null;
        }

        return nextState;
    }

    /**
     * Prepares data for clr component
     * @deprecated
     */
    static isOrderedBy(state?: IOrderData, by?: string): ClrDatagridSortOrder {
        if (!state || state.by !== by) {
            return ClrDatagridSortOrder.UNSORTED;
        }

        return state.isReverse ? ClrDatagridSortOrder.DESC : ClrDatagridSortOrder.ASC;
    }
}
