import { Document } from '@aclass/admin/storage/models/document';
import { OfflineBooking } from '@aclass/admin/storage/models/offline.booking';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { IModelData } from '@aclass/core/storage/models/model';
import { Model as Entity } from 'redux-orm';

interface IBaseDocumentOfflineBookingMapping {

    documentId: string | null;

    bookingId: string | null;
}

export type IDocumentOfflineBookingMapping = IModelData<IBaseDocumentOfflineBookingMapping>;

@Model({
    name: 'DocumentOfflineBookingMapping',
    idAttribute: 'documentId'
})
export class DocumentOfflineBookingMapping extends Entity<IDocumentOfflineBookingMapping> {

    @Fk({ to: 'Document', relatedName: 'relatedDocumentOfflineBookingMappings' }) readonly documentId: Document | null;

    @Fk({ to: 'OfflineBooking', relatedName: 'relatedDocumentOfflineBookingMappings' }) readonly bookingId: OfflineBooking | null;

}
