import {
    TransformerTourRevision
} from '@aclass/admin/components';
import {
    CampaignStory,
    ExtraPricesStory,
    OrmStory,
    RootStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IExtraPriceData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { getFormattedToursDestinationsCombinations } from '@aclass/admin/utils';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const extraPricesModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(ExtraPricesStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.extraPricesModule.get('searchPageInitialized')),
        switchMap(() => [
            ExtraPricesStory.submitOnSearchPage(false),
            ExtraPricesStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(ExtraPricesStory.SEARCH_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(() => {
            const module = state.value.extraPricesModule;
            const form = module.get('searchPageForm').toJS();
            let namesRq: DataSearchRqData;
            const requests = [];
            namesRq = {
                where: {
                    brands: form.brands,
                    locales: form.locales,
                },
                pagination: new Pagination().all()
            };
            requests.push(http.post(`extra-price/destination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`extra-price/tour-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`extra-price/combination-names`, namesRq, { observe: 'response' }));

            return zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`extra-price/destination-names`)) {
                            actions.push(ExtraPricesStory.updateFilterDestinationsOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`extra-price/tour-names`)) {
                            actions.push(ExtraPricesStory.updateFilterToursOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`extra-price/combination-names`)) {
                            actions.push(ExtraPricesStory.updateFilterCombinationsOnSearchPage(rs.body.results));
                        }
                    });

                    return actions;
                })
            );
        })
    ),
    action.pipe(
        ofType(ExtraPricesStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.extraPricesModule;
            let queue = of(ExtraPricesStory.updateSearchLockOnSearchPage(true));
            const requests = [];
            let rq: DataSearchRqData;
            let namesRq: DataSearchRqData;

            if (payload) {
                queue = concat(queue, of(RootStory.resetForm()));
                rq = {
                    pagination: module.get('searchPagePagination'),
                    order: module.get('searchPageOrder'),
                };
            } else {
                const form = module.get('searchPageForm').toJS();
                const brands = form.brands;
                const locales = form.locales;
                const status: string = form.status ? form.status : 'live';
                const showLive = status === 'live';
                const showPast = status === 'past';
                const dateStart = form.date ? form.date.from : null;
                const dateEnd = form.date ? form.date.to : null;
                const createdStart = form.createDate ? form.createDate.from : null;
                const createdEnd = form.createDate ? form.createDate.to : null;
                const departureStart = form.departureDate ? form.departureDate.from : null;
                const departureEnd = form.departureDate ? form.departureDate.to : null;
                const destinations = form.destinations ? form.destinations : null;
                const tours = form.tours ? form.tours : null;
                const combinations = form.combinations ? form.combinations : null;
                rq = {
                    where: {
                        name: form.name,
                        createdStart: createdStart,
                        createdEnd: createdEnd,
                        dateStart: dateStart,
                        dateEnd: dateEnd,
                        departureStart: departureStart,
                        departureEnd: departureEnd,
                        brands,
                        locales,
                        destinations: destinations,
                        tours: tours,
                        combinations: combinations,
                        showLive: showLive,
                        showPast: showPast,
                    },
                    pagination: module.get('searchPagePagination'),
                    order: module.get('searchPageOrder'),
                };
                namesRq = {
                    where: {
                        brands,
                        locales,
                    },
                    pagination: new Pagination().all()
                };
            }
            requests.push(http.post(`extra-price/destination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`extra-price/tour-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`extra-price/combination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`extra-price/search`, rq, { observe: 'response' }));

            return concat(queue, zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [
                        ExtraPricesStory.importRecordsOnSearchPage([])
                    ];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`extra-price/destination-names`)) {
                            actions.push(ExtraPricesStory.updateDestinationsOnSearchPage(rs.body.results));
                            actions.push(ExtraPricesStory.updateFilterDestinationsOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`extra-price/tour-names`)) {
                            actions.push(ExtraPricesStory.updateToursOnSearchPage(rs.body.results));
                            actions.push(ExtraPricesStory.updateFilterToursOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`extra-price/combination-names`)) {
                            actions.push(ExtraPricesStory.updateCombinationsOnSearchPage(rs.body.results));
                            actions.push(ExtraPricesStory.updateFilterCombinationsOnSearchPage(rs.body.results));
                        } else if (response.url.includes('extra-price/search')) {
                            actions.push(OrmStory.populateExtraPrices(rs.body.results));
                            actions.push(ExtraPricesStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)));
                            actions.push(ExtraPricesStory.updatePaginationOnSearchPage(rs.body.pagination));
                            actions.push(ExtraPricesStory.updateOrderOnSearchPage(rs.body.order));
                            actions.push(ExtraPricesStory.updateSearchLockOnSearchPage(false));
                        }
                    });

                    return actions;
                })
            ));
        }),
    ),
    action.pipe(
        ofType(ExtraPricesStory.CREATE_PAGE_DRY_RUN),
        mergeMap(() => {
            return [
                ExtraPricesStory.updateFormCreatePage({ }),
                ExtraPricesStory.updateCreatePageInitState(true)
            ];
        }),
    ),
    action.pipe(
        ofType(ExtraPricesStory.CREATE_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(() => {
            const module = state.value.extraPricesModule;
            const form = module.get('createPageForm').toJS();
            const brand = form.brand;
            const locale = form.locale;
            const brandLabel = form.brandLabel;
            const params = instanceManager.buildHeaders([brand, locale, brandLabel]);

            return concat(of(CampaignStory.updateSearchLockOnCreatePage(true)), http.get(`extra-price/tour-revisions`, params).pipe(
                dispatchActions((rs: Response<Array<TransformerTourRevision>>) => {
                    const values = getFormattedToursDestinationsCombinations(rs);

                    return [
                        OrmStory.populateTourRevisions(values.tourRevisions),
                        OrmStory.populateTours(values.tours),
                        OrmStory.populateDestinationRevisions(values.groupedDestinationRevisions),
                        OrmStory.populateDestinations(values.destinations),
                        OrmStory.populateCombinationRevisions(values.groupedCombinationRevisions),
                        OrmStory.populateCombinations(values.combinations),
                        ExtraPricesStory.importToursOnCreatePage(values.tourRevisionIds),
                        ExtraPricesStory.importDestinationsOnCreatePage(values.destinationRevisionIds),
                        ExtraPricesStory.importCombinationsOnCreatePage(values.combinationRevisionIds),
                        ExtraPricesStory.importDestinationsMappingsOnCreatePage(values.destinationMapping),
                        ExtraPricesStory.importCombinationsMappingOnCreatePage(values.combinationMapping),
                        ExtraPricesStory.updateSearchLockOnCreatePage(false)
                    ];
                })
            ));
        })
    ),
    action.pipe(
        ofType(ExtraPricesStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
            const form = state.value.extraPricesModule.get('createPageForm').toJS();
            const paxesForm: string = form.numberOfPax;
            const [minPax, maxPax] = paxesForm.split('-');
            const extraPrice = {
                name: form.label,
                departureStart: form.departureRange ? form.departureRange.from : null,
                departureEnd: form.departureRange ? form.departureRange.to : null,
                value: parseInt(form.value),
                minPax: parseInt(minPax),
                maxPax: parseInt(maxPax ? maxPax : minPax),
                startedAt: form.dateRange ? form.dateRange.from : null,
                endedAt: form.dateRange ? form.dateRange.to : null,
                allTours: !!form.allTours,
            };
            const getCleanIds = (ids: Array<string> | null): Array<any> => {
                const cleanIds = [];
                ids.forEach(v => {
                    const [, , id] = instanceManager.parseId(v);
                    cleanIds.push(id);
                });

                return cleanIds;
            };

            const tours = form.tours ? getCleanIds(form.tours) : null;
            const destinations = form.destinations ? getCleanIds(form.destinations) : null;
            const combinations = form.combinations ? getCleanIds(form.combinations) : null;
            const result = {
                extraPrice: extraPrice,
                desLevel: destinations,
                tourLevel: tours,
                combLevel: combinations,
                locale: form.locale,
                brand: form.brand,
                brandLabel: form.brandLabel,
            };

            return concat(of(ExtraPricesStory.updateSaveLockCreatePage(true)), http.post('extra-price', result).pipe(
                dispatchActions(
                    (rs: Response<IExtraPriceData>) => {
                        return [
                            ExtraPricesStory.updateSaveLockCreatePage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', `Extra price created`)),
                            OrmStory.populateExtraPrices([rs.body]),
                            RootStory.resetForm(),
                            ExtraPricesStory.updateSearchPageInitState(false),
                            ExtraPricesStory.updateCreatePageInitState(false),
                            SystemStory.navigate(['extra-prices'], { sharedLink: true })
                        ];
                    },
                    () => [
                        ExtraPricesStory.updateSaveLockCreatePage(false),
                        SystemStory.showNotification(SystemNotification.error('Error', `Error`)),
                    ]
                ),
            ));
        }),
    ),
    action.pipe(
        ofType(ExtraPricesStory.EDIT_PAGE_REMOVE_RECORD),
        mergeMap(() => {
            const module = state.value.extraPricesModule;
            const id = module.get('editPageRecordId');

            return http.delete(`extra-price/${id}`).pipe(
                dispatchActions(() => {
                    return [
                        ExtraPricesStory.importRecordsOnSearchPage([]),
                        RootStory.resetForm(),
                        SystemStory.showNotification(SystemNotification.success('Success', `Extra price removed.`)),
                        ExtraPricesStory.updateSearchPageInitState(false),
                        OrmStory.dropExtraPrices([id]),
                        ExtraPricesStory.updateRemoveModalLockOnEditPage(false),
                        ExtraPricesStory.updateShowRemoveModalOnEditPage(false),
                        SystemStory.navigate(['extra-prices'], { replaceUrl: true, sharedLink: true }),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(ExtraPricesStory.EDIT_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(({ payload }: Action) => {
            const [brand, locale, id] = payload;
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(ExtraPricesStory.updateSearchLockOnEditPage(true)), http.get(`extra-price/tour-revisions`, params).pipe(
                dispatchActions((rs: Response<Array<TransformerTourRevision>>) => {
                    const values = getFormattedToursDestinationsCombinations(rs);

                    const actions = [
                        OrmStory.populateTourRevisions(values.tourRevisions),
                        OrmStory.populateTours(values.tours),
                        OrmStory.populateDestinationRevisions(values.groupedDestinationRevisions),
                        OrmStory.populateDestinations(values.destinations),
                        OrmStory.populateCombinationRevisions(values.groupedCombinationRevisions),
                        OrmStory.populateCombinations(values.combinations),
                        ExtraPricesStory.importCombinationsOnEditPage(values.combinationRevisionIds),
                        ExtraPricesStory.importDestinationsMappingsOnEditPage(values.destinationMapping),
                        ExtraPricesStory.importCombinationsMappingOnEditPage(values.combinationMapping),
                        ExtraPricesStory.importToursOnEditPage(values.tourRevisionIds),
                        ExtraPricesStory.importDestinationsOnEditPage(values.destinationRevisionIds),
                    ];
                    if (id) {
                        actions.push(ExtraPricesStory.updateRecordIdEditPage(id));
                    }
                    actions.push(ExtraPricesStory.updateSearchLockOnEditPage(false));

                    return actions;
                })
            ));
        })
    ),
    action.pipe(
        ofType(ExtraPricesStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<number>) => state.value.extraPricesModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<number>) => {
            let rq: DataSearchRqData;
            rq = {
                where: {
                    id: payload,
                },
                pagination: new Pagination().all()
            };

            return http.post(`extra-price/search`, rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IExtraPriceData>>>) => {
                    const actions = [
                        ExtraPricesStory.updateCreatePageInitState(true),
                        ExtraPricesStory.updateFormOnEditPage({ }),
                    ];
                    const record = rs.body.results[0];
                    if (!record.allTours) {
                        actions.push(ExtraPricesStory.updateToursAndDestinationsOnEditPage([record.brand, record.locale, payload]));
                    }
                    if (record.allTours) {
                        actions.push(ExtraPricesStory.updateRecordIdEditPage(payload));
                    }
                    actions.push(OrmStory.populateExtraPrices(rs.body.results));
                    actions.push(ExtraPricesStory.updateFormValueOnEditPage(record));

                    return actions;
                })
            );
        })
    ),
    action.pipe(
        ofType(ExtraPricesStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const form = state.value.extraPricesModule.get('editPageForm').toJS();
            const id = state.value.extraPricesModule.get('editPageRecordId');
            const paxesForm: string = form.numberOfPax;
            const [minPax, maxPax] = paxesForm.split('-');
            const extraPrice = {
                name: form.label,
                departureStart: form.departureRange ? form.departureRange.from : null,
                departureEnd: form.departureRange ? form.departureRange.to : null,
                value: parseInt(form.value),
                minPax: parseInt(minPax),
                maxPax: parseInt(maxPax ? maxPax : minPax),
                startedAt: form.dateRange ? form.dateRange.from : null,
                endedAt: form.dateRange ? form.dateRange.to : null,
                allTours: !!form.allTours,
                autobinding: !!form.autobinding,
            };
            const getCleanIds = (ids: Array<string> | null): Array<any> => {
                const cleanIds = [];
                ids.forEach(v => {
                    const [, , cleanId] = instanceManager.parseId(v);
                    cleanIds.push(cleanId);
                });

                return cleanIds;
            };
            const tours = form.tours ? getCleanIds(form.tours) : null;
            const destinations = form.destinations ? getCleanIds(form.destinations) : null;
            const combinations = form.combinations ? getCleanIds(form.combinations) : null;
            const result = {
                extraPrice: extraPrice,
                desLevel: destinations,
                tourLevel: tours,
                combLevel: combinations,
                locale: form.locale,
                brand: form.brand,
                brandLabel: form.brandLabel
            };

            return concat(of(ExtraPricesStory.updateSaveLockCreatePage(true)), http.put(`extra-price/${id}`, result).pipe(
                dispatchActions(
                    (rs: Response<IExtraPriceData>) => {
                        return [
                            ExtraPricesStory.updateSaveLockCreatePage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', `Extra price updated`)),
                            OrmStory.populateExtraPrices([rs.body]),
                            RootStory.resetForm(),
                            ExtraPricesStory.updateSearchPageInitState(false)
                        ];
                    },
                    () => [
                        ExtraPricesStory.updateSaveLockCreatePage(false),
                    ]
                ),
            ));
        }),
    )
);
