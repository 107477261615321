import { Map, Record } from 'immutable';

export const defaultPageSizes = [
    10,
    20,
    50,
    100
];

export interface IPaginationData {
    /**
     * Number of items per page.
     */
    pageSize: number;

    /**
     * Total number of items.
     */
    totalCount: number;

    /**
     * Current page.
     */
    page: number;
}

/**
 * Pagination represents information relevant to pagination of data items.
 */
export class Pagination extends Record({ pageSize: defaultPageSizes[1], totalCount: 0, page: 0 }) {

    /**
     * Helper to get available page sizes
     */
    static readonly pageSizes = defaultPageSizes;

    pageSize: number;

    totalCount: number;

    page: number;

    get pageCount(): number {
        return this.totalCount ? Math.floor(this.totalCount / this.pageSize) : 0;
    }

    constructor(config?: Partial<IPaginationData> | Map<string, any> | null) {
        super(config);
    }

    with(values: any): Pagination {
        return this.merge(values) as this;
    }

    /**
     * Checks that page is not last
     */
    hasNext(): boolean {
        return this.page < this.pageCount;
    }

    /**
     * Increase page number if it available
     */
    next(): Pagination {
        return this.with(this.hasNext() ? { page: this.page + 1 } : { });
    }

    /**
     * Setup pagination to return all record from back-end
     */
    all(): Pagination {
        return this.with({ pageSize: 0 });
    }
}
