import { ISwitchButtonData } from '@aclass/admin/components';
import { IAdminState } from '@aclass/admin/storage/states';
import { Instance } from '@aclass/core/base/instance';
import { isList } from '@aclass/core/helpers/immutable';
import { BaseInstanceManager } from '@aclass/core/managers/base.instance.manager';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { List } from 'immutable';

type input = Array<string> | List<string> | string;

/**
 * This is bridge between redux store and reactive forms
 */
@Injectable()
export class InstanceManager extends BaseInstanceManager {

    constructor(
        protected ngRdx: NgRedux<IAdminState>,
    ) {
        super();
        this.init(ngRdx);
    }

    /**
     * Prepares brands for switch button
     * By default all is non active
     *
     * @param onlyFor Specify locales for brand's filter
     */
    composeBrandsForSwitchButton(onlyFor?: input): Array<ISwitchButtonData<Instance['brandLabel']>> {
        let composedBrands = this.composeSwitchButton(this.getBrandsByLocale(onlyFor));
        composedBrands = composedBrands.map(item => ({
            title: this.findFirstByBrand(item.title).brandLabel,
            value: item.value
        }));

        return composedBrands;
    }

    /**
     * Prepares brands for switch button
     * By default all is non active
     *
     * @param onlyFor Specify brand for locale's filter
     */
    composeLocalesForSwitchButton(onlyFor?: input): Array<ISwitchButtonData<Instance['locale']>> {
        return this.composeSwitchButton(this.getLocalesByBrand(onlyFor));
    }

    /**
     * Collapse instance list to count locales
     * @param instanceList
     */
    groupInstancesByLocale(instanceList: Instance[]): { value: Instance[], key: string}[] {
        const grouped: Array<Instance[]> = [];
        for (const i of instanceList) {
            if (typeof grouped[i.locale] === 'undefined') {
                grouped[i.locale] = [];
            }
            grouped[i.locale].push(i);
        }

        return Object.keys(grouped).map(key => ({ key, value: grouped[key] }));
    }

    private composeSwitchButton(records: Array<string> | List<string>): Array<ISwitchButtonData<string>> {
        if (isList<string>(records)) {
            records = records.toArray();
        }

        return (<Array<string>>records).map(r => ({ title: r, value: r }));
    }
}
