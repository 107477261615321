import { InstanceManager } from '@aclass/admin/managers';
import { InternationalizationStory } from '@aclass/admin/storage/actions';
import { SourceMessage, TranslationMessage, } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { findById } from '@aclass/core/helpers/orm';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'adm-internationalization-show',
    templateUrl: './internationalization-show.component.html'
})
export class InternationalizationShowComponent implements OnInit {

    ff: ReactiveFormFactory;

    record: SourceMessage;
    messages: Array<TranslationMessage>;

    private _controls: Array<string> = [];

    get module() {
        return this.ngRdx.getState().internationalizationModule;
    }

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    get controls(): Array<string> {
        return this._controls;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private instanceManager: InstanceManager,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.record = findById<SourceMessage>(this.session, SourceMessage, this.module.get('showPageRecordId'));
        this.messages = this.record.relatedTranslatedMessages.all().toModelArray();
        const controls = this.instanceManager.locales.toArray().reduce((prev, curr) => {
            const tmp = this.messages.find(r => r.locale === curr);
            prev[curr] = new FormControl(tmp ? tmp.message : null);

            return prev;
        }, { });
        this._controls = Object.keys(controls);
        this.ff = new ReactiveFormFactory({
            name: 'Internationalization',
            attach: ['internationalizationModule', 'showPageForm'],
            controls: controls,
            controlNames: {
                de: 'Content',
                dk: 'Brand',
                nl: 'Locale',
                no: 'Locale',
                se: 'Locale',
                uk: 'Locale',
            }
        });
    }

    runSave = () => {

        this.save();
        this.ff.form.markAsPristine();
    }

    @dispatch() save = () => InternationalizationStory.submitOnShowPage();
}
