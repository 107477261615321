import { DomainDetailStory } from '@aclass/admin/storage/actions';
import {
    IDomainDetailModuleState,
    INITIAL_DOMAIN_DETAIL_STATE
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const domainDetailModuleReducer = (state: TypedState<IDomainDetailModuleState> = INITIAL_DOMAIN_DETAIL_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case DomainDetailStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case DomainDetailStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case DomainDetailStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case DomainDetailStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case DomainDetailStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case DomainDetailStory.SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case DomainDetailStory.SEARCH_PAGE_UPDATE_MANAGER:
            return state.update('searchUsers', () => List(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_DOMAIN_DETAIL_ID:
            return state.update('editPageRecordId', () => payload);
        case DomainDetailStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_USER_SEARCH_LOCK:
            return state.update('editPageUserSearchLock', () => payload);
        case DomainDetailStory.EDIT_PAGE_UPDATE_USERS:
            return state.update('editPageUsers', () => List(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_EMAIL_TEMPLATE_SEARCH_LOCK:
            return state.update('editPageEmailTemplateSearchLock', () => payload);
        case DomainDetailStory.EDIT_PAGE_UPDATE_EMAIL_TEMPLATES:
            return state.update('editPageEmailTemplates', () => List(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_SMS_TEMPLATE_SEARCH_LOCK:
            return state.update('editPageSmsTemplateSearchLock', () => payload);
        case DomainDetailStory.EDIT_PAGE_UPDATE_SMS_TEMPLATES:
            return state.update('editPageSmsTemplates', () => List(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATES:
            return state.update('editPageQuestionnairesTemplates', () => List(payload));
        case DomainDetailStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATE_SEARCH_LOCK:
            return state.update('editPageSmsTemplateSearchLock', () => payload);
        default:
            return state;
    }
};
