import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { fromEvent, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export interface IFileData {
    /**
     * The content of file
     */
    content: string;

    /**
     * The source name
     */
    name: string;
}

export function readToBase64(file: File): Observable<IFileData> {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);

    return fromEvent<ProgressEvent>(reader, 'load').pipe(
        // @ts-ignore
        map((e: ProgressEvent) => ({ content: e.target.result, name: file.name })),
        first(),
    );
}

export function tryParseFile(rs: HttpResponse<string>): [Blob?, string?] {
    if (!rs.headers.has('Content-Disposition') || !rs.headers.get('Content-Disposition').includes('attachment')) {
        return [];
    }
    const contentType = rs.headers.get('Content-Type'),
        sliceSize = 512,
        byteCharacters = atob(rs.body),
        fileName = rs.headers.get('Content-Disposition').match(/filename="(.+?)"/)[1];
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
    }

    return [new Blob(byteArrays, { type: contentType }), fileName];
}

export function tryToSaveFile(rs: HttpResponse<string>): void {
    const [file, name] = tryParseFile(rs);
    if (!file) {
        return;
    }

    saveAs(file, name);
}
