import { Calculator } from '@aclass/core/base/calculator';
import { forwardRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidator, ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';

@Component({
    selector: 'adm-number-input',
    template: `
        <input #ni type="text" class="b-number-input__control form-control" [class.form-control-sm]="sm" [class.disabled]="disabled" (blur)="runUpdateView()" (input)="runUpdate()" [attr.placeholder]="placeholder" [readonly]="readonly">
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        }
    ],
})
export class NumberInputComponent implements OnInit, ControlValueAccessor, AsyncValidator {

    static REGEX_NUMBER: RegExp = /^[+-]?\d+$/;

    static REGEX_FLOAT: RegExp = /^[+-]?([0-9]*[.])?[0-9]+$/;

    @HostBinding('class.b-number-input') yes = true;

    @ViewChild('ni', { static: true }) input: ElementRef<HTMLInputElement>;

    /**
     * Watched value
     */
    @Input() set value(v) {
        this._v = Calculator.isNumeric(v) ? parseFloat(v) : v;
        this.runUpdateView(true);
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<number | null> = new EventEmitter<number | null>();

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Placeholder text
     */
    @Input() placeholder = '';

    /**
     * Min value
     */
    @Input() min: number | null = null;

    /**
     * Max value
     */
    @Input() max: number | null = null;

    @Input() decimals = false;

    @Input() readonly = false;

    private _v;

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    get dirtyValue(): string {
        return this.input.nativeElement.value.trim().replace(',', '.');
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: number | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v: number | null) {
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: number | null) => void = () => { };

    /**
     * @inheritDoc
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const value = this.dirtyValue;
        if (!value || this._disabled) {
            return of(null);
        }
        const regex = this.decimals ? NumberInputComponent.REGEX_FLOAT : NumberInputComponent.REGEX_NUMBER;
        if (!regex.test(value)) {
            return of({ range: 'Please enter numerical value' });
        }
        const v = parseFloat(value);
        const error: { [key: string]: any } = { };
        if (this.min !== null && this.min > v) {
            error['min'] = { min: this.min, actual: v };
        }
        if (this.max !== null && this.max < v) {
            error['max'] = { max: this.max, actual: v };
        }

        return of(Object.keys(error) ? error : null);
    }

    /**
     * Emits changes
     */
    runUpdate() {
        if (this.disabled || this.readonly) {
            return;
        }
        const value = this.dirtyValue;
        const regex = this.decimals ? NumberInputComponent.REGEX_FLOAT : NumberInputComponent.REGEX_NUMBER;
        // Wait until value full entered
        if (value && (value.endsWith('.') || !regex.test(value))) {
            return;
        }
        this._v = value ? parseFloat(value) : null;
        this.onChangeCallback(this._v);
        this.valueChange.emit(this._v);
    }

    /**
     * If start and end same, hack view to show one value
     */
    runUpdateView(hard: boolean = false) {
        if (this._v === null) {
            if (hard) {
                this.input.nativeElement.value = '';
            }

            return;
        }
        this.input.nativeElement.value = String(this._v);
    }
}
