import { FileStory, OrmStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { Image, IImageData } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { readToBase64, IFileData } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

export const fileModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(FileStory.IMAGE_GALLERY_SELECT),
        switchMap(({ payload }: Action<number | null>) => {
            if (payload === null) {
                return [
                    FileStory.updateSelectedImageGallery(payload),
                    RootStory.resetForm(),
                ];
            }

            const image: Image = findById<Image>(orm.session(state.value.orm), Image, payload);

            return [
                FileStory.updateSelectedImageGallery(image.id),
                FileStory.updateEditFormImageGallery({ id: image.id, alt: image.alt, title: image.title }),
            ];
        }),
    ),
    action.pipe(
        ofType(FileStory.IMAGE_GALLERY_SEARCH),
        mergeMap(() => {
            const module = state.value.fileModule;
            const rq: DataSearchRqData = {
                where: { },
                pagination: module.get('searchPaginationImageGallery'),
                order: { by: 'id', isReverse: true }
            };

            return concat(of(FileStory.updateSearchLockImageGallery(true)), http.post('media/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IImageData>>>) => [
                    OrmStory.populateImages(rs.body.results),
                    FileStory.importRecordsImageGallery(rs.body.results.map(r => r.id)),
                    FileStory.updatePaginationImageGallery(rs.body.pagination),
                    FileStory.updateSearchLockImageGallery(false),
                ]),
            ));
        }),
    ),
    action.pipe(
        ofType(FileStory.IMAGE_GALLERY_UPLOAD),
        switchMap(({ payload }: Action<Array<File>>) => {
            const actions = [];
            const invalid: Array<string> = [];
            const files = payload.filter(f => {
                if (/^image/.test(f.type)) {
                    return true;
                }
                invalid.push(`"${f.name}"`);

                return false;
            });
            if (invalid.length) {
                actions.push(SystemStory.showNotification(
                    SystemNotification.warning('Warning', invalid.join(', ') + (invalid.length > 1 ? ' are not images' : ' is not an image')),
                ));
            }
            if (!files.length) {
                return actions;
            }

            return concat(of(FileStory.updateUploadLockImageGallery(true)), zip(...files.map(readToBase64)).pipe(
                switchMap((results: Array<IFileData>) => zip(...results.map(rq => http.post('media', rq))).pipe(
                    switchMap((responses: Array<Response<IImageData>>) => {
                        const images: Array<IImageData> = responses.filter(rs => rs.success).map((rs: Response<IImageData>) => rs.body);

                        return actions.concat([
                            FileStory.updateUploadLockImageGallery(false),
                            OrmStory.populateImages(images),
                            FileStory.importRecordsImageGallery(images.map(r => r.id))
                        ]);
                    })
                )),
            ));
        }),
    ),
    action.pipe(
        ofType(FileStory.IMAGE_GALLERY_EDIT_FORM_SUBMIT),
        mergeMap(() => {
            const id: number = state.value.fileModule.getIn(['editFormImageGallery', 'id']);
            const rq = {
                alt: state.value.fileModule.getIn(['editFormImageGallery', 'alt']),
                title: state.value.fileModule.getIn(['editFormImageGallery', 'title'])
            };

            return concat(of(FileStory.updateEditLockImageGallery(true)), http.put(`media/${id}`, rq).pipe(
                dispatchActions(((rs: Response<IImageData>) => [
                    SystemStory.showNotification(SystemNotification.success('Success', 'Image updated')),
                    FileStory.updateEditLockImageGallery(false),
                    OrmStory.populateImages([rs.body]),
                ])
            )));
        }),
    ),
    action.pipe(
        ofType(FileStory.IMAGE_GALLERY_REMOVE),
        mergeMap(({ payload }: Action<number>) => concat(of(FileStory.updateEditLockImageGallery(true)), http.delete(`media/${payload}`).pipe(
            dispatchActions(() => {
                return [
                    FileStory.updateEditLockImageGallery(false),
                    FileStory.updateSelectedImageGallery(null),
                    FileStory.removeRecordImageGallery(payload),
                    OrmStory.dropImages([payload]),
                    RootStory.resetForm(),
                    SystemStory.showNotification(SystemNotification.success('Success', 'Image removed'))
                ];
            })
        ))),
    ),
);
