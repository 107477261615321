import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';

/**
 * Please note that this state shared some data between ao and cms
 */
export interface ITemplateModuleState extends IModuleState {
    /**
     * Collapsible menu status
     */
    menuCollapsed: boolean | null;
}

export const INITIAL_TEMPLATE_STATE = createTypedState<ITemplateModuleState>({
    menuCollapsed: null,
});
