/**
 * Define abstract class for obtaining reference to the global window object.
 */
export abstract class WindowRef {

    get nativeWindow(): Window | Object {
        throw new Error('Not implemented.');
    }

}
