import { List, Map, Record } from 'immutable';

export interface IIdentityData {

    id: number;

    username: string;

    email: string;

    token: string;

    role: string;

    permissions: Array<string>;
}

/**
 * Identity is the class for the `user` application component that manages the user authentication status.
 */
export class Identity extends Record({ id: null, username: null, email: null, token: null, role: null, permissions: List([]) }) {

    id: number;

    username: string;

    email: string;

    token: string;

    role: string;

    permissions: List<string>;

    constructor(config?: Partial<IIdentityData> | Map<string, any> | null) {
        super(config);
    }

    with(values: any): Identity {
        return this.merge(values) as this;
    }

    hasPermission(name: string): boolean {
        return this.permissions.includes(name);
    }
}
