import { TransformerTourRevision } from '@aclass/admin/components';
import {
    ICombinationData,
    ICombinationRevisionData,
    IDestinationData,
    IDestinationRevisionData,
    ITourData,
    ITourRevisionData, TourRevision,
} from '@aclass/admin/storage/models';
import { Response } from '@aclass/core/rest/response';

interface FormattedToursDestinationsCombinations {
    tourRevisions: Array<ITourRevisionData>;
    tours: Array<ITourData>;
    groupedDestinationRevisions: Array<IDestinationRevisionData>;
    destinations: Array<IDestinationData>;
    groupedCombinationRevisions: Array<ICombinationRevisionData>;
    combinations: Array<ICombinationData>;
    tourRevisionIds: Array<string>;
    destinationRevisionIds: Array<string>;
    combinationRevisionIds: Array<string>;
    destinationMapping: {
        [key: string]: Array<IDestinationData['id']>
    };
    combinationMapping: {
        [key: string]: Array<ICombinationData['id']>
    };
}

export function getFormattedToursDestinationsCombinations(rs: Response<Array<TransformerTourRevision>>): FormattedToursDestinationsCombinations {
    const destinationMapping = mapDestinations(rs);
    const combinationMapping = mapCombinations(rs);
    const tourRevisions = rs.body.map(r => r.data);
    const tours = [].concat(...rs.body.map(r => r.includes.tour.data));
    const destinationRevisions = [].concat(...rs.body.map(r => r.includes.destinationRevisions.map(rv => rv.data)));
    const destinationsMap = groupBy(destinationRevisions, destinationRevision => destinationRevision.destinationId);
    const groupedDestinationRevisions = groupDestinations(destinationsMap);
    const destinations = [].concat(...rs.body.map(r => r.includes.destinationRevisions.map(rv => rv.includes.destination.data)));
    const combinationRevisions = [].concat(...rs.body.map(r => r.includes.combinationRevisions.map(rv => rv.data)));
    const combinationsMap = groupBy(combinationRevisions, combinationRevision => combinationRevision.combinationId);
    const groupedCombinationRevisions = groupCombinations(combinationsMap);
    const combinations = [].concat(...rs.body.map(r => r.includes.combinationRevisions.map(rv => rv.includes.combination.data)));
    const tourRevisionIds = Array.from(new Set(tourRevisions.map(r => r.id)));
    const destinationRevisionIds = Array.from(new Set(groupedDestinationRevisions.map(r => r.id)));
    const combinationRevisionIds = Array.from(new Set(groupedCombinationRevisions.map(r => r.id)));

    return {
        tourRevisions,
        tours,
        groupedDestinationRevisions,
        destinations,
        groupedCombinationRevisions,
        combinations,
        tourRevisionIds,
        destinationRevisionIds,
        combinationRevisionIds,
        destinationMapping,
        combinationMapping
    };
}

function mapDestinations(rs: Response<Array<TransformerTourRevision>>): { [key: string]: Array<IDestinationData['id']> } {
    const destinationMapping = { };
    rs.body.forEach(r => {
        const tId = r.data.tourId;
        const dIds = r.includes.destinationRevisions.map(rv => rv.data.destinationId);
        destinationMapping[tId] = tId in destinationMapping ? destinationMapping[tId].concat(dIds) : dIds;
        destinationMapping[tId] = Array.from(new Set(destinationMapping[tId]));
    });

    return destinationMapping;
}

function mapCombinations(rs: Response<Array<TransformerTourRevision>>): { [key: string]: Array<ICombinationData['id']> } {
    const combinationMapping = { };
    rs.body.forEach(r => {
        const tId = r.data.tourId;
        const dIds = r.includes.combinationRevisions.map(rv => rv.data.combinationId);
        combinationMapping[tId] = tId in combinationMapping ? combinationMapping[tId].concat(dIds) : dIds;
        combinationMapping[tId] = Array.from(new Set(combinationMapping[tId]));
    });

    return combinationMapping;
}

function groupBy(list: Array<any>, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}

function groupDestinations(destinationsMap: Map<any, any>): Array<IDestinationRevisionData> {
    const groupedDestinationRevisions = [];
    destinationsMap.forEach((v) => {
        groupedDestinationRevisions.push(v.shift());
    });

    return groupedDestinationRevisions;
}

function groupCombinations(combinationsMap: Map<any, any>): Array<ICombinationRevisionData> {
    const groupedCombinationRevisions = [];
    combinationsMap.forEach((v) => {
        groupedCombinationRevisions.push(v.shift());
    });

    return groupedCombinationRevisions;
}

export function filterTours(pickedDestinations: Array<string>, destinationsMappings: { [key: string]: Array<IDestinationData['id']> }): Array<string> {
    const filteredTours = [];
    pickedDestinations.forEach((destination) => {
        Object.entries(destinationsMappings).filter((entry) => {
            const key = entry[0];
            const value: Array<string> = entry[1];
            value.forEach((v) => {
                if (v === destination) {
                    filteredTours.push(key);
                }
            });
        });
    });

    return filteredTours;
}

export function filterTourRevisions(tours: Array<TourRevision>, filteredTours: Array<string>) {
    const filteredToursRevisionIds = [];
    tours.forEach((tour) => {
        if (!filteredTours.includes(tour.tourId.id)) {
            filteredToursRevisionIds.push(tour.id);
        }
    });

    return filteredToursRevisionIds;
}
