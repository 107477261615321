import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { IEmailMessageLogData } from '@aclass/core/storage/models/email.message.log';
import { Model as Entity } from 'redux-orm';

export type IOpportunityEmailMessageLogData = IEmailMessageLogData & {

    readonly enquireDataId: Opportunity | number | null;

};

@Model({
    name: 'OpportunityEmailMessageLog'
})
export class OpportunityEmailMessageLog extends Entity<IOpportunityEmailMessageLogData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly owner: string | null;

    @Attr() readonly body: string | null;

    @Attr() readonly subject: string | null;

    @Attr() readonly viewed: number | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedEmailLogs' }) readonly enquireDataId: Opportunity | null;
}
