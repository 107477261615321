import { ModalManager } from '@aclass/admin/managers';
import { Image } from '@aclass/admin/storage/models';
import { hashed } from '@aclass/core/helpers/hashed';
import { WINDOW } from '@aclass/core/services/window.service';
import { forwardRef, AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Editor, EditorManager, Settings } from 'tinymce';

@Component({
    selector: 'adm-wysiwyg',
    template: `<textarea id="{{ id }}" class="wysiwyg"></textarea>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WysiwygComponent),
            multi: true
        }
    ]
})
export class WysiwygComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor {

    @Input() id = hashed();

    @Input() settings: Settings | undefined;

    @Input() innerValue = '';

    @Input() set readonly(v: boolean | string) {
        // <adm-wysiwyg readonly></adm-wysiwyg> // v === ''
        this._readonly = typeof v === 'string' && v === '' ? true : !!v;
        if (this.editor && this.editor.initialized) {
            this.editor.setMode(v ? 'readonly' : 'design');
        }
    }

    /**
     * Change event emitter
     */
    @Output() innerValueChange: EventEmitter<string> = new EventEmitter<string>();

    editor: Editor;

    private _readonly = false;

    get value(): string {
        return this.innerValue;
    }

    set value(v: string) {
        if (v === this.innerValue || this._readonly) {
            return;
        }
        this.innerValue = v;
        this.onChangeCallback(v);
        this.innerValueChange.emit(v);
    }

    constructor(
        @Inject(WINDOW) private window: Window,
        private modalManager: ModalManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        if (!this.editor) {
            return;
        }
        this.tinymce.remove(this.editor);
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        const defaults: any = {
            selector: `#${ this.id }`,
            height: 90,
            theme: 'silver',
            skin: false, // disable loading css, it already included in build
            relative_urls: true,
            convert_urls: false,
            min_height: 90,
            max_height: 700,
            verify_html: false,
            entities: '',
            /**
             * @see {@link https://www.tinymce.com/docs/configure/content-filtering/#encodingtypes} Encoding Types
             */
            entity_encoding: 'raw',
            forced_root_block: '',
            content_css: [],
            content_style: 'body { font-family: Open Sans, sans-serif; }',
            formats: {
                strikethrough: { inline: 'del' },
            },
            branding: false,
            setup: (editor: Editor): void => {
                editor.on('init', () => {
                    // We swap textarea and wysiwyg position for a more nice css selector.
                    const el = document.getElementById(this.id);
                    if (el && el.previousElementSibling && el.previousElementSibling.classList.contains('mce-tinymce')) {
                        el.parentNode.insertBefore(el, el.previousSibling);
                    }
                    editor.setContent(this.value);
                    editor.setMode(this._readonly ? 'readonly' : 'design');
                });
                editor.on('ExecCommand Change Paste KeyUp ObjectResized', () => {
                    this.value = editor.getContent();
                });
            },
            file_picker_types: 'image',
            file_picker_callback: (callback: (filename: string, metadata: { }) => void, value: string, meta: { filetype: string }) => {
                if (meta.filetype !== 'image') {
                    return;
                }
                this.modalManager.pickImage({ hideBackground: true })
                    .pipe(filter((image: Image | undefined) => typeof image !== 'undefined'))
                    .subscribe((image: Image | undefined) => callback(image.relativeUrl, { alt: image.alt }));
            },
            plugins: [
                'advlist',
                'autolink',
                'autoresize',
                'link',
                'image',
                'lists',
                'charmap',
                'table',
                'fullscreen',
                'insertdatetime',
                'code',
                'preview',
                'template',
            ].join(' '),
            removed_menuitems: 'newdocument',
            toolbar: [
                'undo redo',
                'styleselect',
                'bold italic strikethrough',
                'alignleft aligncenter alignright alignjustify',
                'bullist numlist outdent indent',
                'template table',
                'link image',
                'code preview',
            ].join(' | '),
            contextmenu: false,
        };
        setTimeout(async () => {
            await this.tinymce.init(typeof this.settings === 'object' ? { ...defaults, ...this.settings } : defaults);
            this.editor = this.tinymce.get(this.id);
        });
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: string) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this.readonly = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(value: any) {
        if (value === this.innerValue) {
            return;
        }
        if (!value) {
            value = '';
        }
        this.innerValue = value;
        if (this.editor && this.editor.initialized) {
            this.editor.setContent(value);
        }
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: string) => void = () => { };

    private get tinymce(): EditorManager {
        const w = <any>this.window;

        return 'tinymce' in w ? w.tinymce : null;
    }
}
