import { RouterManager } from '@aclass/admin/managers';
import { OverviewStory } from '@aclass/admin/storage/actions';
import { IAdminState, IOverviewModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class OverviewViewStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:overview-view';

    private id: number;

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<IOverviewModuleState['viewPageRecordId']>(['overviewModule', 'viewPageRecordId']).pipe(
            filter(v => v === this.id)
        );
        this.employee = this.routerManager.onRejectNavigation(OverviewViewStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(route: ActivatedRouteSnapshot): void {
        const id = parseInt(route.params.id);
        this.id = id;
        this.ngRdx.dispatch(OverviewStory.dryRunViewPage(id));
    }
}
