import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ICmsHotelData {

    readonly id: string;

    readonly name: string;

    readonly stars: number;

    readonly content: string;

    readonly images: Array<string>;
}

@Model({
    name: 'CmsHotel'
})
export class CmsHotel extends Entity<ICmsHotelData> {

    @Attr() readonly id: string;

    @Attr() readonly name: string;

    @Attr() readonly stars: number;

    @Attr() readonly content: string;

    @Attr() readonly images: Array<string>;
}

