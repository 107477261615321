import { NumberFormatPipe } from '@aclass/core/pipes/number.format.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'priceFormat',
})

export class PriceFormatPipe extends NumberFormatPipe implements PipeTransform {

    /**
     * Default string to replace
     */
    static SYMBOL = '%s';

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order, symbol to replace, decimal separator, thousands separator, decimal points, removes trailing zero(s) flag
     */
    transform(value: any, ...args: any[]): string {
        const [symbol = PriceFormatPipe.SYMBOL, ...rest] = args;

        return symbol.replace('%s', super.transform(value, ...rest));
    }
}
