import { SystemStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { WINDOW } from '@aclass/core/services/window.service';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService, SimpleNotificationsComponent } from 'angular2-notifications';
import { Subscription } from 'rxjs';

@Component({
    selector: 'adm-root',
    template: `
        <router-outlet></router-outlet>
        <simple-notifications #snc (destroy)="removeNotification($event)" class="b-notifications"></simple-notifications>
    `
})
export class AppComponent implements OnInit, OnDestroy {

    private subscriptions: Array<Subscription> = [];

    @ViewChild('snc', { static: true }) snc: SimpleNotificationsComponent;

    @dispatch() removeNotification = ({ id }) => SystemStory.removeNotification(id);

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private notify: NotificationsService,
        private router: Router,
        @Inject(WINDOW) private window: Window,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['notifications']>(['systemModule', 'notifications']).subscribe(records => {
                records.forEach(record => {
                    const notify = new SystemNotification(record);
                    if (this.snc.notifications.map(n => n.id).includes(notify.id)) {
                        return;
                    }
                    const options = typeof notify.options === 'object' ? notify.options.toJS() : { };
                    this.notify.create(notify.title, notify.content, notify.type, { ...options, ...{ id: notify.id } });
                });
            })
        );
        if (!('document' in this.window)) {
            return;
        }
        const s = this.router.events.subscribe(e => {
            if (!(e instanceof NavigationEnd) || e.url !== e.urlAfterRedirects) {
                return;
            }
            s.unsubscribe();
            new ElementRef(this.window.document.getElementById('preloader')).nativeElement.style.display = 'none';
        });
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
