import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface I404PageData {

    readonly id: string | null;

    readonly value: I404PageValueDate | null;
}

export interface I404PageValueDate {

    readonly content: string | null;
}

@Model({
    name: 'Page404'
})
export class Page404 extends Entity<I404PageData> {

    @Attr() readonly id: string | null;

    @Attr() readonly value: string | null;
}
