import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ILeadScoringState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of instances
     */
    searchPageInstances: List<string>;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * The id of product
     */
    editPageRecordId: string | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    editPageLeadScoreValue: any;

    editPageDestinations: any;

    editPageAirports: any;

    editPageAnswers: any;

    editPageFormValue: any;
}

export const INITIAL_LEAD_SCORING_STATE = createTypedState<ILeadScoringState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageForm: { },
    searchPageInstances: [],
    createPageForm: { },
    createPageSaveLock: null,
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageFormValue: { },
    editPageLeadScoreValue: { },
    editPageDestinations: { },
    editPageAirports: { },
    editPageAnswers: { },
    editPageForm: { },
});
