import { Document } from '@aclass/admin/storage/models/document';
import { DomainDetail } from '@aclass/admin/storage/models/domain.detail';
import { Attr, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IEmailTemplateData {

    readonly id: number | null;

    readonly name: string | null;

    readonly deleted: boolean | null;
}

@Model({
    name: 'EmailTemplate'
})
export class EmailTemplate extends Entity<IEmailTemplateData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly deleted: boolean | null;

    @Many({ to: 'Document', relatedName: 'relatedEmailTemplates', through: 'DocumentEmailTemplateMapping', throughFields: ['templateId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    readonly relatedEmailTemplates: QuerySet<EmailTemplate>;

    readonly relatedDomainDetail: QuerySet<DomainDetail>;

    readonly relatedDomainDetailSecond: QuerySet<DomainDetail>;

    readonly vamoosInitialTemplate: QuerySet<DomainDetail>;

    readonly vamoosUpdateNotifyTemplate: QuerySet<DomainDetail>;

    readonly vamoosAlertTemplate: QuerySet<DomainDetail>;

    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<IEmailTemplateData, 'id' | 'name'> => ({ id: this.id, name: this.name });

}
