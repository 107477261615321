import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'adm-content-layout',
    templateUrl: './content-layout.component.html'
})

export class ContentLayoutComponent {

    @Input() collapsible = false;

    @Input() showProgress = false;

    @Input() header;

    @Input() nav;

    /**
     * Watched value
     */
    @Input() collapsed = false;

    /**
     * Collapsed event emitter
     */
    @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Emits changes
     */
    runUpdate(v: boolean) {
        if (this.collapsed === v) {
            return;
        }
        this.collapsed = v;
        this.collapsedChange.emit(v);
    }
}
