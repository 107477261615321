import { Role } from '@aclass/admin/storage/models/role';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IPermissionData {

    readonly name: string | null;

    readonly description: string | null;
}

export interface IRolePermissionData {

    name: string;

    description: string;

    value: boolean;
}

@Model({
    name: 'Permission',
    idAttribute: 'name'
})
export class Permission extends Entity<IPermissionData> {

    @Attr() readonly name: string | null;

    @Attr() readonly description: string | null;

    readonly roleRecords: QuerySet<Role>;
}
