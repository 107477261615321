import { GeneralRemarkStory } from '@aclass/admin/storage/actions';
import { IGeneralRemarkModuleState, INITIAL_GENERAL_REMARK_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const generalRemarkModuleReducer = (state: TypedState<IGeneralRemarkModuleState> = INITIAL_GENERAL_REMARK_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case GeneralRemarkStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case GeneralRemarkStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case GeneralRemarkStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case GeneralRemarkStory.SEARCH_PAGE_UPDATE_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('editPageRecordId', () => payload);
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK:
            return state.update('removeModalLock', () => payload);
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL:
            return state.update('showRemoveModal', () => payload);
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_DESTINATIONS:
            return state.update('editPageDestinations', () => fromJS(payload));
        case GeneralRemarkStory.EDIT_PAGE_UPDATE_TOURS:
            return state.update('editPageTours', () => fromJS(payload));
        default:
            return state;
    }
};
