import { hashed } from '@aclass/core/helpers/hashed';
import { NotificationType, Options } from 'angular2-notifications';
import { Map, Record } from 'immutable';

export interface ISystemNotificationData {

    id: string;

    type: NotificationType;

    title?: string;

    content?: string;

    options?: Options;
}

export class SystemNotification extends Record({ id: undefined, type: undefined, title: undefined, content: undefined, options: undefined }) {

    id: string;

    type: NotificationType;

    title?: string;

    content?: string;

    options?: Map<string, any>;

    constructor(config?: Partial<ISystemNotificationData> | Map<string, any>) {
        super(config);
    }

    private static create(type: NotificationType, title?: string, content?: string, options?: Options): ISystemNotificationData {
        return { id: hashed(), type, title, content, options };
    }

    static success(title?: string, content?: string, options: Options = { timeOut: 3000 }): ISystemNotificationData {
        return SystemNotification.create(NotificationType.Success, title, content, options);
    }

    static error(title?: string, content?: string, options?: Options): ISystemNotificationData {
        return SystemNotification.create(NotificationType.Error, title, content, options);
    }

    static alert(title?: string, content?: string, options?: Options): ISystemNotificationData {
        return SystemNotification.create(NotificationType.Alert, title, content, options);
    }

    static info(title?: string, content?: string, options?: Options): ISystemNotificationData {
        return SystemNotification.create(NotificationType.Info, title, content, options);
    }

    static warning(title?: string, content?: string, options?: Options): ISystemNotificationData {
        return SystemNotification.create(NotificationType.Warn, title, content, options);
    }

    with(values: any): SystemNotification {
        return this.merge(values) as this;
    }
}
