import { Itinerary } from '@aclass/admin/storage/models/itinerary';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IItineraryPriceData {

    readonly id: string;

    readonly type: number;

    readonly price: string;
}

@Model({
    name: 'ItineraryPrice'
})
export class ItineraryPrice extends Entity<IItineraryPriceData> {

    static TYPE_ADULT = 1;

    static TYPE_CHILD = 2;

    static TYPE_SUPP = 3;

    static TYPE_AIRPORT = 4;

    static TYPE_FLIGHT = 5;

    static TYPE_DOMESTIC_FLIGHT = 6;

    static TYPE_BUFFER = 7;

    static TYPE_DISCOUNT = 8;

    static TYPES = [
        ItineraryPrice.TYPE_AIRPORT,
        ItineraryPrice.TYPE_FLIGHT,
        ItineraryPrice.TYPE_DOMESTIC_FLIGHT,
        ItineraryPrice.TYPE_BUFFER,
        ItineraryPrice.TYPE_DISCOUNT,
    ];

    @Attr() readonly id: string;

    @Attr() readonly type: number;

    @Attr() readonly price: string;

    @Fk({ to: 'Itinerary', relatedName: 'relatedPrices' }) readonly itineraryId: Itinerary;
}
