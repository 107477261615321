import { RouterManager } from '@aclass/admin/managers';
import { AppStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { ISystemAlertData, SystemAlert } from '@aclass/core/base/system.alert';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { NavigationExtras } from '@angular/router';
import { DRIVERS } from 'angular-safeguard';
import { detect } from 'detect-browser';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, ignoreElements, mergeMap, switchMap, tap } from 'rxjs/operators';

export const systemModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { router, routerManager, http, window, location, storage }: IEpicDi) => merge(
    action.pipe(
        ofType(SystemStory.DRY_RUN),
        filter(() => !state.value.systemModule.get('initialized')),
        switchMap(() => {
            const queue = [];
            const actions = [
                SystemStory.updateInitState(true),
                SystemStory.checkBrowser(),
            ];
            if (state.value.appModule.get('loginLock')) {
                actions.push(AppStory.updateLoginLock(false));
            }
            if (!state.value.systemModule.get('identity')) {
                queue.push(http.post('users/login-by-token', null, { observe: 'response' }));
            }
            queue.push(http.get('system/config', { observe: 'response' }));

            return concat(of(SystemStory.updateInitState(false)), zip(...queue).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    if (responses.filter(response => response.status !== 200 || !response.body.success).length) {
                        return [SystemStory.navigate(['login'])];
                    }
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes('users/login-by-token')) {
                            actions.push(SystemStory.updateIdentity(rs.body));
                        } else if (response.url.includes('system/config')) {
                            actions.push(SystemStory.updateConfiguration(rs.body));
                        }
                    });

                    return actions;
                })
            ));
        })
    ),
    action.pipe(
        ofType(SystemStory.SWITCH_APPLICATION),
        mergeMap(({ payload }: Action<string | null>) => {
            storage.set(DRIVERS.LOCAL, 'application', payload);

            return of(SystemStory.updateApplication(payload));
        })
    ),
    action.pipe(
        ofType(SystemStory.NAVIGATE),
        switchMap(({ payload }: Action<{ commands: Array<any>, extras?: NavigationExtras & { sharedLink?: boolean, inNewTab?: boolean } }>) => {
            const { commands, extras } = payload;
            const actions = [];
            if (extras) {
                if ('sharedLink' in extras) {
                    if (extras.sharedLink) {
                        commands.unshift(state.value.systemModule.get('application'));
                    }
                    delete extras.sharedLink;
                }
                if ('inNewTab' in extras) {
                    if (extras.inNewTab) {
                        const { protocol, hostname, port } = window.location;
                        const path = location.prepareExternalUrl(String(router.createUrlTree(commands, extras)));
                        const page = window.open(`${ protocol }//${ hostname }${ port ? `:${ port }` : '' }${ path }`, '_blank');
                        if (!page) {
                            actions.push(SystemStory.showNotification(SystemNotification.error('Error', 'Please allow application to open new page(you should see cross button in address bar) and try again.')));
                        }

                        return actions;
                    }
                    delete extras.inNewTab;
                }
            }

            router.navigate(commands, extras);

            return actions;
        })
    ),
    action.pipe(
        ofType(SystemStory.STOP_NAVIGATE),
        tap(({ payload }: Action<string>) => {
            routerManager.rejectNavigation.emit(payload);
        }),
        ignoreElements()
    ),
    action.pipe(
        ofType(SystemStory.CALL_BASE_INTERCEPTOR),
        filter(() => state.value.systemModule.get('baseInterceptorEnabled')),
        switchMap(({ payload }: Action<HttpResponse<any>>) => {
            const metaData = { };
            const connectionId = payload.headers.get('X-Connection-Id');
            if (connectionId) {
                metaData['connectionId'] = connectionId;
            }
            // Catch [[HttpErrorResponse]]
            if (payload.status === 500) {
                return [
                    SystemStory.stopNavigate(RouterManager.STOP_ALL_EVENT_NAME),
                    SystemStory.showAlert(SystemAlert.serverError(metaData))
                ];
            }
            const rs: Response = payload.body;
            const errorCodes = rs.errors.map(n => n.code);
            if (errorCodes.includes(500)) {
                return [
                    SystemStory.stopNavigate(RouterManager.STOP_ALL_EVENT_NAME),
                    SystemStory.showAlert(SystemAlert.serverError(metaData))
                ];
            }
            const actions = [];
            const alerts: Array<ISystemAlertData> = Object.values(state.value.systemModule.get('alerts').toJS());
            const alertCodes = alerts.map(alert => alert.type);
            if (errorCodes.includes(403) && !alertCodes.includes(SystemAlert.TYPE_FORBIDDEN_ERROR)) {
                actions.push(SystemStory.showAlert(SystemAlert.forbiddenError()));
            }
            if (errorCodes.includes(401) && !alertCodes.includes(SystemAlert.TYPE_UNAUTHORIZED_ERROR)) {
                actions.push(SystemStory.showAlert(SystemAlert.unauthorizedError()));
            }
            const appVersion = payload.headers.get('X-Application-Version');
            if (appVersion && !alertCodes.includes(SystemAlert.TYPE_UPDATE_AVAILABLE)) {
                const version = state.value.systemModule.get('version');
                if (!version) {
                    actions.push(SystemStory.updateVersion(appVersion));
                } else if (version !== appVersion) {
                    actions.push(SystemStory.updateVersion(appVersion));
                    actions.push(SystemStory.showAlert(SystemAlert.updateAvailable()));
                }
            }

            return actions;
        })
    ),
    action.pipe(
        ofType(SystemStory.CALL_SMART_INTERCEPTOR),
        filter(() => state.value.systemModule.get('smartInterceptorEnabled')),
        switchMap(({ payload }: Action<HttpResponse<any>>) => {
            const rs: Response = payload.body;
            // 500, 401, 403 catches in base interceptor
            const errors = rs.errors.filter(n => ![500, 401, 403].includes(n.code));

            return errors.map(n => SystemStory.showNotification(rs.success ? SystemNotification.warning(n.title, n.message) : SystemNotification.error(n.title, n.message)));
        })
    ),
    action.pipe(
        ofType(SystemStory.CHECK_BROWSER),
        filter(() => {
            const alerts: Array<ISystemAlertData> = Object.values(state.value.systemModule.get('alerts').toJS());
            if (alerts.map(alert => alert.type).includes(SystemAlert.TYPE_UNSUPPORTED_BROWSER)) {
                return false;
            }
            if (!('navigator' in window)) {
                return false;
            }
            const browser = detect();
            if (!browser) {
                return false;
            }

            return !['chrome', 'firefox', 'safari'].includes(browser.name);
        }),
        mergeMap(() => of(SystemStory.showAlert(SystemAlert.unsupportedBrowser())))
    ),
);
