import { AdditionalProductEditStateResolver } from '@aclass/admin/resolvers';
import { AdditionalProductStory, OrmStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdditionalProductData } from '@aclass/admin/storage/models';
import { IAdditionalProductModuleState, IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const additionalProductModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(AdditionalProductStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.additionalProductModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            AdditionalProductStory.updateFormSearchPage({ }),
            AdditionalProductStory.importRecordsOnSearchPage([]),
            AdditionalProductStory.updatePaginationOnSearchPage(null),
            AdditionalProductStory.updateOrderOnSearchPage(null),
            AdditionalProductStory.updateSearchLockOnSearchPage(null),
            AdditionalProductStory.updateCollapsedSearchPage(null),
            // Unlock page
            AdditionalProductStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(AdditionalProductStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.additionalProductModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(AdditionalProductStory.updateSearchLockOnSearchPage(true)), http.post('additional-products/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IAdditionalProductData>>>) => [
                    OrmStory.populateAdditionalProducts(rs.body.results),
                    AdditionalProductStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    AdditionalProductStory.updatePaginationOnSearchPage(rs.body.pagination),
                    AdditionalProductStory.updateOrderOnSearchPage(rs.body.order),
                    AdditionalProductStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(AdditionalProductStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => concat(of(AdditionalProductStory.updateSaveLockCreatePage(true)), http.post('additional-products', state.value.additionalProductModule.get('createPageForm')).pipe(
            dispatchActions(
                (rs: Response<Transformed<IAdditionalProductData>>) => [
                    AdditionalProductStory.updateSaveLockCreatePage(false),
                    AdditionalProductStory.updateSearchPageInitState(false),
                    SystemStory.showNotification(SystemNotification.success('Success', `Additional product "${rs.body.data.name}" created`)),
                    RootStory.resetForm(),
                ],
                () => [
                    AdditionalProductStory.updateSaveLockCreatePage(false),
                ]
            ),
        ))),
    ),
    action.pipe(
        ofType(AdditionalProductStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IAdditionalProductModuleState['editPageRecordId']>) => state.value.additionalProductModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IAdditionalProductModuleState['editPageRecordId']>) => http.get(`additional-products/${payload}`).pipe(
            dispatchActions(
                (rs: Response<Transformed<IAdditionalProductData>>) => [
                    OrmStory.populateAdditionalProducts([rs.body.data]),
                    AdditionalProductStory.updateFormOnEditPage({ }),
                    AdditionalProductStory.updateProductIdEditPage(payload),
                ],
                () => [
                    SystemStory.stopNavigate(AdditionalProductEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['additional-products'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(AdditionalProductStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const id = state.value.additionalProductModule.get('editPageRecordId');

            return concat(of(AdditionalProductStory.updateSaveLockEditPage(true)), http.put(`additional-products/${id}`, state.value.additionalProductModule.get('editPageForm')).pipe(
                dispatchActions(
                    (rs: Response<Transformed<IAdditionalProductData>>) => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Product "${rs.body.data.name}" updated`)),
                        OrmStory.populateAdditionalProducts([rs.body.data])
                    ],
                    () => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(AdditionalProductStory.EDIT_PAGE_REMOVE),
        mergeMap(() => {
            const id = state.value.additionalProductModule.get('editPageRecordId');

            return concat(of(AdditionalProductStory.updateSaveLockEditPage(true)), http.delete(`additional-products/${id}`).pipe(
                dispatchActions(
                    () => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                        AdditionalProductStory.updateSearchPageInitState(false),
                        AdditionalProductStory.updateProductIdEditPage(null),
                        OrmStory.dropAdditionalProducts([id]),
                        SystemStory.showNotification(SystemNotification.success('Success', `Additional product removed`)),
                        SystemStory.navigate(['additional-products'], { sharedLink: true })
                    ],
                    () => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
);
