import { BrowserWindowRef } from '@aclass/core/base/browser.window.ref';
import { WindowRef } from '@aclass/core/base/window.ref';
import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

/**
 * Create a new injection token for injecting the window into a component.
 */
export const WINDOW = new InjectionToken('WindowToken');


/**
 * Create an factory function that returns the native window object.
 */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | Object {
    if (isPlatformBrowser(platformId)) {
        return browserWindowRef.nativeWindow;
    }

    return { };
}

/**
 * Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class.
 */
const browserWindowProvider: ClassProvider = {
    provide: WindowRef,
    useClass: BrowserWindowRef
};

/**
 * Create an injectable provider that uses the windowFactory function for returning the native window object.
 */
const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [WindowRef, PLATFORM_ID]
};

/**
 * Create an array of providers.
 */
export const WINDOW_PROVIDERS = [
    browserWindowProvider,
    windowProvider
];
