import { IStackViewSegmentsData } from '@aclass/admin/components';
import { IDeliveryData, IEmailTemplateData, IPCTourPackageData } from '@aclass/admin/storage/models';
import { ICustomerModuleState } from '@aclass/admin/storage/states';
import { ISendEmailState } from '@aclass/admin/storage/states/send.email.state';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IUserData } from '@aclass/core/storage/models/user';

@Story({
    name: 'opportunity'
})
export class OpportunityStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_UPDATE_PIPELINE_LEVELS: string;

    @Task() static SEARCH_PAGE_UPDATE_ASSIGNED: string;

    @Task() static SEARCH_PAGE_UPDATE_PACKAGES: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_PACKAGE_SEARCH: string;

    @Task() static SEARCH_PAGE_PIPELINE_LEVEL_SEARCH: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static RELOAD_FLIGHTS_ON_VAMOOS: string;

    @Task() static EDIT_PAGE_UPDATE_OPPORTUNITY_ID: string;

    @Task() static EDIT_PAGE_UPDATE_DELIVERY_META: string;

    @Task() static EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_USER_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_USERS: string;

    @Task() static EDIT_PAGE_USER_SEARCH: string;

    @Task() static EDIT_PAGE_REMOVE_LOGS_COMMENT: string;

    @Task() static EDIT_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_PIPELINE_LEVELS: string;

    @Task() static EDIT_PAGE_PIPELINE_LEVEL_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_VAMOOS_FLIGHTS_RELOAD_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_COUNTRIES: string;

    @Task() static EDIT_PAGE_UPDATE_IS_USED_CUSTOM_PIPELINE: string;

    @Task() static EDIT_PAGE_COUNTRY_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK: string;

    @Task() static EDIT_PAGE_UPLOAD_DOCUMENTS: string;

    @Task() static EDIT_PAGE_REMOVE_DOCUMENT: string;

    @Task() static EDIT_PAGE_REMOVE_EMAIL: string;

    @Task() static EDIT_PAGE_DOWNLOAD_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_PICKED_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPGRADE_TO_DELIVERY: string;

    @Task() static EDIT_PAGE_REMOVE_DELIVERY: string;

    @Task() static EDIT_PAGE_UPDATE_DELIVERY_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_TOGGLE_ARCHIVE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID: string;

    @Task() static EDIT_PAGE_CUSTOMER_MERGER_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_LEAD_SCOPE: string;

    @Task() static EDIT_PAGE_MERGER_OPEN: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_INIT_STATE: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_OPENED: string;

    @Task() static EDIT_PAGE_MERGER_ADD_OPPORTUNITY: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_OPPORTUNITIES: string;

    @Task() static EDIT_PAGE_MERGER_ADD_SELECTED_OPPORTUNITY: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_SELECTED_OPPORTUNITIES: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM_SUBMIT: string;

    @Task() static EDIT_PAGE_MERGER_UPDATE_FORGET_LOCK: string;

    @Task() static EDIT_PAGE_MERGER_FORGET: string;

    @Task() static EDIT_PAGE_DEFINITIONS_OPENED: string;

    @Task() static SEND_EMAIL_PAGE_UPDATE_EMAIL_TEMPLATE_LOADING_LOCK: string;

    @Task() static SEND_EMAIL_UPDATE_OPENED: string;

    @Task() static SEND_EMAIL_UPDATE_OPENED_CALENDAR: string;

    @Task() static SEND_EMAIL_SEARCH_TEMPLATES: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATES: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK: string;

    @Task() static SEND_EMAIL_GET_MESSAGE: string;

    @Task() static SEND_EMAIL_UPDATE_MESSAGE_ID: string;

    @Task() static SEND_EMAIL_UPDATE_DOCUMENTS: string;

    @Task() static SEND_EMAIL_ADD_DOCUMENT: string;

    @Task() static SEND_EMAIL_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: OpportunityStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: boolean | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updatePipelineLevelsSearchPage(payload: Array<IPipelineLevelData['id']>): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVELS, payload };
    }

    static updateAssignedSearchPage(payload: Array<IUserData['id']>): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_ASSIGNED, payload };
    }

    static updatePackagesSearchPage(payload: Array<IPCTourPackageData['id']>): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_PACKAGES, payload };
    }

    static updateCollapsedSearchPage(payload: boolean | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload: any): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: boolean | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateSearchPackageLockOnSearchPage(payload: boolean | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK, payload };
    }

    static updateSearchPipelineLevelLockOnSearchPage(payload: boolean | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: OpportunityStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<number>): Action {
        return { type: OpportunityStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static searchPackagesOnSearchPage(payload?: string): Action {
        return { type: OpportunityStory.SEARCH_PAGE_PACKAGE_SEARCH, payload };
    }

    static searchPipelineLevelSearchPage(payload?: string): Action {
        return { type: OpportunityStory.SEARCH_PAGE_PIPELINE_LEVEL_SEARCH, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: OpportunityStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunEditPage(payload: IOpportunityData['id']): Action {
        return { type: OpportunityStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static reloadFlightsOnVamoos(payload: IOpportunityData['id']): Action {
        return { type: OpportunityStory.RELOAD_FLIGHTS_ON_VAMOOS, payload };
    }

    static updateOpportunityIdEditPage(payload: number): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateDeliveryMetaEditPage(payload: IDeliveryData | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_DELIVERY_META, payload };
    }

    static updateStackViewSegmentsEditPage(payload: IStackViewSegmentsData | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS, payload };
    }

    static updateSaveLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateFormEditPage(payload: any): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchUserLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_USER_SEARCH_LOCK, payload };
    }

    static updateUsersEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_USERS, payload };
    }

    static searchUsersEditPage(payload?: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_USER_SEARCH, payload };
    }

    static removeLogsCommentOnEditPage(id: number): Action {
        return { type: OpportunityStory.EDIT_PAGE_REMOVE_LOGS_COMMENT, payload: id };
    }

    static updateSearchPipelineLevelLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK, payload };
    }

    static updatePipelineLevelsEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_PIPELINE_LEVELS, payload };
    }

    static searchPipelineLevelEditPage(payload?: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_PIPELINE_LEVEL_SEARCH, payload };
    }

    static updateSearchCountryLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK, payload };
    }

    static searchCountryEditPage(payload?: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_COUNTRY_SEARCH, payload };
    }

    static updateCountryEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_COUNTRIES, payload };
    }

    static updateIsUsedCustomPipelineEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_IS_USED_CUSTOM_PIPELINE, payload };
    }

    static updateUploadDocumentsLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK, payload };
    }

    static uploadDocumentsEditPage(payload: Array<File>): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPLOAD_DOCUMENTS, payload };
    }

    static removeDocumentEditPage(payload: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_REMOVE_DOCUMENT, payload };
    }

    static removeEmailEditPage(payload: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_REMOVE_EMAIL, payload };
    }

    static downloadDocumentEditPage(payload: string): Action {
        return { type: OpportunityStory.EDIT_PAGE_DOWNLOAD_DOCUMENT, payload };
    }

    static updatePickedDocumentEditPage(payload: string | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT, payload };
    }

    static updateDeliverySaveLockEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_DELIVERY_SAVE_LOCK, payload };
    }

    static upgradeToDeliveryEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPGRADE_TO_DELIVERY };
    }

    static toggleRemovedDeliveryEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_REMOVE_DELIVERY };
    }

    static toggleArchiveEditPage(payload: boolean): Action {
        return { type: OpportunityStory.EDIT_PAGE_TOGGLE_ARCHIVE, payload };
    }

    static submitOnEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_SUBMIT };
    }

    static updateCustomerMergerIdOnEditPage(payload: ICustomerData['id'] | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID, payload };
    }

    static submitCustomerMergerOnEditPage(payload: { from: ICustomerData['id'], to: ICustomerData['id'] }): Action {
        return { type: OpportunityStory.EDIT_PAGE_CUSTOMER_MERGER_SUBMIT, payload };
    }

    static updateLeadScopeOnEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_UPDATE_LEAD_SCOPE };
    }

    static openMergerEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_OPEN };
    }

    static updateMergerOpenedOnEditPage(payload: null | boolean): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_OPENED, payload };
    }

    static updateVamoosFlightsEditPage(payload: boolean | null): Action {
        return { type: OpportunityStory.EDIT_PAGE_VAMOOS_FLIGHTS_RELOAD_SEARCH_LOCK, payload };
    }

    static updateMergerInitStateOnEditPage(payload: null | boolean): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_INIT_STATE, payload };
    }

    static addMergerOpportunityOnEditPage(payload: number): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_ADD_OPPORTUNITY, payload };
    }

    static updateMergerOpportunitiesOnEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_OPPORTUNITIES, payload };
    }

    static addMergerSelectedOpportunitiesOnEditPage(payload: number): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_ADD_SELECTED_OPPORTUNITY, payload };
    }

    static updateMergerSelectedOpportunitiesOnEditPage(payload: Array<number>): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SELECTED_OPPORTUNITIES, payload };
    }

    static updateMergerSearchLockOnEditPage(payload: null | boolean): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_LOCK, payload };
    }

    static updateMergerSearchFormOnEditPage(payload: any): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM, payload };
    }

    static submitMergerSearchFormOnEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM_SUBMIT };
    }

    static updateMergerForgetLockOnEditPage(payload: null | boolean): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_UPDATE_FORGET_LOCK, payload };
    }

    static forgetMergerOnEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_MERGER_FORGET };
    }

    static openDefinitionsOnEditPage(): Action {
        return { type: OpportunityStory.EDIT_PAGE_DEFINITIONS_OPENED };
    }

    static sendEmailUpdateOpened(payload: ICustomerModuleState['sendEmailOpened']): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_OPENED, payload };
    }

    static sendEmailUpdateOpenedCalendar(payload: ICustomerModuleState['sendEmailOpened']): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_OPENED_CALENDAR, payload };
    }

    static sendEmailSearchTemplates(payload?: string): Action {
        return { type: OpportunityStory.SEND_EMAIL_SEARCH_TEMPLATES, payload };
    }

    static sendEmailUpdateTemplates(payload: Array<IEmailTemplateData['id']>): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_TEMPLATES, payload };
    }

    static sendEmailUpdateTemplateLock(payload: ISendEmailState['sendEmailTemplateSearchLock']): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK, payload };
    }

    static sendEmailGetMessage(): Action {
        return { type: OpportunityStory.SEND_EMAIL_GET_MESSAGE };
    }

    static sendEmailUpdateMessageId(payload: ISendEmailState['sendEmailMessageId']): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_MESSAGE_ID, payload };
    }

    static sendEmailAddDocument(payload: string): Action {
        return { type: OpportunityStory.SEND_EMAIL_ADD_DOCUMENT, payload };
    }

    static sendEmailUpdateDocuments(payload: Array<string>): Action {
        return { type: OpportunityStory.SEND_EMAIL_UPDATE_DOCUMENTS, payload };
    }

    static sendEmailSubmit(payload: Array<{ id: string, file: File}>): Action {
        return { type: OpportunityStory.SEND_EMAIL_SUBMIT, payload };
    }
}
