import { AuthGuard } from '@aclass/admin/base/auth.guard';
import { ChangesGuard } from '@aclass/admin/base/changes.guard';
import { FormManager, InstanceManager, ModalManager, OrmManager, RouterManager } from '@aclass/admin/managers';
import { PipeManager } from '@aclass/admin/managers/pipe.manager';
import { SystemStateResolver } from '@aclass/admin/resolvers';
import { BaseInterceptor } from '@aclass/admin/rest/base.interceptor';
import { SystemStory } from '@aclass/admin/storage/actions';
import { INITIAL_STATE } from '@aclass/admin/storage/state';
import { IAdminState } from '@aclass/admin/storage/states';
import { baseEpic, baseReducer } from '@aclass/admin/storage/store';
import { WINDOW, WINDOW_PROVIDERS } from '@aclass/core/services/window.service';
import { NgReduxRouter, NgReduxRouterModule } from '@angular-redux/router';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { DRIVERS, Locker, LockerModule } from 'angular-safeguard';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { fromJS } from 'immutable';
import { ChartistModule } from 'ng-chartist';
import { createEpicMiddleware } from 'redux-observable';
import { AoLayoutComponent } from './ao/layout/ao-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSharedModule } from './app.shared.module';
import { CmsLayoutComponent } from './cms/layout/cms-layout.component';
import { LoginAutoComponent } from './shared/login/auto/login-auto.component';
import { LoginBaseComponent } from './shared/login/base/login-base.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        LoginAutoComponent,
        LoginBaseComponent,
        AoLayoutComponent,
        CmsLayoutComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgReduxModule,
        NgReduxRouterModule.forRoot(),
        ChartistModule,
        LockerModule.withConfig({
            driverNamespace: 'adm',
            driverFallback: [DRIVERS.LOCAL]
        }),
        SimpleNotificationsModule.forRoot({
            timeOut: 4000,
            clickIconToClose: true,
            icons: {
                alert: '<clr-icon shape="alarm-clock" size="50"></clr-icon>',
                error: '<clr-icon shape="error-standard" size="50"></clr-icon>',
                info: '<clr-icon shape="info-standard" size="50"></clr-icon>',
                warn: '<clr-icon shape="warning-standard" size="50"></clr-icon>',
                success: '<clr-icon shape="check" size="50"></clr-icon>',
            }
        }),
        AppSharedModule,
    ],
    exports: [
    ],
    providers: [
        WINDOW_PROVIDERS,
        Location,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseInterceptor,
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        AuthGuard,
        ChangesGuard,
        SystemStateResolver,
        FormManager,
        PipeManager,
        ModalManager,
        OrmManager,
        InstanceManager,
        RouterManager
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient,
        private ngRdx: NgRedux<IAdminState>,
        private storage: Locker,
        private formManager: FormManager,
        private pipeManager: PipeManager,
        private ormManager: OrmManager,
        private routerManager: RouterManager,
        private instanceManager: InstanceManager,
        @Inject(WINDOW) private window: Window,
        private ngRdxRouter: NgReduxRouter,
        private devTools: DevToolsExtension,
    ) {
        const epicMiddleware = createEpicMiddleware({
            dependencies: {
                http: this.http,
                router: this.router,
                location: this.location,
                storage: this.storage,
                window: this.window,
                formManager: this.formManager,
                pipeManager: this.pipeManager,
                ormManager: this.ormManager,
                routerManager: this.routerManager,
                instanceManager: this.instanceManager,
            },
        });
        const middleware = [
            epicMiddleware,
        ];
        const enhancers = [];
        if (this.devTools.isEnabled()) {
            const keys = Object.keys(INITIAL_STATE);
            enhancers.push(this.devTools.enhancer(<any>{
                serialize: {
                    // This not working properly in 2.15.3
                    // I've used replacer
                    // options: {
                    //     date: true,
                    //     regex: true,
                    //     undefined: true,
                    //     error: true,
                    //     symbol: true,
                    //     map: true,
                    //     set: true,
                    //     function: true,
                    // },
                    replacer: (key: string, value: any) => {
                        if (value instanceof Date) {
                            throw new Error('Storing date object in not allowed. Please use string values + Daytable to manipulate store value');
                        }

                        return value;
                    },
                    reviver: (key: string, value: any) => {
                        if (key !== 'orm' && keys.includes(key)) {
                            return fromJS(value);
                        }

                        return value;
                    },
                },
            }));
        }
        this.ngRdx.configureStore(baseReducer, INITIAL_STATE, middleware, enhancers);
        this.ngRdxRouter.initialize((state: IAdminState) => state.systemModule.get('route'));
        epicMiddleware.run(baseEpic);
        router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.ngRdx.dispatch(SystemStory.updateNextPageLock(true));
            } else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
                this.ngRdx.dispatch(SystemStory.updateNextPageLock(false));
            }
        });
    }
}
