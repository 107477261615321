import { isIterable } from '@aclass/core/helpers/immutable';
import { BaseRootStory } from '@aclass/core/storage/actions/base.root.story';
import { IAppState } from '@aclass/core/storage/states/app.state';
import { NgRedux } from '@angular-redux/store';
import { AfterContentInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { fromJS, Map } from 'immutable';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class StoreAttachForm implements AfterContentInit, OnDestroy {

    @Input('attach') attach: Array<string>;

    protected ngRdx: NgRedux<IAppState>;

    protected form: FormGroup | NgForm;

    private lock = false;

    private subscriptions: Array<Subscription> = [];

    /**
     * @inheritDoc
     */
    ngAfterContentInit() {
        if (!this.fullPath.length) {
            return;
        }
        this.subscriptions.push(
            this.ngRdx.select(this.attach)
                .pipe(filter(v => {
                    if (this.lock || !this.form || JSON.stringify(this.form.value) === JSON.stringify(v)) {
                        return false;
                    }
                    if (v === null || !(fromJS(v)).size) {
                        this.publish(this.form.value);

                        return false;
                    }

                    return true;
                }))
                .subscribe(() => this.load())
        );
        this.subscriptions.push(
            this.form.valueChanges.pipe(
                filter(v => {
                    const [moduleName, path] = this.fullPath;
                    if (this.lock || !moduleName || !path.length || !this.form) {
                        return false;
                    }

                    return JSON.stringify(v) !== JSON.stringify(this.getModule(moduleName).getIn(path));
                })
            )
            .subscribe(v => this.publish(v))
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    get fullPath(): Array<any> {
        if (!Array.isArray(this.attach)) {
            return [];
        }
        const path = [...this.attach];
        if (path.length < 2) {
            return [];
        }

        return [path.shift(), path];
    }

    private load() {
        this.lock = true;
        const [moduleName, path] = this.fullPath;
        if (!moduleName || !path.length || !this.form) {
            this.lock = false;

            return;
        }
        this.updateControls(moduleName, path, this.form.controls);
        this.lock = false;
    }

    private updateControls(moduleName: string, path: Array<string>, controls: { [key: string]: AbstractControl } | Array<AbstractControl>) {
        const keys: Array<any> = Array.isArray(controls) ? controls.map((r, idx) => idx) : Object.keys(controls);
        keys.forEach(k => {
            const relativePath = path.concat([k]);
            const control = controls[k];
            if (control instanceof FormArray || control instanceof FormGroup) {
                this.updateControls(moduleName, relativePath, controls[k].controls);
            }
            if (!(control instanceof FormControl)) {
                return;
            }
            const v = this.getModule(moduleName).getIn(relativePath, control.value);

            control.setValue(isIterable<any, any>(v) ? v.toJS() : v, { onlySelf: true, emitEvent: false });
        });
    }

    private publish(value: any) {
        this.lock = true;
        this.ngRdx.dispatch(BaseRootStory.updateForm(this.fullPath, value));
        this.lock = false;
    }

    private getModule(name: string): Map<string, any> {
        return this.ngRdx.getState()[name];
    }
}
