import { ISwitchButtonData } from '@aclass/admin/components';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { ICampaignData } from '@aclass/core/storage/models/campaign';
import { Model as Entity } from 'redux-orm';

@Model({
    name: 'Campaign'
})
export class Campaign extends Entity<ICampaignData> {

    /**
     * Value for fixed price
     */
    static AMOUNT_FIXED = 0;

    /**
     * Value for percent price
     * @deprecated
     */
    static AMOUNT_PERCENT = 1;

    /**
     * Value for bonus
     */
    static AMOUNT_BONUS = 3;

    static TYPES = [
        Campaign.AMOUNT_FIXED,
        Campaign.AMOUNT_BONUS,
    ];

    static TYPE_NAMES = {
        [Campaign.AMOUNT_FIXED]: 'Fixed price',
        [Campaign.AMOUNT_BONUS]: 'Bonus',
    };

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly creatorId: number | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly cmsName: string | null;

    @Attr() readonly amountType: number | null;

    @Attr() readonly perAdult: number | null;

    @Attr() readonly perChild: number | null;

    @Attr() readonly perSupp: number | null;

    @Attr() readonly allTours: number | null;

    @Attr() readonly comment: string | null;

    @Attr() readonly cmsComment: string | null;

    @Attr() readonly code: string | null;

    @Attr() readonly mainDeleted: boolean | null;

    @Attr() readonly bannerSettings: {
        enabled: boolean | null,
        bgColor: string | null,
        fontColor: string | null,
        icon: string | null
    } | null;

    @Attr() readonly paused: boolean | null;

    @Attr() readonly archived: number | null;

    @Attr() readonly onCmsSide: boolean | null;

    @Attr() readonly hash: string | null;

    @Attr() readonly brands: Array<any>;

    @Attr() readonly brandsLabels: Array<any>;

    @Attr() readonly locale: string | null;

    @Attr() readonly destinations: any;

    @Attr() readonly tours: any;

    @Attr() readonly days: Array<{
        startedAt: string | null;
        endedAt: string | null;
        departureStart: string | null;
        departureEnd: string | null;
    }>;

    get isFixed(): boolean {
        return this.amountType === Campaign.AMOUNT_FIXED;
    }

    get isBonus(): boolean {
        return this.amountType === Campaign.AMOUNT_BONUS;
    }

    get amountTypeName(): string {
        return this.amountType in Campaign.TYPE_NAMES ? Campaign.TYPE_NAMES[this.amountType] : '';
    }

    /**
     * Prepares types for checkbox
     */
    static composeDiscountTypesForSwitchButton(): Array<ISwitchButtonData<ICampaignData['amountType']>> {
        return Campaign.TYPES.map(r => ({
            title: Campaign.TYPE_NAMES[r],
            value: r,
        }));
    }
}
