import { FormManager } from '@aclass/admin/managers';
import { UserStory } from '@aclass/admin/storage/actions';
import { Permission, Role, User } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState, ISystemModuleState, IUserModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { findByIds } from '@aclass/core/helpers/orm';
import { Order } from '@aclass/core/rest/order';
import { Pagination } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { dispatch, NgRedux } from '@angular-redux/store';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-user-search',
    templateUrl: './user-search.component.html'
})
export class UserSearchComponent implements OnInit, OnDestroy, AfterViewInit {

    ff: ReactiveFormFactory;

    records: Array<User>;

    private _pagination: Pagination = new Pagination();

    private _order: Order = new Order();

    private _collapsed: boolean;

    private _showProgress: boolean;

    private _loading: boolean;

    private subscriptions: Array<Subscription> = [];

    get module() {
        return this.ngRdx.getState().userModule;
    }

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    set pagination(v) {
        this.ngRdx.dispatch(UserStory.updatePaginationOnSearchPage(v));
        this.search();
    }

    get pagination(): Pagination {
        return this._pagination;
    }

    set order(v) {
        this.ngRdx.dispatch(UserStory.updateOrderOnSearchPage(v));
        this.search();
    }

    get order(): Order {
        return this._order;
    }

    set collapsed(v) {
        this.ngRdx.dispatch(UserStory.updateCollapsedSearchPage(v));
    }

    get collapsed() {
        return this._collapsed;
    }

    get showProgress() {
        return this._showProgress;
    }

    get loading() {
        return this._loading;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        const module = this.module;
        this.records = [];
        this._collapsed = false;
        this._loading = module.get('searchPageLock') === null;
        this.ff = new ReactiveFormFactory({
            name: 'Search form',
            attach: ['userModule', 'searchPageForm'],
            controls: {
                username: new FormControl(),
                email: new FormControl(),
                role: new FormControl(),
                showDeactivated: new FormControl(false),
            }
        });
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['searchPageCollapsed']>(['userModule', 'searchPageCollapsed'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._collapsed = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['searchPageLock']>(['userModule', 'searchPageLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._loading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['searchPageResults']>(['userModule', 'searchPageResults'])
                .subscribe(ids => {
                    this.records = findByIds<User>(this.session, User, ids);
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['searchPagePagination']>(['userModule', 'searchPagePagination'])
                .subscribe(v => this._pagination = this._pagination.with(v))
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['searchPageOrder']>(['userModule', 'searchPageOrder'])
                .subscribe(v => this._order = this._order.with(v))
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        if (this.module.get('searchPageLock') === null) {
            this.search();
        }
    }

    @dispatch() search = (opts?: ISearchOptions) => UserStory.submitOnSearchPage(opts);

    runSearch(refresh: boolean = false) {
        if (refresh) {
            this.ff.form.reset({
                showDeactivated: false
            });
        } else if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.search({ pagination: true, order: refresh });
    }

    /**
     * Concat permissions to string for roles
     */
    describePermissions(record: Role): string {
        return record.permissions.all().toModelArray().map((perm: Permission) => perm.description).join(`<div>${ '-'.repeat(60) }</div>`);
    }
}
