import { FormManager } from '@aclass/admin/managers';
import { UserStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISystemModuleState, IUserModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-user-create',
    templateUrl: './user-create.component.html'
})
export class UserCreateComponent implements OnInit, OnDestroy {

    ff: ReactiveFormFactory;

    roleNames: Array<string>;

    private _saveLoading: boolean;

    private _showProgress: boolean;

    private subscriptions: Array<Subscription> = [];

    get saveLoading(): boolean {
        return this._saveLoading;
    }

    get showProgress() {
        return this._showProgress;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.ff = new ReactiveFormFactory({
            name: 'User',
            attach: ['userModule', 'createPageForm'],
            controls: {
                username: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
                email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(255)]),
                password: new FormControl(null, [Validators.required, Validators.pattern(/\w{5,}/)]),
                role: new FormControl(null, [Validators.required]),
            },
        });
        this._saveLoading = false;
        this.roleNames = [];
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['createPageSaveLock']>(['userModule', 'createPageSaveLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._saveLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['createPageRoleNames']>(['userModule', 'createPageRoleNames']).subscribe(records => {
                this.roleNames = records.toArray();
                const roleValue = this.ff.form.controls.role.value;
                if (!roleValue || !this.roleNames.includes(roleValue)) {
                    this.ff.form.controls.role.setValue(this.roleNames[0]);
                }
            })
        );
        this.subscriptions.push(this.formManager.onReset.subscribe(() => {
            this.ff.form.reset();
        }));
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() save = () => UserStory.submitOnCreatePage();

    runSave() {
        if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.save();
        this.ff.form.markAsPristine();
    }
}
