import {
    IAdminState,
    INITIAL_ACL_STATE,
    INITIAL_ADDITIONAL_PRODUCT_STATE,
    INITIAL_APP_MODULE_STATE,
    INITIAL_CAMPAIGN_STATE,
    INITIAL_CUSTOMER_STATE,
    INITIAL_DASHBOARD_STATE,
    INITIAL_DOMAIN_DETAIL_STATE,
    INITIAL_EMAIL_PIPELINE_STATE,
    INITIAL_EMAIL_TEMPLATE_STATE,
    INITIAL_EXCEPTION_STATE,
    INITIAL_EXTRA_PRICES_STATE,
    INITIAL_FILE_MODULE_STATE,
    INITIAL_GENERAL_REMARK_STATE,
    INITIAL_INTERNATIONALIZATION_STATE,
    INITIAL_LEAD_SCORING_STATE,
    INITIAL_NOTIFICATION_STATE,
    INITIAL_OFFLINE_BOOKING_EMAIL_STATE,
    INITIAL_OPPORTUNITY_STATE,
    INITIAL_ORM_STATE,
    INITIAL_OVERVIEW_STATE,
    INITIAL_PACKAGE_ITINERARY_STATE,
    INITIAL_PIPELINE_STATE,
    INITIAL_QUESTIONNAIRE_STATE,
    INITIAL_QUOTE_STATE,
    INITIAL_QUOTE_TEMPLATE_STATE,
    INITIAL_REDIRECT_STATE,
    INITIAL_SETTINGS_STATE,
    INITIAL_SIGNATURE_STATE,
    INITIAL_SMS_TEMPLATE_STATE,
    INITIAL_SOLDOUT_PRODUCT_STATE,
    INITIAL_SPLIT_TEST_STATE,
    INITIAL_SYSTEM_STATE,
    INITIAL_TEMPLATE_STATE,
    INITIAL_USER_STATE,
} from '@aclass/admin/storage/states';

export const INITIAL_STATE: IAdminState = {
    aclModule: INITIAL_ACL_STATE,
    additionalProductModule: INITIAL_ADDITIONAL_PRODUCT_STATE,
    appModule: INITIAL_APP_MODULE_STATE,
    campaignModule: INITIAL_CAMPAIGN_STATE,
    customerModule: INITIAL_CUSTOMER_STATE,
    dashboardModule: INITIAL_DASHBOARD_STATE,
    domainDetailModule: INITIAL_DOMAIN_DETAIL_STATE,
    emailPipelineModule: INITIAL_EMAIL_PIPELINE_STATE,
    emailTemplateModule: INITIAL_EMAIL_TEMPLATE_STATE,
    exceptionModule: INITIAL_EXCEPTION_STATE,
    extraPricesModule: INITIAL_EXTRA_PRICES_STATE,
    fileModule: INITIAL_FILE_MODULE_STATE,
    generalRemarkModule: INITIAL_GENERAL_REMARK_STATE,
    internationalizationModule: INITIAL_INTERNATIONALIZATION_STATE,
    leadScoringModule: INITIAL_LEAD_SCORING_STATE,
    notificationModule: INITIAL_NOTIFICATION_STATE,
    offlineBookingEmailModule: INITIAL_OFFLINE_BOOKING_EMAIL_STATE,
    opportunityModule: INITIAL_OPPORTUNITY_STATE,
    orm: INITIAL_ORM_STATE,
    overviewModule: INITIAL_OVERVIEW_STATE,
    packageItineraryModule: INITIAL_PACKAGE_ITINERARY_STATE,
    pipelineModule: INITIAL_PIPELINE_STATE,
    questionnaireModule: INITIAL_QUESTIONNAIRE_STATE,
    quoteModule: INITIAL_QUOTE_STATE,
    quoteTemplateModule: INITIAL_QUOTE_TEMPLATE_STATE,
    redirectModule: INITIAL_REDIRECT_STATE,
    settingsModule: INITIAL_SETTINGS_STATE,
    signatureModule: INITIAL_SIGNATURE_STATE,
    smsTemplateModule: INITIAL_SMS_TEMPLATE_STATE,
    soldoutModule: INITIAL_SOLDOUT_PRODUCT_STATE,
    splitTestModule: INITIAL_SPLIT_TEST_STATE,
    systemModule: INITIAL_SYSTEM_STATE,
    templateModule: INITIAL_TEMPLATE_STATE,
    userModule: INITIAL_USER_STATE,
};
