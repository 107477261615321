import { forwardRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'adm-toggle-button',
    templateUrl: './toggle-button.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleButtonComponent),
            multi: true
        }
    ],
})
export class ToggleButtonComponent implements OnInit, ControlValueAccessor {

    /**
     * Watched value
     */
    @Input() value = false;

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Icon for true value
     */
    @Input() yesIcon = 'check';

    /**
     * Icon for false value
     */
    @Input() noIcon = 'times';

    /**
     * Icon for false value
     */
    @Input() set icon(v: string) {
        this.yesIcon = v;
        this.noIcon = v;
    }

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Show small version
     */
    @Input() slim = false;

    @Input() readonly = false;

    disabled = false;

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: boolean) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v: boolean | null) {
        // If element is disabled angular send undefined, this removes all items
        // We already control this in [[runUpdate]]
        if (typeof v === 'undefined') {
            return;
        }
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: boolean) => void = () => { };

    /**
     * Emits changes
     */
    runUpdate() {
        if (this.disabled || this.readonly) {
            return;
        }
        this.value = !this.value;
        this.onChangeCallback(this.value);
        this.valueChange.emit(this.value);
    }
}
