import {
    IAdditionalProductData,
    ICmsSmalltourData,
    ICmsTourData,
    ICombinationRevisionData,
    IDestinationRevisionData,
    IEmailTemplateData, IExtraPriceData,
    IPCTourPackageData,
    IQuoteGalleryData,
    IQuotePreviewData,
    ITourRevisionData
} from '@aclass/admin/storage/models';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { IUserData } from '@aclass/core/storage/models/user';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IQuoteModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageRouteMode: 'full' | 'simple';

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Packages loading spinner status
     */
    searchPagePackageSearchLock: boolean | null;

    /**
     * Pipeline level loading spinner status
     */
    searchPagePipelineLevelSearchLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of pipeline level ids
     */
    searchPagePipelineLevels: List<IPipelineLevelData['id']>;

    /**
     * A list of user ids
     */
    searchPageAssigned: List<IUserData['id']>;

    /**
     * A list of Package ids
     */
    searchPagePackages: List<IPCTourPackageData['id']>;

    /**
     * A list of opportunity ids from ORM
     */
    searchPageResults: List<IQuotePreviewData['id']>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Edit extra prices for select
     */
    editExtraPricesInSelect: Array<IExtraPriceData['id']>;

    /**
     * The variable to lock resolver
     */
    editPageResolverId: IQuoteData['revision'] | null;

    /**
     * The id of opportunity
     */
    editPageOpportunityId: IOpportunityData['id'] | null;

    /**
     * The id of active revision
     */
    editPageRevisionId: IQuoteData['id'] | null;

    /**
     * The the switch revision lock
     */
    editPageSwitchRevisionLock: boolean | null;

    /**
     * Form status
     */
    editPageFormEditStatus: boolean | null;

    /**
     * The hash of prepared revision
     */
    editPageActiveState: string | null;

    /**
     * The state of prepared revision, where key is [[viewPageActive]]
     */
    editPagePreparedStates: Map<string, Map<string, any>>;

    /**
     * The state of edit revision, where key is [[viewPageActive]]
     */
    editPageEditedStates: Map<string, Map<string, any>>;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * The form data
     */
    editPageColorRange: Map<string, any> | null;

    /**
     * The form data
     */
    editPageForm: Map<string, any>;

    /**
     * A list of discounts build ids
     */
    editPageDiscounts: List<string>;

    /**
     * A list of bonuses build ids
     */
    editPageBonuses: List<string>;

    /**
     * A list of additional product ids
     */
    editPageAdditionalProducts: List<IAdditionalProductData['id']>;

    /**
     * Trigger to identify that search is running
     */
    editPageSearchCampaignLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    editPageSearchAdditionalProductLock: boolean | null;

    /**
     * A list of email-templates ids
     */
    searchEmailTemplates: List<number>;

    /**
     * Open picker trigger
     */
    sendEmailOpen: boolean | null;

    /**
     * Trigger to lock select email template
     */
    sendEmailSwitchLock: boolean | null;

    /**
     * The reference model
     */
    sendEmailTemplate: Map<string, any> | null;

    /**
     * The reference model id
     */
    sendEmailOpportunityId: IOpportunityData['id'] | null;

    /**
     * The reference model id
     */
    sendEmailRevisionNr: IQuoteData['revision'] | null;

    /**
     * The reference model id
     */
    sendEmailTemplateId: IEmailTemplateData['id'] | null;

    /**
     * Search lock
     */
    sendEmailSearchLock: boolean | null;

    /**
     * Fetch email data lock
     */
    sendEmailFetchLock: boolean | null;

    /**
     * A list of email-templates ids
     */
    sendEmailTemplates: List<IEmailTemplateData['id']>;

    /**
     * A list of documents ids
     */
    sendEmailSelectedDocument: List<string>;

    /**
     * Send lock
     */
    sendEmailSaveLock: boolean | null;

    /**
     * Form email template
     */
    sendEmailForm: Map<string, any>;

    /**
     * Open picker trigger
     */
    galleryPickerOpen: boolean | null;

    /**
     * The reference model id
     */
    galleryPickerOpportunityId: IOpportunityData['id'] | null;

    /**
     * Search progress
     */
    galleryPickerSearchLock: boolean | null;

    /**
     * Search results
     */
    galleryPickerRecords: List<IQuoteGalleryData['id']>;

    /**
     * Picker form data
     */
    galleryPickerForm: Map<string, any>;

    /**
     * The reference model id
     */
    hotelPickerOpportunityId: IOpportunityData['id'] | null;

    /**
     * The reference model id
     */
    smalltourPickerOpportunityId: IOpportunityData['id'] | null;

    /**
     * Search progress
     */
    hotelPickerSearchLock: boolean | null;

    /**
     * Search progress
     */
    smalltourPickerSearchLock: boolean | null;

    /**
     * Search results
     */
    hotelPickerRecords: List<IQuoteGalleryData['id']>;

    /**
     * Search results
     */
    smalltourPickerRecords: List<ICmsSmalltourData['id']>;

    /**
     * Open picker trigger
     */
    changeTourOpen: boolean | null;

    /**
     * The reference model id
     */
    changeTourOpportunityId: IOpportunityData['id'] | null;

    /**
     * Picker form data
     */
    changeTourForm: Map<string, any>;

    /**
     * Search lock
     */
    changeTourDestinationSearchLock: boolean | null;

    /**
     * Search lock
     */
    changeTourTourSearchLock: boolean | null;

    /**
     * Search lock
     */
    changeTourCombinationSearchLock: boolean | null;

    /**
     * Search results
     */
    changeTourDestinations: List<IDestinationRevisionData['id']>;

    /**
     * Search results
     */
    changeTourTours: List<ITourRevisionData['id']>;

    /**
     * Search results
     */
    changeTourCombinations: List<ICombinationRevisionData['id']>;

    /**
     * Fetch tour data lock
     */
    changeTourFetchLock: boolean | null;

    /**
     * Search lock
     */
    changeTourCmsTourId: ICmsTourData['id'] | null;
}

export const INITIAL_QUOTE_STATE = createTypedState<IQuoteModuleState>({
    searchPageInitialized: null,
    searchPageRouteMode: 'simple',
    searchPageLock: null,
    searchPagePackageSearchLock: null,
    searchPagePipelineLevelSearchLock: null,
    searchPageCollapsed: null,
    searchPageForm: { },
    searchPagePipelineLevels: [],
    searchPageAssigned: [],
    searchPagePackages: [],
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    editExtraPricesInSelect: [],
    editPageResolverId: null,
    editPageOpportunityId: null,
    editPageRevisionId: null,
    editPageSwitchRevisionLock: null,
    editPageFormEditStatus: null,
    editPageActiveState: null,
    editPagePreparedStates: { },
    editPageEditedStates: { },
    editPageSaveLock: null,
    editPageColorRange: { },
    editPageForm: { },
    editPageDiscounts: [],
    editPageBonuses: [],
    editPageAdditionalProducts: [],
    editPageSearchCampaignLock: null,
    editPageSearchAdditionalProductLock: null,
    searchEmailTemplates: [],
    sendEmailOpen: null,
    sendEmailSwitchLock: null,
    sendEmailTemplate: null,
    sendEmailOpportunityId: null,
    sendEmailRevisionNr: null,
    sendEmailTemplateId: null,
    sendEmailSearchLock: null,
    sendEmailFetchLock: null,
    sendEmailTemplates: [],
    sendEmailSelectedDocument: [],
    sendEmailSaveLock: null,
    sendEmailForm: { },
    galleryPickerOpen: null,
    galleryPickerOpportunityId: null,
    galleryPickerSearchLock: null,
    galleryPickerRecords: [],
    galleryPickerForm: { },
    hotelPickerOpportunityId: null,
    smalltourPickerOpportunityId: null,
    hotelPickerSearchLock: null,
    smalltourPickerSearchLock: null,
    hotelPickerRecords: [],
    smalltourPickerRecords: [],
    changeTourOpen: null,
    changeTourOpportunityId: null,
    changeTourForm: { },
    changeTourDestinationSearchLock: null,
    changeTourTourSearchLock: null,
    changeTourCombinationSearchLock: null,
    changeTourDestinations: [],
    changeTourTours: [],
    changeTourCombinations: [],
    changeTourFetchLock: null,
    changeTourCmsTourId: null,
});
