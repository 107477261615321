import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: '[admSelectTitle]'
})
export class SelectTitleDirective implements AfterViewInit, OnDestroy {

    private subscriptions: Array<Subscription> = [];

    constructor(
        private readonly el: ElementRef<HTMLElement>
    ) {
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        // This is not a good solution, but in angular 9+
        // @ContentChild and @ContentChildren queries will no longer be able to match their directive's own host node.
        this.subscriptions.push(
            fromEvent(this.el.nativeElement, 'mouseover').subscribe(() => {
                const container = this.el.nativeElement.querySelector('.ng-select-container');
                if (!container) {
                    return;
                }
                const labels: Array<string> = Array.from<HTMLElement>(container.querySelectorAll('.ng-value-container .ng-value .ng-value-label'))
                    .map(({ innerText }) => innerText)
                    .filter(v => v);
                // If something is selected, set title on overall select element
                container.setAttribute('title', labels.join(', '));
                // Set titles on other options
                // const i = container.querySelector('.ng-value-container .ng-input > input');
                // const dropdown = i && i.getAttribute('autocomplete') ? document.getElementById(i.getAttribute('autocomplete')) : null;
                // if (!dropdown) {
                //     return;
                // }
                // dropdown.querySelectorAll<HTMLElement>('.ng-dropdown-panel-items .ng-option').forEach(o => {
                //     const label = o.querySelector<HTMLElement>('.ng-option-label');
                //     o.setAttribute('title', label ? label.innerText : '');
                // });
            })
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
