import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'user'
})
export class UserStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static CREATE_PAGE_UPDATE_ROLE_NAMES: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_USER_ID: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SHOULD_LOGOUT: string;

    @Task() static EDIT_PAGE_UPDATE_ROLE_NAMES: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static PROFILE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static PROFILE_PAGE_UPDATE_SHOULD_LOGOUT: string;

    @Task() static PROFILE_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: UserStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: UserStory.SEARCH_PAGE_UPDATE_ORDER, payload: order };
    }

    static importRecordsOnSearchPage(ids: Array<number>): Action {
        return { type: UserStory.SEARCH_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: UserStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunCreatePage(): Action {
        return { type: UserStory.CREATE_PAGE_DRY_RUN };
    }

    static createSaveLockEditPage(v: boolean | null): Action {
        return { type: UserStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static updateRoleNamesOnCreatePage(data: Array<string>): Action {
        return { type: UserStory.CREATE_PAGE_UPDATE_ROLE_NAMES, payload: data };
    }

    static submitOnCreatePage(): Action {
        return { type: UserStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: number): Action {
        return { type: UserStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateUserIdEditPage(id: number): Action {
        return { type: UserStory.EDIT_PAGE_UPDATE_USER_ID, payload: id };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: UserStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static updateShouldLogoutEditPage(v: boolean): Action {
        return { type: UserStory.EDIT_PAGE_UPDATE_SHOULD_LOGOUT, payload: v };
    }

    static updateRoleNamesOnEditPage(data: Array<string>): Action {
        return { type: UserStory.EDIT_PAGE_UPDATE_ROLE_NAMES, payload: data };
    }

    static submitOnEditPage(): Action {
        return { type: UserStory.EDIT_PAGE_SUBMIT };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: UserStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSaveLockProfilePage(v: boolean | null): Action {
        return { type: UserStory.PROFILE_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static updateShouldLogoutProfilePage(v: boolean): Action {
        return { type: UserStory.PROFILE_PAGE_UPDATE_SHOULD_LOGOUT, payload: v };
    }

    static submitOnProfilePage(): Action {
        return { type: UserStory.PROFILE_PAGE_SUBMIT };
    }
}
