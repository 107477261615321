import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'quote-template'
})
export class QuoteTemplateStory {

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_INSTANCES: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_QUOTE_TEMPLATE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    static dryRunSearchPage(): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static submitOnSearchPage(): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_SUBMIT };
    }

    static updateInstancesOnSearchPage(payload: Array<string>): Action {
        return { type: QuoteTemplateStory.SEARCH_PAGE_UPDATE_INSTANCES, payload };
    }

    static dryRunEditPage(id: string): Action {
        return { type: QuoteTemplateStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static submitOnEditPage(): Action {
        return { type: QuoteTemplateStory.EDIT_PAGE_SUBMIT };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: QuoteTemplateStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateIdEditPage(id: string): Action {
        return { type: QuoteTemplateStory.EDIT_PAGE_UPDATE_QUOTE_TEMPLATE_ID, payload: id };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: QuoteTemplateStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }
}
