import { RouterManager } from '@aclass/admin/managers';
import { SignatureStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISignatureModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class SignatureEditStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:signature-edit';

    private id: string;

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager,
    ) {
        super();
        this.resolver = this.ngRdx.select<ISignatureModuleState['editPageSignatureId']>(['signatureModule', 'editPageSignatureId']).pipe(
            filter(v => v === this.id)
        );
        this.employee = this.routerManager.onRejectNavigation(SignatureEditStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(route: ActivatedRouteSnapshot): void {
        this.id = route.params.id;
        const userId = parseInt(route.params.userId);
        this.ngRdx.dispatch(SignatureStory.updateUserIdSearchPage(userId));
        this.ngRdx.dispatch(SignatureStory.dryRunEditPage(this.id));
    }
}
