import { RouterManager } from '@aclass/admin/managers';
import { SoldoutProductStory } from '@aclass/admin/storage/actions';
import { ISoldoutProductData } from '@aclass/admin/storage/models';
import { IAdminState, ISoldoutProductModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class SoldoutEditStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:soldout-edit';

    private id: ISoldoutProductData['id'];

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISoldoutProductModuleState['editPageRecordId']>(['soldoutModule', 'editPageRecordId']).pipe(
            filter(v => v === this.id)
        );
        this.employee = this.routerManager.onRejectNavigation(SoldoutEditStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(route: ActivatedRouteSnapshot): void {
        this.id = parseInt(route.params.id);
        this.ngRdx.dispatch(SoldoutProductStory.dryRunEditPage(this.id));
    }
}
