<form [attach]="ff.attach" [formGroup]="ff.form" novalidate (ngSubmit)="runSave()">
    <ng-template #header>
        <adm-back-button></adm-back-button>
        <button type="button" class="btn btn-warning-outline btn-sm" (click)="openRemoveModal()">
            <clr-icon shape="times"></clr-icon>
            Remove
        </button>
        <button type="submit" class="btn btn-info-outline btn-sm" [clrLoading]="saveLoading">
            <clr-icon shape="check"></clr-icon>
            Save
        </button>
    </ng-template>
    <adm-content-layout [header]="header" [showProgress]="showProgress">
        <div class="clr-row mb-1">
            <div class="clr-col-2">
                Name:
            </div>
            <div class="clr-col">
                <input type="text" class="form-control" formControlName="name" style="max-width: 450px"/>
            </div>
        </div>
        <div *ngFor="let perm of rolePermissions" class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-3 clr-col-md-12">
                <adm-checkbox-button [(value)]="perm.value" (click)="runUpdatePermissions()"></adm-checkbox-button>
            </div>
            <div class="clr-col">
                {{ perm.description }}
            </div>
        </div>
    </adm-content-layout>
</form>
<clr-modal [(clrModalOpen)]="shouldLogout" [clrModalClosable]="false">
    <h3 class="modal-title">Notice</h3>
    <div class="modal-body">
        <p>Your role has been changed. You should re-login to the system</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="logout()">Re-login</button>
    </div>
</clr-modal>
<form [attach]="dff.attach" [formGroup]="dff.form" novalidate>
    <clr-modal [(clrModalOpen)]="showRemoveModal">
        <h3 class="modal-title">Delete group</h3>
        <div class="modal-body">
            <p>All the users assigned to the deleted group are to be assigned to:</p>
            <select class="form-control" formControlName="name">
                <option *ngFor="let name of roleNames" [value]="name">{{ name }}</option>
            </select>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-info-outline" (click)="showRemoveModal = false">Cancel</button>
            <button type="button" class="btn btn-danger-outline" [clrLoading]="removeModalLock" (click)="runRemoveRole()">Delete</button>
        </div>
    </clr-modal>
</form>