import { ISwitchButtonData } from '@aclass/admin/components';
import { ItineraryValue } from '@aclass/admin/components/package.itinerary';
import { Quote } from '@aclass/admin/storage/models';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { fromJS, List } from 'immutable';

export const OPEN_STATUSES: List<ISwitchButtonData> = fromJS([
    {
        title: 'all',
        value: null,
    },
    {
        title: 'yes',
        value: true,
    },
    {
        title: 'no',
        value: false,
    },
]);

/**
 * Please note that all types are string, because they pasted to [[queryParams]]
 */
export interface IQuoteBuildParams {

    /**
     * Opportunity id
     */
    opportunity?: string;

    /**
     * Quote revision nr
     */
    revision?: string;

    /**
     * Tour id
     */
    tour?: string;

    /**
     * Flight date
     */
    departure?: string;

    /**
     * Airport id
     */
    airport?: string;

    /**
     * Direct flight
     */
    direct?: string;

    /**
     * Combination id
     */
    combination?: string;
}

/**
 * The quote data from builder
 */
export interface IQuoteMetaData {

    queryParams: IQuoteBuildParams;

    quote: IQuoteData;

    tourItinerary: ItineraryValue | null;

    combinationItinerary: ItineraryValue | null;
}

export interface IQuoteLinkData {

    revision: Quote | null;

    queryParams: IQuoteBuildParams;

    queryHash: string | null;

    active: boolean;
}

export interface IQuoteMargin {

    value: string;

    color: 'u-bg-danger-400' | 'u-bg-warning-400' | 'u-bg-success-400' | '';
}

export interface ISearchCampaign {
    name?: string;
    isDiscount?: boolean;
    isBonus?: boolean;
}
