import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ICampaignModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: any;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<number>;

    searchPageDestinations: List<any>;

    searchPageTours: List<any>;

    searchPageFilterDestinations: List<any>;

    searchPageFilterTours: List<any>;

    createPageDestinations: any;

    createPageTours: any;

    editPageDestinations: any;

    editPageTours: any;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Create form data
     */
    createPageForm: any;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * The tours and destination loading lock
     */
    createPageSearchLock: boolean | null;

    /**
     * A list of tours ids from ORM
     */
    createPageToursIds: List<string>;

    /**
     * A list of destinations ids from ORM
     */
    createPageDestinationsIds: List<string>;

    /**
     * A list of combinations ids from ORM
     */
    createPageCombinationsIds: List<string>;

    createPageDestinationsMappings: any;

    createPageCombinationsMappings: any;

    /**
     * The id of product
     */
    editPageRecordId: number | null;

    editPageRecordPausedState: number | null;


    editPageFormValue: any;

    createPageInitialized: boolean | null;
    createPageFormValue: any;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * The tours and destination loading lock
     */
    editPageSearchLock: boolean | null;

    /**
     * A list of tours ids from ORM
     */
    editPageToursIds: List<string>;

    /**
     * A list of destinations ids from ORM
     */
    editPageDestinationsIds: List<string>;

    /**
     * A list of combinations ids from ORM
     */
    editPageCombinationsIds: List<string>;

    editPageDestinationsMappings: any;

    editPageCombinationsMappings: any;

    /**
     * Edit form data
     */
    editPageForm: any;

    /**
     * Modal trigger
     */
    showRemoveModal: boolean | null;

    /**
     * Locks delete button
     */
    removeModalLock: boolean | null;

    /**
     * Toggle delete/restore campaign buttons
     */
    hideDeleteShowRestore: boolean | null;

    /**
     * Modal trigger
     */
    showPauseModal: boolean | null;

    /**
     * Locks delete button
     */
    pauseModalLock: boolean | null;
}

export const INITIAL_CAMPAIGN_STATE = createTypedState<ICampaignModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageDestinations: [],
    searchPageTours: [],
    searchPageFilterDestinations: [],
    searchPageFilterTours: [],
    createPageDestinations: [],
    createPageTours: [],
    editPageDestinations: [],
    editPageTours: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageInitialized: null,
    createPageForm: { },
    createPageSaveLock: null,
    createPageSearchLock: null,
    createPageToursIds: [],
    createPageDestinationsIds: [],
    createPageCombinationsIds: [],
    createPageDestinationsMappings: { },
    createPageCombinationsMappings: { },
    editPageRecordId: null,
    editPageRecordPausedState: null,
    editPageFormValue: { },
    createPageFormValue: { },
    editPageSaveLock: null,
    editPageSearchLock: null,
    editPageToursIds: [],
    editPageDestinationsIds: [],
    editPageCombinationsIds: [],
    editPageDestinationsMappings: { },
    editPageCombinationsMappings: { },
    editPageForm: { },
    showRemoveModal: null,
    removeModalLock: null,
    hideDeleteShowRestore: null,
    showPauseModal: null,
    pauseModalLock: null,
});
