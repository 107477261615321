import { OrmManager } from '@aclass/admin/managers';
import { SmsTemplateEditStateResolver } from '@aclass/admin/resolvers';
import { OrmStory, RootStory, SmsTemplateStory, SystemStory, } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { ISmsMessageData, ISmsTemplateData, } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type viewData = Response<{
    template: ISmsTemplateData,
    message: ISmsMessageData,
}>;

export const smsTemplateModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(SmsTemplateStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.smsTemplateModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SmsTemplateStory.updateFormSearchPage({ }),
            SmsTemplateStory.importRecordsOnSearchPage([]),
            SmsTemplateStory.updatePaginationOnSearchPage(null),
            SmsTemplateStory.updateOrderOnSearchPage(null),
            SmsTemplateStory.updateSearchLockOnSearchPage(null),
            SmsTemplateStory.updateCollapsedSearchPage(null),
            // Unlock page
            SmsTemplateStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SmsTemplateStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.smsTemplateModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: module.get('searchPagePagination'),
                order: module.get('searchPageOrder'),
            };

            return concat(of(SmsTemplateStory.updateSearchLockOnSearchPage(true)), http.post('sms-template/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ISmsTemplateData>>>) => [
                    OrmStory.populateSmsTemplates(rs.body.results),
                    SmsTemplateStory.importRecordsOnSearchPage(rs.body.results),
                    SmsTemplateStory.updatePaginationOnSearchPage(rs.body.pagination),
                    SmsTemplateStory.updateOrderOnSearchPage(rs.body.order),
                    SmsTemplateStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SmsTemplateStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
                const data: Map<string, any> = state.value.smsTemplateModule.get('createPageForm');

                const rq: any = {
                    name: data.get('name'),
                };

                return concat(of(SmsTemplateStory.updateSaveLockCreatePage(true)), http.post('sms-template', rq).pipe(
                    dispatchActions((rs: Response<ISmsTemplateData>) => [
                            SystemStory.showNotification(SystemNotification.success('Success', `Sms template "${ rs.body.name }" created`)),
                            OrmStory.populateSmsTemplates([rs.body]),
                            RootStory.resetForm(),
                            SmsTemplateStory.updateSearchPageInitState(false),
                            SystemStory.navigate(['sms-templates', 'edit', rs.body.id], { sharedLink: true })
                        ],
                    )),
                );
            },
        )
    ),

    action.pipe(
        ofType(SmsTemplateStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload }: Action<{ id: number, brand?: Instance['brand'], locale?: Instance['locale'] }>) => {
            const locale = payload.locale || instanceManager.locales.first();
            const brand = payload.brand || instanceManager.getBrandsByLocale(locale).first();
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(SmsTemplateStory.updateSaveLockEditPage(true)), http.get(`sms-template/${ payload.id }`, params).pipe(
                dispatchActions((rs: viewData) => {
                        const { template, message } = rs.body;
                        const [b, l] = instanceManager.parseId(message.id);

                        return [
                            OrmStory.populateSmsTemplates([template]),
                            OrmStory.populateSmsMessages([message]),
                            SmsTemplateStory.updateFormOnEditPage({ }),
                            SmsTemplateStory.updateBrandEditPage(b),
                            SmsTemplateStory.updateLocaleEditPage(l),
                            SmsTemplateStory.updateIdEditPage(payload.id),
                            SmsTemplateStory.updateActiveIdEditPage(message.id),
                            SmsTemplateStory.updateSaveLockEditPage(false)
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(SmsTemplateEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['sms-template'], { sharedLink: true }),
                        SmsTemplateStory.updateSaveLockEditPage(false)
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(SmsTemplateStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.smsTemplateModule;
            const data = module.get('editPageForm');
            const rq: any = {
                template: {
                    name: data.get('name'),
                },
                email: {
                    subject: data.get('subject'),
                    body: data.get('body'),
                }
            };
            const params = instanceManager.buildHeaders([module.get('editPageBrand'), module.get('editPageLocale')]);

            return concat(of(SmsTemplateStory.updateSaveLockEditPage(true)), http.put(`sms-template/${ module.get('editPageRecordId') }`, rq, params).pipe(
                dispatchActions((rs: viewData) => {
                    const { template, message } = rs.body;

                    return [
                        OrmStory.populateSmsTemplates([template]),
                        OrmStory.populateSmsMessages([message]),
                        OrmStory.reloadModel(OrmManager.RELOAD_SMS_TEMPLATE),
                        SystemStory.showNotification(SystemNotification.success('Success', `Template "${ template.name }" updated`)),
                        SmsTemplateStory.updateSaveLockEditPage(false),
                    ];
                }, () => [
                    SmsTemplateStory.updateSaveLockEditPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SmsTemplateStory.EDIT_PAGE_TOGGLE_ARCHIVE),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.smsTemplateModule;

            return concat(of(SmsTemplateStory.updateSaveLockEditPage(true)), http.put(`sms-template/archived/${ module.get('editPageRecordId') }`, { deleted: payload }).pipe(
                dispatchActions((rs: Response<ISmsTemplateData>) => [
                    OrmStory.populateSmsTemplates([rs.body]),
                    OrmStory.reloadModel(OrmManager.RELOAD_SMS_TEMPLATE),
                    SystemStory.showNotification(SystemNotification.success('Success', `Template "${ rs.body.name }" updated`)),
                    SmsTemplateStory.updateSaveLockEditPage(false),
                ], () => [
                    SmsTemplateStory.updateSaveLockEditPage(false),
                ])
            ));
        }),
    ),
);
