import { FormManager } from '@aclass/admin/managers';
import { AclStory } from '@aclass/admin/storage/actions';
import { Permission, Role } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAclModuleState, IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { findById } from '@aclass/core/helpers/orm';
import { extractModel } from '@aclass/core/storage/models/model';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-acl-search',
    templateUrl: './search.component.html',
    styleUrls: []
})
export class AclSearchComponent implements OnInit, OnDestroy {

    ff: ReactiveFormFactory;

    records;

    private _collapsed: boolean;

    private _showProgress: boolean;

    private _loading: boolean;

    private subscriptions: Array<Subscription> = [];

    get module() {
        return this.ngRdx.getState().userModule;
    }

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    set collapsed(v) {
        this.ngRdx.dispatch(AclStory.updateCollapsedSearchPage(v));
    }

    get collapsed() {
        return this._collapsed;
    }

    get showProgress() {
        return this._showProgress;
    }

    get loading() {
        return this._loading;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.records = (<Array<Role>>extractModel<Role>(this.session, Role).all().toModelArray());
        this._collapsed = false;
        this._loading = this.module.get('searchPageLock') === null;
        this.ff = new ReactiveFormFactory({
            name: 'Search form',
            attach: ['aclModule', 'searchPageForm'],
            controls: {
                name: new FormControl(),
            }
        });
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IAclModuleState['searchPageCollapsed']>(['aclModule', 'searchPageCollapsed'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._collapsed = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IAclModuleState['searchPageLock']>(['aclModule', 'searchPageLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._loading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IAclModuleState['searchPageResults']>(['aclModule', 'searchPageResults'])
                .subscribe(ids => {
                    if (ids.toJS().length > 0) {
                        this.records = ids.toJS().sort().map(id => findById<Role>(orm.session(this.ngRdx.getState().orm), Role, id));
                    }
                })
        );
        this.subscriptions.push(this.formManager.onReset.subscribe(() => {
            this.ff.form.reset();
        }));
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() search = () => AclStory.submitOnSearchPage();

    runSearch = (refresh: boolean = false) => {
        if (refresh) {
            this.ff.form.reset();
        }
        if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.search();
    }

    extractPermissions(record: Role): Array<Permission> {
        return record.permissions.all().toModelArray();
    }

    formatPermissions(records: Array<Permission>): string {
        return records.map(r => r.description).join('<br>');
    }
}
