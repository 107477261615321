import { EmailTemplate } from '@aclass/admin/storage/models/email.template';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IEmailMessageData {

    readonly id: number | null;

    readonly subject: string | null;

    readonly body: string | null;

    readonly templateId: number | null;
}

@Model({
    name: 'EmailMessage'
})
export class EmailMessage extends Entity<IEmailMessageData> {

    @Attr() readonly id: string | null;

    @Attr() readonly subject: string | null;

    @Attr() readonly body: string | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'relatedEmailTemplate' }) readonly templateId: EmailTemplate | null;

}
