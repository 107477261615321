import {
    TransformerTourRevision
} from '@aclass/admin/components';
import { OrmManager } from '@aclass/admin/managers';
import {
    CampaignStory,
    OrmStory,
    RootStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { getFormattedToursDestinationsCombinations } from '@aclass/admin/utils';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ICampaignData } from '@aclass/core/storage/models/campaign';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const campaignModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(CampaignStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.campaignModule.get('searchPageInitialized')),
        switchMap(() => [
            CampaignStory.submitOnSearchPage(false),
            CampaignStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(CampaignStory.SEARCH_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(() => {
            const module = state.value.campaignModule;
            const form = module.get('searchPageForm').toJS();
            const brands = form.brands;
            const locales = form.locales;
            let namesRq: DataSearchRqData;
            const requests = [];
            namesRq = {
                where: {
                    brands: brands,
                    locales: locales,
                },
            };
            requests.push(http.post(`discount-campaign/destination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`discount-campaign/tour-names`, namesRq, { observe: 'response' }));

            return zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`discount-campaign/destination-names`)) {
                            actions.push(CampaignStory.updateFilterDestinationsOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`discount-campaign/tour-names`)) {
                            actions.push(CampaignStory.updateFilterToursOnSearchPage(rs.body.results));
                        }
                    });

                    return actions;
                })
            );
        })
    ),
    action.pipe(
        ofType(CampaignStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<boolean>) => {
            let queue = of(CampaignStory.updateSearchLockOnSearchPage(true));
            const requests = [];
            let rq: DataSearchRqData;
            let namesRq: DataSearchRqData;
            if (payload) {
                queue = concat(queue, of(RootStory.resetForm()));
                rq = {
                    where: {
                        showLive: true,
                        showPast: false,
                    }
                };
            } else {
                const module = state.value.campaignModule;
                const form = module.get('searchPageForm').toJS();
                const brands = form.brands;
                const locales = form.locales;
                const status: string = form.status ? form.status : 'live';
                const showLive = status === 'live';
                const showPast = status === 'past';
                const dateStart = form.date ? form.date.from : null;
                const dateEnd = form.date ? form.date.to : null;
                const createdStart = form.createDate ? form.createDate.from : null;
                const createdEnd = form.createDate ? form.createDate.to : null;
                const destinations = form.destinations ? form.destinations : null;
                const tours = form.tours ? form.tours : null;
                rq = {
                    where: {
                        name: form.name,
                        createdStart: createdStart,
                        createdEnd: createdEnd,
                        dateStart: dateStart,
                        dateEnd: dateEnd,
                        brands,
                        locales,
                        destinations: destinations,
                        tours: tours,
                        showLive: showLive,
                        showPast: showPast,
                    },
                    pagination: module.get('searchPagePagination'),
                    order: module.get('searchPageOrder'),
                };
                namesRq = {
                    where: {
                        brands,
                        locales,
                    },
                    pagination: new Pagination().all()
                };
            }
            requests.push(http.post(`discount-campaign/search`, rq, { observe: 'response' }));
            requests.push(http.post(`discount-campaign/destination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`discount-campaign/tour-names`, namesRq, { observe: 'response' }));

            return concat(queue, zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [
                        CampaignStory.importRecordsOnSearchPage([])
                    ];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`discount-campaign/destination-names`)) {
                            actions.push(CampaignStory.updateDestinationsOnSearchPage(rs.body.results));
                            actions.push(CampaignStory.updateFilterDestinationsOnSearchPage(rs.body.results));
                        } else if (response.url.includes(`discount-campaign/tour-names`)) {
                            actions.push(CampaignStory.updateToursOnSearchPage(rs.body.results));
                            actions.push(CampaignStory.updateFilterToursOnSearchPage(rs.body.results));
                        } else if (response.url.includes('discount-campaign/search')) {
                            actions.push(OrmStory.populateCampaigns(rs.body.results));
                            actions.push(CampaignStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)));
                            actions.push(CampaignStory.updatePaginationOnSearchPage(rs.body.pagination));
                            actions.push(CampaignStory.updateOrderOnSearchPage(rs.body.order));
                            actions.push(CampaignStory.updateSearchLockOnSearchPage(false));
                        }
                    });

                    return actions;
                })
            ));
        }),
    ),
    action.pipe(
        ofType(CampaignStory.CREATE_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(() => {
            const module = state.value.campaignModule;
            const form = module.get('createPageForm').toJS();
            const brands = form.brand;
            const locales = form.locale;
            const params = instanceManager.buildHeaders([brands[0], locales[0]]);

            return concat(of(CampaignStory.updateSearchLockOnCreatePage(true)), http.get(`discount-campaign/tour-revisions`, params).pipe(
                dispatchActions((rs: Response<Array<TransformerTourRevision>>) => {
                    const values = getFormattedToursDestinationsCombinations(rs);

                    return [
                        OrmStory.populateTourRevisions(values.tourRevisions),
                        OrmStory.populateTours(values.tours),
                        OrmStory.populateDestinationRevisions(values.groupedDestinationRevisions),
                        OrmStory.populateDestinations(values.destinations),
                        OrmStory.populateCombinationRevisions(values.groupedCombinationRevisions),
                        OrmStory.populateCombinations(values.combinations),
                        CampaignStory.importToursOnCreatePage(values.tourRevisionIds),
                        CampaignStory.importDestinationsOnCreatePage(values.destinationRevisionIds),
                        CampaignStory.importCombinationsOnCreatePage(values.combinationRevisionIds),
                        CampaignStory.importDestinationsMappingsOnCreatePage(values.destinationMapping),
                        CampaignStory.importCombinationsMappingOnCreatePage(values.combinationMapping),
                        CampaignStory.updateSearchLockOnCreatePage(false)
                    ];
                })
            ));
        })
    ),
    action.pipe(
        ofType(CampaignStory.CREATE_PAGE_DRY_RUN),
        filter(() => !state.value.campaignModule.get('createPageInitialized')),
        mergeMap(() => {
            return [
                RootStory.resetForm(),
                CampaignStory.updateCreatePageInitState(true)
            ];
        }),
    ),
    action.pipe(
        ofType(CampaignStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
            const form = state.value.campaignModule.get('createPageForm').toJS();
            const discount = {
                name: form.name,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                onCmsSide: form.showOnCsmSide,
                perAdult: form.perAdult,
                perChild: form.perChild,
                perSupp: 0,
            };
            const getCleanIds = (ids: Array<string> | null): Array<any> => {
                const cleanIds = [];
                ids.forEach(v => {
                    const [, , id] = instanceManager.parseId(v);
                    cleanIds.push(id);
                });

                return cleanIds;
            };
            const getDates = (ids: Array<any>): Array<any> => {
                if (ids === null) {
                    return [];
                }
                const datesResult = [];
                ids.forEach((v) => {
                    datesResult.push({
                        startedAt: v.dateRange.from,
                        endedAt: v.dateRange.to,
                        departureStart: v.departureRange ? v.departureRange.from : null,
                        departureEnd: v.departureRange ? v.departureRange.to : null,
                    });
                });

                return datesResult;
            };
            const tours = form.tours ? getCleanIds(form.tours) : null;
            const destinations = form.destinations ? getCleanIds(form.destinations) : null;
            const dates = getDates(form.dates);
            const brands = form.brand;
            const locale = form.locale;
            const result = {
                discount: discount,
                code: form.code,
                desLevel: destinations,
                tourLevel: tours,
                dateLevel: dates,
                locale: locale,
                brands: brands
            };

            return concat(of(CampaignStory.updateSaveLockCreatePage(true)), http.post('discount-campaign', result).pipe(
                dispatchActions(
                    (rs: Response<ICampaignData>) => {
                        return [
                            CampaignStory.updateSaveLockCreatePage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', 'Campaign created')),
                            OrmStory.populateCampaigns([rs.body]),
                            RootStory.resetForm(),
                            CampaignStory.updateSearchPageInitState(false),
                            CampaignStory.updateCreatePageInitState(false),
                            SystemStory.navigate(['campaigns'], { sharedLink: true })
                        ];
                    },
                    () => [
                        CampaignStory.updateSaveLockCreatePage(false),
                    ]
                ),
            ));
        }),
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_ADD_DATE_ELEMENT),
        mergeMap(() => {
            const module = state.value.campaignModule;
            const value: ICampaignData = module.get('editPageFormValue').toJS();
            const form = module.get('editPageForm').toJS();
            const daysFormat = (dates: Array<any>) => {
                const result = [];
                dates.forEach((v) => {
                    result.push(
                        {
                            startedAt: v.dateRange ? v.dateRange.from : null,
                            endedAt: v.dateRange ? v.dateRange.to : null,
                            departureStart: v.departureRange ? v.departureRange.from : null,
                            departureEnd: v.departureRange ? v.departureRange.to : null,
                        }
                    );
                });
                result.push(
                    {
                        startedAt: null,
                        endedAt: null,
                        departureStart: null,
                        departureEnd: null,
                    }
                );

                return result;
            };
            const days = daysFormat(form.dates);
            const discount = {
                id: value.id,
                name: form.name,
                createdAt: value.createdAt,
                updatedAt: value.updatedAt,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                perAdult: value.perAdult,
                perChild: value.perChild,
                perSupp: value.perSupp,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                code: form.code,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                paused: value.paused,
                archived: value.archived,
                onCmsSide: form.showOnCsmSide,
                brand: value.brands,
                locale: value.locale,
                days: days,
                destinations: form.destinations,
                tours: form.tours,
            };

            return [
                OrmStory.updateCampaigns([discount]),
                OrmStory.reloadModel(OrmManager.RELOAD_CAMPAIGN),
            ];
        })
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_REMOVE_DATE_ELEMENT),
        mergeMap(({ payload }: Action<number>) => {
            const module = state.value.campaignModule;
            const value: ICampaignData = module.get('editPageFormValue').toJS();
            const form = module.get('editPageForm').toJS();
            const daysFormat = (dates: Array<any>) => {
                const result = [];

                dates.forEach((v) => {
                    result.push(
                        {
                            startedAt: v.dateRange ? v.dateRange.from : null,
                            endedAt: v.dateRange ? v.dateRange.to : null,
                            departureStart: v.departureRange ? v.departureRange.from : null,
                            departureEnd: v.departureRange ? v.departureRange.to : null,
                        }
                    );
                });
                if (result.length < 1) {
                    return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                }
                result.splice(payload, 1);

                return result;
            };
            const days = daysFormat(form.dates);
            const discount = {
                id: value.id,
                name: form.name,
                createdAt: value.createdAt,
                updatedAt: value.updatedAt,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                perAdult: value.perAdult,
                perChild: value.perChild,
                perSupp: value.perSupp,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                code: form.code,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                paused: value.paused,
                archived: value.archived,
                onCmsSide: form.showOnCsmSide,
                brand: value.brands,
                locale: value.locale,
                destinations: value.destinations,
                tours: value.tours,
                days: days,
            };

            return [
                OrmStory.updateCampaigns([discount]),
                OrmStory.reloadModel(OrmManager.RELOAD_CAMPAIGN),
            ];
        })
    ),
    action.pipe(
        ofType(CampaignStory.CREATE_PAGE_ADD_DATE_ELEMENT),
        mergeMap(() => {
            const module = state.value.campaignModule;
            const form = module.get('createPageForm').toJS();
            const daysFormat = (dates: Array<any>) => {
                const result = [];

                dates.forEach((v) => {
                    result.push(
                        {
                            startedAt: v.dateRange ? v.dateRange.from : null,
                            endedAt: v.dateRange ? v.dateRange.to : null,
                            departureStart: v.departureRange ? v.departureRange.from : null,
                            departureEnd: v.departureRange ? v.departureRange.to : null,
                        }
                    );
                });
                result.push(
                    {
                        startedAt: null,
                        endedAt: null,
                        departureStart: null,
                        departureEnd: null,
                    }
                );

                return result;
            };
            const days = daysFormat(form.dates);
            const discount = {
                name: form.name,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                code: form.code,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                onCmsSide: form.showOnCsmSide,
                days: days,
                destinations: form.destinations,
                tours: form.tours,
                perAdult: form.perAdult,
                perChild: form.perChild,
            };

            return [CampaignStory.updateFormValueOnCreatePage(discount)];
        })
    ),
    action.pipe(
        ofType(CampaignStory.CREATE_PAGE_REMOVE_DATE_ELEMENT),
        mergeMap(({ payload }: Action<number>) => {
            const module = state.value.campaignModule;
            const form = module.get('createPageForm').toJS();
            const daysFormat = (dates: Array<any>) => {
                const result = [];

                dates.forEach((v) => {
                    result.push(
                        {
                            startedAt: v.dateRange ? v.dateRange.from : null,
                            endedAt: v.dateRange ? v.dateRange.to : null,
                            departureStart: v.departureRange ? v.departureRange.from : null,
                            departureEnd: v.departureRange ? v.departureRange.to : null,
                        }
                    );
                });
                if (result.length < 1) {
                    return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                }
                result.splice(payload, 1);

                return result;
            };
            const days = daysFormat(form.dates);
            const discount = {
                name: form.name,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                code: form.code,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                onCmsSide: form.showOnCsmSide,
                days: days,
                destinations: form.destinations,
                tours: form.tours,
            };

            return [CampaignStory.updateFormValueOnCreatePage(discount)];
        })
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_UPDATE_TOURS_AND_DESTINATIONS),
        switchMap(({ payload }: Action) => {
            const [brand, locale, id] = payload;
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(CampaignStory.updateSearchLockOnEditPage(true)), http.get(`discount-campaign/tour-revisions`, params).pipe(
                dispatchActions((rs: Response<Array<TransformerTourRevision>>) => {
                    const values = getFormattedToursDestinationsCombinations(rs);

                    const actions = [
                        OrmStory.populateTourRevisions(values.tourRevisions),
                        OrmStory.populateTours(values.tours),
                        OrmStory.populateDestinationRevisions(values.groupedDestinationRevisions),
                        OrmStory.populateDestinations(values.destinations),
                        OrmStory.populateCombinationRevisions(values.groupedCombinationRevisions),
                        OrmStory.populateCombinations(values.combinations),
                        CampaignStory.importToursOnEditPage(values.tourRevisionIds),
                        CampaignStory.importDestinationsOnEditPage(values.destinationRevisionIds),
                        CampaignStory.updateSearchLockOnEditPage(false)
                    ];
                    if (id) {
                        actions.push(CampaignStory.updateCampaignIdEditPage(id));
                    }
                    actions.push(CampaignStory.updateSearchLockOnEditPage(false));

                    return actions;
                })
            ));
        })
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_REMOVE_RECORD),
        mergeMap(() => {
            const module = state.value.campaignModule;
            const id = module.get('editPageRecordId');

            return http.delete(`discount-campaign/${id}`).pipe(
                dispatchActions(() => {
                    return [
                        CampaignStory.importRecordsOnSearchPage([]),
                        CampaignStory.updateSearchPageInitState(false),
                        OrmStory.dropCampaigns([id]),
                        RootStory.resetForm(),
                        CampaignStory.updateRemoveModalLockOnEditPage(false),
                        CampaignStory.updateShowRemoveModalOnEditPage(false),
                        CampaignStory.hideDeleteShowRestoreButtonsOnEditPage(true),
                        SystemStory.showNotification(SystemNotification.success('Success', `Campaign removed.`)),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_RESTORE_RECORD),
        mergeMap(() => {
            const module = state.value.campaignModule;
            const result = {
                id: module.get('editPageRecordId')
            };

            return http.post(`discount-campaign/restore`, result).pipe(
                dispatchActions((rs: Response<any>) => {
                    return [
                        RootStory.resetForm(),
                        CampaignStory.hideDeleteShowRestoreButtonsOnEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Campaign restored.`)),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_PAUSE_RECORD),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.campaignModule;
            const id = module.get('editPageRecordId');

            return http.post(`discount-campaign/toggle-pause/${id}`, { paused: payload}).pipe(
                dispatchActions((rs: Response<any>) => {
                    return [
                        CampaignStory.updateSearchPageInitState(false),
                        OrmStory.updateCampaigns([rs.body]),
                        OrmStory.reloadModel(OrmManager.RELOAD_CAMPAIGN),
                        SystemStory.navigate(['campaigns'], { replaceUrl: true, sharedLink: true }),
                        SystemStory.showNotification(SystemNotification.success('Success', rs.body.paused ? `Campaign paused.` : `Campaign restored.`)),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<number>) => state.value.campaignModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<number>) => {
            let rq: DataSearchRqData;
            rq = {
                where: {
                    id: payload,
                },
            };

            return http.post(`discount-campaign/search`, rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ICampaignData>>>) => {
                    const actions = [
                        CampaignStory.updateFormOnEditPage({ }),
                        OrmStory.populateCampaigns(rs.body.results),
                    ];
                    const record = rs.body.results[0];
                    if (!record.allTours) {
                        actions.push(CampaignStory.updateToursAndDestinationsOnEditPage([record.brands[0], record.locale, payload]));
                    }
                    if (record.allTours) {
                        actions.push(CampaignStory.updateCampaignIdEditPage(payload));
                    }
                    actions.push(CampaignStory.updateFormValueOnEditPage(record));
                    actions.push(CampaignStory.updatePausedStateEditPage(record.paused));

                    return actions;
                })
            );
        })
    ),
    action.pipe(
        ofType(CampaignStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const form = state.value.campaignModule.get('editPageForm').toJS();
            const value: ICampaignData = state.value.campaignModule.get('editPageFormValue').toJS();
            const id = state.value.campaignModule.get('editPageRecordId');
            const brands = form.brand;
            const locale = form.locale;
            const discount = {
                name: form.name,
                cmsName: form.cmsName,
                amountType: form.discountBy,
                allTours: form.allTours ? 1 : 0,
                comment: form.description,
                cmsComment: form.cmsDescription,
                bannerSettings: {
                    enabled: form.showBanner,
                    bgColor: form.bannerBgColor,
                    fontColor: form.bannerFontColor,
                    icon: form.bannerIcon
                },
                onCmsSide: form.showOnCsmSide,
                perAdult: value.perAdult,
                perChild: value.perChild,
                perSupp: value.perSupp,
            };
            const getCleanIds = (ids: Array<string> | null): Array<any> => {
                const cleanIds = [];
                ids.forEach(v => {
                    const [, , cleanId] = instanceManager.parseId(v);
                    cleanIds.push(cleanId);
                });

                return cleanIds;
            };
            const getDates = (ids: Array<any>): Array<any> => {
                if (ids === null) {
                    return [];
                }
                const datesResult = [];
                ids.forEach((v) => {
                    datesResult.push({
                        startedAt: v.dateRange ? v.dateRange.from : null,
                        endedAt: v.dateRange ? v.dateRange.to : null,
                        departureStart: v.departureRange ? v.departureRange.from : null,
                        departureEnd: v.departureRange ? v.departureRange.to : null,
                    });
                });

                return datesResult;
            };
            const tours = form.tours ? getCleanIds(form.tours) : null;
            const destinations = form.destinations ? getCleanIds(form.destinations) : null;
            const dates = getDates(form.dates);
            const result = {
                discount: discount,
                code: form.code,
                desLevel: destinations,
                tourLevel: tours,
                dateLevel: dates,
                locale: locale,
                brands: brands
            };

            return concat(of(CampaignStory.updateSaveLockCreatePage(true)), http.put(`discount-campaign/${id}`, result).pipe(
                dispatchActions(
                    (rs: Response<ICampaignData>) => {
                        return [
                            CampaignStory.updateSaveLockCreatePage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', 'Campaign updated')),
                            OrmStory.populateCampaigns([rs.body]),
                            RootStory.resetForm(),
                            CampaignStory.updateSearchPageInitState(false)
                        ];
                    },
                    () => [
                        CampaignStory.updateSaveLockCreatePage(false),
                    ]
                ),
            ));
        }),
    )
);
