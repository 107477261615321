import { forwardRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'adm-checkbox-button',
    templateUrl: './checkbox-button.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxButtonComponent),
            multi: true
        }
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxButtonComponent implements OnInit, ControlValueAccessor {

    /**
     * Watched value
     */
    @Input() value = false;

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Show small version
     */
    @Input() slim = false;

    @Input() readonly = false;

    disabled = false;

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: boolean) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v: boolean | null) {
        // If element is disabled angular send undefined, this removes all items
        // We already control this in [[runUpdate]]
        if (typeof v === 'undefined') {
            return;
        }
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: boolean) => void = () => { };

    /**
     * Emits changes
     */
    runUpdate(v: boolean) {
        if (this.disabled || this.value === v || this.readonly) {
            return;
        }
        this.value = v;
        this.onChangeCallback(v);
        this.valueChange.emit(v);
    }
}
