import { Notification } from '@aclass/core/rest/notification';
import { Action } from '@aclass/core/storage/action';
import { Observable } from 'rxjs';

/**
 * The result of processing http request
 * In general it is [[ResponseBody]] on backend side
 */
export class Response<Body = { }> {

    /**
     * Identify that body can be parsed
     */
    readonly success: boolean;

    /**
     * The list of notifications
     */
    readonly errors: Array<Notification>;

    /**
     * The result of operation
     */
    readonly body: Body;

    constructor({ success, errors, body }: Response<any>) {
        this.success = success;
        this.errors = errors.map(n => new Notification(n));
        this.body = body;
    }
}


/**
 * Transformed
 */
export interface Transformed<T = any, U = { [key: string]: Transformed | Array<Transformed> }> {

    /**
     * Model fields
     */
    data: T;

    /**
     * Extra relations
     */
    includes?: U;

    /**
     * Extra metadata
     */
    meta?: {
        [key: string]: any
    };
}

export function dispatchActions<Body>(onSuccess?: (rs: Response<Body>) => Action | Array<Action>, onFailure?: (rs: Response<Body>) => Action | Array<Action>) {
    return (source) => new Observable(subscriber => source.subscribe(
        (rs) => {
            try {
                if (!(rs instanceof Response)) {
                    return;
                }
                if (typeof onSuccess !== 'function') {
                    onSuccess = () => [];
                }
                if (typeof onFailure !== 'function') {
                    onFailure = () => [];
                }
                let actions: any = rs.success ? onSuccess(rs) : onFailure(rs);
                if (!Array.isArray(actions)) {
                    actions = [actions];
                }
                actions.forEach(action => {
                    subscriber.next(action);
                });
            } catch (err) {
                subscriber.error(err);
            }
        },
        err => subscriber.error(err),
        () => subscriber.complete()
    ));
}
