import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { ILogData } from '@aclass/core/storage/models/log';
import { Model as Entity } from 'redux-orm';

export type IOpportunityLogData = ILogData & {

    readonly enquireDataId: Opportunity | number | null;
};

@Model({
    name: 'OpportunityLog'
})
export class OpportunityLog extends Entity<IOpportunityLogData> {

    @Attr() readonly id: string | null;

    @Attr() readonly owner: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly log: string | null;

    @Attr() readonly comment: string | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedLogs' }) readonly enquireDataId: Opportunity | null;

    get formattedLog(): string {
        return this.comment ? `<div>${ this.log }Comment: <span style="font-weight: bold">${ this.comment }</span></div>` : this.log;
    }
}
