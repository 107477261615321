import { OrmManager } from '@aclass/admin/managers';
import {
    OfflineBookingEmailEditStateResolver,
    OfflineBookingReportEditStateResolver
} from '@aclass/admin/resolvers';
import { viewDocumentRs } from '@aclass/admin/rest/responses';
import {
    OfflineBookingEmailStory,
    OrmStory,
    RootStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IOfflineBookingEmailData, IOfflineBookingReport } from '@aclass/admin/storage/models';
import { IAdminState, IOfflineBookingEmailModuleState } from '@aclass/admin/storage/states';
import { readToBase64, tryToSaveFile, IFileData } from '@aclass/core/base/file.reader';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const offlineBookingEmailModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(OfflineBookingEmailStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.offlineBookingEmailModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            OfflineBookingEmailStory.updateFormSearchPage({ }),
            OfflineBookingEmailStory.importRecordsOnSearchPage([]),
            OfflineBookingEmailStory.importInstancesOnSearchPage([]),
            OfflineBookingEmailStory.updatePaginationOnSearchPage(null),
            OfflineBookingEmailStory.updateOrderOnSearchPage(null),
            OfflineBookingEmailStory.updateSearchLockOnSearchPage(null),
            OfflineBookingEmailStory.updateCollapsedSearchPage(null),
            // Unlock page
            OfflineBookingEmailStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.offlineBookingEmailModule;
            const brands: Array<Instance['brand']> = module.getIn(['searchPageForm', 'brands']);
            const locales: Array<Instance['locale']> = module.getIn(['searchPageForm', 'locales']);

            return concat(of(OfflineBookingEmailStory.updateSearchLockOnSearchPage(true)), http.post('email-offline-booking/search', { brands, locales }).pipe(
                dispatchActions((rs: Response<Array<IOfflineBookingEmailData>>) => [
                    OrmStory.populateOfflineBookingEmails(rs.body),
                    OfflineBookingEmailStory.importRecordsOnSearchPage(rs.body.map(r => r.id)),
                    OfflineBookingEmailStory.importInstancesOnSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()),
                    OfflineBookingEmailStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_REMOVE_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(OfflineBookingEmailStory.updatePickedDocumentEditPage(payload)), http.delete(`document-email-message-template/${ payload }`).pipe(
            dispatchActions(() => [
                OfflineBookingEmailStory.updatePickedDocumentEditPage(null),
                OrmStory.dropDocuments([payload]),
                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_OPTION_TEMPLATE),
                SystemStory.showNotification(SystemNotification.success('Success', 'Document removed'))
            ])
        ))),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IOfflineBookingEmailModuleState['editPageRecordId']>) => state.value.offlineBookingEmailModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IOfflineBookingEmailModuleState['editPageRecordId']>) => {
            const [brand, locale] = instanceManager.parseId(payload);
            const rq = {
                brands: [brand],
                locales: [locale],
                templateType: 'email-offline-booking'
            };

            return http.post(`email-offline-booking/search`, rq).pipe(
                dispatchActions(
                    (rs: Response<IOfflineBookingEmailData>) => {
                        const documentsMapping = rs.body[0].documents.map(({ id: documentId }) => ({
                            templateId:  rs.body[0].id,
                            documentId
                        }));

                        return [
                            OrmStory.populateDocuments(rs.body[0].documents),
                            OrmStory.truncateDocumentEmailTemplateMappings(),
                            // OrmStory.populateDocumentEmailOptionsTemplateMappings(documentsMapping),
                            OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                            OfflineBookingEmailStory.updateProductIdEditPage(payload),
                            OfflineBookingEmailStory.updateFormOnEditPage({ }),
                            OrmStory.populateOfflineBookingEmails([rs.body[0]]),
                        ];
                    },
                    () => {
                        return [
                            SystemStory.stopNavigate(OfflineBookingEmailEditStateResolver.STOP_EVENT_NAME),
                            SystemStory.navigate(['offline-booking-email'], { sharedLink: true })
                        ];
                    }
                )
            );
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.offlineBookingEmailModule;
            const [brand, locale] = instanceManager.parseId(module.get('editPageRecordId'));
            const form = module.get('editPageForm').toJS();
            const result = {
                header: {
                    logo: form.headerLogo,
                    contactInfo: form.contactInfo
                },
                body: {
                    from: form.from,
                    fromName: form.fromName,
                    subject: form.subject,
                    bcc: form.bcc,
                    greeting: form.greeting,
                    card: form.card,
                    bank: form.bank,
                    info: form.info,
                    table: {
                        passengers: {
                            title: form.tablePassengersTitle,
                            row: {
                                title: form.tablePassengersRowTitle,
                                val: form.tablePassengersRowValue
                            }
                        },
                        other: form.tableOther
                    },
                    aCard: form.aCard,
                    aBank: form.aBank,
                    aInfo: form.aInfo
                },
                footer: {
                    content: form.footer
                }
            };
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(OfflineBookingEmailStory.updateSaveLockEditPage(true)), http.put(`email-offline-booking/save`, result, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<IOfflineBookingEmailData>) => [
                        OfflineBookingEmailStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Record updated`)),
                        OrmStory.populateOfflineBookingEmails([rs.body])
                    ],
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_REPORT_PAGE_DRY_RUN),
        filter(() => !state.value.offlineBookingEmailModule.get('editReportPageInitialized')),
        mergeMap(() => {

            return http.get(`email-offline-booking/report`).pipe(
                dispatchActions(
                    (rs: Response<IOfflineBookingReport>) => [
                        // Reset prev records state
                        OfflineBookingEmailStory.updateFormOnEditReportPage({ }),
                        OfflineBookingEmailStory.updateDataOnEditReportPage(rs.body),
                        // Unlock page
                        OfflineBookingEmailStory.updateEditReportPageInitState(true),
                    ],
                    () => [
                        SystemStory.stopNavigate(OfflineBookingReportEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['offline-booking-email'], { sharedLink: true })
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_REPORT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.offlineBookingEmailModule;
            const form = module.get('editReportPageForm').toJS();
            const result = {
                from: form.from,
                to: form.to,
                fromName: form.fromName,
                subject: form.subject,
                cc: form.cc,
                greeting: form.greeting,
                table: {
                    passengers: {
                        title: form.tablePassengersTitle,
                        row: {
                            title: form.tablePassengersRowTitle,
                            val: form.tablePassengersRowValue
                        }
                    },
                    other: form.tableOther
                },
            };

            return concat(of(OfflineBookingEmailStory.updateSaveLockEditReportPage(true)), http.put(`email-offline-booking/save-report`, result).pipe(
                dispatchActions(
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditReportPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Record updated`)),
                    ],
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditReportPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_REPORT_PAGE_SEND),
        mergeMap(() => {
            const module = state.value.offlineBookingEmailModule;
            const form = module.get('editReportPageModalForm').toJS();
            const mainForm = module.get('editReportPageForm').toJS();
            const result = {
                email: form.email,
                paymentType: form.type,
                report: {
                    from: mainForm.from,
                    to: mainForm.to,
                    fromName: mainForm.fromName,
                    subject: mainForm.subject,
                    cc: mainForm.cc,
                    greeting: mainForm.greeting,
                    table: {
                        passengers: {
                            title: mainForm.tablePassengersTitle,
                            row: {
                                title: mainForm.tablePassengersRowTitle,
                                val: mainForm.tablePassengersRowValue
                            }
                        },
                        other: mainForm.tableOther
                    },
                }
            };
            const { brand, locale } = form;
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(OfflineBookingEmailStory.updateSaveLockEditReportPage(true)), http.post(`email-offline-booking/preview-report`, result, { ...params }).pipe(
                dispatchActions(
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditReportPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Email was sent`)),
                        OfflineBookingEmailStory.showSendModalOnEditReportPage(false),
                        RootStory.resetForm('editReportPageModalForm')
                    ],
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditReportPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_SEND),
        mergeMap(() => {
            const module = state.value.offlineBookingEmailModule;
            const form = module.get('editPageModalForm').toJS();
            const mainForm = module.get('editPageForm').toJS();
            const [brand, locale] = instanceManager.parseId(module.get('editPageRecordId'));
            const result = {
                email: form.email,
                paymentType: form.type,
                header: {
                    logo: mainForm.headerLogo,
                    contactInfo: mainForm.contactInfo
                },
                body: {
                    from: mainForm.from,
                    fromName: mainForm.fromName,
                    subject: mainForm.subject,
                    bcc: mainForm.bcc,
                    greeting: mainForm.greeting,
                    card: mainForm.card,
                    bank: mainForm.bank,
                    info: mainForm.info,
                    table: {
                        passengers: {
                            title: mainForm.tablePassengersTitle,
                            row: {
                                title: mainForm.tablePassengersRowTitle,
                                val: mainForm.tablePassengersRowValue
                            }
                        },
                        other: mainForm.tableOther
                    },
                    aCard: mainForm.aCard,
                    aBank: mainForm.aBank,
                    aInfo: mainForm.aInfo
                },
                footer: {
                    content: mainForm.footer
                }
            };
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(OfflineBookingEmailStory.updateSaveLockEditPage(true)), http.post(`email-offline-booking/preview`, result, { ...params}).pipe(
                dispatchActions(
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Email was sent`)),
                        OfflineBookingEmailStory.showSendModalOnEditReportPage(false),
                        RootStory.resetForm('editPageModalForm')
                    ],
                    () => [
                        OfflineBookingEmailStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION),
        mergeMap(({ payload }: Action<{ files: Array<File>, templateName: string | null, mailType: string | null }>) => {
            const module = state.value.offlineBookingEmailModule;
            const id = module.get('editPageRecordId');
            const params = instanceManager.buildHeaders([module.get('editPageBrand'), module.get('editPageLocale')]);
            const files = payload.files;

            return concat(of(OfflineBookingEmailStory.updateUploadDocumentsLockEditPage(true)), zip(...files.map(readToBase64)).pipe(
                switchMap((results: Array<IFileData>) => concat(http.post('document-email-message-template', { documents: results, type: payload.mailType, instanceId: id, ...{ templateName: payload.templateName } }, params)).pipe(
                    switchMap((rs: viewDocumentRs) => {
                            // const collection: Array<IDocumentData> = rs.body.documents;
                            const collection: Array<any> = rs.body.documents;
                            const actions = [
                                OfflineBookingEmailStory.updateUploadDocumentsLockEditPage(false)
                            ];

                            if (!collection.length) {
                                return actions;
                            }
                            const documentsMapping = collection.map(({ id: documentId }) => ({
                                templateId:  id,
                                documentId
                            }));

                            return [
                                OrmStory.populateDocuments(collection),
                                OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_OPTION_TEMPLATE),
                                SystemStory.showNotification(SystemNotification.success('Success', `Document ${ collection.length > 1 ? 's' : ''} uploaded`)),
                            ];
                        }
                    ))
                ))
            );
        }),
    ),
    action.pipe(
        ofType(OfflineBookingEmailStory.EDIT_PAGE_DOWNLOAD_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(OfflineBookingEmailStory.updatePickedDocumentEditPage(payload)), http.get(`document-email-message-template/download/${ payload }`, { observe: 'response', responseType: 'text' }).pipe(
            mergeMap((rs: HttpResponse<string>) => {
                tryToSaveFile(rs);

                return of(OfflineBookingEmailStory.updatePickedDocumentEditPage(null));
            })
        ))),
    ),
);
