import { Pagination } from '@aclass/core/rest/pagination';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'adm-dg-pagination',
    templateUrl: './dg-pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DgPaginationComponent implements AfterViewInit, OnInit, OnDestroy {

    @HostBinding('class.d-inline-block') inlineBlock = true;

    form: FormGroup;

    pageSizes: Array<number> = Pagination.pageSizes;

    @Input() clrDgPage;

    @Input() clrDgTotalItems;

    @Input() clrDgPageSize: number;

    @Output() clrDgRefresh: EventEmitter<ClrDatagridStateInterface> = new EventEmitter<ClrDatagridStateInterface>();

    private subscriptions: Array<Subscription> = [];

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    /**
     * Calculates total pages count
     */
    get pagesCount(): number {
        return this.clrDgTotalItems ? Math.ceil(this.clrDgTotalItems / this.clrDgPageSize) : 0;
    }

    get pageSize(): number {
        if (!this.form) {
            return 0;
        }

        return Number(this.form.controls.size.value);
    }

    get page(): number {
        if (!this.form || this.form.controls.page.invalid) {
            return 1;
        }

        return Math.max(1, Math.min(parseInt(this.form.controls.page.value), this.pagesCount));
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.form = new FormGroup({
            size: new FormControl(this.clrDgPageSize, [Validators.required]),
            page: new FormControl(this.clrDgPage, [Validators.required, Validators.pattern('^\\d+$'), Validators.min(1)]),
        });
        this.subscriptions.push(
            this.form.controls.page.valueChanges.pipe(debounceTime(500)).subscribe(v => {
                if (this.form.controls.page.invalid || v > this.pagesCount) {
                    return;
                }
                this.emit();
            })
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        // Clarity emit pagination changes after component init, simulate
        this.emit();
    }

    /**
     * Refreshes view
     */
    applyChanges() {
        this.form.controls.size.setValue(this.clrDgPageSize, { emitEvent: false });
        this.form.controls.page.setValue(this.clrDgPage, { emitEvent: false });
        this.cdr.detectChanges();
    }

    /**
     * Switch current page value to 1
     */
    runToStart() {
        this.form.controls.page.setValue(1);
    }

    /**
     * Switch current page value to last
     */
    runToEnd() {
        this.form.controls.page.setValue(this.pagesCount);
    }

    /**
     * Switch current page value to next
     */
    runStepForward() {
        const next = Math.min(this.pagesCount, this.page + 1);
        if (this.page === next) {
            return;
        }

        this.form.controls.page.setValue(next);
    }

    /**
     * Switch current page value to previous
     */
    runStepBackward() {
        const next = Math.max(1, this.page - 1);
        if (this.page === next) {
            return;
        }

        this.form.controls.page.setValue(next);
    }

    private emit() {
        const page: number = this.page - 1;
        const pageSize: number = this.pageSize;
        const to = (page * pageSize + pageSize) - 1;
        this.clrDgRefresh.emit({
            page: {
                from: page * pageSize,
                to: this.clrDgTotalItems ? Math.min(this.clrDgTotalItems, to) : to,
                size: pageSize,
            },
        });
    }
}
