import { RouterManager } from '@aclass/admin/managers';
import { GeneralRemarkStory } from '@aclass/admin/storage/actions';
import { IAdminState, IGeneralRemarkModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class GeneralRemarkSearchStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:general-remark-search';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<IGeneralRemarkModuleState['searchPageInitialized']>(['generalRemarkModule', 'searchPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(GeneralRemarkSearchStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(GeneralRemarkStory.dryRunSearchPage());
    }
}
