import { ICountryData, IPCTourPackageData } from '@aclass/admin/storage/models';
import { ISendEmailState } from '@aclass/admin/storage/states/send.email.state';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IUserData } from '@aclass/core/storage/models/user';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IOpportunityModuleState extends IModuleState, ISendEmailState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Packages loading spinner status
     */
    searchPagePackageSearchLock: boolean | null;

    /**
     * Pipeline level loading spinner status
     */
    searchPagePipelineLevelSearchLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of pipeline level ids
     */
    searchPagePipelineLevels: List<IPipelineLevelData['id']>;

    /**
     * A list of user ids
     */
    searchPageAssigned: List<IUserData['id']>;

    /**
     * A list of package ids
     */
    searchPagePackages: List<IPCTourPackageData['id']>;

    /**
     * A list of opportunity ids from ORM
     */
    searchPageResults: List<IOpportunityData['id']>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The id of opportunity
     */
    editPageRecordId: IOpportunityData['id'] | null;

    /**
     * The delivery's metadata
     */
    editPageDeliveryMeta: Map<string, string> | null;

    /**
     * Block expand state
     */
    editPageStackViewSegments: Map<string, boolean> | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    editPageUserSearchLock: boolean | null;

    /**
     * A list of user ids
     */
    editPageUsers: List<number>;

    /**
     * Trigger to identify that search is running
     */
    editPagePipelineLevelSearchLock: boolean | null;

    /**
     * A list of pipeline level ids
     */
    editPagePipelineLevels: List<number>;

    /**
     * Trigger to identify that search is running
     */
    editPageCountrySearchLock: boolean | null;

    /**
     * A list of pipeline level ids
     */
    editPageCountries: List<number>;

    /**
     * Trigger to identify that search is running
     */
    editPageUploadDocumentsLock: boolean | null;

    /**
     * Trigger to disable download or remove buttons
     */
    editPagePickedIdDocumentLock: string | null;

    /**
     * Trigger to identify that remove is running
     */
    editPageDeliverySaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Identifies that all data for merged is loaded
     */
    editPageMergerInitialized: null | boolean;

    /**
     * Merger opened state
     */
    editPageMergerOpened: null | boolean;

    /**
     * Vamoos reloading state
     */
    editPageVamoosFlightsReloadLock: null | boolean;

    /**
     * Edit form data
     */
    editPageMergerSearchForm: Map<string, any>;

    /**
     * Trigger to identify that search is running
     */
    editPageMergerSearchLock: null | boolean;

    /**
     * A list of opportunity ids
     */
    editPageMergerOpportunities: List<ICountryData['id']>;

    /**
     * A list of opportunity ids
     */
    editPageMergerSelectedOpportunities: List<IOpportunityData['id']>;

    /**
     * Trigger to identify that search is running
     */
    editPageMergerForgetLock: null | boolean;

    /**
     * Customer merger opened state
     */
    editPageCustomerMergerId: ICustomerData['id'] | null;
}

export const INITIAL_OPPORTUNITY_STATE = createTypedState<IOpportunityModuleState>({
    searchPageInitialized: null,
    searchPageLock: null,
    searchPagePackageSearchLock: null,
    searchPagePipelineLevelSearchLock: null,
    searchPageCollapsed: null,
    searchPageForm: { },
    searchPagePipelineLevels: [],
    searchPageAssigned: [],
    searchPagePackages: [],
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    editPageRecordId: null,
    editPageDeliveryMeta: null,
    editPageStackViewSegments: null,
    editPageSaveLock: null,
    editPageUserSearchLock: null,
    editPageUsers: [],
    editPagePipelineLevelSearchLock: null,
    editPagePipelineLevels: [],
    editPageCountrySearchLock: null,
    editPageCountries: [],
    editPageUploadDocumentsLock: null,
    editPagePickedIdDocumentLock: null,
    editPageDeliverySaveLock: null,
    editPageForm: { },
    editPageMergerInitialized: null,
    editPageMergerOpened: null,
    editPageVamoosFlightsReloadLock: null,
    editPageMergerSearchForm: { },
    editPageMergerSearchLock: null,
    editPageMergerOpportunities: [],
    editPageMergerSelectedOpportunities: [],
    editPageMergerForgetLock: null,
    editPageCustomerMergerId: null,
    sendEmailOpened: null,
    sendEmailOpenedCalendar: null,
    sendEmailTemplateForm: { },
    sendEmailMessageForm: { },
    sendEmailTemplateIds: [],
    sendEmailTemplateSearchLock: null,
    sendEmailMessageId: null,
    sendEmailDocumentIds: [],
});
