import { IAppState } from '@aclass/core/storage/states/app.state';
import { NgRedux } from '@angular-redux/store';
import { Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StoreAttachForm } from './store.attach.form';

@Directive({
    selector: 'form[attach]:not([formGroup])'
})
export class StoreAttachFormBaseDirective extends StoreAttachForm {

    constructor(
        protected ngRdx: NgRedux<IAppState>,
        protected form: NgForm
    ) {
        super();
    }
}
