import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'adm-dg-link',
    template: `
        <a [routerLink]="link" [queryParams]="params" class="b-dg-cell-link" [target]="target">
            <ng-content></ng-content>
        </a>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DgLinkComponent {

    @HostBinding('class.b-dg-cell-wrapper') yes = true;

    @Input() link: Array<string | number> | string | number = [];

    @Input() params: { [key: string]: any } | null = null;

    @Input() target: '_blank' | '_self' | '_parent' | '_top' | null = null;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    applyChanges() {
        this.cdr.detectChanges();
    }
}
