import { Component, Input, OnInit } from '@angular/core';
import {
    IChartistData, IPieChartOptions
} from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';

@Component({
    selector: 'adm-bar-chart',
    template: `
        <x-chartist
          [type]="type"
          [data]="data"
          [options]="options"
          [events]="events"
        ></x-chartist>
    `,
})
export class AdmBarChartComponent implements OnInit {
    @Input() params: IChartistData | undefined;
    @Input() settings: IPieChartOptions | undefined;

    type: ChartType = 'Pie';
    data: IChartistData;
    options: IPieChartOptions;
    events: ChartEvent = { };

    ngOnInit(): void {
        const defaultData = {
            labels: [],
            series: []
        };
        const defaults = {
            donut: true,
            chartPadding: 150,
            width: '500px',
            height: '500px',
            donutWidth: '50%',
            labelOffset: 80,
            labelPosition: 'outside',
            ignoreEmptyValues: true
        };
        this.data = (typeof this.params === 'object') ? { ...defaultData, ...this.params } : defaultData;
        this.options = (typeof this.settings === 'object') ? { ...defaults, ...this.settings } : defaults;
    }
}
