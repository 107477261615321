import { Customer } from '@aclass/admin/storage/models/customer';
import { Document } from '@aclass/admin/storage/models/document';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDocumentCustomerMappingData {

    readonly documentId: string | null;

    readonly customerId: string | null;
}

@Model({
    name: 'DocumentCustomerMapping',
    idAttribute: 'documentId'
})
export class DocumentCustomerMapping extends Entity<IDocumentCustomerMappingData> {

    @Fk({ to: 'Document', relatedName: 'relatedDocumentCustomerMappings' }) readonly documentId: Document | null;

    @Fk({ to: 'Customer', relatedName: 'relatedDocumentCustomerMappings' }) readonly customerId: Customer | null;
}
