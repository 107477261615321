import {
     SoldoutEditStateResolver
} from '@aclass/admin/resolvers';
import {
    AdditionalProductStory,
    OrmStory,
    PackageItineraryStory,
    QuoteStory,
    RootStory,
    SoldoutProductStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { ISoldoutProductData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const soldoutProductModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(SoldoutProductStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.soldoutModule.get('searchPageInitialized')),
        switchMap(() => [
            SoldoutProductStory.submitOnSearchPage(),
            SoldoutProductStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SoldoutProductStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload}: Action<ISearchOptions>) => {
            const module = state.value.soldoutModule;
            const brand = payload ? null : module.getIn(['searchPageForm', 'brand']);
            const name = payload ? null : module.getIn(['searchPageForm', 'name']);
            const type = payload ? null : module.getIn(['searchPageForm', 'type']);
            const deleted = typeof module.getIn(['searchPageForm', 'deleted']) === 'boolean' ? module.getIn(['searchPageForm', 'deleted']) : false;
            const rq: DataSearchRqData = {
                where: {
                    brand,
                    productType: type,
                    pcTourId: null,
                    pcCombinationId: null,
                    name,
                    deleted,
                },
                pagination: module.get('searchPagePagination'),
                order: module.get('searchPageOrder'),
            };

            return http.post('stop-sales/headless-product-stop-sales-search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<Partial<ISoldoutProductData>>>>) => {

                    return [
                        OrmStory.populateSoldouts(rs.body.results),
                        SoldoutProductStory.updatePaginationOnSearchPage(rs.body.pagination),
                        SoldoutProductStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(SoldoutProductStory.PRODUCT_OPTION_SEARCH),
        mergeMap(({ payload }: Required<Action<{ brand: string, productType: string, productId: number, name: string | null, isActive: boolean | null }>>) => {
            const productId = payload.productId;
            const rq: DataSearchRqData = {
                where: {
                    productId: payload.productId,
                    productType: payload.productType,
                    name: payload.name || null,
                    deleted: false,
                    active: payload.isActive,
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(PackageItineraryStory.dayUpdateSearchOptionLock('uid', true)), http.post('tw/search-options-with-contracts', rq).pipe(
                dispatchActions((rs: Response<any>) => [
                    SoldoutProductStory.productUpdateOptions(rs.body.results),
                    SoldoutProductStory.productIdUpdateOptions(productId, rs.body.results.map(r => r.id)),
                    OrmStory.populateTwOptions(rs.body.results.map(({ id, name, contractId }) => ({ id, name, contractId }))),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SoldoutProductStory.CREATE_PAGE_DRY_RUN),
        mergeMap(({ payload }: Required<Action<{ brand: string, productType: string, productId: number | null, name: string | null }>>) => {
            const rq: DataSearchRqData = {
                where: {
                    brand: payload.brand,
                    productType: payload.productType,
                    pcTourId: null,
                    pcCombinationId: null,
                    name: payload.name,
                },
                pagination:  new Pagination().all(),
                order: {
                    by: 'name',
                    isReverse: false
                }
            };

            return concat(of(QuoteStory.updateSearchLockHotelPicker(true)), http.post('tw/headless-product-search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<any>>>) => {
                    return [
                        OrmStory.populateTwProducts(rs.body.results),
                        SoldoutProductStory.importProductsIds(rs.body.results.map(r => r.id)),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(SoldoutProductStory.CREATE_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<any> | null) => {
            let data: Map<string, any>;
            let rq: any;
            if (payload) {
                data = state.value.soldoutModule.get('editPageForm');
                rq = {
                    brand: data.get('brand'),
                    productType: data.get('productType'),
                    productId: data.get('productId'),
                    optionId: data.get('optionId'),
                    start: payload.from,
                    end: payload.to,
                };
            } else {
                data = state.value.soldoutModule.get('createPageForm');
                const options = Array.isArray(data.get('optionId').toJS()) ? data.get('optionId').toJS().map(item => item.id) : [data.get('optionId').toJS().id];
                const ranges = data.get('dates').toJS().map(item => {
                    return {
                        start: item.dateRange.from,
                        end: item.dateRange.to};
                });
                rq = {
                    brand: data.get('brands'),
                    productType: data.get('productType'),
                    productId: data.get('productId').id,
                    options,
                    ranges
                };
            }

            return concat(of(SoldoutProductStory.updateSearchPageInitState(true)), http.post('stop-sales', rq).pipe(
                    dispatchActions((rs: Response<any>) => [
                            SystemStory.showNotification(SystemNotification.success('Success', `Stop-sales record created`)),
                            SoldoutProductStory.submitOnSearchPage(),
                            RootStory.resetForm(),
                            SystemStory.navigate(['stop-sales', rs.body.stopSaleId], { sharedLink: true})
                        ],
                    )),
                );
            },
        )),
    action.pipe(
        ofType(SoldoutProductStory.ADD_IN_GROUP_PAGE_SUBMIT),
        mergeMap(({ payload}: Action<any>) => {
                const data: Map<string, any> = state.value.soldoutModule.get('editPageForm');
                const rq: any = {
                    brand: data.get('brand'),
                    productType: data.get('productType'),
                    productId: data.get('productId'),
                    optionId: payload.id,
                    from: payload.from,
                    to: payload.to,
                };

                return http.put(`stop-sales/${payload.id}`, rq).pipe(
                    dispatchActions((rs: Response<any>) => [
                            SystemStory.showNotification(SystemNotification.success('Success', `Stop-sales record updated`)),
                            SoldoutProductStory.updateFormOnEditPage(rs.body.data),
                            SoldoutProductStory.updateFormOnEditPagePeriod(rs.body.data.allPeriodsForProduct),
                        ],
                    )
                );
            },
        )),
    action.pipe(
        ofType(SoldoutProductStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload}: Action<ISoldoutProductData['id']>) => http.get(`stop-sales/${payload}`).pipe(
            dispatchActions((rs: Response<any>) => {

                return [
                        SoldoutProductStory.updateFormOnEditPage(rs.body.data),
                        // SoldoutProductStory.updateFormOnEditPagePeriod(rs.body.data.period),
                        SoldoutProductStory.updateFormOnEditPagePeriod(rs.body.data.allPeriodsForProduct),
                        SoldoutProductStory.updateIdEditPage(rs.body.data.id),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(SoldoutEditStateResolver.STOP_EVENT_NAME),
                ]
            )
        ))
    ),
    action.pipe(
        ofType(SoldoutProductStory.EDIT_PAGE_REMOVE),
        mergeMap(({ payload}: Action<number>) => {
            const id = payload ? payload : state.value.soldoutModule.get('editPageRecordId');

            return http.delete(`stop-sales/${id}`).pipe(
                dispatchActions(
                    () => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Sold-out period removed`)),
                        SoldoutProductStory.submitOnSearchPage(),
                        // SystemStory.navigate(['stop-sales'], { sharedLink: true })
                    ],
                    () => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SoldoutProductStory.SEARCH_PAGE_REMOVE),
        mergeMap(({ payload }: Action<number>) => {

            return http.delete(`stop-sales/${payload}`).pipe(
                dispatchActions(
                    () => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Soldout data removed`)),
                        SoldoutProductStory.submitOnSearchPage(),
                        SystemStory.navigate(['stop-sales'], { sharedLink: true })
                    ],
                    () => [
                        AdditionalProductStory.updateSaveLockEditPage(false),
                    ]
                )
            );
        }),
    ),
);
