import { FormManager } from '@aclass/admin/managers';
import { AclStory, AppStory, SystemStory } from '@aclass/admin/storage/actions';
import { IRolePermissionData } from '@aclass/admin/storage/models';
import { IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { List } from 'immutable';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-acl-edit',
    templateUrl: './edit.component.html',
    styleUrls: [],
})
export class AclEditComponent implements OnInit, OnDestroy {

    ff: ReactiveFormFactory;

    dff: ReactiveFormFactory;

    rolePermissions: Array<IRolePermissionData>;

    shouldLogout: boolean;

    roleNames: Array<string>;

    removeModalLock: boolean;

    private _saveLoading: boolean;

    private _showProgress: boolean;

    private _showRemoveModal: boolean;

    private subscriptions: Array<Subscription> = [];

    get saveLoading(): boolean {
        return this._saveLoading;
    }

    get showProgress() {
        return this._showProgress;
    }

    get showRemoveModal(): boolean {
        return this._showRemoveModal;
    }

    set showRemoveModal(v: boolean) {
        this.ngRdx.dispatch(AclStory.updateShowRemoveModalOnEditPage(v));
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.ff = new ReactiveFormFactory({
            name: 'Role',
            attach: ['aclModule', 'editPageForm'],
            controls: {
                name: new FormControl(null, [Validators.required, Validators.maxLength(255), Validators.pattern(/^[a-zA-Z0-9]*$/)]),
            },
        });
        this.dff = new ReactiveFormFactory({
            name: 'Delete role data',
            attach: ['aclModule', 'editPageDeleteForm'],
            controls: {
                name: new FormControl(null, [Validators.required]),
            },
        });
        this.rolePermissions = [];
        this.shouldLogout = false;
        this.roleNames = [];
        this.removeModalLock = false;
        this._saveLoading = false;
        this._showRemoveModal = false;
        this.subscriptions.push(
            this.formManager.onReset.subscribe((name: string) => {
                if (name === 'delete') {
                    this.dff.form.reset();

                    return;
                }
                this.ff.form.reset();
            })
        );
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['aclModule', 'editPageSaveLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._saveLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<List<IRolePermissionData>>(['aclModule', 'editPageRolePermissions'])
                .subscribe(records => {
                    this.rolePermissions = records.toJS();
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['aclModule', 'editPageShouldLogout'])
                .pipe(filter(v => v !== null))
                .subscribe(v => {
                    this.shouldLogout = v;
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<List<string>>(['aclModule', 'editPageRoleNames'])
                .subscribe(records => {
                    this.roleNames = records.toArray();
                    const roleValue = this.dff.form.controls.name.value;
                    if (!roleValue || !this.roleNames.includes(roleValue)) {
                        this.dff.form.controls.name.setValue(this.roleNames[0]);
                    }
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['aclModule', 'showRemoveModal'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._showRemoveModal = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['aclModule', 'removeModalLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this.removeModalLock = v)
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() updateRolePermissions = () => AclStory.updateRolePermissionsOnEditPage(this.rolePermissions);

    @dispatch() logout = () => AppStory.logout();

    @dispatch() save = () => AclStory.submitOnEditPage();

    @dispatch() removeRole = () => AclStory.removeRoleOnEditPage();

    runSave = () => {
        if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.save();
        this.ff.form.markAsPristine();
    }

    runRemoveRole = () => {
        if (!this.dff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.dff));

            return;
        }

        this.removeRole();
    }

    openRemoveModal = () => {
        if (!this.roleNames.length) {
            this.ngRdx.dispatch(SystemStory.showNotification(SystemNotification.error('Error', 'You can\'t remove the last role')));

            return;
        }

        this.showRemoveModal = true;
    }

    runUpdatePermissions = () => {
        this.updateRolePermissions();
        this.ff.form.markAsDirty();
    }
}
