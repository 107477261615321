import { IOfflineBookingEmailData, IOfflineBookingReport } from '@aclass/admin/storage/models';
import { IOfflineBookingEmailModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'offline-booking-email'
})
export class OfflineBookingEmailStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_REPORT_PAGE_DRY_RUN: string;

    @Task() static EDIT_REPORT_PAGE_UPDATE_INIT_STATE: string;

    @Task() static EDIT_REPORT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_REPORT_PAGE_UPDATE_DATA: string;

    @Task() static EDIT_REPORT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_REPORT_PAGE_SHOW_SEND_MODAL: string;

    @Task() static EDIT_REPORT_PAGE_SEND: string;

    @Task() static EDIT_PAGE_SEND: string;

    @Task() static EDIT_REPORT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION: string;

    @Task() static EDIT_PAGE_DOWNLOAD_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_PICKED_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE_DOCUMENT: string;

    static removeDocumentEditPage(v: string): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_REMOVE_DOCUMENT, payload: v };
    }

    static updateUploadDocumentsLockEditPage(v: boolean | null): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK, payload: v };
    }

    static updatePickedDocumentEditPage(v: string | null): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT, payload: v };
    }

    static downloadDocumentEditPage(v: string): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_DOWNLOAD_DOCUMENT, payload: v };
    }

    static uploadDocumentsRevisionEditPage(payload: { files: Array<File>, templateName: string | null, mailType: string | null }): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION, payload };
    }

    static dryRunSearchPage(): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IOfflineBookingEmailModuleState['searchPageInitialized']): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IOfflineBookingEmailModuleState['searchPageCollapsed']): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(payload: IOfflineBookingEmailModuleState['searchPageLock']): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<IOfflineBookingEmailData['id']>): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static importInstancesOnSearchPage(payload: Array<string>): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_IMPORT_INSTANCES, payload };
    }

    static submitOnSearchPage(): Action {
        return { type: OfflineBookingEmailStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IOfflineBookingEmailModuleState['editPageRecordId']): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateProductIdEditPage(payload: IOfflineBookingEmailModuleState['editPageRecordId']): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IOfflineBookingEmailModuleState['editPageSaveLock']): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnEditPage(): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_SUBMIT };
    }

    static dryRunEditReportPage(): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_DRY_RUN };
    }

    static updateEditReportPageInitState(payload: IOfflineBookingEmailModuleState['editReportPageInitialized']): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateFormOnEditReportPage(payload): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_FORM, payload };
    }

    static updateDataOnEditReportPage(payload: IOfflineBookingReport): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_DATA, payload };
    }

    static updateSaveLockEditReportPage(payload: IOfflineBookingEmailModuleState['editReportPageSaveLock']): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static showSendModalOnEditReportPage(v: boolean | null): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_SHOW_SEND_MODAL, payload: v };
    }

    static editReportPageSend(): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_SEND };
    }

    static editPageSend(): Action {
        return { type: OfflineBookingEmailStory.EDIT_PAGE_SEND };
    }

    static submitOnEditReportPage(): Action {
        return { type: OfflineBookingEmailStory.EDIT_REPORT_PAGE_SUBMIT };
    }
}
