import { Action } from '@aclass/core/storage/action';

export class BaseRootStory {

    static FORM_CHANGED = 'root/update-form';

    static RESET = 'root/reset';

    static updateForm(path: Array<any>, value: any): Action {
        return { type: BaseRootStory.FORM_CHANGED, payload: { path, value } };
    }

    static reset(): Action {
        return { type: BaseRootStory.RESET };
    }
}
