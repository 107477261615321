import { RedirectStory } from '@aclass/admin/storage/actions';
import { INITIAL_REDIRECT_STATE, IRedirectModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const redirectModuleReducer = (state: TypedState<IRedirectModuleState> = INITIAL_REDIRECT_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case RedirectStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case RedirectStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case RedirectStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case RedirectStory.SEARCH_PAGE_UPDATE_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_ID:
            return state.update('recordsSearchPageId', () => payload);
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('recordsSearchPageCollapsed', () => payload);
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_FORM:
            return state.update('recordsSearchPageForm', () => fromJS(payload));
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('recordsSearchPageLock', () => payload);
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('recordsSearchPagePagination', () => Map(payload));
        case RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_ORDER:
            return state.update('recordsSearchPageOrder', () => Map(payload));
        case RedirectStory.RECORDS_SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('recordsSearchPageResults', () => List(payload));
        case RedirectStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case RedirectStory.CREATE_PAGE_UPDATE_INSTANCE_ID:
            return state.update('createPageInstanceId', () => payload);
        case RedirectStory.EDIT_PAGE_UPDATE_REDIRECT_ID:
            return state.update('editPageRecordId', () => payload);
        case RedirectStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case RedirectStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL:
            return state.update('showRemoveModal', () => payload);
        case RedirectStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK:
            return state.update('removeModalLock', () => payload);
        case RedirectStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        default:
            return state;
    }
};
