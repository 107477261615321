import { Customer } from '@aclass/admin/storage/models/customer';
import { IPCTourPackageData, PCTourPackage } from '@aclass/admin/storage/models/pc.tour.package';
import { PipelineLevel } from '@aclass/admin/storage/models/pipeline.level';
import { User } from '@aclass/admin/storage/models/user';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IUserData } from '@aclass/core/storage/models/user';
import { Model as Entity } from 'redux-orm';

export interface IQuotePreviewData {

    readonly id: string | null;

    readonly brand: string | null;

    readonly brandLabel: string | null;

    readonly locale: string | null;

    readonly enquireDataId: number | null;

    readonly revision: number | null;

    readonly hash: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly flightAt: string | null;

    readonly followUpAt: string | null;

    readonly viewed: number | null;

    readonly sent: boolean | null;

    readonly assignedTo: IUserData['id'] | null;

    readonly packageId: IPCTourPackageData['id'] | null;

    readonly customerId: ICustomerData['id'] | null;

    readonly pipelineId: IPipelineLevelData['id'] | null;
}

@Model({
    name: 'QuotePreview'
})
export class QuotePreview extends Entity<IQuotePreviewData> {

    @Attr() readonly id: string | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly enquireDataId: number | null;

    @Attr() readonly revision: number | null;

    @Attr() readonly hash: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly flightAt: string | null;

    @Attr() readonly followUpAt: string | null;

    @Attr() readonly viewed: number | null;

    @Attr() readonly sent: boolean | null;

    @Fk({ to: 'User', relatedName: 'relatedQuotePreviews' }) readonly assignedTo: User | null;

    @Fk({ to: 'PCTourPackage', relatedName: 'relatedQuotePreviews' }) readonly packageId: PCTourPackage | null;

    @Fk({ to: 'Customer', relatedName: 'relatedQuotePreviews' }) readonly customerId: Customer | null;

    @Fk({ to: 'PipelineLevel', relatedName: 'relatedQuotePreviews' }) readonly pipelineId: PipelineLevel | null;
}
