import { SystemStory } from '@aclass/admin/storage/actions';
import { IAdminState } from '@aclass/admin/storage/states';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { DRIVERS, Locker } from 'angular-safeguard';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private storage: Locker,
        private router: Router
    ) {
    }

    /**
     * @inheritDoc
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const { url: redirect } = state;

        return this.checkLogin(redirect && !redirect.includes('login') ? redirect : null);
    }

    /**
     * @inheritDoc
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(redirect: string | null): boolean {
        if (this.ngRdx.getState().systemModule.getIn(['identity', 'token'])) {
            return true;
        }
        if (this.storage.get(DRIVERS.LOCAL, 'token')) {
            return true;
        }
        this.ngRdx.dispatch(SystemStory.updateRedirect(redirect));
        this.router.navigate(['/login']);

        return false;
    }
}
