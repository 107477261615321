import { SystemStory } from '@aclass/admin/storage/actions';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { NgRedux } from '@angular-redux/store';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'adm-placeholder',
    templateUrl: './placeholder.component.html'
})

export class PlaceholderComponent {
    @Input() name: string;
    @Input() tooltip: string;

    constructor(
        private ngRdx: NgRedux<IAdminState>
    ) {
    }

    copy = () => {
        this.ngCopy(this.name);

        this.ngRdx.dispatch(SystemStory.showNotification(SystemNotification.info('', `The ${this.name} copied to clipboard`)));
    }

    private ngCopy = (str: string) => {
        const el: any = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}
