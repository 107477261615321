import { LeadScoringStory } from '@aclass/admin/storage/actions';
import { ILeadScoringState, INITIAL_LEAD_SCORING_STATE } from '@aclass/admin/storage/states/lead.scoring.module.state';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const leadScoringModuleReducer = (state: TypedState<ILeadScoringState> = INITIAL_LEAD_SCORING_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case LeadScoringStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case LeadScoringStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case LeadScoringStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case LeadScoringStory.SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case LeadScoringStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case LeadScoringStory.EDIT_PAGE_UPDATE_PRODUCT_ID:
            return state.update('editPageRecordId', () => payload);
        case LeadScoringStory.EDIT_PAGE_UPDATE_FORM_VALUE:
            return state.update('editPageFormValue', () => fromJS(payload));
        case LeadScoringStory.EDIT_PAGE_UPDATE_DESTINATIONS:
            return state.update('editPageDestinations', () => fromJS(payload));
        case LeadScoringStory.EDIT_PAGE_UPDATE_AIRPORTS:
            return state.update('editPageAirports', () => fromJS(payload));
        case LeadScoringStory.EDIT_PAGE_UPDATE_ANSWERS:
            return state.update('editPageAnswers', () => fromJS(payload));
        case LeadScoringStory.EDIT_PAGE_UPDATE_LEAD_SCORE_VALUE:
            return state.update('editPageLeadScoreValue', () => fromJS(payload));
        case LeadScoringStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case LeadScoringStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        default:
            return state;
    }
};
