import { Daytable, IDateRange } from '@aclass/core/base/daytable';
import { forwardRef, AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidator, ControlValueAccessor, FormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { IMyDateRangeModel, IMyDrpOptions, MyDateRangePicker } from 'mydaterangepicker';
import { of, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'adm-date-range-picker',
    template: `
        <div class="b-date-picker-form">
            <my-date-range-picker class="b-date-picker-form__control" [formControl]="control" [options]="options" #dp></my-date-range-picker>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true,
        }
    ],
})
export class DateRangePickerComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, AsyncValidator {

    static DATE_REGEX: RegExp = /^([0-3][0-9])\.([0-1][0-9])\.([0-9]{4})$/;

    @HostBinding('class.b-date-picker') yes = true;

    @ViewChild('dp', { static: true }) picker: MyDateRangePicker;

    /**
     * Watched value
     */
    @Input() set value(v: IDateRange | null) {
        if (JSON.stringify(this._v) === JSON.stringify(v)) {
            return;
        }
        this._v = v;
        this.control.setValue(this.toPickerDate(v), { emitEvent: false });
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<IDateRange | null> = new EventEmitter<IDateRange | null>();

    /**
     * Show small version
     */
    @HostBinding('class.b-date-picker-sm') @Input() sm = true;

    @Input() settings: Partial<IMyDrpOptions & { minDate: Date | 'now' }> = { };

    control: FormControl;

    options: IMyDrpOptions;

    private _v: IDateRange | null;

    private _disabled = false;

    private subscriptions: Array<Subscription> = [];

    @HostBinding('class.disabled') get disabled(): boolean {
        return this._disabled;
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        const options = {
            dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' },
            dateFormat: 'dd.mm.yyyy',
            openSelectorOnInputClick: true,
            editableDateRangeField: true,
            inline: false,
            showClearDateRangeBtn: true,
            showClearBtn: false,
            showApplyBtn: false,
            showSelectDateText: false,
            minYear: 1990,
            maxYear: 2050,
            sunHighlight: false,
            width: 'auto',
            height: 'auto',
            selectionTxtFontSize: 'auto',
            ...this.settings
        };
        const { minDate } = options;
        if (minDate) {
            delete options.minDate;
            const date = new Daytable(minDate === 'now' ? new Date() : minDate).addDays(-1).toDate();
            options['disableUntil'] = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        }
        this.options = options;
        this.control = new FormControl({ value: this.toPickerDate(this._v), disabled: this._disabled });
        this.subscriptions.push(
            this.control.valueChanges.subscribe((v: IMyDateRangeModel) => {
                if (this._disabled) {
                    return;
                }
                const value = this.fromPickerDate(v);
                this._v = value;
                this.onChangeCallback(value);
                this.valueChange.emit(value);
                this.onTouchedCallback();
                this.trimRange();
            })
        );
        this.subscriptions.push(
            this.picker.inputFieldChanged.subscribe(e => {
                // This handler fix some invalid values for date range picker, ex.: 04.05.2000, 04.05.2000- 05.05.2000, 04.05.2000-06.05.2000, etc
                const v = (e.value || '').trim();
                if (this._disabled || e.valid || !v) {
                    return;
                }
                const [from, to] = (v.includes('-') ? v.split('-').map(r => r.trim()) : [v, v]).map(r => {
                    if (!DateRangePickerComponent.DATE_REGEX.test(r)) {
                        return null;
                    }
                    const [, d = 0, m = 0, y = 0] = <Array<any>>r.match(DateRangePickerComponent.DATE_REGEX);

                    return Daytable.isValidDate(y, m, d) ? new Daytable(new Date(y, m - 1, d, 0, 0, 0, 0)).toDateString() : null;
                });
                const dr: IDateRange = { from, to };
                if (!from || !to || JSON.stringify(this._v) === JSON.stringify(dr)) {
                    return;
                }
                this.control.setValue(this.toPickerDate(dr));
            })
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        // Do it outside angular
        setTimeout(() => {
            this.trimRange();
        });
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: IDateRange | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v: IDateRange | null) {
        this.value = v;
    }

    /**
     * @inheritDoc
     */
    onTouchedCallback: () => void = () => { };

    /**
     * @inheritDoc
     */
    onChangeCallback: (v: IDateRange | null) => void = () => { };

    /**
     * @inheritDoc
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const error: ValidationErrors | null = this.picker.invalidDateRange && !this._v && !this._disabled ? { date: 'Date range is not valid' } : null;

        return of(error);
    }

    /**
     * If start and and date same, hack view to show only one date
     */
    private trimRange() {
        const label = this.picker.selectionDayTxt || '';
        const [from = null, to = null] = label.includes('-') ? label.split('-').map(t => t.trim()) : [];
        if (from && from === to) {
            this.picker.selectionDayTxt = from;
            this.picker.titleAreaText = from;
        }
    }

    private toPickerDate(v: IDateRange | null): Partial<IMyDateRangeModel> | null {
        // Picker require from and to values
        if (!v || !v.from || !v.to) {
            return null;
        }
        const from = Daytable.fromDateString(<string>v.from).toDate();
        const to = Daytable.fromDateString(<string>v.to).toDate();

        return {
            beginDate: {
                year: from.getFullYear(),
                month: from.getMonth() + 1,
                day: from.getDate(),
            },
            endDate: {
                year: to.getFullYear(),
                month: to.getMonth() + 1,
                day: to.getDate(),
            }
        };
    }

    private fromPickerDate(v: IMyDateRangeModel): IDateRange | null {
        // Picker require from and to values
        if (!v || !v.beginDate || !v.endDate) {
            return null;
        }

        return {
            from: new Daytable(new Date(v.beginDate.year, v.beginDate.month - 1, v.beginDate.day, 0, 0, 0, 0)).toDateString(),
            to: new Daytable(new Date(v.endDate.year, v.endDate.month - 1, v.endDate.day, 0, 0, 0, 0)).toDateString()
        };
    }
}
