import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IQuoteTemplateModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of instances
     */
    searchPageInstances: List<string>;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * The brand-locale
     */
    editPageRecordId: string | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;
}

export const INITIAL_QUOTE_TEMPLATE_STATE = createTypedState<IQuoteTemplateModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageForm: { },
    searchPageInstances: [],
    createPageForm: { },
    editPageForm: { },
    editPageRecordId: null,
    editPageSaveLock: null,
});
