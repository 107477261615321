import { IQuestionnaireData } from '@aclass/admin/storage/models';
import { IQuestionnaireModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'questionnaire'
})
export class QuestionnaireStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_COPY_QUESTIONNAIRE: string;

    @Task() static CREATE_PAGE_UPDATE_QUESTIONNAIRE_ID: string;

    static SEARCH_PAGE_COPY_UPDATE_OPENED  = 'QUESTIONNAIRE_SEARCH_PAGE_COPY_UPDATE_OPENED';

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_QUESTIONNAIRE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_CREATE_FORM: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static RESULT_PAGE_DRY_RUN: string;

    @Task() static VIEW_PAGE_PREVIEW: string;

    @Task() static RESULT_PAGE_UPDATE_QUESTIONNAIRE_ID: string;

    @Task() static RESULT_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SHOW_RESULT_PAGE_DRY_RUN: string;

    @Task() static SHOW_RESULT_PAGE_UPDATE_QUESTIONNAIRE_RESULT_ID: string;

    @Task() static VIEW_PAGE_DOWNLOAD: string;

    @Task() static VIEW_PAGE_DELETE_TOGGLE: string;

    @Task() static VIEW_PAGE_UPDATE_SEARCH_LOCK: string;


    static dryRunSearchPage(): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_DRY_RUN };
    }

    static importRecordsOnSearchPage(items: Array<Object>): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_IMPORT_RECORDS, payload: items };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static submitOnSearchPage(refresh: boolean = false): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_SUBMIT, payload: refresh };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static submitOnCreatePage(): Action {
        return { type: QuestionnaireStory.CREATE_PAGE_SUBMIT };
    }

    static updateCopyOpenedOnSearchPage(v: null | boolean): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_COPY_UPDATE_OPENED, payload: v };
    }

    static dryRunEditPage(id: IQuestionnaireData['id']): Action {
        return { type: QuestionnaireStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateIdEditPage(id: IQuestionnaireData['id']): Action {
        return { type: QuestionnaireStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_ID, payload: id };
    }

    static updateFormOnCreatePage(payload: any): Action {
        return { type: QuestionnaireStory.EDIT_PAGE_CREATE_FORM, payload };
    }

    static updateFormOnEditPage(payload: any): Action {
        return { type: QuestionnaireStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static submitOnEditPage(): Action {
        return { type: QuestionnaireStory.EDIT_PAGE_SUBMIT };
    }

    static dryRunResultPage(id: string): Action {
        return { type: QuestionnaireStory.RESULT_PAGE_DRY_RUN, payload: id };
    }

    static importRecordsOnSearchResultPage(items: Array<Object>): Action {
        return { type: QuestionnaireStory.RESULT_SEARCH_PAGE_IMPORT_RECORDS, payload: items };
    }

    static updateIdResultPage(id: string): Action {
        return { type: QuestionnaireStory.RESULT_PAGE_UPDATE_QUESTIONNAIRE_ID, payload: id };
    }

    static dryRunShowResultPage(id: string): Action {
        return { type: QuestionnaireStory.SHOW_RESULT_PAGE_DRY_RUN, payload: id };
    }

    static updateIdShowResultPage(id: string): Action {
        return { type: QuestionnaireStory.SHOW_RESULT_PAGE_UPDATE_QUESTIONNAIRE_RESULT_ID, payload: id };
    }

    static previewOnViewPage(): Action {
        return { type: QuestionnaireStory.VIEW_PAGE_PREVIEW };
    }

    static downloadOnViewPage(id: number): Action {
        return { type: QuestionnaireStory.VIEW_PAGE_DOWNLOAD, payload: id };
    }

    static deleteToggleOnViewPage(id: number): Action {
        return { type: QuestionnaireStory.VIEW_PAGE_DELETE_TOGGLE, payload: id };
    }

    static updateSearchLockOnViewPage(payload: IQuestionnaireModuleState['viewPageLock']): Action {
        return { type: QuestionnaireStory.VIEW_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static copyQuestionnaireOnSearchPage(payload: IQuestionnaireData['id']): Action {
        return { type: QuestionnaireStory.SEARCH_PAGE_COPY_QUESTIONNAIRE, payload };
    }

    static updateQuestionnaireIdCreatePage(payload: IQuestionnaireData['id']): Action {
        return { type: QuestionnaireStory.CREATE_PAGE_UPDATE_QUESTIONNAIRE_ID, payload };
    }
}
