import { QuoteStory } from '@aclass/admin/storage/actions';
import { INITIAL_QUOTE_STATE, IQuoteModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const quoteModuleReducer = (state: TypedState<IQuoteModuleState> = INITIAL_QUOTE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case QuoteStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_ROUTER_MODE:
            return state.update('searchPageRouteMode', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case QuoteStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK:
            return state.update('searchPagePackageSearchLock', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK:
            return state.update('searchPagePipelineLevelSearchLock', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case QuoteStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVELS:
            return state.update('searchPagePipelineLevels', () => List(payload));
        case QuoteStory.SEARCH_PAGE_UPDATE_ASSIGNED:
            return state.update('searchPageAssigned', () => List(payload));
        case QuoteStory.SEARCH_PAGE_UPDATE_PACKAGES:
            return state.update('searchPagePackages', () => List(payload));
        case QuoteStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case QuoteStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case QuoteStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_RESOLVER_ID:
            return state.update('editPageResolverId', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_OPPORTUNITY_ID:
            return state.update('editPageOpportunityId', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_REVISION_ID:
            return state.update('editPageRevisionId', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_COLOR_RANGE:
            return state.update('editPageColorRange', () => payload ? fromJS(payload) : null);
        case QuoteStory.EDIT_PAGE_UPDATE_SWITCH_REVISION_LOCK:
            return state.update('editPageSwitchRevisionLock', () => payload);
        case QuoteStory.EDIT_PAGE_FORM_STATUS:
            return state.update('editPageFormEditStatus', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_ACTIVE_STATE:
            return state.update('editPageActiveState', () => payload);
        case QuoteStory.EDIT_PAGE_MERGE_PREPARED_STATE:
            return state.update('editPagePreparedStates', st => st.merge(fromJS(payload)));
        case QuoteStory.EDIT_PAGE_REMOVE_PREPARED_STATE:
            return state.update('editPagePreparedStates', st => st.delete(payload));
        case QuoteStory.EDIT_PAGE_CLEAR_PREPARED_STATES:
            return state.update('editPagePreparedStates', st => st.clear());
        case QuoteStory.EDIT_PAGE_MERGE_EDITED_STATE:
            return state.update('editPageEditedStates', st => st.merge(fromJS(payload)));
        case QuoteStory.EDIT_PAGE_REMOVE_EDITED_STATE:
            return state.update('editPageEditedStates', st => st.delete(payload));
        case QuoteStory.EDIT_PAGE_CLEAR_EDITED_STATES:
            return state.update('editPageEditedStates', st => st.clear());
        case QuoteStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_RECORDS_BONUSES:
            return state.update('editPageBonuses', () => List(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_EXTRA_PRICES_SELECT:
            return state.update('editExtraPricesInSelect', () => List(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_RECORDS_DISCOUNTS:
            return state.update('editPageDiscounts', () => List(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_RECORDS_ADDITIONAL_PRODUCTS:
            return state.update('editPageAdditionalProducts', () => List(payload));
        case QuoteStory.EDIT_PAGE_UPDATE_CAMPAIGN_SEARCH_LOCK:
            return state.update('editPageSearchCampaignLock', () => payload);
        case QuoteStory.EDIT_PAGE_UPDATE_ADDITIONAL_PRODUCT_SEARCH_LOCK:
            return state.update('editPageSearchAdditionalProductLock', () => payload);
        case QuoteStory.SEND_EMAIL_TOGGLE_OPEN:
            return state.update('sendEmailOpen', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_SWITCH_LOCK:
            return state.update('sendEmailSwitchLock', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_TEMPLATE:
            return state.update('sendEmailTemplate', () => fromJS(payload));
        case QuoteStory.SEND_EMAIL_UPDATE_OPPORTUNITY_ID:
            return state.update('sendEmailOpportunityId', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_REVISION_NR:
            return state.update('sendEmailRevisionNr', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_TEMPLATE_ID:
            return state.update('sendEmailTemplateId', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_FORM:
            return state.update('sendEmailForm', () => fromJS(payload));
        case QuoteStory.SEND_EMAIL_UPDATE_EMAIL_TEMPLATES:
            return state.update('sendEmailTemplates', () => List(payload));
        case QuoteStory.SEND_EMAIL_UPDATE_EMAIL_TEMPLATES_SEARCH_LOCK:
            return state.update('sendEmailSearchLock', () => payload);
        case QuoteStory.SEND_EMAIL_UPDATE_FETCH_LOCK:
            return state.update('sendEmailFetchLock', () => payload);
        case QuoteStory.SEND_EMAIL_SELECT_DOCUMENT:
            return state.update('sendEmailSelectedDocument', (records: List<string>) => records.push(payload));
        case QuoteStory.SEND_EMAIL_UPDATE_SELECTED_DOCUMENTS:
            return state.update('sendEmailSelectedDocument', () => List(payload));
        case QuoteStory.SEND_EMAIL_UPDATE_SAVE_LOCK:
            return state.update('sendEmailSaveLock', () => payload);
        case QuoteStory.GALLERY_PICKER_TOGGLE_OPEN:
            return state.update('galleryPickerOpen', () => payload);
        case QuoteStory.GALLERY_PICKER_UPDATE_SEARCH_LOCK:
            return state.update('galleryPickerSearchLock', () => payload);
        case QuoteStory.GALLERY_PICKER_UPDATE_OPPORTUNITY_ID:
            return state.update('galleryPickerOpportunityId', () => payload);
        case QuoteStory.GALLERY_PICKER_UPDATE_RECORDS:
            return state.update('galleryPickerRecords', () => List(payload));
        case QuoteStory.GALLERY_PICKER_UPDATE_FORM:
            return state.update('galleryPickerForm', () => fromJS(payload));
        case QuoteStory.HOTEL_PICKER_UPDATE_SEARCH_LOCK:
            return state.update('hotelPickerSearchLock', () => payload);
        case QuoteStory.SMALLTOUR_PICKER_UPDATE_SEARCH_LOCK:
            return state.update('smalltourPickerSearchLock', () => payload);
        case QuoteStory.HOTEL_PICKER_UPDATE_OPPORTUNITY_ID:
            return state.update('hotelPickerOpportunityId', () => payload);
        case QuoteStory.SMALLTOUR_PICKER_UPDATE_OPPORTUNITY_ID:
            return state.update('smalltourPickerOpportunityId', () => payload);
        case QuoteStory.HOTEL_PICKER_UPDATE_RECORDS:
            return state.update('hotelPickerRecords', () => List(payload));
        case QuoteStory.SMALLTOUR_PICKER_UPDATE_RECORDS:
            return state.update('smalltourPickerRecords', () => List(payload));
        case QuoteStory.CHANGE_TOUR_TOGGLE_OPEN:
            return state.update('changeTourOpen', () => payload);
        case QuoteStory.CHANGE_TOUR_UPDATE_OPPORTUNITY_ID:
            return state.update('changeTourOpportunityId', () => payload);
        case QuoteStory.CHANGE_TOUR_UPDATE_FORM:
            return state.update('changeTourForm', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_DESTINATION_SEARCH_LOCK:
            return state.update('changeTourDestinationSearchLock', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_TOUR_SEARCH_LOCK:
            return state.update('changeTourTourSearchLock', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_COMBINATION_SEARCH_LOCK:
            return state.update('changeTourCombinationSearchLock', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_DESTINATIONS:
            return state.update('changeTourDestinations', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_TOURS:
            return state.update('changeTourTours', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_COMBINATIONS:
            return state.update('changeTourCombinations', () => fromJS(payload));
        case QuoteStory.CHANGE_TOUR_UPDATE_FETCH_LOCK:
            return state.update('changeTourFetchLock', () => payload);
        case QuoteStory.CHANGE_TOUR_UPDATE_CMS_TOUR_ID:
            return state.update('changeTourCmsTourId', () => payload);
        default:
            return state;
    }
};
