import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IFileModuleState extends IModuleState {
    /**
     * Trigger to identify that search is running
     */
    searchLockImageGallery: boolean | null;

    /**
     * A list of image ids from ORM
     */
    searchResultsImageGallery: List<number>;

    /**
     * The pagination data
     */
    searchPaginationImageGallery: Map<string, number> | null;

    /**
     * Selected image id
     */
    selectedImageGallery: number | null;

    /**
     * Trigger to identify that update is running
     */
    editLockImageGallery: boolean | null;

    /**
     * Edit form data
     */
    editFormImageGallery: any;

    /**
     * Trigger to identify that update is running
     */
    uploadLockImageGallery: boolean | null;
}

export const INITIAL_FILE_MODULE_STATE = createTypedState<IFileModuleState>({
    searchLockImageGallery: null,
    searchResultsImageGallery: [],
    searchPaginationImageGallery: null,
    selectedImageGallery: null,
    editLockImageGallery: null,
    editFormImageGallery: { },
    uploadLockImageGallery: null,
});
