export interface IRange<T> {

    from: T;

    to: T;
}

export type Duration = IRange<number>;

/**
 * Helper which can be used as "Type Guard" to check that given value is [[IRange]]
 */
export function isRange<T>(v): v is IRange<T> {
    return v && typeof v === 'object' && 'from' in v && 'to' in v;
}

export function isDuration(v): v is Duration {
    return  isRange<number>(v) && Number.isInteger(v.from) && Number.isInteger(v.to);
}
