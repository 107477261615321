import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IImageData {

    readonly id: number | null;

    readonly alt: string | null;

    readonly title: string | null;

    readonly url: string | null;
}

@Model({
    name: 'Image'
})
export class Image extends Entity<IImageData> {

    @Attr() readonly id: number;

    @Attr() readonly alt: string;

    @Attr() readonly title: string;

    @Attr() readonly url: string;

    get relativeUrl(): string {
        return new URL(this.url).pathname;
    }
}
