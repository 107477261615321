import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'adm-dg-expand-button',
    templateUrl: './dg-expand-button.component.html',
})
export class DgExpandButtonComponent implements OnInit {

    @Input() expanded: boolean | undefined;

    onClick: EventEmitter<boolean>;

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.onClick = new EventEmitter<boolean>();
    }

    /**
     * Toggles expand status
     */
    click() {
        this.expanded = !this.expanded;
        this.onClick.emit(this.expanded);
    }

}
