import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IExtraPriceData {

    readonly id: number | null;

    readonly name: string | null;

    readonly creatorId: number | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly departureStart: number | null;

    readonly departureEnd: number | null;

    readonly value: number | null;

    readonly minPax: number | null;

    readonly maxPax: number | null;

    readonly startedAt: number | null;

    readonly endedAt: number | null;

    readonly autobinding: number | null;

    readonly allTours: number | null;

    readonly hash: string | null;

    readonly brand: string | null;

    readonly brandLabel: string | null;

    readonly locale: string | null;

    readonly destinations: Array<any>;

    readonly tours: Array<any>;

    readonly combinations: Array<any>;
}

@Model({
    name: 'ExtraPrice'
})
export class ExtraPrice extends Entity<IExtraPriceData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly creatorId: number | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly departureStart: number | null;

    @Attr() readonly departureEnd: number | null;

    @Attr() readonly value: number | null;

    @Attr() readonly minPax: number | null;

    @Attr() readonly maxPax: number | null;

    @Attr() readonly startedAt: number | null;

    @Attr() readonly endedAt: number | null;

    @Attr() readonly autobinding: number | null;

    @Attr() readonly allTours: number | null;

    @Attr() readonly hash: string | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly destinations: Array<any>;

    @Attr() readonly tours: Array<any>;

    @Attr() readonly combinations: Array<any>;
}
