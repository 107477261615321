import { ISplitTestModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'split-test'
})
export class SplitTestStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static VIEW_PAGE_DRY_RUN: string;

    @Task() static VIEW_PAGE_UPDATE_RECORD_ID: string;

    @Task() static VIEW_PAGE_UPDATE_SEARCH_FORM: string;

    @Task() static VIEW_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static VIEW_PAGE_UPDATE_TEST_NAME: string;

    @Task() static VIEW_PAGE_UPDATE_TEST_VALUE: string;

    @Task() static VIEW_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: SplitTestStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: ISplitTestModuleState['searchPageInitialized']): Action {
        return { type: SplitTestStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: ISplitTestModuleState['searchPageCollapsed']): Action {
        return { type: SplitTestStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: SplitTestStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(payload: ISplitTestModuleState['searchPageLock']): Action {
        return { type: SplitTestStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static importRecordsOnSearchPage(payload: Array<number>): Action {
        return { type: SplitTestStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(): Action {
        return { type: SplitTestStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunViewPage(payload: ISplitTestModuleState['viewPageRecordId']): Action {
        return { type: SplitTestStory.VIEW_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdViewPage(payload: ISplitTestModuleState['viewPageRecordId']): Action {
        return { type: SplitTestStory.VIEW_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateSearchFormOnViewPage(payload): Action {
        return { type: SplitTestStory.VIEW_PAGE_UPDATE_SEARCH_FORM, payload };
    }

    static updateSearchLockViewPage(payload: ISplitTestModuleState['viewPageSearchLock']): Action {
        return { type: SplitTestStory.VIEW_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateTestNameViewPage(payload: string): Action {
        return { type: SplitTestStory.VIEW_PAGE_UPDATE_TEST_NAME, payload };
    }

    static updateTestValueViewPage(payload: Array<number>): Action {
        return { type: SplitTestStory.VIEW_PAGE_UPDATE_TEST_VALUE, payload };
    }

    static submitOnViewPage(): Action {
        return { type: SplitTestStory.VIEW_PAGE_SUBMIT };
    }
}
