import { IAskData } from '@aclass/admin/components';
import { ModalManager } from '@aclass/admin/managers';
import { Action } from '@aclass/core/storage/action';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-modal-ask',
    templateUrl: './modal-ask.component.html',
})
export class ModalAskComponent implements OnInit, OnDestroy {

    /**
     * The title message of modal
     */
    title: string | SafeHtml;

    /**
     * The body message of modal
     */
    message: string | SafeHtml;

    /**
     * Yes button text. To disable button use [[false]].
     */
    yes: string | boolean | SafeHtml;

    /**
     * No button text. To disable button use [[false]]
     */
    no: string | boolean | SafeHtml;

    /**
     * Yes button text. To disable button use [[false]]
     */
    size: string | undefined;

    private _show: boolean;

    private awaiter: EventEmitter<boolean | undefined> | undefined;

    private subscriptions: Array<Subscription> = [];

    /**
     * Short code to set trigger value
     */
    set show(v: boolean) {
        if (!v && this.awaiter) {
            this.destroy();
        }
        this._show = v;
    }

    /**
     * Short code to get trigger value
     */
    get show(): boolean {
        return this._show;
    }

    constructor(
        private modalManager: ModalManager,
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this._show = false;
        type input = Action<{ listener: EventEmitter<boolean | undefined>, params?: Partial<IAskData> }>;
        this.subscriptions.push(
            this.modalManager.modal
                .pipe(filter((v: input) => v.type === ModalManager.ASK_OPEN))
                .subscribe((v: input) => {
                    if (this.awaiter) {
                        this.destroy();
                    }
                    this._show = true;
                    this.awaiter = v.payload.listener;
                    const data = v.payload.params || { };
                    this.title = 'title' in data ? data.title : '';
                    this.message = 'message' in data ? data.message : '';
                    this.yes = 'yes' in data ? data.yes : 'Yes';
                    this.no = 'no' in data ? data.no : 'No';
                    this.size = data.size;
                })
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        if (this.awaiter) {
            this.destroy();
        }
    }

    /**
     * Emits selected value to awaiter
     */
    runResolve(v: boolean = false) {
        this.destroy(v);
        this._show = false;
    }

    private destroy(v?: boolean) {
        this.awaiter.emit(v);
        // flush listener
        this.awaiter = undefined;
    }
}
