import { CmsTour } from '@aclass/admin/storage/models/cms.tour';
import { IDateRange } from '@aclass/core/base/daytable';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ICmsAirportPriceData {

    readonly date: IDateRange;

    readonly price: string | null;
}

export interface ICmsAirportData {

    readonly id: string | null;

    readonly name: string | null;

    readonly code: string | null;

    readonly prices: Array<ICmsAirportPriceData>;
}

@Model({
    name: 'CmsAirport'
})
export class CmsAirport extends Entity<ICmsAirportData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly code: string | null;

    @Attr() readonly prices: Array<ICmsAirportPriceData>;

    readonly relatedTours: QuerySet<CmsTour>;
}

