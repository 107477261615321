import { DashboardStory, OrmStory, } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IPCTourPackageData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IUserData } from '@aclass/core/storage/models/user';
import { DRIVERS, Locker } from 'angular-safeguard';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, ignoreElements, switchMap, tap } from 'rxjs/operators';

type searchRsItem = Partial<IOpportunityData> & {
    customerId: ICustomerData['id'];
    customerName: ICustomerData['name'];
    customerSurname: ICustomerData['surname'];
    assignedId: IUserData['id'];
    assignedUsername: IUserData['username'];
    packageId: IPCTourPackageData['id'];
    packageName: IPCTourPackageData['name'];
    packageCode: IPCTourPackageData['code'];
    pipelineId: IPipelineLevelData['id'];
    pipelineName: IPipelineLevelData['name'];
};

function updateLastSeen(storage: Locker, key: string, state: Array<any> | any, size = 10) {
    const prev: Array<any> | null = storage.get(DRIVERS.LOCAL, key);
    const now: Array<any> = Array.isArray(state) ? state : [state];

    storage.set(DRIVERS.LOCAL, key, Array.from(new Set(now.concat(Array.isArray(prev) ? prev : []))).slice(0, size - 1));
}

export const dashboardModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, storage }: IEpicDi) => merge(
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_OPPORTUNITY_DRY_RUN),
        filter(() => {
            const prev = storage.get(DRIVERS.LOCAL, 'lastViewedOpportunities');
            const now = state.value.dashboardModule.get('searchPageStorageOpportunityIds');

            return Array.isArray(prev) && JSON.stringify(prev) !== JSON.stringify(now);
        }),
        switchMap(() => {
            const st = storage.get(DRIVERS.LOCAL, 'lastViewedOpportunities');

            return [
                // Reset prev records state
                DashboardStory.importOpportunityRecordsOnSearchPage([]),
                DashboardStory.updateStorageOpportunityIdsOnSearchPage(Array.isArray(st) ? st : []),
                DashboardStory.submitSearchOpportunitiesOnSearchPage(),
            ];
        })
    ),
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_UPDATE_LAST_OPPORTUNITIES),
        tap(({ payload }: Action<IOpportunityData['id'] | Array<IOpportunityData['id']>>) => {
            updateLastSeen(storage, 'lastViewedOpportunities', payload);
        }),
        ignoreElements()
    ),
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_CUSTOMER_DRY_RUN),
        filter(() => {
            const prev = storage.get(DRIVERS.LOCAL, 'lastViewedCustomers');
            const now = state.value.dashboardModule.get('searchPageStorageCustomersIds');

            return Array.isArray(prev) && JSON.stringify(prev) !== JSON.stringify(now);
        }),
        switchMap(() => {
            const st = storage.get(DRIVERS.LOCAL, 'lastViewedCustomers');

            return [
                // Reset prev records state
                DashboardStory.importCustomerRecordsOnSearchPage([]),
                DashboardStory.updateStorageCustomersIdsOnSearchPage(Array.isArray(st) ? st : []),
                DashboardStory.submitSearchCustomersOnSearchPage(),
            ];
        })
    ),
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_UPDATE_LAST_CUSTOMERS),
        tap(({ payload }: Action<ICustomerData['id'] | Array<ICustomerData['id']>>) => {
            updateLastSeen(storage, 'lastViewedCustomers', payload);
        }),
        ignoreElements()
    ),
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_SUBMIT_OPPORTUNITIES),
        switchMap(() => {

            const rq: DataSearchRqData = {
                where: {
                    ids: state.value.dashboardModule.get('searchPageStorageOpportunityIds').toArray()
                },
                pagination: new Pagination().all(),
                order: state.value.dashboardModule.get('searchPageOrder'),
            };

            return concat(of(DashboardStory.updateOpportunitiesSearchLockOnSearchPage(true)), http.post(`enquire-data/search-opportunities-by-ids`, rq).pipe(
                switchMap((rs: Response<IDataSearchRs<Array<searchRsItem>>>) => [
                    OrmStory.populateCustomers(rs.body.results.map(r => ({ id: r.customerId, name: r.customerName, surname: r.customerSurname }))),
                    OrmStory.populateUsers(rs.body.results.filter(r => r.assignedId).map(r => ({ id: r.assignedId, username: r.assignedUsername }))),
                    OrmStory.populatePcTourPackages(rs.body.results.map(r => ({ id: r.packageId, name: r.packageName, code: r.packageCode }))),
                    OrmStory.populatePipelineLevels(rs.body.results.map(r => ({ id: r.pipelineId, name: r.pipelineName }))),
                    OrmStory.populateOpportunities(rs.body.results.map(r => ({
                        id: r.id,
                        brand: r.brand,
                        brandLabel: r.brandLabel,
                        locale: r.locale,
                        createdAt: r.createdAt,
                        updatedAt: r.updatedAt,
                        flightAt: r.flightAt,
                        followUpAt: r.followUpAt,
                        leadScore: r.leadScore,
                        interest: r.interest,
                        adults: r.adults,
                        children: r.children,
                        sent: r.sent,
                        customerId: r.customerId,
                        assignedTo: r.assignedId,
                        packageId: r.packageId,
                        pipelineId: r.pipelineId,
                        levelWonExists: r.levelWonExists,
                    }))),
                    DashboardStory.importOpportunityRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    DashboardStory.updateOpportunitiesSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(DashboardStory.SEARCH_PAGE_SUBMIT_CUSTOMERS),
        switchMap(() => {
            const rq: DataSearchRqData = {
                where: {
                    ids: state.value.dashboardModule.get('searchPageStorageCustomersIds').toArray()
                },
                pagination: new Pagination().all(),
                order: state.value.dashboardModule.get('searchPageCustomerOrder'),
            };

            return concat(of(DashboardStory.updateCustomersSearchLockOnSearchPage(true)), http.post(`customers/search-customers-by-ids`, rq).pipe(
                switchMap((rs: Response<IDataSearchRs<Array<ICustomerData>>>) => [
                    OrmStory.populateCustomers(rs.body.results),
                    DashboardStory.importCustomerRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    DashboardStory.updateCustomersSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
);
