import { RouterManager } from '@aclass/admin/managers';
import { SettingsStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISettingsModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class ReturningCustomersEditStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:retuning-customers-edit';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISettingsModuleState['returningCustomersPageInitialized']>(['settingsModule', 'returningCustomersPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(ReturningCustomersEditStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(SettingsStory.dryRunReturningCustomersPage());
    }
}
