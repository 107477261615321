import { OrmManager } from '@aclass/admin/managers';
import { EmailTemplateEditStateResolver } from '@aclass/admin/resolvers';
import { viewDocumentRs } from '@aclass/admin/rest/responses';
import {
    EmailTemplateStory,
    OrmStory,
    RootStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    EmailTemplate,
    IDocumentData,
    IEmailMessageData,
    IEmailTemplateData,
} from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { readToBase64, tryToSaveFile, IFileData } from '@aclass/core/base/file.reader';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type viewData = Response<{
    template: IEmailTemplateData,
    email: IEmailMessageData | null,
    brands: Array<string> | null,
    documents: Array<IDocumentData>,
}>;

export const emailTemplateModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(EmailTemplateStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.emailTemplateModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            EmailTemplateStory.updateFormSearchPage({ }),
            EmailTemplateStory.importRecordsOnSearchPage([]),
            EmailTemplateStory.updatePaginationOnSearchPage(null),
            EmailTemplateStory.updateOrderOnSearchPage(null),
            EmailTemplateStory.updateSearchLockOnSearchPage(null),
            EmailTemplateStory.updateCollapsedSearchPage(null),
            // Unlock page
            EmailTemplateStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(EmailTemplateStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.emailTemplateModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: module.get('searchPagePagination'),
                order: module.get('searchPageOrder'),
            };

            return concat(of(EmailTemplateStory.updateSearchLockOnSearchPage(true)), http.post('email-template/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    OrmStory.populateEmailTemplates(rs.body.results),
                    EmailTemplateStory.importRecordsOnSearchPage(rs.body.results),
                    EmailTemplateStory.updatePaginationOnSearchPage(rs.body.pagination),
                    EmailTemplateStory.updateOrderOnSearchPage(rs.body.order),
                    EmailTemplateStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(EmailTemplateStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
                const data: Map<string, any> = state.value.emailTemplateModule.get('createPageForm');

                const rq: any = {
                    name: data.get('name'),
                };

                return concat(of(EmailTemplateStory.updateSearchUserLockEditPage(true)), http.post('email-template', rq).pipe(
                    dispatchActions((rs: Response<IEmailTemplateData>) => [
                            SystemStory.showNotification(SystemNotification.success('Success', `Email template "${ rs.body.name }" created`)),
                            OrmStory.populateEmailTemplates([rs.body]),
                            RootStory.resetForm(),
                            EmailTemplateStory.updateSearchPageInitState(false),
                            SystemStory.navigate(['email-templates', 'edit', rs.body.id], { sharedLink: true })
                        ],
                    )),
                );
            },
        )),

    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload }: Action<{ id: number, brand?: Instance['brand'], locale?: Instance['locale'] }>) => {
            const { id } = payload;
            const locale = payload.locale || instanceManager.locales.first();
            const brand = payload.brand || instanceManager.getBrandsByLocale(locale).first();
            const params = instanceManager.buildHeaders([brand, locale]);

            return http.get(`email-template/${ id }`, params).pipe(
                dispatchActions((rs: viewData) => {

                        const documentsMapping = rs.body.documents.map(({ id: documentId }) => ({
                            templateId: rs.body.email.id,
                            documentId
                        }));

                        return [
                            OrmStory.populateEmailTemplates([rs.body.template]),
                            OrmStory.populateEmailMessages([rs.body.email]),
                            OrmStory.populateDocuments(rs.body.documents),
                            OrmStory.truncateDocumentEmailTemplateMappings(),
                            OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                            // Reset prev state
                            EmailTemplateStory.updateFormOnEditPage({ }),
                            EmailTemplateStory.updateLocaleEditPage(locale),
                            EmailTemplateStory.updateBrandEditPage(brand),
                            EmailTemplateStory.updateIdEditPage(id),
                            OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_TEMPLATE),
                            RootStory.resetForm('EmailTemplate'),
                        ];
                    },
                    () => {
                        return [
                            SystemStory.stopNavigate(EmailTemplateEditStateResolver.STOP_EVENT_NAME),
                            SystemStory.navigate(['email-template'], { sharedLink: true })
                        ];
                    }
                )
            );
        }),
    ),
    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.emailTemplateModule;
            const id = module.get('editPageRecordId');
            const data: Map<string, any> = module.get('editPageForm');
            const rq: any = {
                template: {
                    name: data.get('name'),
                },
                email: {
                    subject: data.get('subject'),
                    body: data.get('body'),
                }
            };
            const params = instanceManager.buildHeaders([module.get('editPageBrand'), module.get('editPageLocale')]);

            return concat(of(EmailTemplateStory.updateSaveLockEditPage(true)), http.put(`email-template/${ id }`, rq, params).pipe(
                dispatchActions((rs: Response<{ template: IEmailTemplateData, email: IEmailMessageData }>) => [
                    OrmStory.populateEmailTemplates([rs.body.template]),
                    OrmStory.populateEmailMessages([rs.body.email]),
                    EmailTemplateStory.updateSearchPageInitState(false),
                    SystemStory.showNotification(SystemNotification.success('Success', `Template "${ rs.body.template.name }" updated`)),
                    EmailTemplateStory.updateSaveLockEditPage(false),
                    RootStory.afterSaveForm({ form: 'EmailTemplate', success: true }),
                    RootStory.resetForm('EmailTemplate')
                ], () => [
                    RootStory.afterSaveForm({ form: 'EmailTemplate', success: false }),
                ])
            ));
        }),
    ),


    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_TOGGLE_ARCHIVE),
        mergeMap(({ payload }: Action<boolean>) => {
            const id = state.value.emailTemplateModule.get('editPageRecordId');

            const rq: any = {
                deleted: payload,
            };

            return concat(of(EmailTemplateStory.updateSearchUserLockEditPage(true)), http.put(`email-template/archived/${ id }`, rq).pipe(
                dispatchActions((rs: Response<IEmailTemplateData>) => [
                    OrmStory.populateEmailTemplates([rs.body]),
                    OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_TEMPLATE),
                    SystemStory.showNotification(SystemNotification.success('Success', `Template "${ rs.body.name }" updated`)),
                ])
            ));
        }),
    ),

    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_SUBMIT),
        mergeMap(() => {
            const module = state.value.emailTemplateModule;
            const id = module.get('editPageRecordId');
            const data: Map<string, any> = module.get('editPageForm');
            const email: Map<string, any> = module.get('editPageSendEmailForm');
            const rq: any = {
                id: id,
                to: email.get('email'),
                subject: data.get('subject'),
                body: data.get('body'),
                username: email.get('username'),
                surname: email.get('surname'),
                gender: email.get('gender'),
                tour: email.get('tour'),
                quotenumber: email.get('quotenumber'),
                vamoospasscode: email.get('vamoospasscode'),
                vamoosuserid: email.get('vamoosuserid'),
                twordernumber: email.get('twordernumber'),
                errorMessage: email.get('errorMessage'),
                brand: email.get('brands'),
                locale: email.get('locales'),
                documents: findById<EmailTemplate>(orm.session(state.value.orm), EmailTemplate, id).relatedDocuments.all().toRefArray().map(r => (r.id)),
            };
            const params = instanceManager.buildHeaders([rq.brand, rq.locale]);

            return concat(of(EmailTemplateStory.updateSendEmailSubmitLockEditPage(true)), http.post('email-template/send-email', rq, params).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Message sent.`)),
                    EmailTemplateStory.updateSendEmailSubmitLockEditPage(false)
                ])
            ));
        }),
    ),

    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_UPLOAD_DOCUMENTS),
        mergeMap(({ payload }: Action<Array<File>>) => {
            const module = state.value.emailTemplateModule;
            const id = module.get('editPageRecordId');
            const params = instanceManager.buildHeaders([module.get('editPageBrand'), module.get('editPageLocale')]);

            return concat(of(EmailTemplateStory.updateUploadDocumentsLockEditPage(true)), zip(...payload.map(readToBase64)).pipe(
                switchMap((results: Array<IFileData>) => concat(http.post('document-email-message-template', { documents: results, ...{ templateId: id } }, params)).pipe(
                    switchMap((rs: viewDocumentRs) => {
                            const collection: Array<IDocumentData> = rs.body.documents;
                            const actions = [
                                EmailTemplateStory.updateUploadDocumentsLockEditPage(false)
                            ];

                            if (!collection.length) {
                                return actions;
                            }

                            return [
                                OrmStory.populateDocuments(collection),
                                OrmStory.populateDocumentEmailTemplateMappings(collection.map(r => ({ documentId: r.id, templateId: id }))),
                                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_TEMPLATE),
                                SystemStory.showNotification(SystemNotification.success('Success', `Document ${ collection.length > 1 ? 's' : ''} uploaded`)),
                            ];
                        }
                    ))
                ))
            );
        }),
    ),

    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_DOWNLOAD_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(EmailTemplateStory.updatePickedDocumentEditPage(payload)), http.get(`document-email-message-template/download/${ payload }`, { observe: 'response', responseType: 'text' }).pipe(
            mergeMap((rs: HttpResponse<string>) => {
                tryToSaveFile(rs);

                return of(EmailTemplateStory.updatePickedDocumentEditPage(null));
            })
        ))),
    ),

    action.pipe(
        ofType(EmailTemplateStory.EDIT_PAGE_REMOVE_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(EmailTemplateStory.updatePickedDocumentEditPage(payload)), http.delete(`document-email-message-template/${ payload }`).pipe(
            dispatchActions(() => [
                EmailTemplateStory.updatePickedDocumentEditPage(null),
                OrmStory.dropDocuments([payload]),
                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_TEMPLATE),
                SystemStory.showNotification(SystemNotification.success('Success', 'Document removed'))
            ])
        ))),
    ),

    action.pipe(
        ofType(EmailTemplateStory.COPY_ON_SEARCH_PAGE),
        mergeMap(({ payload }: Action<number>) => {

            return concat(of(EmailTemplateStory.updateSearchLockOnSearchPage(true)), http.put(`email-template/copy/${ payload }`, null).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    OrmStory.populateEmailTemplates(rs.body.results),
                    EmailTemplateStory.importRecordsOnSearchPage(rs.body.results),
                    EmailTemplateStory.updatePaginationOnSearchPage(rs.body.pagination),
                    EmailTemplateStory.updateOrderOnSearchPage(rs.body.order),
                    EmailTemplateStory.updateSearchLockOnSearchPage(false),
                    SystemStory.showNotification(SystemNotification.success('Success', `Template copied`)),
                ])
            ));
        }),
    ),
);
