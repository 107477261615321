<div class="b-content-sticky">
    <div class="b-content-head" [class.b-content-head--collapsible]="collapsible" *ngIf="header">
        <div class="b-content-head__content" [hidden]="collapsed">
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </div>
        <div class="b-content-head__expand" (click)="runUpdate(true)" [hidden]="!collapsible || collapsed">
            <clr-icon shape="angle-double"></clr-icon>
        </div>
        <div class="b-content-head__collapse" (click)="runUpdate(false)" [hidden]="!collapsible || !collapsed">
            <clr-icon shape="angle-double"></clr-icon>
        </div>
    </div>
    <div class="b-content-nav" *ngIf="nav">
        <ng-container *ngTemplateOutlet="nav"></ng-container>
    </div>
    <div class="b-content-sticky__progress" [hidden]="!showProgress"></div>
</div>
<div class="b-content-body">
    <ng-content></ng-content>
</div>
