import { FileStory } from '@aclass/admin/storage/actions';
import { IFileModuleState, INITIAL_FILE_MODULE_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const fileModuleReducer = (state: TypedState<IFileModuleState> = INITIAL_FILE_MODULE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case FileStory.IMAGE_GALLERY_SEARCH_LOCK:
            return state.update('searchLockImageGallery', () => payload);
        case FileStory.IMAGE_GALLERY_IMPORT_RECORDS:
            return state.update('searchResultsImageGallery',  (records: List<number>) => {
                return List(Array.from(new Set(records.toArray().concat(payload).sort((a, b) => a - b).reverse())));
            });
        case FileStory.IMAGE_GALLERY_REMOVE_RECORD:
            return state.update('searchResultsImageGallery', (records: List<number>) => records.remove(records.indexOf(payload)));
        case FileStory.IMAGE_GALLERY_UPDATE_PAGINATION:
            return state.update('searchPaginationImageGallery', () => Map(payload));
        case FileStory.IMAGE_GALLERY_UPDATE_SELECTED:
            return state.update('selectedImageGallery', () => payload);
        case FileStory.IMAGE_GALLERY_UPLOAD_LOCK:
            return state.update('uploadLockImageGallery', () => payload);
        case FileStory.IMAGE_GALLERY_UPDATE_EDIT_LOCK:
            return state.update('editLockImageGallery', () => payload);
        case FileStory.IMAGE_GALLERY_UPDATE_EDIT_FORM:
            return state.update('editFormImageGallery', () => fromJS(payload));
        default:
            return state;
    }
};
