import { Exception, ITWOptionData, ITWProductData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IExceptionModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<Exception['id']>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * The id of product
     */
    editPageRecordId: Exception['id'] | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * A list of products ids by type
     */
    createUpdateProducts: Array<ITWProductData> | null;

    /**
     * A list of Option ids from ORM
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, List<IOptionData['id']>>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    productOptions: List<ITWOptionData>;

    /**
     * OPTIONS IDS BY PRODUCT ID
     */
    productOptionsIdsByProductId: List<any>;
}

export const INITIAL_EXCEPTION_STATE = createTypedState<IExceptionModuleState>({
    createUpdateProducts: [],
    productOptions: [],
    productOptionsIdsByProductId: [],
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageForm: { },
    createPageSaveLock: null,
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageForm: { },
});
