import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'template'
})
export class TemplateStory {

    @Task() static UPDATE_TOGGLE_MENU: string;

    @Task() static UPDATE_MENU_COLLAPSED: string;

    static toggleMenu(v: boolean | null): Action {
        return { type: TemplateStory.UPDATE_TOGGLE_MENU, payload: v };
    }

    static updateMenuCollapsed(v: boolean | null): Action {
        return { type: TemplateStory.UPDATE_MENU_COLLAPSED, payload: v };
    }
}
