import { Quote } from '@aclass/admin/storage/models/quote';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { IQuoteTemplateData, IQuoteTemplateDataBlocks } from '@aclass/core/storage/models/quote.template';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'QuoteTemplate'
})
export class QuoteTemplate extends Entity<IQuoteTemplateData> {

    @Attr() readonly id: string | null;

    @Attr() readonly acceptButtonLabel: string | null;

    @Attr() readonly agencyInfo: string | null;

    @Attr() readonly bookedButtonLabel: string | null;

    @Attr() readonly commentFlightTemplate: string | null;

    @Attr() readonly descriptionAddition: string | null;

    @Attr() readonly disabledButtonLabel: string | null;

    @Attr() readonly footer: string | null;

    @Attr() readonly generalTravelScheme: string | null;

    @Attr() readonly greeting: string | null;

    @Attr() readonly header: string | null;

    @Attr() readonly contact: string | null;

    @Attr() readonly copyright: string | null;

    @Attr() readonly howBook: string | null;

    @Attr() readonly insurance: string | null;

    @Attr() readonly commentPrice: string | null;

    @Attr() readonly reducedMobility: string | null;

    @Attr() readonly infectionData: string | null;

    @Attr() readonly whyTravelWithUs: string | null;

    @Attr() readonly showBlocks: IQuoteTemplateDataBlocks | null;

    readonly relatedQuotes: QuerySet<Quote>;

}
