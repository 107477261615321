import { IRoleData, IRolePermissionData } from '@aclass/admin/storage/models';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'acl'
})
export class AclStory {

    @Task() static DRY_RUN: string;

    @Task() static UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static CREATE_PAGE_UPDATE_ROLE_PERMISSIONS: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_ROLE_NAME: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SHOULD_LOGOUT: string;

    @Task() static EDIT_PAGE_UPDATE_ROLE_NAMES: string;

    @Task() static EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL: string;

    @Task() static EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_ROLE_PERMISSIONS: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_REMOVE_ROLE: string;

    static dryRun(): Action {
        return { type: AclStory.DRY_RUN };
    }

    static updateInitState(v: boolean | null): Action {
        return { type: AclStory.UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: AclStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: AclStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: AclStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static importRecordsOnSearchPage(ids: Array<string>): Action {
        return { type: AclStory.SEARCH_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnSearchPage(): Action {
        return { type: AclStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunCreatePage(): Action {
        return { type: AclStory.CREATE_PAGE_DRY_RUN };
    }

    static updateRolePermissionsOnCreatePage(data: Array<IRolePermissionData>): Action {
        return { type: AclStory.CREATE_PAGE_UPDATE_ROLE_PERMISSIONS, payload: data };
    }

    static submitOnCreatePage(): Action {
        return { type: AclStory.CREATE_PAGE_SUBMIT };
    }

    static updateSaveLockCreatePage(v: boolean | null): Action {
        return { type: AclStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static dryRunEditPage(roleName: IRoleData['name']): Action {
        return { type: AclStory.EDIT_PAGE_DRY_RUN, payload: roleName };
    }

    static updateRoleNameEditPage(roleName: string): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_ROLE_NAME, payload: roleName };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateShouldLogoutEditPage(v: boolean): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_SHOULD_LOGOUT, payload: v };
    }

    static updateRoleNamesOnEditPage(data: Array<string>): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_ROLE_NAMES, payload: data };
    }

    static updateShowRemoveModalOnEditPage(v: boolean): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL, payload: v };
    }

    static updateRemoveModalLockOnEditPage(v: boolean): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK, payload: v };
    }

    static updateRolePermissionsOnEditPage(data: Array<IRolePermissionData>): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_ROLE_PERMISSIONS, payload: data };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: AclStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static submitOnEditPage(): Action {
        return { type: AclStory.EDIT_PAGE_SUBMIT };
    }

    static removeRoleOnEditPage(): Action {
        return { type: AclStory.EDIT_PAGE_REMOVE_ROLE };
    }
}
