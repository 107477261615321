import { IAskData, IGalleryData } from '@aclass/admin/components';
import { Image } from '@aclass/admin/storage/models';
import { Action } from '@aclass/core/storage/action';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ModalManager {

    static GALLERY_OPEN = 'gallery';

    static ASK_OPEN = 'ask';

    static REPORT_OPEN = 'report';

    readonly modal: EventEmitter<Action> = new EventEmitter<Action>();

    /**
     * Shows images gallery and attaches it to the listener
     */
    pickImage(params?: Partial<IGalleryData>): Observable<Image | undefined> {
        const listener = new EventEmitter<Image | undefined>();
        this.modal.emit({ type: ModalManager.GALLERY_OPEN, payload: { params, listener } });

        return listener;
    }

    /**
     * Shows ask modal and attaches it to the listener
     */
    ask(params?: Partial<IAskData>): Observable<boolean | undefined> {
        const listener = new EventEmitter<boolean | undefined>();
        this.modal.emit({ type: ModalManager.ASK_OPEN, payload: { params, listener } });

        return listener;
    }

    /**
     * Shows report modal
     */
    report(params?: { [key: string]: any }): Observable<boolean | undefined> {
        const listener = new EventEmitter<boolean | undefined>();
        this.modal.emit({ type: ModalManager.REPORT_OPEN, payload: { params, listener } });

        return listener;
    }
}
