import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { Itinerary } from '@aclass/admin/storage/models/itinerary';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IPCCombinationData {
    readonly id: string;

    readonly sid: number;

    readonly string: string;

    readonly instanceId: string;

    readonly forChildren: boolean;

    readonly createdAt: number;

    readonly updatedAt: number;

    readonly deleted: boolean;
}

@Model({
    name: 'PCCombination'
})
export class PCCombination extends Entity<IPCCombinationData> {

    @Attr() readonly id: string;

    @Attr() readonly sid: number;

    @Attr() readonly name: string;

    @Attr() readonly instanceId: string;

    @Attr() readonly forChildren: boolean;

    @Attr() readonly createdAt: number;

    @Attr() readonly updatedAt: number;

    @Attr() readonly deleted: boolean;

    readonly relatedItineraries: Array<Itinerary>;

    readonly relatedCombinationRevisions: QuerySet<CombinationRevision>;
}
