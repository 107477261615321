import { PipeManager } from '@aclass/admin/managers';
import { PriceFormatPipe } from '@aclass/core/pipes/price.format.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localePriceFormat',
})

export class LocalePriceFormatPipe extends PriceFormatPipe implements PipeTransform {

    constructor(
        private pipeManager: PipeManager
    ) {
        super();
    }

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order: locale, symbol to replace, decimal separator, thousands separator, decimal points, removes trailing zero(s) flag
     */
    transform(value: any, ...args: any[]): string {
        const [locale, ...rest] = args;

        return super.transform(value, ...this.pipeManager.getPriceFormatParams(locale, ...rest));
    }
}
