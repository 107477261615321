import { SmsTemplate } from '@aclass/admin/storage/models/sms.template';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ISmsMessageData {

    readonly id: string | null;

    readonly subject: string | null;

    readonly body: string | null;

    readonly templateId: number | null;
}

@Model({
    name: 'SmsMessage'
})

export class SmsMessage extends Entity<ISmsMessageData> {

    @Attr() readonly id: string | null;

    @Attr() readonly subject: string | null;

    @Attr() readonly body: string | null;

    @Fk({ to: 'SmsTemplate', relatedName: 'relatedSmsTemplates' }) readonly templateId: SmsTemplate | null;
}
