interface IStoryOpts {
    name: string;
}

const storyPropName = 'STORY_NAME';

const stories: { [key: string]: string } = { };

export function Story(opts: IStoryOpts) {
    return (target: Function): void => {
        const name = opts.name.toLowerCase();
        if (name in stories) {
            throw new Error(`Error during register story "${ name }" for "${ target.name }" it already defined for "${ stories[name] }"`);
        }
        // Secret property
        Object.defineProperty(target, storyPropName, {
            enumerable: false,
            configurable: false,
            value: name,
            writable: false
        });
        stories[name] = target.name;
    };
}

export function Task() {
    return (target: Function, propertyName: string): void => {
        const name: string = propertyName.replace(/_/g, '-').toLowerCase();
        Object.defineProperty(target, propertyName, {
            enumerable: true,
            configurable: true,
            get(this: Function) {
                if (!this[storyPropName]) {
                    throw new Error(`Please register story for "${ this.name }" with @Story() decorator`);
                }

                return `${ this[storyPropName] }/${ name }`;
            },
        });
    };
}
