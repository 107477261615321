import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

export abstract class BaseResolver implements Resolve<any> {

    protected employee: Observable<any>;

    protected resolver: Observable<any>;

    protected subscriptions: Array<Subscription> = [];

    abstract beforeResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void;

    /**
     * @inheritDoc
     */
    resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        this.unsubscribe();
        this.beforeResolve(route, state);

        return new Promise((resolve, reject) => {
            this.subscriptions = [
                this.employee.subscribe(reject),
                this.resolver.subscribe(resolve)
            ];
        });
    }

    private unsubscribe() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }
}
