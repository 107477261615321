import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ISplitTestModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The id of product
     */
    viewPageRecordId: string | null;

    /**
     * The the save button lock
     */
    viewPageSearchLock: boolean | null;

    /**
     * A list of product ids from ORM
     */
    viewPageTestValue: List<number>;

    viewPageTestName: string | null;

    /**
     * Edit form data
     */
    viewPageSearchForm: Map<string, any>;
}

export const INITIAL_SPLIT_TEST_STATE = createTypedState<ISplitTestModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    viewPageRecordId: null,
    viewPageSearchLock: null,
    viewPageTestValue: [],
    viewPageTestName: null,
    viewPageSearchForm: { },
});
