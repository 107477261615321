import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'extra-prices'
})
export class ExtraPricesStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static SEARCH_PAGE_UPDATE_TOURS: string;

    @Task() static SEARCH_PAGE_UPDATE_COMBINATIONS: string;

    @Task() static SEARCH_PAGE_UPDATE_FILTER_DESTINATIONS: string;

    @Task() static SEARCH_PAGE_UPDATE_FILTER_TOURS: string;

    @Task() static SEARCH_PAGE_UPDATE_FILTER_COMBINATIONS: string;

    @Task() static SEARCH_PAGE_UPDATE_TOURS_AND_DESTINATIONS: string;

    @Task() static CREATE_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static CREATE_PAGE_UPDATE_COMBINATIONS: string;

    @Task() static CREATE_PAGE_UPDATE_TOURS: string;

    @Task() static CREATE_PAGE_UPDATE_FORM: string;

    @Task() static CREATE_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static CREATE_PAGE_IMPORT_TOURS: string;

    @Task() static CREATE_PAGE_IMPORT_DESTINATIONS: string;

    @Task() static CREATE_PAGE_IMPORT_COMBINATIONS: string;

    @Task() static CREATE_PAGE_IMPORT_DESTINATIONS_MAPPINGS: string;

    @Task() static CREATE_PAGE_IMPORT_COMBINATIONS_MAPPINGS: string;

    @Task() static EDIT_PAGE_UPDATE_TOURS_AND_DESTINATIONS: string;

    @Task() static EDIT_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static EDIT_PAGE_UPDATE_TOURS: string;

    @Task() static EDIT_PAGE_UPDATE_COMBINATIONS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_UPDATE_INIT_STATE: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_EXTRA_PRICES_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static CREATE_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static CREATE_PAGE_UPDATE_TOURS_AND_DESTINATIONS: string;

    @Task() static EDIT_PAGE_IMPORT_TOURS: string;

    @Task() static EDIT_PAGE_IMPORT_DESTINATIONS: string;

    @Task() static EDIT_PAGE_IMPORT_COMBINATIONS: string;

    @Task() static EDIT_PAGE_IMPORT_DESTINATIONS_MAPPINGS: string;

    @Task() static EDIT_PAGE_IMPORT_COMBINATIONS_MAPPINGS: string;

    @Task() static EDIT_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL: string;

    @Task() static EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_REMOVE_RECORD: string;

    static dryRunSearchPage(): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_ORDER, payload: order };
    }

    static importRecordsOnSearchPage(ids: Array<number>): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static updateDestinationsOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateToursOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_TOURS, payload: data };
    }

    static updateFilterDestinationsOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_FILTER_DESTINATIONS, payload: data };
    }

    static updateFilterToursOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_FILTER_TOURS, payload: data };
    }

    static updateFilterCombinationsOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_FILTER_COMBINATIONS, payload: data };
    }

    static updateToursAndDestinationsOnSearchPage(): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_TOURS_AND_DESTINATIONS };
    }

    static updateDestinationsOnCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateCombinationsOnSearchPage(data: any): Action {
        return { type: ExtraPricesStory.SEARCH_PAGE_UPDATE_COMBINATIONS, payload: data };
    }

    static updateToursOnCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_TOURS, payload: data };
    }

    static updateCombinationsOnCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_COMBINATIONS, payload: data };
    }

    static updateSearchLockOnCreatePage(v: boolean | null): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static importToursOnCreatePage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_IMPORT_TOURS, payload: ids };
    }

    static importDestinationsOnCreatePage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_IMPORT_DESTINATIONS, payload: ids };
    }

    static importCombinationsOnCreatePage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_IMPORT_COMBINATIONS, payload: ids };
    }

    static importDestinationsMappingsOnCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_IMPORT_DESTINATIONS_MAPPINGS, payload: data };
    }

    static importCombinationsMappingOnCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_IMPORT_COMBINATIONS_MAPPINGS, payload: data };
    }

    static updateToursAndDestinationsOnEditPage([brands, locale, id]): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_TOURS_AND_DESTINATIONS, payload: [brands, locale, id] };
    }

    static updateDestinationsOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateToursOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_TOURS, payload: data };
    }

    static updateCombinationsOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_COMBINATIONS, payload: data };
    }

    static submitOnSearchPage(refresh: boolean = false) {
        return { type: ExtraPricesStory.SEARCH_PAGE_SUBMIT, payload: refresh };
    }

    static updateSaveLockCreatePage(v: boolean | null): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static dryRunCreatePage(): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_DRY_RUN };
    }

    static updateFormCreatePage(data: any): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_FORM, payload: data };
    }

    static updateCreatePageInitState(v: boolean | null): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateToursAndDestinationsOnCreatePage(): Action {
        return { type: ExtraPricesStory.CREATE_PAGE_UPDATE_TOURS_AND_DESTINATIONS };
    }

    static submitOnCreatePage() {
        return { type: ExtraPricesStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: number): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateRecordIdEditPage(id: number): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_EXTRA_PRICES_ID, payload: id };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateShowRemoveModalOnEditPage(v: boolean): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL, payload: v };
    }

    static updateRemoveModalLockOnEditPage(v: boolean): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK, payload: v };
    }

    static updateFormValueOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static updateSearchLockOnEditPage(v: boolean | null): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static importToursOnEditPage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_IMPORT_TOURS, payload: ids };
    }

    static importDestinationsOnEditPage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_IMPORT_DESTINATIONS, payload: ids };
    }

    static importCombinationsOnEditPage(ids: Array<string>): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_IMPORT_COMBINATIONS, payload: ids };
    }

    static importDestinationsMappingsOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_IMPORT_DESTINATIONS_MAPPINGS, payload: data };
    }

    static importCombinationsMappingOnEditPage(data: any): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_IMPORT_COMBINATIONS_MAPPINGS, payload: data };
    }

    static submitOnEditPage() {
        return { type: ExtraPricesStory.EDIT_PAGE_SUBMIT };
    }

    static removeExtraPriceOnEditPage(): Action {
        return { type: ExtraPricesStory.EDIT_PAGE_REMOVE_RECORD };
    }
}
