import { ISwitchButtonData } from '@aclass/admin/components';
import { fromJS, List, Map } from 'immutable';

export const PIPELINE_LEVEL_PRESETS: List<ISwitchButtonData> = fromJS([
    {
        title: 'Ongoing',
        value: 'ongoing',
    },
    {
        title: 'Lost',
        value: 'lost',
    },
    {
        title: 'Won',
        value: 'won',
    },
    {
        title: 'All',
        value: 'all',
    },
]);

export const SENT_STATUSES: List<ISwitchButtonData> = fromJS([
    {
        title: 'all',
        value: null,
    },
    {
        title: 'yes',
        value: true,
    },
    {
        title: 'no',
        value: false,
    },
]);

export const PING_BACK_STATUSES: List<ISwitchButtonData> = fromJS([
    {
        title: 'all',
        value: null,
    },
    {
        title: 'yes',
        value: true,
    },
    {
        title: 'no',
        value: false,
    },
]);

export const PHONE_STATUSES: List<ISwitchButtonData> = fromJS([
    {
        title: 'all',
        value: null,
    },
    {
        title: 'yes',
        value: true,
    },
    {
        title: 'no',
        value: false,
    },
]);

export const LEAD_SCORE_ANSWERS_PRESETS: List<ISwitchButtonData> = fromJS([
    {
        title: 'NOT DEFINED',
        value: 'NOT DEFINED',
    },
    {
        title: 'HOT',
        value: 'HOT',
    },
    {
        title: 'INSPIRATION',
        value: 'INSPIRATION',
    },
    {
        title: 'MEDIUM',
        value: 'MEDIUM',
    },
    {
        title: 'OTHER',
        value: 'OTHER',
    },
]);

export const OPPORTUNITY_EDIT_SEGMENTS: Map<string, boolean> = fromJS({
    customer: false,
    request: true,
    revision: true,
    followUp: false,
    offlineBooking: false,
    delivery: false,
    logs: true,
    documents: false,
    documentsTravelWise: false,
});
