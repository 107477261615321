import { isDateRange, Daytable, IDateRange } from '@aclass/core/base/daytable';
import { Directive, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[admDateWatcherNode]'
})
export class DateWatcherNodeDirective implements OnDestroy {

    @Input('admDateWatcherNode') set form(v: FormArray) {
        this.deattach();
        this._form = v || null;
        if (!v) {
            return;
        }
        this.attach();
    }

    @Input() range = true;

    @Input() min: string | null = null;

    @Input() mode: 'mechanic' | null = null;

    private _form: FormArray;

    private state: Array<IDateRange | string> = [];

    private subscriptions: Array<Subscription> = [];

    get form(): FormArray {
        return this._form;
    }

    get controls(): Array<FormControl> {
        return <Array<FormControl>>this.form.controls.map(r => (<FormGroup>r).controls.date);
    }

    constructor() {
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    runAddControl(c: FormGroup, position?: number | null) {
        if (!this.form || !('date' in c.controls)) {
            return;
        }
        this.deattach();
        const p: number = Number.isInteger(position) ? position : this.form.controls.length;
        const dt = this.calculateDate(p);
        c.controls.date.setValue(dt);
        this.form.insert(p, c);
        switch (this.mode) {
            case 'mechanic':
                this.state.splice(p, 0, dt);
                break;
            default:
                this.updateSequence(1, p + 1);
        }
        this.attach();
    }

    runRemoveControl(position?: number | null) {
        if (!this.form || !(position in this.form.controls)) {
            return;
        }
        this.deattach();
        this.form.removeAt(position);
        this.attach();
    }

    runUpdateSequence(diff: number | null, position: number = 0): void {
        if (!this.form || !diff) {
            return;
        }
        this.deattach();
        this.updateSequence(diff, position);
        this.attach();
    }

    private updateSequence(diff: number | null, position: number = 0) {
        if (!diff) {
            return;
        }
        this.controls.forEach((r, idx) => {
            if (idx < position) {
                return;
            }
            const dt: IDateRange<Daytable> | Daytable | null = isDateRange<string>(r.value) ? Daytable.fromDateRangeString(r.value) : Daytable.fromDateString(r.value);
            if (isDateRange<Daytable>(dt)) {
                r.setValue({ from: dt.from.addDays(diff).toDateString(), to: dt.to.addDays(diff).toDateString() });
            } else if (dt) {
                r.setValue(dt.addDays(diff).toDateString());
            }
        });
    }

    private attach() {
        const controls = this.controls;
        this.state = controls.map(r => r.value);
        this.subscriptions = controls.map((r, idx) => r.valueChanges.subscribe((v: IDateRange | string) => {
            const st = this.state[idx];
            if (!st || !v || JSON.stringify(st) === JSON.stringify(v)) {
                this.state[idx] = v;

                return;
            }
            const diff = Daytable.fromDateString(isDateRange<string>(v) ? v.to : v).diff(Daytable.fromDateString(isDateRange<string>(st) ? st.to : st), 'days');
            this.deattach();
            this.updateSequence(diff, idx + 1);
            // Control not updated automatically, maybe because I deattach subscriptions?
            controls[idx].setValue(v);
            this.attach();
        }));
    }

    private deattach() {
        this.subscriptions.forEach(r => r.unsubscribe());
        this.subscriptions = [];
        this.state = [];
    }

    private calculateDate(position?: number | null): IDateRange | string {
        const prev: string | null = this.form.at(position - 1) instanceof FormGroup ? (<FormGroup>this.form.at(position - 1)).controls.date.value : null;
        let start: string | null = isDateRange<string>(prev) ? prev.to : prev;
        if (start && this.mode !== 'mechanic') {
            start = start ? Daytable.fromDateString(start).addDays(1).toDateString() : null;
        }
        if (!start) {
            const next: string | null = this.form.at(position) instanceof FormGroup ? (<FormGroup>this.form.at(position)).controls.date.value : null;
            start = isDateRange<string>(next) ? next.from : next;
        }
        if (!start) {
            start = this.min;
        }

        return this.range && start ? { from: start, to: start } : start;
    }
}
