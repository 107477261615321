import { Destination } from '@aclass/admin/storage/models/destination';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IDestinationRevisionData {

    readonly id: string | null;

    readonly link: string | null;

    readonly name: string | null;

    readonly destinationId: string | null;
}

@Model({
    name: 'DestinationRevision'
})
export class DestinationRevision extends Entity<IDestinationRevisionData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly link: string | null;

    @Fk({ to: 'Destination', relatedName: 'destinationRevisions' }) readonly destinationId: Destination | null;

    readonly relatedTours: QuerySet<TourRevision>;
}

