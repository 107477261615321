import { IGeneralRemarkModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'general-remark'
})
export class GeneralRemarkStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_INSTANCES: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static EDIT_PAGE_UPDATE_TOURS: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL: string;

    @Task() static EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IGeneralRemarkModuleState['searchPageInitialized']): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IGeneralRemarkModuleState['searchPageCollapsed']): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateInstancesOnSearchPage(v: Array<string>): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_UPDATE_INSTANCES, payload: v };
    }

    static submitOnSearchPage(): Action {
        return { type: GeneralRemarkStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IGeneralRemarkModuleState['editPageRecordId']): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdEditPage(payload: IGeneralRemarkModuleState['editPageRecordId']): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateDestinationsOnEditPage(data: any): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateToursOnEditPage(data: any): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_TOURS, payload: data };
    }

    static updateSaveLockEditPage(payload: IGeneralRemarkModuleState['editPageSaveLock']): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateShowRemoveModalOnEditPage(v: boolean): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL, payload: v };
    }

    static updateRemoveModalLockOnEditPage(v: boolean): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK, payload: v };
    }

    static submitOnEditPage(): Action {
        return { type: GeneralRemarkStory.EDIT_PAGE_SUBMIT };
    }
}
