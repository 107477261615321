import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface INotificationData {

    readonly id: number;

    readonly brand: string;

    readonly brandLabel: string;

    readonly locale: string;

    readonly enqId: string;

    readonly email: string | null;

    readonly offerLink: string | null;

    readonly pipeline: string;

    readonly createdAt: number;

    readonly viewedAt: number;

    readonly flightAt: number;

    readonly tourName: string | null;
}

@Model({
    name: 'Notification'
})
export class Notification extends Entity<INotificationData> {

    @Attr() readonly id: number;

    @Attr() readonly brand: string;

    @Attr() readonly brandLabel: string;

    @Attr() readonly locale: string;

    @Attr() readonly enqId: string;

    @Attr() readonly email: string | null;

    @Attr() readonly offerLink: string | null;

    @Attr() readonly pipeline: string;

    @Attr() readonly createdAt: number;

    @Attr() readonly viewedAt: number;

    @Attr() readonly flightAt: number;

    @Attr() readonly tourName: string | null;
}
