<div class="main-container">
    <adm-system-alerts></adm-system-alerts>
    <header class="header header-6">
        <div class="branding">
            <a routerLink="/ao" class="nav-link">
                <span class="title">AO - TOURCOMPASS</span>
            </a>
        </div>
        <div class="header-actions">
            <a href="javascript://" class="nav-link nav-icon" title="Report an issue" (click)="runReport()">
                <clr-icon shape="bug"></clr-icon>
            </a>
            <a routerLink="/cms" class="nav-link nav-icon" title="Switch to cms">
                <clr-icon shape="switch"></clr-icon>
            </a>
            <clr-dropdown>
                <button clrDropdownTrigger title="Profile info" style="margin: 0 .5rem">
                    {{ username }}
                    <clr-icon shape="user" size="24"></clr-icon>
                    <clr-icon shape="caret down" size="12"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a clrDropdownItem routerLink="/ao/profile">Preferences</a>
                    <a href="javascript://" clrDropdownItem (click)="logout()">Log out</a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </header>
    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" class="nav-trigger--bottom">
            <a clrVerticalNavLink routerLink="/ao">
                <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
                Home
            </a>
            <clr-vertical-nav-group *admHasPermission="'manageUsers'" [routerLinkActive]="['active']">
                <clr-icon shape="shield" clrVerticalNavIcon></clr-icon>
                Users & Roles
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink routerLink="/ao/users" [routerLinkActive]="['active']">
                        Manage users
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/roles" [routerLinkActive]="['active']">
                        Manage roles
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <a clrVerticalNavLink routerLink="/ao/persons" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="users"></clr-icon>
                Persons
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageUsersRequest'" routerLink="/ao/opportunities" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="shopping-bag"></clr-icon>
                Opportunities
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageStandardOffer'" routerLink="/ao/quotes" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="shopping-cart"></clr-icon>
                Quotes
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageDiscountCampaigns'" routerLink="/ao/campaigns" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="savings"></clr-icon>
                Campaigns
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageDiscountCampaigns'" routerLink="/ao/extra-prices" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="dollar-bill"></clr-icon>
                Extra prices
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageProducts'" routerLink="/ao/additional-products" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="blocks-group"></clr-icon>
                Additional product
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageStopSales'" routerLink="/ao/stop-sales" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="no-access"></clr-icon>
                Stop sales
            </a>
            <a clrVerticalNavLink *admHasPermission="'manageExceptions'" routerLink="/ao/exceptions" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="no-access"></clr-icon>
                Exceptions
            </a>
            <a *admHasPermission="'handleOffers'" clrVerticalNavLink routerLink="/ao/notification" [routerLinkActive]="['active']">
                <clr-icon clrVerticalNavIcon shape="envelope"></clr-icon>
                Logging and notifications
            </a>
            <clr-vertical-nav-group *admHasPermission="['manageEmailTemplate', 'manageOfferTemplate', 'manageSignatures']" [routerLinkActive]="['active']">
                <clr-icon shape="layers" clrVerticalNavIcon></clr-icon>
                Templates
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink *admHasPermission="'manageEmailTemplate'" routerLink="/ao/email-templates" [routerLinkActive]="['active']">
                        Custom emails
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageEmailTemplate'" routerLink="/ao/sms-templates" [routerLinkActive]="['active']">
                        Custom sms
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageEmailTemplate'" routerLink="/ao/email-pipelines" [routerLinkActive]="['active']">
                        Follow up emails
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageEmailTemplate'" routerLink="/ao/offline-booking-email" [routerLinkActive]="['active']">
                        Offline booking emails
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageEmailTemplate'" routerLink="/ao/offline-booking-report" [routerLinkActive]="['active']">
                        Offline booking report
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageOfferTemplate'" routerLink="/ao/quote-templates" [routerLinkActive]="['active']">
                        Quote
                    </a>
                    <a clrVerticalNavLink *admHasPermission="'manageSignatures'" routerLink="/ao/signatures" [routerLinkActive]="['active']">
                        Signatures
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <clr-vertical-nav-group *admHasPermission="'manageStatisticsAndReports'" [routerLinkActive]="['active']">
                <clr-icon shape="line-chart" clrVerticalNavIcon></clr-icon>
                Statistics & Reports
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink routerLink="/ao/overviews" [routerLinkActive]="['active']">
                        Overviews
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/split-test" [routerLinkActive]="['active']">
                        Split tests
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/questionnaires" [routerLinkActive]="['active']">
                        Questionnaires
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <clr-vertical-nav-group *admHasPermission="'manageAClassInfo'" [routerLinkActive]="['active']">
                <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
                Settings
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink routerLink="/ao/domain-details" [routerLinkActive]="['active']">
                        Domain details
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/general-remark" [routerLinkActive]="['active']">
                        General remark settings
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/i18n" [routerLinkActive]="['active']">
                        Internationalization
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/lead-scoring" [routerLinkActive]="['active']">
                        Lead Scoring
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/total-pax-amount" [routerLinkActive]="['active']">
                        Total Pax amount
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/returning-customers" [routerLinkActive]="['active']">
                        Returning customers
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/manual-handling" [routerLinkActive]="['active']">
                        Manual handling settings
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/offer-validity" [routerLinkActive]="['active']">
                        Offer validity settings
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/offer-send-interval" [routerLinkActive]="['active']">
                        Offer send interval
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/working-hours" [routerLinkActive]="['active']">
                        Working hours
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/pipelines" [routerLinkActive]="['active']">
                        Pipelines
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/price-formatting" [routerLinkActive]="['active']">
                        Price formatting
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/system-price-formatting" [routerLinkActive]="['active']">
                        System price formatting
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/margin-color" [routerLinkActive]="['active']">
                        Margin color-coding
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/date-formatting" [routerLinkActive]="['active']">
                        Date formatting
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/anonymization" [routerLinkActive]="['active']">
                        Automatic anonymization
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/vamoos" [routerLinkActive]="['active']">
                        Vamoos synchronization
                    </a>
                    <a clrVerticalNavLink routerLink="/ao/page-404-settings" [routerLinkActive]="['active']">
                        404 Page
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
        </clr-vertical-nav>
    </div>
</div>
<adm-modal-ask></adm-modal-ask>
<adm-report-issue></adm-report-issue>
<adm-image-gallery></adm-image-gallery>
