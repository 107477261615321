import { ITWOptionData, TWOption } from '@aclass/admin/storage/models/tw.option';
import { Attr, Model, One } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITWExcursionOptionData {

    readonly id: number;

    readonly type: string;

    readonly optionId: ITWOptionData['id'];
}

@Model({
    name: 'TWExcursionOption'
})
export class TWExcursionOption extends Entity<ITWExcursionOptionData> {

    @Attr() readonly id: number;

    @Attr() readonly type: string;

    @One({ to: 'TWOption', relatedName: 'excursionOption' }) readonly optionId: TWOption;
}
