import { AppStory } from '@aclass/admin/storage/actions';
import { IAppModuleState, INITIAL_APP_MODULE_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';

export const appModuleReducer = (state: TypedState<IAppModuleState> = INITIAL_APP_MODULE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case AppStory.LOGIN_LOCK:
            return state.update('loginLock', () => payload);
        case AppStory.SHOW_ERROR:
            return state.update('loginPageMessage', () => payload);
        case AppStory.HIDE_ERROR:
            return state.update('loginPageMessage', () => '');
        default:
            return state;
    }
};
