import { Daytable } from '@aclass/core/base/daytable';
import {
    forwardRef,
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { AbstractControl, AsyncValidator, ControlValueAccessor, FormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { IMyDateModel, IMyDpOptions, MyDatePicker } from 'mydatepicker';
import { of, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'adm-date-picker',
    template: `
        <div class="b-date-picker-form">
            <my-date-picker class="b-date-picker-form__control" [formControl]="control" [options]="options" #dp></my-date-picker>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        }
    ],
})
export class DatePickerComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor, AsyncValidator {

    @HostBinding('class.b-date-picker') yes = true;

    @ViewChild('dp', { static: true }) dp: MyDatePicker;

    /**
     * Watched value
     */
    @Input() set value(v: string | null) {
        if (this._v === v) {
            return;
        }
        this._v = v;
        this.control.setValue(this.toPickerDate(v), { emitEvent: false });
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<string | null> = new EventEmitter<string | null>();

    /**
     * Open event emitter
     */
    @Output() open: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Close event emitter
     */
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Show small version
     */
    @HostBinding('class.b-date-picker-sm') @Input() sm = true;

    @Input() settings: Partial<IMyDpOptions & { minDate: Date | 'now' }> = { };

    control: FormControl;

    options: IMyDpOptions;

    private _v: string | null;

    private _disabled = false;

    private subscriptions: Array<Subscription> = [];

    @HostBinding('class.disabled') get disabled(): boolean {
        return this._disabled;
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        const options = {
            dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' },
            dateFormat: 'dd.mm.yyyy',
            openSelectorOnInputClick: true,
            editableDateField: true,
            showTodayBtn: false,
            minYear: 1900,
            maxYear: 2050,
            sunHighlight: false,
            width: 'auto',
            height: 'auto',
            selectionTxtFontSize: 'auto',
            ...this.settings
        };
        const { minDate } = options;
        if (minDate) {
            delete options.minDate;
            const date = new Daytable(minDate === 'now' ? new Date() : minDate).addDays(-1).toDate();
            options['disableUntil'] = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        }
        this.options = options;
        this.control = new FormControl({ value: this.toPickerDate(this._v), disabled: this._disabled });
        this.subscriptions.push(
            this.control.valueChanges.subscribe((v: IMyDateModel) => {
                if (this._disabled) {
                    return;
                }
                const value = this.fromPickerDate(v);
                this._v = value;
                this.onChangeCallback(value);
                this.valueChange.emit(value);
                this.onTouchedCallback();
            }),
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        this.subscriptions.push(
            this.dp.calendarToggle.subscribe((v: number) => {
                /**
                 * @see https://github.com/kekeh/mydatepicker#calendartoggle-callback
                 */
                if (v === 1) {
                    this.open.emit();

                    return;
                }
                this.close.emit();
            })
        );
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: string | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
        if (!this.control) {
            return;
        }
        const control = this.control;
        if (isDisabled) {
            control.disable();

            return;
        }
        control.enable();
    }

    /**
     * @inheritDoc
     */
    writeValue(v: string | null) {
        this.value = v;
    }

    /**
     * @inheritDoc
     */
    onTouchedCallback: () => void = () => { };

    /**
     * @inheritDoc
     */
    onChangeCallback: (v: string | null) => void = () => { };

    /**
     * @inheritDoc
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const error: ValidationErrors | null = this.dp.invalidDate && !this._v && !this._disabled ? { date: 'Date is not valid' } : null;

        return of(error);
    }

    private toPickerDate(v: string | null): Partial<IMyDateModel> | null {
        const d = Daytable.fromDateString(v);
        if (!(d instanceof Daytable)) {
            return null;
        }
        const dt = d.toDate();

        return {
            date: {
                year: dt.getFullYear(),
                month: dt.getMonth() + 1,
                day: dt.getDate(),
            }
        };
    }

    private fromPickerDate(v: IMyDateModel): string | null {
        return v ? new Daytable(new Date(v.date.year, v.date.month - 1, v.date.day, 0, 0, 0, 0)).toDateString() : null;
    }
}
