import { IOverviewData, IOverviewDefinitionData } from '@aclass/admin/storage/models';
import { IOverviewModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'overview'
})
export class OverviewStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_COPY_OVERVIEW: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_UPDATE_INIT_STATE: string;

    @Task() static CREATE_PAGE_UPDATE_FORM: string;

    @Task() static CREATE_PAGE_UPDATE_OVERVIEW_ID: string;

    @Task() static CREATE_PAGE_DEFINITIONS_SEARCH: string;

    @Task() static CREATE_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK: string;

    @Task() static CREATE_PAGE_UPDATE_DEFINITIONS: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_OVERVIEW_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DEFINITIONS_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_DEFINITIONS: string;

    @Task() static EDIT_PAGE_TOGGLE_DELETED: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static VIEW_PAGE_DRY_RUN: string;

    @Task() static VIEW_PAGE_UPDATE_OVERVIEW_ID: string;

    @Task() static VIEW_PAGE_UPDATE_FORM: string;

    @Task() static VIEW_PAGE_UPDATE_COLLAPSED: string;

    @Task() static VIEW_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static VIEW_PAGE_UPDATE_PAGINATION: string;

    @Task() static VIEW_PAGE_UPDATE_ORDER: string;

    @Task() static VIEW_PAGE_IMPORT_RECORDS: string;

    @Task() static VIEW_PAGE_UPDATE_LAST_SEARCH_RQ: string;

    @Task() static VIEW_PAGE_DOWNLOAD: string;

    @Task() static VIEW_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: OverviewStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IOverviewModuleState['searchPageInitialized']): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IOverviewModuleState['searchPageCollapsed']): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static copyOverviewOnSearchPage(payload: IOverviewData['id']): Action {
        return { type: OverviewStory.SEARCH_PAGE_COPY_OVERVIEW, payload };
    }

    static updateSearchLockOnSearchPage(payload: IOverviewModuleState['searchPageLock']): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: OverviewStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<number>): Action {
        return { type: OverviewStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: OverviewStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunCreatePage(): Action {
        return { type: OverviewStory.CREATE_PAGE_DRY_RUN };
    }

    static updateFormOnCreatePage(payload): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_FORM, payload };
    }

    static updateOverviewIdCreatePage(payload: IOverviewData['id']): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_OVERVIEW_ID, payload };
    }

    static updateCreatePageInitState(payload: IOverviewModuleState['createPageInitialized']): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_INIT_STATE, payload };
    }

    static searchDefinitionsOnCreatePage(payload?: string): Action {
        return { type: OverviewStory.CREATE_PAGE_DEFINITIONS_SEARCH, payload };
    }

    static updateSearchDefinitionLockCreatePage(payload: IOverviewModuleState['createPageDefinitionSearchLock']): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK, payload };
    }

    static updateOverviewDefinitionsCreatePage(payload: Array<IOverviewDefinitionData['id']>): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_DEFINITIONS, payload };
    }

    static updateSaveLockCreatePage(payload: IOverviewModuleState['createPageSaveLock']): Action {
        return { type: OverviewStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: OverviewStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IOverviewModuleState['editPageRecordId']): Action {
        return { type: OverviewStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateOverviewIdEditPage(payload: IOverviewModuleState['editPageRecordId']): Action {
        return { type: OverviewStory.EDIT_PAGE_UPDATE_OVERVIEW_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: OverviewStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IOverviewModuleState['editPageSaveLock']): Action {
        return { type: OverviewStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static searchDefinitionsOnEditPage(payload?: string): Action {
        return { type: OverviewStory.EDIT_PAGE_DEFINITIONS_SEARCH, payload };
    }

    static updateSearchDefinitionLockEditPage(payload: boolean | null): Action {
        return { type: OverviewStory.EDIT_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK, payload };
    }

    static updateOverviewDefinitionsEditPage(payload: Array<IOverviewDefinitionData['id']>): Action {
        return { type: OverviewStory.EDIT_PAGE_UPDATE_DEFINITIONS, payload };
    }

    static toggleDeletedEditPage(payload: boolean): Action {
        return { type: OverviewStory.EDIT_PAGE_TOGGLE_DELETED, payload };
    }

    static submitOnEditPage(): Action {
        return { type: OverviewStory.EDIT_PAGE_SUBMIT };
    }

    static dryRunViewPage(payload: IOverviewData['id']): Action {
        return { type: OverviewStory.VIEW_PAGE_DRY_RUN, payload };
    }

    static updateOverviewIdViewPage(payload: IOverviewData['id']): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_OVERVIEW_ID, payload };
    }

    static updateFormOnViewPage(payload): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_FORM, payload };
    }

    static updateCollapsedViewPage(payload: IOverviewModuleState['viewPageCollapsed']): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateSearchLockOnViewPage(payload: IOverviewModuleState['viewPageLock']): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnViewPage(payload: IPaginationData | null): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnViewPage(payload: IOrderData | null): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnViewPage(payload: Array<number>): Action {
        return { type: OverviewStory.VIEW_PAGE_IMPORT_RECORDS, payload };
    }

    static updateLastSearchRqOnViewPage(payload: DataSearchRqData<{ overview: IOverviewData['id'], conditions?: any }>): Action {
        return { type: OverviewStory.VIEW_PAGE_UPDATE_LAST_SEARCH_RQ, payload };
    }

    static downloadOnViewPage(): Action {
        return { type: OverviewStory.VIEW_PAGE_DOWNLOAD };
    }

    static submitOnViewPage(payload: ISearchOptions = { }): Action {
        return { type: OverviewStory.VIEW_PAGE_SUBMIT, payload };
    }
}
