import { AdditionalProduct } from '@aclass/admin/storage/models';
import { IAdditionalProductModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'product'
})
export class AdditionalProductStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_PRODUCT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IAdditionalProductModuleState['searchPageInitialized']): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IAdditionalProductModuleState['searchPageCollapsed']): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: IAdditionalProductModuleState['searchPageLock']): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<AdditionalProduct['id']>): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: AdditionalProductStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static updateSaveLockCreatePage(payload: IAdditionalProductModuleState['createPageSaveLock']): Action {
        return { type: AdditionalProductStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: AdditionalProductStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IAdditionalProductModuleState['editPageRecordId']): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateProductIdEditPage(payload: IAdditionalProductModuleState['editPageRecordId']): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_UPDATE_PRODUCT_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IAdditionalProductModuleState['editPageSaveLock']): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static removeOnEditPage(): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_REMOVE };
    }

    static submitOnEditPage(): Action {
        return { type: AdditionalProductStory.EDIT_PAGE_SUBMIT };
    }
}
