import { Identity } from '@aclass/admin/base/identity';
import { IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { NgRedux } from '@angular-redux/store';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[admHasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {

    private identity: Identity | null;

    private stateSubscription?: Subscription;

    private permissions = [];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private ngRdx: NgRedux<IAdminState>
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.stateSubscription = this.ngRdx.select<ISystemModuleState['identity']>(['systemModule', 'identity'])
            .pipe(filter(v => v !== null))
            .subscribe(v => {
                this.identity = new Identity(v);
                this.updateView();
            });
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy(): void {
        this.stateSubscription.unsubscribe();
    }

    @Input() set admHasPermission(val) {
        this.permissions = Array.isArray(val) ? val : [val];
        this.updateView();
    }

    private updateView(): void {
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);

            return;
        }
        this.viewContainer.clear();
    }

    private checkPermission(): boolean {

        if (!this.identity) {
            return false;
        }

        for (let i = 0; i < this.permissions.length; i++) {
            if (this.identity.hasPermission(this.permissions[i])) {
                return true;
            }
        }

        return false;
    }
}
