import { IOverviewData, IOverviewDefinitionData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IOverviewModuleState extends IModuleState {
    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Identifies that all data for create page is loaded
     */
    createPageInitialized: boolean | null;

    /**
     * The id of overview, used in copy action
     */
    createPageRecordId: IOverviewData['id'] | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    createPageDefinitionSearchLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    createPageDefinitions: List<IOverviewDefinitionData['id']>;

    /**
     * The id of overview
     */
    editPageRecordId: IOverviewData['id'] | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Trigger to identify that search is running
     */
    editPageDefinitionSearchLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    editPageDefinitions: List<IOverviewDefinitionData['id']>;

    /**
     * The id of overview
     */
    viewPageRecordId: IOverviewData['id'] | null;

    /**
     * Collapsible form status
     */
    viewPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    viewPageLock: boolean | null;

    /**
     * Search form data
     */
    viewPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    viewPageResults: List<number>;

    /**
     * The pagination data
     */
    viewPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    viewPageOrder: Map<string, string> | null;

    /**
     * Search form data
     */
    viewPageLastSearchRq: Map<string, any>;
}

export const INITIAL_OVERVIEW_STATE = createTypedState<IOverviewModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageInitialized: null,
    createPageRecordId: null,
    createPageForm: { },
    createPageSaveLock: null,
    createPageDefinitionSearchLock: null,
    createPageDefinitions: [],
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageForm: { },
    editPageDefinitionSearchLock: null,
    editPageDefinitions: [],
    viewPageRecordId: null,
    viewPageCollapsed: null,
    viewPageLock: null,
    viewPageForm: { },
    viewPageResults: [],
    viewPagePagination: null,
    viewPageOrder: null,
    viewPageLastSearchRq: { },
});
