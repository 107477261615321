import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { Map } from 'immutable';

export interface IAppModuleState extends IModuleState {

    /**
     * Error message text
     */
    loginPageMessage: string;

    /**
     * Trigger to identify that login is running
     */
    loginLock: boolean | null;

    /**
     * Form data
     */
    loginPageForm: Map<string, any>;
}

export const INITIAL_APP_MODULE_STATE = createTypedState<IAppModuleState>({
    loginPageMessage: '',
    loginLock: null,
    loginPageForm: { },
});
