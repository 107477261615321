import { DashboardStory } from '@aclass/admin/storage/actions';
import { IDashboardModuleState, INITIAL_DASHBOARD_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export const dashboardModuleReducer = (state: TypedState<IDashboardModuleState> = INITIAL_DASHBOARD_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case DashboardStory.SEARCH_PAGE_UPDATE_OPPORTUNITIES_SEARCH_LOCK:
            return state.update('searchPageOpportunitiesLock', () => payload);
        case DashboardStory.SEARCH_PAGE_UPDATE_CUSTOMER_SEARCH_LOCK:
            return state.update('searchPageCustomersLock', () => payload);
        case DashboardStory.SEARCH_PAGE_UPDATE_STORAGE_OPPORTUNITY_IDS:
            return state.update('searchPageStorageOpportunityIds', () => List(payload));
        case DashboardStory.SEARCH_PAGE_UPDATE_STORAGE_CUSTOMERS_IDS:
            return state.update('searchPageStorageCustomersIds', () => List(payload));
        case DashboardStory.SEARCH_PAGE_IMPORT_OPPORTUNITY_RECORDS:
            return state.update('searchPageOpportunityResults', () => List(payload));
        case DashboardStory.SEARCH_PAGE_IMPORT_CUSTOMER_RECORDS:
            return state.update('searchPageCustomersResults', () => List(payload));
        case DashboardStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case DashboardStory.SEARCH_PAGE_CUSTOMER_UPDATE_ORDER:
            return state.update('searchPageCustomerOrder', () => Map(payload));
        default:
            return state;
    }
};
