import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IVamoosData {

    id: number | null;

    readonly vamoosId: number | null;

    readonly vamoosUser: string | null;

    readonly vamoosReferenceCode: string | null;

    readonly flightInfo: string | null;

    readonly flightUploadErrors: string | null;

    readonly departureDate: string | null;

    readonly startTime: string | null;

    readonly returnDate: string | null;

    readonly updatedAt: number | null;

    readonly createdAt: number | null;

    readonly canReload: boolean | null;
}

@Model({
    name: 'Vamoos'
})
export class Vamoos extends Entity<IVamoosData> {

    @Attr() id: number | null;

    @Attr() readonly vamoosId: number | null;

    @Attr() readonly vamoosUser: string | null;

    @Attr() readonly vamoosReferenceCode: string | null;

    @Attr() readonly flightInfo: string | null;

    @Attr() readonly flightUploadErrors: string | null;

    @Attr() readonly departureDate: string | null;

    @Attr() readonly startTime: string | null;

    @Attr() readonly returnDate: string | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly canReload: boolean | null;
}
