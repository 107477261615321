import { QuoteTemplateEditStateResolver } from '@aclass/admin/resolvers';
import {
    OrmStory,
    QuoteTemplateStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { IQuoteTemplateData } from '@aclass/core/storage/models/quote.template';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const quoteTemplateModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(QuoteTemplateStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.quoteTemplateModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            QuoteTemplateStory.updateFormSearchPage({ }),
            QuoteTemplateStory.updateCollapsedSearchPage(null),
            // Unlock page
            QuoteTemplateStory.updateSearchPageInitState(true),
            QuoteTemplateStory.updateInstancesOnSearchPage(instanceManager.getInstances().map(i => i.buildId()).toArray()),
        ])
    ),
    action.pipe(
        ofType(QuoteTemplateStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.quoteTemplateModule;
            const brands = module.getIn(['searchPageForm', 'brands']);
            const locales = module.getIn(['searchPageForm', 'locales']);

            return of(QuoteTemplateStory.updateInstancesOnSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()));
        }),
    ),
    action.pipe(
        ofType(QuoteTemplateStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.quoteTemplateModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => {
            const [brand, locale] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeaders([brand, locale]);

            return http.get(`offer-template`, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<IQuoteTemplateData>) => {
                        const actions = [
                            QuoteTemplateStory.updateFormOnEditPage({ }),
                            QuoteTemplateStory.updateIdEditPage(payload)
                        ];
                        if (rs.body) {
                            actions.push(OrmStory.populateQuoteTemplates([rs.body]));
                        }

                        return actions;
                    },
                    () => [
                        SystemStory.stopNavigate(QuoteTemplateEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/', 'ao', 'quote-templates'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(QuoteTemplateStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.quoteTemplateModule;
            const form = module.get('editPageForm').toJS();
            const result = {
                acceptButtonLabel: form.acceptButtonLabel,
                agencyInfo: form.agencyInfo,
                bookedButtonLabel: form.bookedButtonLabel,
                commentFlightTemplate: form.commentFlightTemplate,
                descriptionAddition: form.descriptionAddition,
                disabledButtonLabel: form.disabledButtonLabel,
                footer: form.footer,
                generalTravelScheme: form.generalTravelScheme,
                greeting: form.greeting,
                header: form.header,
                contact: form.contact,
                copyright: form.copyright,
                howBook: form.howBook,
                insurance: form.insurance,
                commentPrice: form.commentPrice,
                reducedMobility: form.reducedMobility,
                infectionData: form.infectionData,
                showBlocks: form.showBlocks,
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(QuoteTemplateStory.updateSaveLockEditPage(true)), http.post(`offer-template`, result, { ...params}).pipe(
                dispatchActions(
                    (rs: Response<IQuoteTemplateData>) => {
                        return [
                            QuoteTemplateStory.updateSaveLockEditPage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', 'Quote template updated')),
                            OrmStory.populateQuoteTemplates([rs.body]),
                            QuoteTemplateStory.updateSearchPageInitState(false)
                        ];
                    },
                    () => [
                        QuoteTemplateStory.updateSaveLockEditPage(false),
                        SystemStory.stopNavigate(QuoteTemplateEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/', 'ao', 'quote-templates'], { sharedLink: true })
                    ]
                ),
            ));
        }),
    ),
);
