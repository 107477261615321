import { IDashboardModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';

@Story({
    name: 'dashboard'
})
export class DashboardStory {

    @Task() static SEARCH_PAGE_OPPORTUNITY_DRY_RUN: string;

    @Task() static SEARCH_PAGE_CUSTOMER_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_LAST_CUSTOMERS: string;

    @Task() static SEARCH_PAGE_UPDATE_CUSTOMER_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_LAST_OPPORTUNITIES: string;

    @Task() static SEARCH_PAGE_UPDATE_OPPORTUNITIES_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_STORAGE_OPPORTUNITY_IDS: string;

    @Task() static SEARCH_PAGE_UPDATE_STORAGE_CUSTOMERS_IDS: string;

    @Task() static SEARCH_PAGE_IMPORT_OPPORTUNITY_RECORDS: string;

    @Task() static SEARCH_PAGE_IMPORT_CUSTOMER_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT_OPPORTUNITIES: string;

    @Task() static SEARCH_PAGE_SUBMIT_CUSTOMERS: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_CUSTOMER_UPDATE_ORDER: string;

    static dryRunOpportunityLoadingOnSearchPage(): Action {
        return { type: DashboardStory.SEARCH_PAGE_OPPORTUNITY_DRY_RUN };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }


    static updateCustomerOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: DashboardStory.SEARCH_PAGE_CUSTOMER_UPDATE_ORDER, payload };
    }

    static dryRunCustomerLoadingOnSearchPage(): Action {
        return { type: DashboardStory.SEARCH_PAGE_CUSTOMER_DRY_RUN };
    }

    static updateLastOpportunitiesOnSearchPage(payload: IOpportunityData['id'] | Array<IOpportunityData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_LAST_OPPORTUNITIES, payload };
    }

    static updateOpportunitiesSearchLockOnSearchPage(payload: IDashboardModuleState['searchPageOpportunitiesLock']): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_OPPORTUNITIES_SEARCH_LOCK, payload };
    }

    static updateLastCustomersOnSearchPage(payload: ICustomerData['id'] | Array<ICustomerData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_LAST_CUSTOMERS, payload };
    }

    static updateCustomersSearchLockOnSearchPage(payload: IDashboardModuleState['searchPageCustomersLock']): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_CUSTOMER_SEARCH_LOCK, payload };
    }

    static updateStorageOpportunityIdsOnSearchPage(payload: Array<IOpportunityData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_STORAGE_OPPORTUNITY_IDS, payload };
    }

    static updateStorageCustomersIdsOnSearchPage(payload: Array<ICustomerData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_UPDATE_STORAGE_CUSTOMERS_IDS, payload };
    }

    static importOpportunityRecordsOnSearchPage(payload: Array<IOpportunityData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_IMPORT_OPPORTUNITY_RECORDS, payload };
    }

    static importCustomerRecordsOnSearchPage(payload: Array<ICustomerData['id']>): Action {
        return { type: DashboardStory.SEARCH_PAGE_IMPORT_CUSTOMER_RECORDS, payload };
    }

    static submitSearchOpportunitiesOnSearchPage(): Action {
        return { type: DashboardStory.SEARCH_PAGE_SUBMIT_OPPORTUNITIES };
    }

    static submitSearchCustomersOnSearchPage(): Action {
        return { type: DashboardStory.SEARCH_PAGE_SUBMIT_CUSTOMERS };
    }
}
