import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IAnswersResultData {
    readonly type: string | null;

    readonly questionTitle: string | null;

    readonly answer: Array<any>;
}


export interface IQuestionnaireResultData {

    readonly id: string | null;

    readonly createAt: number | null;

    readonly email: string | null;

    readonly quote: string | null;

    readonly answers: Array<IAnswersResultData> | null;
}

@Model({
    name: 'QuestionnaireResult'
})
export class QuestionnaireResult extends Entity<IQuestionnaireResultData> {

    @Attr() readonly id: number | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly email: string | null;

    @Attr() readonly quote: string | null;

    @Attr() readonly answers: Array<IAnswersResultData> | null;
}
