import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDocumentData {

    readonly id: string | null;

    readonly name: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean | null;
}

@Model({
    name: 'Document'
})
export class Document extends Entity<IDocumentData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean | null;
}
