import { OverviewStory } from '@aclass/admin/storage/actions';
import { INITIAL_OVERVIEW_STATE, IOverviewModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const overviewModuleReducer = (state: TypedState<IOverviewModuleState> = INITIAL_OVERVIEW_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case OverviewStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case OverviewStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case OverviewStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case OverviewStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case OverviewStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case OverviewStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case OverviewStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case OverviewStory.CREATE_PAGE_UPDATE_INIT_STATE:
            return state.update('createPageInitialized', () => payload);
        case OverviewStory.CREATE_PAGE_UPDATE_OVERVIEW_ID:
            return state.update('createPageRecordId', () => payload);
        case OverviewStory.CREATE_PAGE_UPDATE_FORM:
            return state.update('createPageForm', () => fromJS(payload));
        case OverviewStory.CREATE_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK:
            return state.update('createPageDefinitionSearchLock', () => payload);
        case OverviewStory.CREATE_PAGE_UPDATE_DEFINITIONS:
            return state.update('createPageDefinitions', () => List(payload));
        case OverviewStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case OverviewStory.EDIT_PAGE_UPDATE_OVERVIEW_ID:
            return state.update('editPageRecordId', () => payload);
        case OverviewStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case OverviewStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case OverviewStory.EDIT_PAGE_UPDATE_DEFINITIONS_SEARCH_LOCK:
            return state.update('editPageDefinitionSearchLock', () => payload);
        case OverviewStory.EDIT_PAGE_UPDATE_DEFINITIONS:
            return state.update('editPageDefinitions', () => List(payload));
        case OverviewStory.VIEW_PAGE_UPDATE_OVERVIEW_ID:
            return state.update('viewPageRecordId', () => payload);
        case OverviewStory.VIEW_PAGE_UPDATE_FORM:
            return state.update('viewPageForm', () => payload);
        case OverviewStory.VIEW_PAGE_UPDATE_COLLAPSED:
            return state.update('viewPageCollapsed', () => payload);
        case OverviewStory.VIEW_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('viewPageLock', () => payload);
        case OverviewStory.VIEW_PAGE_UPDATE_PAGINATION:
            return state.update('viewPagePagination', () => Map(payload));
        case OverviewStory.VIEW_PAGE_UPDATE_ORDER:
            return state.update('viewPageOrder', () => Map(payload));
        case OverviewStory.VIEW_PAGE_IMPORT_RECORDS:
            return state.update('viewPageResults', () => List(payload));
        case OverviewStory.VIEW_PAGE_UPDATE_LAST_SEARCH_RQ:
            return state.update('viewPageLastSearchRq', () => fromJS(payload));
        default:
            return state;
    }
};
