<div class="login-wrapper">
    <form class="login" [attach]="ff.attach" [formGroup]="ff.form" (ngSubmit)="login()" novalidate>
        <section class="title">
            <h3 class="welcome">Welcome to</h3>
            Aclass
        </section>
        <div class="login-group">
            <select class="form-control auth-source" formControlName="application">
                <option *ngFor="let p of applications" [ngValue]="p">{{ p.toUpperCase() }}</option>
            </select>
            <input id="admin-user-name" class="form-control username" type="text" placeholder="Username" formControlName="email" (focus)="hideError()">
            <input id="admin-user-pass" class="form-control password" type="password" placeholder="Password" formControlName="password" (focus)="hideError()">
            <div class="error active fade" [class.in]="msg" [innerHTML]="msg"></div>
            <button id="admin-submit" [clrLoading]="loading" type="submit" class="btn btn-primary" (click)="hideError()">Login</button>
        </div>
    </form>
</div>
