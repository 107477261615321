import { IRoleData, IRolePermissionData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IAclModuleState extends IModuleState {

    /**
     * Identifies that all data for acl is loaded
     * In general it means, that all roles and permissions are available
     */
    initialized: boolean | null;

    /**
     * A list of ids(in current implementation is name) of permissions from ORM
     */
    permissions: List<string>;

    /**
     * A list of ids(in current implementation is name) of roles from ORM
     */
    roles: List<string>;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of roles ids(in current implementation is name) from ORM
     */
    searchPageResults: List<string>;

    /**
     * The configuration of permissions for role
     */
    createPageRolePermissions: List<IRolePermissionData>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    createPageForm: Map<string, any>;

    /**
     * The current edited role name
     */
    editPageRoleName: IRoleData['name'];

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * If user change self role application, the should re-login
     */
    editPageShouldLogout: boolean | null;

    /**
     * A list of roles
     */
    editPageRoleNames: List<string>;

    /**
     * Modal trigger
     */
    showRemoveModal: boolean | null;

    /**
     * Locks delete button
     */
    removeModalLock: boolean | null;

    /**
     * The configuration of permissions for role
     */
    editPageRolePermissions: List<IRolePermissionData>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Delete form data
     */
    editPageDeleteForm: Map<string, any>;
}

export const INITIAL_ACL_STATE = createTypedState<IAclModuleState>({
    initialized: null,
    permissions: [],
    roles: [],
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    createPageRolePermissions: [],
    createPageSaveLock: null,
    createPageForm: { },
    editPageRoleName: null,
    editPageSaveLock: null,
    editPageShouldLogout: null,
    editPageRoleNames: [],
    showRemoveModal: null,
    removeModalLock: null,
    editPageRolePermissions: [],
    editPageForm: { },
    editPageDeleteForm: { },
});
