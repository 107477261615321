import {
    ITWOptionData,
    ITWProductData,
} from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List } from 'immutable';

export interface IPackageItineraryModuleState extends IModuleState {

    /**
     * Product loading spinner status
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, boolean | null>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    dayProductSearchLock: boolean | null;

    /**
     * A list of product ids from ORM
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, List<IProductData['id']>>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    dayProducts: List<ITWProductData['id']>;

    /**
     * Option loading spinner status
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, boolean | null>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    dayOptionSearchLock: boolean | null;

    /**
     * A list of Option ids from ORM
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, List<IOptionData['id']>>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    dayOptions: List<ITWOptionData['id']>;
}

export const INITIAL_PACKAGE_ITINERARY_STATE = createTypedState<IPackageItineraryModuleState>({
    dayProductSearchLock: { },
    dayProducts: { },
    dayOptionSearchLock: { },
    dayOptions: { },
});
