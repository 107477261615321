import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IDashboardModuleState extends IModuleState {

    /**
     * Trigger to identify that search is running
     */
    searchPageOpportunitiesLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageCustomersLock: boolean | null;

    /**
     * An array of last viewed opportunities ids
     */
    searchPageStorageOpportunityIds: List<IOpportunityData['id']>;

    /**
     * An array of last viewed persons ids
     */
    searchPageStorageCustomersIds: List<ICustomerData['id']>;

    /**
     * A list of opportunity ids from ORM
     */
    searchPageOpportunityResults: List<number>;

    /**
     * A list of customer ids from ORM
     */
    searchPageCustomersResults: List<number>;

    /**
     * opportunity order
     */
    searchPageOrder: Map<string, string>  | null;

    /**
     * customer order
     */
    searchPageCustomerOrder: Map<string, string> | null;
}

export const INITIAL_DASHBOARD_STATE = createTypedState<IDashboardModuleState>({
    searchPageOpportunitiesLock: null,
    searchPageCustomersLock: null,
    searchPageStorageOpportunityIds: [],
    searchPageStorageCustomersIds: [],
    searchPageOpportunityResults: [],
    searchPageCustomersResults: [],
    searchPageOrder: null,
    searchPageCustomerOrder: null,
});
