import { Exception, IExceptionData, ITWOptionData } from '@aclass/admin/storage/models';
import { IExceptionModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'exception'
})
export class ExceptionStory {

    @Task() static PRODUCT_UPDATE_OPTIONS: string;

    @Task() static PRODUCT_ID_UPDATE_OPTIONS_IDS: string;

    @Task() static PRODUCT_OPTION_SEARCH: string;

    @Task() static IMPORT_HOTELS_RECORDS: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_PRODUCT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_ID: string;

    static productUpdateOptions( payload: Array<ITWOptionData>): Action {
        return { type: ExceptionStory.PRODUCT_UPDATE_OPTIONS, payload  };
    }

    static productIdUpdateOptions(uid: number, ids: Array<ITWOptionData['id']>): Action {
        return { type: ExceptionStory.PRODUCT_ID_UPDATE_OPTIONS_IDS, payload: { uid, ids } };
    }

    static productSearchOptions(payload): Action {
        return { type: ExceptionStory.PRODUCT_OPTION_SEARCH, payload };
    }

    static importProductsIds(items: Array<Object>): Action {
        return { type: ExceptionStory.IMPORT_HOTELS_RECORDS, payload: items };
    }

    static dryRunSearchPage(): Action {
        return { type: ExceptionStory.SEARCH_PAGE_DRY_RUN };
    }

    static createPageDryRun(payload): Action {
        return { type: ExceptionStory.CREATE_PAGE_DRY_RUN, payload };
    }

    static updateSearchPageInitState(payload: IExceptionModuleState['searchPageInitialized']): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IExceptionModuleState['searchPageCollapsed']): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: IExceptionModuleState['searchPageLock']): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: ExceptionStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<Exception['id']>): Action {
        return { type: ExceptionStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: ExceptionStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static updateSaveLockCreatePage(payload: IExceptionModuleState['createPageSaveLock']): Action {
        return { type: ExceptionStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: ExceptionStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: IExceptionData['id']): Action {
        return { type: ExceptionStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateProductIdEditPage(payload: IExceptionModuleState['editPageRecordId']): Action {
        return { type: ExceptionStory.EDIT_PAGE_UPDATE_PRODUCT_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: ExceptionStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IExceptionModuleState['editPageSaveLock']): Action {
        return { type: ExceptionStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static removeOnEditPage(): Action {
        return { type: ExceptionStory.EDIT_PAGE_REMOVE };
    }

    static submitOnEditPage(payload: IExceptionData['exceptionText']): Action {
        return { type: ExceptionStory.EDIT_PAGE_SUBMIT, payload };
    }

    static updateIdEditPage(id: IExceptionData['id']): Action {
        return { type: ExceptionStory.EDIT_PAGE_UPDATE_ID, payload: id };
    }
}
