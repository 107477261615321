import { RouterManager } from '@aclass/admin/managers';
import { SettingsStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISettingsModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class MarginColorEditStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop: margin-color-range-edit';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISettingsModuleState['marginColorRangePageInitialized']>(['settingsModule', 'marginColorRangePageInitialized']).pipe(
            filter((v: boolean) => v)
        );
        this.employee = this.routerManager.onRejectNavigation(MarginColorEditStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(SettingsStory.dryRunMarginColorRangePage());
    }
}
