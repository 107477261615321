import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';
import { Fk } from '../../../../../src/decorators/orm.decorator';
import { Questionnaire } from './questionnaire';

export interface IQuestionData {

    id: number | null;

    name: string | null;

    description: string | null;

    required: boolean | null;

    customAnswer: boolean | null;

    customAnswerText: string | null;

    type: string | null;

    options: any | null;

    questionnaireId: Questionnaire | number | null;
}


@Model({
    name: 'Question'
})
export class Question extends Entity<IQuestionData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly description: string | null;

    @Attr() readonly required: boolean | null;

    @Attr() readonly customAnswer: boolean | null;

    @Attr() readonly customAnswerText: string | null;

    @Attr() readonly type: string | null;

    @Attr() readonly options: string | null;

    @Fk({ to: 'Questionnaire', relatedName: 'relatedQuestions' }) readonly questionnaireId: Questionnaire;
}
