import { ISwitchButtonData } from '@aclass/admin/components';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITWProductData {
    readonly id: number;

    readonly code: string;

    readonly brand: string | null;

    readonly name: string;

    readonly type: string;

    readonly pickup: ILocationData | null;

    readonly dropoff: ILocationData | null;

    readonly createdAt: number;

    readonly updatedAt: number;

    readonly deleted: boolean;
}

export interface ILocationData {
    readonly country: string;

    readonly state: string;

    readonly city: string;

    readonly cityCode: string;

    readonly address: string;
}

@Model({
    name: 'TWProduct'
})
export class TWProduct extends Entity<ITWProductData> {

    static TYPE_HOTEL = 'HOTEL';

    static TYPE_EXCURSION = 'EXCURSION';

    static TYPE_TRANSFER = 'TRANSFER';

    static TYPE_ROUND_TRIP = 'ROUND_TRIP';

    static TYPES = [
        TWProduct.TYPE_ROUND_TRIP,
        TWProduct.TYPE_HOTEL,
        TWProduct.TYPE_TRANSFER,
        TWProduct.TYPE_EXCURSION,
    ];

    static TYPE_NAMES = {
        [TWProduct.TYPE_ROUND_TRIP]: 'Round trip',
        [TWProduct.TYPE_HOTEL]: 'Accommodation',
        [TWProduct.TYPE_TRANSFER]: 'Transfer',
        [TWProduct.TYPE_EXCURSION]: 'Excursion',
    };

    static TYPE_ICONS = {
        [TWProduct.TYPE_ROUND_TRIP]: 'sync',
        [TWProduct.TYPE_HOTEL]: 'store',
        [TWProduct.TYPE_TRANSFER]: 'car',
        [TWProduct.TYPE_EXCURSION]: 'map',
    };

    @Attr() readonly id: number;

    @Attr() readonly code: string;

    @Attr() readonly name: string;

    @Attr() readonly type: string;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly pickup: ILocationData | null;

    @Attr() readonly dropoff: ILocationData | null;

    @Attr() readonly createdAt: number;

    @Attr() readonly updatedAt: number;

    @Attr() readonly deleted: boolean;

    static isHotel(type: ITWProductData['type'] | null): boolean {
        return type === TWProduct.TYPE_HOTEL;
    }

    static isRt(type: ITWProductData['type'] | null): boolean {
        return type === TWProduct.TYPE_ROUND_TRIP;
    }

    static isExcursion(type: ITWProductData['type'] | null): boolean {
        return type === TWProduct.TYPE_EXCURSION;
    }

    static isTransfer(type: ITWProductData['type'] | null): boolean {
        return type === TWProduct.TYPE_TRANSFER;
    }

    static isAllocable(type: ITWProductData['type'] | null): boolean {
        return [TWProduct.TYPE_ROUND_TRIP, TWProduct.TYPE_HOTEL].includes(type);
    }

    /**
     * Transform type code to name
     */
    get typeName(): string {
        return this.type in TWProduct.TYPE_NAMES ? TWProduct.TYPE_NAMES[this.type] : '';
    }

    get pickupAddress(): string {
        return TWProduct.formatAddress(this.pickup);
    }

    get dropoffAddress(): string {
        return TWProduct.formatAddress(this.dropoff);
    }

    get fullAddress(): string {
        return [this.pickupAddress, this.dropoffAddress].filter(r => r).join(' — ');
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeTypesForSwitchButton(): Array<ISwitchButtonData<ITWProductData['type']>> {
        return TWProduct.TYPES.map(r => ({
            title: TWProduct.TYPE_NAMES[r],
            icon: TWProduct.TYPE_ICONS[r],
            value: r,
        }));
    }

    private static formatAddress(v: ILocationData | null): string {
        if (!v) {
            return '';
        }
        const { country, state, city, address } = v;

        return [country, state, city, address].filter(r => r).join(', ');
    }
}


