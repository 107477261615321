import { IRedirectModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'redirect'
})
export class RedirectStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_INSTANCES: string;

    @Task() static RECORDS_SEARCH_PAGE_DRY_RUN: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_ID: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static RECORDS_SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static RECORDS_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static RECORDS_SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_UPDATE_INSTANCE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_REDIRECT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL: string;

    @Task() static EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE_RECORD: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: RedirectStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IRedirectModuleState['searchPageInitialized']): Action {
        return { type: RedirectStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IRedirectModuleState['searchPageCollapsed']): Action {
        return { type: RedirectStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: RedirectStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateInstancesOnSearchPage(v: Array<string>): Action {
        return { type: RedirectStory.SEARCH_PAGE_UPDATE_INSTANCES, payload: v };
    }

    static dryRunRecordsSearchPage(id: string): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_DRY_RUN, payload: id };
    }

    static updateRecordsSearchPageId(payload: IRedirectModuleState['recordsSearchPageId']): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_ID, payload };
    }

    static updateCollapsedRecordsSearchPage(payload: IRedirectModuleState['recordsSearchPageCollapsed']): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormRecordsSearchPage(data): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnRecordsSearchPage(payload: IRedirectModuleState['recordsSearchPageLock']): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnRecordsSearchPage(payload: IPaginationData | null): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnRecordsSearchPage(payload: IOrderData | null): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnRecordsSearchPage(payload: Array<string>): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(): Action {
        return { type: RedirectStory.SEARCH_PAGE_SUBMIT };
    }

    static submitOnRecordsSearchPage(): Action {
        return { type: RedirectStory.RECORDS_SEARCH_PAGE_SUBMIT };
    }

    static updateSaveLockCreatePage(payload: IRedirectModuleState['createPageSaveLock']): Action {
        return { type: RedirectStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static dryRunCreatePage(payload: IRedirectModuleState['editPageInstanceId']): Action {
        return { type: RedirectStory.CREATE_PAGE_DRY_RUN, payload };
    }

    static updateCreatePageInstanceId(payload: IRedirectModuleState['createPageInstanceId']): Action {
        return { type: RedirectStory.CREATE_PAGE_UPDATE_INSTANCE_ID, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: RedirectStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IRedirectModuleState['editPageRecordId']): Action {
        return { type: RedirectStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdEditPage(payload: IRedirectModuleState['editPageRecordId']): Action {
        return { type: RedirectStory.EDIT_PAGE_UPDATE_REDIRECT_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: RedirectStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IRedirectModuleState['editPageSaveLock']): Action {
        return { type: RedirectStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateShowRemoveModalOnEditPage(v: boolean): Action {
        return { type: RedirectStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL, payload: v };
    }

    static updateRemoveModalLockOnEditPage(v: boolean): Action {
        return { type: RedirectStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK, payload: v };
    }

    static removeRedirectOnEditPage(): Action {
        return { type: RedirectStory.EDIT_PAGE_REMOVE_RECORD };
    }

    static submitOnEditPage(): Action {
        return { type: RedirectStory.EDIT_PAGE_SUBMIT };
    }
}
