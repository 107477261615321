import { ItineraryDayValue } from '@aclass/admin/components/package.itinerary';
import {
    ITWOptionData,
    ITWProductData,
} from '@aclass/admin/storage/models';
import { IPackageItineraryModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'packageItinerary'
})
export class PackageItineraryStory {

    @Task() static DAY_REGISTER: string;

    @Task() static DAY_DEREGISTER: string;

    @Task() static DAY_UPDATE_PRODUCTS: string;

    @Task() static DAY_UPDATE_OPTIONS: string;

    @Task() static DAY_PRODUCT_SEARCH_LOCK: string;

    @Task() static DAY_OPTION_SEARCH_LOCK: string;

    @Task() static DAY_PRODUCT_SEARCH: string;

    @Task() static DAY_OPTION_SEARCH: string;

    static dayRegister(payload: string): Action {
        return { type: PackageItineraryStory.DAY_REGISTER, payload };
    }

    static dayDeregister(payload: string): Action {
        return { type: PackageItineraryStory.DAY_DEREGISTER, payload };
    }

    static dayUpdateSearchProductLock(uid: string, lock: IPackageItineraryModuleState['dayProductSearchLock']): Action {
        return { type: PackageItineraryStory.DAY_PRODUCT_SEARCH_LOCK, payload: { uid, lock } };
    }

    static dayUpdateSearchOptionLock(uid: string, lock: IPackageItineraryModuleState['dayOptionSearchLock']): Action {
        return { type: PackageItineraryStory.DAY_OPTION_SEARCH_LOCK, payload: { uid, lock } };
    }

    static daySearchProducts(uid: string, params: Pick<ItineraryDayValue, 'productType'> & { name?: string, pcTourId?: string, pcCombinationId?: string }): Action {
        return { type: PackageItineraryStory.DAY_PRODUCT_SEARCH, payload: { uid, ...params } };
    }

    static daySearchOptions(uid: string, params: Pick<ItineraryDayValue, 'productType' | 'productId' | 'date'> & { name?: string }): Action {
        return { type: PackageItineraryStory.DAY_OPTION_SEARCH, payload: { uid, ...params } };
    }

    static dayUpdateProducts(uid: string, ids: Array<ITWProductData['id']>): Action {
        return { type: PackageItineraryStory.DAY_UPDATE_PRODUCTS, payload: { uid, ids } };
    }

    static dayUpdateOptions(uid: string, ids: Array<ITWOptionData['id']>): Action {
        return { type: PackageItineraryStory.DAY_UPDATE_OPTIONS, payload: { uid, ids } };
    }
}
