import { RouterManager } from '@aclass/admin/managers';
import { InternationalizationStory } from '@aclass/admin/storage/actions';
import { IAdminState, IInternationalizationModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class InternationalizationSearchStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:internationalization-search';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager,
    ) {
        super();
        this.resolver = this.ngRdx.select<IInternationalizationModuleState['searchPageInitialized']>(['internationalizationModule', 'searchPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(InternationalizationSearchStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(route: ActivatedRouteSnapshot): void {
        this.ngRdx.dispatch(InternationalizationStory.dryRunSearchPage());
    }
}
