import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IInternationalizationModuleState extends IModuleState {

    /**
     * current module name
     */
    category: string | null;

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * A list of product ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The id of product
     */
    showPageRecordId: number | null;

    /**
     * Create form data
     */
    showPageForm: Map<string, any>;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;
}

export const INITIAL_INTERNATIONALIZATION_STATE = createTypedState<IInternationalizationModuleState>({
    category: null,
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPagePagination: null,
    searchPageOrder: null,
    searchPageResults: [],
    showPageRecordId: null,
    showPageForm: { },
    searchPageForm: { },
});
