import { Daytable } from '@aclass/core/base/daytable';
import { formatDate } from '@angular/common';

export class BaseDateFormatPipe {

    /**
     * Default date format
     */
    static DATE = 'd.m.Y';

    /**
     * Default date time format
     */
    static TIME = 'H:i';

    private transformFormat(format?: string): string {
        if (!format) {
            return '';
        }

        return format.split('').map(symbol => {
            switch (symbol) {
                case 'd':
                    return 'dd';
                case 'D':
                    return 'EE';
                case 'j':
                    return 'd';
                case 'l':
                    return 'EEEE';
                case 'F':
                    return 'MMMM';
                case 'm':
                    return 'MM';
                case 'M':
                    return 'MMM';
                case 'n':
                    return 'M';
                case 'Y':
                    return 'yyyy';
                case 'y':
                    return 'YY';
                case 'g':
                    return 'h';
                case 'G':
                    return 'H';
                case 'h':
                    return 'hh';
                case 'H':
                    return 'HH';
                case 'i':
                    return 'mm';
                case 's':
                    return 'ss';
                default:
                    return symbol;
            }
        }).join('');
    }

    /**
     * Formats a date according to locale rules.
     * @see {formatDate}
     */
    protected transform(value: any, ...args: any[]): string {
        const v = value instanceof Daytable ? value.toDate() : value;
        const format = args.filter(f => f).join(' ');

        return v instanceof Date && format ? formatDate(v, this.transformFormat(format), 'en-US') : '';
    }
}
