import { CampaignStory } from '@aclass/admin/storage/actions';
import { ICampaignModuleState, INITIAL_CAMPAIGN_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const campaignModuleReducer = (state: TypedState<ICampaignModuleState> = INITIAL_CAMPAIGN_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case CampaignStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case CampaignStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case CampaignStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case CampaignStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case CampaignStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case CampaignStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case CampaignStory.SEARCH_PAGE_UPDATE_DESTINATIONS:
            return state.update('searchPageDestinations', () => List(payload));
        case CampaignStory.SEARCH_PAGE_UPDATE_TOURS:
            return state.update('searchPageTours', () => List(payload));
        case CampaignStory.SEARCH_PAGE_UPDATE_FILTER_DESTINATIONS:
            return state.update('searchPageFilterDestinations', () => List(payload));
        case CampaignStory.SEARCH_PAGE_UPDATE_FILTER_TOURS:
            return state.update('searchPageFilterTours', () => List(payload));
        case CampaignStory.CREATE_PAGE_UPDATE_DESTINATIONS:
            return state.update('createPageDestinations', () => fromJS(payload));
        case CampaignStory.CREATE_PAGE_UPDATE_TOURS:
            return state.update('createPageTours', () => fromJS(payload));
        case CampaignStory.CREATE_PAGE_IMPORT_TOURS:
            return state.update('createPageToursIds', () => List(payload));
        case CampaignStory.CREATE_PAGE_IMPORT_DESTINATIONS:
            return state.update('createPageDestinationsIds', () => List(payload));
        case CampaignStory.CREATE_PAGE_IMPORT_COMBINATIONS:
            return state.update('createPageCombinationsIds', () => List(payload));
        case CampaignStory.CREATE_PAGE_IMPORT_DESTINATIONS_MAPPINGS:
            return state.update('createPageDestinationsMappings', () => fromJS(payload));
        case CampaignStory.CREATE_PAGE_IMPORT_COMBINATIONS_MAPPINGS:
            return state.update('createPageCombinationsMappings', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_UPDATE_DESTINATIONS:
            return state.update('editPageDestinations', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_UPDATE_TOURS:
            return state.update('editPageTours', () => fromJS(payload));
        case CampaignStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case CampaignStory.CREATE_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('createPageSearchLock', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_CAMPAIGN_ID:
            return state.update('editPageRecordId', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_PAUSED_STATE:
            return state.update('editPageRecordPausedState', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_FORM_VALUE:
            return state.update('editPageFormValue', () => fromJS(payload));
        case CampaignStory.CREATE_PAGE_UPDATE_INIT_STATE:
            return state.update('createPageInitialized', () => payload);
        case CampaignStory.CREATE_PAGE_UPDATE_FORM_VALUE:
            return state.update('createPageFormValue', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case CampaignStory.EDIT_PAGE_IMPORT_TOURS:
            return state.update('editPageToursIds', () => List(payload));
        case CampaignStory.EDIT_PAGE_IMPORT_DESTINATIONS:
            return state.update('editPageDestinationsIds', () => List(payload));
        case CampaignStory.EDIT_PAGE_IMPORT_COMBINATIONS:
            return state.update('editPageCombinationsIds', () => List(payload));
        case CampaignStory.EDIT_PAGE_IMPORT_DESTINATIONS_MAPPINGS:
            return state.update('editPageDestinationsMappings', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_IMPORT_COMBINATIONS_MAPPINGS:
            return state.update('editPageCombinationsMappings', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('editPageSearchLock', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case CampaignStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL:
            return state.update('showRemoveModal', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK:
            return state.update('removeModalLock', () => payload);
        case CampaignStory.HIDE_DELETE_SHOW_RESTORE_BUTTONS:
            return state.update('hideDeleteShowRestore', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_SHOW_PAUSE_MODAL:
            return state.update('showPauseModal', () => payload);
        case CampaignStory.EDIT_PAGE_UPDATE_PAUSE_MODAL_LOCK:
            return state.update('pauseModalLock', () => payload);
        default:
            return state;
    }
};
