import { LeadScoringStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const leadScoringModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(LeadScoringStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.leadScoringModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            LeadScoringStory.updateFormSearchPage({ }),
            LeadScoringStory.updateCollapsedSearchPage(null),
            // Unlock page
            LeadScoringStory.updateSearchPageInitState(true),
            LeadScoringStory.updateInstancesOnSearchPage(instanceManager.getInstances().map(i => i.buildId()).toArray()),
        ])
    ),
    action.pipe(
        ofType(LeadScoringStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.leadScoringModule;
            const brands = module.getIn(['searchPageForm', 'brands']);
            const locales = module.getIn(['searchPageForm', 'locales']);

            return of(LeadScoringStory.updateInstancesOnSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()));
        }),
    ),
    action.pipe(
        ofType(LeadScoringStory.EDIT_PAGE_ADD_FORM_ELEMENT),
        mergeMap(({ payload }: Action<string>) => {
            const module = state.value.leadScoringModule;
            const form = module.get('editPageForm').toJS();
            const leadScoring = module.get('editPageLeadScoreValue').toJS();
            const destinationsList = module.get('editPageDestinations').toJS();
            const airportsList = module.get('editPageAirports').toJS();
            const answersList = module.get('editPageAnswers').toJS();
            const destinations = form.destination;
            const airports = form.airport;
            const answers = form.answer;
            const departures = [];
            const paxes = form.pax;
            const pages = [];
            const returnsCounts = [];
            const timeSpent = [];

            destinations.forEach((value, key) => {
                const destination = destinationsList.find(v => v.id === value.destination);
                const id = destination ? destination.id : null;
                const name = destination ? destination.title : null;
                destinations[key].destination = { id: id, name: name };
            });
            airports.forEach((value, key) => {
                const airport = airportsList.find(v => v.id === value.airport);
                const id = airport ? airport.id : null;
                const name = airport ? airport.name : null;
                airports[key].airport = { id: id, name: name };
            });
            form.departure.forEach((v) => {
                const values = v.departure.split('-');
                departures.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            form.page.forEach((v) => {
                const values = v.page.split('-');
                pages.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            form.returnsCount.forEach((v) => {
                const values = v.return.split('-');
                returnsCounts.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            form.timeSpent.forEach((v) => {
                const values = v.time.split('-');
                timeSpent.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            switch (payload) {
                case 'destinations':
                    if (!(destinations.length < destinationsList.length)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its maximum.`))];
                    }
                    destinations.push({
                        destination: null,
                        points: 0
                    });
                    break;
                case 'airports':
                    if (!(airports.length < airportsList.length)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its maximum.`))];
                    }
                    airports.push({
                        airport: null,
                        points: 0
                    });
                    break;
                case 'answers':
                    if (!(answers.length < answersList.length)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its maximum.`))];
                    }
                    answers.push({
                        answer: null,
                        points: 0
                    });
                    leadScoring.answers = answers;
                    break;
                case 'departures':
                    departures.push({
                        min: 0,
                        max: 0,
                        points: 0
                    });
                    break;
                case 'paxes':
                    paxes.push({
                        count: 0,
                        points: 0
                    });
                    break;
                case 'pages':
                    pages.push({
                        min: 0,
                        max: 0,
                        points: 0
                    });
                    break;
                case 'returnsCounts':
                    returnsCounts.push({
                        min: 0,
                        max: 0,
                        points: 0
                    });
                    break;
                case 'timeSpent':
                    timeSpent.push({
                        min: 0,
                        max: 0,
                        points: 0
                    });
                    break;
            }
            leadScoring.destinations = destinations;
            leadScoring.airports = airports;
            leadScoring.answers = answers;
            leadScoring.departures = departures;
            leadScoring.pax = paxes;
            leadScoring.pagesSeen = pages;
            leadScoring.returnsCount = returnsCounts;
            leadScoring.timeSpent = timeSpent;

            return [LeadScoringStory.updateLeadScoreValueOnEditPage(leadScoring)];
        })
    ),
    action.pipe(
        ofType(LeadScoringStory.EDIT_PAGE_REMOVE_FORM_ELEMENT),
        mergeMap(({ payload }: Action) => {
            const index = payload.shift();
            const name = payload.shift();
            const module = state.value.leadScoringModule;
            const form = module.get('editPageForm').toJS();
            const leadScoring = module.get('editPageLeadScoreValue').toJS();
            const destinationsList = module.get('editPageDestinations').toJS();
            const airportsList = module.get('editPageAirports').toJS();
            const destinations = form.destination;
            const airports = form.airport;
            const answers = form.answer;
            const departures = [];
            const paxes = form.pax;
            const pages = [];
            const returnsCounts = [];
            const timeSpent = [];

            destinations.forEach((value, key) => {
                const destination = destinationsList.find(v => v.id === value.destination);
                const id = destination ? destination.id : null;
                const destinationName = destination ? destination.title : null;
                destinations[key].destination = { id: id, name: destinationName };
            });
            airports.forEach((value, key) => {
                const airport = airportsList[0].data.find(v => v.id === value.airport);
                const id = airport ? airport.id : null;
                const airportName = airport ? airport.title : null;
                airports[key].airport = { id: id, name: airportName };
            });
            form.departure.forEach((v) => {
                const values = v.departure.split('-');
                departures.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.page.forEach((v) => {
                const values = v.page.split('-');
                pages.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.returnsCount.forEach((v) => {
                const values = v.return.split('-');
                returnsCounts.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.timeSpent.forEach((v) => {
                const values = v.time.split('-');
                timeSpent.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            switch (name) {
                case 'destinations':
                    if (!(destinations.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    destinations.splice(index, 1);
                    break;
                case 'airports':
                    if (!(airports.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    airports.splice(index, 1);
                    break;
                case 'answers':
                    if (!(answers.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    answers.splice(index, 1);
                    break;
                case 'departures':
                    if (!(departures.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    departures.splice(index, 1);
                    break;
                case 'paxes':
                    if (!(paxes.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    paxes.splice(index, 1);
                    break;
                case 'pages':
                    if (!(pages.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    pages.splice(index, 1);
                    break;
                case 'returnsCounts':
                    if (!(returnsCounts.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    returnsCounts.splice(index, 1);
                    break;
                case 'timeSpent':
                    if (!(timeSpent.length >= 0)) {
                        return [SystemStory.showNotification(SystemNotification.error('Error', `The number of elements has reached its minimum.`))];
                    }
                    timeSpent.splice(index, 1);
                    break;
            }
            leadScoring.destinations = destinations;
            leadScoring.airports = airports;
            leadScoring.answers = answers;
            leadScoring.departures = departures;
            leadScoring.pax = paxes;
            leadScoring.pagesSeen = pages;
            leadScoring.returnsCount = returnsCounts;
            leadScoring.timeSpent = timeSpent;

            return [LeadScoringStory.updateLeadScoreValueOnEditPage(leadScoring)];
        })
    ),
    action.pipe(
        ofType(LeadScoringStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.leadScoringModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => {
            const queue = [];
            const [brand, locale] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeaders([brand, locale]);
            let namesRq: DataSearchRqData;
            namesRq = {
                where: {
                    brands: [brand],
                    locales: [locale],
                },
                pagination: new Pagination().all()
            };
            queue.push(http.get(`lead-score`, { ...params, ...{ observe: 'response' } }));
            queue.push(http.get(`lead-score/get-airports`, { ...params, ...{ observe: 'response' } }));
            queue.push(http.post(`lead-score/destination-names`, namesRq, { observe: 'response' }));

            return zip(...queue).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [
                        LeadScoringStory.updateProductIdEditPage(payload),
                        LeadScoringStory.updateFormOnEditPage({ })
                    ];
                    const answers = ['HOT', 'MEDIUM', 'INSPIRATION', 'OTHER', 'NOT DEFINED'];
                    actions.push(LeadScoringStory.updateAnswersOnEditPage(answers));
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.endsWith(`lead-score`)) {
                            actions.push(LeadScoringStory.updateLeadScoreValueOnEditPage(rs.body));
                        } else if (response.url.includes('lead-score/get-airports')) {
                            actions.push(LeadScoringStory.updateAirportsOnEditPage(rs.body));
                        } else if (response.url.includes(`lead-score/destination-names`)) {
                            actions.push(LeadScoringStory.updateDestinationsOnEditPage(rs.body.results));
                        }
                    });

                    return actions;
                })
            );
        }),
    ),
    action.pipe(
        ofType(LeadScoringStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.leadScoringModule;
            const form = module.get('editPageForm').toJS();
            const destinations = form.destination;
            const destinationsList = module.get('editPageDestinations').toJS();
            const airports = form.airport;
            const airportsList = module.get('editPageAirports').toJS();
            const departures = [];
            const pages = [];
            const returnsCount = [];
            const timeSpent = [];
            destinations.forEach((value, key) => {
                const destination = destinationsList.find(v => v.id === value.destination);
                const id = destination ? destination.id : null;
                const name = destination ? destination.name : null;
                destinations[key].destination = { id: id, name: name };
            });
            airports.forEach((value, key) => {
                const airport = airportsList.find(v => v.id === value.airport);
                const id = airport ? airport.id : null;
                const name = airport ? airport.name : null;
                airports[key].airport = { id: id, name: name };
            });
            form.departure.forEach((v) => {
                const values = v.departure.split('-');
                departures.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.page.forEach((v) => {
                const values = v.page.split('-');
                pages.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.returnsCount.forEach((v) => {
                const values = v.return.split('-');
                returnsCount.push({ min: values.shift(), max: values.shift(), points: v.points });
            });
            form.timeSpent.forEach((v) => {
                const values = v.time.split('-');
                timeSpent.push({ min: values.shift(), max: values.shift(), points: v.points });
            });

            const result = {
                returnsCount: returnsCount,
                timeSpent: timeSpent,
                airports: airports,
                answers: form.answer,
                departures: departures,
                destinations: destinations,
                pagesSeen: pages,
                pax: form.pax,
                phone: form.phone.shift(),
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return http.post(`lead-score`, result, params).pipe(
                dispatchActions((rs: Response<any>) => {
                    const actions = [
                        SystemStory.showNotification(SystemNotification.success('Success', `Lead scores updated`)),
                    ];
                    actions.push(LeadScoringStory.updateLeadScoreValueOnEditPage(rs.body));
                    actions.push(LeadScoringStory.updateSearchPageInitState(false));

                    return actions;
                })
            );
        }),
    )
);
