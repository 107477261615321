import { ITWContractData, TWContract } from '@aclass/admin/storage/models/tw.contract';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITWTransferOptionData {
    readonly id: string;

    readonly contractId: ITWContractData['id'];

    readonly sid: string;

    readonly name: string;

    readonly deleted: boolean;
}

@Model({
    name: 'TWTransferOption'
})
export class TWTransferOption extends Entity<ITWTransferOptionData> {

    @Attr() readonly id: string;

    @Attr() readonly sid: string;

    @Attr() readonly name: string;

    @Attr() readonly deleted: boolean;

    @Fk({ to: 'TWContract', relatedName: 'relatedContract' }) readonly contractId: TWContract | null;
}
