import { hashed } from '@aclass/core/helpers/hashed';
import { Map, Record } from 'immutable';

interface MetaData {
    [key: string]: any;
}

export interface ISystemAlertData {

    /**
     * Alert id
     */
    id: string;

    /**
     * The type of notification
     */
    type: number;

    /**
     * Any extra data
     */
    meta: MetaData;
}

export class SystemAlert extends Record({ id: undefined, type: undefined, meta: Map({ }) }) {

    static TYPE_SERVER_ERROR = 1;

    static TYPE_FORBIDDEN_ERROR = 2;

    static TYPE_UNAUTHORIZED_ERROR = 3;

    static TYPE_UNSUPPORTED_BROWSER = 11;

    static TYPE_UPDATE_AVAILABLE = 12;

    /**
     * Alert id
     */
    id: string;

    /**
     * The type of notification
     */
    type: number;

    /**
     * Any extra data
     */
    meta: Map<string, any>;

    constructor(config?: Partial<ISystemAlertData> | Map<string, any>) {
        super(config);
    }

    private static create(type: number, meta: MetaData = { }): ISystemAlertData {
        return { id: hashed(), type, meta };
    }

    static serverError(meta?: MetaData): ISystemAlertData {
        return SystemAlert.create(SystemAlert.TYPE_SERVER_ERROR, meta);
    }

    static forbiddenError(meta?: MetaData): ISystemAlertData {
        return SystemAlert.create(SystemAlert.TYPE_FORBIDDEN_ERROR, meta);
    }

    static unauthorizedError(meta?: MetaData): ISystemAlertData {
        return SystemAlert.create(SystemAlert.TYPE_UNAUTHORIZED_ERROR, meta);
    }

    static unsupportedBrowser(meta?: MetaData): ISystemAlertData {
        return SystemAlert.create(SystemAlert.TYPE_UNSUPPORTED_BROWSER, meta);
    }

    static updateAvailable(meta?: MetaData): ISystemAlertData {
        return SystemAlert.create(SystemAlert.TYPE_UPDATE_AVAILABLE, meta);
    }

    with(values: any): SystemAlert {
        return this.merge(values) as this;
    }

    /**
     * Determines that this is the internal server error
     */
    isServerError(): boolean {
        return this.type === SystemAlert.TYPE_SERVER_ERROR;
    }

    /**
     * Determines that this is the forbidden error
     */
    isForbiddenError(): boolean {
        return this.type === SystemAlert.TYPE_FORBIDDEN_ERROR;
    }

    /**
     * Determines that this is the unauthorized error
     */
    isUnauthorizedError(): boolean {
        return this.type === SystemAlert.TYPE_UNAUTHORIZED_ERROR;
    }

    /**
     * Determines that this is unsupported browser
     */
    isUnsupportedBrowser(): boolean {
        return this.type === SystemAlert.TYPE_UNSUPPORTED_BROWSER;
    }

    /**
     * Determines that this is update
     */
    isUpdateAvailable(): boolean {
        return this.type === SystemAlert.TYPE_UPDATE_AVAILABLE;
    }
}
