import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IAdditionalProductPrice {
    [key: string]: number;
}

export interface IAdditionalProductData {

    readonly id: number;

    readonly brand: string;

    readonly brandLabel: string;

    readonly name: string;

    readonly createdAt: number;

    readonly updatedAt: number;

    readonly prices: IAdditionalProductPrice;
}

@Model({
    name: 'AdditionalProduct'
})
export class AdditionalProduct extends Entity<IAdditionalProductData> {

    @Attr() readonly id: number | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly prices: IAdditionalProductPrice | null;
}
