// Not a mistake, because depends on this module components
import { DataGridLinkDirective } from '@aclass/admin/directives/data.grid.link.directive';
import { DateWatcherDirective } from '@aclass/admin/directives/date.watcher.directive';
import { DateWatcherFilteredDirective } from '@aclass/admin/directives/date.watcher.filtered.directive';
import { DateWatcherNodeDirective } from '@aclass/admin/directives/date.watcher.node.directive';
import { HasPermissionDirective } from '@aclass/admin/directives/has.assess.directive';
import { SelectTitleDirective } from '@aclass/admin/directives/select.title.directive';
import { LocalePriceFormatPipe } from '@aclass/admin/pipes';
import {
    AclCreateStateResolver,
    AclEditStateResolver,
    AclStateResolver,
    AdditionalProductEditStateResolver,
    AdditionalProductSearchStateResolver,
    InternationalizationSearchStateResolver,
    InternationalizationShowStateResolver,
    OfficeWorkingHoursEditStateResolver,
    OfficeWorkingHoursSearchStateResolver,
    OverviewCreateStateResolver,
    OverviewEditStateResolver,
    OverviewSearchStateResolver,
    OverviewViewStateResolver,
    PipelineEditStateResolver,
    PipelineSearchStateResolver,
    QuoteWorkingHoursEditStateResolver,
    QuoteWorkingHoursSearchStateResolver,
    UserCreateStateResolver,
    UserEditStateResolver,
    UserSearchStateResolver,
} from '@aclass/admin/resolvers';
import { StoreAttachFormBaseDirective } from '@aclass/core/directives/store.attach.form.base.directive';
import { StoreAttachFormReactiveDirective } from '@aclass/core/directives/store.attach.form.reactive.directive';
import { DateFormatPipe } from '@aclass/core/pipes/date.format.pipe';
import { DatePipe } from '@aclass/core/pipes/date.pipe';
import { DateRangeFormatPipe } from '@aclass/core/pipes/date.range.format.pipe';
import { DateRangePipe } from '@aclass/core/pipes/date.range.pipe';
import { DateTimeFormatPipe } from '@aclass/core/pipes/date.time.format.pipe';
import { DateTimePipe } from '@aclass/core/pipes/date.time.pipe';
import { LengthPipe } from '@aclass/core/pipes/length.pipe';
import { NumberFormatPipe } from '@aclass/core/pipes/number.format.pipe';
import { PriceFormatPipe } from '@aclass/core/pipes/price.format.pipe';
import { SafeHtmlPipe } from '@aclass/core/pipes/safe.html';
import { TimestampPipe } from '@aclass/core/pipes/timestamp.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { ChartistModule } from 'ng-chartist';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AclCreateComponent } from './shared/acl/create/create.component';
import { AclEditComponent } from './shared/acl/edit/edit.component';
import { AclSearchComponent } from './shared/acl/search/search.component';
import { BackButtonComponent } from './shared/button/back/back-button.component';
import { CheckboxButtonComponent } from './shared/button/checkbox/checkbox-button.component';
import { SwitchButtonComponent } from './shared/button/switch/switch-button.component';
import { ToggleButtonComponent } from './shared/button/toggle/toggle-button.component';
import { UploadButtonComponent } from './shared/button/upload/upload-button.component';
import { WhBackButtonComponent } from './shared/button/wh-back/wh-back-button.component';
import { AdmBarChartComponent } from './shared/chartist/bar-chart.component';
import { ContentLayoutComponent } from './shared/content-layout/content-layout.component';
import { DatePickerComponent } from './shared/date/picker/date-picker.component';
import { DateRangePickerComponent } from './shared/date/range-picker/date-range-picker.component';
import { DateTimePickerComponent } from './shared/date/time-picker/date-time-picker.component';
import { DgExpandButtonComponent } from './shared/dg/expand-button/dg-expand-button.component';
import { DgLayoutComponent } from './shared/dg/layout/dg-layout.component';
import { DgLinkComponent } from './shared/dg/link/dg-link.component';
import { DgPaginationComponent } from './shared/dg/pagination/dg-pagination.component';
import { ImageGalleryComponent } from './shared/image/gallery/image-gallery.component';
import { ImageSelectComponent } from './shared/image/select/image-select.component';
import { InlinePaginationComponent } from './shared/inline-pagination/inline-pagination.component';
import { NumberInputComponent } from './shared/input/number/number-input.component';
import { RangeInputComponent } from './shared/input/range/range-input.component';
import { InternationalizationSearchComponent } from './shared/internationalization/search/internationalization-search.component';
import { InternationalizationShowComponent } from './shared/internationalization/show/internationalization-show.component';
import { ModalAskComponent } from './shared/modal/ask/modal-ask.component';
import { PlaceholderComponent } from './shared/placeholder/placeholder.component';
import { ReportIssueComponent } from './shared/report-issue/report-issue.component';
import { SystemAlertsComponent } from './shared/system-alerts/system-alerts.component';
import { UserCreateComponent } from './shared/user/create/user-create.component';
import { UserEditComponent } from './shared/user/edit/user-edit.component';
import { UserProfileComponent } from './shared/user/profile/profile.component';
import { UserSearchComponent } from './shared/user/search/user-search.component';
import { WysiwygComponent } from './shared/wysiwyg/wysiwyg.component';

@NgModule({
    declarations: [
        AclCreateComponent,
        AclEditComponent,
        AclSearchComponent,
        AdmBarChartComponent,
        BackButtonComponent,
        CheckboxButtonComponent,
        ContentLayoutComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DateTimePickerComponent,
        DgExpandButtonComponent,
        DgLayoutComponent,
        DgLinkComponent,
        DgPaginationComponent,
        InlinePaginationComponent,
        ImageGalleryComponent,
        ImageSelectComponent,
        InternationalizationSearchComponent,
        InternationalizationShowComponent,
        ModalAskComponent,
        NumberInputComponent,
        PlaceholderComponent,
        RangeInputComponent,
        ReportIssueComponent,
        SwitchButtonComponent,
        SystemAlertsComponent,
        ToggleButtonComponent,
        UploadButtonComponent,
        UserCreateComponent,
        UserEditComponent,
        UserProfileComponent,
        UserSearchComponent,
        WysiwygComponent,
        DataGridLinkDirective,
        DateWatcherDirective,
        DateWatcherNodeDirective,
        DateWatcherFilteredDirective,
        HasPermissionDirective,
        SelectTitleDirective,
        StoreAttachFormBaseDirective,
        StoreAttachFormReactiveDirective,
        DateFormatPipe,
        DatePipe,
        DateRangeFormatPipe,
        DateRangePipe,
        DateTimeFormatPipe,
        DateTimePipe,
        LengthPipe,
        LocalePriceFormatPipe,
        NumberFormatPipe,
        PriceFormatPipe,
        SafeHtmlPipe,
        TimestampPipe,
        WhBackButtonComponent,
    ],
    imports: [
        AngularMyDatePickerModule,
        ClarityModule,
        CommonModule,
        FormsModule,
        MyDatePickerModule,
        MyDateRangePickerModule,
        NgSelectModule,
        ReactiveFormsModule,
        RouterModule,
        ColorPickerModule,
        ChartistModule,
        NgxTrimDirectiveModule
    ],
    exports: [
        ClarityModule,
        ColorPickerModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgxTrimDirectiveModule,
        ReactiveFormsModule,
        RouterModule,
        SimpleNotificationsModule,
        AclCreateComponent,
        AclEditComponent,
        AclSearchComponent,
        AdmBarChartComponent,
        BackButtonComponent,
        CheckboxButtonComponent,
        ContentLayoutComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DateTimePickerComponent,
        DgExpandButtonComponent,
        DgLayoutComponent,
        DgLinkComponent,
        DgPaginationComponent,
        InlinePaginationComponent,
        ImageGalleryComponent,
        ImageSelectComponent,
        InternationalizationSearchComponent,
        InternationalizationShowComponent,
        ModalAskComponent,
        NumberInputComponent,
        PlaceholderComponent,
        RangeInputComponent,
        ReportIssueComponent,
        SwitchButtonComponent,
        SystemAlertsComponent,
        ToggleButtonComponent,
        UploadButtonComponent,
        UserCreateComponent,
        UserEditComponent,
        UserProfileComponent,
        UserSearchComponent,
        WysiwygComponent,
        DataGridLinkDirective,
        DateWatcherDirective,
        DateWatcherNodeDirective,
        DateWatcherFilteredDirective,
        HasPermissionDirective,
        SelectTitleDirective,
        StoreAttachFormBaseDirective,
        StoreAttachFormReactiveDirective,
        DateFormatPipe,
        DatePipe,
        DateRangeFormatPipe,
        DateRangePipe,
        DateTimeFormatPipe,
        DateTimePipe,
        LengthPipe,
        LocalePriceFormatPipe,
        NumberFormatPipe,
        PriceFormatPipe,
        SafeHtmlPipe,
        TimestampPipe,
        WhBackButtonComponent,
    ],
    providers: [
        AclCreateStateResolver,
        AclEditStateResolver,
        AclStateResolver,
        AdditionalProductEditStateResolver,
        AdditionalProductSearchStateResolver,
        InternationalizationSearchStateResolver,
        InternationalizationShowStateResolver,
        OfficeWorkingHoursEditStateResolver,
        OfficeWorkingHoursSearchStateResolver,
        OverviewCreateStateResolver,
        OverviewEditStateResolver,
        OverviewSearchStateResolver,
        OverviewViewStateResolver,
        PipelineEditStateResolver,
        PipelineSearchStateResolver,
        QuoteWorkingHoursEditStateResolver,
        QuoteWorkingHoursSearchStateResolver,
        UserCreateStateResolver,
        UserEditStateResolver,
        UserSearchStateResolver,
        LocalePriceFormatPipe,
        NumberFormatPipe,
        PriceFormatPipe,
        SafeHtmlPipe,
    ],
})

export class AppSharedModule {

}
