import { DomainDetail } from '@aclass/admin/storage/models/domain.detail';
import { SmsMessage } from '@aclass/admin/storage/models/sms.message';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ISmsTemplateData {

    readonly id: number | null;

    readonly name: string | null;

    readonly deleted: boolean | null;
}

@Model({
    name: 'SmsTemplate'
})

export class SmsTemplate extends Entity<ISmsTemplateData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly deleted: boolean | null;

    readonly relatedDomainDetail: QuerySet<DomainDetail>;

    readonly relatedSmsTemplates: QuerySet<SmsMessage>;

    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<ISmsTemplateData, 'id' | 'name'> => ({ id: this.id, name: this.name });
}
