import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'file'
})
export class FileStory {

    @Task() static IMAGE_GALLERY_SEARCH_LOCK: string;

    @Task() static IMAGE_GALLERY_UPDATE_PAGINATION: string;

    @Task() static IMAGE_GALLERY_IMPORT_RECORDS: string;

    @Task() static IMAGE_GALLERY_SEARCH: string;

    @Task() static IMAGE_GALLERY_SELECT: string;

    @Task() static IMAGE_GALLERY_UPDATE_SELECTED: string;

    @Task() static IMAGE_GALLERY_UPLOAD: string;

    @Task() static IMAGE_GALLERY_UPLOAD_LOCK: string;

    @Task() static IMAGE_GALLERY_UPDATE_EDIT_LOCK: string;

    @Task() static IMAGE_GALLERY_UPDATE_EDIT_FORM: string;

    @Task() static IMAGE_GALLERY_EDIT_FORM_SUBMIT: string;

    @Task() static IMAGE_GALLERY_REMOVE: string;

    @Task() static IMAGE_GALLERY_REMOVE_RECORD: string;

    static updateSearchLockImageGallery(v: boolean | null): Action {
        return { type: FileStory.IMAGE_GALLERY_SEARCH_LOCK, payload: v };
    }

    static updatePaginationImageGallery(pagination: IPaginationData | null): Action {
        return { type: FileStory.IMAGE_GALLERY_UPDATE_PAGINATION, payload: pagination };
    }

    static importRecordsImageGallery(ids: Array<number>): Action {
        return { type: FileStory.IMAGE_GALLERY_IMPORT_RECORDS, payload: ids };
    }

    static searchImageGallery(): Action {
        return { type: FileStory.IMAGE_GALLERY_SEARCH };
    }

    static selectImageGallery(v: number | null): Action {
        return { type: FileStory.IMAGE_GALLERY_SELECT, payload: v };
    }

    static updateSelectedImageGallery(v: number | null): Action {
        return { type: FileStory.IMAGE_GALLERY_UPDATE_SELECTED, payload: v };
    }

    static uploadImageGallery(files: Array<File>): Action {
        return { type: FileStory.IMAGE_GALLERY_UPLOAD, payload: files };
    }

    static updateUploadLockImageGallery(v: boolean | null): Action {
        return { type: FileStory.IMAGE_GALLERY_UPLOAD_LOCK, payload: v };
    }

    static updateEditLockImageGallery(v: boolean | null): Action {
        return { type: FileStory.IMAGE_GALLERY_UPDATE_EDIT_LOCK, payload: v };
    }

    static updateEditFormImageGallery(data: any): Action {
        return { type: FileStory.IMAGE_GALLERY_UPDATE_EDIT_FORM, payload: data };
    }

    static submitEditFormImageGallery(): Action {
        return { type: FileStory.IMAGE_GALLERY_EDIT_FORM_SUBMIT };
    }

    static removeImageGallery(id: number): Action {
        return { type: FileStory.IMAGE_GALLERY_REMOVE, payload: id };
    }

    static removeRecordImageGallery(id: number): Action {
        return { type: FileStory.IMAGE_GALLERY_REMOVE_RECORD, payload: id };
    }
}
