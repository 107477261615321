import { IAnonymizationData, IDateFormattingOptionValueForm, IFormattingOptions, ISystemFormattingOptions } from '@aclass/admin/components';
import {
    OfferValiditySearchStateResolver,
    OfficeWorkingHoursEditStateResolver,
    Page404SearchStateResolver,
    PriceFormattingEditStateResolver,
    QuoteWorkingHoursEditStateResolver,
    ReturningCustomersEditStateResolver
} from '@aclass/admin/resolvers';
import { DateFormattingEditStateResolver } from '@aclass/admin/resolvers/date.formatting-edit.state.resolver';
import { OfferSendIntervalEditStateResolver } from '@aclass/admin/resolvers/offer.send.interval.edit.state.resolver';
import { OrmStory, SettingsStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { I404PageData, IColorRangeSettings, IOfferValidityData, IOfficeWorkingHoursData, IQuoteWorkingHoursData, Setting, TourDestinations, TourSettings } from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { PriceFormatTypes } from '@aclass/admin/utils';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const settingsModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('quoteWorkingHoursSearchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SettingsStory.updateFormOnQuoteWorkingHoursSearchPage({ }),
            SettingsStory.importRecordsOnQuoteWorkingHoursSearchPage([]),
            SettingsStory.importInstancesOnQuoteWorkingHoursSearchPage([]),
            SettingsStory.updateSearchLockOnQuoteWorkingHoursSearchPage(null),
            SettingsStory.updateCollapsedQuoteWorkingHoursSearchPage(null),
            // Unlock page
            SettingsStory.updateQuoteWorkingHoursSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const brands: Array<Instance['brand']> = module.getIn(['quoteWorkingHoursSearchPageForm', 'brands']);
            const locales: Array<Instance['locale']> = module.getIn(['quoteWorkingHoursSearchPageForm', 'locales']);
            const rq: DataSearchRqData = {
                where: {
                    brands,
                    locales,
                },
                pagination: new Pagination().all()
            };

            return concat(of(SettingsStory.updateSearchLockOnQuoteWorkingHoursSearchPage(true)), http.post('setting/search-offer-working-hours', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IQuoteWorkingHoursData>>>) => [
                    OrmStory.populateQuoteWorkingHours(rs.body.results),
                    SettingsStory.importRecordsOnQuoteWorkingHoursSearchPage(rs.body.results.map(r => r.id)),
                    SettingsStory.importInstancesOnQuoteWorkingHoursSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()),
                    SettingsStory.updateSearchLockOnQuoteWorkingHoursSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.settingsModule.get('quoteWorkingHoursEditPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => http.get('setting/offer-working-hours', instanceManager.buildHeaders(instanceManager.parseId(payload))).pipe(
            dispatchActions(
                (rs: Response<IQuoteWorkingHoursData | null>) => {
                    const actions = [
                        SettingsStory.updateRecordIdQuoteWorkingHoursEditPage(payload),
                        SettingsStory.updateFormQuoteWorkingHoursEditPage({ }),
                    ];
                    if (rs.body) {
                        actions.push(OrmStory.populateQuoteWorkingHours([rs.body]));
                    }

                    return actions;
                },
                () => [
                    SystemStory.stopNavigate(QuoteWorkingHoursEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['working-hours'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_SUBMIT),
        switchMap(() => {
            const module = state.value.settingsModule;
            const data = module.get('quoteWorkingHoursEditPageForm');
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('quoteWorkingHoursEditPageRecordId')));

            return concat(of(SettingsStory.updateSaveLockQuoteWorkingHoursEditPage(true)), http.put(`setting/offer-working-hours`, data, params).pipe(
                dispatchActions((rs: Response<IQuoteWorkingHoursData>) => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Working hours updated`)),
                    OrmStory.populateQuoteWorkingHours([rs.body]),
                    SettingsStory.updateSaveLockQuoteWorkingHoursEditPage(false)
                ])
            ));
        })
    ),
    action.pipe(
        ofType(SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('officeWorkingHoursSearchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SettingsStory.updateFormOnOfficeWorkingHoursSearchPage({ }),
            SettingsStory.importRecordsOnOfficeWorkingHoursSearchPage([]),
            SettingsStory.importInstancesOnOfficeWorkingHoursSearchPage([]),
            SettingsStory.updateSearchLockOnOfficeWorkingHoursSearchPage(null),
            SettingsStory.updateCollapsedOfficeWorkingHoursSearchPage(null),
            // Unlock page
            SettingsStory.updateOfficeWorkingHoursSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const brands: Array<Instance['brand']> = module.getIn(['officeWorkingHoursSearchPageForm', 'brands']);
            const locales: Array<Instance['locale']> = module.getIn(['officeWorkingHoursSearchPageForm', 'locales']);
            const rq: DataSearchRqData = {
                where: {
                    brands,
                    locales,
                },
                pagination: new Pagination().all()
            };

            return concat(of(SettingsStory.updateSearchLockOnOfficeWorkingHoursSearchPage(true)), http.post('setting/search-office-working-hours', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IOfficeWorkingHoursData>>>) => [
                    OrmStory.populateOfficeWorkingHours(rs.body.results),
                    SettingsStory.importRecordsOnOfficeWorkingHoursSearchPage(rs.body.results.map(r => r.id)),
                    SettingsStory.importInstancesOnOfficeWorkingHoursSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()),
                    SettingsStory.updateSearchLockOnOfficeWorkingHoursSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.settingsModule.get('officeWorkingHoursEditPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => http.get('setting/office-working-hours', instanceManager.buildHeaders(instanceManager.parseId(payload))).pipe(
            dispatchActions(
                (rs: Response<IOfficeWorkingHoursData | null>) => {
                    const actions = [
                        SettingsStory.updateRecordIdOfficeWorkingHoursEditPage(payload),
                        SettingsStory.updateFormOfficeWorkingHoursEditPage({ }),
                    ];
                    if (rs.body) {
                        actions.push(OrmStory.populateOfficeWorkingHours([rs.body]));
                    }

                    return actions;
                },
                () => [
                    SystemStory.stopNavigate(OfficeWorkingHoursEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['working-hours'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_SUBMIT),
        switchMap(() => {
            const module = state.value.settingsModule;
            const data = module.get('officeWorkingHoursEditPageForm');
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('officeWorkingHoursEditPageRecordId')));

            return concat(of(SettingsStory.updateSaveLockOfficeWorkingHoursEditPage(true)), http.put(`setting/office-working-hours`, data, params).pipe(
                dispatchActions((rs: Response<IOfficeWorkingHoursData>) => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Working hours updated`)),
                    OrmStory.populateOfficeWorkingHours([rs.body]),
                    SettingsStory.updateSaveLockOfficeWorkingHoursEditPage(false)
                ])
            ));
        })
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('offerValiditySearchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SettingsStory.updateFormOnOfferValiditySearchPage({ }),
            SettingsStory.importRecordsOnOfferValiditySearchPage([]),
            SettingsStory.importInstancesOnOfferValiditySearchPage([]),
            SettingsStory.updateSearchLockOnOfferValiditySearchPage(null),
            SettingsStory.updateCollapsedOfferValiditySearchPage(null),
            // Unlock page
            SettingsStory.updateOfferValiditySearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const brands: Array<Instance['brand']> = module.getIn(['offerValiditySearchPageForm', 'brands']);
            const locales: Array<Instance['locale']> = module.getIn(['offerValiditySearchPageForm', 'locales']);
            const rq: DataSearchRqData = {
                where: {
                    brands,
                    locales,
                    model: 'OfferSettings',
                },
                pagination: new Pagination().all()
            };

            return concat(of(SettingsStory.updateSearchLockOnOfferValiditySearchPage(true)), http.post('offer-settings', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IOfferValidityData>>>) => [
                    OrmStory.populateOfferValidity(rs.body.results),
                    SettingsStory.importRecordsOnOfferValiditySearchPage(rs.body.results.map(r => r.id)),
                    SettingsStory.importInstancesOnOfferValiditySearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()),
                    SettingsStory.updateSearchLockOnOfferValiditySearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_EDIT_PAGE_ADD_VALUES),
        switchMap(({ payload }: Action<number>) => {
            const module = state.value.settingsModule;
            const valuesForms = module.get('offerValidityEditPageValuesForms').toJS();
            const active: Array<string> = module.get('offerValidityEditPageValuesFormsActive').toJS();
            const prev = Object.keys(valuesForms).pop();
            const next = prev ? parseInt(prev) + 1 : 0;
            for (let i = 0; i < payload; i++) {
                const k = String(i + next);
                valuesForms[k] = valuesForms[k] || { };
                active.push(k);
            }

            return [
                SettingsStory.updateValuesFormsOnOfferValidityEditPage(valuesForms),
                SettingsStory.updateValuesFormsActiveOnOfferValidityEditPage(active),
            ];
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_EDIT_PAGE_REMOVE_VALUES_FORM),
        mergeMap(({ payload }: Action<number>) => {
            const module = state.value.settingsModule;
            const meta: Array<string> = module.get('offerValidityEditPageValuesFormsActive').toJS();
            meta.splice(payload, 1);

            return of(SettingsStory.updateValuesFormsActiveOnOfferValidityEditPage(meta));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.settingsModule.get('offerValidityEditPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => {
            const parsedId = instanceManager.parseId(payload);
            let rq: DataSearchRqData;
            rq = {
                where: {
                    model: 'OfferSettings',
                    brands: [parsedId.shift()],
                    locales: [parsedId.shift()],
                },
            };

            return http.post(`offer-settings`, rq).pipe(
                dispatchActions(
                    (rs: Response<IDataSearchRs<Array<IOfferValidityData>>>) => {
                        let firstRecord = null;
                        let defaultValue = null;
                        let values = null;
                        if (rs.body.results.length > 0) {
                            firstRecord = rs.body.results.shift();
                            defaultValue = firstRecord.value.default;
                            values = firstRecord.value.data;
                        }

                        const actions = [
                            SettingsStory.updateFormOfferValidityEditPage({ }),
                            SettingsStory.updateRecordIdOfferValidityEditPage(payload),
                            SettingsStory.updateDefaultValueOnOfferValidityEditPage(defaultValue),
                            SettingsStory.updateValuesOnOfferValidityEditPage(values),
                            SettingsStory.updateValuesFormsOnOfferValidityEditPage({ }),
                            SettingsStory.updateValuesFormsActiveOnOfferValidityEditPage([]),
                        ];

                        if (values && values.length > 0) {
                            actions.push(SettingsStory.addValuesOfferValidityEditPage(values.length));
                        }

                        return actions;
                    },
                    () => [
                        SystemStory.stopNavigate(OfferValiditySearchStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['offer-validity'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_VALIDITY_EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const mainForm = module.get('offerValidityEditPageForm');
            const activeValuesForms = module.get('offerValidityEditPageValuesFormsActive').toJS();
            const values = activeValuesForms.map(rf => {
                const holidayFormData: Map<string, any> = module.getIn(['offerValidityEditPageValuesForms', rf]);

                return {
                    validityDate: holidayFormData.get('validityDate'),
                    expirationDate: holidayFormData.get('expirationDate'),
                };
            });
            const defaultValue = parseInt(mainForm.get('default'));
            const value = {
                default: defaultValue,
                data: values
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('offerValidityEditPageRecordId')));

            return http.put(`offer-settings/update`, value, params).pipe(
                dispatchActions((rs: Response<IOfferValidityData>) => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Offer validity settings updated`)),
                    OrmStory.populateOfferValidity([rs.body]),
                    SettingsStory.updateOfferValiditySearchPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.ANONYMIZATION_EDIT_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('anonymizationPageInitialized')),
        mergeMap(() => {

            return http.get(`setting/anonymization`).pipe(
                dispatchActions(
                    (rs: Response<IAnonymizationData | null>) => {
                        return [
                            SettingsStory.updateAutomaticAnonymizationPeriodValue(rs.body.value),
                            SettingsStory.updateAnonymizationPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(QuoteWorkingHoursEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.ANONYMIZATION_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;

            return http.put(`setting/anonymization`, module.get('anonymizationEditPageForm')).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Automated anonymization settings updated`)),
                    SettingsStory.updateOfferValiditySearchPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.SEARCH_404_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('search404PageInitialized')),
        switchMap(() => [
            SettingsStory.submitOnSearch404Page(),
            SettingsStory.updateSearch404PageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SettingsStory.SEARCH_404_PAGE_SUBMIT),
        mergeMap(() => {
            const queue = of(SettingsStory.updateSearchLockOnSearch404Page(true));

            return concat(queue, http.get('page-404-settings').pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<I404PageData>>>) => {

                    return [
                        OrmStory.populate404Page(rs.body.results),
                        SettingsStory.importRecordsOnSearch404Page(rs.body.results.map(r => r.id)),
                        SettingsStory.updateSearchLockOnSearch404Page(false),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_404_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.settingsModule.get('edit404PageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => {

            return http.get(`page-404-settings/${ payload }`).pipe(
                dispatchActions(
                    (rs: Response<I404PageData>) => {
                        return [
                            SettingsStory.updateFormValueOnEdit404Page({ }),
                            SettingsStory.updateRecordIdEdit404Page(payload),
                            SettingsStory.updateValueOnEdit404Page(rs.body.value),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(Page404SearchStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/', 'cms', 'page-404-settings'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_404_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const form = module.get('edit404PageForm');
            const id = module.get('edit404PageRecordId');

            return http.put(`page-404-settings/${ id }`, form).pipe(
                dispatchActions((rs: Response<I404PageData>) => [
                    SystemStory.showNotification(SystemNotification.success('Success', `404 page settings updated`)),
                    OrmStory.populate404Page([rs.body]),
                    SettingsStory.updateSearch404PageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_DATE_FORMATTING_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('editDateFormattingPageInitialized')),
        mergeMap(() => {

            return http.get(`setting`).pipe(
                dispatchActions(
                    (rs: Response<IFormattingOptions | null>) => {

                        return [
                            SettingsStory.updateValueOnEditDateFormattingPage(rs.body.dateFormatting.value),
                            SettingsStory.updateEditDateFormattingPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(DateFormattingEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_DATE_FORMATTING_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const form: IDateFormattingOptionValueForm = module.get('editDateFormattingPageForm').toJS();
            const result = { };
            for (const [key, format] of Object.entries(form)) {
                result[key] = { format };
            }

            return http.post(`setting/date-formatting`, { model: 'dateFormatting', data: result }).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Date formatting settings updated`)),
                    SettingsStory.updateEditDateFormattingPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_PRICE_FORMATTING_PAGE_DRY_RUN),
        mergeMap(() => {
            return http.get(`setting`).pipe(
                dispatchActions(
                    (rs: Response<IFormattingOptions | null>) => {
                        return [
                            SettingsStory.updateValueOnEditPriceFormattingPage(rs.body.priceFormatting.value),
                            SettingsStory.updateEditPriceFormattingPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(PriceFormattingEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_PRICE_FORMATTING_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const form: { [key: string]: { format: string, thousandSeparator: string | null, decimalSeparator: string | null } } = module.get('editPriceFormattingPageForm').toJS();
            const data = Object.keys(form).reduce((prev: object, locale: string) => {
                const { format, thousandSeparator, decimalSeparator } = form[locale];
                prev[locale] = {
                    format,
                    thousandSeparator: thousandSeparator !== PriceFormatTypes.TYPE_DEFAULT ? thousandSeparator : undefined,
                    decimalSeparator: decimalSeparator !== PriceFormatTypes.TYPE_DEFAULT ? decimalSeparator : undefined,
                };

                return prev;
            }, { });

            return http.post(`setting/price-formatting`, { model: 'priceFormatting', data: data }).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Price formatting settings updated`)),
                    SettingsStory.updateEditPriceFormattingPageInitState(false),
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_PRICE_COLOR_RANGE_SUBMIT),
        mergeMap(() => {
            const settingsFormsList = state.value.settingsModule.getIn(['marginColorRangeEditPageForm', 'colorRange']).toJS();
            const data = [];
            settingsFormsList.map(item => {
                item.instances.map(instance => {
                    data.push({ instanceId: instance, from: item.range.from, to: item.range.to });
                });
            });

            return http.post(`setting/margin-color`, { model: 'colorRange', data: data }).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Margin color range settings updated`)),
                    SettingsStory.updateEditPriceFormattingPageInitState(false),
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_DRY_RUN),
        mergeMap(() => {

            return http.get(`setting/system`).pipe(
                dispatchActions(
                    (rs: Response<ISystemFormattingOptions | null>) => {
                        const value = rs.body.systemPriceFormatting ? rs.body.systemPriceFormatting.value : '';

                        return [
                            SettingsStory.updateValueOnEditSystemPriceFormattingPage(value),
                            SettingsStory.updateEditSystemPriceFormattingPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(PriceFormattingEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const form: { [key: string]: { format: string, thousandSeparator: string | null, decimalSeparator: string | null } } = module.get('editSystemPriceFormattingPageForm').toJS();
            const data = Object.keys(form).reduce((prev: object, locale: string) => {
                const { format, thousandSeparator, decimalSeparator } = form[locale];
                prev[locale] = {
                    format,
                    thousandSeparator: thousandSeparator !== PriceFormatTypes.TYPE_DEFAULT ? thousandSeparator : undefined,
                    decimalSeparator: decimalSeparator !== PriceFormatTypes.TYPE_DEFAULT ? decimalSeparator : undefined,
                };

                return prev;
            }, { });

            return http.post(`setting/price-formatting`, { model: 'systemPriceFormatting', data: data }).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Price formatting settings updated`)),
                    SettingsStory.updateEditSystemPriceFormattingPageInitState(false),
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.MARGIN_COLOR_RANGE_EDIT_PAGE_DRY_RUN),
        mergeMap(() => {
            const rq: DataSearchRqData = {
                where: {
                    name: Setting.TYPE_COLOR_RANGE_SCHEME
                },
                pagination: new Pagination().all()
            };

            return http.post(`setting/search`, rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<Setting<IColorRangeSettings>>>>) => [
                        OrmStory.populateSettings(rs.body.results),
                        SettingsStory.updateMarginColorRangeForm({ }),
                        SettingsStory.updateMarginColorRangePageInitState(true),
                    ],
                    () => [
                        SystemStory.stopNavigate(OfferSendIntervalEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_SEND_INTERVAL_EDIT_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('offerSendIntervalPageInitialized')),
        mergeMap(() => {

            return http.get(`worker-settings/time-sent`).pipe(
                dispatchActions(
                    (rs: Response<{ time: number }>) => {
                        return [
                            SettingsStory.updateOfferSendIntervalValue(rs.body.time),
                            SettingsStory.updateOfferSendIntervalPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(OfferSendIntervalEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true }),
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.OFFER_SEND_INTERVAL_PAGE_SUBMIT),
        mergeMap(() => {
            return http.post(`worker-settings/time-sent`, state.value.settingsModule.get('offerSendIntervalEditPageForm')).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Offer send interval settings updated`)),
                    SettingsStory.updateOfferSendIntervalPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('manualHandlingSearchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SettingsStory.updateFormManualHandlingSearchPage({ }),
            SettingsStory.updateCollapsedManualHandlingSearchPage(null),
            // Unlock page
            SettingsStory.updateManualHandlingSearchPageInitState(true),
            SettingsStory.updateInstancesOnManualHandlingSearchPage(instanceManager.getInstances().map(i => i.buildId()).toArray()),
        ])
    ),
    action.pipe(
        ofType(SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const brands = module.getIn(['manualHandlingSearchPageForm', 'brands']);
            const locales = module.getIn(['manualHandlingSearchPageForm', 'locales']);

            return of(SettingsStory.updateInstancesOnManualHandlingSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()));
        }),
    ),
    action.pipe(
        ofType(SettingsStory.MANUAL_HANDLING_EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.settingsModule.get('manualHandlingEditPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => {
            const queue = [];
            const [brand, locale] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeaders([brand, locale]);
            let namesRq: DataSearchRqData;
            namesRq = {
                where: {
                    brands: [brand],
                    locales: [locale],
                },
                pagination: new Pagination().all()
            };
            queue.push(http.get(`manual-handling-settings`, { ...params, ...{ observe: 'response' } }));
            queue.push(http.post(`manual-handling-settings/destination-names`, namesRq, { observe: 'response' }));
            queue.push(http.post(`manual-handling-settings/tour-names`, namesRq, { observe: 'response' }));

            return zip(...queue).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [
                        SettingsStory.updateRecordIdManualHandlingEditPage(payload),
                        SettingsStory.updateFormOnManualHandlingEditPage({ })
                    ];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes('manual-handling-settings/tour-names')) {
                            actions.push(OrmStory.populateToursSettings(rs.body.results));
                            actions.push(SettingsStory.updateToursOnManualHandlingEditPage(rs.body.results));
                        } else if (response.url.includes(`manual-handling-settings/destination-names`)) {
                            actions.push(OrmStory.populateToursDestinations(rs.body.results));
                            actions.push(SettingsStory.updateDestinationsOnManualHandlingEditPage(rs.body.results));
                        } else if (response.url.includes(`manual-handling-settings`)) {
                            actions.push(SettingsStory.updateOtherValuesOnManualHandlingEditPage(rs.body));
                        }
                    });

                    return actions;
                })
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.MANUAL_HANDLING_EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.settingsModule;
            const form = module.get('manualHandlingEditPageForm').toJS();
            const destinations = [];
            const tours = [];
            form.destinations.forEach((v) => {
                const destination = findById<TourDestinations>(orm.session(state.value.orm), TourDestinations, v);
                destinations.push({ id: destination.id, name: destination.name });
            });
            form.tours.forEach((v) => {
                const tour = findById<TourSettings>(orm.session(state.value.orm), TourSettings, v);
                tours.push({ id: tour.id, name: tour.name });
            });
            const result = {
                allTours: form.allTours,
                totalPax: parseInt(form.totalPax),
                destinations: destinations,
                tours: tours,
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('manualHandlingEditPageRecordId')));

            return http.post(`manual-handling-settings`, result, params).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Tours settings updated`)),
                    SettingsStory.updateManualHandlingSearchPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.TOTAL_PAX_AMOUNT_EDIT_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('totalPaxAmountPageInitialized')),
        mergeMap(() => {
            return http.get(`manual-handling-settings/total-pax`).pipe(
                dispatchActions(
                    (rs: Response<{ totalPax: number }>) => {
                        return [
                            SettingsStory.updateTotalPaxAmountValue(rs.body.totalPax),
                            SettingsStory.updateTotalPaxAmountPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(OfferSendIntervalEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.TOTAL_PAX_AMOUNT_PAGE_SUBMIT),
        mergeMap(() => {
            return http.post(`manual-handling-settings/total-pax`, state.value.settingsModule.get('totalPaxAmountEditPageForm')).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Offer send interval settings updated`)),
                    SettingsStory.updateTotalPaxAmountPageInitState(false)
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.RETURNING_VAMOOS_EDIT_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('returningVamoosPageInitialized')),
        mergeMap(() => {
            return http.get(`vamoos`).pipe(
                dispatchActions(
                    (rs: Response<any>) => {

                        return [
                            SettingsStory.updateDaysAfterForVamoosFlightsSyncValue(rs.body.flightFrom),
                            SettingsStory.updateDaysTillForVamoosFlightsSyncValue(rs.body.flightTo),
                            SettingsStory.updateDaysAfterForVamoosDatesSyncValue(rs.body.dateFrom),
                            SettingsStory.updateDaysTillForVamoosDatesSyncValue(rs.body.dateTo),
                            SettingsStory.updateVamoosNotifyByMailValue(rs.body.notifyByMail),
                            SettingsStory.updateVamoosNotifyBySMSValue(rs.body.notifyBySMS),
                            SettingsStory.updateVamoosNotifyByAppValue(rs.body.notifyByApp),
                            SettingsStory.updateReturningVamoosPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(ReturningCustomersEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.RETURNING_CUSTOMERS_EDIT_PAGE_DRY_RUN),
        filter(() => !state.value.settingsModule.get('returningCustomersPageInitialized')),
        mergeMap(() => {
            return http.get(`returning-customers`).pipe(
                dispatchActions(
                    (rs: Response<{ returningCustomers: boolean }>) => {
                        return [
                            SettingsStory.updateReturningCustomersValue(rs.body.returningCustomers),
                            SettingsStory.updateReturningCustomersPageInitState(true),
                        ];
                    },
                    () => [
                        SystemStory.stopNavigate(ReturningCustomersEditStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['/'], { sharedLink: true })
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.RETURNING_CUSTOMERS_PAGE_SUBMIT),
        mergeMap(() => {
            return http.post(`returning-customers`, state.value.settingsModule.get('returningCustomersEditPageForm')).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Returning customers setting updated`))
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SettingsStory.RETURNING_VAMOOS_PAGE_SUBMIT),
        mergeMap(() => {

            const form = state.value.settingsModule.get('returningVamoosEditPageForm').toJS();
            const settings = {
                flightFrom: parseInt(form.flightFrom),
                flightTo: parseInt(form.flightTo),
                dateFrom: parseInt(form.dateFrom),
                dateTo: parseInt(form.dateTo),
                notifyByMail: form.vamoosNotifyByMail !== null ? form.vamoosNotifyByMail : false,
                notifyBySMS: form.vamoosNotifyBySMS !== null ? form.vamoosNotifyBySMS : false,
                notifyByApp: form.vamoosNotifyByApp !== null ? form.vamoosNotifyByApp : false,
            };

            return http.post(`vamoos`, settings).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Vamoos synchronization settings updated`))
                ])
            );
        }),
    ),
);
