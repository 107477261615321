import { isMap } from '@aclass/core/helpers/immutable';
import { Map } from 'immutable';

export interface IDateRange<T = string> {
    from: T | null;
    to:  T | null;
}

/**
 * Helper which can be used as "Type Guard" to check that given value is [[IDateRange]]
 */
export function isDateRange<T>(v): v is IDateRange<T> {
    return v && typeof v === 'object' && 'from' in v && 'to' in v;
}

/**
 * Immutable date manipulation
 */
export class Daytable {

    private readonly _date: Date;

    constructor(date?: Date | null) {
        this._date = date || new Date();
    }

    /**
     * Parses date string value and rise a model
     */
    static fromDateString(value: string | null): Daytable | null {
        if (typeof value !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            return null;
        }
        const [y, m, d] = value.split('-').map(Number);

        return new Daytable(new Date(y, m - 1, d, 0, 0, 0, 0));
    }

    /**
     * Parses date string value and rise a model
     */
    static fromDateTimeString(value: string | null): Daytable | null {
        if (typeof value !== 'string' || !/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/.test(value)) {
            return undefined;
        }
        const [date, time] = value.split(' ');
        const [y, m, d] = date.split('-').map(Number);
        const [h, i, s] = time.split(':').map(Number);

        return new Daytable(new Date(y, m - 1, d, h, i, s, 0));
    }

    /**
     * Parses date string value and rise a model
     */
    static fromDateRangeString(value: IDateRange | null): IDateRange<Daytable> | null {
        if (!isDateRange<string>(value)) {
            return null;
        }

        return {
            from: Daytable.fromDateString(value.from),
            to: Daytable.fromDateString(value.to)
        };
    }

    /**
     * Parses timestamp value and rise a model
     */
    static fromTimestamp(value: number | null): Daytable | null {
        if (!Number.isInteger(value)) {
            return undefined;
        }

        return new Daytable(new Date(value * 1000));
    }

    /**
     * Tries parse date and converts to backend date format
     */
    static prepareDateForBackend(source?: Date | null): string | null {
        if (!(source instanceof Date)) {
            return null;
        }

        return new Daytable(source).toDateString();
    }

    /**
     * Tries parse date and converts to backend date time format
     */
    static prepareDateTimeForBackend(source?: Date | null): string | null {
        if (!(source instanceof Date)) {
            return null;
        }

        return new Daytable(source).toDateTimeString();
    }

    static prepareDateRangeForBackend(source: IDateRange<Date> | Map<string, Date | null> | null): IDateRange {
        const [from, to] = Daytable.prepareRange(source);

        return {
            from: from ? from.toDateString() : null,
            to: to ? to.toDateString() : null
        };
    }

    static prepareDateTimeRangeForBackend(source: IDateRange<Date> | Map<string, Date | null> | null): IDateRange {
        const [from, to] = Daytable.prepareRange(source);

        return {
            from: from ? from.toDateTimeString() : null,
            to: to ? to.toDateTimeString() : null
        };
    }

    static isValidDate(y, m, d): boolean {
        y = parseInt(y);
        m = parseInt(m);
        d = parseInt(d);
        if (isNaN(y) || isNaN(m) || isNaN(d)) {
            return false;
        }
        m = m - 1;

        return m >= 0 && m < 12 && d > 0 && d <= Daytable.daysInMonth(y, m);
    }

    private static daysInMonth(y: number, m: number): number {
        switch (m) {
            case 1 :
                return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
            case 8 : case 3 : case 5 : case 10 :
                return 30;
            default :
                return 31;
        }
    }

    private static prepareRange(source: IDateRange<Date> | Map<string, Date | null> | null): [Daytable | null, Daytable | null] {
        if (!source || typeof source !== 'object') {
            return [null, null];
        }
        let from, to;
        if (isMap(source)) {
            if (!source.has('from') || !source.has('to')) {
                return [null, null];
            }
            from = source.get('from');
            to = source.get('to');
        } else if (('from' in source) && ('to' in source)) {
            from = source.from;
            to = source.to;
        }

        return [
            from instanceof Date ? new Daytable(from) : null,
            to instanceof Date ? new Daytable(to) : null
        ];
    }

    toDate(): Date {
        return new Date(+this._date);
    }

    /**
     * Converts value to date string representation
     */
    toDateString(): string {
        const [y, m, d] = this.toSpareParts();

        return `${y}-${m}-${d}`;
    }

    toTimespamt(): number {
        return Math.floor(+this._date / 1000);
    }

    /**
     * Converts value to date-time string representation
     */
    toDateTimeString(): string {
        const [y, m, d, h, i, s] = this.toSpareParts();

        return `${y}-${m}-${d} ${h}:${i}:${s}`;
    }

    toSpareParts(): Array<string> {
        const dt = this.toDate();

        // tslint:disable-next-line
        return [String(dt.getFullYear()), ('0' + (dt.getMonth() + 1)).slice(-2), ('0' + dt.getDate()).slice(-2), ('0' + dt.getHours()).slice(-2), ('0' + dt.getMinutes()).slice(-2), ('0' + dt.getSeconds()).slice(-2)];
    }

    addDays(days: number): Daytable {
        const date = this.toDate();
        if (days) {
            date.setDate(date.getDate() + days);
        }

        return new Daytable(date);
    }

    diff(dt: Daytable, type: 'days'): number {
        switch (type) {
            case 'days':
                return Math.round((this.toDate().getTime() - dt.toDate().getTime()) / (24 * 3600 * 1000));
        }

        return 0;
    }
}
