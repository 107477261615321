<ng-template #header>
    <form [attach]="ff.attach" [formGroup]="ff.form" (ngSubmit)="runSearch(false)" novalidate>
        <div class="clr-row">
            <div class="clr-col-2">
                <label>Source message</label>
                <input type="text" trim="blur" class="form-control form-control-sm" formControlName="message"/>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col text-right">
                <div class="btn-group btn-sm">
                    <button [clrLoading]="loading" type="button" class="btn btn-info-outline" (click)="runSearch(true)">
                        <clr-icon shape="repeat"></clr-icon>
                        Reset
                    </button>
                    <button [clrLoading]="loading" type="submit" class="btn btn-info-outline">
                        <clr-icon shape="search"></clr-icon>
                        Search
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<adm-content-layout [collapsible]="true" [header]="header" [(collapsed)]="collapsed" [showProgress]="showProgress">
    <adm-dg-layout [(pagination)]="pagination" [(order)]="order">
        <clr-datagrid class="mb-1" [clrDgLoading]="loading">
            <clr-dg-placeholder>We couldn't find any messages!</clr-dg-placeholder>
            <clr-dg-column [clrDgSortBy]="'message'">Message</clr-dg-column>
            <clr-dg-column>Description</clr-dg-column>
            <clr-dg-row *ngFor="let message of messages" class="u-cursor-pointer" [admDgLink]="[message.id]">
                <clr-dg-cell>
                    <adm-dg-link>{{ message.message }}</adm-dg-link>
                </clr-dg-cell>
                <clr-dg-cell>
                    <adm-dg-link>{{ message.description }}</adm-dg-link>
                </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                <adm-dg-pagination [hidden]="!pagination.totalCount"></adm-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </adm-dg-layout>
</adm-content-layout>