import { SourceMessage } from '@aclass/admin/storage/models/source.message';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITranslationMessageData {

    readonly id: string | null;

    readonly locale: string | null;

    readonly message: string | null;

    readonly sourceId: SourceMessage | number | null;
}

@Model({
    name: 'TranslationMessage'
})
export class TranslationMessage extends Entity<ITranslationMessageData> {

    @Attr() readonly id: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly message: string | null;

    @Fk({ to: 'SourceMessage', relatedName: 'relatedTranslatedMessages' }) readonly sourceId: SourceMessage | null;
}
