import { OrmManager } from '@aclass/admin/managers';
import { OverviewCreateStateResolver, OverviewEditStateResolver, OverviewViewStateResolver } from '@aclass/admin/resolvers';
import { OrmStory, OverviewStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IOverviewConditionData, IOverviewData, IOverviewDefinitionData, IOverviewDisplayData, IOverviewReportData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { tryToSaveFile } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Order } from '@aclass/core/rest/order';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { List } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type viewData = Response<Partial<{
    overview: IOverviewData,
    conditions: Array<IOverviewConditionData>,
    displays: Array<IOverviewDisplayData>,
    definitions: Array<IOverviewDefinitionData>,
}>>;

export const overviewModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(OverviewStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.overviewModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            OverviewStory.updateFormSearchPage({ }),
            OverviewStory.importRecordsOnSearchPage([]),
            OverviewStory.updatePaginationOnSearchPage(null),
            OverviewStory.updateOrderOnSearchPage(null),
            OverviewStory.updateSearchLockOnSearchPage(null),
            OverviewStory.updateCollapsedSearchPage(null),
            // Unlock page
            OverviewStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(OverviewStory.SEARCH_PAGE_COPY_OVERVIEW),
        switchMap(({ payload }: Action<IOverviewData['id']>) => {
            const redirect = SystemStory.navigate(['overviews', 'create'], { sharedLink: true });
            if (state.value.overviewModule.get('createPageRecordId') === payload) {
                return [redirect];
            }

            return [
                OverviewStory.updateFormOnCreatePage({ }),
                OverviewStory.updateOverviewIdCreatePage(payload),
                OverviewStory.updateCreatePageInitState(false),
                redirect
            ];
        }),
    ),
    action.pipe(
        ofType(OverviewStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.overviewModule;
            const rq: DataSearchRqData = {
                where: {
                    name: module.getIn(['searchPageForm', 'name'], null),
                    createdAt: module.getIn(['searchPageForm', 'createdAt'], null),
                    showDeactivated: module.getIn(['searchPageForm', 'showDeactivated'], null),
                    types: module.getIn(['searchPageForm', 'types'])
                },
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(OverviewStory.updateSearchLockOnSearchPage(true)), http.post('overviews/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IOverviewData>>>) => [
                    OrmStory.populateOverviews(rs.body.results),
                    OverviewStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    OverviewStory.updatePaginationOnSearchPage(rs.body.pagination),
                    OverviewStory.updateOrderOnSearchPage(rs.body.order),
                    OverviewStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(OverviewStory.CREATE_PAGE_DRY_RUN),
        filter(() => !state.value.overviewModule.get('createPageInitialized')),
        mergeMap(() => {
            const module = state.value.overviewModule;
            const id = module.get('createPageRecordId');
            if (!id) {
                return of(OverviewStory.updateCreatePageInitState(true));
            }

            return http.get(`overviews/${id}`).pipe(
                dispatchActions(
                    (rs: viewData) => [
                        OrmStory.populateOverviews([rs.body.overview]),
                        OrmStory.populateOverviewConditions(rs.body.conditions),
                        OrmStory.populateOverviewDisplays(rs.body.displays),
                        OrmStory.populateOverviewDefinitions(rs.body.definitions),
                        OverviewStory.updateOverviewDefinitionsCreatePage(rs.body.definitions.map(r => r.id)),
                        OverviewStory.updateCreatePageInitState(true)
                    ],
                    () => [
                        SystemStory.stopNavigate(OverviewCreateStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['overviews'], { sharedLink: true })
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(OverviewStory.CREATE_PAGE_DEFINITIONS_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                },
                order: new Order({ by: 'name', isReverse: false }),
                pagination: new Pagination().all()
            };

            return concat(of(OverviewStory.updateSearchDefinitionLockCreatePage(true)), http.post('overviews/search-definitions', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IOverviewDefinitionData>>>) => [
                    OverviewStory.updateSearchDefinitionLockCreatePage(false),
                    OrmStory.populateOverviewDefinitions(rs.body.results),
                    OverviewStory.updateOverviewDefinitionsCreatePage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OverviewStory.CREATE_PAGE_SUBMIT),
        switchMap(() => {
            const module = state.value.overviewModule;
            const form = module.get('createPageForm');
            const conditions = (<Array<Array<object>>>(<List<any>>form.get('conditions')).toJS()).map((records, group) => records.map(r => ({
                ...r,
                ...{ group }
            })));
            const rq = {
                name: form.get('name'),
                type: form.get('type'),
                description: form.get('description'),
                conditions: [].concat(...conditions),
                displays: form.get('displays')
            };

            return concat(of(OverviewStory.updateSaveLockCreatePage(true)), http.post('overviews', rq).pipe(
                dispatchActions(
                    (rs: viewData) => [
                        OverviewStory.updateSaveLockCreatePage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Overview "${rs.body.overview.name}" created`)),
                        RootStory.resetForm(),
                        OverviewStory.updateSearchPageInitState(false),
                        OverviewStory.updateOverviewIdCreatePage(null),
                        SystemStory.navigate(['overviews'], { sharedLink: true }),
                    ],
                    () => [
                        OverviewStory.updateSaveLockCreatePage(false),
                    ]
                )
            ));
        })
    ),
    action.pipe(
        ofType(OverviewStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IOverviewData['id']>) => state.value.overviewModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IOverviewData['id']>) => http.get(`overviews/${payload}`).pipe(
            dispatchActions(
                (rs: viewData) => [
                    OverviewStory.updateOverviewIdEditPage(payload),
                    OrmStory.populateOverviews([rs.body.overview]),
                    OrmStory.populateOverviewConditions(rs.body.conditions),
                    OrmStory.populateOverviewDisplays(rs.body.displays),
                    OrmStory.populateOverviewDefinitions(rs.body.definitions),
                    OverviewStory.updateFormOnEditPage({ }),
                    OverviewStory.updateOverviewDefinitionsEditPage(rs.body.definitions.map(r => r.id))
                ],
                () => [
                    SystemStory.stopNavigate(OverviewEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['overviews'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(OverviewStory.EDIT_PAGE_DEFINITIONS_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                },
                order: new Order({ by: 'name', isReverse: false }),
                pagination: new Pagination().all()
            };

            return concat(of(OverviewStory.updateSearchDefinitionLockEditPage(true)), http.post('overviews/search-definitions', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IOverviewDefinitionData>>>) => [
                    OverviewStory.updateSearchDefinitionLockEditPage(false),
                    OrmStory.populateOverviewDefinitions(rs.body.results),
                    OverviewStory.updateOverviewDefinitionsEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OverviewStory.EDIT_PAGE_TOGGLE_DELETED),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.overviewModule;
            const id = module.get('editPageRecordId');
            const eventName = payload ? 'removed' : 'restored';


            return concat(of(OverviewStory.updateSaveLockEditPage(true)), http.post(`overviews/toggle-deleted`, { id, deleted: payload }).pipe(
                dispatchActions((rs: viewData) => [
                    OverviewStory.updateSaveLockEditPage(false),
                    OrmStory.populateOverviews([rs.body.overview]),
                    OrmStory.reloadModel(OrmManager.RELOAD_OVERVIEW),
                    SystemStory.showNotification(SystemNotification.success('Success', `Overview ${eventName}`)),
                    SystemStory.navigate(['overviews'], { replaceUrl: true, sharedLink: true }),
                ])));
        })
    ),
    action.pipe(
        ofType(OverviewStory.EDIT_PAGE_SUBMIT),
        switchMap(() => {
            const module = state.value.overviewModule;
            const id = module.get('editPageRecordId');
            const form = module.get('editPageForm');
            const conditions = (<Array<Array<object>>>(<List<any>>form.get('conditions')).toJS()).map((records, group) => records.map(r => ({
                ...r,
                ...{ group }
            })));
            const rq = {
                name: form.get('name'),
                type: form.get('type'),
                description: form.get('description'),
                conditions: [].concat(...conditions),
                displays: form.get('displays')
            };

            return concat(of(OverviewStory.updateSaveLockEditPage(true)), http.put(`overviews/${id}`, rq).pipe(
                dispatchActions(
                    (rs: viewData) => [
                        OverviewStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Overview "${rs.body.overview.name}" updated`)),
                        // Reset prev state
                        OrmStory.dropOverviews([id]),
                        OverviewStory.updateOverviewIdViewPage(null),
                        // Load new state
                        OrmStory.populateOverviews([rs.body.overview]),
                        OrmStory.populateOverviewConditions(rs.body.conditions),
                        OrmStory.populateOverviewDisplays(rs.body.displays),
                        OrmStory.populateOverviewDefinitions(rs.body.definitions),
                    ],
                    () => [
                        OverviewStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        })
    ),
    action.pipe(
        ofType(OverviewStory.VIEW_PAGE_DRY_RUN),
        filter(({ payload }: Action<IOverviewData['id']>) => state.value.overviewModule.get('viewPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IOverviewData['id']>) => http.get(`overviews/${payload}`).pipe(
            dispatchActions(
                (rs: viewData) => [
                    // Reset
                    OverviewStory.updateFormOnViewPage({ }),
                    OverviewStory.importRecordsOnViewPage([]),
                    OverviewStory.updatePaginationOnViewPage(null),
                    OverviewStory.updateOrderOnViewPage(null),
                    OverviewStory.updateSearchLockOnViewPage(null),
                    OverviewStory.updateCollapsedViewPage(null),
                    // Unlock page
                    OverviewStory.updateOverviewIdViewPage(payload),
                    OrmStory.populateOverviews([rs.body.overview]),
                    OrmStory.populateOverviewConditions(rs.body.conditions),
                    OrmStory.populateOverviewDisplays(rs.body.displays),
                    OrmStory.populateOverviewDefinitions(rs.body.definitions),
                ],
                () => [
                    SystemStory.stopNavigate(OverviewViewStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['overviews'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(OverviewStory.VIEW_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.overviewModule;
            const rq: DataSearchRqData<{ overview: IOverviewData['id'], conditions?: any }> = {
                where: {
                    overview: module.get('viewPageRecordId'),
                    conditions: module.get('viewPageForm')
                },
                pagination: payload.pagination ? null : module.get('viewPagePagination'),
                order: payload.order ? null : module.get('viewPageOrder'),
            };

            return concat(of(OverviewStory.updateSearchLockOnViewPage(true)), http.post('overviews/report', rq).pipe(
                dispatchActions(
                    (rs: Response<IDataSearchRs<Array<IOverviewReportData>>>) => [
                        OverviewStory.updateLastSearchRqOnViewPage(rq),
                        OrmStory.populateOverviewReports(rs.body.results),
                        OverviewStory.importRecordsOnViewPage(rs.body.results.map(r => r.id)),
                        OverviewStory.updatePaginationOnViewPage(rs.body.pagination),
                        OverviewStory.updateOrderOnViewPage(rs.body.order),
                        OverviewStory.updateSearchLockOnViewPage(false),
                    ],
                    () => [
                        OverviewStory.updateSearchLockOnViewPage(false)
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(OverviewStory.VIEW_PAGE_DOWNLOAD),
        mergeMap(() => {
            const module = state.value.overviewModule;

            return concat(of(OverviewStory.updateSearchLockOnViewPage(true)), http.post('overviews/download', module.get('viewPageLastSearchRq'), { observe: 'response', responseType: 'text' }).pipe(
                mergeMap((rs: HttpResponse<string>) => {
                    tryToSaveFile(rs);

                    return of(OverviewStory.updateSearchLockOnViewPage(false));
                })
            ));
        }),
    ),
);
