<div class="btn-group btn-group--border b-switch m-0" [class.btn-sm]="sm" [hidden]="!controls.length">
    <div class="checkbox btn b-switch__elm"
         *ngFor="let c of controls"
         [class.btn-info-outline]="!c.selected"
         [class.b-btn-slim]="slim"
         [class.disabled]="(disabled || readonly)"
         [class.btn-primary]="c.selected"
         [attr.title]="c.title"
         (click)="runUpdate(c)">
        <clr-icon *ngIf="c.icon" [attr.shape]="c.icon"></clr-icon>
        <span [innerHtml]="c.title" [hidden]="c.icon && slim"></span>
    </div>
</div>
