import { ITWContractData, TWContract } from '@aclass/admin/storage/models/tw.contract';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export enum OptionType {
    SGL = 'SGL',
    DBL = 'DBL',
    TRPL = 'TRPL',
    TWIN = 'TWIN',
    QUAD = 'QUAD',
    DS = 'DS',
    SeatInCoach = 'SeatInCoach',
    Private = 'Private'
}

type Prices = {
    [key in OptionType]?: string;
};

export interface ITWOptionData {

    readonly id: number;

    readonly contractId: ITWContractData['id'];

    readonly name: string;

    readonly prices:  Prices | null;

    readonly deleted: boolean;

    readonly disabled: boolean;

    readonly warningEnd: boolean | null;

    readonly warningStart: boolean | null;

    readonly warningIn: boolean | null;

    readonly warning: boolean | null;

    readonly priceLock: boolean | false;

    readonly exceptionWarning: boolean | false;

    readonly exceptionText: string | null;
}

@Model({
    name: 'TWOption'
})
export class TWOption extends Entity<ITWOptionData> {

    @Attr() readonly id: number;

    @Attr() readonly name: string;

    @Attr() readonly deleted: boolean;

    @Attr() readonly disabled: boolean | false;

    @Attr() readonly prices: Prices | null;

    @Attr() readonly warningEnd: boolean | null;

    @Attr() readonly warningStart: boolean | null;

    @Attr() readonly warningIn: boolean | null;

    @Attr() readonly warning: boolean | null;

    @Attr() readonly priceLock: boolean | false;

    @Attr() readonly exceptionWarning: boolean | false;

    @Attr() readonly exceptionText: string | null;

    @Fk({ to: 'TWContract', relatedName: 'relatedOptions' }) readonly contractId: TWContract | null;
}
