import { AfterViewInit, ContentChildren, Directive, ElementRef, HostBinding, Input, QueryList } from '@angular/core';
import { ClrDatagridCell } from '@clr/angular';
import { DgLinkComponent } from '../../ui/app/shared/dg/link/dg-link.component';

@Directive({
    selector: '[admDgLink]'
})
export class DataGridLinkDirective implements AfterViewInit {

    @HostBinding('class.b-dg-row') inlineBlock = true;

    @ContentChildren(DgLinkComponent, { descendants: true }) records: QueryList<DgLinkComponent>;

    @ContentChildren(ClrDatagridCell, { read: ElementRef }) cells: QueryList<ElementRef>;

    @Input('admDgLink') link: Array<string | number> | string | number;

    @Input('admDgLinkParams') params: { [key: string]: any } | null = null;

    @Input('admDgLinkTarget') target: '_blank' | '_self' | '_parent' | '_top' | null = null;

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        if (!this.records) {
            return;
        }
        this.records.forEach(r => {
            r.link = this.link;
            r.params = this.params;
            r.target = this.target;
            r.applyChanges();
        });
        if (!this.cells) {
            return;
        }
        this.cells.forEach(r => {
            r.nativeElement.classList.add('b-dg-cell');
            const link = r.nativeElement.querySelector('adm-dg-link');
            if (!link) {
                return;
            }
            r.nativeElement.classList.add('has-link');
        });
    }
}
