<ng-template #header>
    <form [attach]="ff.attach" [formGroup]="ff.form" (ngSubmit)="runSearch()" novalidate>
        <div class="clr-row">
            <div class="clr-col-2">
                <label>Role name</label>
                <input type="text" trim="blur" class="form-control form-control-sm" formControlName="name"/>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col text-right">
                <div class="btn-group btn-sm">
                    <button type="button" class="btn btn-info-outline" (click)="runSearch(true)">
                        <clr-icon shape="repeat"></clr-icon>
                        Reset
                    </button>
                    <button type="submit" class="btn btn-info-outline">
                        <clr-icon shape="search"></clr-icon>
                        Search
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<adm-content-layout [collapsible]="true" [header]="header" [(collapsed)]="collapsed" [showProgress]="showProgress">
    <clr-datagrid>
        <clr-dg-placeholder>We couldn't find any roles!</clr-dg-placeholder>
        <clr-dg-action-bar>
            <div class="btn-group">
                <a class="btn btn-sm btn-secondary" [routerLink]="['create']">
                    <clr-icon shape="plus"></clr-icon> Add new
                </a>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column>Name</clr-dg-column>
        <clr-dg-column>Permissions</clr-dg-column>
        <clr-dg-row *ngFor="let r of records" [admDgLink]="['edit', r.name]">
            <clr-dg-cell>
                <adm-dg-link>{{ r.name }}</adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link><span [innerHtml]="formatPermissions(extractPermissions(r))"></span></adm-dg-link>
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <span>
                {{ records | length | async }} roles
            </span>
        </clr-dg-footer>
    </clr-datagrid>
</adm-content-layout>