import { ItineraryDayValue } from '@aclass/admin/components/package.itinerary';
import {
    OrmStory,
    PackageItineraryStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    ITWContractData,
    ITWOptionData,
    ITWProductData,
} from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const packageItineraryModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(PackageItineraryStory.DAY_PRODUCT_SEARCH),
        mergeMap(({ payload }: Required<Action<{ uid: string, name?: string } & Pick<ItineraryDayValue, 'productType'>>>) => {
            const { uid, ...params } = payload;
            const rq: DataSearchRqData = {
                where: {
                    ...params,
                    name: params.name || null,
                    deleted: false,
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(PackageItineraryStory.dayUpdateSearchProductLock(uid, true)), http.post('tw/headless-product-search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<Partial<ITWProductData>>>>) => [
                    PackageItineraryStory.dayUpdateSearchProductLock(uid, false),
                    OrmStory.populateTwProducts(rs.body.results),
                    PackageItineraryStory.dayUpdateProducts(uid, rs.body.results.map(r => r.id)),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(PackageItineraryStory.DAY_OPTION_SEARCH),
        mergeMap(({ payload }: Required<Action<{ uid: string, name?: string } & Pick<ItineraryDayValue, 'productType' | 'productId'>>>) => {
            const { uid, ...params } = payload;
            const rq: DataSearchRqData = {
                where: {
                    ...params,
                    name: params.name || null,
                    deleted: false,
                    active: true,
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(PackageItineraryStory.dayUpdateSearchOptionLock(uid, true)), http.post('tw/search-options-with-contracts', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<Partial<ITWOptionData & { contractName: ITWContractData['name'], productId: ITWContractData['productId'] }>>>>) => [
                    PackageItineraryStory.dayUpdateSearchOptionLock(uid, false),
                    OrmStory.populateTwContracts(rs.body.results.map(({ contractId, contractName, productId }) => ({ id: contractId, name: contractName, productId }))),
                    OrmStory.populateTwOptions(rs.body.results.map(({ id, name, contractId, warningEnd, warningStart, warningIn }) => ({ id, name, contractId, warningEnd, warningStart, warningIn }))),
                    PackageItineraryStory.dayUpdateOptions(uid, rs.body.results.map(r => r.id)),
                ])
            ));
        }),
    ),
);
