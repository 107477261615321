import { RouterManager } from '@aclass/admin/managers';
import { SignatureStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISignatureModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class SignatureUsersStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:signature-users';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISignatureModuleState['searchUsersPageInitialized']>(['signatureModule', 'searchUsersPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(SignatureUsersStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(SignatureStory.dryRunSearchUserPage());
    }
}
