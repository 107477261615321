import { WindowRef } from '@aclass/core/base/window.ref';
import { Injectable } from '@angular/core';

/**
 * Define class that implements the abstract class and returns the native window object.
 */
@Injectable()
export class BrowserWindowRef extends WindowRef {

    get nativeWindow(): Window | Object {
        return window;
    }

}
