<form [attach]="ff.attach" [formGroup]="ff.form" novalidate (ngSubmit)="runSave()">
    <ng-template #header>
        <adm-back-button></adm-back-button>
        <button type="submit" class="btn btn-info-outline btn-sm">
            <clr-icon shape="check"></clr-icon>
            Save
        </button>
    </ng-template>
    <adm-content-layout [header]="header">
        <div class="clr-row mb-1">
            <div class="clr-col-2">
                Name:
            </div>
            <div class="clr-col">
                <input type="text" class="form-control" formControlName="name" style="max-width: 450px"/>
            </div>
        </div>
        <div *ngFor="let perm of rolePermissions" class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-3 clr-col-md-12">
                <adm-checkbox-button [(value)]="perm.value" (click)="runUpdatePermissions()"></adm-checkbox-button>
            </div>
            <div class="clr-col">
                {{ perm.description }}
            </div>
        </div>
    </adm-content-layout>
</form>