import { ExceptionStory } from '@aclass/admin/storage/actions';
import {
    IExceptionModuleState,
    INITIAL_EXCEPTION_STATE
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const exceptionModuleReducer = (state: TypedState<IExceptionModuleState> = INITIAL_EXCEPTION_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case ExceptionStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case ExceptionStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case ExceptionStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case ExceptionStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case ExceptionStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case ExceptionStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case ExceptionStory.IMPORT_HOTELS_RECORDS:
            return state.update('createUpdateProducts', () => List(payload));
        case ExceptionStory.PRODUCT_UPDATE_OPTIONS:
            return state.update('productOptions', () => List(payload));
        case ExceptionStory.PRODUCT_ID_UPDATE_OPTIONS_IDS:
            return state.updateIn(['productOptionsIdsByProductId', payload.uid], () => List(payload.ids));
        case ExceptionStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case ExceptionStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        // case ExceptionStory.EDIT_PAGE_CREATE_FORM:
        //     return state.update('createPageForm', () => fromJS(payload));
        case ExceptionStory.EDIT_PAGE_UPDATE_ID:
            return state.update('editPageRecordId', () => payload);
        case ExceptionStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        default:
            return state;
    }
};
