import { RouterManager } from '@aclass/admin/managers';
import { QuoteStory } from '@aclass/admin/storage/actions';
import { IAdminState, IQuoteModuleState } from '@aclass/admin/storage/states';
import { IQuoteBuildParams } from '@aclass/admin/utils';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class QuoteEditStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:quote-edit';

    private id: IQuoteModuleState['editPageResolverId'];

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<IQuoteModuleState['editPageResolverId']>(['quoteModule', 'editPageResolverId']).pipe(filter(v => v === this.id));
        this.employee = this.routerManager.onRejectNavigation(QuoteEditStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(route: ActivatedRouteSnapshot): void {
        const id = Number(route.params.id);
        this.id = id;
        this.ngRdx.dispatch(QuoteStory.dryRunEditPage({ id, ...(<IQuoteBuildParams>route.queryParams) }));
    }
}
