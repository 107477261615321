import { Customer } from '@aclass/admin/storage/models/customer';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { IEmailMessageLogData } from '@aclass/core/storage/models/email.message.log';
import { Model as Entity } from 'redux-orm';

export type ICustomerEmailMessageLogData = IEmailMessageLogData & {

    readonly customerId: Customer | number | null;

};

@Model({
    name: 'CustomerEmailMessageLog'
})
export class CustomerEmailMessageLog extends Entity<ICustomerEmailMessageLogData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly owner: string | null;

    @Attr() readonly body: string | null;

    @Attr() readonly subject: string | null;

    @Attr() readonly viewed: number | null;

    @Attr() readonly isSent: boolean | null;

    @Attr() readonly deleted: boolean | null;

    @Fk({ to: 'Customer', relatedName: 'relatedEmailLogs' }) readonly customerId: Customer | null;
}
