import { AclStory } from '@aclass/admin/storage/actions';
import { IAclModuleState, INITIAL_ACL_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const aclModuleReducer = (state: TypedState<IAclModuleState> = INITIAL_ACL_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case AclStory.UPDATE_INIT_STATE:
            return state.update('initialized', () => payload);
        case AclStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case AclStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case AclStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case AclStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => fromJS(payload));
        case AclStory.CREATE_PAGE_UPDATE_ROLE_PERMISSIONS:
            return state.update('createPageRolePermissions', () => fromJS(payload));
        case AclStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_ROLE_NAME:
            return state.update('editPageRoleName', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case AclStory.EDIT_PAGE_UPDATE_SHOULD_LOGOUT:
            return state.update('editPageShouldLogout', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_ROLE_NAMES:
            return state.update('editPageRoleNames', () => List(payload));
        case AclStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL:
            return state.update('showRemoveModal', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK:
            return state.update('removeModalLock', () => payload);
        case AclStory.EDIT_PAGE_UPDATE_ROLE_PERMISSIONS:
            return state.update('editPageRolePermissions', () => fromJS(payload));
        default:
            return state;
    }
};
