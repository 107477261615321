import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { PCTour } from '@aclass/admin/storage/models/pc.tour';
import { QuotePreview } from '@aclass/admin/storage/models/quote.preview';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IPCTourPackageData {
    readonly id: string;

    readonly code: string;

    readonly name: string;
}

@Model({
    name: 'PCTourPackage'
})
export class PCTourPackage extends Entity<IPCTourPackageData> {

    @Attr() readonly id: string;

    @Attr() readonly code: string;

    @Attr() readonly name: string;

    @Attr() readonly deleted: boolean;

    readonly relatedTours: Array<PCTour>;

    readonly relatedOpportunities: QuerySet<Opportunity>;

    readonly relatedQuotePreviews: QuerySet<QuotePreview>;
}
