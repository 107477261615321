import { Itinerary } from '@aclass/admin/storage/models/itinerary';
import { IPCTourPackageData, PCTourPackage } from '@aclass/admin/storage/models/pc.tour.package';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IPCTourData {
    readonly id: string;

    readonly sid: number;

    readonly instanceId: string;

    readonly packageId: IPCTourPackageData['id'];

    readonly forChildren: boolean;

    readonly createdAt: number;

    readonly updatedAt: number;

    readonly deleted: boolean;
}

@Model({
    name: 'PCTour'
})
export class PCTour extends Entity<IPCTourData> {

    @Attr() readonly id: string;

    @Attr() readonly sid: number;

    @Attr() readonly instanceId: string;

    @Attr() readonly forChildren: boolean;

    @Attr() readonly createdAt: number;

    @Attr() readonly updatedAt: number;

    @Attr() readonly deleted: boolean;

    @Fk({ to: 'PCTourPackage', relatedName: 'relatedPackage' }) readonly packageId: PCTourPackage;

    readonly relatedItineraries: Array<Itinerary>;

    readonly relatedTourRevisions: QuerySet<TourRevision>;
}
