import { ISignatureModalResultData } from '@aclass/admin/components';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { IUserData } from '@aclass/core/storage/models/user';

@Story({
    name: 'signature'
})
export class SignatureStory {

    @Task() static SEARCH_USER_PAGE_UPDATE_INITIALIZED: string;

    @Task() static SEARCH_USER_PAGE_DRY_RUN: string;

    @Task() static SEARCH_USER_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_USER_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_USER_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_USER_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_USER_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_USER_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_USER_PAGE_SUBMIT: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_INITIALIZED: string;

    @Task() static SEARCH_SIGNATURE_PAGE_DRY_RUN: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_SIGNATURE_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_SIGNATURE_PAGE_SUBMIT: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_USER_ID: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_BRAND_AND_LOCALE: string;

    @Task() static CREATE_SIGNATURE_PAGE_DRY_RUN: string;

    @Task() static CREATE_SIGNATURE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_SIGNATURE_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_SIGNATURE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL: string;

    @Task() static EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE_SIGNATURE: string;

    @Task() static SHOW_COPY_MODAL: string;

    @Task() static SEARCH_SIGNATURE_PAGE_IMPORT_CONTENT_FOR_COPY_MODAL: string;

    @Task() static SEARCH_SIGNATURE_PAGE_UPDATE_COPY_MODAL_RESULT_DATA: string;

    @Task() static COPY_SIGNATURE_FROM_TO: string;

    @Task() static UPDATE_CREATE_PAGE_ID: string;

    static dryRunSearchUserPage(): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_DRY_RUN };
    }

    static updateSearchUserPageInitState(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_INITIALIZED, payload: v };
    }

    static updateCollapsedUserSearchPage(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormUserSearchPage(data: any): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnUserSearchPage(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updatePaginationOnUserSearchPage(pagination: IPaginationData | null): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnUserSearchPage(order: IOrderData | null): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_UPDATE_ORDER, payload: order };
    }

    static importRecordsOnUserSearchPage(ids: Array<number>): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnUserSearchPage(): Action {
        return { type: SignatureStory.SEARCH_USER_PAGE_SUBMIT };
    }
    static dryRunSearchPage(id: number): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_DRY_RUN, payload: id };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_INITIALIZED, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_ORDER, payload: order };
    }

    static importRecordsOnSearchPage(ids: Array<string>): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_SUBMIT, payload };
    }

    static updateUserIdSearchPage(id: IUserData['id']): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_USER_ID, payload: id };
    }

    static updateBrandAndLocale(brandAndLocale: object): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_BRAND_AND_LOCALE, payload: brandAndLocale };
    }

    static submitOnCreatePage(): Action {
        return { type: SignatureStory.CREATE_SIGNATURE_PAGE_SUBMIT };
    }

    static updateFormOnCreatePage(data: any): Action {
        return { type: SignatureStory.CREATE_PAGE_UPDATE_FORM, payload: data };
    }

    static dryRunCreatePage(id: string): Action {
        return { type: SignatureStory.CREATE_SIGNATURE_PAGE_DRY_RUN, payload: id };
    }

    static dryRunEditPage(id: string): Action {
        return { type: SignatureStory.EDIT_SIGNATURE_PAGE_DRY_RUN, payload: id };
    }

    static updateSignatureIdEditPage(id: string): Action {
        return { type: SignatureStory.EDIT_PAGE_UPDATE_SIGNATURE_ID, payload: id };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: SignatureStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static submitOnEditPage(): Action {
        return { type: SignatureStory.EDIT_PAGE_SUBMIT };
    }

    static showCopyModal(v: boolean | null): Action {
        return { type: SignatureStory.SHOW_COPY_MODAL, payload: v };
    }

    static loadContentForCopyModal(data: any): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_IMPORT_CONTENT_FOR_COPY_MODAL, payload: data };
    }

    static copySignatureFromTo(modalForm: any): Action {
        return { type: SignatureStory.COPY_SIGNATURE_FROM_TO, payload: modalForm };
    }

    static updateCopyModalResultData(data: ISignatureModalResultData): Action {
        return { type: SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_COPY_MODAL_RESULT_DATA, payload: data };
    }

    static updateIdCreatePage(id: IUserData['id']): Action {
        return { type: SignatureStory.UPDATE_CREATE_PAGE_ID, payload: id };
    }

    static updateShowRemoveModalOnEditPage(v: boolean): Action {
        return { type: SignatureStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL, payload: v };
    }

    static updateRemoveModalLockOnEditPage(v: boolean): Action {
        return { type: SignatureStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK, payload: v };
    }

    static removeSignatureOnEditPage(): Action {
        return { type: SignatureStory.EDIT_PAGE_REMOVE_SIGNATURE };
    }
}
