import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOverviewReportData {

    readonly id: number | null;

    readonly data: { [key: number]: any };
}

@Model({
    name: 'OverviewReport'
})
export class OverviewReport extends Entity<IOverviewReportData> {

    @Attr() readonly id: number | null;

    @Attr() readonly data: { [key: number]: any };
}
