import { DomainDetail } from '@aclass/admin/storage/models/domain.detail';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Role } from '@aclass/admin/storage/models/role';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { IUserData } from '@aclass/core/storage/models/user';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'User'
})
export class User extends Entity<IUserData> {

    @Attr() readonly id: number;

    @Attr() readonly username: string;

    @Attr() readonly email: string;

    @Attr() readonly createdAt: number;

    @Attr() readonly updatedAt: number;

    @Attr() readonly deleted: boolean;

    @Fk({ to: 'Role', relatedName: 'userRecords' }) readonly role: Role;

    readonly relatedOpportunities: QuerySet<Opportunity>;

    readonly relatedDomainDetail: QuerySet<DomainDetail>;

    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<IUserData, 'id' | 'username'> => ({ id: this.id, username: this.username });
}
