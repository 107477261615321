import { PackageItineraryStory } from '@aclass/admin/storage/actions';
import { INITIAL_PACKAGE_ITINERARY_STATE, IPackageItineraryModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { List } from 'immutable';

export const packageItineraryModuleReducer = (state: TypedState<IPackageItineraryModuleState> = INITIAL_PACKAGE_ITINERARY_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case PackageItineraryStory.DAY_REGISTER:
            return state
                .updateIn(['dayProductSearchLock'], st => st.set(payload, null))
                .updateIn(['dayProducts'], st => st.set(payload, List([])))
                .updateIn(['dayOptionSearchLock'], st => st.set(payload, null))
                .updateIn(['dayOptions'], st => st.set(payload, List([])));
        case PackageItineraryStory.DAY_DEREGISTER:
            return state
                .updateIn(['dayProductSearchLock'], st => st.delete(payload))
                .updateIn(['dayProducts'], st => st.delete(payload))
                .updateIn(['dayOptionSearchLock'], st => st.delete(payload))
                .updateIn(['dayOptions'], st => st.delete(payload));
        case PackageItineraryStory.DAY_PRODUCT_SEARCH_LOCK:
            return state.updateIn(['dayProductSearchLock', payload.uid], () => payload.lock);
        case PackageItineraryStory.DAY_OPTION_SEARCH_LOCK:
            return state.updateIn(['dayOptionSearchLock', payload.uid], () => payload.lock);
        case PackageItineraryStory.DAY_UPDATE_PRODUCTS:
            return state.updateIn(['dayProducts', payload.uid], () => List(payload.ids));
        case PackageItineraryStory.DAY_UPDATE_OPTIONS:
            return state.updateIn(['dayOptions', payload.uid], () => List(payload.ids));
        default:
            return state;
    }
};
