import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOperatorDefinitionData {

    readonly id: number;

    readonly name: string;
}

export interface IValueDefinitionData {

    readonly id: string | number;

    readonly name: string | number;
}

export interface IOverviewDefinitionData {

    readonly id: number | null;

    readonly type: number | null;

    readonly name: string | null;

    readonly operators: Array<number>;

    readonly values: Array<string | number> | { [key: string]: string | number };
}

@Model({
    name: 'OverviewDefinition'
})
export class OverviewDefinition extends Entity<IOverviewDefinitionData> {

    /**
     * Compare operator's codes
     */

    static OPERATOR_EQUALS = 1;

    static OPERATOR_NOT_EQUALS = 2;

    static OPERATOR_LESS = 3;

    static OPERATOR_LESS_OR_EQUALS = 4;

    static OPERATOR_GREATER = 5;

    static OPERATOR_GREATER_OR_EQUALS = 6;

    static OPERATOR_CONTAINS = 7;

    static OPERATOR_NOT_CONTAINS = 8;

    static OPERATOR_BEGINS = 9;

    static OPERATOR_ENDS = 10;

    static OPERATOR_DATE_CURRENT = 11;

    static OPERATOR_DATE_WEEK = 12;

    static OPERATOR_DATE_MONTH = 13;

    static OPERATOR_DATE_YEAR = 14;

    static OPERATOR_DATE_PERIOD = 15;

    static OPERATOR_NAMES = {
        [OverviewDefinition.OPERATOR_EQUALS]: 'Equals',
        [OverviewDefinition.OPERATOR_NOT_EQUALS]: 'Not equals',
        [OverviewDefinition.OPERATOR_LESS]: 'Less',
        [OverviewDefinition.OPERATOR_LESS_OR_EQUALS]: 'Less or equals',
        [OverviewDefinition.OPERATOR_GREATER]: 'Greater',
        [OverviewDefinition.OPERATOR_GREATER_OR_EQUALS]: 'Greater or equals',
        [OverviewDefinition.OPERATOR_CONTAINS]: 'Contains',
        [OverviewDefinition.OPERATOR_NOT_CONTAINS]: 'Not contains',
        [OverviewDefinition.OPERATOR_BEGINS]: 'Begins with',
        [OverviewDefinition.OPERATOR_ENDS]: 'Ends with',
        [OverviewDefinition.OPERATOR_DATE_CURRENT]: 'Current date',
        [OverviewDefinition.OPERATOR_DATE_WEEK]: 'Last week',
        [OverviewDefinition.OPERATOR_DATE_MONTH]: 'Last month',
        [OverviewDefinition.OPERATOR_DATE_YEAR]: 'Last year',
        [OverviewDefinition.OPERATOR_DATE_PERIOD]: '± days',
    };

    /**
     * Data types
     */

    static TYPE_STRING = 1;

    static TYPE_NUMBER = 2;

    static TYPE_SWITCH = 3;

    static TYPE_DATE = 4;

    static TYPE_DATE_TIME = 5;

    static TYPE_TIMESTAMP = 6;

    @Attr() readonly id: number | null;

    @Attr() readonly type: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly operators: Array<number>;

    @Attr() readonly values: Array<string | number> | { [key: string]: string | number };

    /**
     * Returns type of control
     */
    static inputType(r?: OverviewDefinition, operator?: number): 'switch' | 'native' | 'date' | 'datetime' | undefined {
        // Tick value without operator not allowed
        if (!r || !operator) {
            return undefined;
        }
        if (r.isSwitch()) {
            return 'switch';
        }
        if (r.isDaytable()) {
            if (operator === OverviewDefinition.OPERATOR_DATE_PERIOD) {
                return 'native';
            }
            const specials = [
                OverviewDefinition.OPERATOR_DATE_CURRENT,
                OverviewDefinition.OPERATOR_DATE_WEEK,
                OverviewDefinition.OPERATOR_DATE_MONTH,
                OverviewDefinition.OPERATOR_DATE_YEAR
            ];
            // Special compare, value ignored, disable control
            if (specials.includes(operator)) {
                return undefined;
            }
            if (r.isDate()) {
                return 'date';
            }

            return 'datetime';
        }

        return 'native';
    }

    /**
     * Convert model to paste it to ui selects
     */
    composerOperatorsData = (): Array<IOperatorDefinitionData> => this.operators.map(id => ({ id, name: OverviewDefinition.OPERATOR_NAMES[id] || 'Not defined' }));

    /**
     * Convert model to paste it to ui selects
     */
    composerValueData(): Array<IValueDefinitionData> {
        if (Array.isArray(this.values)) {
            return this.values.map(r => ({ id: r, name: r }));
        }

        return Object.keys(typeof this.values === 'object' ? this.values : { }).map(name => ({ id: this.values[name], name }));
    }

    /**
     * Checks that given type is string
     */
    isString(): boolean {
        return this.type === OverviewDefinition.TYPE_STRING;
    }

    /**
     * Checks that given type is number
     */
    isNumber(): boolean {
        return this.type === OverviewDefinition.TYPE_NUMBER;
    }

    /**
     * Checks that given type is switch. Please note that this option includes boolean
     */
    isSwitch(): boolean {
        return this.type === OverviewDefinition.TYPE_SWITCH;
    }

    /**
     * Checks that given type is date
     */
    isDate(): boolean {
        return this.type === OverviewDefinition.TYPE_DATE;
    }

    /**
     * Checks that given type is date time
     */
    isDateTime(): boolean {
        return this.type === OverviewDefinition.TYPE_DATE_TIME;
    }

    /**
     * Checks that given type is timestamp
     */
    isTimestamp(): boolean {
        return this.type === OverviewDefinition.TYPE_TIMESTAMP;
    }

    /**
     * Checks that given type is date, data time or timestamp
     */
    isDaytable(): boolean {
        return [OverviewDefinition.TYPE_DATE, OverviewDefinition.TYPE_DATE_TIME, OverviewDefinition.TYPE_TIMESTAMP].includes(this.type);
    }

    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<IOverviewDefinitionData, 'id' | 'name'> => ({ id: this.id, name: this.name });
}
