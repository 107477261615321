import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ICmsDestinationData {

    readonly id: string | null;

    readonly name: string | null;

    readonly link: string | null;
}

@Model({
    name: 'CmsDestination'
})
export class CmsDestination extends Entity<ICmsDestinationData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly link: string | null;

    readonly relatedTours: QuerySet<TourRevision>;
}

