import { SystemStory } from '@aclass/admin/storage/actions';
import { INITIAL_SYSTEM_STATE, ISystemModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS } from 'immutable';

export const systemModuleReducer = (state: TypedState<ISystemModuleState> = INITIAL_SYSTEM_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case SystemStory.UPDATE_INIT_STATE:
            return state.update('initialized', () => payload);
        case SystemStory.SWITCH_BASE_INTERCEPTOR:
            return state.update('baseInterceptorEnabled', () => payload);
        case SystemStory.SWITCH_SMART_INTERCEPTOR:
            return state.update('smartInterceptorEnabled', () => payload);
        case SystemStory.SHOW_ALERT:
            return state.update('alerts', records => records.push(fromJS(payload)));
        case SystemStory.REMOVE_ALERT:
            return state.update('alerts', records => records.delete(records.findIndex(r => r.get('id') === payload)));
        case SystemStory.SHOW_NOTIFICATION:
            return state.update('notifications', records => records.push(fromJS(payload)));
        case SystemStory.REMOVE_NOTIFICATION:
            return state.update('notifications', records => records.delete(records.findIndex(r => r.get('id') === payload)));
        case SystemStory.UPDATE_REDIRECT:
            return state.update('redirect', () => payload);
        case SystemStory.UPDATE_ROUTE:
            return state.update('route', () => payload);
        case SystemStory.UPDATE_IDENTITY:
            return state.update('identity', () => fromJS(payload));
        case SystemStory.UPDATE_VERSION:
            return state.update('version', () => payload);
        case SystemStory.UPDATE_NEXT_PAGE_LOCK:
            return state.update('nextPageLock', () => payload);
        case SystemStory.UPDATE_APPLICATION:
            return state.update('application', () => payload);
        case SystemStory.UPDATE_CONFIGURATION:
            return state.update('config', r => r.merge(fromJS(payload)));
        default:
            return state;
    }
};
