import { Daytable } from '@aclass/core/base/daytable';
import { forwardRef, AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidator, ControlValueAccessor, FormControl, FormGroup, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { IMyDateModel, IMyDpOptions, MyDatePicker } from 'mydatepicker';
import { of, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'adm-date-time-picker',
    template: `
        <form [formGroup]="form" class="b-date-picker-form" novalidate>
            <my-date-picker class="b-date-picker-form__control" formControlName="date" [options]="options" #dp></my-date-picker>
            <div class="b-date-picker-time">
                <input type="text" class="form-control b-date-picker-time__input" [class.form-control-sm]="sm" [class.disabled]="disabled" formControlName="time" (click)="toggleRanges()" placeholder="00:00">
                <div class="b-date-picker-timeline" [hidden]="!rangesShowed">
                    <div class="b-date-picker-timeline-panel">
                        <div class="b-date-picker-timeline-panel__el" *ngFor="let r of ranges" (click)="updateTime(r)">{{ r }}</div>
                    </div>
                </div>
            </div>
        </form>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true,
        }
    ],
})
export class DateTimePickerComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor, AsyncValidator {

    @HostBinding('class.b-date-picker') yes = true;

    @ViewChild('dp', { static: true }) dp: MyDatePicker;

    /**
     * Watched value
     */
    @Input() set value(v: string | null) {
        if (this._v === v) {
            return;
        }
        this._v = v;
        this.form.patchValue({
            date: this.toPickerDate(v),
            time: this.toPickerTime(v),
        }, { emitEvent: false });
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<string | null> = new EventEmitter<string | null>();

    /**
     * Open event emitter
     */
    @Output() open: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Close event emitter
     */
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Show small version
     */
    @HostBinding('class.b-date-picker-sm') @Input() sm = true;

    @Input() settings: Partial<IMyDpOptions & { minDate: Date | 'now' }> = { };

    ranges: Array<string> = [];

    form: FormGroup;

    options: IMyDpOptions;

    rangesShowed = false;

    private _v: string | null;

    private _disabled = false;

    private subscriptions: Array<Subscription> = [];

    @HostBinding('class.disabled') get disabled(): boolean {
        return this._disabled;
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        let el = $event.target;
        let found = false;
        while (el) {
            if (el.classList && el.classList.contains('b-date-picker-time')) {
                found = true;
                break;
            }
            el = el.parentNode;
        }
        if (found) {
            return;
        }
        this.toggleRanges(false);
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        const options = {
            dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' },
            dateFormat: 'dd.mm.yyyy',
            openSelectorOnInputClick: true,
            editableDateField: true,
            showTodayBtn: false,
            minYear: 1900,
            maxYear: 2050,
            sunHighlight: false,
            width: 'auto',
            height: 'auto',
            selectionTxtFontSize: 'auto',
            ...this.settings
        };
        const { minDate } = options;
        if (minDate) {
            delete options.minDate;
            const date = new Daytable(minDate === 'now' ? new Date() : minDate).addDays(-1).toDate();
            options['disableUntil'] = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        }
        this.options = options;
        this.ranges = [].concat(...this.getTimeline(24).map(h => this.getTimeline(60, 30).map(i => `${h}:${i}`)));
        this.form = new FormGroup({
            date: new FormControl({ value: this.toPickerDate(this._v), disabled: this._disabled }),
            time: new FormControl({ value: this.toPickerTime(this._v), disabled: this._disabled }),
        });
        this.subscriptions.push(
            this.form.valueChanges.subscribe((v: { date: IMyDateModel | null, time: string | null }) => {
                if (this._disabled) {
                    return;
                }
                const value = this.fromPickerDate(v);
                this._v = value;
                this.onChangeCallback(value);
                this.valueChange.emit(value);
                this.onTouchedCallback();
            }),
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        this.subscriptions.push(
            this.dp.calendarToggle.subscribe((v: number) => {
                /**
                 * @see https://github.com/kekeh/mydatepicker#calendartoggle-callback
                 */
                if (v === 1) {
                    this.open.emit();

                    return;
                }
                this.close.emit();
            })
        );
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: string | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
        if (!this.form) {
            return;
        }
        const control = this.form.controls.v;
        if (isDisabled) {
            control.disable();

            return;
        }
        control.enable();
    }

    /**
     * @inheritDoc
     */
    writeValue(v: string | null) {
        this.value = v;
    }

    /**
     * @inheritDoc
     */
    onTouchedCallback: () => void = () => { };

    /**
     * @inheritDoc
     */
    onChangeCallback: (v: string | null) => void = () => { };

    /**
     * @inheritDoc
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const error: ValidationErrors | null = this.dp.invalidDate && !this._v && !this._disabled ? { date: 'Date time is not valid' } : null;

        return of(error);
    }

    toggleRanges(v: boolean = true) {
        this.rangesShowed = v;
        if (v) {
            this.open.emit();

            return;
        }
        this.close.emit();
    }

    updateTime(v: string) {
        this.form.controls.time.setValue(v);
        this.toggleRanges(false);
    }

    /**
     * Returns timeline
     */
    private getTimeline(time: number, step: number = 1): Array<string> {
        return Array.from({ length: Math.ceil(time / step) }, (_, i) => `0${ i * step }`.slice(-2));
    }

    private toPickerDate(v: string | null): Partial<IMyDateModel> | null {
        const d = Daytable.fromDateTimeString(v);
        if (!(d instanceof Daytable)) {
            return null;
        }
        const dt = d.toDate();

        return {
            date: {
                year: dt.getFullYear(),
                month: dt.getMonth() + 1,
                day: dt.getDate(),
            }
        };
    }

    private toPickerTime(v: string | null): string | null {
        const d = Daytable.fromDateTimeString(v);
        if (!(d instanceof Daytable)) {
            return null;
        }
        const [, , , h, i] = d.toSpareParts();

        return `${h}:${i}`;
    }

    private fromPickerDate(v: { date: IMyDateModel | null, time: string | null }): string | null {
        const { date, time } = v;
        if (!date) {
            return null;
        }
        const t = time || '00:00';
        // User input invalid format
        if (!/^\d{2}:\d{2}$/.test(t)) {
            return null;
        }
        const [h, i] = t.split(':').map(Number);

        return v ? new Daytable(new Date(date.date.year, date.date.month - 1, date.date.day, h, i, 0, 0)).toDateTimeString() : null;
    }
}
