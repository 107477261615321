import { ModalManager } from '@aclass/admin/managers';
import { forwardRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-image-select',
    template: `
        <form [formGroup]="form" novalidate>
            <div class="input-group"  [class.input-group-sm]="sm">
                <input type="text" class="form-control" [class.disabled]="disabled" formControlName="v">
                <div class="input-group-append" [class.u-cursor-pointer]="!disabled">
                    <span class="input-group-text" [class.u-disabled-background]="disabled" (click)="pickImage()">
                        <clr-icon shape="folder-open"></clr-icon>
                    </span>
                    <span class="input-group-text" (click)="viewImage()" [class.u-cursor-not-allowed]="!viewable">
                        <clr-icon shape="eye"></clr-icon>
                    </span>
                </div>
            </div>
        </form>
        <clr-modal [(clrModalOpen)]="preview" [clrModalStaticBackdrop]="false" [clrModalSize]="'xl'">
            <h3 class="modal-title">View image</h3>
            <div class="modal-body text-center">
                <img [src]="form.value.v" class="img-fluid" *ngIf="viewable"/>
            </div>
        </clr-modal>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageSelectComponent),
            multi: true
        }
    ]
})
export class ImageSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

    /**
     * Watched value
     */
    @Input() set value(v: string | null) {
        if (this._v === v) {
            return;
        }
        this._v = v;
        if (!this.form) {
            return;
        }
        this.form.controls.v.setValue(v);
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<string | null> = new EventEmitter<string | null>();

    /**
     * Open event emitter
     */
    @Output() open: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Close event emitter
     */
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Show small version
     */
    @Input() sm = true;

    form: FormGroup;

    preview = false;

    private _v: string | null;

    private _disabled = false;

    private subscriptions: Array<Subscription> = [];

    get disabled(): boolean {
        return this._disabled;
    }

    get viewable(): boolean {
        const c = this.form.controls.v;

        return c.value && c.valid;
    }

    constructor(
        private modalManager: ModalManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.form = new FormGroup({
            v: new FormControl({ value: this._v, disabled: this._disabled }, [Validators.pattern(/\.(png|jpe?g|gif|bmp)$/)]),
        });
        this.subscriptions.push(
            this.form.controls.v.valueChanges.subscribe(v => {
                if (this._disabled) {
                    return;
                }
                v = this.form.controls.v.valid ? v : null;
                this._v = v;
                this.onChangeCallback(v);
                this.valueChange.emit(v);
            }),
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: string | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
        if (!this.form) {
            return;
        }
        const control = this.form.controls.v;
        if (isDisabled) {
            control.disable();

            return;
        }
        control.enable();
    }

    /**
     * @inheritDoc
     */
    writeValue(v: string | null) {
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: string | null) => void = () => { };

    pickImage() {
        if (this.disabled) {
            return;
        }
        this.modalManager.pickImage()
            .pipe(filter(i => typeof i !== 'undefined'))
            .subscribe(i => this.value = i.relativeUrl);
    }

    viewImage() {
        if (!this.viewable) {
            return;
        }
        this.preview = true;
    }
}
