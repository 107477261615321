import { FormManager } from '@aclass/admin/managers';
import { AppStory, UserStory } from '@aclass/admin/storage/actions';
import { User } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState, ISystemModuleState, IUserModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { findById } from '@aclass/core/helpers/orm';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-user-edit',
    templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit, OnDestroy {

    ff: ReactiveFormFactory;

    record: User;

    roleNames: Array<string>;

    shouldLogout: boolean;

    private _saveLoading: boolean;

    private _showProgress: boolean;

    private subscriptions: Array<Subscription> = [];

    get module() {
        return this.ngRdx.getState().userModule;
    }

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    get saveLoading(): boolean {
        return this._saveLoading;
    }

    get showProgress() {
        return this._showProgress;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.record = findById<User>(this.session, User, this.module.get('editPageRecordId'));
        const data = this.composerUserFormData(this.record);
        this.ff = new ReactiveFormFactory({
            name: 'User',
            attach: ['userModule', 'editPageForm'],
            controls: {
                username: new FormControl(data.username, [Validators.required, Validators.maxLength(255)]),
                email: new FormControl(data.email, [Validators.required, Validators.email, Validators.maxLength(255)]),
                password: new FormControl(data.password, [Validators.pattern(/\w{5,}/)]),
                deleted: new FormControl(data.deleted),
                role: new FormControl(data.role, [Validators.required]),
            },
        });
        this._saveLoading = false;
        this.roleNames = [];
        this.shouldLogout = false;
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['editPageSaveLock']>(['userModule', 'editPageSaveLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._saveLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['editPageRoleNames']>(['userModule', 'editPageRoleNames']).subscribe(records => {
                this.roleNames = records.toArray();
            })
        );
        this.subscriptions.push(
            this.ngRdx.select<IUserModuleState['editPageShouldLogout']>(['userModule', 'editPageShouldLogout'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this.shouldLogout = v)
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() logout = () => AppStory.logout();

    @dispatch() save = () => UserStory.submitOnEditPage();

    runSave() {
        if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.save();
        this.ff.form.markAsPristine();
    }

    private composerUserFormData = (r: User) => ({
        username: r.username,
        email: r.email,
        deleted: r.deleted,
        role: r.role ? r.role.name : null,
        password: null
    })
}
