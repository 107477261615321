import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IQuoteGalleryData {

    readonly id: string | null;

    readonly enquireDataId: number | null;

    readonly revision: number | null;

    readonly createdAt: number | null;

    readonly tourName: string | null;
}

@Model({
    name: 'QuoteGallery'
})
export class QuoteGallery extends Entity<IQuoteGalleryData> {

    @Attr() readonly id: string | null;

    @Attr() readonly enquireDataId: number | null;

    @Attr() readonly revision: number | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly tourName: string | null;
}
