import {
    GeneralRemarkStory,
    OrmStory, SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IGeneralRemarkData } from '@aclass/admin/storage/models';
import { IAdminState, IGeneralRemarkModuleState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const generalRemarkModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(GeneralRemarkStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.generalRemarkModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            GeneralRemarkStory.updateFormSearchPage({ }),
            GeneralRemarkStory.updateCollapsedSearchPage(null),
            // Unlock page
            GeneralRemarkStory.updateSearchPageInitState(true),
            GeneralRemarkStory.updateInstancesOnSearchPage(instanceManager.getInstances().map(i => i.buildId()).toArray()),
        ])
    ),
    action.pipe(
        ofType(GeneralRemarkStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.generalRemarkModule;
            const brands = module.getIn(['searchPageForm', 'brands']);
            const locales = module.getIn(['searchPageForm', 'locales']);

            return of(GeneralRemarkStory.updateInstancesOnSearchPage(instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray()));
        }),
    ),
    action.pipe(
        ofType(GeneralRemarkStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IGeneralRemarkModuleState['editPageRecordId']>) => state.value.generalRemarkModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IGeneralRemarkModuleState['editPageRecordId']>) => {
            const requests = [];
            const [brand, locale] = instanceManager.parseId(payload);
            const params = instanceManager.buildHeaders([brand, locale]);
            let namesRq: DataSearchRqData;
            namesRq = {
                where: {
                    brands: [brand],
                    locales: [locale],
                },
                pagination: new Pagination().all()
            };

            requests.push(http.get(`remark-settings`, { ...params, observe: 'response' }));
            requests.push(http.post(`remark-settings/destination-names`, namesRq, { observe: 'response' }));
            requests.push(http.post(`remark-settings/tour-names`, namesRq, { observe: 'response' }));

            return zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [
                        GeneralRemarkStory.updateFormOnEditPage({ }),
                        GeneralRemarkStory.updateRecordIdEditPage(payload),
                    ];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`remark-settings/destination-names`)) {
                            actions.push(GeneralRemarkStory.updateDestinationsOnEditPage(rs.body.results));
                        } else if (response.url.includes(`remark-settings/tour-names`)) {
                            actions.push(GeneralRemarkStory.updateToursOnEditPage(rs.body.results));
                        } else if (response.url.includes('remark-settings')) {
                            actions.push(GeneralRemarkStory.updateFormOnEditPage({ }));
                            actions.push(OrmStory.populateGeneralRemarks([rs.body]));
                        }
                    });

                    return actions;
                })
            );
        }),
    ),
    action.pipe(
        ofType(GeneralRemarkStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const result = [];
            const module = state.value.generalRemarkModule;
            const rows = module.getIn(['editPageForm', 'rows']).toJS();
            rows.forEach((v) => {
                result.push({
                    text: v.text,
                    destinations: v.destinations ? v.destinations : [],
                    tours: v.tours ? v.tours : [],
                    paxNumber: v.paxNumber
                });
            });

            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(GeneralRemarkStory.updateSaveLockEditPage(true)), http.post(`remark-settings`, { rows: result}, params).pipe(
                dispatchActions(
                    (rs: Response<IGeneralRemarkData>) => [
                        GeneralRemarkStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `General remark setting updated`)),
                        OrmStory.populateGeneralRemarks([rs.body])
                    ],
                    () => [
                        GeneralRemarkStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    )
);
