import { QuestionnaireStory } from '@aclass/admin/storage/actions';
import {
    INITIAL_QUESTIONNAIRE_STATE,
    IQuestionnaireModuleState,
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const questionnaireModuleReducer = (state: TypedState<IQuestionnaireModuleState> = INITIAL_QUESTIONNAIRE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case QuestionnaireStory.CREATE_PAGE_UPDATE_QUESTIONNAIRE_ID:
            return state.update('createPageRecordId', () => payload);
        case QuestionnaireStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case QuestionnaireStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case QuestionnaireStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case QuestionnaireStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case QuestionnaireStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case QuestionnaireStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case QuestionnaireStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_ID:
            return state.update('editPageRecordId', () => payload);
        case QuestionnaireStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case QuestionnaireStory.EDIT_PAGE_CREATE_FORM:
            return state.update('createPageForm', () => fromJS(payload));
        case QuestionnaireStory.RESULT_PAGE_UPDATE_QUESTIONNAIRE_ID:
            return state.update('resultPageRecordId', () => payload);
        case QuestionnaireStory.RESULT_SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('resultSearchPageResults', () => List(payload));
        case QuestionnaireStory.SHOW_RESULT_PAGE_UPDATE_QUESTIONNAIRE_RESULT_ID:
            return state.update('showResultPageRecordId', () => payload);
        default:
            return state;
    }
};
