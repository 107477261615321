import { WINDOW } from '@aclass/core/services/window.service';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

@Component({
    selector: 'adm-back-button',
    templateUrl: './back-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackButtonComponent implements OnInit {

    /**
     * Show small version
     */
    @Input() sm = true;

    constructor(
        @Inject(WINDOW) private window: Window,
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    back() {
        if (!('history' in this.window)) {
            return;
        }
        this.window.history.back();
    }
}
