import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { Map } from 'immutable';

export interface IEmailTemplateModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of email-templates ids from ORM
     */
    searchPageResults: Array<object> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * The id of email template
     */
    editPageRecordId: number | null;

    /**
     * Edit form data
     */
    editPageSendEmailForm: Map<string, any>;

    /**
     * Send Email opened state
     */
    editPageSendEmailOpened: null | boolean;

    /**
     * Trigger to identify that search is running
     */
    editPageUploadDocumentsLock: null | boolean;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    editPageLocale: string | null;

    editPageBrand: string | null;

    editPageSaveLock: boolean | null;

    editPageSaveValidate: boolean | null;

    /**
     * Trigger to identify that email is sending
     */
    sendEmailPageSendingEmail: boolean | null;
}

export const INITIAL_EMAIL_TEMPLATE_STATE = createTypedState<IEmailTemplateModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageOrder: null,
    createPageForm: { },
    editPageForm: { },
    editPageRecordId: null,
    editPageSendEmailForm: { },
    editPageSendEmailOpened: null,
    editPageUploadDocumentsLock: null,
    searchPagePagination: null,
    editPageLocale: null,
    editPageBrand: null,
    editPageSaveLock: null,
    editPageSaveValidate: null,
    sendEmailPageSendingEmail: null,
});
