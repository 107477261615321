import { Instance } from '@aclass/core/base/instance';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'email-template'
})
export class EmailTemplateStory {

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_USER_SEARCH_LOCK: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPLOAD_DOCUMENTS: string;

    @Task() static EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK: string;

    @Task() static EDIT_PAGE_DOWNLOAD_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_PICKED_DOCUMENT: string;

    @Task() static EDIT_PAGE_REMOVE_DOCUMENT: string;

    @Task() static EDIT_PAGE_SEND_EMAIL_SUBMIT: string;

    @Task() static EDIT_PAGE_SEND_EMAIL_UPDATE_OPENED: string;

    @Task() static EDIT_PAGE_SEND_EMAIL_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static EDIT_PAGE_TOGGLE_ARCHIVE: string;

    @Task() static COPY_ON_SEARCH_PAGE: string;

    @Task() static EDIT_PAGE_UPDATE_LOCALE: string;

    @Task() static EDIT_PAGE_UPDATE_BRAND: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_SAVE_VALIDATE: string;

    @Task() static EDIT_PAGE_SEND_EMAIL_SUBMIT_LOCK: string;

    static dryRunSearchPage(): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_ORDER, payload: order };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static importRecordsOnSearchPage(items: Array<Object>): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_IMPORT_RECORDS, payload: items };
    }

    static submitOnSearchPage(): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: { id: number, brand?: Instance['brand'], locale?: Instance['locale'] }): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static submitOnEditPage(): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_SUBMIT };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateIdEditPage(id: number): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_ID, payload: id };
    }

    static updateSearchUserLockEditPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_USER_SEARCH_LOCK, payload: v };
    }

    static submitOnCreatePage(): Action {
        return { type: EmailTemplateStory.CREATE_PAGE_SUBMIT };
    }

    static uploadDocumentsEditPage(files: Array<File>): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPLOAD_DOCUMENTS, payload: files };
    }

    static updateUploadDocumentsLockEditPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK, payload: v };
    }

    static downloadDocumentEditPage(v: string): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_DOWNLOAD_DOCUMENT, payload: v };
    }

    static updatePickedDocumentEditPage(v: string | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT, payload: v };
    }

    static removeDocumentEditPage(v: string): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_REMOVE_DOCUMENT, payload: v };
    }

    static submitSendEmailEditPage(): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_SUBMIT };
    }

    static updateSendEmailOpenedOnEditPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_UPDATE_OPENED, payload: v };
    }

    static updateSendEmailFormOnEditPage(v: any): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_UPDATE_FORM, payload: v };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: EmailTemplateStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static toggleArchiveEditPage(v: boolean): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_TOGGLE_ARCHIVE, payload: v };
    }

    static copyOnSearchPage(v: number): Action {
        return { type: EmailTemplateStory.COPY_ON_SEARCH_PAGE, payload: v };
    }

    static updateLocaleEditPage(payload: Instance['locale'] | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_LOCALE, payload };
    }

    static updateBrandEditPage(payload: Instance['brand'] | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_BRAND, payload };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static updateSendEmailSubmitLockEditPage(v: boolean | null): Action {
        return { type: EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_SUBMIT_LOCK, payload: v };
    }
}
