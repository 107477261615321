// import { WINDOW } from '@aclass/core/services/window.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'adm-wh-back-button',
    templateUrl: './wh-back-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhBackButtonComponent implements OnInit {

    /**
     * Show small version
     */
    @Input() sm = true;

    constructor(
        // @Inject(WINDOW) private window: Window,
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    whBack() {
        window.location.href = `/admin/ao/working-hours`;
    }
}
