import { ISignatureModalResultData } from '@aclass/admin/components';
import {
    OrmStory,
    RootStory,
    SignatureStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { ISignatureData, Signature } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { IUserData } from '@aclass/core/storage/models/user';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const signatureModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(SignatureStory.SEARCH_USER_PAGE_DRY_RUN),
        filter(() => !state.value.signatureModule.get('searchUsersPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SignatureStory.updateFormUserSearchPage({ }),
            SignatureStory.importRecordsOnUserSearchPage([]),
            SignatureStory.updatePaginationOnUserSearchPage(null),
            SignatureStory.updateOrderOnUserSearchPage(null),
            SignatureStory.updateSearchLockOnUserSearchPage(null),
            SignatureStory.updateCollapsedUserSearchPage(null),
            // Unlock page
            SignatureStory.updateSearchUserPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SignatureStory.SEARCH_USER_PAGE_SUBMIT),
        switchMap(() => {
            const module = state.value.signatureModule;
            const rq: DataSearchRqData = {
                where: module.get('searchUsersPageForm'),
                pagination: module.get('searchUsersPagePagination'),
                order: module.get('searchUsersPageOrder'),
            };

            return concat(of(SignatureStory.updateSearchLockOnUserSearchPage(true)), http.post('signatures/user-list', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    OrmStory.populateUsers(rs.body.results),
                    SignatureStory.importRecordsOnUserSearchPage(rs.body.results.map(r => r.id)),
                    SignatureStory.updatePaginationOnUserSearchPage(rs.body.pagination),
                    SignatureStory.updateOrderOnUserSearchPage(rs.body.order),
                    SignatureStory.updateSearchLockOnUserSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SignatureStory.SEARCH_SIGNATURE_PAGE_DRY_RUN),
        filter(({ payload }: Action<IUserData['id']>) => {
            const module = state.value.signatureModule;

            return !module.get('searchPageInitialized') || module.get('signatureUserId') !== payload;
        }),
        switchMap(({ payload }: Action<IUserData['id']>) => [
            // Reset prev records state
            SignatureStory.updateFormSearchPage({ }),
            SignatureStory.importRecordsOnSearchPage([]),
            SignatureStory.updatePaginationOnSearchPage(null),
            SignatureStory.updatePaginationOnSearchPage(null),
            SignatureStory.updateSearchLockOnSearchPage(null),
            SignatureStory.updateCollapsedSearchPage(null),
            // Unlock page
            SignatureStory.updateUserIdSearchPage(payload),
            SignatureStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SignatureStory.SEARCH_SIGNATURE_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.signatureModule;
            const rq: DataSearchRqData = {
                where: {
                    userId: module.get('signatureUserId'),
                    brands: module.getIn(['searchPageForm', 'brands']),
                    locales: module.getIn(['searchPageForm', 'locales']),
                },
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(SignatureStory.updateSearchLockOnSearchPage(true)), http.post('signatures/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ISignatureData>>>) => [
                    OrmStory.populateSignatures(rs.body.results),
                    SignatureStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    SignatureStory.updatePaginationOnSearchPage(rs.body.pagination),
                    SignatureStory.updateOrderOnSearchPage(rs.body.order),
                    SignatureStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SignatureStory.CREATE_SIGNATURE_PAGE_DRY_RUN),
        filter(({ payload }) => state.value.signatureModule.get('createPageSignatureId') !== payload),
        mergeMap(({ payload }) => {
            const userId = state.value.signatureModule.get('signatureUserId');
            const rq: DataSearchRqData = {
                where: { id: userId }
            };

            return http.post(`signatures/user-list`, rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => {

                    return [
                        OrmStory.populateUsers(rs.body.results),
                        SignatureStory.updateIdCreatePage(payload),
                    ];
                })
            );
        }),
    ),
    action.pipe(
        ofType(SignatureStory.CREATE_SIGNATURE_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.signatureModule;
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('createPageSignatureId')));
            const userId = state.value.signatureModule.get('signatureUserId');
            const rq = {
                userId: userId,
                content: state.value.signatureModule.getIn(['createPageForm', 'content'])
            };

            return http.post('signatures', rq, params).pipe(
                dispatchActions((rs: Response<ISignatureData>) => [
                    SignatureStory.updateFormOnCreatePage({ userId: userId }),
                    SystemStory.showNotification(SystemNotification.success('Success', `Signature created`)),
                    OrmStory.populateSignatures([rs.body]),
                    RootStory.resetForm(),
                    SignatureStory.updateSearchPageInitState(false),
                    SystemStory.navigate(['signatures', 'edit', userId, rs.body.id], { replaceUrl: true, sharedLink: true }),
                ])
            );
        }),
    ),
    action.pipe(
        ofType(SignatureStory.EDIT_SIGNATURE_PAGE_DRY_RUN),
        filter(({ payload }) => state.value.signatureModule.get('editPageSignatureId') !== payload),
        mergeMap(({ payload }) => {
            const userId = state.value.signatureModule.get('signatureUserId');
            const queue = [];
            const [brand, locale, id] = instanceManager.parseId(payload);
            const rq: DataSearchRqData = {
                where: { id: userId }
            };
            const params = instanceManager.buildHeaders([brand, locale]);
            queue.push(http.get(`signatures/${ id }`, { ...params, ...{ observe: 'response' } }));
            queue.push(http.post(`signatures/user-list`, rq, { ...params, ...{ observe: 'response' } }));

            return zip(...queue).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes('signatures/user-list')) {
                            actions.push(OrmStory.populateUsers(rs.body.results));
                        } else if (response.url.includes(`signatures/${ id }`)) {
                            actions.push(SignatureStory.updateSignatureIdEditPage(payload));
                            actions.push(SignatureStory.updateFormOnEditPage({ content: rs.body.content, brand: brand, locale: locale }));
                            actions.push(OrmStory.populateSignatures([rs.body]));
                        }
                    });

                    return actions;
                })
            );
        }),
    ),
    action.pipe(
        ofType(SignatureStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.signatureModule;
            const [brand, locale, id] = instanceManager.parseId(module.get('editPageSignatureId'));
            const params = instanceManager.buildHeaders([brand, locale]);
            const data = module.get('editPageForm');

            return http.put(`signatures/${ id }`, module.get('editPageForm'), params).pipe(
                dispatchActions((rs: Response<ISignatureData>) => {
                    const actions = [
                        SystemStory.showNotification(SystemNotification.success('Success', `Signature updated`)),
                    ];
                    actions.push(OrmStory.populateSignatures([rs.body]));
                    actions.push(SignatureStory.updateFormOnEditPage({ ...data.toJS() }));
                    actions.push(SignatureStory.updateSearchPageInitState(false));

                    return actions;
                })
            );
        }),
    ),
    action.pipe(
        ofType(SignatureStory.SEARCH_SIGNATURE_PAGE_IMPORT_CONTENT_FOR_COPY_MODAL),
        switchMap(({ payload }) => {
            const signature: Signature = findById<Signature>(orm.session(state.value.orm), Signature, payload.value.brandLocaleFrom);
            const result: ISignatureModalResultData = { brand: payload.value.brandLocaleTo.split('-')[0], locale: payload.value.brandLocaleTo.split('-')[1], content: signature.content };

            return [
                SignatureStory.updateCopyModalResultData(result),
            ];
        })
    ),
    action.pipe(
        ofType(SignatureStory.COPY_SIGNATURE_FROM_TO),
        mergeMap(({ payload }) => {
            const brandLocaleTo = payload.value.brandLocaleTo;
            const module = state.value.signatureModule;
            const id = module.get('signatureUserId');
            const content = module.get('copyModalResultData').content;
            const result = {
                content: content,
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(brandLocaleTo));

            return http.put(`signatures/copy/${ id }`, result, params).pipe(
                dispatchActions((rs: Response<ISignatureData>) => {
                    payload.reset();

                    return [
                        SignatureStory.showCopyModal(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Signature copied`)),
                        OrmStory.populateSignatures([rs.body]),
                        SignatureStory.updateSearchPageInitState(false),
                        SignatureStory.submitOnSearchPage(),
                    ];
                })
            );
        })
    ),
    action.pipe(
        ofType(SignatureStory.EDIT_PAGE_REMOVE_SIGNATURE),
        mergeMap(() => {
            const module = state.value.signatureModule;
            const signatureId = module.get('editPageSignatureId');
            const userId = module.get('signatureUserId');
            const [brand, locale, id] = instanceManager.parseId(signatureId);
            const params = instanceManager.buildHeaders([brand, locale]);

            return http.delete(`signatures/${ id }`, params).pipe(
                dispatchActions(() => {
                    return [
                        SignatureStory.updateSearchPageInitState(false),
                        OrmStory.dropSignatures([signatureId]),
                        RootStory.resetForm(),
                        SignatureStory.updateRemoveModalLockOnEditPage(false),
                        SignatureStory.updateShowRemoveModalOnEditPage(false),
                        SystemStory.navigate(['signatures', 'list', userId], { replaceUrl: true, sharedLink: true }),
                        SystemStory.showNotification(SystemNotification.success('Success', `Signature "${ signatureId }" removed.`)),
                    ];
                })
            );
        }),
    ),
);
