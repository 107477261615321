import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

// export interface IAdditionalProductPrice {
//     [key: string]: number;
// }

export interface ISoldoutProductData {

    readonly id: number;

    readonly brand: string;

    readonly name: string;

    readonly productType: string;

    readonly productId: string | number;

    readonly optionId: string | number;

    readonly optionName: string;

    readonly dayStart: string;

    readonly dayEnd: string;

    readonly deleted: boolean;

    readonly stopSales: string | null;

    readonly period: Array<any> | null;

    readonly createdAt: number;

    readonly updatedAt: number;

}

@Model({
    name: 'SoldoutProduct'
})

export class SoldoutProduct extends Entity<ISoldoutProductData> {

    @Attr() readonly id: number | null;

    @Attr() readonly brand: string;

    @Attr() readonly brandLabel: string;

    @Attr() readonly name: string;

    @Attr() readonly productType: string;

    @Attr() readonly productId: string;

    @Attr() readonly optionId: string;

    @Attr() readonly optionName: string;

    @Attr() readonly stopSales: string | null;

    @Attr() readonly period: Array<any> | null;

    @Attr() readonly dayStart: string | null;

    @Attr() readonly dayEnd: string | null;

    @Attr() readonly deleted: boolean | null;

    // todo to remove product&option name from api
    // readonly productType: ITWProductData['type'];

    // readonly productId: ITWProductData['id'];

    // readonly optionId: ITWOptionData['id'];
    // @Fk({ to: 'TWProduct', relatedName: 'relatedProductDayInfos' }) readonly productId: TWProduct;
    //
    // @Fk({ to: 'TWOption', relatedName: 'relatedProductDayInfos' }) readonly optionId: TWOption;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    // get product(): TWProduct {
    //     return this.productId;
    // }
    //
    // get contract(): TWOption {
    //     return this.optionId;
    // }
}
