import { IOverviewData, Overview } from '@aclass/admin/storage/models/overview';
import { IOverviewDefinitionData, OverviewDefinition } from '@aclass/admin/storage/models/overview.definition';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOverviewDisplayData {

    readonly id: number | null;

    readonly definitionId: OverviewDefinition | IOverviewDefinitionData['id'] | null;

    readonly overviewId: Overview | IOverviewData['id'] | null;

    readonly name: string | null;
}

@Model({
    name: 'OverviewDisplay'
})
export class OverviewDisplay extends Entity<IOverviewDisplayData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Fk({ to: 'OverviewDefinition', relatedName: 'relatedDisplays' }) readonly definitionId: OverviewDefinition | null;

    @Fk({ to: 'Overview', relatedName: 'relatedDisplays' }) readonly overviewId: Overview | null;
}
