import { FormManager } from '@aclass/admin/managers';
import { AclStory } from '@aclass/admin/storage/actions';
import { IRolePermissionData } from '@aclass/admin/storage/models';
import { IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { List } from 'immutable';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-acl-create',
    templateUrl: './create.component.html',
    styleUrls: []
})
export class AclCreateComponent implements OnInit, OnDestroy {

    ff: ReactiveFormFactory;

    rolePermissions: Array<IRolePermissionData>;

    private _saveLoading: boolean;

    private _showProgress: boolean;

    private subscriptions: Array<Subscription> = [];

    get saveLoading(): boolean {
        return this._saveLoading;
    }

    get showProgress() {
        return this._showProgress;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.ff = new ReactiveFormFactory({
            name: 'Role',
            attach: ['aclModule', 'createPageForm'],
            controls: {
                name: new FormControl(null, [Validators.required, Validators.maxLength(255), Validators.pattern(/^[a-zA-Z0-9]*$/)]),
            },
        });
        this._saveLoading = false;
        this.rolePermissions = [];
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['aclModule', 'createPageSaveLock'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._saveLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<List<IRolePermissionData>>(['aclModule', 'createPageRolePermissions']).subscribe(records => {
                this.rolePermissions = records.toJS();
            })
        );
        this.subscriptions.push(this.formManager.onReset.subscribe(() => {
            this.ff.form.reset();
        }));
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    @dispatch() updateRolePermissions = () => AclStory.updateRolePermissionsOnCreatePage(this.rolePermissions);

    @dispatch() save = () => AclStory.submitOnCreatePage();

    runSave = () => {
        if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.save();
        this.ff.form.markAsPristine();
    }

    runUpdatePermissions = () => {
        this.updateRolePermissions();
        this.ff.form.markAsDirty();
    }
}
