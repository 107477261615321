import { Daytable } from '@aclass/core/base/daytable';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fromDateString'
})

export class DatePipe implements PipeTransform {

    /**
     * @inheritDoc
     */
    transform(value: any): Date | undefined {
        const dt = Daytable.fromDateString(value);

        return dt ? dt.toDate() : undefined;
    }
}
