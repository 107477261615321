import { createTypedState } from '@aclass/core/storage/states/module.state';
import { ISystemModuleState as BaseSystemModuleState } from '@aclass/core/storage/states/system.module.state';
import { Map } from 'immutable';

export interface ISystemModuleState extends BaseSystemModuleState {
    /**
     * The redirect link after login
     */
    redirect: string | null;

    /**
     * The application name
     */
    application: string | null;

    /**
     * The current user of the system
     */
    identity: Map<string, any> | null;
}

export const INITIAL_SYSTEM_STATE = createTypedState<ISystemModuleState>({
    initialized: null,
    application: null,
    baseInterceptorEnabled: true,
    smartInterceptorEnabled: true,
    redirect: null,
    route: '',
    version: '',
    alerts: [],
    notifications: [],
    identity: null,
    nextPageLock: null,
    config: {
        apiEndpoint: '/api/',
    }
});
