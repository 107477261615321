import { Document } from '@aclass/admin/storage/models/document';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDocumentTravelWiseOpportunityMappingData {

    readonly documentId: Document | string | null;

    readonly enquireDataId: Opportunity | number | null;

    readonly type: string | null;

    readonly comment: string | null;
}

@Model({
    name: 'DocumentTravelWiseOpportunityMapping',
    idAttribute: 'documentId'
})
export class DocumentTravelWiseOpportunityMapping extends Entity<IDocumentTravelWiseOpportunityMappingData> {

    @Fk({ to: 'Document', relatedName: 'relatedDocumentTravelWiseOpportunityMappings' }) readonly documentId: Document | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedDocumentTravelWiseOpportunityMappings' }) readonly enquireDataId: Opportunity | null;

    @Attr() readonly type: string | null;

    @Attr() readonly comment: string | null;
}
