import { IOfflineBookingEmailData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IOfflineBookingEmailModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of record ids from ORM
     */
    searchPageResults: List<IOfflineBookingEmailData['id']>;

    /**
     * A list of domains
     */
    searchPageInstances: List<string>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The id of record
     */
    editPageRecordId: string | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Identifies that all data for page is loaded
     */
    editReportPageInitialized: boolean | null;

    /**
     * The the save button lock
     */
    editReportPageSaveLock: boolean | null;

    /**
     * Edit page form data
     */
    editReportPageForm: Map<string, any>;

    /**
     * Edit page modal form data
     */
    editReportPageModalForm: Map<string, any>;

    /**
     * Edit page modal form data
     */
    editPageModalForm: Map<string, any>;

    /**
     * Data for report edit page
     */
    editReportPageData: Map<string, any>;

    /**
     * Locks delete button
     */
    editReportPageShowSendModal: boolean | null;
}

export const INITIAL_OFFLINE_BOOKING_EMAIL_STATE = createTypedState<IOfflineBookingEmailModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageInstances: [],
    searchPagePagination: null,
    searchPageOrder: null,
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageForm: { },
    editReportPageInitialized: null,
    editReportPageSaveLock: null,
    editReportPageForm: { },
    editReportPageModalForm: { },
    editPageModalForm: { },
    editReportPageData: { },
    editReportPageShowSendModal: null,
});
