import { ICountryData } from '@aclass/admin/storage/models';
import { ISendEmailState } from '@aclass/admin/storage/states/send.email.state';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ICustomerModuleState extends IModuleState, ISendEmailState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * A list of customer's ids
     */
    searchPageSelectedCustomers: List<string>;

    /**
     * A list of opportunity ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The id of opportunity
     */
    editPageRecordId: ICustomerData['id'] | null;

    /**
     * Block expand state
     */
    editPageStackViewSegments: Map<string, boolean> | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    editPageUploadDocumentsLock: boolean | null;

    /**
     * Trigger to disable download or remove buttons
     */
    editPagePickedIdDocumentLock: string | null;

    /**
     * Trigger to identify that search is running
     */
    editPageCountrySearchLock: boolean | null;

    /**
     * A list of pipeline level ids
     */
    editPageCountries: List<ICountryData['id']>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Customer merger opened state
     */
    editPageCustomerMergerId: ICustomerData['id'] | null;
}

export const INITIAL_CUSTOMER_STATE = createTypedState<ICustomerModuleState>({
    searchPageInitialized: null,
    searchPageLock: null,
    searchPageCollapsed: null,
    searchPageForm: { },
    searchPagePagination: null,
    searchPageOrder: null,
    searchPageSelectedCustomers: [],
    searchPageResults: [],
    editPageRecordId: null,
    editPageStackViewSegments: null,
    editPageSaveLock: null,
    editPageUploadDocumentsLock: null,
    editPagePickedIdDocumentLock: null,
    editPageForm: { },
    editPageCountrySearchLock: null,
    editPageCountries: [],
    editPageCustomerMergerId: null,
    sendEmailOpened: null,
    sendEmailOpenedCalendar: null,
    sendEmailTemplateForm: { },
    sendEmailMessageForm: { },
    sendEmailTemplateIds: [],
    sendEmailTemplateSearchLock: null,
    sendEmailMessageId: null,
    sendEmailDocumentIds: [],
});
