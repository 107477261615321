import { FormManager } from '@aclass/admin/managers';
import { InternationalizationStory, SystemStory } from '@aclass/admin/storage/actions';
import { SourceMessage } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState, ISystemModuleState } from '@aclass/admin/storage/states';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { findByIds } from '@aclass/core/helpers/orm';
import { IOrderData, Order } from '@aclass/core/rest/order';
import { IPaginationData, Pagination } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { dispatch, NgRedux } from '@angular-redux/store';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { List, Map } from 'immutable';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-internationalization-search',
    templateUrl: './internationalization-search.component.html'
})
export class InternationalizationSearchComponent implements OnInit, OnDestroy, AfterViewInit {

    ff: ReactiveFormFactory;

    messages: Array<SourceMessage>;

    private _pagination: Pagination = new Pagination();

    private _category: string;

    private _order: Order = new Order();

    private _collapsed;

    private _showProgress: boolean;

    private _loading: boolean;

    private subscriptions: Array<Subscription> = [];

    get module() {
        return this.ngRdx.getState().internationalizationModule;
    }

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    set category(v: string) {
        this.ngRdx.dispatch(InternationalizationStory.updateSearchPageInitState(false));
        this._category = v;
    }

    get category() {
        return this._category;
    }

    set collapsed(v: boolean | null) {
        this.ngRdx.dispatch(InternationalizationStory.updateCollapsedSearchPage(v));
    }

    get collapsed() {
        return this._collapsed;
    }

    set pagination(v) {
        this.ngRdx.dispatch(InternationalizationStory.updatePaginationOnSearchPage(v));
        this.search();
    }

    get pagination(): Pagination {
        return this._pagination;
    }

    set order(v) {
        this.ngRdx.dispatch(InternationalizationStory.updateOrderOnSearchPage(v));
        this.search();
    }

    get order(): Order {
        return this._order;
    }

    get showProgress() {
        return this._showProgress;
    }

    get loading() {
        return this._loading;
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private formManager: FormManager
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        const module = this.module;
        this.messages = [];
        this._loading = module.get('searchPageLock') === null;
        this.ff = new ReactiveFormFactory({
            name: 'Search form',
            attach: ['internationalizationModule', 'searchPageForm'],
            controls: {
                message: new FormControl(null),
                description: new FormControl(null)
            }
        });
        this.subscriptions.push(
            this.ngRdx.select(['internationalizationModule', 'searchPageLock'])
                .pipe(filter((v: boolean | null) => v !== null))
                .subscribe((v: boolean | null) => this._loading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select(['internationalizationModule', 'searchPageResults'])
                .subscribe((ids: List<string>) => {
                    this.messages = findByIds<SourceMessage>(this.session, SourceMessage, ids);
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<Map<string, any> | null>(['internationalizationModule', 'searchPagePagination'])
                .subscribe(v => this._pagination = this._pagination.with(v))
        );
        this.subscriptions.push(
            this.ngRdx.select<Map<string, any> | null>(['internationalizationModule', 'searchPageOrder'])
                .subscribe(v => this._order = this._order.with(v))
        );
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['nextPageLock']>(['systemModule', 'nextPageLock'])
                .subscribe(v => this._showProgress = v !== null && v)
        );
        this.subscriptions.push(
            this.ngRdx.select<ISystemModuleState['application']>(['systemModule', 'application'])
                .subscribe(v => {
                    this.category = v;
                })
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        if (this.module.get('searchPageLock') === null) {
            this.search();
        }
    }

    @dispatch() showTranslations = (r: SourceMessage) => SystemStory.navigate(['i18n', 'show', r.id], { sharedLink: true });

    @dispatch() search = (opts?: ISearchOptions) => InternationalizationStory.submitOnSearchPage(opts);

    @dispatch() updatePagination = (data: IPaginationData) => InternationalizationStory.updatePaginationOnSearchPage(data);

    @dispatch() updateOrder = (data: IOrderData) => InternationalizationStory.updateOrderOnSearchPage(data);

    runSearch(refresh: boolean = false) {
        if (refresh) {
            this.ff.form.reset();
        } else if (!this.ff.validate()) {
            this.ngRdx.dispatch(this.formManager.collectErrors(this.ff));

            return;
        }

        this.search({ pagination: true, order: refresh });
    }
}
