import { isList } from '@aclass/core/helpers/immutable';
import { List } from 'immutable';
import { ORMCommonState, SessionWithModels } from 'redux-orm';

export type OrmSession = SessionWithModels<ORMCommonState>;

export function hasId(session: OrmSession, model: any, id: any): boolean {
    return (<any>session[model.modelName]).idExists(id);
}

export function findById<T>(session: OrmSession, model: any, id: any): T {
    const r = session[model.modelName].withId(id);
    if (!r) {
        throw new Error(`${ model.modelName } "${ id }" not found`);
    }

    return <T>r;
}

export function findByIds<T>(session: OrmSession, model: any, ids: Array<any> | List<any>): Array<T> {
    const list = isList(ids) ? ids.toArray() : ids;

    return <Array<T>>(<Array<any>>list).map(id => findById<T>(session, model, id));
}
