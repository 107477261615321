import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IPipelineModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of pipeline ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The id of pipeline
     */
    editPageRecordId: number | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Definitions opened state
     */
    searchPageDefinitionsOpened: null | boolean;
}

export const INITIAL_PIPELINE_STATE = createTypedState<IPipelineModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageForm: { },
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageForm: { },
    searchPageDefinitionsOpened: null
});
