<clr-alerts [(clrCurrentAlertIndex)]="index">
    <clr-alert *ngFor="let a of alerts" [clrAlertType]="getLevel(a)" [clrAlertAppLevel]="true" [clrAlertClosable]="false">
        <div *ngIf="a.isServerError()">
            <div class="alert-item">
                <span class="alert-text">
                    Application detects an issue
                </span>
                <div class="alert-actions btn-group">
                    <button class="btn btn-info-outline alert-action" (click)="runReport(a)">Report</button>
                    <button class="btn btn-info-outline alert-action" (click)="removeAlert(a)">Dismiss</button>
                </div>
            </div>
        </div>
        <div *ngIf="a.isForbiddenError()">
            <div class="alert-item">
                <span class="alert-text">
                    It looks like your permissions has been changed. Try to re-login to the system
                </span>
                <div class="alert-actions btn-group">
                    <button class="btn btn-info-outline alert-action" (click)="logout()">Re-login</button>
                </div>
            </div>
        </div>
        <div *ngIf="a.isUnauthorizedError()">
            <div class="alert-item">
                <span class="alert-text">
                    Your session is closed on server side. Please re-login to the system
                </span>
                <div class="alert-actions btn-group">
                    <button class="btn btn-info-outline alert-action" (click)="logout()">Re-login</button>
                </div>
            </div>
        </div>
        <div *ngIf="a.isUnsupportedBrowser()">
            <div class="alert-item">
                <span class="alert-text">
                    You're using the not supported browser
                </span>
                <div class="alert-actions">
                    <button class="btn alert-action" (click)="runBrowserUpdate()">Read more</button>
                </div>
            </div>
        </div>
        <div *ngIf="a.isUpdateAvailable()">
            <div class="alert-item">
                <span class="alert-text">
                    A new update is now available.
                </span>
                <div class="alert-actions">
                    <button class="btn alert-action" (click)="runUpdate()">Install Update</button>
                </div>
            </div>
        </div>
    </clr-alert>
</clr-alerts>