import { RouterManager } from '@aclass/admin/managers';
import { AclStory } from '@aclass/admin/storage/actions';
import { IAclModuleState, IAdminState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class AclCreateStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:acl-create';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<IAclModuleState['initialized']>(['aclModule', 'initialized']).pipe(
            filter(v => v),
            tap(() => this.ngRdx.dispatch(AclStory.dryRunCreatePage()))
        );
        this.employee = this.routerManager.onRejectNavigation(AclCreateStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
    }
}
