import { TranslationMessage } from '@aclass/admin/storage/models/translation.message';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ISourceMessageData {

    readonly id: string | null;

    readonly message: string | null;

    readonly description: string | null;
}

@Model({
    name: 'SourceMessage'
})
export class SourceMessage extends Entity<ISourceMessageData> {

    @Attr() readonly id: string | null;

    @Attr() readonly message: string | null;

    @Attr() readonly description: string | null;

    readonly relatedTranslatedMessages: QuerySet<TranslationMessage> | null;
}
