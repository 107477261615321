import { SystemStory } from '@aclass/admin/storage/actions';
import { IAdminState } from '@aclass/admin/storage/states';
import { Response } from '@aclass/core/rest/response';
import { NgRedux } from '@angular-redux/store';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DRIVERS, Locker } from 'angular-safeguard';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class BaseInterceptor implements HttpInterceptor {

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private storage: Locker
    ) {
    }

    /**
     * @inheritDoc
     */
    intercept(rq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storage.get(DRIVERS.LOCAL, 'token');
        const override: { headers: HttpHeaders, url?: string } = {
            headers: rq.headers
        };

        override.headers = override.headers.set('Accept', 'application/json');
        if (token) {
            override.headers = override.headers.set('Authorization', `Bearer ${token}`);
        }
        if (!rq.headers.has('Content-Type')) {
            override.headers = override.headers.set('Content-Type', 'application/json');
        }

        const apiEndpoint = this.ngRdx.getState().systemModule.getIn(['config', 'apiEndpoint']);
        if (apiEndpoint && !rq.url.startsWith('/')) {
            override.url = `${apiEndpoint}${rq.url}`;
        }

        return next.handle(rq.clone(override)).pipe(
            map((rs: HttpEvent<any>): any => {
                if (!(rs instanceof HttpResponse)) {
                    return rs;
                }
                if (!rs.headers.has('Content-Type') || !rs.headers.get('Content-Type').includes('application/json')) {
                    return rs;
                }
                // A dirty hack to wrap body
                // Back end returns json for any non standard behavior
                // Please don't use 'blob' to download files, because in this case angular wrap error responses to blob object
                ((response: any) => response.body = new Response(rq.responseType === 'text' ? JSON.parse(response.body) : response.body))(rs);
                this.ngRdx.dispatch(SystemStory.callBaseInterceptor(rs));
                this.ngRdx.dispatch(SystemStory.callSmartInterceptor(rs));
                if (rq.responseType === 'text') {
                    ((response: any) => response.body = null)(rs);
                }

                return rs;
            }),
            catchError((rs: HttpErrorResponse): any => {
                if (!(rs instanceof HttpErrorResponse)) {
                    return of(rs);
                }
                if (!rs.headers.has('Content-Type') || !rs.headers.get('Content-Type').includes('application/json')) {
                    return rs;
                }

                this.ngRdx.dispatch(SystemStory.callBaseInterceptor(rs));

                return of(rs);
            })
        );
    }
}
