import { DomainDetailEditStateResolver } from '@aclass/admin/resolvers';
import {
    DomainDetailStory,
    OpportunityStory,
    OrmStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    IDomainDetailData,
    IEmailTemplateData, IQuestionnaireData, ISmsTemplateData,
} from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Order } from '@aclass/core/rest/order';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { IUserData } from '@aclass/core/storage/models/user';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type viewData = Response<{
    domainDetail: IDomainDetailData,
    manager: IUserData | null,
    emailTemplates: Array<IEmailTemplateData>,
    smsTemplates: Array<ISmsTemplateData>,
    questionnaireTemplates: Array<IQuestionnaireData>
}>;

export const domainDetailModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(DomainDetailStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.domainDetailModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            DomainDetailStory.updateFormSearchPage({ }),
            DomainDetailStory.importRecordsOnSearchPage([]),
            DomainDetailStory.importInstancesOnSearchPage([]),
            DomainDetailStory.updateSearchLockOnSearchPage(null),
            DomainDetailStory.updateCollapsedSearchPage(null),
            // Unlock page
            DomainDetailStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(DomainDetailStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.domainDetailModule;
            const brands: Array<Instance['brand']> = module.getIn(['searchPageForm', 'brands']);
            const locales: Array<Instance['locale']> = module.getIn(['searchPageForm', 'locales']);
            const userName = module.getIn(['searchPageForm', 'username']);
            const rq: DataSearchRqData = {
                where: {
                    brands,
                    locales,
                    username: userName,
                },
                pagination: new Pagination().all()
            };

            return concat(of(DomainDetailStory.updateSearchLockOnSearchPage(true)), http.post('domain-detail/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IDomainDetailData & { managerUsername: string | null }>>>) => {
                    const users: Array<Partial<IUserData>> = [];
                    const domains: Array<Partial<IDomainDetailData>> = [];
                    const ids = [];

                    rs.body.results.forEach(r => {
                        const { manager, managerUsername, ...data } = r;
                        if (manager) {
                            users.push({ id: manager, username: managerUsername });
                            if (ids.indexOf(data.id) < 0) {
                                ids.push(data.id);
                            }
                        }
                        domains.push({
                            ...data,
                            manager
                        });
                    });
                    const instanses = userName !== 'undefined' ? ids : instanceManager.getInstances(brands, locales).map(i => i.buildId()).toArray();

                    return [
                        OrmStory.populateDomainDetail(domains),
                        OrmStory.populateUsers(users),
                        DomainDetailStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        DomainDetailStory.importInstancesOnSearchPage(instanses),
                        DomainDetailStory.updateSearchLockOnSearchPage(false),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_USER_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.domainDetailModule;
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(DomainDetailStory.updateSearchUserLockEditPage(true)), http.post('domain-detail/search-users', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    DomainDetailStory.updateSearchUserLockEditPage(false),
                    OrmStory.populateUsers(rs.body.results),
                    DomainDetailStory.updateUsersEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),

    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_EMAIL_TEMPLATE_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.domainDetailModule;
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    showDeactivated: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(DomainDetailStory.updateSearchEmailTemplateLockEditPage(true)), http.post('domain-detail/search-email-templates', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    DomainDetailStory.updateSearchEmailTemplateLockEditPage(false),
                    OrmStory.populateEmailTemplates(rs.body.results),
                    DomainDetailStory.updateEmailTemplatesEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),

    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_SMS_TEMPLATE_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.domainDetailModule;
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(DomainDetailStory.updateSearchSmsTemplateLockEditPage(true)), http.post('domain-detail/search-sms-templates', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ISmsTemplateData>>>) => [
                    DomainDetailStory.updateSearchSmsTemplateLockEditPage(false),
                    OrmStory.populateSmsTemplates(rs.body.results),
                    DomainDetailStory.updateSmsTemplatesEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),

    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_QUESTIONNAIRE_TEMPLATE_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const id = state.value.domainDetailModule.get('editPageRecordId');
            const module = state.value.domainDetailModule;
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    brands: [instanceManager.parseId(id)[0]],
                    locales: [instanceManager.parseId(id)[1]],
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };
            const params = instanceManager.buildHeaders(instanceManager.parseId(module.get('editPageRecordId')));

            return concat(of(DomainDetailStory.updateSearchQuestionnaireTemplateLockEditPage(true)), http.post('domain-detail/search-questionnaires', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IQuestionnaireData>>>) => [
                    DomainDetailStory.updateSearchQuestionnaireTemplateLockEditPage(false),
                    OrmStory.populateQuestionnaires(rs.body.results),
                    DomainDetailStory.updateQuestionnairesTemplatesEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),

    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.domainDetailModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => http.get(`domain-detail/${ payload }`, instanceManager.buildHeaders(instanceManager.parseId(payload))).pipe(
            dispatchActions((rs: viewData) => {
                    const actions = [
                        DomainDetailStory.updateIdEditPage(payload),
                        // Reset prev state
                        DomainDetailStory.updateFormOnEditPage({ }),
                    ];
                    if (!rs.body) {
                        return actions;
                    }
                    if (rs.body.domainDetail) {
                        actions.push(OrmStory.populateDomainDetail([rs.body.domainDetail]));
                    }
                    if (rs.body.manager) {
                        actions.push(OrmStory.populateUsers([rs.body.manager]));
                    }
                    if (rs.body.emailTemplates && rs.body.emailTemplates.length) {
                        actions.push(OrmStory.populateEmailTemplates(rs.body.emailTemplates));
                    }
                    if (rs.body.smsTemplates && rs.body.smsTemplates.length) {
                        actions.push(OrmStory.populateSmsTemplates(rs.body.smsTemplates));
                    }
                    if (rs.body.questionnaireTemplates && rs.body.questionnaireTemplates.length) {
                        actions.push(OrmStory.populateQuestionnaires(rs.body.questionnaireTemplates));
                    }

                    return actions;
                },
                () => [
                    SystemStory.stopNavigate(DomainDetailEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['domain-detail'], { sharedLink: true })
                ]
            )
        ))
    ),
    action.pipe(
        ofType(DomainDetailStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.domainDetailModule;
            const id = module.get('editPageRecordId');
            const params = instanceManager.buildHeaders(instanceManager.parseId(id));

            const data = module.get('editPageForm');
            const rq = {
                domainDetail: {
                    brandName: data.getIn(['name']),
                    email: data.getIn(['email']),
                    fromName: data.getIn(['fromName']),
                    userId: data.getIn(['manager', 'id']),
                    primaryEmailTemplateId: data.getIn(['quoteTemplateFirst', 'id']),
                    secondaryEmailTemplateId: data.getIn(['quoteTemplateSecond', 'id']),
                    vamoosInitialTemplateId: data.getIn(['vamoosTemplateInitial', 'id']),
                    vamoosUpdateNotifyTemplateId: data.getIn(['vamoosTemplateUpdateNotify', 'id']),
                    primarySmsTemplateId: data.getIn(['quoteSmsTemplate', 'id']),
                    vamoosSmsInitialTemplateId: data.getIn(['vamoosSmsTemplateInitial', 'id']),
                    vamoosAlertTemplateId: data.getIn(['vamoosAlertTemplate', 'id']),
                    vamoosSmsUpdateNotifyTemplateId: data.getIn(['vamoosSmsTemplateUpdateNotify', 'id']),
                    primaryQuestionnaireTemplateId: data.getIn(['quoteQuestionnaire', 'id']),
                },
            };

            return concat(of(OpportunityStory.updateSaveLockEditPage(true)), http.put(`domain-detail/${ id }`, rq.domainDetail, params).pipe(
                dispatchActions((rs: Response<{ domainDetail: IDomainDetailData }>) => [
                    OrmStory.populateDomainDetail([rs.body.domainDetail]),
                    SystemStory.showNotification(SystemNotification.success('Success', `Record updated`)),
                    DomainDetailStory.updateSearchPageInitState(false)
                ])
            ));
        }),
    )
);
