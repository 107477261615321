import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'adm-page-not-found',
    templateUrl: './page-not-found.component.html',
    styles: [
        'html, body { height: 100% }'
    ],
    encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
