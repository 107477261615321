import { Map, Record } from 'immutable';

export interface IOrderData {
    /**
     * The key to be sorted by.
     */
    by: string | null;

    /**
     * The sorting direction. It can be either ASC or DESC.
     */
    isReverse: boolean | null;
}

/**
 * Pagination represents information relevant to pagination of data items.
 */
export class Order extends Record({ by: null, isReverse: null }) {

    by: string | null;

    isReverse: boolean | null;

    constructor(config?: Partial<IOrderData> | Map<string, any> | null) {
        super(config);
    }

    with(values: any): Order {
        return this.merge(values) as this;
    }
}
