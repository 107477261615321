import { CustomerStory } from '@aclass/admin/storage/actions';
import { ICustomerModuleState, INITIAL_CUSTOMER_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const customerModuleReducer = (state: TypedState<ICustomerModuleState> = INITIAL_CUSTOMER_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case CustomerStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case CustomerStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case CustomerStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case CustomerStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case CustomerStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case CustomerStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case CustomerStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case CustomerStory.SEARCH_PAGE_UPDATE_SELECTED_CUSTOMERS:
            return state.update('searchPageSelectedCustomers', () => List(payload));
        case CustomerStory.EDIT_PAGE_UPDATE_CUSTOMER_ID:
            return state.update('editPageRecordId', () => payload);
        case CustomerStory.EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS:
            return state.update('editPageStackViewSegments', () => payload ? Map(payload) : null);
        case CustomerStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case CustomerStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK:
            return state.update('editPageUploadDocumentsLock', () => payload);
        case CustomerStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT:
            return state.update('editPagePickedIdDocumentLock', () => payload);
        case CustomerStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case CustomerStory.EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK:
            return state.update('editPageCountrySearchLock', () => payload);
        case CustomerStory.EDIT_PAGE_UPDATE_COUNTRIES:
            return state.update('editPageCountries', () => List(payload));
        case CustomerStory.EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID:
            return state.update('editPageCustomerMergerId', () => payload);
        case CustomerStory.SEND_EMAIL_UPDATE_OPENED:
            return state.update('sendEmailOpened', () => payload);
        case CustomerStory.SEND_EMAIL_UPDATE_TEMPLATES:
            return state.update('sendEmailTemplateIds', () => List(payload));
        case CustomerStory.SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK:
            return state.update('sendEmailPageEmailTemplateSearchLock', () => payload);
        case CustomerStory.SEND_EMAIL_UPDATE_MESSAGE_ID:
            return state.update('sendEmailMessageId', () => payload);
        case CustomerStory.SEND_EMAIL_UPDATE_DOCUMENTS:
            return state.update('sendEmailDocumentIds', () => List(payload));
        case CustomerStory.SEND_EMAIL_ADD_DOCUMENT:
            return state.update('sendEmailDocumentIds', (records: List<string>) => records.push(payload));
        default:
            return state;
    }
};
