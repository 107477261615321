/**
 * Creates a hash based on timestamp + random digits
 */
export function hashed(): string {
    const timestamp = (new Date()).valueOf();
    // 6 random digits
    const digits = Math.random().toString().substring(2, 8);

    return parseFloat(`${timestamp}${digits}`).toString(16);
}
