import { OpportunityStory } from '@aclass/admin/storage/actions';
import { INITIAL_OPPORTUNITY_STATE, IOpportunityModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const opportunityModuleReducer = (state: TypedState<IOpportunityModuleState> = INITIAL_OPPORTUNITY_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case OpportunityStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case OpportunityStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case OpportunityStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case OpportunityStory.SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK:
            return state.update('searchPagePackageSearchLock', () => payload);
        case OpportunityStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK:
            return state.update('searchPagePipelineLevelSearchLock', () => payload);
        case OpportunityStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case OpportunityStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVELS:
            return state.update('searchPagePipelineLevels', () => List(payload));
        case OpportunityStory.SEARCH_PAGE_UPDATE_ASSIGNED:
            return state.update('searchPageAssigned', () => List(payload));
        case OpportunityStory.SEARCH_PAGE_UPDATE_PACKAGES:
            return state.update('searchPagePackages', () => List(payload));
        case OpportunityStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case OpportunityStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case OpportunityStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_OPPORTUNITY_ID:
            return state.update('editPageRecordId', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_DELIVERY_META:
            return state.update('editPageDeliveryMeta', () => payload ? Map(payload) : null);
        case OpportunityStory.EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS:
            return state.update('editPageStackViewSegments', segments => segments ? segments.merge(payload) : Map(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_USER_SEARCH_LOCK:
            return state.update('editPageUserSearchLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_USERS:
            return state.update('editPageUsers', () => List(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK:
            return state.update('editPagePipelineLevelSearchLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_PIPELINE_LEVELS:
            return state.update('editPagePipelineLevels', () => List(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK:
            return state.update('editPageCountrySearchLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_COUNTRIES:
            return state.update('editPageCountries', () => List(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK:
            return state.update('editPageUploadDocumentsLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT:
            return state.update('editPagePickedIdDocumentLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_DELIVERY_SAVE_LOCK:
            return state.update('editPageDeliverySaveLock', () => payload);
        case OpportunityStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case OpportunityStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_INIT_STATE:
            return state.update('editPageMergerInitialized', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_OPENED:
            return state.update('editPageMergerOpened', () => payload);
        case OpportunityStory.EDIT_PAGE_VAMOOS_FLIGHTS_RELOAD_SEARCH_LOCK:
            return state.update('editPageVamoosFlightsReloadLock', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM:
            return state.update('editPageMergerSearchForm', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_LOCK:
            return state.update('editPageMergerSearchLock', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_OPPORTUNITIES:
            return state.update('editPageMergerOpportunities', () => List(payload));
        case OpportunityStory.EDIT_PAGE_MERGER_ADD_OPPORTUNITY:
            return state.update('editPageMergerOpportunities', (records: List<number>) => records.push(payload));
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SELECTED_OPPORTUNITIES:
            return state.update('editPageMergerSelectedOpportunities', () => List(payload));
        case OpportunityStory.EDIT_PAGE_MERGER_UPDATE_FORGET_LOCK:
            return state.update('editPageMergerForgetLock', () => payload);
        case OpportunityStory.EDIT_PAGE_MERGER_ADD_SELECTED_OPPORTUNITY:
            return state.update('editPageMergerSelectedOpportunities', (records: List<number>) => records.push(payload));
        case OpportunityStory.EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID:
            return state.update('editPageCustomerMergerId', () => payload);
        case OpportunityStory.SEND_EMAIL_UPDATE_OPENED:
            return state.update('sendEmailOpened', () => payload);
        case OpportunityStory.SEND_EMAIL_UPDATE_OPENED_CALENDAR:
            return state.update('sendEmailOpenedCalendar', () => payload);
        case OpportunityStory.SEND_EMAIL_UPDATE_TEMPLATES:
            return state.update('sendEmailTemplateIds', () => List(payload));
        case OpportunityStory.SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK:
            return state.update('sendEmailPageEmailTemplateSearchLock', () => payload);
        case OpportunityStory.SEND_EMAIL_UPDATE_MESSAGE_ID:
            return state.update('sendEmailMessageId', () => payload);
        case OpportunityStory.SEND_EMAIL_UPDATE_DOCUMENTS:
            return state.update('sendEmailDocumentIds', () => List(payload));
        case OpportunityStory.SEND_EMAIL_ADD_DOCUMENT:
            return state.update('sendEmailDocumentIds', (records: List<string>) => records.push(payload));
        default:
            return state;
    }
};
