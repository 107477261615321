export const fontAweasomeOptions = [
    { id: 'fa-align-left', value: '&#xf036; fa-align-left' },
    { id: 'fa-align-right', value: '&#xf038; fa-align-right' },
    { id: 'fa-amazon', value: '&#xf270; fa-amazon' },
    { id: 'fa-ambulance', value: '&#xf0f9; fa-ambulance' },
    { id: 'fa-anchor', value: '&#xf13d; fa-anchor' },
    { id: 'fa-android', value: '&#xf17b; fa-android' },
    { id: 'fa-angellist', value: '&#xf209; fa-angellist' },
    { id: 'fa-angle-double-down', value: '&#xf103; fa-angle-double-down' },
    { id: 'fa-angle-double-left', value: '&#xf100; fa-angle-double-left' },
    { id: 'fa-angle-double-right', value: '&#xf101; fa-angle-double-right' },
    { id: 'fa-angle-double-up', value: '&#xf102; fa-angle-double-up' },
    { id: 'fa-angle-left', value: '&#xf104; fa-angle-left' },
    { id: 'fa-angle-right', value: '&#xf105; fa-angle-right' },
    { id: 'fa-angle-up', value: '&#xf106; fa-angle-up' },
    { id: 'fa-apple', value: '&#xf179; fa-apple' },
    { id: 'fa-archive', value: '&#xf187; fa-archive' },
    { id: 'fa-area-chart', value: '&#xf1fe; fa-area-chart' },
    { id: 'fa-arrow-circle-down', value: '&#xf0ab; fa-arrow-circle-down' },
    { id: 'fa-arrow-circle-left', value: '&#xf0a8; fa-arrow-circle-left' },
    { id: 'fa-arrow-circle-o-down', value: '&#xf01a; fa-arrow-circle-o-down' },
    { id: 'fa-arrow-circle-o-left', value: '&#xf190; fa-arrow-circle-o-left' },
    { id: 'fa-arrow-circle-o-right', value: '&#xf18e; fa-arrow-circle-o-right' },
    { id: 'fa-arrow-circle-o-up', value: '&#xf01b; fa-arrow-circle-o-up' },
    { id: 'fa-arrow-circle-right', value: '&#xf0a9; fa-arrow-circle-right' },
    { id: 'fa-arrow-circle-up', value: '&#xf0aa; fa-arrow-circle-up' },
    { id: 'fa-arrow-down', value: '&#xf063; fa-arrow-down' },
    { id: 'fa-arrow-left', value: '&#xf060; fa-arrow-left' },
    { id: 'fa-arrow-right', value: '&#xf061; fa-arrow-right' },
    { id: 'fa-arrow-up', value: '&#xf062; fa-arrow-up' },
    { id: 'fa-arrows', value: '&#xf047; fa-arrows' },
    { id: 'fa-arrows-alt', value: '&#xf0b2; fa-arrows-alt' },
    { id: 'fa-arrows-h', value: '&#xf07e; fa-arrows-h' },
    { id: 'fa-arrows-v', value: '&#xf07d; fa-arrows-v' },
    { id: 'fa-asterisk', value: '&#xf069; fa-asterisk' },
    { id: 'fa-at', value: '&#xf1fa; fa-at' },
    { id: 'fa-automobile', value: '&#xf1b9; fa-automobile' },
    { id: 'fa-backward', value: '&#xf04a; fa-backward' },
    { id: 'fa-balance-scale', value: '&#xf24e; fa-balance-scale' },
    { id: 'fa-ban', value: '&#xf05e; fa-ban' },
    { id: 'fa-bank', value: '&#xf19c; fa-bank' },
    { id: 'fa-bar-chart', value: '&#xf080; fa-bar-chart' },
    { id: 'fa-bar-chart-o', value: '&#xf080; fa-bar-chart-o' },
    { id: 'fa-battery-full', value: '&#xf240; fa-battery-full' },
    { id: 'fa-beer', value: '&#xf0fc; fa-beer' },
    { id: 'fa-behance', value: '&#xf1b4; fa-behance' },
    { id: 'fa-behance-square', value: '&#xf1b5; fa-behance-square' },
    { id: 'fa-bell', value: '&#xf0f3; fa-bell' },
    { id: 'fa-bell-o', value: '&#xf0a2; fa-bell-o' },
    { id: 'fa-bell-slash', value: '&#xf1f6; fa-bell-slash' },
    { id: 'fa-bell-slash-o', value: '&#xf1f7; fa-bell-slash-o' },
    { id: 'fa-bicycle', value: '&#xf206; fa-bicycle' },
    { id: 'fa-binoculars', value: '&#xf1e5; fa-binoculars' },
    { id: 'fa-birthday-cake', value: '&#xf1fd; fa-birthday-cake' },
    { id: 'fa-bitbucket', value: '&#xf171; fa-bitbucket' },
    { id: 'fa-bitbucket-square', value: '&#xf172; fa-bitbucket-square' },
    { id: 'fa-bitcoin', value: '&#xf15a; fa-bitcoin' },
    { id: 'fa-black-tie', value: '&#xf27e; fa-black-tie' },
    { id: 'fa-bold', value: '&#xf032; fa-bold' },
    { id: 'fa-bolt', value: '&#xf0e7; fa-bolt' },
    { id: 'fa-bomb', value: '&#xf1e2; fa-bomb' },
    { id: 'fa-book', value: '&#xf02d; fa-book' },
    { id: 'fa-bookmark', value: '&#xf02e; fa-bookmark' },
    { id: 'fa-bookmark-o', value: '&#xf097; fa-bookmark-o' },
    { id: 'fa-briefcase', value: '&#xf0b1; fa-briefcase' },
    { id: 'fa-btc', value: '&#xf15a; fa-btc' },
    { id: 'fa-bug', value: '&#xf188; fa-bug' },
    { id: 'fa-building', value: '&#xf1ad; fa-building' },
    { id: 'fa-building-o', value: '&#xf0f7; fa-building-o' },
    { id: 'fa-bullhorn', value: '&#xf0a1; fa-bullhorn' },
    { id: 'fa-bullseye', value: '&#xf140; fa-bullseye' },
    { id: 'fa-bus', value: '&#xf207; fa-bus' },
    { id: 'fa-cab', value: '&#xf1ba; fa-cab' },
    { id: 'fa-calendar', value: '&#xf073; fa-calendar' },
    { id: 'fa-camera', value: '&#xf030; fa-camera' },
    { id: 'fa-car', value: '&#xf1b9; fa-car' },
    { id: 'fa-caret-up', value: '&#xf0d8; fa-caret-up' },
    { id: 'fa-cart-plus', value: '&#xf217; fa-cart-plus' },
    { id: 'fa-cc', value: '&#xf20a; fa-cc' },
    { id: 'fa-cc-amex', value: '&#xf1f3; fa-cc-amex' },
    { id: 'fa-cc-jcb', value: '&#xf24b; fa-cc-jcb' },
    { id: 'fa-cc-paypal', value: '&#xf1f4; fa-cc-paypal' },
    { id: 'fa-cc-stripe', value: '&#xf1f5; fa-cc-stripe' },
    { id: 'fa-cc-visa', value: '&#xf1f0; fa-cc-visa' },
    { id: 'fa-chain', value: '&#xf0c1; fa-chain' },
    { id: 'fa-check', value: '&#xf00c; fa-check' },
    { id: 'fa-chevron-left', value: '&#xf053; fa-chevron-left' },
    { id: 'fa-chevron-right', value: '&#xf054; fa-chevron-right' },
    { id: 'fa-chevron-up', value: '&#xf077; fa-chevron-up' },
    { id: 'fa-child', value: '&#xf1ae; fa-child' },
    { id: 'fa-chrome', value: '&#xf268; fa-chrome' },
    { id: 'fa-circle', value: '&#xf111; fa-circle' },
    { id: 'fa-circle-o', value: '&#xf10c; fa-circle-o' },
    { id: 'fa-circle-o-notch', value: '&#xf1ce; fa-circle-o-notch' },
    { id: 'fa-circle-thin', value: '&#xf1db; fa-circle-thin' },
    { id: 'fa-clipboard', value: '&#xf0ea; fa-clipboard' },
    { id: 'fa-clock-o', value: '&#xf017; fa-clock-o' },
    { id: 'fa-clone', value: '&#xf24d; fa-clone' },
    { id: 'fa-close', value: '&#xf00d; fa-close' },
    { id: 'fa-cloud', value: '&#xf0c2; fa-cloud' },
    { id: 'fa-cloud-download', value: '&#xf0ed; fa-cloud-download' },
    { id: 'fa-cloud-upload', value: '&#xf0ee; fa-cloud-upload' },
    { id: 'fa-cny', value: '&#xf157; fa-cny' },
    { id: 'fa-code', value: '&#xf121; fa-code' },
    { id: 'fa-code-fork', value: '&#xf126; fa-code-fork' },
    { id: 'fa-codepen', value: '&#xf1cb; fa-codepen' },
    { id: 'fa-coffee', value: '&#xf0f4; fa-coffee' },
    { id: 'fa-cog', value: '&#xf013; fa-cog' },
    { id: 'fa-cogs', value: '&#xf085; fa-cogs' },
    { id: 'fa-columns', value: '&#xf0db; fa-columns' },
    { id: 'fa-comment', value: '&#xf075; fa-comment' },
    { id: 'fa-comment-o', value: '&#xf0e5; fa-comment-o' },
    { id: 'fa-commenting', value: '&#xf27a; fa-commenting' },
    { id: 'fa-commenting-o', value: '&#xf27b; fa-commenting-o' },
    { id: 'fa-comments', value: '&#xf086; fa-comments' },
    { id: 'fa-comments-o', value: '&#xf0e6; fa-comments-o' },
    { id: 'fa-compass', value: '&#xf14e; fa-compass' },
    { id: 'fa-compress', value: '&#xf066; fa-compress' },
    { id: 'fa-connectdevelop', value: '&#xf20e; fa-connectdevelop' },
    { id: 'fa-contao', value: '&#xf26d; fa-contao' },
    { id: 'fa-copy', value: '&#xf0c5; fa-copy' },
    { id: 'fa-copyright', value: '&#xf1f9; fa-copyright' },
    { id: 'fa-creative-commons', value: '&#xf25e; fa-creative-commons' },
    { id: 'fa-credit-card', value: '&#xf09d; fa-credit-card' },
    { id: 'fa-crop', value: '&#xf125; fa-crop' },
    { id: 'fa-crosshairs', value: '&#xf05b; fa-crosshairs' },
    { id: 'fa-css3', value: '&#xf13c; fa-css3' },
    { id: 'fa-cube', value: '&#xf1b2; fa-cube' },
    { id: 'fa-cubes', value: '&#xf1b3; fa-cubes' },
    { id: 'fa-cut', value: '&#xf0c4; fa-cut' },
    { id: 'fa-cutlery', value: '&#xf0f5; fa-cutlery' },
    { id: 'fa-dashboard', value: '&#xf0e4; fa-dashboard' },
    { id: 'fa-dashcube', value: '&#xf210; fa-dashcube' },
    { id: 'fa-database', value: '&#xf1c0; fa-database' },
    { id: 'fa-dedent', value: '&#xf03b; fa-dedent' },
    { id: 'fa-delicious', value: '&#xf1a5; fa-delicious' },
    { id: 'fa-desktop', value: '&#xf108; fa-desktop' },
    { id: 'fa-deviantart', value: '&#xf1bd; fa-deviantart' },
    { id: 'fa-diamond', value: '&#xf219; fa-diamond' },
    { id: 'fa-digg', value: '&#xf1a6; fa-digg' },
    { id: 'fa-dollar', value: '&#xf155; fa-dollar' },
    { id: 'fa-download', value: '&#xf019; fa-download' },
    { id: 'fa-dribbble', value: '&#xf17d; fa-dribbble' },
    { id: 'fa-dropbox', value: '&#xf16b; fa-dropbox' },
    { id: 'fa-drupal', value: '&#xf1a9; fa-drupal' },
    { id: 'fa-edit', value: '&#xf044; fa-edit' },
    { id: 'fa-eject', value: '&#xf052; fa-eject' },
    { id: 'fa-ellipsis-h', value: '&#xf141; fa-ellipsis-h' },
    { id: 'fa-ellipsis-v', value: '&#xf142; fa-ellipsis-v' },
    { id: 'fa-empire', value: '&#xf1d1; fa-empire' },
    { id: 'fa-envelope', value: '&#xf0e0; fa-envelope' },
    { id: 'fa-envelope-o', value: '&#xf003; fa-envelope-o' },
    { id: 'fa-eur', value: '&#xf153; fa-eur' },
    { id: 'fa-euro', value: '&#xf153; fa-euro' },
    { id: 'fa-exchange', value: '&#xf0ec; fa-exchange' },
    { id: 'fa-exclamation', value: '&#xf12a; fa-exclamation' },
    { id: 'fa-exclamation-circle', value: '&#xf06a; fa-exclamation-circle' },
    { id: 'fa-exclamation-triangle', value: '&#xf071; fa-exclamation-triangle' },
    { id: 'fa-expand', value: '&#xf065; fa-expand' },
    { id: 'fa-expeditedssl', value: '&#xf23e; fa-expeditedssl' },
    { id: 'fa-external-link', value: '&#xf08e; fa-external-link' },
    { id: 'fa-external-link-square', value: '&#xf14c; fa-external-link-square' },
    { id: 'fa-eye', value: '&#xf06e; fa-eye' },
    { id: 'fa-eye-slash', value: '&#xf070; fa-eye-slash' },
    { id: 'fa-eyedropper', value: '&#xf1fb; fa-eyedropper' },
    { id: 'fa-facebook', value: '&#xf09a; fa-facebook' },
    { id: 'fa-facebook-f', value: '&#xf09a; fa-facebook-f' },
    { id: 'fa-facebook-official', value: '&#xf230; fa-facebook-official' },
    { id: 'fa-facebook-square', value: '&#xf082; fa-facebook-square' },
    { id: 'fa-fast-backward', value: '&#xf049; fa-fast-backward' },
    { id: 'fa-fast-forward', value: '&#xf050; fa-fast-forward' },
    { id: 'fa-fax', value: '&#xf1ac; fa-fax' },
    { id: 'fa-feed', value: '&#xf09e; fa-feed' },
    { id: 'fa-female', value: '&#xf182; fa-female' },
    { id: 'fa-fighter-jet', value: '&#xf0fb; fa-fighter-jet' },
    { id: 'fa-file', value: '&#xf15b; fa-file' },
    { id: 'fa-file-archive-o', value: '&#xf1c6; fa-file-archive-o' },
    { id: 'fa-file-audio-o', value: '&#xf1c7; fa-file-audio-o' },
    { id: 'fa-file-code-o', value: '&#xf1c9; fa-file-code-o' },
    { id: 'fa-file-excel-o', value: '&#xf1c3; fa-file-excel-o' },
    { id: 'fa-file-image-o', value: '&#xf1c5; fa-file-image-o' },
    { id: 'fa-file-movie-o', value: '&#xf1c8; fa-file-movie-o' },
    { id: 'fa-file-o', value: '&#xf016; fa-file-o' },
    { id: 'fa-file-pdf-o', value: '&#xf1c1; fa-file-pdf-o' },
    { id: 'fa-file-photo-o', value: '&#xf1c5; fa-file-photo-o' },
    { id: 'fa-file-picture-o', value: '&#xf1c5; fa-file-picture-o' },
    { id: 'fa-file-powerpoint-o', value: '&#xf1c4; fa-file-powerpoint-o' },
    { id: 'fa-file-sound-o', value: '&#xf1c7; fa-file-sound-o' },
    { id: 'fa-file-text', value: '&#xf15c; fa-file-text' },
    { id: 'fa-file-text-o', value: '&#xf0f6; fa-file-text-o' },
    { id: 'fa-file-video-o', value: '&#xf1c8; fa-file-video-o' },
    { id: 'fa-file-word-o', value: '&#xf1c2; fa-file-word-o' },
    { id: 'fa-file-zip-o', value: '&#xf1c6; fa-file-zip-o' },
    { id: 'fa-files-o', value: '&#xf0c5; fa-files-o' },
    { id: 'fa-film', value: '&#xf008; fa-film' },
    { id: 'fa-filter', value: '&#xf0b0; fa-filter' },
    { id: 'fa-fire', value: '&#xf06d; fa-fire' },
    { id: 'fa-fire-extinguisher', value: '&#xf134; fa-fire-extinguisher' },
    { id: 'fa-firefox', value: '&#xf269; fa-firefox' },
    { id: 'fa-flag', value: '&#xf024; fa-flag' },
    { id: 'fa-flag-checkered', value: '&#xf11e; fa-flag-checkered' },
    { id: 'fa-flag-o', value: '&#xf11d; fa-flag-o' },
    { id: 'fa-flash', value: '&#xf0e7; fa-flash' },
    { id: 'fa-flask', value: '&#xf0c3; fa-flask' },
    { id: 'fa-flickr', value: '&#xf16e; fa-flickr' },
    { id: 'fa-floppy-o', value: '&#xf0c7; fa-floppy-o' },
    { id: 'fa-folder', value: '&#xf07b; fa-folder' },
    { id: 'fa-folder-o', value: '&#xf114; fa-folder-o' },
    { id: 'fa-folder-open', value: '&#xf07c; fa-folder-open' },
    { id: 'fa-folder-open-o', value: '&#xf115; fa-folder-open-o' },
    { id: 'fa-font', value: '&#xf031; fa-font' },
    { id: 'fa-fonticons', value: '&#xf280; fa-fonticons' },
    { id: 'fa-forumbee', value: '&#xf211; fa-forumbee' },
    { id: 'fa-forward', value: '&#xf04e; fa-forward' },
    { id: 'fa-foursquare', value: '&#xf180; fa-foursquare' },
    { id: 'fa-frown-o', value: '&#xf119; fa-frown-o' },
    { id: 'fa-futbol-o', value: '&#xf1e3; fa-futbol-o' },
    { id: 'fa-gamepad', value: '&#xf11b; fa-gamepad' },
    { id: 'fa-gavel', value: '&#xf0e3; fa-gavel' },
    { id: 'fa-gbp', value: '&#xf154; fa-gbp' },
    { id: 'fa-ge', value: '&#xf1d1; fa-ge' },
    { id: 'fa-gear', value: '&#xf013; fa-gear' },
    { id: 'fa-gears', value: '&#xf085; fa-gears' },
    { id: 'fa-genderless', value: '&#xf22d; fa-genderless' },
    { id: 'fa-get-pocket', value: '&#xf265; fa-get-pocket' },
    { id: 'fa-gg', value: '&#xf260; fa-gg' },
    { id: 'fa-gg-circle', value: '&#xf261; fa-gg-circle' },
    { id: 'fa-gift', value: '&#xf06b; fa-gift' },
    { id: 'fa-git', value: '&#xf1d3; fa-git' },
    { id: 'fa-git-square', value: '&#xf1d2; fa-git-square' },
    { id: 'fa-github', value: '&#xf09b; fa-github' },
    { id: 'fa-github-alt', value: '&#xf113; fa-github-alt' },
    { id: 'fa-github-square', value: '&#xf092; fa-github-square' },
    { id: 'fa-gittip', value: '&#xf184; fa-gittip' },
    { id: 'fa-glass', value: '&#xf000; fa-glass' },
    { id: 'fa-globe', value: '&#xf0ac; fa-globe' },
    { id: 'fa-google', value: '&#xf1a0; fa-google' },
    { id: 'fa-google-plus', value: '&#xf0d5; fa-google-plus' },
    { id: 'fa-google-plus-square', value: '&#xf0d4; fa-google-plus-square' },
    { id: 'fa-google-wallet', value: '&#xf1ee; fa-google-wallet' },
    { id: 'fa-graduation-cap', value: '&#xf19d; fa-graduation-cap' },
    { id: 'fa-gratipay', value: '&#xf184; fa-gratipay' },
    { id: 'fa-group', value: '&#xf0c0; fa-group' },
    { id: 'fa-h-square', value: '&#xf0fd; fa-h-square' },
    { id: 'fa-hacker-news', value: '&#xf1d4; fa-hacker-news' },
    { id: 'fa-hand-grab-o', value: '&#xf255; fa-hand-grab-o' },
    { id: 'fa-hand-lizard-o', value: '&#xf258; fa-hand-lizard-o' },
    { id: 'fa-hand-o-down', value: '&#xf0a7; fa-hand-o-down' },
    { id: 'fa-hand-o-left', value: '&#xf0a5; fa-hand-o-left' },
    { id: 'fa-hand-o-right', value: '&#xf0a4; fa-hand-o-right' },
    { id: 'fa-hand-o-up', value: '&#xf0a6; fa-hand-o-up' },
    { id: 'fa-hand-paper-o', value: '&#xf256; fa-hand-paper-o' },
    { id: 'fa-hand-peace-o', value: '&#xf25b; fa-hand-peace-o' },
    { id: 'fa-hand-pointer-o', value: '&#xf25a; fa-hand-pointer-o' },
    { id: 'fa-hand-rock-o', value: '&#xf255; fa-hand-rock-o' },
    { id: 'fa-hand-scissors-o', value: '&#xf257; fa-hand-scissors-o' },
    { id: 'fa-hand-spock-o', value: '&#xf259; fa-hand-spock-o' },
    { id: 'fa-hand-stop-o', value: '&#xf256; fa-hand-stop-o' },
    { id: 'fa-hdd-o', value: '&#xf0a0; fa-hdd-o' },
    { id: 'fa-header', value: '&#xf1dc; fa-header' },
    { id: 'fa-headphones', value: '&#xf025; fa-headphones' },
    { id: 'fa-heart', value: '&#xf004; fa-heart' },
    { id: 'fa-heart-o', value: '&#xf08a; fa-heart-o' },
    { id: 'fa-heartbeat', value: '&#xf21e; fa-heartbeat' },
    { id: 'fa-history', value: '&#xf1da; fa-history' },
    { id: 'fa-home', value: '&#xf015; fa-home' },
    { id: 'fa-hospital-o', value: '&#xf0f8; fa-hospital-o' },
    { id: 'fa-hotel', value: '&#xf236; fa-hotel' },
    { id: 'fa-hourglass', value: '&#xf254; fa-hourglass' },
    { id: 'fa-hourglass-1', value: '&#xf251; fa-hourglass-1' },
    { id: 'fa-hourglass-2', value: '&#xf252; fa-hourglass-2' },
    { id: 'fa-hourglass-3', value: '&#xf253; fa-hourglass-3' },
    { id: 'fa-hourglass-end', value: '&#xf253; fa-hourglass-end' },
    { id: 'fa-hourglass-half', value: '&#xf252; fa-hourglass-half' },
    { id: 'fa-hourglass-o', value: '&#xf250; fa-hourglass-o' },
    { id: 'fa-hourglass-start', value: '&#xf251; fa-hourglass-start' },
    { id: 'fa-houzz', value: '&#xf27c; fa-houzz' },
    { id: 'fa-html5', value: '&#xf13b; fa-html5' },
    { id: 'fa-i-cursor', value: '&#xf246; fa-i-cursor' },
    { id: 'fa-ils', value: '&#xf20b; fa-ils' },
    { id: 'fa-image', value: '&#xf03e; fa-image' },
    { id: 'fa-inbox', value: '&#xf01c; fa-inbox' },
    { id: 'fa-indent', value: '&#xf03c; fa-indent' },
    { id: 'fa-industry', value: '&#xf275; fa-industry' },
    { id: 'fa-info', value: '&#xf129; fa-info' },
    { id: 'fa-info-circle', value: '&#xf05a; fa-info-circle' },
    { id: 'fa-inr', value: '&#xf156; fa-inr' },
    { id: 'fa-instagram', value: '&#xf16d; fa-instagram' },
    { id: 'fa-institution', value: '&#xf19c; fa-institution' },
    { id: 'fa-internet-explorer', value: '&#xf26b; fa-internet-explorer' },
    { id: 'fa-intersex', value: '&#xf224; fa-intersex' },
    { id: 'fa-ioxhost', value: '&#xf208; fa-ioxhost' },
    { id: 'fa-italic', value: '&#xf033; fa-italic' },
    { id: 'fa-joomla', value: '&#xf1aa; fa-joomla' },
    { id: 'fa-jpy', value: '&#xf157; fa-jpy' },
    { id: 'fa-jsfiddle', value: '&#xf1cc; fa-jsfiddle' },
    { id: 'fa-key', value: '&#xf084; fa-key' },
    { id: 'fa-keyboard-o', value: '&#xf11c; fa-keyboard-o' },
    { id: 'fa-krw', value: '&#xf159; fa-krw' },
    { id: 'fa-language', value: '&#xf1ab; fa-language' },
    { id: 'fa-laptop', value: '&#xf109; fa-laptop' },
    { id: 'fa-lastfm', value: '&#xf202; fa-lastfm' },
    { id: 'fa-lastfm-square', value: '&#xf203; fa-lastfm-square' },
    { id: 'fa-leaf', value: '&#xf06c; fa-leaf' },
    { id: 'fa-leanpub', value: '&#xf212; fa-leanpub' },
    { id: 'fa-legal', value: '&#xf0e3; fa-legal' },
    { id: 'fa-lemon-o', value: '&#xf094; fa-lemon-o' },
    { id: 'fa-level-down', value: '&#xf149; fa-level-down' },
    { id: 'fa-level-up', value: '&#xf148; fa-level-up' },
    { id: 'fa-life-bouy', value: '&#xf1cd; fa-life-bouy' },
    { id: 'fa-life-buoy', value: '&#xf1cd; fa-life-buoy' },
    { id: 'fa-life-ring', value: '&#xf1cd; fa-life-ring' },
    { id: 'fa-life-saver', value: '&#xf1cd; fa-life-saver' },
    { id: 'fa-lightbulb-o', value: '&#xf0eb; fa-lightbulb-o' },
    { id: 'fa-line-chart', value: '&#xf201; fa-line-chart' },
    { id: 'fa-link', value: '&#xf0c1; fa-link' },
    { id: 'fa-linkedin', value: '&#xf0e1; fa-linkedin' },
    { id: 'fa-linkedin-square', value: '&#xf08c; fa-linkedin-square' },
    { id: 'fa-linux', value: '&#xf17c; fa-linux' },
    { id: 'fa-list', value: '&#xf03a; fa-list' },
    { id: 'fa-list-alt', value: '&#xf022; fa-list-alt' },
    { id: 'fa-list-ol', value: '&#xf0cb; fa-list-ol' },
    { id: 'fa-list-ul', value: '&#xf0ca; fa-list-ul' },
    { id: 'fa-location-arrow', value: '&#xf124; fa-location-arrow' },
    { id: 'fa-lock', value: '&#xf023; fa-lock' },
    { id: 'fa-long-arrow-down', value: '&#xf175; fa-long-arrow-down' },
    { id: 'fa-long-arrow-left', value: '&#xf177; fa-long-arrow-left' },
    { id: 'fa-long-arrow-right', value: '&#xf178; fa-long-arrow-right' },
    { id: 'fa-long-arrow-up', value: '&#xf176; fa-long-arrow-up' },
    { id: 'fa-magic', value: '&#xf0d0; fa-magic' },
    { id: 'fa-magnet', value: '&#xf076; fa-magnet' },
    { id: 'fa-mars-stroke-v', value: '&#xf22a; fa-mars-stroke-v' },
    { id: 'fa-maxcdn', value: '&#xf136; fa-maxcdn' },
    { id: 'fa-meanpath', value: '&#xf20c; fa-meanpath' },
    { id: 'fa-medium', value: '&#xf23a; fa-medium' },
    { id: 'fa-medkit', value: '&#xf0fa; fa-medkit' },
    { id: 'fa-meh-o', value: '&#xf11a; fa-meh-o' },
    { id: 'fa-mercury', value: '&#xf223; fa-mercury' },
    { id: 'fa-microphone', value: '&#xf130; fa-microphone' },
    { id: 'fa-mobile', value: '&#xf10b; fa-mobile' },
    { id: 'fa-motorcycle', value: '&#xf21c; fa-motorcycle' },
    { id: 'fa-mouse-pointer', value: '&#xf245; fa-mouse-pointer' },
    { id: 'fa-music', value: '&#xf001; fa-music' },
    { id: 'fa-navicon', value: '&#xf0c9; fa-navicon' },
    { id: 'fa-neuter', value: '&#xf22c; fa-neuter' },
    { id: 'fa-newspaper-o', value: '&#xf1ea; fa-newspaper-o' },
    { id: 'fa-opencart', value: '&#xf23d; fa-opencart' },
    { id: 'fa-openid', value: '&#xf19b; fa-openid' },
    { id: 'fa-opera', value: '&#xf26a; fa-opera' },
    { id: 'fa-outdent', value: '&#xf03b; fa-outdent' },
    { id: 'fa-pagelines', value: '&#xf18c; fa-pagelines' },
    { id: 'fa-paper-plane-o', value: '&#xf1d9; fa-paper-plane-o' },
    { id: 'fa-paperclip', value: '&#xf0c6; fa-paperclip' },
    { id: 'fa-paragraph', value: '&#xf1dd; fa-paragraph' },
    { id: 'fa-paste', value: '&#xf0ea; fa-paste' },
    { id: 'fa-pause', value: '&#xf04c; fa-pause' },
    { id: 'fa-paw', value: '&#xf1b0; fa-paw' },
    { id: 'fa-paypal', value: '&#xf1ed; fa-paypal' },
    { id: 'fa-pencil', value: '&#xf040; fa-pencil' },
    { id: 'fa-pencil-square-o', value: '&#xf044; fa-pencil-square-o' },
    { id: 'fa-phone', value: '&#xf095; fa-phone' },
    { id: 'fa-photo', value: '&#xf03e; fa-photo' },
    { id: 'fa-picture-o', value: '&#xf03e; fa-picture-o' },
    { id: 'fa-pie-chart', value: '&#xf200; fa-pie-chart' },
    { id: 'fa-pied-piper', value: '&#xf1a7; fa-pied-piper' },
    { id: 'fa-pied-piper-alt', value: '&#xf1a8; fa-pied-piper-alt' },
    { id: 'fa-pinterest', value: '&#xf0d2; fa-pinterest' },
    { id: 'fa-pinterest-p', value: '&#xf231; fa-pinterest-p' },
    { id: 'fa-pinterest-square', value: '&#xf0d3; fa-pinterest-square' },
    { id: 'fa-plane', value: '&#xf072; fa-plane' },
    { id: 'fa-play', value: '&#xf04b; fa-play' },
    { id: 'fa-play-circle', value: '&#xf144; fa-play-circle' },
    { id: 'fa-play-circle-o', value: '&#xf01d; fa-play-circle-o' },
    { id: 'fa-plug', value: '&#xf1e6; fa-plug' },
    { id: 'fa-plus', value: '&#xf067; fa-plus' },
    { id: 'fa-plus-circle', value: '&#xf055; fa-plus-circle' },
    { id: 'fa-plus-square', value: '&#xf0fe; fa-plus-square' },
    { id: 'fa-plus-square-o', value: '&#xf196; fa-plus-square-o' },
    { id: 'fa-power-off', value: '&#xf011; fa-power-off' },
    { id: 'fa-print', value: '&#xf02f; fa-print' },
    { id: 'fa-puzzle-piece', value: '&#xf12e; fa-puzzle-piece' },
    { id: 'fa-qq', value: '&#xf1d6; fa-qq' },
    { id: 'fa-qrcode', value: '&#xf029; fa-qrcode' },
    { id: 'fa-question', value: '&#xf128; fa-question' },
    { id: 'fa-question-circle', value: '&#xf059; fa-question-circle' },
    { id: 'fa-quote-left', value: '&#xf10d; fa-quote-left' },
    { id: 'fa-quote-right', value: '&#xf10e; fa-quote-right' },
    { id: 'fa-ra', value: '&#xf1d0; fa-ra' },
    { id: 'fa-random', value: '&#xf074; fa-random' },
    { id: 'fa-rebel', value: '&#xf1d0; fa-rebel' },
    { id: 'fa-recycle', value: '&#xf1b8; fa-recycle' },
    { id: 'fa-reddit', value: '&#xf1a1; fa-reddit' },
    { id: 'fa-reddit-square', value: '&#xf1a2; fa-reddit-square' },
    { id: 'fa-refresh', value: '&#xf021; fa-refresh' },
    { id: 'fa-registered', value: '&#xf25d; fa-registered' },
    { id: 'fa-remove', value: '&#xf00d; fa-remove' },
    { id: 'fa-renren', value: '&#xf18b; fa-renren' },
    { id: 'fa-reorder', value: '&#xf0c9; fa-reorder' },
    { id: 'fa-repeat', value: '&#xf01e; fa-repeat' },
    { id: 'fa-reply', value: '&#xf112; fa-reply' },
    { id: 'fa-reply-all', value: '&#xf122; fa-reply-all' },
    { id: 'fa-retweet', value: '&#xf079; fa-retweet' },
    { id: 'fa-rmb', value: '&#xf157; fa-rmb' },
    { id: 'fa-road', value: '&#xf018; fa-road' },
    { id: 'fa-rocket', value: '&#xf135; fa-rocket' },
    { id: 'fa-rotate-left', value: '&#xf0e2; fa-rotate-left' },
    { id: 'fa-rotate-right', value: '&#xf01e; fa-rotate-right' },
    { id: 'fa-rouble', value: '&#xf158; fa-rouble' },
    { id: 'fa-rss', value: '&#xf09e; fa-rss' },
    { id: 'fa-rss-square', value: '&#xf143; fa-rss-square' },
    { id: 'fa-rub', value: '&#xf158; fa-rub' },
    { id: 'fa-ruble', value: '&#xf158; fa-ruble' },
    { id: 'fa-rupee', value: '&#xf156; fa-rupee' },
    { id: 'fa-safari', value: '&#xf267; fa-safari' },
    { id: 'fa-sliders', value: '&#xf1de; fa-sliders' },
    { id: 'fa-slideshare', value: '&#xf1e7; fa-slideshare' },
    { id: 'fa-smile-o', value: '&#xf118; fa-smile-o' },
    { id: 'fa-sort-asc', value: '&#xf0de; fa-sort-asc' },
    { id: 'fa-sort-desc', value: '&#xf0dd; fa-sort-desc' },
    { id: 'fa-sort-down', value: '&#xf0dd; fa-sort-down' },
    { id: 'fa-spinner', value: '&#xf110; fa-spinner' },
    { id: 'fa-spoon', value: '&#xf1b1; fa-spoon' },
    { id: 'fa-spotify', value: '&#xf1bc; fa-spotify' },
    { id: 'fa-square', value: '&#xf0c8; fa-square' },
    { id: 'fa-square-o', value: '&#xf096; fa-square-o' },
    { id: 'fa-star', value: '&#xf005; fa-star' },
    { id: 'fa-star-half', value: '&#xf089; fa-star-half' },
    { id: 'fa-stop', value: '&#xf04d; fa-stop' },
    { id: 'fa-subscript', value: '&#xf12c; fa-subscript' },
    { id: 'fa-tablet', value: '&#xf10a; fa-tablet' },
    { id: 'fa-tachometer', value: '&#xf0e4; fa-tachometer' },
    { id: 'fa-tag', value: '&#xf02b; fa-tag' },
    { id: 'fa-tags', value: '&#xf02c; fa-tags' },
];
