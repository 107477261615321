import { DestinationRevision } from '@aclass/admin/storage/models/destination.revision';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDestinationToTourRevisionMappingData {

    readonly id: string | null;

    readonly tourRevisionId: string | null;

    readonly destinationRevisionId: string | null;
}

@Model({
    name: 'DestinationToTourRevisionMapping'
})
export class DestinationToTourRevisionMapping extends Entity<IDestinationToTourRevisionMappingData> {

    @Attr() readonly id: string | null;

    @Fk({ to: 'TourRevision', relatedName: 'relatedDestinationToTourRevisionMappings' }) readonly tourRevisionId: TourRevision | null;

    @Fk({ to: 'DestinationRevision', relatedName: 'relatedDestinationToTourRevisionMappings' }) readonly destinationRevisionId: DestinationRevision | null;
}

