import { InternationalizationShowStateResolver } from '@aclass/admin/resolvers';
import {
    InternationalizationStory,
    OrmStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { ISourceMessageData, ITranslationMessageData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const internationalizationModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(InternationalizationStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.internationalizationModule.get('searchPageInitialized') || state.value.systemModule.get('application') !== state.value.internationalizationModule.get('category')),
        switchMap(() => {
            return [
                InternationalizationStory.updateCategory(state.value.systemModule.get('application')),
                // Reset prev records state
                InternationalizationStory.updateFormSearchPage({ }),
                InternationalizationStory.importRecordsOnSearchPage([]),
                InternationalizationStory.updatePaginationOnSearchPage(null),
                InternationalizationStory.updateOrderOnSearchPage(null),
                InternationalizationStory.updateSearchLockOnSearchPage(null),
                InternationalizationStory.updateCollapsedSearchPage(null),
                // Unlock page
                InternationalizationStory.updateSearchPageInitState(true),
            ];
        })
    ),
    action.pipe(
        ofType(InternationalizationStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.internationalizationModule;
            const form = module.get('searchPageForm');
            const rq: DataSearchRqData = {
                where: {
                    message: form.get('message'),
                    description: form.get('description'),
                    category: module.get('category')
                },
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(InternationalizationStory.updateSearchLockOnSearchPage(true)), http.post('i18n', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ISourceMessageData>>>) => {
                    return [
                        OrmStory.populateSourceMessages(rs.body.results),
                        InternationalizationStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        InternationalizationStory.updatePaginationOnSearchPage(rs.body.pagination),
                        InternationalizationStory.updateOrderOnSearchPage(rs.body.order),
                        InternationalizationStory.updateSearchLockOnSearchPage(false),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(InternationalizationStory.SHOW_PAGE_DRY_RUN),
        filter(({ payload }) => state.value.internationalizationModule.get('showPageRecordId') !== payload),
        mergeMap(({ payload }: Action<number>) => http.get(`i18n/view/${payload}`).pipe(
            dispatchActions(
                (rs: Response<{ source: ISourceMessageData, messages: Array<ITranslationMessageData> }>) => {
                    return [
                        OrmStory.populateSourceMessages([rs.body.source]),
                        OrmStory.populateTranslatedMessages(rs.body.messages),
                        InternationalizationStory.updateMessageIdShowPage(payload),
                        InternationalizationStory.updateFormOnShowPage({ }),
                    ];
                },
                () => {
                    return [
                        SystemStory.stopNavigate(InternationalizationShowStateResolver.STOP_EVENT_NAME),
                        SystemStory.navigate(['i18n', 'search'], { sharedLink: true })
                    ];
                }
            )
        )),
    ),
    action.pipe(
        ofType(InternationalizationStory.SHOW_PAGE_SUBMIT),
        mergeMap(() => {
            const id = state.value.internationalizationModule.get('showPageRecordId');

            return http.put(`i18n/${id}`, state.value.internationalizationModule.get('showPageForm')).pipe(
                dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Message updated`))
                ])
            );
        }),
    )
);
