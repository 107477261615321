import { fromJS, Iterable, Map } from 'immutable';

export interface TypedState<T> {

    get<K extends keyof T, V = any>(key: K, notSetValue?: any): T[K];

    getIn<V = any>(searchKeyPath: Array<any> | Iterable<any, any>, notSetValue?: any): V;

    set<K extends keyof T, V>(key: K, value: any): Map<K, V>;

    setIn<K, V>(keyPath: Array<any> | Iterable<any, any>, value: any): Map<K, V>;

    update<K extends keyof T, V>(updater: (value: T[K]) => V): Map<K, V>;

    update<K extends keyof T, V>(key: K, updater: (value: T[K]) => V): Map<K, V>;

    update<K extends keyof T, V>(key: K, notSetValue: V, updater: (value: T[K]) => V): Map<K, V>;

    updateIn<K, V>(
        keyPath: Array<any> | Iterable<any, any>,
        updater: (value: any) => any,
    ): Map<K, V>;

    updateIn<K, V>(
        keyPath: Array<any> | Iterable<any, any>,
        notSetValue: any,
        updater: (value: any) => any,
    ): Map<K, V>;

    hasIn(searchKeyPath: Array<any> | Iterable<any, any>): boolean;

    toJS(): T;
}

export function createTypedState<T>(v: { [P in keyof T]: any }) {
    return fromJS(v) as TypedState<T>;
}

export interface IModuleState {
    [key: string]: any;
}
