import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IRedirectData {

    readonly id: string | null;

    readonly source: string | null;

    readonly redirect: string | null;

    readonly permanent: boolean | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;
}

@Model({
    name: 'Redirect'
})
export class Redirect extends Entity<IRedirectData> {

    @Attr() readonly id: string | null;

    @Attr() readonly source: string | null;

    @Attr() readonly redirect: string | null;

    @Attr() readonly permanent: boolean | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;
}
