import { OrmStory, SplitTestStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { ISplitTestData } from '@aclass/admin/storage/models';
import { IAdminState, ISplitTestModuleState } from '@aclass/admin/storage/states';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const splitTestModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(SplitTestStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.splitTestModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            SplitTestStory.updateFormSearchPage({ }),
            SplitTestStory.importRecordsOnSearchPage([]),
            SplitTestStory.updateSearchLockOnSearchPage(null),
            SplitTestStory.updateCollapsedSearchPage(null),
            // Unlock page
            SplitTestStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(SplitTestStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.splitTestModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
            };

            return concat(of(SplitTestStory.updateSearchLockOnSearchPage(true)), http.post('split-tests/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ISplitTestData>>>) => [
                    OrmStory.populateSplitTests(rs.body.results),
                    SplitTestStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    SplitTestStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(SplitTestStory.VIEW_PAGE_DRY_RUN),
        filter(({ payload }: Action<ISplitTestModuleState['viewPageRecordId']>) => state.value.splitTestModule.get('viewPageRecordId') !== payload),
        mergeMap(({ payload }: Action<ISplitTestModuleState['viewPageRecordId']>) => [
            SplitTestStory.updateRecordIdViewPage(payload),
            SplitTestStory.updateSearchLockViewPage(null),
            SplitTestStory.updateSearchFormOnViewPage({ }),
        ]),
    ),
    action.pipe(
        ofType(SplitTestStory.VIEW_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.splitTestModule;
            const form = module.get('viewPageSearchForm').toJS();
            const brands = form.brands;
            const locales = form.locales;
            const createdAtStart = form.createdAt ? form.createdAt.from : null;
            const createdAtEnd = form.createdAt ? form.createdAt.to : null;
            const rq = {
                id: parseInt(module.get('viewPageRecordId')),
                params: {
                    brands,
                    locales,
                    createdAtStart,
                    createdAtEnd
                },
            };

            return concat(of(SplitTestStory.updateSearchLockViewPage(true)), http.post('split-tests/view', rq).pipe(
                dispatchActions((rs: Response<{ name: string, params: Array<number>}>) => [
                    SplitTestStory.updateTestNameViewPage(rs.body.name),
                    SplitTestStory.updateTestValueViewPage(rs.body.params),
                    SplitTestStory.updateSearchLockViewPage(false),
                ])
            ));
        }),
    ),
);
