import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ISplitTestData {

    readonly id: number | null;

    readonly name: string | null;

    readonly class: string | null;
}

@Model({
    name: 'SplitTest'
})
export class SplitTest extends Entity<ISplitTestData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly class: string | null;
}
