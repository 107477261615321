import { IDomainDetailData } from '@aclass/admin/storage/models';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'domain-detail'
})
export class DomainDetailStory {

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_MANAGER: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_DOMAIN_DETAIL_ID: string;

    @Task() static EDIT_PAGE_USER_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_USER_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_USERS: string;

    @Task() static EDIT_PAGE_UPDATE_EMAIL_TEMPLATE_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_EMAIL_TEMPLATE_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_EMAIL_TEMPLATES: string;

    @Task() static EDIT_PAGE_UPDATE_SMS_TEMPLATE_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_SMS_TEMPLATES: string;

    @Task() static EDIT_PAGE_SMS_TEMPLATE_SEARCH: string;

    @Task() static EDIT_PAGE_QUESTIONNAIRE_TEMPLATE_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATES: string;

    @Task() static EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATE_SEARCH_LOCK: string;

    static dryRunSearchPage(): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static importRecordsOnSearchPage(items: Array<IDomainDetailData['id']>): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_IMPORT_RECORDS, payload: items };
    }

    static importInstancesOnSearchPage(payload: Array<string>): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_IMPORT_INSTANCES, payload };
    }

    static submitOnSearchPage(): Action {
        return { type: DomainDetailStory.SEARCH_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static submitOnEditPage(): Action {
        return { type: DomainDetailStory.EDIT_PAGE_SUBMIT };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateIdEditPage(id: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_DOMAIN_DETAIL_ID, payload: id };
    }

    static updateSearchUserLockEditPage(v: boolean | null): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_USER_SEARCH_LOCK, payload: v };
    }

    static updateUsersEditPage(v: Array<number>): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_USERS, payload: v };
    }

    static searchUsersEditPage(v?: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_USER_SEARCH, payload: v };
    }

    static updateSearchEmailTemplateLockEditPage(v: boolean | null): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_EMAIL_TEMPLATE_SEARCH_LOCK, payload: v };
    }

    static searchEmailTemplatesEditPage(v?: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_EMAIL_TEMPLATE_SEARCH, payload: v };
    }

    static updateEmailTemplatesEditPage(v: Array<number>): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_EMAIL_TEMPLATES, payload: v };
    }

    static updateSearchSmsTemplateLockEditPage(v: boolean | null): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_SMS_TEMPLATE_SEARCH_LOCK, payload: v };
    }

    static updateSearchQuestionnaireTemplateLockEditPage(v: boolean | null): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATE_SEARCH_LOCK, payload: v };
    }

    static searchSmsTemplatesEditPage(v?: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_SMS_TEMPLATE_SEARCH, payload: v };
    }

    static updateSmsTemplatesEditPage(v: Array<number>): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_SMS_TEMPLATES, payload: v };
    }

    static searchQuestionnairesTemplateEditPage(v?: string): Action {
        return { type: DomainDetailStory.EDIT_PAGE_QUESTIONNAIRE_TEMPLATE_SEARCH, payload: v };
    }

    static updateQuestionnairesTemplatesEditPage(v?:  Array<number>): Action {
        return { type: DomainDetailStory.EDIT_PAGE_UPDATE_QUESTIONNAIRE_TEMPLATES, payload: v };
    }
}
