import { IGalleryData } from '@aclass/admin/components';
import { FormManager, ModalManager } from '@aclass/admin/managers';
import { FileStory, SystemStory } from '@aclass/admin/storage/actions';
import { Image } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findByIds } from '@aclass/core/helpers/orm';
import { Pagination } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';
import { dispatch, NgRedux } from '@angular-redux/store';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { List } from 'immutable';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adm-image-gallery',
    templateUrl: './image-gallery.component.html',
})
export class ImageGalleryComponent implements OnInit, OnDestroy {

    images: Array<Image>;

    @ViewChild('container', { static: true }) container: ElementRef;

    editForm: FormGroup;

    private _hideBackground: boolean;

    private _loading: boolean;

    private _editLoading: boolean;

    private _uploadLoading: boolean;

    private _show: boolean;

    private _selected: number | null;

    private awaiter: EventEmitter<Image | undefined> | undefined;

    private subscriptions: Array<Subscription> = [];

    get session() {
        return orm.session(this.ngRdx.getState().orm);
    }

    get hideBackground(): boolean {
        return this._hideBackground;
    }

    get loading(): boolean {
        return this._loading;
    }

    get editLoading(): boolean {
        return this._editLoading;
    }

    get uploadLoading(): boolean {
        return this._uploadLoading;
    }

    /**
     * Short code to set trigger value
     */
    set show(v: boolean) {
        if (!v && this.awaiter) {
            this.destroy();
        }
        this._show = v;
    }

    /**
     * Short code to get trigger value
     */
    get show(): boolean {
        return this._show;
    }

    /**
     * Short code to set trigger value
     */
    set selected(v: number | null) {
        this.ngRdx.dispatch(FileStory.selectImageGallery(v));
    }

    /**
     * Short code to get trigger value
     */
    get selected(): number | null {
        return this._selected;
    }

    /**
     * Short code to get trigger value
     */
    get selectedImage(): Image | undefined {
        return this.images.find(r => r.id === this.selected);
    }

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private modalManager: ModalManager,
        private formManager: FormManager
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.images = [];
        this._hideBackground = false;
        this._loading = false;
        this._show = false;
        this._uploadLoading = false;
        this._editLoading = false;
        this.editForm = new FormGroup({
            id: new FormControl(null, [Validators.required]),
            alt: new FormControl(null, [Validators.maxLength(255)]),
            title: new FormControl(null, [Validators.maxLength(255)]),
        });
        type input = Action<{ listener: EventEmitter<Image | undefined>, params?: Partial<IGalleryData> }>;
        this.subscriptions.push(
            this.modalManager.modal
                .pipe(filter((v: input) => v.type === ModalManager.GALLERY_OPEN))
                .subscribe((v: input) => {
                    this._show = true;
                    this.awaiter = v.payload.listener;
                    const data = v.payload.params || { };
                    this._hideBackground = 'hideBackground' in data ? data.hideBackground : false;
                    if (!this.images.length) {
                        this.ngRdx.dispatch(FileStory.updatePaginationImageGallery({ pageSize: 6 * 4, totalCount: 0, page: 0 })); // 6 rows
                        this.search();
                    }
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['fileModule', 'searchLockImageGallery'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._loading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<List<string>>(['fileModule', 'searchResultsImageGallery'])
                .subscribe(ids => {
                    this.images = findByIds<Image>(this.session, Image, ids);
                })
        );
        this.subscriptions.push(
            this.ngRdx.select<number | null>(['fileModule', 'selectedImageGallery'])
                .subscribe(v => this._selected = v)
        );
        this.subscriptions.push(this.formManager.onReset.subscribe(() => {
            this.editForm.reset();
        }));
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['fileModule', 'uploadLockImageGallery'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._uploadLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['fileModule', 'uploadLockImageGallery'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._uploadLoading = v)
        );
        this.subscriptions.push(
            this.ngRdx.select<boolean | null>(['fileModule', 'editLockImageGallery'])
                .pipe(filter(v => v !== null))
                .subscribe(v => this._editLoading = v)
        );
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        if (this.awaiter) {
            this.destroy();
        }
    }

    @dispatch() search = () => FileStory.searchImageGallery();

    @dispatch() upload = (files: Array<File>) => FileStory.uploadImageGallery(files);

    @dispatch() save = () => FileStory.submitEditFormImageGallery();

    @dispatch() remove = () => FileStory.removeImageGallery(this.selected);

    /**
     * Searches for new records
     */
    runSearch() {
        if (this._loading) {
            return;
        }
        const pagination = new Pagination(this.ngRdx.getState().fileModule.get('searchPaginationImageGallery'));
        if (!pagination.hasNext()) {
            return;
        }
        const el = this.container.nativeElement;
        // tslint:disable-next-line
        if ((el.scrollTop + el.offsetHeight) < el.scrollHeight) {
            return;
        }
        this.ngRdx.dispatch(FileStory.updatePaginationImageGallery(pagination.next()));
        this.search();
    }

    /**
     * Saves image data
     */
    runSave() {
        if (!this.editForm.valid) {
            this.ngRdx.dispatch(SystemStory.showNotification(SystemNotification.error('Error', 'The image data is invalid')));

            return;
        }
        this.save();
    }

    /**
     * Toggles selected record
     */
    select(v: number) {
        if (this.selected === v) {
            this.selected = null;

            return;
        }
        this.selected = v;
    }

    /**
     * Emits selected record to listener
     */
    pick() {
        if (this.selected === null || !this.awaiter) {
            return;
        }
        this.destroy(this.selectedImage);
        this.show = false;
    }

    private destroy(img?: Image) {
        this.selected = null;
        this.awaiter.emit(img);
        // flush listener
        this.awaiter = undefined;
    }
}
