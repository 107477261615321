<clr-modal class="b-image-picker" [class.hide-backdrop]="hideBackground" [(clrModalOpen)]="show" [clrModalSize]="'xl'">
    <h3 class="modal-title">Select Image</h3>
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col-lg-9 clr-col-md-12" #container style="max-height: 640px; overflow-y: auto" (scroll)="runSearch()">
                <div class="clr-row">
                    <div class="clr-col-3" *ngFor="let i of images">
                        <a href="javascript://" class="card mt-0 mb-1 clickable card-img" [class.active]="selected === i.id" (click)="select(i.id)">
                            <div class="card-img">
                                <img [src]="i.url" [alt]="i.alt ? i.alt : ''" [title]="i.title ? i.title : ''"/>
                            </div>
                        </a>
                    </div>
                    <div class="clr-col text-center" [hidden]="!loading">
                        <span class="spinner"></span>
                    </div>
                </div>
            </div>
            <div class="clr-col-lg-3 clr-col-md-12">
                <form class="card mt-0 mb-1" [attach]="['fileModule', 'editFormImageGallery']" [formGroup]="editForm" novalidate (ngSubmit)="runSave()" *ngIf="selected">
                    <div class="card-img">
                        <img [src]="selectedImage.url">
                    </div>
                    <div class="card-block">
                        <div class="form-group">
                            <label>
                                Alt
                            </label>
                            <input type="text" class="form-control" formControlName="alt">
                        </div>
                        <div class="form-group">
                            <label>
                                Title
                            </label>
                            <input type="text" class="form-control" formControlName="title">
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="button" class="btn btn-warning-outline btn-sm" [clrLoading]="editLoading" (click)="remove()">Delete</button>
                        <button type="submit" class="btn btn-info-outline btn-sm" [clrLoading]="editLoading">Update</button>
                    </div>
                </form>
                <div class="text-center" *ngIf="!selected">
                    <adm-upload-button [loading]="uploadLoading" [sm]="false" (upload)="upload($event)">
                        <clr-icon shape="upload"></clr-icon>
                        Upload images
                    </adm-upload-button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info-outline" [ngClass]="{ disabled: !selected }" (click)="pick()">Select</button>
    </div>
</clr-modal>