import { SignatureStory } from '@aclass/admin/storage/actions';
import { INITIAL_SIGNATURE_STATE, ISignatureModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const signatureModuleReducer = (state: TypedState<ISignatureModuleState> = INITIAL_SIGNATURE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_INITIALIZED:
            return state.update('searchUsersPageInitialized', () => payload);
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_COLLAPSED:
            return state.update('searchUsersPageCollapsed', () => payload);
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_FORM:
            return state.update('searchUsersPageForm', () => payload);
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchUsersPageLock', () => payload);
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_PAGINATION:
            return state.update('searchUsersPagePagination', () => Map(payload));
        case SignatureStory.SEARCH_USER_PAGE_UPDATE_ORDER:
            return state.update('searchUsersPageOrder', () => Map(payload));
        case SignatureStory.SEARCH_USER_PAGE_IMPORT_RECORDS:
            return state.update('searchUsersPageResults', () => List(payload));
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_USER_ID:
            return state.update('signatureUserId', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_INITIALIZED:
            return state.update('searchPageInitialized', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case SignatureStory.SEARCH_SIGNATURE_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case SignatureStory.CREATE_PAGE_UPDATE_FORM:
            return state.update('createPageForm', () => fromJS(payload));
        case SignatureStory.EDIT_PAGE_UPDATE_SIGNATURE_ID:
            return state.update('editPageSignatureId', () => payload);
        case SignatureStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_BRAND_AND_LOCALE:
            return state.update('brandAndlocale', () => payload);
        case SignatureStory.SHOW_COPY_MODAL:
            return state.update('showCopyModal', () => payload);
        case SignatureStory.SEARCH_SIGNATURE_PAGE_UPDATE_COPY_MODAL_RESULT_DATA:
            return state.update('copyModalResultData', () => payload);
        case SignatureStory.UPDATE_CREATE_PAGE_ID:
            return state.update('createPageSignatureId', () => payload);
        case SignatureStory.EDIT_PAGE_UPDATE_SHOW_REMOVE_MODAL:
            return state.update('showRemoveModal', () => payload);
        case SignatureStory.EDIT_PAGE_UPDATE_REMOVE_MODAL_LOCK:
            return state.update('removeModalLock', () => payload);
        default:
            return state;
    }
};
