<form [formGroup]="form" novalidate>
<clr-wizard #wizard [(clrWizardOpen)]="show" clrWizardSize="lg" (clrWizardOnFinish)="runResolve()" (clrWizardOnCancel)="runResolve()">
    <clr-wizard-title>Contact support team</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!form.controls.subject.valid">
        <ng-template clrPageTitle>Describe problem or enter question</ng-template>
        <ng-template clrPageNavTitle>General</ng-template>
            <div class="form-group">
                <label>
                    Enter a subject<sup class="text-danger">*</sup>
                </label>
                <input type="text" class="form-control" formControlName="subject">
            </div>
            <div class="form-group">
                <label>
                    Enter a message
                </label>
                <textarea class="form-control" rows="7" formControlName="message"></textarea>
            </div>
    </clr-wizard-page>

    <clr-wizard-page (clrWizardPageCustomButton)="runSend()">
        <ng-template clrPageTitle>Attach extra files to report</ng-template>
        <ng-template clrPageNavTitle>Attachments</ng-template>
        <div class="form-group">
            <label>
                Enter attachments
            </label>
            <input type="file" class="form-control" formControlName="attachments" (change)="runUpload($event)" multiple>
        </div>
        <!--
        <div class="form-group">
            <label>
                Attach my application state(it helps to reproduce issue)
            </label>
            <br>
            <adm-checkbox-button formControlName="state"></adm-checkbox-button>
        </div>
        -->
        <ng-template clrPageButtons>
            <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
            <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
            <clr-wizard-button [type]="'custom'">Sent</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>
    <clr-wizard-page>
        <ng-template clrPageTitle>Resolution</ng-template>
        <div class="spinner" *ngIf="loading"></div>
            <p>{{ success ? getSuccessMessage() : 'Error while sending message' }}</p>
        <ng-template clrPageButtons>
            <clr-wizard-button [type]="'finish'">Close</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>
</clr-wizard>
</form>