import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'app'
})
export class AppStory {

    @Task() static AUTO_LOGIN: string;

    @Task() static SHOW_ERROR: string;

    @Task() static HIDE_ERROR: string;

    @Task() static LOGIN: string;

    @Task() static LOGIN_LOCK: string;

    @Task() static LOGOUT: string;

    static autoLogin(): Action {
        return { type: AppStory.AUTO_LOGIN };
    }

    static login(): Action {
        return { type: AppStory.LOGIN };
    }

    static updateLoginLock(v: boolean | null): Action {
        return { type: AppStory.LOGIN_LOCK, payload: v };
    }

    static logout(): Action {
        return { type: AppStory.LOGOUT };
    }

    static showError(message: string): Action {
        return { type: AppStory.SHOW_ERROR, payload: message };
    }

    static hideError(): Action {
        return { type: AppStory.HIDE_ERROR };
    }
}
