import { IRange } from '@aclass/core/components';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IColorRangeSettings extends IRange<number> {

}

export interface ISettingData<T> {

    /**
     * The setting name, ex: date_formatting, price_formatting
     * If option depends on instance: africa_uk_domain_detail
     */
    readonly id: string;

    readonly value: T;
}

@Model({
    name: 'Setting'
})
export class Setting<T> extends Entity<ISettingData<T>> {

    static TYPE_COLOR_RANGE_SCHEME = 'colorRangeScheme';

    @Attr() readonly id: string;

    @Attr() readonly value: T;
}
