import { Map, Record } from 'immutable';

export interface IInstanceData {

    brand: string;

    brandLabel: string;

    locale: string;

    domainConf: { scheme: string, host: string };
}

export type headlessInstance = [IInstanceData['brand'], IInstanceData['locale'], IInstanceData['brandLabel']];

/**
 * Instance is the class to encapsulate the bunch of brand, brandLabel, locale, domain
 */
export class Instance extends Record({ brand: null, locale: null, brandLabel: null, domainConf: Map({ scheme: null, host: null }) }) {

    static DELIMITER = '_';

    /**
     * The location of Africa
     */
    static BRAND_AFRICA = 'africa';

    /**
     * The location of Asia
     */
    static BRAND_ASIA = 'asia';

    /**
     * The location of Lama
     */
    static BRAND_LAMA = 'lama';

    /**
     * The location of Lama
     */
    static BRAND_AUSTRALIA = 'australia';

    /**
     * The location of North America
     */
    static BRAND_NORTH_AMERICA = 'namerica';

    /**
     * Locale code for uk instance
     */
    static LOCALE_UK = 'uk';

    /**
     * Locale code for de instance
     */
    static LOCALE_DE = 'de';

    /**
     * Locale code for dk instance
     */
    static LOCALE_DK = 'dk';

    /**
     * Locale code for se instance
     */
    static LOCALE_SE = 'se';

    /**
     * Locale code for no instance
     */
    static LOCALE_NO = 'no';

    /**
     * Locale code for nl instance
     */
    static LOCALE_NL = 'nl';

    /**
     * Locale code for fi instance
     */
    static LOCALE_FI = 'fi';

    /**
     * Locale code for fr instance
     */
    static LOCALE_FR = 'fr';

    static BRANDS = [
        Instance.BRAND_AFRICA,
        Instance.BRAND_ASIA,
        Instance.BRAND_LAMA,
        Instance.BRAND_AUSTRALIA,
        Instance.BRAND_NORTH_AMERICA,
    ];

    static LOCALES = [
        Instance.LOCALE_UK,
        Instance.LOCALE_DE,
        Instance.LOCALE_DK,
        Instance.LOCALE_SE,
        Instance.LOCALE_NO,
        Instance.LOCALE_NL,
        Instance.LOCALE_FI,
        Instance.LOCALE_FR,
    ];

    /**
     * Brand value for instance
     */
    brand: IInstanceData['brand'];

    /**
     * Human-readable brand value for instance
     */
    brandLabel: IInstanceData['brandLabel'];

    /**
     * Locale value for instance
     */
    locale: IInstanceData['locale'];

    /**
     * The configuration of instance route
     */
    domainConf: Map<string, string>;

    get link(): string | null {
        const { scheme, host } = this.domainConf.toJS();

        return `${ scheme }://${ host }`;
    }

    get notRevertLocale(): boolean {
        return Instance.LOCALES.includes(this.locale);
    }

    get isAfrica(): boolean {
        return this.brand === Instance.BRAND_AFRICA;
    }

    get isAsia(): boolean {
        return this.brand === Instance.BRAND_ASIA;
    }

    get isLama(): boolean {
        return this.brand === Instance.BRAND_LAMA;
    }

    get isAustralia(): boolean {
        return this.brand === Instance.BRAND_AUSTRALIA;
    }

    get isNorthAmerica(): boolean {
        return this.brand === Instance.BRAND_NORTH_AMERICA;
    }

    get isUk(): boolean {
        return this.locale === Instance.LOCALE_UK;
    }

    get isDe(): boolean {
        return this.locale === Instance.LOCALE_DE;
    }

    get isDk(): boolean {
        return this.locale === Instance.LOCALE_DK;
    }

    get isSe(): boolean {
        return this.locale === Instance.LOCALE_SE;
    }

    get isNo(): boolean {
        return this.locale === Instance.LOCALE_NO;
    }

    get isNl(): boolean {
        return this.locale === Instance.LOCALE_NL;
    }

    get isFi(): boolean {
        return this.locale === Instance.LOCALE_FI;
    }

    get isFr(): boolean {
        return this.locale === Instance.LOCALE_FR;
    }

    constructor(config?: IInstanceData | Map<string, any>) {
        super(config);
    }

    static parseId(id: string): Array<string> {
        return id.split(Instance.DELIMITER);
    }

    /**
     * Builds prefix based on brand, locale and extras
     */
    buildId(id?: string | number | null): string {
        return this.buildPrefix(id);
    }

    /**
     * Builds prefix based on locale and extras
     */
    buildIdByLocale(id?: string | number | null): string {
        return this.buildPrefixByLocale(id);
    }

    /**
     * Returns formatted string of brand and locale
     */
    stringify(): string {
        return `${ this.brandLabel } ${ this.locale.toUpperCase() }`;
    }

    /**
     * Builds prefix based on brand, locale and extras
     */
    private buildPrefix(...args: Array<any>) {
        return [this.brand, this.locale, ...args].filter(r => r).join(Instance.DELIMITER);
    }

    /**
     * Builds prefix based on locale and extras
     */
    private buildPrefixByLocale(...args: Array<any>) {
        return [this.locale, ...args].filter(r => r).join(Instance.DELIMITER);
    }

    /**
     * Builds cms link for given path
     */
    buildLink(path?: string): string {
        const base = this.link;
        if (!path) {
            return base;
        }

        return `${ base }/${ path.replace(/^\/+/, '') }`;
    }

    /**
     * Builds ao link for given path
     */
    buildAoLink(path?: string): string {
        return this.buildLink(path).replace('www.', 'os.');
    }

    /**
     * Gets instance min data
     */
    composeHeadlessData = (): headlessInstance => [ this.brand, this.locale, this.brandLabel ];

    toString() {
        return this.stringify();
    }
}
