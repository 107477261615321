import { ISoldoutProductData, ITWOptionData, ITWProductData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ISoldoutProductModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of questionnaire ids from ORM
     */
    searchPageResults: Array<object> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Create form data
     */
    editPageFormPeriod: Array<any>;

    /**
     * The id of overview, used in copy action
     */
    createPageRecordId: ISoldoutProductData['id'] | null;

    /**
     * The id of questionnaire
     */
    editPageRecordId: ISoldoutProductData['id'];

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Search results form data
     */
    resultSearchPageForm: Map<string, any>;

    /**
     * A list of questionnaire result ids from ORM
     */
    resultSearchPageResults: Array<object> | null;

    /**
     * Current type for search products
     */
    searchProductsType: string;

    /**
     * A list of products ids by type
     */
    createUpdateProducts: Array<ITWProductData> | null;

    /**
     * A list of Option ids from ORM
     *
     * THE REAL TYPE OF THIS PARAMS IS Map<string, List<IOptionData['id']>>
     * I CAN'T FETCH SUBTYPE OF IMMUTABLE STRUCTURE
     */
    productOptions: List<ITWOptionData>;

    /**
     * OPTIONS IDS BY PRODUCT ID
     */
    productOptionsIdsByProductId: List<any>;

    /**
     * The id of questionnaire
     */
    resultPageRecordId: string | null;

    /**
     * The id of questionnaire result
     */
    showResultPageRecordId: string | null;

    /**
     * show detail result form data
     */
    showResultPageForm;
}

export const INITIAL_SOLDOUT_PRODUCT_STATE = createTypedState<ISoldoutProductModuleState>({
    createUpdateProducts: [],
    productOptions: [],
    productOptionsIdsByProductId: [],
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageOrder: null,
    searchPagePagination: null,
    createPageForm: { },
    editPageFormPeriod: { },
    editPageRecordId: null,
    editPageForm: { },
    resultSearchPageForm: { },
    resultSearchPageResults: [],
    searchProductsType: 'HOTEL',
    resultPageRecordId: null,
    showResultPageRecordId: null,
    showResultPageForm: { },
    createPageRecordId: null,
});
