<form class="b-dg-pagination" [formGroup]="form" novalidate>
    <span>
        Elements per page -&nbsp;
    </span>
    <select class="form-control form-control-sm b-dg-pagination__select" (change)="runToStart()" formControlName="size">
        <option *ngFor="let v of pageSizes" [value]="v">{{ v }}</option>
    </select>
    <span class="b-dg-pagination__elements-count"> {{ clrDgTotalItems }} elements </span>
    <ul class="pagination-list">
        <li>
            <button class="b-dg-pagination__to-start" type="button" (click)="runToStart()">
                <clr-icon shape="step-forward-2 down"></clr-icon>
            </button>
        </li>
        <li>
            <button class="pagination-previous" type="button" (click)="runStepBackward()">
                <clr-icon shape="angle left"></clr-icon>
            </button>
        </li>
        <li class="b-dg-pagination__input">
            <input type="text" class="form-control form-control-sm b-dg-pagination__current-page" formControlName="page">
        </li>
        <li>
            /
        </li>
        <li>
            <button type="button">
                {{ pagesCount }}
            </button>
        </li>
        <li>
            <button class="pagination-next" type="button" (click)="runStepForward()">
                <clr-icon shape="angle right"></clr-icon>
            </button>
        </li>
        <li>
            <button class="b-dg-pagination__to-end" type="button" (click)="runToEnd()">
                <clr-icon shape="step-forward-2 up"></clr-icon>
            </button>
        </li>
    </ul>
</form>