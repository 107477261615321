import { Instance } from '@aclass/core/base/instance';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { Map } from 'immutable';

export interface ISmsTemplateModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of email-templates ids from ORM
     */
    searchPageResults: Array<object> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * The id of email template
     */
    editPageRecordId: number | null;

    /**
     * The id of sms message
     */
    editPageActiveId: string | null;

    /**
     * The active brand value
     */
    editPageBrand: Instance['brand'] | null;

    /**
     * The active brand value
     */
    editPageLocale: Instance['locale'] | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;
}

export const INITIAL_SMS_TEMPLATE_STATE = createTypedState<ISmsTemplateModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageOrder: null,
    searchPagePagination: null,
    createPageForm: { },
    createPageSaveLock: null,
    editPageForm: { },
    editPageRecordId: null,
    editPageActiveId: null,
    editPageBrand: null,
    editPageLocale: null,
    editPageSaveLock: null,
});
