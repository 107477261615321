import { isDateRange, Daytable, IDateRange } from '@aclass/core/base/daytable';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fromDateRangeString'
})

export class DateRangePipe implements PipeTransform {

    /**
     * @inheritDoc
     */
    transform(value: any): IDateRange<Date> | undefined {
        if (!isDateRange<string>(value)) {
            return undefined;
        }
        const [from, to]: [Daytable, Daytable] = [Daytable.fromDateString(String(value.from)), Daytable.fromDateString(String(value.to))];

        return {
            from: from ? from.toDate() : null,
            to: to ? to.toDate() : null,
        };
    }
}
