import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IUserModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of user ids from ORM
     */
    searchPageResults: List<number>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * A list of roles
     */
    createPageRoleNames: List<string>;

    /**
     * The the save button lock
     */
    createPageSaveLock: boolean | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The id of user
     */
    editPageRecordId: number | null;

    /**
     * The the save button lock
     */
    editPageSaveLock: boolean | null;

    /**
     * A list of roles
     */
    editPageRoleNames: List<string>;

    /**
     * If user change self role application, the should re-login
     */
    editPageShouldLogout: boolean | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Edit form data
     */
    profilePageForm: Map<string, any>;

    /**
     * The the save button lock
     */
    profilePageSaveLock: boolean | null;

    /**
     * If user change self role application, the should re-login
     */
    profilePageShouldLogout: boolean | null;
}

export const INITIAL_USER_STATE = createTypedState<IUserModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    createPageRoleNames: [],
    createPageSaveLock: null,
    createPageForm: { },
    editPageRecordId: null,
    editPageSaveLock: null,
    editPageRoleNames: [],
    editPageShouldLogout: null,
    editPageForm: { },
    profilePageForm: { },
    profilePageSaveLock: null,
    profilePageShouldLogout: null,
});
