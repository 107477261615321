import { headlessInstance, Instance, IInstanceData } from '@aclass/core/base/instance';
import { isList } from '@aclass/core/helpers/immutable';
import { IAppState } from '@aclass/core/storage/states/app.state';
import { ISystemModuleState } from '@aclass/core/storage/states/system.module.state';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { List } from 'immutable';

type input = Array<string> | List<string> | string;

/**
 * This is bridge between redux store and reactive forms
 */
@Injectable()
export class BaseInstanceManager {

    private _instances: List<Instance>;

    /**
     * Checks that given brand is Africa
     */
    static isAfrica(brand?: string): boolean {
        return brand === Instance.BRAND_AFRICA;
    }

    /**
     * Checks that given brand is Asia
     */
    static isAsia(brand?: string): boolean {
        return brand === Instance.BRAND_ASIA;
    }

    /**
     * Checks that given brand is Lama
     */
    static isLama(brand?: string): boolean {
        return brand === Instance.BRAND_LAMA;
    }

    /**
     * Checks that given brand is Lama
     */
    static isAustralia(brand?: string): boolean {
        return brand === Instance.BRAND_AUSTRALIA;
    }

    /**
     * Checks that given brand is North America
     */
    static isNorthAmerica(brand?: string): boolean {
        return brand === Instance.BRAND_NORTH_AMERICA;
    }

    /**
     * Checks that given locale is uk
     */
    static isUk(locale?: string): boolean {
        return locale === Instance.LOCALE_UK;
    }

    /**
     * Checks that given locale is de
     */
    static isDe(locale?: string): boolean {
        return locale === Instance.LOCALE_DE;
    }

    /**
     * Checks that given locale is dk
     */
    static isDk(locale?: string): boolean {
        return locale === Instance.LOCALE_DK;
    }

    /**
     * Checks that given locale is se
     */
    static isSe(locale?: string): boolean {
        return locale === Instance.LOCALE_SE;
    }

    /**
     * Checks that given locale is no
     */
    static isNo(locale?: string): boolean {
        return locale === Instance.LOCALE_NO;
    }

    /**
     * Checks that given locale is nl
     */
    static isNl(locale?: string): boolean {
        return locale === Instance.LOCALE_NL;
    }

    /**
     * Checks that given locale is fi
     */
    static isFi(locale?: string): boolean {
        return locale === Instance.LOCALE_FI;
    }

    /**
     * Checks that given locale is fr
     */
    static isFr(locale?: string): boolean {
        return locale === Instance.LOCALE_FR;
    }

    /**
     * Gets all of available instances
     */
    get instances(): List<Instance> {
        return this._instances ? this._instances : List([]);
    }

    /**
     * Gets all of available brands
     */
    get brands(): List<string> {
        return this.instances.map(v => v.brand).toSet().toList();
    }

    /**
     * Gets all of available brand labels
     */
    get brandsLabels(): List<string> {
        return this.instances.map(v => v.brandLabel).toSet().toList();
    }

    /**
     * Gets all of available locales
     */
    get locales(): List<string> {
        // return this.instances.filter(r => r.notRevertLocale).map(v => v.locale).toSet().toList();
        return this.instances.map(v => v.locale).toSet().toList();
    }

    /**
     * Initializes the object.
     */
    protected init(ngRdx: NgRedux<IAppState>): void {
        ngRdx.select<ISystemModuleState['config']>(['systemModule', 'config']).subscribe(v => {
            if (!v.has('instances')) {
                return;
            }
            this._instances = v.get('instances').map(r => new Instance(r));
        });
    }

    /**
     * Searches for instance in available, if instance not found returns empty
     */
    find(brand: IInstanceData['brand'], locale: IInstanceData['locale']): Instance | null {
        return this.instances.find(r => r.brand === brand && r.locale === locale) || null;
    }

    /**
     * Searches for first instance in available by brand, if no instance returns empty
     * @param brand
     */
    findFirstByBrand(brand: IInstanceData['brand']): Instance | null {
        return this.instances.find(r => r.brand === brand) || null;
    }

    /**
     * Returns a list of available instances by given filter
     */
    getInstances(brands?: input, locales?: input): List<Instance> {
        const filteredBrands = <Array<Instance['brand']>>this.castInput(brands);
        const filteredLocales = <Array<Instance['locale']>>this.castInput(locales);

        return <List<Instance>>this.instances.filter(i => {
            return (!filteredBrands.length || filteredBrands.includes(i.brand)) && (!filteredLocales.length || filteredLocales.includes(i.locale));
        });
    }

    /**
     * Returns a list of available instances by locale
     */
    getInstancesByLocale(locales?: input): List<Instance> {
        const filteredLocales = <Array<Instance['locale']>>this.castInput(locales);

        return <List<Instance>>this.instances.filter(i => {
            return (!filteredLocales.length || filteredLocales.includes(i.locale));
        });
    }

    parseId(id: string): Array<string> {
        return Instance.parseId(id);
    }

    /**
     * Returns brands by given locale(s)
     */
    getBrandsByLocale(records?: input): List<Instance['brand']> {
        records = this.castInput(records);

        return records.length ? this.instances.filter(r => records.includes(r.locale)).map(r => r.brand).toSet().toList() : this.brands;
    }

    /**
     * Returns brands by given locale(s)
     */
    getBrandsLabelsByLocale(records?: input): List<Instance['brandLabel']> {
        records = this.castInput(records);

        return records.length ? this.instances.filter(r => records.includes(r.locale)).map(r => r.brand).toSet().toList() : this.brandsLabels;
    }

    /**
     * Returns locales by given brand(s)
     */
    getLocalesByBrand(records?: input): List<Instance['locale']> {
        records = this.castInput(records);

        // return records.length ? this.instances.filter(r => r.notRevertLocale).filter(r => records.includes(r.brand)).map(r => r.locale).toSet().toList() : this.locales;
        return records.length ? this.instances.filter(r => records.includes(r.brand)).map(r => r.locale).toSet().toList() : this.locales;
    }

    /**
     * Setups requests headers for given instance.
     * Please note that this method accept multiple types of input. Ex:
     * ```ts
     * instanceManager.buildHeaders(instanceManager.parseId('africa_uk'))
     * instanceManager.buildHeaders(instanceManager.parseId('africa_uk_23'))
     * instanceManager.buildHeaders(instanceManager.find('africa', 'uk'))
     * instanceManager.buildHeaders(['africa', 'uk'])
     * instanceManager.buildHeaders()
     * instanceManager.buildHeaders(null)
     * ```
     */
    buildHeaders(v?: Instance | headlessInstance | Array<string> | List<string> | null): { headers?: { [header: string]: string | string[]; } } {
        if (!v) {
            return { };
        }
        let brand, locale;
        if (isList(v)) {
            v = v.toJS();
        }
        if (Array.isArray(v)) {
            [brand, locale] = v;
        } else if (typeof v === 'object') {
            brand = (<Instance>v).brand;
            locale = (<Instance>v).locale;
        }

        return !this.brands.includes(brand) || !this.locales.includes(locale) ? { } : {
            // Please note, that header are case-insensitive
            headers: {
                brand,
                locale
            }
        };
    }

    /**
     * Setups requests headers for given locale.
     * instanceManager.buildHeaders(['uk'])
     * instanceManager.buildHeaders()
     * instanceManager.buildHeaders(null)
     * ```
     */
    buildHeadersByLocale(v?: Instance | headlessInstance | Array<string> | List<string> | null): { headers?: { [header: string]: string | string[]; } } {
        if (!v) {
            return { };
        }
        let locale;
        if (isList(v)) {
            v = v.toJS();
        }
        if (Array.isArray(v)) {
            [locale] = v;
        } else if (typeof v === 'object') {
            locale = (<Instance>v).locale;
        }

        return !this.locales.includes(locale) ? { } : {
            // Please note, that header are case-insensitive
            headers: {
                locale
            }
        };
    }

    protected castInput(v: input): Array<string> {
        if (typeof v === 'string') {
            return [v];
        }
        if (isList<string>(v)) {
            return v.toArray();
        }

        return Array.isArray(v) ? v : [];
    }
}
