import { attr as attribute, fk as foreignKey, many as manyToMany, oneToOne, AttributeOpts } from 'redux-orm';

interface IModelOpts {
    name: string;
    idAttribute?: string;
}

export interface IRelationalOpts {
    to: string;
    relatedName?: string;
    through?: string;
    throughFields?: [string, string];
}

function injectFields(target: Object) {
    if (target.constructor.hasOwnProperty('fields')) {
        return;
    }
    Object.defineProperty(target.constructor, 'fields', {
        enumerable: true,
        configurable: true,
        value: { },
        writable: false
    });
}

export function Model(opts: IModelOpts) {
    return (target: Function) => {
        Object.defineProperty(target, 'modelName', {
            enumerable: true,
            configurable: true,
            value: opts.name,
            writable: false
        });
        if (opts.idAttribute) {
            const options: () => Object = (<any>target).options;
            (<any>target).options = (...args) => ({
                ...options.apply(null, args),
                ...{
                    idAttribute: opts.idAttribute
                }
            });
        }
    };
}

export function Attr(opts?: AttributeOpts) {
    return (target: Object, propertyName: string): void => {
        injectFields(target);
        (<any>target.constructor).fields[propertyName] = attribute(opts);
    };
}

export function Fk(opts: IRelationalOpts) {
    return (target: Object, propertyName: string): void => {
        injectFields(target);
        (<any>target.constructor).fields[propertyName] = foreignKey(<any>opts);
    };
}

export function Many(opts: IRelationalOpts) {
    return (target: Object, propertyName: string): void => {
        injectFields(target);
        (<any>target.constructor).fields[propertyName] = manyToMany(<any>opts);
    };
}

export function One(opts: IRelationalOpts) {
    return (target: Object, propertyName: string): void => {
        injectFields(target);
        (<any>target.constructor).fields[propertyName] = oneToOne(<any>opts);
    };
}
