import { ISoldoutProductData, ITWOptionData } from '@aclass/admin/storage/models';
import { ISoldoutProductModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'soldoutProduct'
})
export class SoldoutProductStory {

    @Task() static PRODUCT_UPDATE_OPTIONS: string;

    @Task() static PRODUCT_ID_UPDATE_OPTIONS_IDS: string;

    @Task() static PRODUCT_OPTION_SEARCH: string;

    @Task() static IMPORT_HOTELS_RECORDS: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static ADD_IN_GROUP_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_COPY_QUESTIONNAIRE: string;

    @Task() static CREATE_PAGE_UPDATE_QUESTIONNAIRE_ID: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_SOLDOUT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_FORM_PERIOD: string;

    @Task() static EDIT_PAGE_CREATE_FORM: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_REMOVE: string;

    @Task() static SEARCH_PAGE_REMOVE: string;

    @Task() static RESULT_PAGE_DRY_RUN: string;

    @Task() static VIEW_PAGE_PREVIEW: string;

    @Task() static RESULT_PAGE_UPDATE_QUESTIONNAIRE_ID: string;

    @Task() static RESULT_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SHOW_RESULT_PAGE_DRY_RUN: string;

    @Task() static SHOW_RESULT_PAGE_UPDATE_QUESTIONNAIRE_RESULT_ID: string;

    @Task() static VIEW_PAGE_DOWNLOAD: string;

    @Task() static VIEW_PAGE_DELETE_TOGGLE: string;

    @Task() static VIEW_PAGE_UPDATE_SEARCH_LOCK: string;

    static productUpdateOptions( payload: Array<ITWOptionData>): Action {
        return { type: SoldoutProductStory.PRODUCT_UPDATE_OPTIONS, payload  };
    }

    static productIdUpdateOptions(uid: number, ids: Array<ITWOptionData['id']>): Action {
        return { type: SoldoutProductStory.PRODUCT_ID_UPDATE_OPTIONS_IDS, payload: { uid, ids } };
    }

    static productSearchOptions(payload): Action {
        return { type: SoldoutProductStory.PRODUCT_OPTION_SEARCH, payload };
    }

    static dryRunSearchPage(): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_DRY_RUN };
    }

    static importRecordsOnSearchPage(items: Array<Object>): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_IMPORT_RECORDS, payload: items };
    }

    static importProductsIds(items: Array<Object>): Action {
        return { type: SoldoutProductStory.IMPORT_HOTELS_RECORDS, payload: items };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static submitOnSearchPage(refresh: boolean = false): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_SUBMIT, payload: refresh };
    }

    static createPageDryRun(payload): Action {
        return { type: SoldoutProductStory.CREATE_PAGE_DRY_RUN, payload };
    }

    static updateFormSearchPage(payload): Action {
       return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static submitOnCreatePage(v: any | null): Action {
        return { type: SoldoutProductStory.CREATE_PAGE_SUBMIT, payload: v };
    }


    static addInGroupPageSubmit(payload: any): Action {
        return { type: SoldoutProductStory.ADD_IN_GROUP_PAGE_SUBMIT, payload };
    }

    static dryRunEditPage(id: ISoldoutProductData['id']): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateIdEditPage(id: ISoldoutProductData['id']): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_UPDATE_SOLDOUT_ID, payload: id };
    }

    static updateFormOnCreatePage(payload: any): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_CREATE_FORM, payload };
    }

    static updateFormOnEditPage(payload: any): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateFormOnEditPagePeriod(payload: any): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_UPDATE_FORM_PERIOD, payload };
    }

    static submitOnEditPage(): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_SUBMIT };
    }

    static removeOnEditPage(id: number): Action {
        return { type: SoldoutProductStory.EDIT_PAGE_REMOVE, payload: id };
    }

    static removeOnSearchPage(id: number): Action {
        return { type: SoldoutProductStory.SEARCH_PAGE_REMOVE, payload: id };
    }

    static previewOnViewPage(): Action {
        return { type: SoldoutProductStory.VIEW_PAGE_PREVIEW };
    }

    static updateSearchLockOnViewPage(payload: ISoldoutProductModuleState['viewPageLock']): Action {
        return { type: SoldoutProductStory.VIEW_PAGE_UPDATE_SEARCH_LOCK, payload };
    }
}
