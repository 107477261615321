import { DomainDetail } from '@aclass/admin/storage/models/domain.detail';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';
import { Question } from './question';

export interface IAnswerData {

    readonly answer: string | null;

    readonly customText: string | null;
}

export interface IQuestionnaireData {

    readonly id: number | null;

    readonly instance_id: string;

    readonly brand: string | null;

    readonly brandLabel: string | null;

    readonly locale: string | null;

    readonly name: string | null;

    readonly description: string | null;

    readonly questionsCount: number | null;

    readonly resultsCount: number | null;

    readonly linkTitle: string | null;

    readonly thankText: string | null;

    readonly deleted: boolean | null;

    readonly updatedAt: number | null;
}

@Model({
    name: 'Questionnaire'
})
export class Questionnaire extends Entity<IQuestionnaireData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly brand: string | null;

    @Attr() readonly brandLabel: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly description: string | null;

    @Attr() readonly questionsCount: number | null;

    @Attr() readonly resultsCount: number | null;

    @Attr() readonly linkTitle: string | null;

    @Attr() readonly thankText: string | null;

    @Attr() readonly hash: string | null;

    @Attr() readonly deleted: boolean | null;

    @Attr() readonly updatedAt: number | null;

    readonly relatedQuestions: QuerySet<Question>;

    readonly relatedDomainDetail: QuerySet<DomainDetail>;
    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<IQuestionnaireData, 'id' | 'name'> => ({ id: this.id, name: this.name });
}
