import { Pagination } from '@aclass/core/rest/pagination';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'adm-inline-pager',
    template: `
        Results:&nbsp;
        <span [style.opacity]="loading ? .4 : 1">{{ pagination.totalCount }}</span>
    `,
})
export class InlinePaginationComponent {

    @HostBinding('class.b-inline-pager') yes = true;

    @Input() pagination: Pagination = new Pagination();

    @Input() loading = false;
}
