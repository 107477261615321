import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ITourData {

    readonly id: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean;
}

@Model({
    name: 'Tour'
})
export class Tour extends Entity<ITourData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean;

    readonly tourRevisions: QuerySet<TourRevision>;
}

