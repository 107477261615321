import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'safe',
})
export class SafeHtmlPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    /**
     * @inheritDoc
     */
    transform(value: any): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(typeof value === 'string' ? value : '');
    }
}
