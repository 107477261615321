import { SystemStory } from '@aclass/admin/storage/actions';
import { ReactiveFormFactory } from '@aclass/core/base/reactive.form.factory';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Action } from '@aclass/core/storage/action';
import { EventEmitter, Injectable } from '@angular/core';


export interface ISaveState {
    form: string;
    success: boolean;
}

/**
 * This is bridge between redux store and reactive forms
 */
@Injectable()
export class FormManager {

    readonly onReset: EventEmitter<string> = new EventEmitter<string>();

    readonly onAfterSave: EventEmitter<ISaveState> = new EventEmitter<ISaveState>();

    /**
     * Tells to all subscribers call reset form event.
     * Name used to identify form. If you used one form on page you can leave this params empty.
     */
    reset = (name: string = '') => this.onReset.emit(name);

    afterSave = (v: ISaveState) => this.onAfterSave.emit(v);

    collectErrors(form: ReactiveFormFactory): Action {
        const content: Array<string> = form.errors.map(r => {
            return `${r.name}<ul><li>${r.errors.join('</li><li>')}</li></ul>`;
        });

        return SystemStory.showNotification(SystemNotification.error(`"${form.name}" is not valid`, content.join('')));
    }
}
