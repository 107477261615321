import { UserStory } from '@aclass/admin/storage/actions';
import { INITIAL_USER_STATE, IUserModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const userModuleReducer = (state: TypedState<IUserModuleState> = INITIAL_USER_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case UserStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case UserStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case UserStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case UserStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case UserStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case UserStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case UserStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case UserStory.CREATE_PAGE_UPDATE_ROLE_NAMES:
            return state.update('createPageRoleNames', () => List(payload));
        case UserStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case UserStory.EDIT_PAGE_UPDATE_USER_ID:
            return state.update('editPageRecordId', () => payload);
        case UserStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case UserStory.EDIT_PAGE_UPDATE_SHOULD_LOGOUT:
            return state.update('editPageShouldLogout', () => payload);
        case UserStory.EDIT_PAGE_UPDATE_ROLE_NAMES:
            return state.update('editPageRoleNames', () => List(payload));
        case UserStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case UserStory.PROFILE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('profilePageSaveLock', () => payload);
        case UserStory.PROFILE_PAGE_UPDATE_SHOULD_LOGOUT:
            return state.update('profilePageShouldLogout', () => payload);
        default:
            return state;
    }
};
