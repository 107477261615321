import { PipelineEditStateResolver } from '@aclass/admin/resolvers';
import {
    OpportunityStory,
    OrmStory,
    PipelineStory,
    SystemStory,
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { PipelineLevel } from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const pipelineModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(PipelineStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.pipelineModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            PipelineStory.updateFormSearchPage({ }),
            PipelineStory.importRecordsOnSearchPage([]),
            PipelineStory.updatePaginationOnSearchPage(null),
            PipelineStory.updateOrderOnSearchPage(null),
            PipelineStory.updateSearchLockOnSearchPage(null),
            PipelineStory.updateCollapsedSearchPage(null),
            // Unlock page
            PipelineStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(PipelineStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.pipelineModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(PipelineStory.updateSearchLockOnSearchPage(true)), http.post('pipeline/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPipelineLevelData>>>) => [
                    OrmStory.populatePipelineLevels(rs.body.results),
                    PipelineStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    PipelineStory.updatePaginationOnSearchPage(rs.body.pagination),
                    PipelineStory.updateOrderOnSearchPage(rs.body.order),
                    PipelineStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(PipelineStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<number>) => state.value.pipelineModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<number>) => http.get(`pipeline/${ payload }`).pipe(
            dispatchActions(
                (rs: Response<IPipelineLevelData>) => [
                    PipelineStory.updatePipelineIdEditPage(payload),
                    PipelineStory.updateFormOnEditPage({ }),
                    OrmStory.populatePipelineLevels([rs.body]),
                ],
                () => [
                    SystemStory.stopNavigate(PipelineEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['pipelines'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(PipelineStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const id = state.value.pipelineModule.get('editPageRecordId');
            let data = state.value.pipelineModule.get('editPageForm');
            const ormPipeline = findById<PipelineLevel>(orm.session(state.value.orm), PipelineLevel, id);

            // cast to int
            data = data.set('days', +data.get('days'));


            const isDaysChanged = function(): boolean {
                return data.get('days') !== ormPipeline.days;
            }();

            return concat(of(PipelineStory.updateSaveLockEditPage(true)), http.put(`pipeline/${ id }`, data).pipe(
                dispatchActions(
                    (rs: Response<IPipelineLevelData>) => {

                        const actions = [
                            PipelineStory.updateSaveLockEditPage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', `Pipeline "${ rs.body.name }" updated`)),
                            OrmStory.populatePipelineLevels([rs.body])
                        ];

                        const invalidateOpportunityData = [
                            OpportunityStory.updateOpportunityIdEditPage(null),
                            OpportunityStory.updateSearchPageInitState(null),
                        ];

                        if (isDaysChanged) {
                            actions.push(...invalidateOpportunityData);
                        }

                        return actions;
                    },
                    () => [
                        PipelineStory.updateSaveLockEditPage(false)
                    ]
                )
            ));
        }),
    )
);
