import { AppStory } from '@aclass/admin/storage/actions';
import { dispatch } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'adm-login-auto',
    template: '',
})
export class LoginAutoComponent implements OnInit {

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.autoLogin();
    }

    @dispatch() autoLogin = () => AppStory.autoLogin();
}
