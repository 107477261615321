import { CmsAirport, ICmsAirportData } from '@aclass/admin/storage/models/cms.airport';
import { CmsDestination, ICmsDestinationData } from '@aclass/admin/storage/models/cms.destination';
import { Attr, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ICmsTourPriceData {

    date: string;

    direct: boolean;

    adultPrice: string;

    childPrice: string;

    adultPriceWithDirectFlight: string | null;

    childPriceWithDirectFlight: string | null;
}

export interface ICmsTourData {

    readonly id: string | null;

    readonly link: string | null;

    readonly name: string | null;

    readonly prices: Array<ICmsTourPriceData>;

    readonly destinations: Array<ICmsDestinationData['id']>;

    readonly airports: Array<ICmsAirportData['id']>;
}

@Model({
    name: 'CmsTour'
})
export class CmsTour extends Entity<ICmsTourData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly link: string | null;

    @Attr() readonly prices: Array<ICmsTourPriceData>;

    @Many({ to: 'CmsDestination', relatedName: 'relatedTours' }) readonly destinations: QuerySet<CmsDestination>;

    @Many({ to: 'CmsAirport', relatedName: 'relatedTours' }) readonly airports: QuerySet<CmsAirport>;
}

