import { isIterable } from '@aclass/core/helpers/immutable';
import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'length'
})

export class LengthPipe implements PipeTransform {

    /**
     * @inheritDoc
     */
    transform(value: any): Observable<any> {
        if (isObservable(value)) {
            return value.pipe(map(v => this.calcLength(v)));
        }

        return of(this.calcLength(value));
    }

    private calcLength(value: any): number {
        if (isIterable<any, any>(value)) {
            value = value.toJS();
        }
        if (Array.isArray(value)) {
            return value.length;
        }
        if (typeof value === 'object') {
            return Object.keys(value).length;
        }
        if (Number.isInteger(value)) {
            return value;
        }

        return 0;
    }
}
