import { ISmsMessageData } from '@aclass/admin/storage/models';
import { ISmsTemplateModuleState } from '@aclass/admin/storage/states';
import { Instance } from '@aclass/core/base/instance';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'sms-template'
})

export class SmsTemplateStory {

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_ACTIVE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_BRAND: string;

    @Task() static EDIT_PAGE_UPDATE_LOCALE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_TOGGLE_ARCHIVE: string;

    static dryRunSearchPage(): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: boolean | null): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: boolean | null): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload: any): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static updateSearchLockOnSearchPage(payload: boolean | null): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static importRecordsOnSearchPage(payload: Array<Object>): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_SUBMIT };
    }

    static submitOnCreatePage(): Action {
        return { type: SmsTemplateStory.CREATE_PAGE_SUBMIT };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: SmsTemplateStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateSaveLockCreatePage(payload: ISmsTemplateModuleState['createPageSaveLock']): Action {
        return { type: SmsTemplateStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static dryRunEditPage(payload: { id: number, brand?: Instance['brand'], locale?: Instance['locale'] }): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateIdEditPage(payload: number): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_ID, payload };
    }

    static updateActiveIdEditPage(payload: ISmsMessageData['id']): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_ACTIVE_ID, payload };
    }

    static updateBrandEditPage(payload: Instance['brand'] | null): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_BRAND, payload };
    }

    static updateLocaleEditPage(payload: Instance['locale'] | null): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_LOCALE, payload };
    }

    static updateFormOnEditPage(payload: any): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static submitOnEditPage(): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_SUBMIT };
    }

    static updateSaveLockEditPage(payload: boolean | null): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static toggleArchiveEditPage(payload: boolean): Action {
        return { type: SmsTemplateStory.EDIT_PAGE_TOGGLE_ARCHIVE, payload };
    }
}
