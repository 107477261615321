import { ClrDatagridTransformer } from '@aclass/admin/transformers/clr.datagrid.transformer';
import { IOrderData, Order } from '@aclass/core/rest/order';
import { IPaginationData, Pagination } from '@aclass/core/rest/pagination';
import { WINDOW } from '@aclass/core/services/window.service';
import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { Subscription } from 'rxjs';
import { EditorManager } from 'tinymce';
import { DgExpandButtonComponent } from '../expand-button/dg-expand-button.component';
import { DgPaginationComponent } from '../pagination/dg-pagination.component';

@Component({
    selector: 'adm-dg-layout',
    templateUrl: './dg-layout.component.html',
})
export class DgLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    @ContentChild(ClrDatagrid) dg: ClrDatagrid;

    @ContentChild(ClrDatagrid, { read: ElementRef }) dgRef: ElementRef;

    @ContentChild(DgPaginationComponent) dgp: DgPaginationComponent;

    @ContentChild(DgExpandButtonComponent) expandButton: DgExpandButtonComponent;

    @Input() set pagination(v: Pagination) {
        this._pagination = v;
        if (!this._initialized || !this.dgp) {
            return;
        }
        this.loadPagination();
    }

    @Input() set order(v: Order) {
        this._order = v;
        if (!this._initialized || !this.dg) {
            return;
        }
        this.loadOrder();
    }

    @Output() paginationChange: EventEmitter<IPaginationData> = new EventEmitter<IPaginationData>();

    @Output() orderChange: EventEmitter<IOrderData> = new EventEmitter<IOrderData>();

    private _initialized = false;

    private _pagination: Pagination;

    private _order: Order;

    private subscriptions: Array<Subscription> = [];

    constructor(
        @Inject(WINDOW) private window: Window,
    ) {
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    ngAfterViewInit() {
        if (!this.dg) {
            return;
        }
        this.dgRef.nativeElement.classList.add('b-dg');
        this.subscriptions.push(this.dg.refresh.subscribe(this.runUpdate));
        this.subscriptions.push(this.dg.columns.changes.subscribe(this.loadOrder));

        if (this.dgp) {
            this.subscriptions.push(this.dgp.clrDgRefresh.subscribe(this.runUpdate));
            setTimeout(() => {
                this.loadPagination();
                this.loadOrder();
            });
        }
        this._initialized = true;
        this.riseExpand();
        this.riseWysiwyg();
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    runUpdate = (state: ClrDatagridStateInterface) => {
        const pState = ClrDatagridTransformer.toNextPaginationState(state, this._pagination),
            oState = ClrDatagridTransformer.toNextOrderState(state, this._order);
        if (pState) {
            this.paginationChange.emit(pState);
        }
        if (oState) {
            this.orderChange.emit(oState);
        }
    }

    private riseExpand() {
        if (!this.expandButton) {
            return;
        }
        if (typeof this.expandButton.expanded === 'undefined') {
            setTimeout(() => {
                this.expandButton.expanded = this.dg.rows.some(r => r.expanded);
            });
        }
        this.subscriptions.push(this.expandButton.onClick.subscribe(v => {
            this.dg.rows.forEach(r => {
                if (v === r.expanded) {
                    return;
                }
                r.toggleExpand();
            });
        }));
    }

    /**
     * AFR-2740
     * Editor becomes empty and temporarily unavailable when creating another element in expandable datagrid
     */
    private riseWysiwyg() {
        if (!this.tinymce) {
            return;
        }
        this.dg.rows.changes.subscribe(() => {
            this.dgRef.nativeElement.querySelectorAll('textarea.wysiwyg').forEach(r => {
                this.tinymce.execCommand('mceRemoveEditor', true, r.id);
                this.tinymce.execCommand('mceAddEditor', true, r.id);
            });
        });
    }

    private loadPagination = () => {
        const v = this._pagination;
        if (!v) {
            return;
        }
        this.dgp.clrDgPage = v.page + 1;
        this.dgp.clrDgTotalItems = v.totalCount;
        this.dgp.clrDgPageSize = v.pageSize;
        this.dgp.applyChanges();
    }

    private loadOrder = () => {
        const v = this._order;
        if (!v) {
            return;
        }
        this.dg.columns.forEach(r => {
            // It is strange. Because DatagridPropertyComparator has public property
            // "prop" in constructor, but tslint not see it.
            let by: any = r.sortBy;
            // Can be DatagridPropertyComparator
            if (typeof by === 'object') {
                by = by.prop;
            }
            if (v.by !== by) {
                r.sortOrder = ClrDatagridSortOrder.UNSORTED;

                return;
            }

            r.sortOrder = v.isReverse ? ClrDatagridSortOrder.DESC : ClrDatagridSortOrder.ASC;
        });
    }

    private get tinymce(): EditorManager {
        const w = <any>this.window;

        return 'tinymce' in w ? w.tinymce : null;
    }
}
