import { ORMCommonState, QuerySet, SessionWithModels } from 'redux-orm';

export type IModelData<T> = { readonly [P in keyof T]?: T[P] | null };

interface Query<T> {
    all(): QuerySet<any, any, any>;
    withId(id: string | number | null): T;
    hasId(id: string | number | null): boolean;
}

/**
 * Redux orm not like typescript. This is hack for IDE and typescript for more flexible typecasting
 */
export function extractModel<T>(session: SessionWithModels<ORMCommonState>, model: any): Query<T> {
    if (typeof model === 'string') {
        return session[model];
    }

    return 'modelName' in model ? session[model.modelName] : null;
}
