import { IDestinationRevisionData } from '@aclass/admin/storage/models/destination.revision';
import { ITourRevisionData } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IGeneralRemarkData {

    readonly id: string;

    readonly brand: string;

    readonly brandLabel: string;

    readonly locale: string;

    readonly rows: Array<IGeneralRemarkRowData>;
}

export interface IGeneralRemarkRowData {

    readonly text: string;

    readonly destinations: Array<IDestinationRevisionData['id']>;

    readonly tours: Array<ITourRevisionData['id']>;

    readonly paxNumber: number;
}

@Model({
    name: 'GeneralRemark'
})
export class GeneralRemark extends Entity<IGeneralRemarkData> {

    @Attr() readonly id: string;

    @Attr() readonly brand: string;

    @Attr() readonly brandLabel: string;

    @Attr() readonly locale: string;

    @Attr() readonly rows: Array<IGeneralRemarkRowData>;
}
