import { TemplateStory } from '@aclass/admin/storage/actions';
import { INITIAL_TEMPLATE_STATE, ITemplateModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';

export const templateModuleReducer = (state: TypedState<ITemplateModuleState> = INITIAL_TEMPLATE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case TemplateStory.UPDATE_MENU_COLLAPSED:
            return state.update('menuCollapsed', () => payload);
        default:
            return state;
    }
};
