import { ISwitchButtonData } from '@aclass/admin/components';

export class PriceFormatTypes {

    static TYPE_COMMA = ',';

    static TYPE_DOT = '.';

    static TYPE_SPACE = ' ';

    static TYPE_EMPTY = '';

    static TYPE_DEFAULT = 'default';

    static TYPE_NAMES = {
        [PriceFormatTypes.TYPE_COMMA]: 'Comma',
        [PriceFormatTypes.TYPE_DOT]: 'Dot',
        [PriceFormatTypes.TYPE_SPACE]: 'One space',
        [PriceFormatTypes.TYPE_EMPTY]: 'Empty',
        [PriceFormatTypes.TYPE_DEFAULT]: 'Default value',
    };

    /**
     * Prepares statuses for checkbox
     */
    static composeDecimalsForSwitchButton(): Array<ISwitchButtonData> {
        const list = [
            PriceFormatTypes.TYPE_COMMA,
            PriceFormatTypes.TYPE_DOT,
            PriceFormatTypes.TYPE_DEFAULT
        ];

        return list.map(r => ({
            title: PriceFormatTypes.TYPE_NAMES[r],
            value: r,
        }));
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeThousandsForSwitchButton(): Array<ISwitchButtonData> {
        const list = [
            PriceFormatTypes.TYPE_COMMA,
            PriceFormatTypes.TYPE_DOT,
            PriceFormatTypes.TYPE_SPACE,
            PriceFormatTypes.TYPE_EMPTY,
            PriceFormatTypes.TYPE_DEFAULT
        ];

        return list.map(r => ({
            title: PriceFormatTypes.TYPE_NAMES[r],
            value: r,
        }));
    }
}
