import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ICombinationToTourRevisionMappingData {

    readonly id: string | null;

    readonly tourRevisionId: TourRevision | string | null;

    readonly combinationRevisionId: CombinationRevision | string | null;
}

@Model({
    name: 'CombinationToTourRevisionMapping'
})
export class CombinationToTourRevisionMapping extends Entity<ICombinationToTourRevisionMappingData> {

    @Attr() readonly id: string | null;

    @Fk({ to: 'TourRevision', relatedName: 'relatedCombinationToTourRevisionMappings' }) readonly tourRevisionId: TourRevision | string | null;

    @Fk({ to: 'CombinationRevision', relatedName: 'relatedCombinationToTourRevisionMappings' }) readonly combinationRevisionId: CombinationRevision | string | null;
}

