import { IAdminState } from '@aclass/admin/storage/states';
import { BasePipeManager } from '@aclass/core/managers/base.pipe.manager';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

/**
 * This is bridge between redux store and reactive forms
 */
@Injectable()
export class PipeManager extends BasePipeManager {

    constructor(
        protected ngRdx: NgRedux<IAdminState>,
    ) {
        super();
        this.init(ngRdx);
    }
}
