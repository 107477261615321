/**
 * The error wrapper
 * In general it is [[FrontError]] on backend side
 */
export class Notification {

    /**
     * The http status code
     */
    readonly code: number;

    /**
     * The short message of notification
     */
    readonly title: string;

    /**
     * The full message of notification
     */
    readonly message: string | null;

    constructor({ code, title, message }: Notification) {
        this.code = code;
        this.title = title;
        this.message = message;
    }
}
