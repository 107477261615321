import { ISwitchButtonData } from '@aclass/admin/components';
import { OverviewCondition } from '@aclass/admin/storage/models/overview.condition';
import { OverviewDisplay } from '@aclass/admin/storage/models/overview.display';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IOverviewData {

    readonly id: number | null;

    readonly type: number | null;

    readonly name: string | null;

    readonly description: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean | null;
}

@Model({
    name: 'Overview'
})
export class Overview extends Entity<IOverviewData> {

    static TYPE_PRODUCT = 1;

    static TYPE_CUSTOMER = 2;

    static TYPE_OPPORTUNITY = 3;

    static TYPE_QUOTE = 4;

    static TYPE_OFFLINE_BOOKING = 5;

    static TYPE_DELIVERY = 6;

    static TYPES = [
        Overview.TYPE_PRODUCT,
        Overview.TYPE_CUSTOMER,
        Overview.TYPE_OPPORTUNITY,
        Overview.TYPE_QUOTE,
        Overview.TYPE_OFFLINE_BOOKING,
        Overview.TYPE_DELIVERY,
    ];

    static TYPE_NAMES = {
        [Overview.TYPE_PRODUCT]: 'Product',
        [Overview.TYPE_CUSTOMER]: 'Customer',
        [Overview.TYPE_OPPORTUNITY]: 'Opportunity',
        [Overview.TYPE_QUOTE]: 'Quote',
        [Overview.TYPE_OFFLINE_BOOKING]: 'Offline booking',
        [Overview.TYPE_DELIVERY]: 'Delivery',
    };

    @Attr() readonly id: number | null;

    @Attr() readonly type: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly description: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean | null;

    readonly relatedConditions: QuerySet<OverviewCondition> | null;

    readonly relatedDisplays: QuerySet<OverviewDisplay> | null;

    /**
     * Transform type code to name
     */
    get typeName(): string {
        return this.type in Overview.TYPE_NAMES ? Overview.TYPE_NAMES[this.type] : '';
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeTypesForSwitchButton(): Array<ISwitchButtonData<number>> {
        return Overview.TYPES.map(r => ({
            title: Overview.TYPE_NAMES[r],
            value: r,
        }));
    }
}
