import { QuoteTemplateStory } from '@aclass/admin/storage/actions';
import {
    INITIAL_QUOTE_TEMPLATE_STATE,
    IQuoteTemplateModuleState,
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const quoteTemplateModuleReducer = (state: TypedState<IQuoteTemplateModuleState> = INITIAL_QUOTE_TEMPLATE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case QuoteTemplateStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case QuoteTemplateStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case QuoteTemplateStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case QuoteTemplateStory.SEARCH_PAGE_UPDATE_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case QuoteTemplateStory.EDIT_PAGE_UPDATE_QUOTE_TEMPLATE_ID:
            return state.update('editPageRecordId', () => payload);
        case QuoteTemplateStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case QuoteTemplateStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);

        default:
            return state;
    }
};
