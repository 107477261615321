<div class="main-container">
    <adm-system-alerts></adm-system-alerts>
    <header class="header header-6">
        <div class="branding">
            <a routerLink="/cms" class="nav-link">
                <span class="title">CMS</span>
            </a>
        </div>
        <div class="header-actions">
            <a href="javascript://" class="nav-link nav-icon" title="Report an issue" (click)="runReport()">
                <clr-icon shape="bug"></clr-icon>
            </a>
            <a routerLink="/ao" class="nav-link nav-icon" title="Switch to ao">
                <clr-icon shape="switch"></clr-icon>
            </a>
            <clr-dropdown>
                <button clrDropdownTrigger title="Profile info" style="margin: 0 .5rem">
                    {{ username }}
                    <clr-icon shape="user" size="24"></clr-icon>
                    <clr-icon shape="caret down" size="12"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a clrDropdownItem routerLink="/cms/profile">Preferences</a>
                    <a href="javascript://" clrDropdownItem (click)="logout()">Log out</a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </header>
    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" class="nav-trigger--bottom">
            <a clrVerticalNavLink routerLink="/cms">
                <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
                Home
            </a>
            <clr-vertical-nav-group *admHasPermission="'manageUsers'" [routerLinkActive]="['active']">
                <clr-icon shape="shield" clrVerticalNavIcon></clr-icon>
                Users & Roles
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink routerLink="/cms/users" [routerLinkActive]="['active']">
                        Manage users
                    </a>
                    <a clrVerticalNavLink routerLink="/cms/roles" [routerLinkActive]="['active']">
                        Manage roles
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <clr-vertical-nav-group [routerLinkActive]="['active']">
                <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
                Settings
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink routerLink="/cms/i18n" [routerLinkActive]="['active']">
                        Internationalization
                    </a>
                    <a clrVerticalNavLink routerLink="/cms/working-hours" [routerLinkActive]="['active']">
                        Office working hours
                    </a>
                    <a clrVerticalNavLink routerLink="/cms/redirects" [routerLinkActive]="['active']">
                        Redirects
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
        </clr-vertical-nav>
    </div>
</div>
<adm-modal-ask></adm-modal-ask>
<adm-report-issue></adm-report-issue>
<adm-image-gallery></adm-image-gallery>
