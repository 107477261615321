import { ItineraryDay, IItineraryDayData } from '@aclass/admin/storage/models/itinerary.day';
import { IOfflineBookingPassengerData } from '@aclass/admin/storage/models/offline.booking.passenger';
import { OptionType } from '@aclass/admin/storage/models/tw.option';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IItineraryRoomData {

    readonly id: number | string;

    readonly dayOptionsId: number | string;

    readonly itineraryDayId: IItineraryDayData['id'];

    readonly roomType: string;

    readonly passengers: Array<Partial<IOfflineBookingPassengerData>>;
}

@Model({
    name: 'ItineraryRoom'
})
export class ItineraryRoom extends Entity<IItineraryRoomData> {

    static TYPE_SINGLE = OptionType.SGL;

    static TYPE_DOUBLE = OptionType.DBL;

    static TYPE_TWIN = OptionType.TWIN;

    static TYPE_TRIPLE = OptionType.TRPL;

    static TYPE_QUAD = OptionType.QUAD;

    static TYPES = [
        ItineraryRoom.TYPE_SINGLE,
        ItineraryRoom.TYPE_DOUBLE,
        ItineraryRoom.TYPE_TWIN,
        ItineraryRoom.TYPE_TRIPLE,
        ItineraryRoom.TYPE_QUAD,
    ];

    static TYPE_NAMES = {
        [ItineraryRoom.TYPE_SINGLE]: 'Single',
        [ItineraryRoom.TYPE_DOUBLE]: 'Double',
        [ItineraryRoom.TYPE_TWIN]: 'Twin',
        [ItineraryRoom.TYPE_TRIPLE]: 'Triple',
        [ItineraryRoom.TYPE_QUAD]: 'Quad',
    };

    static PAX_ALLOCATION = {
        [ItineraryRoom.TYPE_SINGLE]: 1,
        [ItineraryRoom.TYPE_DOUBLE]: 2,
        [ItineraryRoom.TYPE_TWIN]: 2,
        [ItineraryRoom.TYPE_TRIPLE]: 3,
        [ItineraryRoom.TYPE_QUAD]: 4,
    };

    static MAX_CHILDREN_EXTRA_PLACES_QUANTITY = 2;

    @Attr() readonly id: number;

    @Attr() readonly dayOptionsId: number;

    @Attr() readonly roomType: string;

    @Fk({ to: 'ItineraryDay', relatedName: 'relatedRooms' }) readonly itineraryDayId: ItineraryDay;

    get itineraryDay(): ItineraryDay {
        return this.itineraryDayId;
    }

    /**
     * Transform type code to name
     */
    get typeName(): string {
        return this.roomType in ItineraryRoom.TYPE_NAMES ? ItineraryRoom.TYPE_NAMES[this.roomType] : '';
    }

    /**
     * Prepares statuses for select
     */
    static composeTypesForSelect() {
        return ItineraryRoom.TYPES.map(r => ({
            title: ItineraryRoom.TYPE_NAMES[r],
            type: r,
            active: false
        }));
    }
}
