import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class RouterManager {

    static STOP_ALL_EVENT_NAME = 'stop:all';

    readonly rejectNavigation: EventEmitter<string> = new EventEmitter<string>();

    onRejectNavigation = (event: string): Observable<string> => this.rejectNavigation.pipe(filter(v => v === RouterManager.STOP_ALL_EVENT_NAME || v === event ));
}
