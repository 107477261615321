import { EmailPipelineStory } from '@aclass/admin/storage/actions';
import {
    IEmailPipelineModuleState,
    INITIAL_EMAIL_PIPELINE_STATE,
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const emailPipelineModuleReducer = (state: TypedState<IEmailPipelineModuleState> = INITIAL_EMAIL_PIPELINE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case EmailPipelineStory.SEARCH_PAGE_UPDATE_PIPELINE_LIST:
            return state.update('searchPagePipelines', () => fromJS(payload));
        case EmailPipelineStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case EmailPipelineStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case EmailPipelineStory.EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('editPageRecordId', () => payload);
        case EmailPipelineStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case EmailPipelineStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case EmailPipelineStory.EDIT_PAGE_SHOW_SEND_MODAL:
            return state.update('editPageShowSendModal', () => payload);
        case EmailPipelineStory.EDIT_PAGE_UPDATE_MODAL_FORM:
            return state.update('editPageModalForm', () => fromJS(payload));
        default:
            return state;
    }
};
