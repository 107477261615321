import {
    IAdditionalProductData,
    ICmsHotelData,
    ICmsSmalltourData,
    IColorRangeSettings,
    ICombinationRevisionData,
    IDestinationRevisionData,
    IEmailTemplateData, IPCTourPackageData,
    IQuoteGalleryData,
    IQuotePreviewData,
    ITourRevisionData
} from '@aclass/admin/storage/models';
import { IQuoteModuleState } from '@aclass/admin/storage/states';
import { IQuoteBuildParams } from '@aclass/admin/utils';
import { ISearchCampaign } from '@aclass/admin/utils/quotes';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { IUserData } from '@aclass/core/storage/models/user';

@Story({
    name: 'quote'
})
export class QuoteStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_ROUTER_MODE: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_UPDATE_PIPELINE_LEVELS: string;

    @Task() static SEARCH_PAGE_UPDATE_ASSIGNED: string;

    @Task() static SEARCH_PAGE_UPDATE_PACKAGES: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_PACKAGE_SEARCH: string;

    @Task() static SEARCH_PAGE_PIPELINE_LEVEL_SEARCH: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_SWITCH_REVISION_LOCK: string;

    @Task() static EDIT_PAGE_FORM_STATUS: string;

    @Task() static EDIT_PAGE_BUILD_REVISION: string;

    @Task() static EDIT_PAGE_UPDATE_RESOLVER_ID: string;

    @Task() static EDIT_PAGE_UPDATE_ACTIVE_STATE: string;

    @Task() static EDIT_PAGE_MERGE_PREPARED_STATE: string;

    @Task() static EDIT_PAGE_REMOVE_PREPARED_STATE: string;

    @Task() static EDIT_PAGE_CLEAR_PREPARED_STATES: string;

    @Task() static EDIT_PAGE_MERGE_EDITED_STATE: string;

    @Task() static EDIT_PAGE_REMOVE_EDITED_STATE: string;

    @Task() static EDIT_PAGE_CLEAR_EDITED_STATES: string;

    @Task() static EDIT_PAGE_UPDATE_OPPORTUNITY_ID: string;

    @Task() static EDIT_PAGE_UPDATE_REVISION_ID: string;

    @Task() static EDIT_PAGE_UPDATE_COLOR_RANGE: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_TOGGLE_GALLERY: string;

    @Task() static EDIT_PAGE_REMOVE: string;

    @Task() static EDIT_PAGE_PREVIEW: string;

    @Task() static EDIT_PAGE_RECALCULATE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_CAMPAIGN_SEARCH: string;

    @Task() static EDIT_PAGE_ADDITIONAL_PRODUCT_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_RECORDS_BONUSES: string;

    @Task() static EDIT_PAGE_UPDATE_RECORDS_DISCOUNTS: string;

    @Task() static EDIT_PAGE_UPDATE_RECORDS_ADDITIONAL_PRODUCTS: string;

    @Task() static EDIT_PAGE_UPDATE_CAMPAIGN_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_ADDITIONAL_PRODUCT_SEARCH_LOCK: string;

    @Task() static SEND_EMAIL_OPEN: string;

    @Task() static SEND_EMAIL_TOGGLE_OPEN: string;

    @Task() static SEND_EMAIL_UPDATE_SWITCH_LOCK: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATE: string;

    @Task() static SEND_EMAIL_UPDATE_OPPORTUNITY_ID: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATE_ID: string;

    @Task() static SEND_EMAIL_UPDATE_REVISION_NR: string;

    @Task() static SEND_EMAIL_UPDATE_FORM: string;

    @Task() static SEND_EMAIL_UPDATE_EMAIL_TEMPLATES: string;

    @Task() static SEND_EMAIL_EMAIL_TEMPLATES_SEARCH: string;

    @Task() static SEND_EMAIL_UPDATE_EMAIL_TEMPLATES_SEARCH_LOCK: string;

    @Task() static SEND_EMAIL_UPDATE_FETCH_LOCK: string;

    @Task() static SEND_EMAIL_FETCH_EMAIL_DATA: string;

    @Task() static SEND_EMAIL_SELECT_DOCUMENT: string;

    @Task() static SEND_EMAIL_UPDATE_SELECTED_DOCUMENTS: string;

    @Task() static SEND_EMAIL_UPDATE_SAVE_LOCK: string;

    @Task() static SEND_EMAIL_SUBMIT: string;

    @Task() static GALLERY_PICKER_TOGGLE_OPEN: string;

    @Task() static GALLERY_PICKER_DRY_RUN: string;

    @Task() static GALLERY_PICKER_UPDATE_OPPORTUNITY_ID: string;

    @Task() static GALLERY_PICKER_UPDATE_SEARCH_LOCK: string;

    @Task() static GALLERY_PICKER_UPDATE_RECORDS: string;

    @Task() static GALLERY_PICKER_SEARCH: string;

    @Task() static GALLERY_PICKER_UPDATE_FORM: string;

    @Task() static GALLERY_PICKER_SUBMIT: string;

    @Task() static HOTEL_PICKER_DRY_RUN: string;

    @Task() static SMALLTOUR_PICKER_DRY_RUN: string;

    @Task() static HOTEL_PICKER_UPDATE_OPPORTUNITY_ID: string;

    @Task() static SMALLTOUR_PICKER_UPDATE_OPPORTUNITY_ID: string;

    @Task() static HOTEL_PICKER_UPDATE_SEARCH_LOCK: string;

    @Task() static HOTEL_PICKER_UPDATE_RECORDS: string;

    @Task() static HOTEL_PICKER_SEARCH: string;

    @Task() static HOTEL_PICKER_GET_ALL: string;

    @Task() static SMALLTOUR_PICKER_UPDATE_SEARCH_LOCK: string;

    @Task() static SMALLTOUR_PICKER_UPDATE_RECORDS: string;

    @Task() static SMALLTOUR_PICKER_SEARCH: string;

    @Task() static CHANGE_TOUR_TOGGLE_OPEN: string;

    @Task() static CHANGE_TOUR_DRY_RUN: string;

    @Task() static CHANGE_TOUR_UPDATE_OPPORTUNITY_ID: string;

    @Task() static CHANGE_TOUR_UPDATE_FORM: string;

    @Task() static CHANGE_TOUR_UPDATE_DESTINATION_SEARCH_LOCK: string;

    @Task() static CHANGE_TOUR_UPDATE_TOUR_SEARCH_LOCK: string;

    @Task() static CHANGE_TOUR_UPDATE_COMBINATION_SEARCH_LOCK: string;

    @Task() static CHANGE_TOUR_UPDATE_DESTINATIONS: string;

    @Task() static CHANGE_TOUR_UPDATE_TOURS: string;

    @Task() static CHANGE_TOUR_UPDATE_COMBINATIONS: string;

    @Task() static CHANGE_TOUR_SEARCH_DESTINATIONS: string;

    @Task() static CHANGE_TOUR_SEARCH_TOURS: string;

    @Task() static CHANGE_TOUR_SEARCH_COMBINATIONS: string;

    @Task() static CHANGE_TOUR_UPDATE_FETCH_LOCK: string;

    @Task() static CHANGE_TOUR_UPDATE_CMS_TOUR_ID: string;

    @Task() static CHANGE_TOUR_FETCH_TOUR_DATA: string;

    @Task() static CHANGE_TOUR_SUBMIT: string;

    @Task() static RETRIEVE_PNR: string;

    @Task() static EDIT_PAGE_UPDATE_EXTRA_PRICES_SELECT: string;

    @Task() static EDIT_PAGE_SEARCH_FILTERED_EXTRA_PRICES: string;

    static dryRunSearchPage(): Action {
        return { type: QuoteStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IQuoteModuleState['searchPageInitialized']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updatePipelineLevelsSearchPage(payload: Array<IPipelineLevelData['id']>): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVELS, payload };
    }

    static updateAssignedSearchPage(payload: Array<IUserData['id']>): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_ASSIGNED, payload };
    }

    static updatePackagesSearchPage(payload: Array<IPCTourPackageData['id']>): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_PACKAGES, payload };
    }

    static updateCollapsedSearchPage(payload: IQuoteModuleState['searchPageCollapsed']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateRouterModeSearchPage(payload: IQuoteModuleState['searchPageRouteMode']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_ROUTER_MODE, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: IQuoteModuleState['searchPageLock']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateSearchPackageLockOnSearchPage(payload: IQuoteModuleState['searchPagePackageSearchLock']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_PACKAGE_SEARCH_LOCK, payload };
    }

    static updateSearchPipelineLevelLockOnSearchPage(payload: IQuoteModuleState['searchPagePipelineLevelSearchLock']): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_PIPELINE_LEVEL_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: QuoteStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<IQuotePreviewData['id']>): Action {
        return { type: QuoteStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static searchPackagesOnSearchPage(payload?: string): Action {
        return { type: QuoteStory.SEARCH_PAGE_PACKAGE_SEARCH, payload };
    }

    static searchPipelineLevelSearchPage(payload?: string): Action {
        return { type: QuoteStory.SEARCH_PAGE_PIPELINE_LEVEL_SEARCH, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }) {
        return { type: QuoteStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunEditPage(payload: IQuoteBuildParams & { id: IOpportunityData['id'] }): Action {
        return { type: QuoteStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateSwitchRevisionLockEditPage(payload: IQuoteModuleState['editPageSwitchRevisionLock']): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_SWITCH_REVISION_LOCK, payload };
    }

    static updatePageFormEditStatus(payload: IQuoteModuleState['editPageFormEditStatus']): Action {
        return { type: QuoteStory.EDIT_PAGE_FORM_STATUS, payload };
    }

    static buildRevisionEditPage(payload: IQuoteBuildParams): Action {
        return { type: QuoteStory.EDIT_PAGE_BUILD_REVISION, payload };
    }

    static updateResolverIdEditPage(payload: IQuoteModuleState['editPageResolverId'] | null): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_RESOLVER_ID, payload };
    }

    static updateActiveStateEditPage(payload: IQuoteModuleState['editPageActiveState']): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_ACTIVE_STATE, payload };
    }

    static mergePreparedStateEditPage(payload: { [key: string]: any }): Action {
        return { type: QuoteStory.EDIT_PAGE_MERGE_PREPARED_STATE, payload };
    }

    static removePreparedStateEditPage(payload: string): Action {
        return { type: QuoteStory.EDIT_PAGE_REMOVE_PREPARED_STATE, payload };
    }

    static clearPreparedStatesEditPage(): Action {
        return { type: QuoteStory.EDIT_PAGE_CLEAR_PREPARED_STATES };
    }

    static mergeEditedStateEditPage(payload: { [key: string]: any }): Action {
        return { type: QuoteStory.EDIT_PAGE_MERGE_EDITED_STATE, payload };
    }

    static removeEditedStateEditPage(payload: string): Action {
        return { type: QuoteStory.EDIT_PAGE_REMOVE_EDITED_STATE, payload };
    }

    static clearEditedStatesEditPage(): Action {
        return { type: QuoteStory.EDIT_PAGE_CLEAR_EDITED_STATES };
    }

    static updateOpportunityIdEditPage(payload: IQuoteModuleState['editPageOpportunityId']): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateRevisionIdEditPage(payload: IQuoteModuleState['editPageRevisionId']): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_REVISION_ID, payload };
    }

    static updateColorRangeEditPage(payload: IColorRangeSettings | null): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_COLOR_RANGE, payload };
    }

    static updateFormEditPage(payload): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IQuoteModuleState['editPageSaveLock']): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static toggleGalleryEditPage(payload: IQuoteData['gallery']): Action {
        return { type: QuoteStory.EDIT_PAGE_TOGGLE_GALLERY, payload };
    }

    static removeEditPage(): Action {
        return { type: QuoteStory.EDIT_PAGE_REMOVE };
    }

    static previewOnEditPage(): Action {
        return { type: QuoteStory.EDIT_PAGE_PREVIEW };
    }

    static recalculateOnEditPage(payload: { recalculateSale: boolean, flightPriceLocked: boolean }): Action {
        return { type: QuoteStory.EDIT_PAGE_RECALCULATE, payload };
    }

    static submitOnEditPage(payload: boolean): Action {
        return { type: QuoteStory.EDIT_PAGE_SUBMIT, payload };
    }

    static searchCampaignEditPage(payload: ISearchCampaign): Action {
        return { type: QuoteStory.EDIT_PAGE_CAMPAIGN_SEARCH, payload };
    }

    static searchAdditionalProductsEditPage(payload?: string): Action {
        return { type: QuoteStory.EDIT_PAGE_ADDITIONAL_PRODUCT_SEARCH, payload };
    }

    static updateRecordsDiscountsEditPage(ids: Array<string>): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_RECORDS_DISCOUNTS, payload: ids };
    }

    static updateRecordsAdditionalProductsEditPage(payload: Array<IAdditionalProductData['id']>): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_RECORDS_ADDITIONAL_PRODUCTS, payload };
    }

    static updateSearchCampaignLockOnEditPage(payload: null | boolean): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_CAMPAIGN_SEARCH_LOCK, payload };
    }

    static updateSearchAdditionalProductLockOnEditPage(payload: null | boolean): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_ADDITIONAL_PRODUCT_SEARCH_LOCK, payload };
    }

    static updateRecordsBonusesEditPage(ids: Array<string>): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_RECORDS_BONUSES, payload: ids };
    }

    static openSendEmail(payload: [IOpportunityData['id'], IQuoteData['revision']?]): Action {
        return { type: QuoteStory.SEND_EMAIL_OPEN, payload };
    }

    static toggleOpenSendEmail(payload: IQuoteModuleState['sendEmailOpen']): Action {
        return { type: QuoteStory.SEND_EMAIL_TOGGLE_OPEN, payload };
    }

    static toggleSwitchLockSendEmail(payload: IQuoteModuleState['sendEmailSwitchLock']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_SWITCH_LOCK, payload };
    }

    static updateTemplateSendEmail(payload: IEmailTemplateData): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_TEMPLATE, payload };
    }

    static updateOpportunityIdSendEmail(payload: IQuoteModuleState['sendEmailOpportunityId']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateTemplateIdSendEmail(payload: IQuoteModuleState['sendEmailTemplateId']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_TEMPLATE_ID, payload };
    }

    static updateRevisionNrSendEmail(payload: IQuoteModuleState['sendEmailRevisionNr']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_REVISION_NR, payload };
    }

    static updateFormSendEmail(payload): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_FORM, payload };
    }

    static updateSendEmailTemplates(payload: Array<IEmailTemplateData['id']>): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_EMAIL_TEMPLATES, payload };
    }

    static searchEmailTemplatesSendEmail(payload?: string): Action {
        return { type: QuoteStory.SEND_EMAIL_EMAIL_TEMPLATES_SEARCH, payload };
    }

    static updateSendEmailTemplatesLock(payload: boolean | null): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_EMAIL_TEMPLATES_SEARCH_LOCK, payload };
    }

    static updateFetchLockSendEmail(payload: IQuoteModuleState['sendEmailFetchLock']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_FETCH_LOCK, payload };
    }

    static fetchEmailDataSendEmail(): Action {
        return { type: QuoteStory.SEND_EMAIL_FETCH_EMAIL_DATA };
    }

    static selectDocumentSendEmail(payload: string): Action {
        return { type: QuoteStory.SEND_EMAIL_SELECT_DOCUMENT, payload };
    }

    static updateSelectedDocumentsSendEmail(payload: Array<string>): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_SELECTED_DOCUMENTS, payload };
    }

    static updateSaveLockSendEmail(payload: IQuoteModuleState['sendEmailSaveLock']): Action {
        return { type: QuoteStory.SEND_EMAIL_UPDATE_SAVE_LOCK, payload };
    }

    static submitSendEmail(payload?: Array<{ id: string, file: File }>) {
        return { type: QuoteStory.SEND_EMAIL_SUBMIT, payload };
    }

    static toggleOpenGalleryPicker(payload: IQuoteModuleState['galleryPickerOpen']): Action {
        return { type: QuoteStory.GALLERY_PICKER_TOGGLE_OPEN, payload };
    }

    static dryRunGalleryPicker(payload: IQuoteModuleState['galleryPickerOpportunityId']): Action {
        return { type: QuoteStory.GALLERY_PICKER_DRY_RUN, payload };
    }

    static updateOpportunityIdGalleryPicker(payload: IQuoteModuleState['galleryPickerOpportunityId']): Action {
        return { type: QuoteStory.GALLERY_PICKER_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateSearchLockGalleryPicker(payload: IQuoteModuleState['galleryPickerSearchLock']): Action {
        return { type: QuoteStory.GALLERY_PICKER_UPDATE_SEARCH_LOCK, payload };
    }

    static updateRecordsGalleryPicker(payload: Array<IQuoteGalleryData['id']>): Action {
        return { type: QuoteStory.GALLERY_PICKER_UPDATE_RECORDS, payload };
    }

    static searchGalleryPicker(payload?: string): Action {
        return { type: QuoteStory.GALLERY_PICKER_SEARCH, payload };
    }

    static updateFormGalleryPicker(payload): Action {
        return { type: QuoteStory.GALLERY_PICKER_UPDATE_FORM, payload };
    }

    static submitGalleryPicker(payload: boolean): Action {
        return { type: QuoteStory.GALLERY_PICKER_SUBMIT, payload };
    }

    static dryRunHotelPicker(payload: IQuoteModuleState['hotelPickerOpportunityId']): Action {
        return { type: QuoteStory.HOTEL_PICKER_DRY_RUN, payload };
    }

    static dryRunSmalltourPicker(payload: IQuoteModuleState['smalltourPickerOpportunityId']): Action {
        return { type: QuoteStory.SMALLTOUR_PICKER_DRY_RUN, payload };
    }

    static updateOpportunityIdHotelPicker(payload: IQuoteModuleState['hotelPickerOpportunityId']): Action {
        return { type: QuoteStory.HOTEL_PICKER_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateOpportunityIdSmalltourPicker(payload: IQuoteModuleState['smalltourPickerOpportunityId']): Action {
        return { type: QuoteStory.SMALLTOUR_PICKER_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateSearchLockHotelPicker(payload: IQuoteModuleState['hotelPickerSearchLock']): Action {
        return { type: QuoteStory.HOTEL_PICKER_UPDATE_SEARCH_LOCK, payload };
    }


    static updateSearchLockSmalltourPicker(payload: IQuoteModuleState['smalltourPickerSearchLock']): Action {
        return { type: QuoteStory.SMALLTOUR_PICKER_UPDATE_SEARCH_LOCK, payload };
    }

    static updateRecordsHotelPicker(payload: Array<ICmsHotelData['id']>): Action {
        return { type: QuoteStory.HOTEL_PICKER_UPDATE_RECORDS, payload };
    }

    static updateRecordsSmalltourPicker(payload: Array<ICmsSmalltourData['id']>): Action {
        return { type: QuoteStory.SMALLTOUR_PICKER_UPDATE_RECORDS, payload };
    }

    static searchHotelPicker(): Action {
        return { type: QuoteStory.HOTEL_PICKER_SEARCH };
    }

    static searchSmalltourPicker(): Action {
        return { type: QuoteStory.SMALLTOUR_PICKER_SEARCH };
    }

    static searchAllHotelPicker(): Action {
        return { type: QuoteStory.HOTEL_PICKER_GET_ALL };
    }

    static toggleOpenChangeTour(payload: IQuoteModuleState['changeTourOpen']): Action {
        return { type: QuoteStory.CHANGE_TOUR_TOGGLE_OPEN, payload };
    }

    static dryRunChangeTour(payload: IQuoteModuleState['changeTourOpportunityId']): Action {
        return { type: QuoteStory.CHANGE_TOUR_DRY_RUN, payload };
    }

    static updateOpportunityIdChangeTour(payload: IQuoteModuleState['changeTourOpportunityId']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_OPPORTUNITY_ID, payload };
    }

    static updateFormChangeTour(payload): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_FORM, payload };
    }

    static updateDestinationSearchLockChangeTour(payload: IQuoteModuleState['changeTourDestinationSearchLock']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_DESTINATION_SEARCH_LOCK, payload };
    }

    static updateTourSearchLockChangeTour(payload: IQuoteModuleState['changeTourTourSearchLock']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_TOUR_SEARCH_LOCK, payload };
    }

    static updateCombinationSearchLockChangeTour(payload: IQuoteModuleState['changeTourCombinationSearchLock']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_COMBINATION_SEARCH_LOCK, payload };
    }

    static updateDestinationsChangeTour(payload: Array<IDestinationRevisionData['id']>): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_DESTINATIONS, payload };
    }

    static updateToursChangeTour(payload: Array<ITourRevisionData['id']>): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_TOURS, payload };
    }

    static updateCombinationsChangeTour(payload: Array<ICombinationRevisionData['id']>): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_COMBINATIONS, payload };
    }

    static searchDestinationsChangeTour(payload?: string): Action {
        return { type: QuoteStory.CHANGE_TOUR_SEARCH_DESTINATIONS, payload };
    }

    static searchToursChangeTour(payload?: string): Action {
        return { type: QuoteStory.CHANGE_TOUR_SEARCH_TOURS, payload };
    }

    static searchCombinationsChangeTour(payload?: string): Action {
        return { type: QuoteStory.CHANGE_TOUR_SEARCH_COMBINATIONS, payload };
    }

    static updateFetchLockChangeTour(payload: IQuoteModuleState['changeTourFetchLock']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_FETCH_LOCK, payload };
    }

    static updateCmsTourIdChangeTour(payload: IQuoteModuleState['changeTourCmsTourId']): Action {
        return { type: QuoteStory.CHANGE_TOUR_UPDATE_CMS_TOUR_ID, payload };
    }

    static fetchTourDataChangeTour(): Action {
        return { type: QuoteStory.CHANGE_TOUR_FETCH_TOUR_DATA };
    }

    static submitChangeTour(payload: boolean): Action {
        return { type: QuoteStory.CHANGE_TOUR_SUBMIT, payload };
    }

    static retrievePnr(payload: string): Action {
        return { type: QuoteStory.RETRIEVE_PNR, payload };
    }

    static editPageUpdateExtraPricesSelect(payload): Action {
        return { type: QuoteStory.EDIT_PAGE_UPDATE_EXTRA_PRICES_SELECT, payload };
    }

    static editPageSearchFilteredExtraPrices(payload): Action {
        return { type: QuoteStory.EDIT_PAGE_SEARCH_FILTERED_EXTRA_PRICES, payload };
    }
}
