import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'PipelineLevel'
})
export class PipelineLevel extends Entity<IPipelineLevelData> {

    static PIPELINE_LEVEL_PHONE_CALL = 4;

    static LEVEL_WON = 13;
    static LEVEL_3_DAYS = 5;
    static LEVEL_28_DAYS = 6;
    static LEVEL_3_MONTH = 7;
    static LEVEL_6_MONTH = 8;

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly days: number | null;

    @Attr() readonly levelCriteria: string | null;

    @Attr() readonly consider: string | null;

    @Attr() readonly criteriaNextLevel: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean | null;

    @Attr() readonly paused: boolean | null;

    readonly relatedOpportunities: QuerySet<Opportunity>;

    /**
     * Convert model to paste it to ui selects
     */
    composeHeadlessData = (): Pick<IPipelineLevelData, 'id' | 'name'> => ({ id: this.id, name: this.name });
}
