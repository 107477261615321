<ng-template #header>
    <form [attach]="ff.attach" [formGroup]="ff.form" (ngSubmit)="runSearch()" novalidate>
        <div class="clr-row">
            <div class="clr-col-2">
                <label>Username</label>
                <input type="text" trim="blur" class="form-control form-control-sm" formControlName="username"/>
            </div>
            <div class="clr-col-2">
                <label>Email</label>
                <input type="text" trim="blur" class="form-control form-control-sm" formControlName="email"/>
            </div>
            <div class="clr-col-2">
                <label>Role</label>
                <input type="text" trim="blur" class="form-control form-control-sm" formControlName="role"/>
            </div>
            <div class="clr-col-2">
                <label>Show deactivated</label>
                <br>
                <adm-checkbox-button formControlName="showDeactivated"></adm-checkbox-button>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col text-right">
                <div class="btn-group btn-sm">
                    <button [clrLoading]="loading" type="button" class="btn btn-info-outline" (click)="runSearch(true)">
                        <clr-icon shape="repeat"></clr-icon>
                        Reset
                    </button>
                    <button [clrLoading]="loading" type="submit" class="btn btn-info-outline">
                        <clr-icon shape="search"></clr-icon>
                        Search
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<adm-content-layout [collapsible]="true" [header]="header" [(collapsed)]="collapsed" [showProgress]="showProgress">
    <adm-dg-layout [(pagination)]="pagination" [(order)]="order">
    <clr-datagrid class="mb-1" [clrDgLoading]="loading">
        <clr-dg-placeholder>We couldn't find any user!</clr-dg-placeholder>
        <clr-dg-action-bar>
            <div class="btn-group">
                <a class="btn btn-sm btn-secondary" [routerLink]="['create']">
                    <clr-icon shape="plus"></clr-icon> Add new
                </a>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column [clrDgSortBy]="'username'">Username</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'email'">Email</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'role'">Role</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'createAt'">Created</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'updateAt'">Updated</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'deleted'">Active</clr-dg-column>
        <clr-dg-row *ngFor="let r of records" [admDgLink]="['edit', r.id]">
            <clr-dg-cell>
                <adm-dg-link>{{ r.username }}</adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link>{{ r.email }}</adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link>
                    {{ r.role?.name }}
                    <clr-tooltip *ngIf="r.role">
                        <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                        <clr-tooltip-content clrPosition="right" clrSize="lg" *clrIfOpen>
                            <div class="text-center" [innerHtml]="describePermissions(r.role)"></div>
                        </clr-tooltip-content>
                    </clr-tooltip>
                </adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link>{{ r.createdAt | fromTimestamp | dateTimeFormat }}</adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link>{{ r.updatedAt | fromTimestamp | dateTimeFormat }}</adm-dg-link>
            </clr-dg-cell>
            <clr-dg-cell>
                <adm-dg-link>
                    <clr-icon shape="check" class="text-success" [hidden]="r.deleted"></clr-icon>
                    <clr-icon shape="times" class="text-danger" [hidden]="!r.deleted"></clr-icon>
                </adm-dg-link>
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <adm-dg-pagination [hidden]="!pagination.totalCount"></adm-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
    </adm-dg-layout>
</adm-content-layout>