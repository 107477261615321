import { OrmManager } from '@aclass/admin/managers';
import { EmailPipelineEditStateResolver } from '@aclass/admin/resolvers';
import { viewDocumentRs } from '@aclass/admin/rest/responses';
import {
    EmailPipelineStory, OfflineBookingEmailStory,
    OrmStory, SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IEmailPipelineData } from '@aclass/admin/storage/models';
import { IAdminState, IEmailPipelineModuleState } from '@aclass/admin/storage/states';
import { readToBase64, tryToSaveFile, IFileData } from '@aclass/core/base/file.reader';
import { Instance } from '@aclass/core/base/instance';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const emailPipelineModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(EmailPipelineStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.emailPipelineModule.get('searchPageInitialized')),
        switchMap(() => {

            return http.post('email-pipelines/pipeline-search', { }).pipe(
                dispatchActions((rs: Response<any>) => {
                    return [
                        // Reset prev records state
                        EmailPipelineStory.updatePipelineListOnSearchPage(rs.body),
                        EmailPipelineStory.updateFormSearchPage({ }),
                        EmailPipelineStory.importRecordsOnSearchPage([]),
                        EmailPipelineStory.updatePaginationOnSearchPage(null),
                        EmailPipelineStory.updateOrderOnSearchPage(null),
                        EmailPipelineStory.updateSearchLockOnSearchPage(null),
                        EmailPipelineStory.updateCollapsedSearchPage(null),
                        // Unlock page
                        EmailPipelineStory.updateSearchPageInitState(true),
                    ];
                })
            );
        })
    ),
    action.pipe(
        ofType(EmailPipelineStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.emailPipelineModule;
            const rq: DataSearchRqData = {
                where: {
                    pipeline: module.getIn(['searchPageForm', 'pipeline']),
                    model: 'EmailPipeline'
                },
                pagination: new Pagination().all(),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(EmailPipelineStory.updateSearchLockOnSearchPage(true)), http.post('email-pipelines/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailPipelineData>>>) => {

                    return [
                        OrmStory.populateEmailPipelines(rs.body.results),
                        EmailPipelineStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        EmailPipelineStory.updatePaginationOnSearchPage(rs.body.pagination),
                        EmailPipelineStory.updateOrderOnSearchPage(rs.body.order),
                        EmailPipelineStory.updateSearchLockOnSearchPage(false),
                    ];
                })
            ));
        }),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IEmailPipelineModuleState['editPageRecordId']>) => state.value.emailPipelineModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IEmailPipelineModuleState['editPageRecordId']>) => {
            const [locale, pipeline] = payload.split('_');
            const rq: DataSearchRqData = {
                where: {
                    model: 'EmailPipeline',
                    locale: [locale],
                    pipeline
                }
            };

            return http.post(`email-pipelines/search`, rq).pipe(
                dispatchActions(
                    (rs: Response<IDataSearchRs<Array<IEmailPipelineData>>>) => {
                        const filteredById = rs.body.results.filter(r => r.id === payload);
                        const documents = filteredById[0].documents;
                        const documentsMapping = documents.map(({ id: documentId }) => ({
                            templateId: filteredById[0].id,
                            documentId
                        }));

                        return [
                            OrmStory.populateDocuments(documents),
                            OrmStory.truncateDocumentEmailTemplateMappings(),
                            OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                            EmailPipelineStory.editPageUpdateModalForm({ }),
                            EmailPipelineStory.updateRecordIdEditPage(payload),
                            EmailPipelineStory.updateFormOnEditPage({ }),
                            OrmStory.populateEmailPipelines(filteredById),
                        ];
                    },
                    () => {
                        return [
                            SystemStory.stopNavigate(EmailPipelineEditStateResolver.STOP_EVENT_NAME),
                            SystemStory.navigate(['email-pipelines'], { sharedLink: true })
                        ];
                    }
                )
            );
        }),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION),
        mergeMap(({ payload }: Action<{ files: Array<File>, templateName: string | null, mailType: string | null }>) => {
            const module = state.value.emailPipelineModule;
            const id = module.get('editPageRecordId');
            const [locale, pipeline] = id.split('_');
            // all brands templates stored in africa brand
            const instanceId = `${Instance.BRAND_AFRICA}_${locale}`;
            const templateName = `EmailPipeline::${pipeline}`;
            const params = instanceManager.buildHeaders([module.get('editPageBrand'), module.get('editPageLocale')]);
            const files = payload.files;

            return concat(of(OfflineBookingEmailStory.updateUploadDocumentsLockEditPage(true)), zip(...files.map(readToBase64)).pipe(
                switchMap((results: Array<IFileData>) => concat(http.post('document-email-message-template', { documents: results, type: payload.mailType, instanceId, ...{ templateName } }, params)).pipe(
                    switchMap((rs: viewDocumentRs) => {
                            const collection: Array<any> = rs.body.documents;
                            const actions = [
                                OfflineBookingEmailStory.updateUploadDocumentsLockEditPage(false)
                            ];

                            if (!collection.length) {
                                return actions;
                            }
                            const documentsMapping = collection.map(({ id: documentId }) => ({
                                templateId:  id,
                                documentId
                            }));

                            return [
                                OrmStory.populateDocuments(collection),
                                OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_PIPELINE_TEMPLATE),
                                SystemStory.showNotification(SystemNotification.success('Success', `Document ${ collection.length > 1 ? 's' : ''} uploaded`)),
                            ];
                        }
                    ))
                ))
            );
        }),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const id = state.value.emailPipelineModule.get('editPageRecordId');
            const [locale, pipeline] = id.split('_');
            const form = state.value.emailPipelineModule.get('editPageForm');
            const result = {
                locale,
                pipeline,
                form
            };

            return concat(of(EmailPipelineStory.updateSaveLockEditPage(true)), http.put(`email-pipelines/update`, result).pipe(
                dispatchActions(
                    (rs: Response<IEmailPipelineData>) => [
                        EmailPipelineStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Email pipeline updated`)),
                        OrmStory.populateEmailPipelines([rs.body])
                    ],
                    () => [
                        EmailPipelineStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_SEND),
        mergeMap(() => {
            const id = state.value.emailPipelineModule.get('editPageRecordId');
            const [locale, pipeline] = id.split('_');
            const form = state.value.emailPipelineModule.get('editPageModalForm').toJS();
            const mainForm = state.value.emailPipelineModule.get('editPageForm').toJS();
            const result = {
                email: {
                    aSignature: mainForm.aSignature,
                    body: mainForm.body,
                    pipelineLevel: parseInt(pipeline),
                    subject: mainForm.subject,
                },
                customer: {
                    name: form.name,
                    surname: form.surname,
                    email: form.email,
                },
                tourName: form.tourName
            };
            const params = instanceManager.buildHeaders([form.brand, locale]);

            return concat(of(EmailPipelineStory.updateSaveLockEditPage(true)), http.post(`email-pipelines/preview`, result, params).pipe(
                dispatchActions(
                    () => [
                        EmailPipelineStory.updateSaveLockEditPage(false),
                        EmailPipelineStory.showSendModalOnEditPage(false),
                        EmailPipelineStory.editPageUpdateModalForm({ }),
                        SystemStory.showNotification(SystemNotification.success('Success', `Email was sent`)),
                    ],
                    () => [
                        EmailPipelineStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.error('Error', `Email wasn't sent`)),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_REMOVE_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(EmailPipelineStory.updatePickedDocumentEditPage(payload)), http.delete(`document-email-message-template/${ payload }`).pipe(
            dispatchActions(() => [
                EmailPipelineStory.updatePickedDocumentEditPage(null),
                OrmStory.dropDocuments([payload]),
                OrmStory.reloadModel(OrmManager.RELOAD_EMAIL_PIPELINE_TEMPLATE),
                SystemStory.showNotification(SystemNotification.success('Success', 'Document removed'))
            ])
        ))),
    ),
    action.pipe(
        ofType(EmailPipelineStory.EDIT_PAGE_DOWNLOAD_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(EmailPipelineStory.updatePickedDocumentEditPage(payload)), http.get(`document-email-message-template/download/${ payload }`, { observe: 'response', responseType: 'text' }).pipe(
            mergeMap((rs: HttpResponse<string>) => {
                tryToSaveFile(rs);

                return of(EmailPipelineStory.updatePickedDocumentEditPage(null));
            })
        ))),
    ),
);
