import { RootStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { merge } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';

export const rootModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { formManager }: IEpicDi) => merge(
    action.pipe(
        ofType(RootStory.RESET_FORM),
        tap(({ payload }: Action) => {
            formManager.reset(payload);
        }),
        ignoreElements()
    ),
    action.pipe(
        ofType(RootStory.AFTER_SAVE_FORM),
        tap(({ payload }: Action) => {
            formManager.afterSave(payload);
        }),
        ignoreElements()
    ),
);
