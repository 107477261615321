import { Calculator } from '@aclass/core/base/calculator';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat',
})

export class NumberFormatPipe implements PipeTransform {

    /**
     * Default separator for the decimal point
     */
    static DS = '.';

    /**
     * Default thousands separator
     */
    static TS = '';

    /**
     * Default number of decimal points
     */
    static DECIMALS = 2;

    /**
     * Converts any input to number
     */
    static normalizeNumericValue(v: any): number {
        if (typeof v === 'object' && !Array.isArray(v)) {
            // Use native valueof
            v = Number(v);
        }
        if (!Calculator.isNumeric(v)) {
            return 0;
        }

        return parseFloat(v);
    }

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order, decimal separator, thousands separator, decimal points, removes trailing zero(s) flag
     */
    transform(value: any, ...args: any[]): string {
        const [ds = NumberFormatPipe.DS, ts = NumberFormatPipe.TS, decimals = NumberFormatPipe.DECIMALS, trim = true] = args;
        const v = NumberFormatPipe.normalizeNumericValue(value);
        const negative = v < 0 ? '-' : '';
        const [intPart, restPart] = String(Math.abs(v).toFixed(decimals)).split('.');
        const parts = intPart.length > 3 ? [intPart.substring(0, intPart.length - 3), ts, intPart.substr(-3)] : [intPart];
        if (restPart && (restPart !== '0'.repeat(decimals) || !trim)) {
            parts.push(ds);
            parts.push(restPart);
        }

        return negative + parts.join('');
    }
}
