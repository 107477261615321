import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { DestinationRevision } from '@aclass/admin/storage/models/destination.revision';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { IPCTourData, PCTour } from '@aclass/admin/storage/models/pc.tour';
import { Quote } from '@aclass/admin/storage/models/quote';
import { ITourData, Tour } from '@aclass/admin/storage/models/tour';
import { Attr, Fk, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ITourRevisionData {

    readonly id: string | null;

    readonly link: string | null;

    readonly name: string | null;

    readonly pcTourId: IPCTourData['id'] | null;

    readonly tourId: ITourData['id'] | null;
}

@Model({
    name: 'TourRevision'
})
export class TourRevision extends Entity<ITourRevisionData> {

    @Attr() readonly id: string | null;

    @Attr() readonly name: string | null;

    @Attr() readonly link: string | null;

    @Fk({ to: 'PCTour', relatedName: 'relatedTourRevisions' }) readonly pcTourId: PCTour | null;

    @Fk({ to: 'Tour', relatedName: 'tourRevisions' }) readonly tourId: Tour | null;

    @Many({ to: 'DestinationRevision', relatedName: 'relatedTours', through: 'DestinationToTourRevisionMapping', throughFields: ['tourRevisionId', 'destinationRevisionId'] }) readonly relatedDestinations: QuerySet<DestinationRevision>;

    @Many({ to: 'CombinationRevision', relatedName: 'relatedTours', through: 'CombinationToTourRevisionMapping', throughFields: ['tourRevisionId', 'combinationRevisionId'] }) readonly relatedCombinations: QuerySet<CombinationRevision>;

    readonly relatedOpportunities: QuerySet<Opportunity>;

    readonly relatedQuotes: QuerySet<Quote>;
}

