import {
    AbstractControl,
    FormArray,
    FormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';

export interface IFormErrorData {

    /**
     * Path from root.
     */
    path: Array<string>;

    /**
     * The list of error strings
     */
    errors: Array<string>;
}

export interface IFormError {

    /**
     * Form control name
     */
    name: string;

    /**
     * The list of error strings
     */
    errors: Array<string>;
}

export type ControlNameFormatter = (path: IFormErrorData['path']) => string;

export interface IControlNames {
    [key: string]: IControlNames | ControlNameFormatter | string;
}

export interface IOptions {

    /**
     * Form name
     */
    name: string;

    /**
     * Path in store
     */
    attach: Array<string>;

    /**
     * This map describes control names.
     * Examples:
     * ...
     * controls: {
     *     someControl: new FormControl(), // This transforms to "Test", see [[controlNames]]
     *     otherControl: new FormControl(), // This transforms to "Other control", it missing in [[controlNames]], see [[getPrettyName]]
     *     someGroup: new FormGroup({
     *         someControl: 'Test', // This transforms to "Pretty group test", see [[controlNames]]
     *         ...
     *     }),
     *     otherGroup: {
     *         someControl: 'Test',  // This transforms to "Other group 1 test", see [[controlNames]]
     *         ...
     *     },
     * },
     * controlNames: {
     *   someControl: 'Test',
     *   someGroup: {
     *       someControl: 'Test',
     *       toString: () => 'Pretty group', <- Describes group name
     *       ...
     *   },
     *   otherGroup: {
     *       someControl: 'Test',
     *       ...
     *   },
     * }
     * ...
     */
    controlNames?: IControlNames | ControlNameFormatter;
}

/**
 * To reuse form
 */
export interface IReactiveForm extends IOptions {

    form: FormGroup;
}

/**
 * To create form from form controls
 */
export interface IReactiveControls extends IOptions {

    controls: { [key: string]: AbstractControl };
}

/**
 * Flexible wrapper for native forms, which helps to standardize working with errors, store.
 */
export class ReactiveFormFactory<T = { [key: string]: AbstractControl }> {

    readonly name: string;

    readonly attach: Array<string>;

    private readonly _controlNames: IControlNames | ControlNameFormatter;

    private readonly _form: FormGroup;

    private _errors: Array<IFormError> = [];

    constructor(config: IReactiveForm | IReactiveControls) {
        const { name, attach, form, controls, controlNames = { } } = <IReactiveForm & IReactiveControls>config;
        this.name = name;
        this.attach = attach;
        this._controlNames = controlNames;
        this._form = form || new FormGroup(controls);
    }

    /**
     * Returns the form's instance or creates a new one
     */
    get form(): FormGroup {
        return this._form;
    }

    /**
     * The state of form controls
     */
    get controls(): T {
        return <any>this.form.controls;
    }

    /**
     * Returns the errors for all controls.
     */
    get errors(): Array<IFormError> {
        return this._errors;
    }

    static getFormControl<T = AbstractControl>(c: AbstractControl, paths: Array<string | number>): T {
        const control = paths.length ? c.get(paths) : c;
        if (!control) {
            throw new Error(`Invalid control path: ${ paths.join(' -> ') }`);
        }

        return <any>control;
    }

    static getFormControls<T = AbstractControl>(c: AbstractControl, paths: Array<string | number>): Array<T> {
        const f = ReactiveFormFactory.getFormControl<FormGroup | FormArray>(c, paths);

        return <Array<any>>(Array.isArray(f.controls) ? f.controls : Object.values(f.controls));
    }

    private static resolveControlAndPosition(form: AbstractControl, paths: Array<string | number>, position?: number): [FormArray | null, number | null] {
        const ref = ReactiveFormFactory.getFormControl<FormGroup | FormArray>(form, paths);

        return ref instanceof FormArray ? [ref, Number.isInteger(<number>position) ? <number>position : ref.controls.length] : [null, null];
    }

    static runAddControl(form: AbstractControl, paths: Array<string | number>, control: AbstractControl, position?: number): AbstractControl | null {
        const [f, p] = ReactiveFormFactory.resolveControlAndPosition(form, paths, position);
        if (!f || p === null) {
            return null;
        }
        const current = f.at(p);
        f.insert(p, control);

        return current;
    }

    static runRemoveControl(form: AbstractControl, paths: Array<string | number>, position?: number): AbstractControl | null {
        const [f, p] = ReactiveFormFactory.resolveControlAndPosition(form, paths, position);
        if (!f || p === null) {
            return null;
        }
        const current = f.at(p);
        f.removeAt(p);

        return current;
    }

    /**
     * Performs the data validation.
     */
    validate(): boolean {
        this._errors = this.collectErrors(this.form).map(r => this.formatError(r));

        return !this._errors.length;
    }

    /**
     * Checks form for errors
     */
    private collectErrors(form: FormGroup | FormArray, paths: IFormErrorData['path'] = []): Array<IFormErrorData> {
        // Fix offset for [[FormArray]]
        const controls: Array<[string, AbstractControl]> = form instanceof FormArray ? form.controls.map((c, idx) => [`${ ++idx }`, c]) : Object.entries(form.controls);
        const errors = controls.filter(([, c]) => c.invalid).flatMap(([key, c]) => {
            const path = [...paths, key];
            if (c instanceof FormGroup || c instanceof FormArray) {
                return this.collectErrors(c, path);
            }

            return {
                path,
                errors: Object.entries(c.errors || { }).map(([type, error]) => this.getPrettyErrorDescription(type, error))
            };
        });

        if (form.errors) {
            errors.unshift({
                path: paths,
                errors: Object.entries(form.errors || { }).map(([type, error]) => this.getPrettyErrorDescription(type, error))
            });
        }

        return errors;
    }

    /**
     * Transforms errors
     */
    private formatError({ path, errors }: IFormErrorData): IFormError {
        return {
            name: this.getControlName(path, this._controlNames),
            errors
        };
    }

    private getControlName(paths: IFormErrorData['path'], controlNames: IControlNames | ControlNameFormatter): string {
        const parts = [...paths];
        const path = <string>parts.shift();
        // If declaration is function immediately pick it
        const name: any = typeof controlNames === 'object' ? controlNames[path] : controlNames;
        if (typeof name === 'string') {
            return name;
        }
        if (typeof name === 'function') {
            return name(parts);
        }
        if (typeof name === 'object') {
            const parent = String(name) !== '[object Object]' ? String(name) : '';
            const next = parts[0];
            if (!name.hasOwnProperty(next) && isNaN(Number(next))) {
                return `${ parent } ${ this.getPrettyName(next) }`.trim();
            }
            const child = this.getControlName(parts, <IControlNames>(name.hasOwnProperty(next) ? name : {
                [next]: {
                    ...name,
                    // If it is [[FormArray]] use index as key
                    // If you don't want see indexes use callback declaration
                    toString: () => isNaN(Number(next)) ? '[object Object]' : next
                },
            }));

            return `${ parent } ${ child }`.trim();
        }

        return paths.map(this.getPrettyName).join(' ');
    }

    private getPrettyName(control): string {
        return String(control)
            .replace(/([A-Z])/g, (match) => ` ${ match }`)
            .replace(/^./, (match) => match.toUpperCase());
    }

    private getPrettyErrorDescription(type: string, error: { [key: string]: any } | string): string {
        if (typeof error === 'string') {
            return error;
        }

        switch (type) {
            case 'required':
                return 'Field is required';
            case 'minlength':
                return `Minimum field length is ${ error.requiredLength } but actual length is ${ error.actualLength }`;
            case 'maxlength':
                return `Maximum field length is ${ error.requiredLength } but actual length is ${ error.actualLength }`;
            case 'min':
                return `Minimum field value is ${ error.min } but actual value is ${ error.actual }`;
            case 'max':
                return `Maximum field value is ${ error.max } but actual value is ${ error.actual }`;
            case 'email':
                return 'Please enter a valid email';
            case 'atLeastOne':
                return error.value;
            case 'checkDate':
                return error.value;
            case 'emptyLatestFlightTicketIssue':
                return error.value;
            case 'pattern':
                if (['/^\\d+$/', '/^[+-]?\\d+$/'].includes(error.requiredPattern)) {
                    return 'Please enter integer value';
                }
                if (error.requiredPattern === '/^\\w+$/') {
                    return 'Please enter only letters and numbers';
                }
                if (error.requiredPattern === '/^[a-zA-Z]+$/') {
                    return 'Please enter only letters';
                }
                break;
        }

        return 'Field is invalid';
    }
}

export const atLeastOne = (validator: ValidatorFn, controls: string[], errorDescription: string = 'Please fill at least one of required fields') => (group: FormGroup): ValidationErrors | null => {

    const hasAtLeastOne = group && group.controls && controls.some(k => !validator(group.controls[k]));

    return hasAtLeastOne ? null : {
        atLeastOne: { value: errorDescription },
    };
};

export function checkDate(errorDescription: string = 'You cannot use empty or current or previous date!'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if ((value && new Date(value) <= new Date()) || (value === null && control.touched)) {

            return {
                checkDate: { value: errorDescription },
            };
        }

        return null;
    };
}

export function afterPNRNumberExists(errorDescription: string = 'Field is required'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return {
                emptyLatestFlightTicketIssue: { value: errorDescription },
            };
        }

        return null;
    };
}
