import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IProductItineraryData {
    readonly id: number;

    readonly code: string;

    readonly name: string;
}

@Model({
    name: 'ProductItinerary'
})
export class ProductItinerary extends Entity<IProductItineraryData> {

    @Attr() readonly id: number | null;

    @Attr() readonly code: string | null;

    @Attr() readonly name: string | null;
}
