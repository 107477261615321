import { ItineraryRoom, IItineraryData, IItineraryDayData, IItineraryExpeditionData, IItineraryPriceData, IItineraryRoomData, IOfflineBookingPassengerData, ITWContractData, ITWOptionData, ITWProductData, Quote, TWProduct, } from '@aclass/admin/storage/models';
import { Transformed } from '@aclass/core/rest/response';
import { IPriceBreakdownData, IProductPriceData } from '@aclass/core/storage/models/quote';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export type TransformedItinerary = Transformed<IItineraryData, {

    days: Array<Transformed<IItineraryDayData, {

        product: Transformed<ITWProductData>;

        option: Transformed<ITWOptionData>;

        contract: Transformed<ITWContractData>;

        expedition: Transformed<IItineraryExpeditionData, {

            passengers: Array<Transformed<Partial<IOfflineBookingPassengerData>>>;
        }>;

        rooms: Array<Transformed<Partial<IItineraryRoomData>, {

            passengers: Array<Transformed<Partial<IOfflineBookingPassengerData>>>;
        }>>;

        options: Array<Transformed<Partial<ITWOptionData>>>,
    }>>;

    prices: Array<Transformed<IItineraryPriceData>>;
}>;

export type IProductPriceValue = { -readonly [P in keyof IProductPriceData]?: IProductPriceData[P] | null };

export type IPriceBreakdownValue = { -readonly [P in keyof IPriceBreakdownData]?: IPriceBreakdownData[P] | null };

export type ItineraryValue = { -readonly [P in keyof IItineraryData]?: IItineraryData[P] extends Array<any> ? IItineraryData[P] : IItineraryData[P] | null };

export type ItineraryDayValue = { -readonly [P in keyof IItineraryDayData]?: IItineraryDayData[P] extends Array<any> ? IItineraryDayData[P] : IItineraryDayData[P] | null };

export type IItineraryPriceValue = { -readonly [P in keyof IItineraryPriceData]?: IItineraryPriceData[P] | null };

export function getPriceBreakdownControlData(r: Partial<IPriceBreakdownData | IPriceBreakdownValue> = { }): { -readonly [P in keyof IPriceBreakdownData]?: AbstractControl } {
    const { type = Quote.PRICE_TYPE_CUSTOM, label = null, quantity = null, cost = null, sale = null, discount = 0, locked = false } = r;

    return {
        type: new FormControl(type, [Validators.required]),
        label: new FormControl(label, [Validators.required]),
        quantity: new FormControl(quantity, [Validators.required, Validators.min(1)]),
        cost: new FormControl(cost, [Validators.required]),
        sale: new FormControl(sale, [Validators.required]),
        discount: new FormControl(discount, [Validators.required]),
        locked: new FormControl(locked, [Validators.required]),
    };
}

export function getPriceProductControlData(r: Partial<IProductPriceData | IProductPriceValue> = { }): { -readonly [P in keyof IProductPriceData]?: AbstractControl } {
    const { date = null, label = null, price = null } = r;

    return {
        date: new FormControl(date, [Validators.required]),
        label: new FormControl(label, [Validators.required]),
        price: new FormControl(price, [Validators.required])
    };
}

export function getItineraryControlData(r: Partial<IItineraryData | ItineraryValue> = { }, requirePassengers = false): { [P in keyof IItineraryData]?: AbstractControl } {
    const { margin = 100, childRate = 100, days = [], prices = [] } = r;

    return {
        margin: new FormControl(margin),
        childRate: new FormControl(childRate),
        days: new FormArray(days.map(rr => new FormGroup(getItineraryDayControlData(rr, requirePassengers)))),
        prices: new FormArray(prices.map(rr => new FormGroup(getItineraryPriceControlData(rr)))),
    };
}

export function mergeRooms(rooms: Array<Partial<IItineraryRoomData>>, r: Partial<IItineraryData | ItineraryValue>): Partial<IItineraryData | ItineraryValue> {
    const reformatDays = r.days.map(day => {
            if (TWProduct.isAllocable(day.productType)) {
                const copyRoomsWithDayOptions = rooms.map(room => {
                    return ({ ...room, dayOptionsId: day.options[0].id });
                });

                return ({ ...day, rooms: copyRoomsWithDayOptions });
            } else {
                return day;
            }
        }
    );

    return {
        ...r, days: reformatDays
    };
}

export function getItineraryPriceControlData(r: Partial<IItineraryPriceData | IItineraryPriceValue> = { }): { [P in keyof IItineraryPriceData]?: AbstractControl } {
    const { type, price } = r;

    return {
        type: new FormControl(type, [Validators.required]),
        price: new FormControl(price, [Validators.required]),
    };
}

export function getItineraryDayControlData(r: Partial<IItineraryDayData | ItineraryDayValue> = { }, requirePassengers = false): { [P in keyof IItineraryDayData]?: AbstractControl } {
    const { date = null, productType = TWProduct.TYPE_ROUND_TRIP, productId = null, contractId = null, optionId = null, pricingFlag = true, expedition: exp = null, options: optionsParce = [], rooms: suits = [],  prices = null } = r;
    const rooms = new FormArray(suits.map(s => new FormGroup(getItineraryRoomControlData(s, requirePassengers, optionId as any))), [Validators.required]);
    // const roomsByOptions = new FormArray(suits.map(s => new FormGroup(getItineraryRoomControlData(s, requirePassengers))));
    // if optionsParce is null - create new on optionId
    const options = optionsParce !== null && optionsParce.length > 0 ? new FormArray(optionsParce.map(option =>  new FormControl({ value: option, disabled: !productId }, [Validators.required]))) : new FormArray([new FormControl({ value: optionId !== null ? optionId : null }, [Validators.required])]);
    const expedition = new FormGroup(getItineraryExpeditionControlData(exp || { }, requirePassengers));
    if (!TWProduct.isAllocable(productType)) {
        rooms.disable({ emitEvent: false });
    }
    if (!TWProduct.isExcursion(productType)) {
        expedition.disable({ emitEvent: false });
    }

    return {
        date: new FormControl(date, [Validators.required]),
        productType: new FormControl(productType, [Validators.required]),
        productId: new FormControl(productId, [Validators.required]),
        contractId: new FormControl(contractId, [Validators.required]),
        optionId: new FormControl({ value: optionId, disabled: !productId }, [Validators.required]),
        pricingFlag: new FormControl(pricingFlag),
        prices: new FormControl(prices),
        expedition,
        rooms,
        options,
    };
}

export function getItineraryExpeditionControlData(r: Partial<IItineraryExpeditionData> = { }, requirePassengers = false): { [P in keyof IItineraryExpeditionData]?: AbstractControl } {
    const { travelers = 0, passengers: tourists = [] } = r;
    const passengers = new FormArray(Array.from({ length: travelers }).map((omit, idx) => new FormGroup(getItineraryPassengerControlData(tourists[idx]))));
    if (!requirePassengers) {
        passengers.disable({ emitEvent: false });
    }

    return {
        travelers: new FormControl(travelers, [Validators.required, Validators.min(1)]),
        passengers
    };
}

export function getItineraryRoomControlData(r: Partial<IItineraryRoomData> = { }, requirePassengers = false, setDayOptions: { id: ITWOptionData['id'] } = null, parkedRoomsType = null): { [P in keyof IItineraryRoomData]?: AbstractControl } {
// export function getItineraryRoomControlData(r: Partial<IItineraryRoomData> = { }, requirePassengers = false, setDayOptions: { id: ITWOptionData['id'] } = null): { [P in keyof IItineraryRoomData]?: AbstractControl } {
    const { roomType = parkedRoomsType !== null ? parkedRoomsType : ItineraryRoom.TYPE_DOUBLE, passengers: tourist = [] } = r;
    // const { roomType = ItineraryRoom.TYPE_SINGLE, passengers: tourist = [] } = r;
    const dayOptionsId = r?.dayOptionsId !== undefined && r?.dayOptionsId !== null ? r.dayOptionsId : setDayOptions?.id;
    // In delivery we allow pick not all passenger, for this case we should increased amount of rooms
    const length = Math.max(tourist.length, ItineraryRoom.PAX_ALLOCATION[roomType]);
    const passengers = new FormArray(Array.from({ length }).map((omit, idx) => new FormGroup(getItineraryPassengerControlData(tourist[idx]))));
    if (!requirePassengers) {
        passengers.disable({ emitEvent: false });
    }

    return {
        roomType: new FormControl(roomType, [Validators.required]),
        dayOptionsId: new FormControl(dayOptionsId),
        passengers
    };
}

export function getItineraryPassengerControlData(r: Partial<IOfflineBookingPassengerData> = { }): { [key: string]: AbstractControl } {
    const { id = null, name = null } = r;

    return {
        id: new FormControl(id, [Validators.required]),
        name: new FormControl(name),
    };
}

export function getDayValue(v: { [P in keyof IItineraryDayData]?: any }): ItineraryDayValue {
    const {
        date = null,
        pricingFlag = false,
        productType = null,
        rooms = [],
        // roomsByOptions = [],
        options = [],
        expedition = { travelers: 0, rooms: [] },
        prices = null
    } = v;

    let {
        productId = null,
        contractId = null,
        optionId = null
    } = v;

    // Wild things are happening here, folks
    if (productId && productId.id) {
        productId = productId.id;
    }
    if (contractId && contractId.id) {
        contractId = contractId.id;
    }
    if (optionId && optionId.id) {
        optionId = optionId.id;
    }

    return {
        date,
        productType,
        productId: productId ? productId : null,
        contractId: contractId ? contractId : null,
        optionId: optionId ? optionId : null,
        pricingFlag,
        expedition,
        rooms,
        // roomsByOptions,
        options,
        prices
    };
}

export function transformItineraryToValue(v: TransformedItinerary): { [P in keyof IItineraryData]?: any } | null {
    if (!v || v.data === null) {
        return null;
    }
    const days = v.includes.days.map(d => {
        const day = d.data;
        const exp = d.includes.expedition;

        let optionsMulti = [];
        if (d.includes?.options !== undefined) {
            optionsMulti = d.includes.options.map(r => {
                    return { ...r.data};
                });
        }

        const rooms = d.includes?.rooms !== null ? d.includes.rooms.map(r => {
            const { passengers = [] } = r.includes || { passengers: [] };

            return { ...r.data, passengers: passengers.map(p => p.data) };
        }) : null;

        return {
            ...day,
            rooms: rooms,
            productType: d.includes.product.data.type,
            productId: d.includes.product.data,
            contractId: d.includes.contract.data,
            optionId: d.includes.option.data,
            options: optionsMulti,
            expedition: {
                ...exp.data,
                passengers: (exp !== null && 'includes' in exp && Array.isArray(exp.includes.passengers) ? exp.includes.passengers : []).map(p => p.data)
            },
        };
    });
    const prices = v.includes.prices ? v.includes.prices.map(({ data }) => ({ type: data.type, price: data.price })) : [];

    return { ...v.data, days, prices };
}
export function transformItineraryCombinationToValue(v: TransformedItinerary): { [P in keyof IItineraryData]?: any } | null {
    if (!v || v.data === null) {
        return null;
    }
    const days = v.includes.days.map(d => {
        const day = d.data;
        const exp = d.includes.expedition;

        return {
            ...day,
            rooms: d.includes.rooms.map(r => {
                const { passengers = [] } = r.includes || { passengers: [] };

                return { ...r.data, passengers: passengers.map(p => p.data) };
            }),
            options: d.includes.options.map(r => {
                return { ...r.data};
            }),
            productType: d.includes.product.data.type,
            productId: d.includes.product.data,
            contractId: d.includes.contract.data,
            optionId: d.includes.option.data,
            expedition: {
                ...exp.data,
                passengers: ('includes' in exp && Array.isArray(exp.includes.passengers) ? exp.includes.passengers : []).map(p => p.data)
            },
        };
    });
    const prices = v.includes.prices ? v.includes.prices.map(({ data }) => ({ type: data.type, price: data.price })) : [];

    return { ...v.data, days, prices };
}

// ["days", "1", "rooms", "2", "passengers", "1", "id"]
// ["days", "1", "productId"]
export function generateItineraryControlNames(itineraryName: string = 'Itinerary') {
    return (v: Array<string>) => {
        const paths = [...v];
        const parts = [itineraryName];
        switch (paths.shift()) {
            case 'days':
                const [a, b, c, d, e] = paths;
                parts.push(`day ${ a }`);
                switch (b) {
                    case 'productType':
                        parts.push(`product type`);
                        break;
                    case 'date':
                        parts.push(`dates`);
                        break;
                    case 'productId':
                        parts.push(`product`);
                        break;
                    case 'contractId':
                        parts.push(`contract`);
                        break;
                    case 'optionId':
                        parts.push(`option`);
                        break;
                    case 'rooms':
                        if (typeof c === 'undefined') {
                            parts.push(`Rooms amount`);
                            break;
                        }
                        parts.push(`room ${ c }`);

                        switch (d) {
                            case 'roomType':
                                parts.push(`type`);
                                break;
                            case 'passengers':
                                if (typeof e === 'undefined') {
                                    parts.push(`Allocation`);
                                    break;
                                }
                                parts.push(`allocation ${ e }`);
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'expedition':
                        switch (c) {
                            case 'travelers':
                                parts.push(`Number of pax`);
                                break;
                            case 'passengers':
                                parts.push(`allocation ${ d }`);
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                break;
            case 'id':
                parts.push('identifier');
                break;
            case 'name':
                parts.push('name');
                break;
            default:
                break;
        }

        return parts.join(' ');
    };
}
