import { IPipelineModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'pipeline'
})
export class PipelineStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_PIPELINE_ID: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_DEFINITIONS_UPDATE_OPENED: string;

    static dryRunSearchPage(): Action {
        return { type: PipelineStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateCollapsedSearchPage(payload: IPipelineModuleState['searchPageCollapsed']): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchPageInitState(payload: IPipelineModuleState['searchPageInitialized']): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateSearchLockOnSearchPage(payload: IPipelineModuleState['searchPageLock']): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: PipelineStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<number>): Action {
        return { type: PipelineStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: PipelineStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunEditPage(payload: IPipelineModuleState['editPageRecordId']): Action {
        return { type: PipelineStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updatePipelineIdEditPage(payload: IPipelineModuleState['editPageRecordId']): Action {
        return { type: PipelineStory.EDIT_PAGE_UPDATE_PIPELINE_ID, payload };
    }

    static updateSaveLockEditPage(payload: IPipelineModuleState['editPageSaveLock']): Action {
        return { type: PipelineStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: PipelineStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static submitOnEditPage(): Action {
        return { type: PipelineStory.EDIT_PAGE_SUBMIT };
    }

    static updateDefinitionsOnSearchPage(payload: IPipelineModuleState['searchPageDefinitionsOpened']): Action {
        return { type: PipelineStory.SEARCH_PAGE_DEFINITIONS_UPDATE_OPENED, payload };
    }
}
