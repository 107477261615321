import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ICmsSmalltourData {

    readonly id: string;

    readonly name: string;

    readonly link: string;
}

@Model({
    name: 'CmsSmalltour'
})
export class CmsSmalltour extends Entity<ICmsSmalltourData> {

    @Attr() readonly id: string;

    @Attr() readonly name: string;

    @Attr() readonly link: string;
}

