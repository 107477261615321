import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { Map } from 'immutable';

export interface IDeliveryModuleState extends IModuleState {
    /**
     * Edit form data
     */
    form: Map<string, any>;
}

export const INITIAL_DELIVERY_STATE = createTypedState<IDeliveryModuleState>({
    form: { },
});
