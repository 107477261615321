import { Customer } from '@aclass/admin/storage/models/customer';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { ILogData } from '@aclass/core/storage/models/log';
import { Model as Entity } from 'redux-orm';

export type ICustomerLogData = ILogData & {

    readonly customerId: Customer | number | null;
};

@Model({
    name: 'CustomerLog'
})
export class CustomerLog extends Entity<ICustomerLogData> {

    @Attr() readonly id: string | null;

    @Attr() readonly owner: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly log: string | null;

    @Attr() readonly comment: string | null;

    @Fk({ to: 'Customer', relatedName: 'relatedLogs' }) readonly customerId: Customer | null;

    get formattedLog(): string {
        return this.comment ? `<div>${ this.log }Comment: <span style="font-weight: bold">${ this.comment }</span></div>` : this.log;
    }
}
