import { NotificationStory, OrmStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { Response } from '@aclass/core/rest/response';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const notificationModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(NotificationStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.notificationModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            NotificationStory.updateFormSearchPage({ }),
            NotificationStory.importRecordsOnSearchPage([]),
            NotificationStory.updatePaginationOnSearchPage(null),
            NotificationStory.updateOrderOnSearchPage(null),
            NotificationStory.updateSearchLockOnSearchPage(null),
            NotificationStory.updateCollapsedSearchPage(null),
            // Unlock page
            NotificationStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(NotificationStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.notificationModule;
            const form = module.get('searchPageForm').toJS();
            const requests = [];
            const rq: DataSearchRqData = {
                where: {
                    brands: form.brands,
                    locales: form.locales,
                    sentDateFrom: form.sentDate ? form.sentDate.from : null,
                    sentDateTo: form.sentDate ? form.sentDate.to : null,
                    email: form.email,
                    personName: form.personName,
                    pipelines: form.pipelines
                },
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };
            requests.push(http.get('notifications/pipelines-view', { observe: 'response' }));
            requests.push(http.post('notifications/search', rq, { observe: 'response' }));

            return concat(of(NotificationStory.updateSearchLockOnSearchPage(true)), zip(...requests).pipe(
                switchMap((responses: Array<HttpResponse<Response<any>>>) => {
                    const actions = [];
                    responses.forEach(response => {
                        const rs: Response<any> = response.body;
                        if (response.url.includes(`notifications/pipelines-view`)) {
                            actions.push(NotificationStory.importPipelinesOnSearchPage(rs.body));
                        } else if (response.url.includes(`notifications/search`)) {
                            actions.push(OrmStory.populateNotification(rs.body.results));
                            actions.push(NotificationStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)));
                            actions.push(NotificationStory.updatePaginationOnSearchPage(rs.body.pagination));
                            actions.push(NotificationStory.updateOrderOnSearchPage(rs.body.order));
                            actions.push(NotificationStory.updateSearchLockOnSearchPage(false));
                        }
                    });

                    return actions;
                })
            ));
        }),
    )
);
