import { Document, IDocumentData } from '@aclass/admin/storage/models/document';
import { Many } from '@aclass/core/decorators/orm.decorator';
import { attr, Model, QuerySet } from 'redux-orm';

export interface IOfflineBookingEmailData {

    readonly id: string | null;

    readonly header: IOfflineBookingEmailDataHeader;

    readonly body: IOfflineBookingEmailDataBody;

    readonly footer: IOfflineBookingEmailDataFooter;

    readonly documents: Array<IDocumentData>;
}

export interface IOfflineBookingEmailDataHeader {
    readonly logo: string | null;
    readonly contactInfo: string | null;
}

export interface IOfflineBookingEmailDataBody {
    readonly from: string | null;
    readonly fromName: string | null;
    readonly subject: string | null;
    readonly bcc: string | null;
    readonly greeting: string | null;
    readonly card: string | null;
    readonly bank: string | null;
    readonly info: string | null;
    readonly table: IOfflineBookingEmailDataBodyTable;
    readonly aCard: string | null;
    readonly aBank: string | null;
    readonly aInfo: string | null;
}

export interface IOfflineBookingEmailDataFooter {
    readonly content: string | null;
}

export interface IOfflineBookingEmailDataBodyTable {
    readonly passengers: {
        title: string,
        row: {
            title: string,
            val: string
        }
    };
    readonly other: Array<{
        title: string,
        rows: Array<{
            title: string,
            val: string
        }>
    }>;
}

export interface IOfflineBookingReport {
    readonly from: string | null;
    readonly to: string | null;
    readonly cc: string | null;
    readonly fromName: string | null;
    readonly subject: string | null;
    readonly greeting: string | null;
    readonly table: IOfflineBookingEmailDataBodyTable;
}

export class OfflineBookingEmail extends Model<IOfflineBookingEmailData> {

    /**
     * @inheritDoc
     */
    static modelName = 'OfflineBookingEmail';

    /**
     * @inheritDoc
     */
    static fields = {
        id: attr(),
        header: attr(),
        body: attr(),
        footer: attr(),
    };

    // @Many({ to: 'Document', relatedName: 'relatedOptionEmailTemplates', through: 'DocumentEmailOptionTemplateMapping', throughFields: ['templateId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;
    @Many({ to: 'Document', relatedName: 'relatedOptionEmailTemplates', through: 'DocumentEmailTemplateMapping', throughFields: ['templateId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    readonly relatedOptionEmailTemplates: QuerySet<OfflineBookingEmail>;

    /**
     * THIS FIELDS USED FOR IDE AUTOCOMPLETE
     */
    readonly id: string | null;

    readonly header: IOfflineBookingEmailDataHeader;

    readonly body: IOfflineBookingEmailDataBody;

    readonly footer: IOfflineBookingEmailDataFooter;
}
