<div class="b-404">
  <div class="b-404__title">
    404
  </div>
  <div class="b-404__message">
    Page not found
  </div>
  <div class="b-404__home p-1">
    <a class="btn btn-info-outline m-0" routerLink="/">Go home</a>
  </div>
</div>