import { NotificationStory } from '@aclass/admin/storage/actions';
import { INotificationModuleState, INITIAL_NOTIFICATION_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const notificationModuleReducer = (state: TypedState<INotificationModuleState> = INITIAL_NOTIFICATION_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case NotificationStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case NotificationStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case NotificationStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case NotificationStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case NotificationStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case NotificationStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case NotificationStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case NotificationStory.SEARCH_PAGE_IMPORT_PIPELINES:
            return state.update('searchPagePipelines', () => fromJS(payload));
        default:
            return state;
    }
};
