import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOfferValidityData {

    readonly id: string | null;

    readonly value: IOfferValidityValueData | null;
}

export interface IOfferValidityValueData {

    readonly default: number;

    readonly data: Array<IOfferValidityValue>;
}

export interface IOfferValidityValue {

    readonly validityDate: number | null;

    readonly expirationDate: number | null;
}

@Model({
    name: 'OfferValidity'
})
export class OfferValidity extends Entity<IOfferValidityData> {

    @Attr() readonly id: string | null;

    @Attr() readonly value: IOfferValidityValueData | null;
}
