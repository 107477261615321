import { RouterManager } from '@aclass/admin/managers';
import { SoldoutProductStory } from '@aclass/admin/storage/actions';
import {
    IAdminState,
    ISoldoutProductModuleState
} from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class SoldoutProductSearchStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:soldout-search';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISoldoutProductModuleState['searchPageInitialized']>(['soldoutModule', 'searchPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(SoldoutProductSearchStateResolver.STOP_EVENT_NAME);
        // this.resolver = this.ngRdx.select<IQuestionnaireModuleState['searchPageInitialized']>(['questionnaireModule', 'searchPageInitialized']).pipe(
        //     filter(v => v)
        // );
        // this.employee = this.routerManager.onRejectNavigation(SoldoutProductSearchStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(SoldoutProductStory.dryRunSearchPage());
    }
}

