import { AdditionalProductStory } from '@aclass/admin/storage/actions';
import { IAdditionalProductModuleState, INITIAL_ADDITIONAL_PRODUCT_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const additionalProductModuleReducer = (state: TypedState<IAdditionalProductModuleState> = INITIAL_ADDITIONAL_PRODUCT_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case AdditionalProductStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case AdditionalProductStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case AdditionalProductStory.CREATE_PAGE_UPDATE_SAVE_LOCK:
            return state.update('createPageSaveLock', () => payload);
        case AdditionalProductStory.EDIT_PAGE_UPDATE_PRODUCT_ID:
            return state.update('editPageRecordId', () => payload);
        case AdditionalProductStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case AdditionalProductStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        default:
            return state;
    }
};
