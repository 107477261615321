import { Identity } from '@aclass/admin/base/identity';
import { UserEditStateResolver } from '@aclass/admin/resolvers';
import {
    OrmStory,
    RootStory,
    SystemStory,
    UserStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { Role } from '@aclass/admin/storage/models';
import {  orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { extractModel } from '@aclass/core/storage/models/model';
import { IUserData } from '@aclass/core/storage/models/user';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { ORMCommonState, SessionWithModels } from 'redux-orm';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

function getRoleNames(session: SessionWithModels<ORMCommonState>): Array<string> {
    const roles: Array<Role> = extractModel<Role>(session, Role).all().toModelArray();

    return roles.map(record => record.name).sort();
}

export const userModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(UserStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.userModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            UserStory.updateFormSearchPage({ }),
            UserStory.importRecordsOnSearchPage([]),
            UserStory.updatePaginationOnSearchPage(null),
            UserStory.updateOrderOnSearchPage(null),
            UserStory.updateSearchLockOnSearchPage(null),
            UserStory.updateCollapsedSearchPage(null),
            // Unlock page
            UserStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(UserStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.userModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(UserStory.updateSearchLockOnSearchPage(true)), http.post('users/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    OrmStory.populateUsers(rs.body.results),
                    UserStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    UserStory.updatePaginationOnSearchPage(rs.body.pagination),
                    UserStory.updateOrderOnSearchPage(rs.body.order),
                    UserStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(UserStory.CREATE_PAGE_DRY_RUN),
        mergeMap(() => of(UserStory.updateRoleNamesOnCreatePage(getRoleNames(orm.session(state.value.orm))))),
    ),
    action.pipe(
        ofType(UserStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => concat(of(UserStory.createSaveLockEditPage(true)), http.post('users', state.value.userModule.get('createPageForm').toJS()).pipe(
            dispatchActions(
                (rs: Response<IUserData>) => [
                    OrmStory.populateUsers([rs.body]),
                    RootStory.resetForm(),
                    UserStory.updateRoleNamesOnCreatePage(getRoleNames(orm.session(state.value.orm))),
                    UserStory.updateSearchPageInitState(false),
                    UserStory.createSaveLockEditPage(false),
                    SystemStory.showNotification(SystemNotification.success('Success', `User "${rs.body.username}" with role "${rs.body.role}" created`)),
                ],
                () => [
                    UserStory.createSaveLockEditPage(false),
                ]
            ),
        ))),
    ),
    action.pipe(
        ofType(UserStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<number>) => state.value.userModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<number>) => http.get(`users/${payload}`).pipe(
            dispatchActions(
                (rs: Response<IUserData>) => [
                    UserStory.updateUserIdEditPage(payload),
                    UserStory.updateRoleNamesOnEditPage(getRoleNames(orm.session(state.value.orm))),
                    OrmStory.populateUsers([rs.body]),
                    UserStory.updateFormOnEditPage({ }),
                ],
                () => [
                    SystemStory.stopNavigate(UserEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['users'], { sharedLink: true })
                ]
            )
        )),
    ),
    action.pipe(
        ofType(UserStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.userModule;
            const id = module.get('editPageRecordId');
            const data: { username: string, email: string, password: string, deleted: boolean, role: string } = module.get('editPageForm').toJS();
            if (!data.password) {
                delete data.password;
            }

            return concat(of(UserStory.updateSaveLockEditPage(true)), http.put(`users/${id}`, data).pipe(
                dispatchActions(
                    (rs: Response<IUserData>) => {
                        const actions = [
                            UserStory.updateSaveLockEditPage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', `User "${rs.body.username}" updated`)),
                        ];
                        const identity = new Identity(state.value.systemModule.get('identity'));
                        if (identity.id === rs.body.id) {
                            actions.push(UserStory.updateShouldLogoutEditPage(true));
                        } else {
                            actions.push(OrmStory.updateUsers([rs.body]));
                        }

                        return actions;
                    },
                    () => [
                        UserStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(UserStory.PROFILE_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.userModule;
            const identity = new Identity(state.value.systemModule.get('identity'));
            const data: { username: string, email: string, oldPassword: string, password: string, confirmPassword: string } = module.get('profilePageForm').toJS();
            if (!data.password) {
                delete data.oldPassword;
                delete data.password;
            } else {
                if (!data.oldPassword) {
                    return of(SystemStory.showNotification(SystemNotification.error('Error', 'Please enter old password')));
                }
                if (data.password !== data.confirmPassword) {
                    return of(SystemStory.showNotification(SystemNotification.error('Error', 'Confirm password is not same as new password')));
                }
            }

            delete data.confirmPassword;

            return concat(of(UserStory.updateSaveLockProfilePage(true)), http.put(`users/${identity.id}`, data).pipe(
                dispatchActions(
                    () => [
                        UserStory.updateSaveLockProfilePage(false),
                        UserStory.updateShouldLogoutProfilePage(true),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Profile updated')),
                    ],
                    () => [
                        UserStory.updateSaveLockProfilePage(false),
                    ]
                )
            ));
        }),
    )
);
