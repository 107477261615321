import { Identity } from '@aclass/admin/base/identity';
import {
    AclStory,
    OrmStory,
    RootStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    IRolePermissionData,
    Permission,
    Role
} from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { extractModel } from '@aclass/core/storage/models/model';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { ORMCommonState, SessionWithModels } from 'redux-orm';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

function getRoleData(session: SessionWithModels<ORMCommonState>, roleName?): Array<IRolePermissionData> {
    const allPermissions: Array<Permission> = extractModel<Permission>(session, Permission).all().toModelArray();
    const data: Array<IRolePermissionData> = allPermissions.map(record => ({
        name: record.name,
        description: record.description,
        value: false
    }));
    if (!roleName) {
        return data;

    }
    const role: Role = findById<Role>(session, Role, roleName);
    const permissions: Array<Permission> = role.permissions.all().toModelArray();
    data.forEach(record => {
        permissions.forEach(perm => {
            if (record.name === perm.name) {
                record.value = true;
            }
        });
    });

    return data;
}

function getRoleNames(session: SessionWithModels<ORMCommonState>): Array<string> {
    const roles: Array<Role> = extractModel<Role>(session, Role).all().toModelArray();

    return roles.map(record => record.name).sort();
}

export const aclModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { window, http }: IEpicDi) => merge(
    action.pipe(
        ofType(AclStory.DRY_RUN),
        filter(() => !state.value.aclModule.get('initialized')),
        switchMap(() => {
            const roleSearch: DataSearchRqData = {
                pagination: new Pagination().all()
            };

            return concat(of(AclStory.updateInitState(false)), http.post('roles/search', roleSearch).pipe(
                switchMap((rs: Response<IDataSearchRs<{ permissions: any, roles: any }>>) => {
                    return [
                        OrmStory.populateRoles(rs.body.results.roles),
                        OrmStory.populatePermissions(rs.body.results.permissions),
                        AclStory.updateInitState(true),
                    ];
                }),
            ));
        }),
    ),
    action.pipe(
        ofType(AclStory.SEARCH_PAGE_SUBMIT),
        switchMap(() => {
            const roles = (<Array<Role>>extractModel<Role>(orm.session(state.value.orm), Role).all().toRefArray()).map(r => r.name);
            const name: string | null = state.value.aclModule.getIn(['searchPageForm', 'name']);

            return [
                AclStory.importRecordsOnSearchPage(
                    name ? roles.filter(r => r.toLocaleLowerCase().includes(name.toLowerCase())) : roles
                )
            ];
        }),
    ),
    action.pipe(
        ofType(AclStory.CREATE_PAGE_DRY_RUN),
        filter(() => !state.value.aclModule.get('createPageRolePermissions').toJS().length),
        mergeMap(() => of(AclStory.updateRolePermissionsOnCreatePage(getRoleData(orm.session(state.value.orm))))),
    ),
    action.pipe(
        ofType(AclStory.CREATE_PAGE_SUBMIT),
        mergeMap(() => {
            const roles: Array<Role> = extractModel<Role>(orm.session(state.value.orm), Role).all().toModelArray();
            const rq: { name: string, permissions: Array<string> } = {
                name: state.value.aclModule.getIn(['createPageForm', 'name']),
                permissions: state.value.aclModule.get('createPageRolePermissions').toJS().filter(record => record.value).map(record => record.name)
            };
            if (roles.map(record => record.name).includes(rq.name)) {
                return of(SystemStory.showNotification(SystemNotification.error('Error', `Role "${rq.name}" already exists`)));
            }
            if (!rq.permissions.length) {
                return of(SystemStory.showNotification(SystemNotification.error('Error', `Role "${rq.name}" must contain at least one permission`)));
            }

            return concat(of(AclStory.updateSaveLockCreatePage(true)), http.post('roles', rq).pipe(
                dispatchActions(
                    () => [
                        AclStory.updateSaveLockCreatePage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Role "${rq.name}" created`)),
                        OrmStory.populateRoles([rq]),
                        RootStory.resetForm(),
                        AclStory.updateRolePermissionsOnCreatePage(getRoleData(orm.session(state.value.orm))),
                        AclStory.updateInitState(false),
                        SystemStory.navigate(['roles', 'edit', rq.name], { replaceUrl: true, sharedLink: true })

                    ],
                    () => [
                        AclStory.updateSaveLockCreatePage(false),
                    ]
                ),
            ));
        }),
    ),
    action.pipe(
        ofType(AclStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.aclModule.get('editPageRoleName') !== payload),
        switchMap(({ payload }: Action<string>) => {
            return http.post(`roles/search`, { where: { name: payload}}).pipe(
                dispatchActions(
                    (rs:  Response<IDataSearchRs<{ permissions: any, roles: any }>>) => [
                        OrmStory.populateRoles(rs.body.results.roles),
                        AclStory.updateRoleNameEditPage(payload),
                        AclStory.updateFormOnEditPage({ ...state.value.aclModule.get('editPageForm').toJS(), ...{ name: payload }}),
                        AclStory.updateRoleNamesOnEditPage(getRoleNames(orm.session(state.value.orm)).filter(n => n !== payload)),
                        AclStory.updateRolePermissionsOnEditPage(getRoleData(orm.session(state.value.orm), payload)),
                    ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(AclStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const rq: { name: string, permissions: Array<string> } = {
                name: state.value.aclModule.getIn(['editPageForm', 'name']),
                permissions: state.value.aclModule.get('editPageRolePermissions').toJS().filter(record => record.value).map(record => record.name)
            };
            const oldName: string = state.value.aclModule.get('editPageRoleName');
            if (!rq.permissions.length) {
                return of(SystemStory.showNotification(SystemNotification.error('Error', `Role "${rq.name}" must contain at least one permission`)));
            }

            return concat(of(AclStory.updateSaveLockEditPage(true)), http.put(`roles/${oldName}`, rq).pipe(
                dispatchActions(
                    () => {
                        const actions = [
                            AclStory.updateSaveLockEditPage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', `Role "${rq.name}" updated`)),
                            // Mark system roles as dirty
                            AclStory.updateInitState(false)
                        ];
                        const identity = new Identity(state.value.systemModule.get('identity'));
                        if (identity.role === oldName) {
                            actions.push(AclStory.updateShouldLogoutEditPage(true));
                        } else if (oldName !== rq.name) {
                            const newRole: any = { ...rq };
                            actions.push(OrmStory.dropRoles([oldName]));
                            actions.push(OrmStory.populateRoles([newRole]));
                            actions.push(SystemStory.navigate(['roles', 'edit', rq.name], { replaceUrl: true, sharedLink: true }));
                        }

                        return actions;
                    },
                    () => [
                        AclStory.updateSaveLockEditPage(false)
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(AclStory.EDIT_PAGE_REMOVE_ROLE),
        mergeMap(() => {
            const rq: { name: string, toName: string } = {
                name: state.value.aclModule.get('editPageRoleName'),
                toName: state.value.aclModule.getIn(['editPageDeleteForm', 'name']),
            };

            return concat(of(AclStory.updateRemoveModalLockOnEditPage(true)), http.post(`roles/remove`, rq).pipe(
                dispatchActions(() => {
                    return [
                        // Mark system roles as dirty
                        AclStory.updateInitState(false),
                        AclStory.importRecordsOnSearchPage([]),
                        OrmStory.dropRoles([rq.name]),
                        RootStory.resetForm(),
                        RootStory.resetForm('delete'),
                        AclStory.updateRemoveModalLockOnEditPage(false),
                        AclStory.updateShowRemoveModalOnEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `Role "${rq.name}" removed. All users reassigned to "${rq.toName}"`)),
                        SystemStory.navigate(['roles'], { replaceUrl: true, sharedLink: true }),
                    ];
                })
            ));
        }),
    ),
);
