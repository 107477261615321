import { OfflineBookingEmailStory } from '@aclass/admin/storage/actions';
import { INITIAL_OFFLINE_BOOKING_EMAIL_STATE, IOfflineBookingEmailModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const offlineBookingEmailModuleReducer = (state: TypedState<IOfflineBookingEmailModuleState> = INITIAL_OFFLINE_BOOKING_EMAIL_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case OfflineBookingEmailStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case OfflineBookingEmailStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case OfflineBookingEmailStory.SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('searchPageInstances', () => List(payload));
        case OfflineBookingEmailStory.EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('editPageRecordId', () => payload);
        case OfflineBookingEmailStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case OfflineBookingEmailStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_INIT_STATE:
            return state.update('editReportPageInitialized', () => payload);
        case OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editReportPageSaveLock', () => payload);
        case OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_FORM:
            return state.update('editReportPageForm', () => fromJS(payload));
        case OfflineBookingEmailStory.EDIT_REPORT_PAGE_UPDATE_DATA:
            return state.update('editReportPageData', () => fromJS(payload));
        case OfflineBookingEmailStory.EDIT_REPORT_PAGE_SHOW_SEND_MODAL:
            return state.update('editReportPageShowSendModal', () => payload);
        default:
            return state;
    }
};
