import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { IWorkingHoursDayData, IWorkingHoursValueData } from '@aclass/core/storage/models/working.hours';
import { Model as Entity } from 'redux-orm';

export interface IQuoteWorkingHoursData extends IWorkingHoursValueData {

    readonly id: string | null;

}

@Model({
    name: 'QuoteWorkingHours'
})
export class QuoteWorkingHours extends Entity<IQuoteWorkingHoursData> {

    @Attr() readonly id: string | null;

    @Attr() readonly mon: IWorkingHoursDayData | null;

    @Attr() readonly tue: IWorkingHoursDayData | null;

    @Attr() readonly wed: IWorkingHoursDayData | null;

    @Attr() readonly thu: IWorkingHoursDayData | null;

    @Attr() readonly fri: IWorkingHoursDayData | null;

    @Attr() readonly sat: IWorkingHoursDayData | null;

    @Attr() readonly sun: IWorkingHoursDayData | null;
}
