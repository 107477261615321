import { ISignatureModalResultData } from '@aclass/admin/components';
import { IUserData } from '@aclass/core/storage/models/user';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ISignatureModuleState extends IModuleState {

    /**
     * Identifies that all data for acl is loaded
     * In general it means, that all roles and permissions are available
     */
    searchUsersPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchUsersPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchUsersPageLock: boolean | null;

    /**
     * Search form data
     */
    searchUsersPageForm: Map<string, any>;

    /**
     * A list of user ids from ORM
     */
    searchUsersPageResults: List<number>;

    /**
     * The pagination data
     */
    searchUsersPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchUsersPageOrder: Map<string, string> | null;

    /**
     * Identifies that all data for acl is loaded
     * In general it means, that all roles and permissions are available
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of user ids from ORM
     */
    searchPageResults: List<string>;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The id of user
     */
    signatureUserId: IUserData['id'];

    /**
     * The id of signature
     */
    editPageSignatureId: string | null;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Brand and locale
     */
    brandAndlocale: any;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Signature copy data
     */
    copySignatureFromTo: any;

    /**
     * Copy modal status
     */
    showCopyModal: boolean;

    /**
     * Copy modal status
     */
    copyModalResultData: ISignatureModalResultData;

    /**
     * Current signature id
     */
    createPageSignatureId: string | null;

    /**
     * Delete form data
     */
    editPageDeleteForm: Map<string, any>;

    /**
     * Modal trigger
     */
    showRemoveModal: boolean | null;

    /**
     * Locks delete button
     */
    removeModalLock: boolean | null;
}

export const INITIAL_SIGNATURE_STATE = createTypedState<ISignatureModuleState>({
    searchUsersPageInitialized: null,
    searchUsersPageCollapsed: null,
    searchUsersPageLock: null,
    searchUsersPageForm: { },
    searchUsersPageResults: [],
    searchUsersPagePagination: null,
    searchUsersPageOrder: null,
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPagePagination: null,
    searchPageOrder: null,
    signatureUserId: null,
    editPageSignatureId: null,
    editPageForm: { },
    brandAndlocale: { },
    createPageForm: { },
    copySignatureFromTo: { },
    showCopyModal: false,
    copyModalResultData: { brand: '', locale: '', content: ''},
    createPageSignatureId: null,
    editPageDeleteForm: { },
    showRemoveModal: null,
    removeModalLock: null,
});
