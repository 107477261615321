import { Document } from '@aclass/admin/storage/models/document';
import { EmailTemplate } from '@aclass/admin/storage/models/email.template';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDocumentEmailTemplateMappingData {

    readonly documentId: string | null;

    readonly templateId: any | null;
    // readonly templateId: number | null;
}

@Model({
    name: 'DocumentEmailTemplateMapping',
    idAttribute: 'documentId'
})
export class DocumentEmailTemplateMapping extends Entity<IDocumentEmailTemplateMappingData> {

    @Fk({ to: 'Document', relatedName: 'relatedDocumentEmailTemplateMappings' }) readonly documentId: Document | null;

    @Fk({ to: 'EmailTemplate', relatedName: 'relatedDocumentEmailTemplateMappings' }) readonly templateId: EmailTemplate | null;
}
