import { IAppState } from '@aclass/core/storage/states/app.state';
import { NgRedux } from '@angular-redux/store';
import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StoreAttachForm } from './store.attach.form';

@Directive({
    selector: 'form[attach][formGroup]'
})
export class StoreAttachFormReactiveDirective extends StoreAttachForm {

    @Input('formGroup') form: FormGroup;

    constructor(
        protected ngRdx: NgRedux<IAppState>
    ) {
        super();
    }
}
