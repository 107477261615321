import { IStackViewSegmentsData } from '@aclass/admin/components';
import { IDocumentData, IEmailTemplateData } from '@aclass/admin/storage/models';
import { ICustomerModuleState } from '@aclass/admin/storage/states';
import { ISendEmailState } from '@aclass/admin/storage/states/send.email.state';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';

@Story({
    name: 'customer'
})
export class CustomerStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_UPDATE_SELECTED_CUSTOMERS: string;

    @Task() static SEARCH_PAGE_DELETE_BULK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_CUSTOMER_ID: string;

    @Task() static EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_COUNTRIES: string;

    @Task() static EDIT_PAGE_COUNTRY_SEARCH: string;

    @Task() static EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK: string;

    @Task() static EDIT_PAGE_UPDATE_PICKED_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPLOAD_DOCUMENTS: string;

    @Task() static EDIT_PAGE_REMOVE_DOCUMENT: string;

    @Task() static EDIT_PAGE_DOWNLOAD_DOCUMENT: string;

    @Task() static EDIT_PAGE_TOGGLE_DELETED: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID: string;

    @Task() static EDIT_PAGE_CUSTOMER_MERGER_SUBMIT: string;

    @Task() static SEND_EMAIL_UPDATE_OPENED: string;

    @Task() static SEND_EMAIL_SEARCH_TEMPLATES: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATES: string;

    @Task() static SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK: string;

    @Task() static SEND_EMAIL_GET_MESSAGE: string;

    @Task() static SEND_EMAIL_UPDATE_MESSAGE_ID: string;

    @Task() static SEND_EMAIL_UPDATE_DOCUMENTS: string;

    @Task() static SEND_EMAIL_ADD_DOCUMENT: string;

    @Task() static SEND_EMAIL_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: CustomerStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: ICustomerModuleState['searchPageInitialized']): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: ICustomerModuleState['searchPageCollapsed']): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: ICustomerModuleState['']): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_ORDER, payload: order };
    }

    static updateSelectedCustomersOnSearchPage(payload: Array<string>): Action {
        return { type: CustomerStory.SEARCH_PAGE_UPDATE_SELECTED_CUSTOMERS, payload };
    }

    static bulkDeleteOnSearchPage(): Action {
        return { type: CustomerStory.SEARCH_PAGE_DELETE_BULK };
    }

    static importRecordsOnSearchPage(payload: Array<string>): Action {
        return { type: CustomerStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: CustomerStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static dryRunEditPage(payload: ICustomerModuleState['editPageRecordId']): Action {
        return { type: CustomerStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateCustomerIdEditPage(payload: ICustomerModuleState['editPageRecordId']): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_CUSTOMER_ID, payload };
    }

    static updateSearchCountryLockEditPage(payload: boolean | null): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_COUNTRY_SEARCH_LOCK, payload };
    }

    static searchCountryEditPage(payload?: string): Action {
        return { type: CustomerStory.EDIT_PAGE_COUNTRY_SEARCH, payload };
    }

    static updateCountryEditPage(payload: Array<number>): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_COUNTRIES, payload };
    }

    static updateStackViewSegmentsEditPage(payload: IStackViewSegmentsData | null): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_STACK_VIEW_SEGMENTS, payload };
    }

    static updateSaveLockEditPage(payload: ICustomerModuleState['editPageSaveLock']): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateUploadDocumentsLockEditPage(payload: ICustomerModuleState['editPageUploadDocumentsLock']): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK, payload };
    }

    static updatePickedDocumentEditPage(payload: ICustomerModuleState['editPagePickedIdDocumentLock']): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: CustomerStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static uploadDocumentsEditPage(payload: Array<File>): Action {
        return { type: CustomerStory.EDIT_PAGE_UPLOAD_DOCUMENTS, payload };
    }

    static removeDocumentEditPage(payload: IDocumentData['id']): Action {
        return { type: CustomerStory.EDIT_PAGE_REMOVE_DOCUMENT, payload };
    }

    static downloadDocumentEditPage(payload: IDocumentData['id']): Action {
        return { type: CustomerStory.EDIT_PAGE_DOWNLOAD_DOCUMENT, payload };
    }

    static toggleDeletedEditPage(payload: boolean): Action {
        return { type: CustomerStory.EDIT_PAGE_TOGGLE_DELETED, payload };
    }

    static submitOnEditPage(): Action {
        return { type: CustomerStory.EDIT_PAGE_SUBMIT };
    }

    static updateCustomerMergerIdOnEditPage(payload: ICustomerData['id'] | null): Action {
        return { type: CustomerStory.EDIT_PAGE_CUSTOMER_MERGER_UPDATE_ID, payload };
    }

    static submitCustomerMergerOnEditPage(payload: { from: ICustomerData['id'], to: ICustomerData['id'] }): Action {
        return { type: CustomerStory.EDIT_PAGE_CUSTOMER_MERGER_SUBMIT, payload };
    }

    static sendEmailUpdateOpened(payload: ICustomerModuleState['sendEmailOpened']): Action {
        return { type: CustomerStory.SEND_EMAIL_UPDATE_OPENED, payload };
    }

    static sendEmailSearchTemplates(payload?: string): Action {
        return { type: CustomerStory.SEND_EMAIL_SEARCH_TEMPLATES, payload };
    }

    static sendEmailUpdateTemplates(payload: Array<IEmailTemplateData['id']>): Action {
        return { type: CustomerStory.SEND_EMAIL_UPDATE_TEMPLATES, payload };
    }

    static sendEmailUpdateTemplateLock(payload: ISendEmailState['sendEmailTemplateSearchLock']): Action {
        return { type: CustomerStory.SEND_EMAIL_UPDATE_TEMPLATE_SEARCH_LOCK, payload };
    }

    static sendEmailGetMessage(): Action {
        return { type: CustomerStory.SEND_EMAIL_GET_MESSAGE };
    }

    static sendEmailUpdateMessageId(payload: ISendEmailState['sendEmailMessageId']): Action {
        return { type: CustomerStory.SEND_EMAIL_UPDATE_MESSAGE_ID, payload };
    }

    static sendEmailAddDocument(payload: string): Action {
        return { type: CustomerStory.SEND_EMAIL_ADD_DOCUMENT, payload };
    }

    static sendEmailUpdateDocuments(payload: Array<string>): Action {
        return { type: CustomerStory.SEND_EMAIL_UPDATE_DOCUMENTS, payload };
    }

    static sendEmailSubmit(payload: Array<{ id: string, file: File}>): Action {
        return { type: CustomerStory.SEND_EMAIL_SUBMIT, payload };
    }
}
