import { Campaign } from '@aclass/admin/storage/models/campaign';
import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { QuoteTemplate } from '@aclass/admin/storage/models/quote.template';
import { TourRevision } from '@aclass/admin/storage/models/tour.revision';
import { Daytable } from '@aclass/core/base/daytable';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { ICommentsData, IExtraPriceQuoteData, IFlightInfoData, IHotelStayData, IItineraryData, IPnrRetrieveData, IPracticalInfoData, IPriceBreakdownData, IProductPriceData, IQuoteData, ITourInfoData } from '@aclass/core/storage/models/quote';
import { IQuoteTemplateDataBlocks } from '@aclass/core/storage/models/quote.template';
import { Model as Entity } from 'redux-orm';

@Model({
    name: 'Quote'
})
export class Quote extends Entity<IQuoteData> {

    static PRICE_TYPE_ADULT_SGL = 1;

    static PRICE_TYPE_ADULT_DBL = 2;

    static PRICE_TYPE_ADULT_TWIN = 3;

    static PRICE_TYPE_ADULT_TRPL = 4;

    static PRICE_TYPE_ADULT_QUAD = 5;

    static PRICE_TYPE_ADULT_COMBINED = 9;

    static PRICE_TYPE_CHILD = 6;

    static PRICE_TYPE_CUSTOM = 7;

    static PRICE_TYPE_SUPP = 8;

    static PRICE_TYPE_TOTAL = 50;

    static PRICE_ADULT_TYPES = [
        Quote.PRICE_TYPE_ADULT_SGL,
        Quote.PRICE_TYPE_ADULT_DBL,
        Quote.PRICE_TYPE_ADULT_TWIN,
        Quote.PRICE_TYPE_ADULT_TRPL,
        Quote.PRICE_TYPE_ADULT_QUAD,
        Quote.PRICE_TYPE_ADULT_COMBINED,
    ];

    static PRICE_TYPES = [
        Quote.PRICE_TYPE_ADULT_SGL,
        Quote.PRICE_TYPE_ADULT_DBL,
        Quote.PRICE_TYPE_ADULT_TWIN,
        Quote.PRICE_TYPE_ADULT_TRPL,
        Quote.PRICE_TYPE_ADULT_QUAD,
        Quote.PRICE_TYPE_ADULT_COMBINED,
        Quote.PRICE_TYPE_CHILD,
        Quote.PRICE_TYPE_SUPP,
        Quote.PRICE_TYPE_CUSTOM,
    ];

    static PRICE_TYPE_NAMES = {
        [Quote.PRICE_TYPE_ADULT_SGL]: 'Adult price SGL',
        [Quote.PRICE_TYPE_ADULT_DBL]: 'Adult price DBL',
        [Quote.PRICE_TYPE_ADULT_TWIN]: 'Adult price TWIN',
        [Quote.PRICE_TYPE_ADULT_TRPL]: 'Adult price TRPL',
        [Quote.PRICE_TYPE_ADULT_QUAD]: 'Adult price QUAD',
        [Quote.PRICE_TYPE_ADULT_COMBINED]: 'Adult price combined',
        [Quote.PRICE_TYPE_CHILD]: 'Child price',
        [Quote.PRICE_TYPE_SUPP]: 'Room supp(deprecated)',
        [Quote.PRICE_TYPE_CUSTOM]: 'Custom price',
    };

    @Attr() readonly id: number | string | null;

    @Attr() readonly revision: number | null;

    @Attr() readonly hash: string | null;

    @Attr() readonly domain: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly viewed: number | null;

    @Attr() readonly draft: boolean | null;

    @Attr() readonly gallery: boolean | null;

    @Attr() readonly adults: number | null;

    @Attr() readonly children: number | null;

    @Attr() readonly customerInfo: string | null;

    @Attr() readonly detailedItinerary: Array<IItineraryData>;

    @Attr() readonly flightAt: string | null;

    @Attr() readonly directFlight: boolean | null;

    @Attr() readonly flightInfo: Array<IFlightInfoData>;

    @Attr() readonly hotelsInfo: Array<IHotelStayData>;

    @Attr() readonly practicalInfo: Array<IPracticalInfoData>;

    @Attr() readonly preItinerary: Array<IItineraryData>;

    @Attr() readonly priceExtra: Array<IExtraPriceQuoteData>;

    @Attr() readonly priceProduct: Array<IProductPriceData>;

    @Attr() readonly priceBreakdown: Array<IPriceBreakdownData>;

    @Attr() readonly showBlocks: IQuoteTemplateDataBlocks | null;

    @Attr() readonly tourInfo: ITourInfoData;

    @Attr() readonly comments: ICommentsData;

    @Attr() readonly pnr: IPnrRetrieveData;

    @Attr() readonly validTill: number | null;

    @Attr() readonly sent: boolean | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedQuotes' }) readonly enquireDataId: Opportunity;

    @Fk({ to: 'QuoteTemplate', relatedName: 'relatedQuotes' }) readonly templateRevisionId: QuoteTemplate | null;

    @Fk({ to: 'TourRevision', relatedName: 'relatedQuotes' }) readonly tourRevisionId: TourRevision | null;

    @Fk({ to: 'CombinationRevision', relatedName: 'relatedQuotes' }) readonly combinationRevisionId: CombinationRevision | null;

    @Fk({ to: 'Campaign', relatedName: 'relatedQuoteBonuses' }) readonly bonusId: Campaign | null;

    @Fk({ to: 'Campaign', relatedName: 'relatedQuoteDiscounts' }) readonly discountId: Campaign | null;

    private _isValid: boolean;

    private _expiredAt: number;

    private _totalPrice: IPriceBreakdownData | null;

    get totalPrice(): IPriceBreakdownData | null {
        // If undefined
        if (!this._totalPrice && this._totalPrice !== null) {
            this._totalPrice = this.priceBreakdown.find(({ type }) => Quote.PRICE_TYPE_TOTAL) || null;
        }

        return this._totalPrice;
    }

    get totalCost(): number | null {
        return this.totalPrice ? this.totalPrice.cost : null;
    }

    get totalSale(): number | null {
        return this.totalPrice ? this.totalPrice.sale : null;
    }

    get expiredAt(): number {
        if (!Number.isInteger(this._expiredAt)) {
            const dt = Daytable.fromTimestamp(this.createdAt).addDays(this.validTill).toDate();
            dt.setHours(23, 59, 59);

            this._expiredAt = Math.floor(+dt / 1000);
        }

        return this._expiredAt;
    }

    get isValid(): boolean {
        if (typeof this._isValid !== 'boolean') {
            this._isValid = new Daytable().toTimespamt() <= this.expiredAt;
        }

        return this._isValid;
    }

    /**
     * Prepares statuses for select
     */
    static composeTypesForSelect() {
        return Quote.PRICE_TYPES.map(r => ({
            title: Quote.PRICE_TYPE_NAMES[r],
            type: r,
            active: false
        }));
    }
}
