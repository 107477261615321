import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITourSettingsData {

    /**
     * Value for displaying in form
     */
    readonly id: number | null;
    readonly name: string | null;
    readonly additionalDescription: any;
    readonly airports: any;
    readonly combinations: any;
    readonly condorFlight: any;
    readonly destination: {
        id: number | null,
        link: string | null,
        title: string | null,
    };
    readonly duration: any;
    readonly itineraries: any;
    readonly link: any;
    readonly map: any;
    readonly packageCode: any;
    readonly practicalInfos: any;
    readonly priceInfo: any;
    readonly roomCombinations: any;
    readonly tourDescription: any;
    readonly tourExcluded: any;
    readonly tourIncluded: any;
    readonly webCrmProduct: any;
}

@Model({
    name: 'TourSettings'
})
export class TourSettings extends Entity<ITourSettingsData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly additionalDescription: any;

    @Attr() readonly airports: any;

    @Attr() readonly combinations: any;

    @Attr() readonly condorFlight: any;

    @Attr() readonly destination: {
        id: number | null,
        link: string | null,
        title: string | null,
    };

    @Attr() readonly duration: any;

    @Attr() readonly itineraries: any;

    @Attr() readonly link: any;

    @Attr() readonly map: any;

    @Attr() readonly packageCode: any;

    @Attr() readonly practicalInfos: any;

    @Attr() readonly priceInfo: any;

    @Attr() readonly roomCombinations: any;

    @Attr() readonly tourDescription: any;

    @Attr() readonly tourExcluded: any;

    @Attr() readonly tourIncluded: any;

    @Attr() readonly webCrmProduct: any;
}
