import { Document, IDocumentData } from '@aclass/admin/storage/models/document';
import { Attr, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IEmailPipelineData {

    readonly id: string | null;

    readonly locale: string | null;

    readonly brands: string | null;

    readonly value: IEmailPipelineValue | null;

    readonly documents: Array<IDocumentData>;
}

export interface IEmailPipelineValue {

    readonly pipelineLevel: number;

    readonly subject: string;

    readonly body: string;

    readonly aSignature: string;
}

@Model({
    name: 'EmailPipeline'
})
export class EmailPipeline extends Entity<IEmailPipelineData> {

    @Attr() readonly id: string | null;

    @Attr() readonly locale: string | null;

    @Attr() readonly brands: string | null;

    @Attr() readonly brandsLabels: string | null;

    @Attr() readonly value: IEmailPipelineValue | null;

    @Many({ to: 'Document', relatedName: 'relatedPipelineEmailTemplates', through: 'DocumentEmailTemplateMapping', throughFields: ['templateId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    readonly relatedPipelineEmailTemplates: QuerySet<EmailPipeline>;
}
