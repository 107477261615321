export class Calculator {

    public static SOLD_OUT = 'SOLD_OUT';

    public static ON_REQUEST = 'ON_REQUEST';

    public static TYPES = [
        Calculator.SOLD_OUT,
        Calculator.ON_REQUEST,
    ];

    /**
     * Determines whether its argument represents a JavaScript number
     */
    public static isNumeric(v): boolean {
        return !isNaN(parseFloat(v)) && isFinite(v);
    }

    /**
     * Determines whether its [[SOLD_OUT]]
     */
    public static isSoldOut(v): boolean {
        return v === Calculator.SOLD_OUT;
    }

    /**
     * Determines whether its [[ON_REQUEST]]
     */
    public static isOnRequest(v): boolean {
        return v === Calculator.ON_REQUEST;
    }

    /**
     * Calculate sum of values
     */
    public static sum(values: any[]): number {
        // Exclude [[null]], [[SOLD_OUT]] & [[ON_REQUEST]]
        let result = 0;
        values.filter(Calculator.isNumeric).forEach(v => {
            result += parseFloat(v);
        });

        return result;
    }

    /**
     * Calculate sub of values
     */
    public static sub(values: any[]): number {
        // Exclude [[null]], [[SOLD_OUT]] & [[ON_REQUEST]]
        const range = values.filter(Calculator.isNumeric);
        let result = parseFloat(range.shift() || 0);
        range.forEach(v => {
            result -= parseFloat(v);
        });

        return result;
    }
}
