import {
    transformItineraryCombinationToValue,
    transformItineraryToValue,
    TransformedItinerary
} from '@aclass/admin/components/package.itinerary';
import { OrmManager } from '@aclass/admin/managers';
import { QuoteEditStateResolver } from '@aclass/admin/resolvers';
import { transformedSendEmailData } from '@aclass/admin/rest/responses';
import { CustomerStory, OpportunityStory, OrmStory, QuoteStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    IAdditionalProductData,
    ICmsAirportData,
    ICmsHotelData,
    ICmsSmalltourData,
    ICmsTourData,
    ICombinationRevisionData,
    ICombinationToTourRevisionMappingData,
    IDestinationRevisionData,
    IDestinationToTourRevisionMappingData,
    IDocumentData,
    IDocumentEmailTemplateMappingData,
    IEmailTemplateData,
    IExtraPriceData,
    IPCTourPackageData,
    IQuoteGalleryData,
    IQuotePreviewData,
    ITourRevisionData,
    ITWContractData,
    ITWOptionData,
    ITWProductData,
    Opportunity,
} from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState, IQuoteModuleState } from '@aclass/admin/storage/states';
import { IQuoteBuildParams } from '@aclass/admin/utils';
import { ISearchCampaign } from '@aclass/admin/utils/quotes';
import { Daytable, IDateRange } from '@aclass/core/base/daytable';
import { readToBase64 } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Order } from '@aclass/core/rest/order';
import { defaultPageSizes, Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ICampaignData } from '@aclass/core/storage/models/campaign';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IFlightInfoData, IPnrRetrieveData, IQuoteData } from '@aclass/core/storage/models/quote';
import { IUserData } from '@aclass/core/storage/models/user';
import { PIPELINE_LEVEL_PRESETS_LEVELS } from '@aclass/core/utils/opportunities';
import * as md5 from 'md5';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type searchRsItem = IQuotePreviewData & {
    customerId: ICustomerData['id'];
    customerName: ICustomerData['name'];
    customerSurname: ICustomerData['surname'];
    customerEmail: ICustomerData['email'];
    assignedId: IUserData['id'];
    assignedUsername: IUserData['username'];
    packageId: IPCTourPackageData['id'];
    packageName: IPCTourPackageData['name'];
    packageCode: IPCTourPackageData['code'];
    pipelineId: IPipelineLevelData['id'];
    pipelineName: IPipelineLevelData['name'];
};

type transformed = Transformed<IQuoteData, {

    combinationItinerary: TransformedItinerary;

    tourItinerary: TransformedItinerary;

    tourRevision: Transformed<ITourRevisionData, {

        destinationRevisions: Array<Transformed<IDestinationRevisionData>>;

        destinationToTourRevisionMappings: Array<Transformed<IDestinationToTourRevisionMappingData>>;

        combinationRevisions: Array<Transformed<ICombinationRevisionData>>;

        combinationToTourRevisionMappings: Array<Transformed<ICombinationToTourRevisionMappingData>>;
    }>;

    combinationRevision: Transformed<ICombinationRevisionData>;

    discountCampaigns: Array<Transformed<ICampaignData>>;
}>;

function normalizeQueryParams(params: IQuoteBuildParams): IQuoteBuildParams {
    return Object.keys(params).sort().reduce((prev, curr) => {
        prev[curr] = String(params[curr]);

        return prev;
    }, { });
}

function getQueryParamsHash(params: IQuoteBuildParams): string {
    return md5(JSON.stringify(params));
}

export const quoteModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager, window }: IEpicDi) => merge(
    action.pipe(
        ofType(QuoteStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.quoteModule.get('searchPageInitialized')),
        switchMap(() => {
            // Search query is the same for all requests
            const allSearch: DataSearchRqData = {
                where: { showDeactivated: false },
                pagination: new Pagination().all()
            };

            return concat(of(QuoteStory.updateSearchPageInitState(false)), http.post('offers/search-assigned', allSearch).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    OrmStory.populateUsers(rs.body.results),
                    QuoteStory.updateAssignedSearchPage(rs.body.results.map(r => r.id)),
                    // Reset prev records state
                    QuoteStory.updateFormSearchPage({ }),
                    QuoteStory.importRecordsOnSearchPage([]),
                    QuoteStory.updatePaginationOnSearchPage(null),
                    QuoteStory.updateOrderOnSearchPage(null),
                    QuoteStory.updateSearchLockOnSearchPage(null),
                    QuoteStory.updateCollapsedSearchPage(null),
                    QuoteStory.updateRouterModeSearchPage('simple'),
                    // Unlock page
                    QuoteStory.updateSearchPageInitState(true),
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEARCH_PAGE_PACKAGE_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    codeName: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'codeName', isReverse: false })
            };

            return concat(of(QuoteStory.updateSearchPackageLockOnSearchPage(true)), http.post('offers/search-packages', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPCTourPackageData>>>) => [
                    QuoteStory.updateSearchPackageLockOnSearchPage(false),
                    OrmStory.populatePcTourPackages(rs.body.results),
                    QuoteStory.updatePackagesSearchPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),

    action.pipe(
        ofType(QuoteStory.SEARCH_PAGE_PIPELINE_LEVEL_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'level', isReverse: false })
            };

            return concat(of(QuoteStory.updateSearchPipelineLevelLockOnSearchPage(true)), http.post('offers/search-pipelines', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPipelineLevelData>>>) => [
                    QuoteStory.updateSearchPipelineLevelLockOnSearchPage(false),
                    OrmStory.populatePipelineLevels(rs.body.results),
                    QuoteStory.updatePipelineLevelsSearchPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEARCH_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.quoteModule;
            const level: number | null = module.getIn(['searchPageForm', 'pipelineLevel'], false);
            const group = module.getIn(['searchPageForm', 'pipelineLevelGroups']);
            const rq: DataSearchRqData = {
                where: {
                    brands: module.getIn(['searchPageForm', 'brands']),
                    locales: module.getIn(['searchPageForm', 'locales']),
                    enqId: module.getIn(['searchPageForm', 'enqId'], null),
                    customer: module.getIn(['searchPageForm', 'customer'], null),
                    assigned: module.getIn(['searchPageForm', 'assigned'], null),
                    package: module.getIn(['searchPageForm', 'package'], null),
                    pipelineLevels: level ? [level] : PIPELINE_LEVEL_PRESETS_LEVELS.get(group),
                    createdAt: module.getIn(['searchPageForm', 'createdAt'], null),
                    updateAt: module.getIn(['searchPageForm', 'updateAt'], null),
                    flightAt: module.getIn(['searchPageForm', 'flightAt'], null),
                    sent: module.getIn(['searchPageForm', 'sent']),
                    viewed: module.getIn(['searchPageForm', 'viewed']),
                    gallery: module.getIn(['searchPageForm', 'gallery']),
                },
                pagination: module.get('searchPagePagination'),
                order: module.get('searchPageOrder'),
            };

            return concat(of(QuoteStory.updateSearchLockOnSearchPage(true)), http.post('offers/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<searchRsItem>>>) => [
                    OrmStory.populateCustomers(rs.body.results.map(r => ({ id: r.customerId, name: r.customerName, surname: r.customerSurname, email: r.customerEmail }))),
                    OrmStory.populateUsers(rs.body.results.filter(r => r.assignedId).map(r => ({ id: r.assignedId, username: r.assignedUsername }))),
                    OrmStory.populatePcTourPackages(rs.body.results.map(r => ({ id: r.packageId, name: r.packageName, code: r.packageCode }))),
                    OrmStory.populatePipelineLevels(rs.body.results.map(r => ({ id: r.pipelineId, name: r.pipelineName }))),
                    OrmStory.populateQuotePreviews(rs.body.results.map(r => ({
                        id: r.id,
                        brand: r.brand,
                        brandLabel: r.brandLabel,
                        locale: r.locale,
                        enquireDataId: r.enquireDataId,
                        revision: r.revision,
                        hash: r.hash,
                        createdAt: r.createdAt,
                        updatedAt: r.updatedAt,
                        flightAt: r.flightAt,
                        followUpAt: r.followUpAt,
                        viewed: r.viewed,
                        sent: r.sent,
                        assignedTo: r.assignedId,
                        packageId: r.packageId,
                        customerId: r.customerId,
                        pipelineId: r.pipelineId,
                    }))),
                    QuoteStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    QuoteStory.updatePaginationOnSearchPage(rs.body.pagination),
                    QuoteStory.updateOrderOnSearchPage(rs.body.order),
                    QuoteStory.updateRouterModeSearchPage(rq.where.gallery ? 'full' : 'simple'),
                    QuoteStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload }: Action<IQuoteBuildParams & { id: IOpportunityData['id'] }>) => {
            const { id, ...params } = payload;
            const prev = state.value.quoteModule.get('editPageOpportunityId');

            // Ensure that opportunity available and get revisions
            // This is a little bit slowly we can send 2 requests in parallel, but I dont want merge errors and copy/past build revision functionality
            return prev === id ? of(QuoteStory.buildRevisionEditPage(params)) : http.get(`offers/revisions/${ id }`).pipe(dispatchActions(
                (rs: Response<Transformed<IOpportunityData, { offers: Array<Transformed<IQuoteData>>, customer: Transformed<ICustomerData> }>>) => {

                    return [
                        OrmStory.populateOpportunities([rs.body.data]),
                        OrmStory.populateCustomers([rs.body.includes.customer.data]),
                        OrmStory.populateQuotes(rs.body.includes.offers.map(r => r.data)),
                        QuoteStory.dryRunHotelPicker(id),
                        QuoteStory.dryRunSmalltourPicker(id),
                        QuoteStory.updateOpportunityIdEditPage(id),
                        QuoteStory.updateColorRangeEditPage(rs.body.meta.colorRange),
                        QuoteStory.updateFormEditPage({ }),
                        QuoteStory.clearPreparedStatesEditPage(),
                        QuoteStory.clearEditedStatesEditPage(),
                        QuoteStory.buildRevisionEditPage(params),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(QuoteEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['quotes'], { sharedLink: true })
                ]
            ));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_BUILD_REVISION),
        mergeMap(({ payload }: Action<IQuoteBuildParams>) => {
            const module = state.value.quoteModule;
            const payloadHash = getQueryParamsHash(normalizeQueryParams(payload));
            if (module.hasIn(['editPagePreparedStates', payloadHash])) {
                return of(QuoteStory.updateActiveStateEditPage(payloadHash));
            }
            const id = module.get('editPageOpportunityId');

            return concat(of(QuoteStory.updateSwitchRevisionLockEditPage(true)), http.post(`offers/build/${ id }`, payload).pipe(dispatchActions(
                (rs: Response<transformed>) => {
                    const queryParams = normalizeQueryParams(<IQuoteBuildParams>rs.body.meta.params);
                    const queryHash = getQueryParamsHash(queryParams);
                    const tourItinerary = transformItineraryToValue(rs.body.includes.tourItinerary);
                    const combinationItinerary = transformItineraryCombinationToValue(rs.body.includes.combinationItinerary);
                    const products: { [key: number]: ITWProductData } = { };
                    const contracts: { [key: number]: ITWContractData } = { };
                    const optionsMylti: { [key: number]: ITWOptionData } = { };
                    [rs.body.includes.tourItinerary, rs.body.includes.combinationItinerary].forEach(itinerary => {
                        if (!itinerary.includes) {
                            return;
                        }
                        itinerary.includes.days.forEach(({ includes }) => {
                            if (!includes) {
                                return;
                            }
                            const { product, contract, options } = includes;
                            products[product.data.id] = product.data;
                            contracts[contract.data.id] = contract.data;
                            options.forEach(optionMulti => {
                                optionsMylti[optionMulti.data.id] = <ITWOptionData>optionMulti.data;
                            });
                        });
                    });
                    const actions = [
                        QuoteStory.mergePreparedStateEditPage({
                            [queryHash]: {
                                queryParams,
                                tourItinerary,
                                combinationItinerary,
                                quote: rs.body.data,
                            }
                        }),
                        OrmStory.populateTourRevisions([rs.body.includes.tourRevision.data]),
                        OrmStory.populateDestinationRevisions(rs.body.includes.tourRevision.includes.destinationRevisions.map(r => r.data)),
                        OrmStory.populateDestinationToTourRevisionMappings(rs.body.includes.tourRevision.includes.destinationToTourRevisionMappings.map(r => r.data)),
                        OrmStory.populateCombinationRevisions(rs.body.includes.tourRevision.includes.combinationRevisions.map(r => r.data)),
                        OrmStory.populateCombinationToTourRevisionMappings(rs.body.includes.tourRevision.includes.combinationToTourRevisionMappings.map(r => r.data)),
                        OrmStory.populateCampaigns(rs.body.includes.discountCampaigns.map(r => r.data)),
                        OrmStory.populateTwProducts(Object.values(products)),
                        OrmStory.populateTwContracts(Object.values(contracts)),
                        OrmStory.populateTwOptions(Object.values(optionsMylti)),
                        QuoteStory.updateRecordsBonusesEditPage(rs.body.includes.discountCampaigns.map(r => String(r.data.id))),
                        QuoteStory.updateRecordsDiscountsEditPage(rs.body.includes.discountCampaigns.map(r => String(r.data.id))),
                        QuoteStory.updateActiveStateEditPage(queryHash),
                    ];

                    if (rs.body.includes.combinationRevision && rs.body.includes.combinationRevision.data) {
                        actions.push(OrmStory.populateCombinationRevisions([rs.body.includes.combinationRevision.data]));
                    }

                    return [
                        ...actions,
                        QuoteStory.updateSwitchRevisionLockEditPage(false),
                        QuoteStory.updateResolverIdEditPage(id),
                        QuoteStory.updatePageFormEditStatus(true)
                    ];
                },
                () => [
                    SystemStory.stopNavigate(QuoteEditStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['quotes'], { sharedLink: true })
                ]
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_TOGGLE_GALLERY),
        mergeMap(({ payload }: Action<IQuoteData['gallery']>) => {
            const module = state.value.quoteModule;
            const [brand, locale, id] = instanceManager.parseId(<string>module.get('editPageRevisionId'));
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.post(`offers/toggle-gallery/${ id }`, { gallery: payload }, params).pipe(dispatchActions(
                (rs: Response<Transformed<IQuoteData>>) => [
                    OrmStory.populateQuotes([rs.body.data]),
                    OrmStory.reloadModel(OrmManager.RELOAD_QUOTE),
                    QuoteStory.updateSaveLockEditPage(false),
                    SystemStory.showNotification(SystemNotification.success('Success', rs.body.data.gallery ? 'Quote added to gallery' : 'Quote removed from gallery')),
                ],
                () => [
                    QuoteStory.updateSaveLockEditPage(false),
                ]
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_REMOVE),
        mergeMap(() => {
            const module = state.value.quoteModule;
            const revId = module.get('editPageRevisionId');
            const [brand, locale, id] = instanceManager.parseId(<string>revId);
            const params = instanceManager.buildHeaders([brand, locale]);

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.delete(`offers/${ id }`, params).pipe(dispatchActions(
                (rs: Response<any>) => {
                    const active = module.get('editPageActiveState');
                    const st = Object.keys(module.get('editPagePreparedStates').toJS()).filter(r => r !== active).pop();
                    const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(module.get('editPageOpportunityId')));
                    const quote = opportunity.relatedQuotes.toModelArray().filter(r => r.id !== revId).pop();
                    const actions = [
                        OrmStory.dropQuotes([revId]),
                        QuoteStory.removePreparedStateEditPage(active),
                        QuoteStory.removeEditedStateEditPage(active),
                    ];
                    if (st) {
                        actions.push(QuoteStory.updateActiveStateEditPage(st));
                    } else if (quote) {
                        actions.push(QuoteStory.buildRevisionEditPage({ revision: String(quote.revision) }));
                    } else {
                        actions.push(QuoteStory.updateOpportunityIdEditPage(null));
                        actions.push(SystemStory.navigate(['quotes'], { sharedLink: true, replaceUrl: true }));
                    }

                    return actions.concat([
                        QuoteStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Quote removed')),
                    ]);
                },
                () => [
                    QuoteStory.updateSaveLockEditPage(false),
                ]
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_PREVIEW),
        mergeMap(() => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');
            const defaults = [QuoteStory.updateSaveLockEditPage(false)];

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.post(`offers/preview/${ id }`, module.get('editPageForm')).pipe(dispatchActions(
                (rs: Response<any>) => {
                    const { protocol, hostname, port } = window.location;
                    const host = `${ protocol }//${ hostname }${ port ? `:${ port }` : '' }`;
                    const page = window.open(`${ host }/ao/quote-preview`, '_blank', 'location=no,status=no');
                    if (!page) {
                        return defaults.concat([
                            SystemStory.showNotification(SystemNotification.error('Error', 'Please allow application to open new page(you should see cross button in address bar) and try again.')),
                        ]);
                    }
                    // @ts-ignore
                    page.__QUOTE__ = JSON.stringify(rs.body);

                    return defaults;
                },
                () => defaults
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_RECALCULATE),
        mergeMap(({ payload }: Action<{ recalculateSale: boolean, flightPriceLocked: boolean }>) => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.post(`offers/recalculate/${ id }`, { ...module.get('editPageForm').toJS(), ...payload }).pipe(dispatchActions(
                (rs: Response<Transformed<IQuoteData, { customer: Transformed<ICustomerData>, tourItinerary: TransformedItinerary, combinationItinerary: TransformedItinerary }>>) => {
                    const tourItinerary = transformItineraryToValue(rs.body.includes.tourItinerary);
                    const combinationItinerary = transformItineraryToValue(rs.body.includes.combinationItinerary);

                    const active = module.get('editPageActiveState');
                    const queryParams = module.hasIn(['editPageEditedStates', active, 'queryParams']) ? module.getIn(['editPageEditedStates', active, 'queryParams']).toJS() : module.getIn(['editPagePreparedStates', active, 'queryParams']).toJS();

                    return [
                        QuoteStory.removeEditedStateEditPage(active),
                        QuoteStory.mergeEditedStateEditPage({
                            [active]: {
                                queryParams,
                                tourItinerary,
                                combinationItinerary,
                                quote: rs.body.data,
                            }
                        }),
                        RootStory.resetForm(),
                        SystemStory.showNotification(SystemNotification.success('Success', `New itinerary data retrieved.`)),
                        QuoteStory.updateSaveLockEditPage(false)
                    ];
                },
                () => [
                    QuoteStory.updateSaveLockEditPage(false)
                ]
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');
            const editFormModule = module.get('editPageForm');

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.put(`offers/${ id }`, editFormModule).pipe(dispatchActions(
                (rs: Response<Transformed<IQuoteData, { customer: Transformed<ICustomerData>, tourItinerary: TransformedItinerary, combinationItinerary: TransformedItinerary }>>) => {
                    const queryParams: IQuoteBuildParams = { revision: String(rs.body.data.revision) };
                    const queryHash = getQueryParamsHash(queryParams);
                    const tourItinerary = transformItineraryToValue(rs.body.includes.tourItinerary);
                    const combinationItinerary = transformItineraryToValue(rs.body.includes.combinationItinerary);
                    const products: { [key: number]: ITWProductData } = { };
                    const contracts: { [key: number]: ITWContractData } = { };
                    const options: { [key: number]: ITWOptionData } = { };
                    [rs.body.includes.tourItinerary, rs.body.includes.combinationItinerary].forEach(itinerary => {
                        if (!itinerary.includes) {
                            return;
                        }
                        itinerary.includes.days.forEach(({ includes }) => {
                            if (!includes) {
                                return;
                            }
                            const { product, contract, option } = includes;
                            products[product.data.id] = product.data;
                            contracts[contract.data.id] = contract.data;
                            options[option.data.id] = option.data;
                        });
                    });

                    const active = module.get('editPageActiveState');
                    const actions = [
                        CustomerStory.updateCustomerIdEditPage(null),
                        OpportunityStory.updateOpportunityIdEditPage(null),
                        QuoteStory.updateSearchPageInitState(false),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Revision created')),
                        OrmStory.populateQuotes([rs.body.data]),
                        OrmStory.populateCustomers([rs.body.includes.customer.data]),
                        OrmStory.populateTwProducts(Object.values(products)),
                        OrmStory.populateTwContracts(Object.values(contracts)),
                        OrmStory.populateTwOptions(Object.values(options)),
                        QuoteStory.removePreparedStateEditPage(active),
                        QuoteStory.removeEditedStateEditPage(active),
                        QuoteStory.mergePreparedStateEditPage({
                            [queryHash]: {
                                queryParams,
                                tourItinerary,
                                combinationItinerary,
                                quote: rs.body.data,
                            }
                        }),
                        QuoteStory.updateActiveStateEditPage(queryHash),
                        QuoteStory.updateSaveLockEditPage(false),
                    ];

                    if (payload) {
                        actions.push(QuoteStory.openSendEmail([id, rs.body.data.revision]));
                    }


                    return actions;
                },
                () => [
                    QuoteStory.updateSaveLockEditPage(false),
                ]
            )));
        }),
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_CAMPAIGN_SEARCH),
        switchMap(({ payload }: Action<ISearchCampaign>) => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
            const { name, isDiscount, isBonus } = payload;
            const rq: DataSearchRqData = {
                where: {
                    name: name || null,
                    isDiscount,
                    isBonus,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(QuoteStory.updateSearchCampaignLockOnEditPage(true)), http.post('offers/search-discount-campaigns', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ICampaignData>>>) => {

                    const actions = [
                        QuoteStory.updateSearchCampaignLockOnEditPage(false),
                        OrmStory.populateCampaigns(rs.body.results),
                    ];

                    if (isBonus) {
                        actions.push(QuoteStory.updateRecordsBonusesEditPage(rs.body.results.map(r => String(r.id))));
                    } else if (isDiscount) {
                        actions.push(QuoteStory.updateRecordsDiscountsEditPage(rs.body.results.map(r => String(r.id))));
                    }

                    return actions;
                })
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_ADDITIONAL_PRODUCT_SEARCH),
        switchMap(({ payload }: Action<string | undefined>) => {
            const module = state.value.quoteModule;
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, module.get('editPageOpportunityId'));
            const rq: DataSearchRqData = {
                where: {
                    brands: [opportunity.brand],
                    name: payload || null,
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(QuoteStory.updateSearchAdditionalProductLockOnEditPage(true)), http.post('offers/search-additional-products', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IAdditionalProductData>>>) => [
                    QuoteStory.updateSearchAdditionalProductLockOnEditPage(false),
                    OrmStory.populateAdditionalProducts(rs.body.results),
                    QuoteStory.updateRecordsAdditionalProductsEditPage(rs.body.results.map(({ id }) => id)),
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.GALLERY_PICKER_DRY_RUN),
        filter(({ payload }: Action<IQuoteModuleState['galleryPickerOpportunityId']>) => payload !== state.value.quoteModule.get('galleryPickerOpportunityId')),
        switchMap(({ payload }: Action<IQuoteModuleState['galleryPickerOpportunityId']>) => [
            QuoteStory.updateSearchLockGalleryPicker(null),
            QuoteStory.updateFormGalleryPicker({ }),
            QuoteStory.updateOpportunityIdGalleryPicker(payload),
            QuoteStory.updateRecordsGalleryPicker([])
        ])
    ),
    action.pipe(
        ofType(QuoteStory.GALLERY_PICKER_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('galleryPickerOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
            const rq: DataSearchRqData = {
                where: {
                    exclude: id,
                    search: payload || null,
                    gallery: module.getIn(['galleryPickerForm', 'gallery'])
                },
                pagination: new Pagination({ pageSize: defaultPageSizes[2] })
            };

            return concat(of(QuoteStory.updateSearchLockGalleryPicker(true)), http.post('offers/search-galleries', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IQuoteGalleryData>>>) => [
                    QuoteStory.updateSearchLockGalleryPicker(false),
                    OrmStory.populateQuoteGalleries(rs.body.results),
                    QuoteStory.updateRecordsGalleryPicker(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.EDIT_PAGE_SEARCH_FILTERED_EXTRA_PRICES),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('galleryPickerOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);

            return concat(of(QuoteStory.updateSearchLockGalleryPicker(true)), http.post('offers/search-extra-prices', { where:  payload}, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IExtraPriceData>>>) => [
                    OrmStory.populateExtraPrices(rs.body.results),
                    QuoteStory.editPageUpdateExtraPricesSelect(rs.body.results.map(r => r.id)),
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.HOTEL_PICKER_DRY_RUN),
        filter(({ payload }: Action<IQuoteModuleState['hotelPickerOpportunityId']>) => payload !== state.value.quoteModule.get('hotelPickerOpportunityId')),
        switchMap(({ payload }: Action<IQuoteModuleState['hotelPickerOpportunityId']>) => [
            QuoteStory.updateSearchLockHotelPicker(null),
            QuoteStory.updateOpportunityIdHotelPicker(payload),
            QuoteStory.updateRecordsHotelPicker([])
        ])
    ),
    action.pipe(
        ofType(QuoteStory.SMALLTOUR_PICKER_DRY_RUN),
        filter(({ payload }: Action<IQuoteModuleState['smalltourPickerOpportunityId']>) => payload !== state.value.quoteModule.get('smalltourPickerOpportunityId')),
        switchMap(({ payload }: Action<IQuoteModuleState['smalltourPickerOpportunityId']>) => [
            QuoteStory.updateSearchLockSmalltourPicker(null),
            QuoteStory.updateOpportunityIdSmalltourPicker(payload),
            QuoteStory.updateRecordsSmalltourPicker([])
        ])
    ),
    action.pipe(
        ofType(QuoteStory.HOTEL_PICKER_SEARCH),
        switchMap(() => {
            const module = state.value.quoteModule;
            const id = module.get('hotelPickerOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);

            return concat(of(QuoteStory.updateSearchLockHotelPicker(true)), http.get('offers/accommodations', params).pipe(
                dispatchActions((rs: Response<Array<Transformed<ICmsHotelData>>>) => [
                    QuoteStory.updateSearchLockHotelPicker(false),
                    OrmStory.populateCmsHotels(rs.body.map(r => r.data)),
                    QuoteStory.updateRecordsHotelPicker(rs.body.map(r => r.data.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.SMALLTOUR_PICKER_SEARCH),
        switchMap(() => {
            const module = state.value.quoteModule;
            const id = module.get('smalltourPickerOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);

            return concat(of(QuoteStory.updateSearchLockSmalltourPicker(true)), http.get('offers/smalltours', params).pipe(
                dispatchActions((rs: Response<Array<Transformed<ICmsSmalltourData>>>) => [
                    QuoteStory.updateSearchLockSmalltourPicker(false),
                    OrmStory.populateCmsSmalltours(rs.body.map(r => r.data)),
                    QuoteStory.updateRecordsSmalltourPicker(rs.body.map(r => r.data.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.GALLERY_PICKER_SUBMIT),
        switchMap(({ payload }: Action<boolean>) => {
            const module = state.value.quoteModule;
            const queryParams: IQuoteBuildParams = {
                opportunity: module.getIn(['galleryPickerForm', 'record', 'enquireDataId']),
                revision: module.getIn(['galleryPickerForm', 'record', 'revision'])
            };

            return [
                QuoteStory.toggleOpenGalleryPicker(false),
                SystemStory.navigate(['quotes', module.get('galleryPickerOpportunityId')], { queryParams, sharedLink: true, replaceUrl: !payload, inNewTab: payload })
            ];
        })
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_DRY_RUN),
        filter(({ payload }: Action<IQuoteModuleState['changeTourOpportunityId']>) => payload !== state.value.quoteModule.get('changeTourOpportunityId')),
        switchMap(({ payload }: Action<IQuoteModuleState['changeTourOpportunityId']>) => [
            QuoteStory.updateFormChangeTour({ }),
            QuoteStory.updateDestinationSearchLockChangeTour(null),
            QuoteStory.updateTourSearchLockChangeTour(null),
            QuoteStory.updateCombinationSearchLockChangeTour(null),
            QuoteStory.updateDestinationsChangeTour([]),
            QuoteStory.updateToursChangeTour([]),
            QuoteStory.updateCombinationsChangeTour([]),
            QuoteStory.updateCmsTourIdChangeTour(null),
            QuoteStory.updateOpportunityIdChangeTour(payload),
        ])
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_SEARCH_DESTINATIONS),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('changeTourOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
            const rq: DataSearchRqData = {
                where: {
                    name: payload
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(QuoteStory.updateDestinationSearchLockChangeTour(true)), http.post('offers/search-destination-revisions', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IDestinationRevisionData>>>) => [
                    QuoteStory.updateDestinationSearchLockChangeTour(false),
                    OrmStory.populateDestinationRevisions(rs.body.results),
                    QuoteStory.updateDestinationsChangeTour(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_SEARCH_TOURS),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('changeTourOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
            const rq: DataSearchRqData = {
                where: {
                    destination: module.getIn(['changeTourForm', 'destination', 'id'], null),
                    name: payload
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(QuoteStory.updateTourSearchLockChangeTour(true)), http.post('offers/search-tour-revisions', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ITourRevisionData>>>) => [
                    QuoteStory.updateTourSearchLockChangeTour(false),
                    OrmStory.populateTourRevisions(rs.body.results),
                    QuoteStory.updateToursChangeTour(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_SEARCH_COMBINATIONS),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('changeTourOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
            const rq: DataSearchRqData = {
                where: {
                    tour: module.getIn(['changeTourForm', 'tour', 'id'], null),
                    name: payload
                },
                order: { by: 'name', isReverse: false }
            };

            return concat(of(QuoteStory.updateCombinationSearchLockChangeTour(true)), http.post('offers/search-combination-revisions', rq, params).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ICombinationRevisionData>>>) => [
                    QuoteStory.updateCombinationSearchLockChangeTour(false),
                    OrmStory.populateCombinationRevisions(rs.body.results),
                    QuoteStory.updateCombinationsChangeTour(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_FETCH_TOUR_DATA),
        switchMap(() => {
            const module = state.value.quoteModule;
            const id = module.get('changeTourOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);

            return concat(of(QuoteStory.updateFetchLockChangeTour(true)), http.get(`offers/tour-data/${ module.getIn(['changeTourForm', 'tour', 'id']) }`, params).pipe(dispatchActions(
                (rs: Response<Transformed<ICmsTourData, { airports: Array<Transformed<ICmsAirportData>> }>>) => [
                    OrmStory.populateCmsTours([rs.body.data]),
                    OrmStory.populateCmsAirports(rs.body.includes.airports.map(r => r.data)),
                    QuoteStory.updateCmsTourIdChangeTour(rs.body.data.id),
                    QuoteStory.updateFetchLockChangeTour(false)
                ], () => [
                    QuoteStory.updateFetchLockChangeTour(false)
                ]
            )));
        })
    ),
    action.pipe(
        ofType(QuoteStory.CHANGE_TOUR_SUBMIT),
        switchMap(({ payload }: Action<boolean>) => {
            const module = state.value.quoteModule;
            const tmp = ['changeTourForm', 'combination', 'combinationId'];
            const queryParams: IQuoteBuildParams = {
                airport: instanceManager.parseId(module.getIn(['changeTourForm', 'airport', 'id'])).pop(),
                combination: module.hasIn(tmp) ? instanceManager.parseId(module.getIn(tmp)).pop() : undefined,
                departure: module.getIn(['changeTourForm', 'date', 'date']),
                direct: module.getIn(['changeTourForm', 'date', 'direct']) ? 'true' : 'false',
                tour: instanceManager.parseId(module.getIn(['changeTourForm', 'tour', 'tourId'])).pop(),
            };

            return [
                QuoteStory.toggleOpenChangeTour(false),
                SystemStory.navigate(['quotes', module.get('changeTourOpportunityId')], { queryParams, sharedLink: true, replaceUrl: !payload, inNewTab: payload })
            ];
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEND_EMAIL_OPEN),
        switchMap(({ payload }: Action<[IOpportunityData['id'], IQuoteData['revision']?]>) => {
            const [id, nr = null] = payload;
            const module = state.value.quoteModule;
            const opportunity = module.get('sendEmailOpportunityId');
            const revision = module.get('sendEmailRevisionNr');

            if (id === opportunity && nr === revision) {
                return [
                    QuoteStory.updateTemplateIdSendEmail(null),
                    QuoteStory.toggleOpenSendEmail(true)
                ];
            }

            return http.get(`offers/email-message/${ id }`).pipe(
                dispatchActions((rs: Response<Transformed<IEmailTemplateData>>) => [
                    QuoteStory.updateOpportunityIdSendEmail(id),
                    QuoteStory.updateRevisionNrSendEmail(nr),
                    QuoteStory.updateTemplateIdSendEmail(null),
                    QuoteStory.updateTemplateSendEmail(rs.body.data),
                    QuoteStory.toggleSwitchLockSendEmail(rs.body.meta.locked),
                    QuoteStory.toggleOpenSendEmail(true),
                ])
            );
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEND_EMAIL_EMAIL_TEMPLATES_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const { brand, locale } = findById<Opportunity>(orm.session(state.value.orm), Opportunity, module.get('sendEmailOpportunityId'));
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    brand,
                    locale,
                    showDeactivated: false,
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(QuoteStory.updateSendEmailTemplatesLock(true)), http.post('offers/search-email-templates', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    OrmStory.populateEmailTemplates(rs.body.results),
                    QuoteStory.updateSendEmailTemplates(rs.body.results.map(r => r.id)),
                    QuoteStory.updateSendEmailTemplatesLock(false)
                ])
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEND_EMAIL_FETCH_EMAIL_DATA),
        switchMap(() => {
            const module = state.value.quoteModule;

            const rq: any = {
                // If form control disabled pick value from store
                template: module.getIn(['sendEmailForm', 'template', 'id']) || module.getIn(['sendEmailTemplate', 'id']),
                opportunity: module.get('sendEmailOpportunityId'),
                revision: module.get('sendEmailRevisionNr'),
            };

            return concat(of(QuoteStory.updateFetchLockSendEmail(true)), http.post(`offers/show-email-message`, rq).pipe(
                dispatchActions((rs: Response<transformedSendEmailData>) => {
                    const { data, includes } = rs.body;
                    const documentsMapping: Array<IDocumentEmailTemplateMappingData> = [];
                    let documents = [];
                    if ('documents' in includes && includes.documents.length) {
                        includes.documents.forEach((doc: Transformed<IDocumentData>) => {
                            documentsMapping.push({
                                templateId: data.templateId,
                                documentId: doc.data.id
                            });
                        });
                        documents = documents.concat(includes.documents.map(r => r.data));
                    }

                    return [
                        OrmStory.populateEmailMessages([data]),
                        OrmStory.populateEmailTemplates([includes.emailTemplate.data]),
                        OrmStory.populateDomainDetail([includes.domainDetail.data]),
                        OrmStory.populateDocuments(documents),
                        OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                        OrmStory.populateUsers([includes.user.data]),
                        QuoteStory.updateSelectedDocumentsSendEmail(documents.map(r => r.id)),
                        QuoteStory.updateTemplateIdSendEmail(rq.template),
                        QuoteStory.updateFetchLockSendEmail(false)
                    ];
                })
            ));
        })
    ),
    action.pipe(
        ofType(QuoteStory.SEND_EMAIL_SUBMIT),
        mergeMap(({ payload }: Action<Array<{ id: string, file: File }>>) => {
            const module = state.value.quoteModule;
            const documents = <Array<string>>module.get('sendEmailSelectedDocument').toArray();
            const attached: Array<File> = payload.filter(r => documents.includes(r.id)).map(r => r.file);
            const rq: any = {
                opportunity: module.get('sendEmailOpportunityId'),
                revision: module.get('sendEmailRevisionNr'),
                ...module.getIn(['sendEmailForm', 'email']).toJS(),
                documents: documents.filter(r => !payload.map(a => a.id).includes(r)),
            };

            return concat(of(QuoteStory.updateSaveLockSendEmail(true)), (attached.length ? zip(...attached.map(readToBase64)) : of([])).pipe(switchMap(files => {
                    rq.files = files;

                    return http.post(`offers/send-email`, rq).pipe(dispatchActions(
                        () => [
                            SystemStory.showNotification(SystemNotification.success('Success', 'Email was sent.')),
                            QuoteStory.toggleOpenSendEmail(false),
                            QuoteStory.updateOpportunityIdSendEmail(null),
                            QuoteStory.updateRevisionNrSendEmail(null),
                            QuoteStory.updateSaveLockSendEmail(false),
                        ]
                    ));
                }
            )));
        }),
    ),

    action.pipe(
        ofType(QuoteStory.RETRIEVE_PNR),
        mergeMap(({ payload }: Action<string>) => {
            const module = state.value.quoteModule;
            const id = module.get('editPageOpportunityId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));
            const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);

            return concat(of(QuoteStory.updateSaveLockEditPage(true)), http.post(`offers/retrieve-pnr`, { pnr: payload }, params).pipe(dispatchActions(({ body: { pnrNumber, pnrValidityDate, flights, passengers } }: Response<IPnrRetrieveData & { flights: Array<IFlightInfoData> }>) => {
                    const active = module.get('editPageActiveState');
                    const queryParams = module.hasIn(['editPageEditedStates', active, 'queryParams']) ? module.getIn(['editPageEditedStates', active, 'queryParams']).toJS() : module.getIn(['editPagePreparedStates', active, 'queryParams']).toJS();
                    const { tourItinerary, combinationItinerary, quote } = module.get('editPageForm').toJS();

                    return [
                        QuoteStory.removeEditedStateEditPage(active),
                        QuoteStory.mergeEditedStateEditPage({
                            [active]: {
                                queryParams,
                                tourItinerary,
                                combinationItinerary,
                                quote: {
                                    ...quote,
                                    pnr: {
                                        pnrNumber,
                                        pnrValidityDate,
                                        passengers,
                                    },
                                    // The flights received from Amadeus by PNR are added to the list and sorted by their dates
                                    flightInfo: [...(<IQuoteData>quote).flightInfo, ...flights].sort((a, b) => {
                                        // Transform to date time strings, ex: '2020-03-31 12:00:00'
                                        const [from, to] = [[a.date, a.time], [b.date, b.time]].map(([range, time]: [IDateRange | null, string | null]) => {
                                            const t = time ? `${ time.split('-').map(r => r.trim()).shift() }:00` : '00:00:00';

                                            return range ? `${ range.from } ${ t }` : null;
                                        });
                                        // Analyze result and compare timestamps
                                        const [dep, arr] = [Daytable.fromDateTimeString(from), Daytable.fromDateTimeString(to)];
                                        if (!dep || !arr) {
                                            return arr ? 1 : -1;
                                        }

                                        return Number(dep.toDate()) - Number(arr.toDate());
                                    })
                                },
                            }
                        }),
                        RootStory.resetForm(),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Data was transferred.')),
                        QuoteStory.updateSaveLockEditPage(false)
                    ];
                },
                () => [
                    QuoteStory.updateSaveLockEditPage(false),
                ])
            ));
        }),
    )
);
