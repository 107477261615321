import { EmailTemplateStory } from '@aclass/admin/storage/actions';
import {
    IEmailTemplateModuleState,
    INITIAL_EMAIL_TEMPLATE_STATE,
} from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const emailTemplateModuleReducer = (state: TypedState<IEmailTemplateModuleState> = INITIAL_EMAIL_TEMPLATE_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_ORDER:
            return state.update('searchPageOrder', () => Map(payload));
        case EmailTemplateStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case EmailTemplateStory.EDIT_PAGE_UPDATE_ID:
            return state.update('editPageRecordId', () => payload);
        case EmailTemplateStory.EDIT_PAGE_UPDATE_FORM:
            return state.update('editPageForm', () => fromJS(payload));
        case EmailTemplateStory.EDIT_PAGE_UPDATE_UPLOAD_DOCUMENTS_LOCK:
            return state.update('editPageUploadDocumentsLock', () => payload);
        case EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_UPDATE_OPENED:
            return state.update('editPageSendEmailOpened', () => payload);
        case EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_UPDATE_FORM:
            return state.update('editPageSendEmailForm', () => payload);
        case EmailTemplateStory.SEARCH_PAGE_UPDATE_PAGINATION:
            return state.update('searchPagePagination', () => Map(payload));
        case EmailTemplateStory.EDIT_PAGE_UPDATE_LOCALE:
            return state.update('editPageLocale', () => payload);
        case EmailTemplateStory.EDIT_PAGE_UPDATE_BRAND:
            return state.update('editPageBrand', () => payload);
        case EmailTemplateStory.EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('editPageSaveLock', () => payload);
        case EmailTemplateStory.EDIT_PAGE_SAVE_VALIDATE:
            return state.update('editPageSaveValidate', () => payload);
        case EmailTemplateStory.EDIT_PAGE_SEND_EMAIL_SUBMIT_LOCK:
            return state.update('sendEmailPageSendingEmail', () => payload);
        default:
            return state;
    }
};
