import { IItineraryData } from '@aclass/admin/storage/models/itinerary';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IDeliveryData {

    readonly id: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean | null;

    readonly firstDeposit: number | null;

    readonly firstDepositPayDate: string | null;

    readonly secondDeposit: number | null;

    readonly secondDepositPayDate: string | null;

    readonly pnrNumber: string | null;

    readonly latestFlightTicketIssue: string | null;

    readonly revenue: number | null;

    readonly flightPlan: string | null;

    readonly dob: string | null;

    readonly customerSpecialRequest: string | null;

    readonly tripIncludes: string | null;

    readonly pricePerPerson: string | null;

    readonly excludeAdmFee: boolean | null;

    readonly address: boolean | null;

    readonly synchronized: boolean | null;

    readonly messagesToBooker: string | null;

    readonly enquireDataId: Opportunity | number | null;

    readonly itineraryId: string | null;

    readonly combinationItineraryId: string | null;

    tourItinerary?: { [P in keyof IItineraryData]?: any } | null;

    combinationItinerary?: { [P in keyof IItineraryData]?: any } | null;
}

@Model({
    name: 'Delivery'
})
export class Delivery extends Entity<IDeliveryData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean | null;

    @Attr() readonly firstDeposit: number | null;

    @Attr() readonly firstDepositPayDate: string | null;

    @Attr() readonly secondDeposit: number | null;

    @Attr() readonly secondDepositPayDate: string | null;

    @Attr() readonly pnrNumber: string | null;

    @Attr() readonly latestFlightTicketIssue: string | null;

    @Attr() readonly revenue: number | null;

    @Attr() readonly flightPlan: string | null;

    @Attr() readonly dob: string | null;

    @Attr() readonly customerSpecialRequest: string | null;

    @Attr() readonly tripIncludes: string | null;

    @Attr() readonly pricePerPerson: string | null;

    @Attr() readonly excludeAdmFee: boolean | null;

    @Attr() readonly address: boolean | null;

    @Attr() readonly synchronized: boolean | null;

    @Attr() readonly messagesToBooker: string | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedDelivery' }) readonly enquireDataId: Opportunity | null;
}
