import { AuthGuard } from '@aclass/admin/base/auth.guard';
import { SystemStateResolver } from '@aclass/admin/resolvers';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AoLayoutComponent } from './ao/layout/ao-layout.component';
import { CmsLayoutComponent } from './cms/layout/cms-layout.component';
import { LoginAutoComponent } from './shared/login/auto/login-auto.component';
import { LoginBaseComponent } from './shared/login/base/login-base.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
    { path: '', redirectTo: '/auto-login', pathMatch: 'full' },
    { path: 'auto-login', component: LoginAutoComponent },
    { path: 'login', component: LoginBaseComponent },
    { path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule), component: CmsLayoutComponent, canActivate: [AuthGuard], resolve: { SystemStateResolver }, data: { application: 'cms' } },
    { path: 'ao', loadChildren: () => import('./ao/ao.module').then(m => m.AoModule), component: AoLayoutComponent, canActivate: [AuthGuard], resolve: { SystemStateResolver }, data: { application: 'ao' } },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        }),
    ],
    // imports: [RouterModule.forRoot(routes, { enableTracing: true })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
