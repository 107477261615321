import { Pipe, PipeTransform } from '@angular/core';
import { BaseDateFormatPipe } from './base.date.format.pipe';

@Pipe({
    name: 'dateFormat'
})

export class DateFormatPipe extends BaseDateFormatPipe implements PipeTransform {

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order: date format
     */
    transform(value: any, ...args: any[]): string {
        const [format = BaseDateFormatPipe.DATE] = args;

        return super.transform(value, format);
    }
}
