import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITourDestinationData {

    readonly id: number | null;

    readonly name: string | null;

    readonly link: string | null;
}

@Model({
    name: 'TourDestinations'
})
export class TourDestinations extends Entity<ITourDestinationData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly link: string | null;
}
