<form [attach]="ff.attach" [formGroup]="ff.form" novalidate (ngSubmit)="runSave()">
    <ng-template #header>
        <adm-back-button></adm-back-button>
        <button type="submit" class="btn btn-info-outline btn-sm" [clrLoading]="saveLoading">
            <clr-icon shape="check"></clr-icon>
            Save
        </button>
    </ng-template>
    <adm-content-layout [header]="header" [showProgress]="showProgress">
        <div class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                Username:
            </div>
            <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-12">
                <input type="text" class="form-control" formControlName="username"/>
            </div>
        </div>
        <div class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                Email:
            </div>
            <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-12">
                <input type="email" class="form-control" formControlName="email"/>
            </div>
        </div>
        <div class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                Role:
            </div>
            <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-12">
                <select class="form-control" formControlName="role">
                    <option *ngFor="let name of roleNames" [value]="name">{{ name }}</option>
                </select>
            </div>
        </div>
        <div class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                Deactivated:
            </div>
            <div class="clr-col">
                <adm-checkbox-button [sm]="false" formControlName="deleted"></adm-checkbox-button>
            </div>
        </div>
        <div class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                Password:
            </div>
            <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-12">
                <input type="password" class="form-control" formControlName="password" placeholder="Alphanumeric, minimal 5 characters"/>
            </div>
        </div>
    </adm-content-layout>
    <clr-modal [(clrModalOpen)]="shouldLogout" [clrModalClosable]="false">
        <h3 class="modal-title">Notice</h3>
        <div class="modal-body">
            <p>Your profile has been changed. You should re-login to the system</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="logout()">Re-login</button>
        </div>
    </clr-modal>
</form>