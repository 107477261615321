import { ISwitchButtonData } from '@aclass/admin/components';
import { CustomerEmailMessageLog } from '@aclass/admin/storage/models/customer.email.message.log';
import { CustomerLog } from '@aclass/admin/storage/models/customer.log';
import { Document } from '@aclass/admin/storage/models/document';
import { DocumentCustomerMapping } from '@aclass/admin/storage/models/document.customer.mapping';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Attr, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { Model as Entity, QuerySet } from 'redux-orm';

@Model({
    name: 'Customer'
})
export class Customer extends Entity<ICustomerData> {

    static GENDER_MALE = 1;

    static GENDER_FEMALE = 2;

    static GENDERS = [
        Customer.GENDER_MALE,
        Customer.GENDER_FEMALE
    ];

    static GENDER_NAMES = {
        [Customer.GENDER_MALE]: 'Male',
        [Customer.GENDER_FEMALE]: 'Female',
    };

    @Attr() readonly id: string | null;

    @Attr() readonly gender: number | null;

    @Attr() readonly title: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly surname: string | null;

    @Attr() readonly email: string | null;

    @Attr() readonly phone: string | null;

    @Attr() readonly birthAt: string | null;

    @Attr() readonly countryCode: string | null;

    @Attr() readonly state: string | null;

    @Attr() readonly city: string | null;

    @Attr() readonly address: string | null;

    @Attr() readonly zip: string | null;

    @Attr() readonly purchase: boolean | null;

    @Attr() readonly sent: boolean | null;

    @Attr() readonly followup: boolean | null;

    @Attr() readonly agreed: boolean | null;

    @Attr() readonly deleted: boolean | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly notifiedAt: number | null;

    @Attr() readonly anonymizedAt: number | null;

    @Many({ to: 'Document', relatedName: 'relatedCustomers', through: 'DocumentCustomerMapping', throughFields: ['customerId', 'documentId'] }) readonly relatedDocuments: QuerySet<Document>;

    readonly relatedOpportunities: QuerySet<Opportunity>;

    readonly relatedDocumentCustomerMappings: QuerySet<DocumentCustomerMapping>;

    readonly relatedLogs: QuerySet<CustomerLog>;

    readonly relatedEmailLogs: QuerySet<CustomerEmailMessageLog>;

    get fullName() {
        return [this.name, this.surname].filter(r => r).join(' ');
    }

    get fullAddress() {
        return [this.countryCode, this.state, this.city, this.address, this.zip].filter(r => r).join(', ');
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeGenderForSwitchButton(): Array<ISwitchButtonData<number>> {
        return Customer.GENDERS.map(r => ({
            title: Customer.GENDER_NAMES[r],
            value: r,
        }));
    }
}
