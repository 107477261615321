import { isDuration, Duration } from '@aclass/core/components';
import { forwardRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidator, ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';

@Component({
    selector: 'adm-range-input',
    template: `
        <input #di type="text" class="b-range-input__control form-control" [class.form-control-sm]="sm" [class.disabled]="disabled" (blur)="runUpdateView()" (input)="runUpdate()">
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeInputComponent),
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => RangeInputComponent),
            multi: true,
        }
    ],
})
export class RangeInputComponent implements OnInit, ControlValueAccessor, AsyncValidator {

    static RANGE_REGEX: RegExp = /^(-?[0-9]+)\s?-?\s?(-?[0-9]*)$/;

    @HostBinding('class.b-range-input') yes = true;

    @ViewChild('di', { static: true }) input: ElementRef<HTMLInputElement>;

    /**
     * Watched value
     */
    @Input() set value(v: Duration | null) {
        this._v = isDuration(v) ? v : null;
        this.runUpdateView(true);
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<Duration | null> = new EventEmitter<Duration | null>();

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Min value
     */
    @Input() min = 0;

    /**
     * Max value
     */
    @Input() max = Infinity;

    @Input() readonly = false;

    private _v: Duration | null;

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    get dirtyValue(): [number | null, number | null] {
        const [, from, to] = this.input.nativeElement.value
            .split(RangeInputComponent.RANGE_REGEX)
            .map(r => r.trim())
            .map(r => /^[\-?\d]+$/.test(r) ? Number(r) : null);

        return [from, to ? to : from];
    }

    /**
     * @inheritDoc
     */
    ngOnInit() {
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v: Duration | null) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v: Duration | null) {
        // If element is disabled angular send undefined, this removes all items
        // We already control this in [[runUpdate]]
        if (!v) {
            return;
        }
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v: Duration | null) => void = () => { };

    /**
     * @inheritDoc
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        if (!this.input.nativeElement.value || this._disabled) {
            return of(null);
        }
        if (!RangeInputComponent.RANGE_REGEX.test(this.input.nativeElement.value)) {
            return of({ range: 'Please enter correct range' });
        }
        const error: { [key: string]: any } = { };
        const [from, to] = <[number, number]>this.dirtyValue;
        if (this.min > from) {
            error['min'] = { min: this.min, actual: from };
        }
        if (this.max < to) {
            error['max'] = { max: this.max, actual: from };
        }
        if (from > to) {
            error['range'] = `${ from } must be greater than ${ to }`;
        }

        return of(Object.keys(error) ? error : null);
    }

    /**
     * Emits changes
     */
    runUpdate() {
        if (this.disabled || this.readonly) {
            return;
        }
        const [from, to] = RangeInputComponent.RANGE_REGEX.test(this.input.nativeElement.value) ? this.dirtyValue : [null, null];
        this._v = from === null || to === null ||  from > to  ? null : { from, to };
        this.onChangeCallback(this._v);
        this.valueChange.emit(this._v);
    }

    /**
     * If start and end same, hack view to show one value
     */
    runUpdateView(hard: boolean = false) {
        if (!this._v) {
            if (hard) {
                this.input.nativeElement.value = '';
            }

            return;
        }
        const { from, to } = this._v;

        this.input.nativeElement.value = from === to ? String(from) : `${ from } - ${ to }`;
    }
}
