import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ICountryData {

    readonly id: number;

    readonly name: string;

    readonly code: string;
}

@Model({
    name: 'Country'
})
export class Country extends Entity<ICountryData> {

    @Attr() readonly id: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly code: string | null;
}
