import { CombinationRevision } from '@aclass/admin/storage/models/combination.revision';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface ICombinationData {

    readonly id: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean;
}

@Model({
    name: 'Combination'
})
export class Combination extends Entity<ICombinationData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean;

    readonly combinationRevisions: QuerySet<CombinationRevision>;
}

