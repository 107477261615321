import { TWOption } from '@aclass/admin/storage/models/tw.option';
import { ITWProductData, TWProduct } from '@aclass/admin/storage/models/tw.product';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ITWContractData {

    readonly id: number;

    readonly name: string;

    readonly supplier: string;

    readonly currency: string | null;

    readonly productId: ITWProductData['id'];

    readonly deleted: boolean;
}

@Model({
    name: 'TWContract'
})
export class TWContract extends Entity<ITWContractData> {

    @Attr() readonly id: number;

    @Attr() readonly name: string;

    @Attr() readonly supplier: string;

    @Attr() readonly currency: string | null;

    @Attr() readonly deleted: boolean;

    @Fk({ to: 'TWProduct', relatedName: 'relatedContracts' }) readonly productId: TWProduct | null;

    readonly relatedOptions: Array<TWOption>;
}
