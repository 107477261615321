import { ISwitchButtonData } from '@aclass/admin/components';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { IWorkingHoursDaysData, IWorkingHoursDayData, IWorkingHoursValueData } from '@aclass/core/storage/models/working.hours';
import { Model as Entity } from 'redux-orm';

export interface IOfficeWorkingHoursData extends IWorkingHoursValueData {

    readonly id: string | null;

    readonly days: Array<IWorkingHoursDaysData>;

}

@Model({
    name: 'OfficeWorkingHours'
})
export class OfficeWorkingHours extends Entity<IOfficeWorkingHoursData> {

    static DAY_TYPE_HOLIDAY = 'hdy';

    static DAY_TYPE_WORKING = 'wdy';

    static DAY_TYPES = [
        OfficeWorkingHours.DAY_TYPE_HOLIDAY,
        OfficeWorkingHours.DAY_TYPE_WORKING,
    ];

    static DAY_NAMES = {
        [OfficeWorkingHours.DAY_TYPE_HOLIDAY]: 'Holiday',
        [OfficeWorkingHours.DAY_TYPE_WORKING]: 'Working day',
    };

    @Attr() readonly id: string | null;

    @Attr() readonly mon: IWorkingHoursDayData | null;

    @Attr() readonly tue: IWorkingHoursDayData | null;

    @Attr() readonly wed: IWorkingHoursDayData | null;

    @Attr() readonly thu: IWorkingHoursDayData | null;

    @Attr() readonly fri: IWorkingHoursDayData | null;

    @Attr() readonly sat: IWorkingHoursDayData | null;

    @Attr() readonly sun: IWorkingHoursDayData | null;

    @Attr() readonly days: Array<IWorkingHoursDaysData>;

    /**
     * Prepares statuses for checkbox
     */
    static composeTypeForSwitchButton(): Array<ISwitchButtonData<string>> {
        return OfficeWorkingHours.DAY_TYPES.map(r => ({
            title: OfficeWorkingHours.DAY_NAMES[r],
            value: r,
        }));
    }
}
