import { ISwitchButtonData } from '@aclass/admin/components';
import { OfflineBooking } from '@aclass/admin/storage/models/offline.booking';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOfflineBookingPassengerData {

    readonly id: string | null;

    readonly gender: number | null;

    readonly title: number | null;

    readonly name: string | null;

    readonly surname: string | null;

    readonly birth: string | null;

    readonly bookingId: OfflineBooking | string | null;
}

@Model({
    name: 'OfflineBookingPassenger'
})
export class OfflineBookingPassenger extends Entity<IOfflineBookingPassengerData> {

    /**
     * male's code
     */
    static GENDER_MALE = 1;

    /**
     * female's code
     */
    static GENDER_FEMALE = 2;

    /**
     * child's code
     */
    static GENDER_CHILD = 3;

    static GENDERS = [
        OfflineBookingPassenger.GENDER_MALE,
        OfflineBookingPassenger.GENDER_FEMALE,
        OfflineBookingPassenger.GENDER_CHILD,
    ];

    static GENDER_NAMES = {
        [OfflineBookingPassenger.GENDER_MALE]: 'Male',
        [OfflineBookingPassenger.GENDER_FEMALE]: 'Female',
        [OfflineBookingPassenger.GENDER_CHILD]: 'Child',
    };

    static TITLE_MR = 1;

    static TITLE_MS = 2;

    static TITLE_MRS = 3;

    static TITLES = [
        OfflineBookingPassenger.TITLE_MR,
        OfflineBookingPassenger.TITLE_MS,
        OfflineBookingPassenger.TITLE_MRS
    ];

    static TITLE_NAMES = {
        [OfflineBookingPassenger.TITLE_MR]: 'mr',
        [OfflineBookingPassenger.TITLE_MS]: 'ms',
        [OfflineBookingPassenger.TITLE_MRS]: 'mrs',
    };

    @Attr() readonly id: string | null;

    @Attr() readonly gender: number | null;

    @Attr() readonly title: number | null;

    @Attr() readonly name: string | null;

    @Attr() readonly surname: string | null;

    @Attr() readonly birth: string | null;

    @Fk({ to: 'OfflineBooking', relatedName: 'relatedOfflineBookingPassengers' }) readonly bookingId: OfflineBooking | null;

    /**
     * Prepares statuses for checkbox
     */
    static composeGenderForSwitchButton(): Array<ISwitchButtonData<number>> {
        return OfflineBookingPassenger.GENDERS.map(r => ({
            title: OfflineBookingPassenger.GENDER_NAMES[r],
            value: r,
        }));
    }

    /**
     * Prepares statuses for checkbox
     */
    static composeTitleForSwitchButton(): Array<ISwitchButtonData<number>> {
        return OfflineBookingPassenger.TITLES.map(r => ({
            title: OfflineBookingPassenger.TITLE_NAMES[r],
            value: r,
        }));
    }
}
