import { TemplateStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IAdminState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { DRIVERS } from 'angular-safeguard';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const templateModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { storage }: IEpicDi) => merge(
    action.pipe(
        ofType(TemplateStory.UPDATE_TOGGLE_MENU),
        mergeMap(({ payload }: Action<boolean>) => {
            storage.set(DRIVERS.LOCAL, 'templateModule:menuCollapsed', payload);

            return of(TemplateStory.updateMenuCollapsed(payload));
        }),
    ),
);
