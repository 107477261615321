import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'lead-scoring'
})
export class LeadScoringStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_PRODUCT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static EDIT_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static EDIT_PAGE_UPDATE_AIRPORTS: string;

    @Task() static EDIT_PAGE_UPDATE_ANSWERS: string;

    @Task() static EDIT_PAGE_UPDATE_LEAD_SCORE_VALUE: string;

    @Task() static EDIT_PAGE_ADD_FORM_ELEMENT: string;

    @Task() static EDIT_PAGE_REMOVE_FORM_ELEMENT: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateInstancesOnSearchPage(ids: Array<string>): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_IMPORT_INSTANCES, payload: ids };
    }

    static updateFormSearchPage(data): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static submitOnSearchPage(refresh: boolean = false): Action {
        return { type: LeadScoringStory.SEARCH_PAGE_SUBMIT, payload: refresh };
    }

    static updateSaveLockCreatePage(v: boolean | null): Action {
        return { type: LeadScoringStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static submitOnCreatePage(): Action {
        return { type: LeadScoringStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: string): Action {
        return { type: LeadScoringStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateProductIdEditPage(id: string): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_PRODUCT_ID, payload: id };
    }

    static updateFormOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateValueOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static updateDestinationsOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateAirportsOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_AIRPORTS, payload: data };
    }

    static updateAnswersOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_ANSWERS, payload: data };
    }

    static updateLeadScoreValueOnEditPage(data: any): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_LEAD_SCORE_VALUE, payload: data };
    }

    static addFormElement(name: string): Action {
        return { type: LeadScoringStory.EDIT_PAGE_ADD_FORM_ELEMENT, payload: name };
    }

    static removeFormElement(index: number, name: string): Action {
        return { type: LeadScoringStory.EDIT_PAGE_REMOVE_FORM_ELEMENT, payload: [index, name] };
    }

    static updateSaveLockEditPage(v: boolean | null): Action {
        return { type: LeadScoringStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload: v };
    }

    static submitOnEditPage(): Action {
        return { type: LeadScoringStory.EDIT_PAGE_SUBMIT };
    }
}
