<form [attach]="ff.attach" [formGroup]="ff.form" novalidate (ngSubmit)="runSave()">
    <ng-template #header>
        <adm-back-button></adm-back-button>
        <button type="submit" class="btn btn-info-outline btn-sm">
            <clr-icon shape="check"></clr-icon>
            Save
        </button>
    </ng-template>
    <adm-content-layout [header]="header">
        <div *ngFor="let key of controls" class="clr-row mb-1">
            <div class="clr-col-xl-2 clr-col-lg-2 clr-col-md-12">
                {{ key | uppercase }} translation
            </div>
            <div class="clr-col-xl-4 clr-col-lg-4 clr-col-md-12">
                <input type="text" class="form-control" [formControlName]="key"/>
            </div>
        </div>
    </adm-content-layout>
</form>
