import { hashed } from '@aclass/core/helpers/hashed';
import { WINDOW } from '@aclass/core/services/window.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'adm-upload-button',
    templateUrl: './upload-button.component.html'
})
export class UploadButtonComponent implements OnInit {

    @Input() id: string = hashed();

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Show slim version
     */
    @Input() slim = true;

    /**
     * Allow pick multiple files
     */
    @Input() multiple = true;

    /**
     * Allow set loading state from parent component(Waiting for upload)
     */
    @Input() loading = false;

    /**
     * Change event emitter
     */
    @Output() upload: EventEmitter<Array<File>> = new EventEmitter<Array<File>>();

    form: FormGroup;

    constructor(
        @Inject(WINDOW) private window: Window
    ) { }

    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.form = new FormGroup({
            files: new FormControl(null),
        });
    }

    runPick() {
        if (!('document' in this.window)) {
            return;
        }
        this.window.document.getElementById(this.id).click();
    }

    /**
     * Handles upload action
     */
    runUpload(e: Event) {
        // @ts-ignore
        this.upload.emit(Array.from(e.target.files));
        this.form.reset();
    }
}
