import { ItineraryDay, IItineraryDayData } from '@aclass/admin/storage/models/itinerary.day';
import { ItineraryPrice, IItineraryPriceData } from '@aclass/admin/storage/models/itinerary.price';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IItineraryData {

    readonly id: string;

    readonly margin: number;

    readonly childRate: number;

    readonly days: Array<Partial<IItineraryDayData>>;

    readonly prices: Array<Partial<IItineraryPriceData>>;
}

@Model({
    name: 'Itinerary'
})
export class Itinerary extends Entity<IItineraryData> {

    @Attr() readonly id: string;

    @Attr() readonly margin: number;

    @Attr() readonly childRate: number;

    readonly relatedDays: QuerySet<ItineraryDay>;

    readonly relatedPrices: QuerySet<ItineraryPrice>;
}
