import { Permission } from '@aclass/admin/storage/models/permission';
import { User } from '@aclass/admin/storage/models/user';
import { Attr, Many, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IRoleData {

    readonly name: string | null;

    readonly permissions: Array<string>;
}

@Model({
    name: 'Role',
    idAttribute: 'name'
})
export class Role extends Entity<IRoleData> {

    @Attr() readonly name: string | null;

    @Many({ to: 'Permission', relatedName: 'roleRecords' }) readonly permissions: QuerySet<Permission>;

    readonly userRecords: QuerySet<User>;
}
