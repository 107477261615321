import { IInstanceData } from '@aclass/core/base/instance';
import { IAppState } from '@aclass/core/storage/states/app.state';
import { ISystemModuleState } from '@aclass/core/storage/states/system.module.state';
import { NgRedux } from '@angular-redux/store';

/**
 * This is bridge between redux store and reactive forms
 */
export class BasePipeManager {

    private _dateFormats: {
        [locale: string]: [string, string?, string?]; // [format, decimalSeparator, thousandSeparator]
    };

    private _priceFormats: {
        [locale: string]: [string, string?, string?]; // [format, decimalSeparator, thousandSeparator]
    };

    /**
     * Initializes the object.
     */
    protected init(ngRdx: NgRedux<IAppState>): void {
        ngRdx.select<ISystemModuleState['config']>(['systemModule', 'config']).subscribe(v => {
            this._dateFormats = { };
            this._priceFormats = { };
            if (v.has('priceFormatting')) {
                const formats: { [key: string]: { format?: string, thousandSeparator?: string, decimalSeparator?: string } } = v.get('priceFormatting').toJS();
                this._priceFormats = <any>Object.keys(formats).reduce((prev: object, locale) => {
                    const { format, thousandSeparator, decimalSeparator } = formats[locale];
                    prev[locale] = [format, decimalSeparator, thousandSeparator];

                    return prev;
                }, { });
            }
            if (v.has('dateFormatting')) {
                const formats: { [key: string]: { format?: string } } = v.get('dateFormatting').toJS();
                this._dateFormats = <any>Object.keys(formats).reduce((prev: object, locale) => {
                    const { format } = formats[locale];
                    prev[locale] = [format];

                    return prev;
                }, { });
            }
        });
    }

    getPriceFormatParams(locale?: IInstanceData['locale'], ...args: any[]): any[] {
        if (!locale || !(locale in this._priceFormats)) {
            return args;
        }
        const format = this._priceFormats[locale];
        const size = Math.max(args.length, format.length);
        for (let i = 0; i < size; i++) {
            // If argument provided prefer it, otherwise use value from formatter
            args[i] = typeof args[i] !== 'undefined' ? args[i] : format[i];
        }

        return args;
    }

    getDateFormatParams(locale?: IInstanceData['locale'], ...args: any[]): any[] {
        if (!locale || !(locale in this._dateFormats)) {
            return args;
        }
        const format = (Array.isArray(locale) && locale.length === 1) ? this._dateFormats[locale][0] : this._dateFormats[locale];
        const size = Math.max(args.length, format.length);

        for (let i = 0; i < size; i++) {
            // If argument provided prefer it, otherwise use value from formatter
            args[i] = typeof args[i] !== 'undefined' ? args[i] : format[i];
        }

        return args;
    }
}
