import { ISwitchButtonControl, ISwitchButtonData } from '@aclass/admin/components';
import { forwardRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'adm-switch-button',
    templateUrl: './switch-button.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchButtonComponent),
            multi: true
        }
    ]
})
export class SwitchButtonComponent implements ControlValueAccessor {

    /**
     * Watched value
     */
    @Input() set value(v) {
        this._selected = Array.isArray(v) ? v : [v];
        this._controls.forEach(i => {
            i.selected = this._selected.some(value => i.value === value);
        });
    }

    /**
     * Change event emitter
     */
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Show small version
     */
    @Input() sm = true;

    /**
     * Show slim version
     */
    @Input() slim = true;

    /**
     * Allow pick multiple values
     */
    @Input() multiple = true;

    /**
     * Restricts to pick one item
     */
    @Input() strict = true;

    @Input() readonly = false;

    @Input() set items(v: Array<ISwitchButtonData>) {
        this._controls = (Array.isArray(v) ? v : []).map(i => ({ ...i, selected: this._selected.some(value => i.value === value) }));
    }

    private _selected: Array<any> = [];

    private _controls: Array<ISwitchButtonControl> = [];

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    get controls() {
        return this._controls;
    }

    get selected() {
        return this.multiple ? this._selected : [...this._selected].shift();
    }

    /**
     * @inheritDoc
     */
    registerOnChange(fn: (v) => void) {
        this.onChangeCallback = fn;
    }

    /**
     * @inheritDoc
     */
    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }

    /**
     * @inheritDoc
     */
    writeValue(v) {
        // If element is disabled angular send undefined, this removes all items
        // We already control this in [[runUpdate]]
        if (typeof v === 'undefined') {
            return;
        }
        this.value = v;
    }

    onTouchedCallback: () => void = () => { };

    onChangeCallback: (v) => void = () => { };

    /**
     * Emits changes
     */
    runUpdate(control: ISwitchButtonControl) {
        if (this._disabled || this.readonly) {
            return;
        }
        if (this.strict && this._controls.filter(({ selected }) => selected).length && !this._controls.some(({ selected, value }) => selected && value !== control.value)) {
            return;
        }
        this._controls.forEach(i => {
            if (i.value === control.value) {
                i.selected = !control.selected;

                return;
            }
            if (!this.multiple) {
                i.selected = false;
            }
        });
        this._selected = this._controls.filter(({ selected }) => selected).map(({ value }) => value);
        const v = this.selected;
        this.onChangeCallback(v);
        this.valueChange.emit(v);
    }
}
