import { DestinationRevision } from '@aclass/admin/storage/models/destination.revision';
import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export interface IDestinationData {

    readonly id: string | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;

    readonly deleted: boolean;
}

@Model({
    name: 'Destination'
})
export class Destination extends Entity<IDestinationData> {

    @Attr() readonly id: string | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;

    @Attr() readonly deleted: boolean;

    readonly destinationRevisions: QuerySet<DestinationRevision>;
}

