import { IQuestionnaireData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { Map } from 'immutable';

export interface IQuestionnaireModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of questionnaire ids from ORM
     */
    searchPageResults: Array<object> | null;

    /**
     * The order data
     */
    searchPageOrder: Map<string, string> | null;

    /**
     * The pagination data
     */
    searchPagePagination: Map<string, number> | null;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * The id of overview, used in copy action
     */
    createPageRecordId: IQuestionnaireData['id'] | null;

    /**
     * The id of questionnaire
     */
    editPageRecordId: IQuestionnaireData['id'];

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * Search results form data
     */
    resultSearchPageForm: Map<string, any>;

    /**
     * A list of questionnaire result ids from ORM
     */
    resultSearchPageResults: Array<object> | null;

    /**
     * The id of questionnaire
     */
    resultPageRecordId: string | null;

    /**
     * The id of questionnaire result
     */
    showResultPageRecordId: string | null;

    /**
     * show detail result form data
     */
    showResultPageForm;
}

export const INITIAL_QUESTIONNAIRE_STATE = createTypedState<IQuestionnaireModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageOrder: null,
    searchPagePagination: null,
    createPageForm: { },
    editPageRecordId: null,
    editPageForm: { },
    resultSearchPageForm: { },
    resultSearchPageResults: [],
    resultPageRecordId: null,
    showResultPageRecordId: null,
    showResultPageForm: { },
    createPageRecordId: null,
});
