import { SplitTestStory } from '@aclass/admin/storage/actions';
import { INITIAL_SPLIT_TEST_STATE, ISplitTestModuleState } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List } from 'immutable';

export const splitTestModuleReducer = (state: TypedState<ISplitTestModuleState> = INITIAL_SPLIT_TEST_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case SplitTestStory.SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('searchPageInitialized', () => payload);
        case SplitTestStory.SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('searchPageCollapsed', () => payload);
        case SplitTestStory.SEARCH_PAGE_UPDATE_FORM:
            return state.update('searchPageForm', () => fromJS(payload));
        case SplitTestStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('searchPageLock', () => payload);
        case SplitTestStory.SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('searchPageResults', () => List(payload));
        case SplitTestStory.VIEW_PAGE_UPDATE_RECORD_ID:
            return state.update('viewPageRecordId', () => payload);
        case SplitTestStory.VIEW_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('viewPageSearchLock', () => payload);
        case SplitTestStory.VIEW_PAGE_UPDATE_SEARCH_FORM:
            return state.update('viewPageSearchForm', () => fromJS(payload));
        case SplitTestStory.VIEW_PAGE_UPDATE_TEST_NAME:
            return state.update('viewPageTestName', () => payload);
        case SplitTestStory.VIEW_PAGE_UPDATE_TEST_VALUE:
            return state.update('viewPageTestValue', () => List(payload));
        default:
            return state;
    }
};
