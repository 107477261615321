import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'i18n'
})
export class InternationalizationStory {

    @Task() static UPDATE_CATEGORY: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SHOW_PAGE_DRY_RUN: string;

    @Task() static SHOW_PAGE_UPDATE_MESSAGE_ID: string;

    @Task() static SHOW_PAGE_UPDATE_FORM: string;

    @Task() static SHOW_PAGE_SUBMIT: string;

    static dryRunSearchPage(): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateCollapsedSearchPage(v: boolean | null): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateFormSearchPage(data: any): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateCategory(moduleName: string | null): Action {
        return { type: InternationalizationStory.UPDATE_CATEGORY, payload: moduleName };
    }

    static updateSearchPageInitState(v: boolean | null): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updatePaginationOnSearchPage(pagination: IPaginationData | null): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearchPage(order: IOrderData | null): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_ORDER, payload: order };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static importRecordsOnSearchPage(ids: Array<string>): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static updateSearchLockOnSearchPage(v: boolean | null): Action {
        return { type: InternationalizationStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static dryRunShowPage(id: number): Action {
        return { type: InternationalizationStory.SHOW_PAGE_DRY_RUN, payload: id };
    }

    static updateMessageIdShowPage(id: number): Action {
        return { type: InternationalizationStory.SHOW_PAGE_UPDATE_MESSAGE_ID, payload: id };
    }

    static updateFormOnShowPage(data: any): Action {
        return { type: InternationalizationStory.SHOW_PAGE_UPDATE_FORM, payload: data };
    }

    static submitOnShowPage(): Action {
        return { type: InternationalizationStory.SHOW_PAGE_SUBMIT };
    }
}
