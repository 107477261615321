import { IOverviewData, Overview } from '@aclass/admin/storage/models/overview';
import { IOverviewDefinitionData, OverviewDefinition } from '@aclass/admin/storage/models/overview.definition';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IOverviewConditionData {

    readonly id: number | null;

    readonly definitionId: IOverviewDefinitionData['id'] | null;

    readonly overviewId: IOverviewData['id'] | null;

    readonly group: number | null;

    readonly operator: number | null;

    readonly value: string | null;

    readonly editable: boolean | null;
}

@Model({
    name: 'OverviewCondition'
})
export class OverviewCondition extends Entity<IOverviewConditionData> {

    @Attr() readonly id: number | null;

    @Attr() readonly group: number | null;

    @Attr() readonly operator: number | null;

    @Attr() readonly value: string | null;

    @Attr() readonly editable: boolean | null;

    @Fk({ to: 'OverviewDefinition', relatedName: 'relatedConditions' }) readonly definitionId: OverviewDefinition | null;

    @Fk({ to: 'Overview', relatedName: 'relatedConditions' }) readonly overviewId: Overview | null;
}
