import { IDomainDetailData } from '@aclass/admin/storage/models';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface IDomainDetailModuleState extends IModuleState {

    /**
     * Identifies that all data for search page is loaded
     */
    searchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    searchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    searchPageLock: boolean | null;

    /**
     * Search form data
     */
    searchPageForm: Map<string, any>;

    /**
     * A list of domain-details ids from ORM
     */
    searchPageResults: List<IDomainDetailData['id']>;

    /**
     * A list of domains
     */
    searchPageInstances: List<string>;

    /**
     * A list of user ids
     */
    searchUsers: List<number>;

    /**
     * Create form data
     */
    createPageForm: Map<string, any>;

    /**
     * Edit form data
     */
    editPageForm: Map<string, any>;

    /**
     * The brand-locale
     */
    editPageRecordId: string | null;

    /**
     * Trigger to identify that search is running
     */
    editPageUserSearchLock: boolean | null;

    /**
     * A list of users
     */
    editPageUsers: List<number>;

    /**
     * A list of email templates
     */
    editPageEmailTemplates: List<number>;

    /**
     * Trigger to identify that search email-templates  is running
     */
    editPageEmailTemplateSearchLock: boolean | null;

    /**
     * A list of sms templates
     */
    editPageSmsTemplates: List<number>;


    /**
     * A list of questionnaires templates
     */
    editPageQuestionnairesTemplates: List<number>;

    /**
     * Trigger to identify that search sms-templates is running
     */
    editPageSmsTemplateSearchLock: boolean | null;

     /**
     * Trigger to identify that search questionnaires-templates is running
     */
    editPageQuestionnairesTemplateSearchLock: boolean | null;

}

export const INITIAL_DOMAIN_DETAIL_STATE = createTypedState<IDomainDetailModuleState>({
    searchPageInitialized: null,
    searchPageCollapsed: null,
    searchPageLock: null,
    searchPageForm: { },
    searchPageResults: [],
    searchPageInstances: [],
    searchUsers: [],
    createPageForm: { },
    editPageForm: { },
    editPageRecordId: null,
    editPageUserSearchLock: null,
    editPageUsers: [],
    editPageEmailTemplates: [],
    editPageEmailTemplateSearchLock: null,
    editPageSmsTemplates: [],
    editPageSmsTemplateSearchLock: null,
    editPageQuestionnairesTemplates: [],
    editPageQuestionnairesTemplateSearchLock: null,
});
