import { IAskData } from '@aclass/admin/components';
import { ModalManager } from '@aclass/admin/managers';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface OnPageLeave {
    /**
     * Checks that component has not saved changes and blocks page leave without confirmation
     */
    hasChanges: () => boolean;
}

@Injectable()
export class ChangesGuard implements CanDeactivate<OnPageLeave> {

    constructor(
        private modalManager: ModalManager,
    ) { }

    canDeactivate(
        component: OnPageLeave,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!('hasChanges' in component) || !component.hasChanges()) {
            return true;
        }
        const params: Partial<IAskData> = {
            title: 'Confirm',
            message: 'You have not saved changes. Do you want to leave this page?'
        };

        return this.modalManager.ask(params);
    }
}
