import { RouterManager } from '@aclass/admin/managers';
import { SmsTemplateStory } from '@aclass/admin/storage/actions';
import { IAdminState, ISmsTemplateModuleState } from '@aclass/admin/storage/states';
import { BaseResolver } from '@aclass/core/resolvers/base.resolver';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class SmsTemplateSearchStateResolver extends BaseResolver {

    static STOP_EVENT_NAME = 'stop:sms-template-search';

    constructor(
        private ngRdx: NgRedux<IAdminState>,
        private routerManager: RouterManager
    ) {
        super();
        this.resolver = this.ngRdx.select<ISmsTemplateModuleState['searchPageInitialized']>(['smsTemplateModule', 'searchPageInitialized']).pipe(
            filter(v => v)
        );
        this.employee = this.routerManager.onRejectNavigation(SmsTemplateSearchStateResolver.STOP_EVENT_NAME);
    }

    /**
     * @inheritDoc
     */
    beforeResolve(): void {
        this.ngRdx.dispatch(SmsTemplateStory.dryRunSearchPage());
    }
}
