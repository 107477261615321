import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface ISignatureData {

    readonly id: string | null;

    readonly userId: number | null;

    readonly content: string | null;

    readonly locale: string | null;

    readonly brand: string | null;

    readonly brandLabel: string | null;

    readonly creatorId: number | null;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;
}

@Model({
    name: 'Signature'
})
export class Signature extends Entity<ISignatureData> {

    @Attr() readonly id: string;

    @Attr() readonly userId: number;

    @Attr() readonly content: string;

    @Attr() readonly locale: string;

    @Attr() readonly brand: string;

    @Attr() readonly brandLabel: string;

    @Attr() readonly creatorId: number;

    @Attr() readonly createdAt: number;

    @Attr() readonly updatedAt: number;
}
