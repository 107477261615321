import { IEmailPipelineModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'email-pipeline'
})
export class EmailPipelineStory {

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_UPDATE_PIPELINE_LIST: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_SHOW_SEND_MODAL: string;

    @Task() static EDIT_PAGE_SEND: string;

    @Task() static EDIT_PAGE_UPDATE_MODAL_FORM: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION: string;

    @Task() static EDIT_PAGE_DOWNLOAD_DOCUMENT: string;

    @Task() static EDIT_PAGE_REMOVE_DOCUMENT: string;

    @Task() static EDIT_PAGE_UPDATE_PICKED_DOCUMENT: string;

    static updatePickedDocumentEditPage(v: string | null): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPDATE_PICKED_DOCUMENT, payload: v };
    }
    static removeDocumentEditPage(v: string): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_REMOVE_DOCUMENT, payload: v };
    }

    static downloadDocumentEditPage(v: string): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_DOWNLOAD_DOCUMENT, payload: v };
    }
    static uploadDocumentsRevisionEditPage(payload: { files: Array<File>, templateName: string | null, mailType: string | null }): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPLOAD_DOCUMENTS_REVISION, payload };
    }
    static dryRunSearchPage(): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_DRY_RUN };
    }

    static updateSearchPageInitState(payload: IEmailPipelineModuleState['searchPageInitialized']): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IEmailPipelineModuleState['searchPageCollapsed']): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(data): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_FORM, payload: data };
    }

    static updateSearchLockOnSearchPage(payload: IEmailPipelineModuleState['searchPageLock']): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updatePipelineListOnSearchPage(payload: Array<any>): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_PIPELINE_LIST, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(payload: Array<string>): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: EmailPipelineStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static updateSaveLockCreatePage(payload: IEmailPipelineModuleState['createPageSaveLock']): Action {
        return { type: EmailPipelineStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: EmailPipelineStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(payload: IEmailPipelineModuleState['editPageRecordId']): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdEditPage(payload: IEmailPipelineModuleState['editPageRecordId']): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IEmailPipelineModuleState['editPageSaveLock']): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static showSendModalOnEditPage(v: boolean | null): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_SHOW_SEND_MODAL, payload: v };
    }

    static editPageSend(): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_SEND };
    }

    static editPageUpdateModalForm(payload): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_UPDATE_MODAL_FORM, payload };
    }

    static submitOnEditPage(): Action {
        return { type: EmailPipelineStory.EDIT_PAGE_SUBMIT };
    }
}
