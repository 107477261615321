import { Pipe, PipeTransform } from '@angular/core';
import { BaseDateFormatPipe } from './base.date.format.pipe';

@Pipe({
    name: 'dateTimeFormat'
})

export class DateTimeFormatPipe extends BaseDateFormatPipe implements PipeTransform {

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order: date format, time format
     */
    transform(value: any, ...args: any[]): string {
        const [date = BaseDateFormatPipe.DATE, time = BaseDateFormatPipe.TIME] = args;

        return super.transform(value, date, time);
    }
}
