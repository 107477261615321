import { ItineraryRoom } from '@aclass/admin/storage/models/itinerary.room';
import { OfflineBookingPassenger } from '@aclass/admin/storage/models/offline.booking.passenger';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IItineraryRoomToPassengerMappingData {

    roomId: number | string | null;

    passengerId: number | string | null;
}

@Model({
    name: 'ItineraryRoomToPassengerMapping',
    idAttribute: 'roomId'
})
export class ItineraryRoomToPassengerMapping extends Entity<IItineraryRoomToPassengerMappingData> {

    @Fk({ to: 'ItineraryRoom', relatedName: 'relatedRoom' }) readonly roomId: ItineraryRoom | null;

    @Fk({ to: 'OfflineBookingPassenger', relatedName: 'relatedPassenger' }) readonly passengerId: OfflineBookingPassenger | null;

}
