import { isDateRange } from '@aclass/core/base/daytable';
import { Pipe, PipeTransform } from '@angular/core';
import { BaseDateFormatPipe } from './base.date.format.pipe';

@Pipe({
    name: 'dateRangeFormat'
})

export class DateRangeFormatPipe extends BaseDateFormatPipe implements PipeTransform {

    /**
     * Glue to separate from and to value
     */
    static SEPARATOR = ' - ';

    /**
     * @inheritDoc
     * @param value The value being formatted
     * @param args Extra arguments in following order: date format, separator, trim
     */
    transform(value: any, ...args: any[]): string {
        if (!isDateRange<Date>(value)) {
            return '';
        }
        const [format = BaseDateFormatPipe.DATE, separator = DateRangeFormatPipe.SEPARATOR, trim = true] = args;
        const [from, to] = [super.transform(value.from, format), super.transform(value.to, format)];

        return trim && from === to ? from : [from, to].join(separator);
    }
}
